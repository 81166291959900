'use strict';

const yup = require('yup');
const moment = require('moment');

const integerDate = (errorMessage = 'Invalid integer date, date must be YYYYMMDD') =>
  yup
    .number()
    .nullable()
    .test('is-valid-integer-date', errorMessage, (value) => {
      if (value === null || value === undefined) {
        return true;
      }
      return moment(value, 'YYYYMMDD', true).isValid();
    });

// the built-in yup.string().uuid() only support v3 and v4 uuid https://github.com/jquense/yup/issues/1705
// There are some migrated clients that have uuid in other version and will fail in the validation.
// Eg. 26183392-c69e-0156-9307-1e2047c5280a is a v1 uuid.
// We use a regex here to support more versions.
const guidRe = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

const uuid = (errorMessage = 'Invalid UUID') => yup.string().matches(guidRe, errorMessage);

const customYupValidators = {
  integerDate,
  uuid,
};

module.exports = {
  customYupValidators,
};

/* eslint-disable import/no-cycle */
import { store } from '@sb-itops/redux';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import { fetchPostP } from '@sb-itops/redux/fetch';
import moment from 'moment';
import uuid from '@sb-itops/uuid';
import { buildReversalTransactionOpdates, opdateCache, rollbackOpdateCache } from './reversal-transaction-opdates';

const getAccountId = () => authSelectors.getAccountId(store.getState());
const getUserId = () => authSelectors.getUserId(store.getState());

const buildReversalDepositTransaction = (transaction) => {
  const { accountType, bankAccountId, reason, deleteTransaction, checkMatterBalance, allowOverdraw } = transaction;
  const accountId = getAccountId();
  const userId = getUserId();
  return {
    accountType,
    bankAccountId,
    newTransactionId: uuid(),
    accountId,
    userId,
    effectiveDate: +moment().format('YYYYMMDD'),
    reason,
    hideTransactions: !!deleteTransaction,
    checkMatterBalance: !!checkMatterBalance,
    allowOverdraw: !!allowOverdraw,
  };
};

/**
 * Reverse a transaction that is a deposit
 * @param {transaction like {  bankAccountId, accountType, transactionId, reason, checkMatterBalance, deleteTransaction, allowOverdraw }} transaction
 * @returns {thunk} thunk
 */
export const reverseDeposit = (transaction) => {
  const { transactionId } = transaction;

  if (!transactionId) {
    throw new Error('Invalid Transaction, you need a transaction id for reversing a transaction');
  }

  const reverseDepositThunk = async () => {
    const reversalDepositTransaction = buildReversalDepositTransaction(transaction);

    const opdateReversalDepositTransaction = buildReversalTransactionOpdates({
      originalTransaction: transaction,
      reversalTransactionId: reversalDepositTransaction.newTransactionId,
    });

    // Apply to save optimistically.
    opdateCache({ optimisticEntities: opdateReversalDepositTransaction });

    try {
      const path = `/billing/bank-account/reversal/:accountId/transaction/${transactionId}`;
      const fetchOptions = { body: JSON.stringify(reversalDepositTransaction) };
      await fetchPostP({ path, fetchOptions });
    } catch (err) {
      rollbackOpdateCache({ optimisticEntities: opdateReversalDepositTransaction });

      throw err;
    }
  };

  return store.dispatch(reverseDepositThunk);
};

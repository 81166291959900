import { getById as getOperatingChequeById } from '@sb-billing/redux/operating-cheques';
import { getById as getExpenseById } from '@sb-billing/redux/expenses';
import { getLogger } from '@sb-itops/fe-logger';
import * as messageDisplayService from '@sb-itops/message-display';

import { services as expenseService } from '@sb-billing/business-logic/expense';

angular.module('sb.billing.webapp').component('sbOperatingChequeDetails', {
  templateUrl: 'ng-components/operating-cheque-details/operating-cheque-details.html',
  bindings: { chequeId: '<', onClose: '<' },
  controller: function (sbOperatingChequesService, focusService, sbLocalisationService) {
    const ctrl = this;
    const log = getLogger('sbOperatingChequeDetails');

    ctrl.view = {
      isReverseCollapsed: true,
    };
    ctrl.errors = {
      reason: false,
    };
    ctrl.model = {
      reason: undefined,
      delete: false,
    };

    ctrl.toggleReversalUI = toggleReversalUI;
    ctrl.closeModal = closeModal;
    ctrl.processReversal = processReversal;
    ctrl.validate = validate;
    ctrl.t = sbLocalisationService.t;

    ctrl.$onChanges = () => {
      const cheque = getOperatingChequeById(ctrl.chequeId);
      let amount = 0;
      const matterIdSet = new Set();

      cheque.expenseIds.forEach((expenseId) => {
        const expense = getExpenseById(expenseId);
        amount += expenseService.getIncludingTaxAmount(expense);
        matterIdSet.add(expense.matterId);
      });

      ctrl.view.cheque = {
        amount,
        chequeId: cheque.chequeId,
        effectiveDate: cheque.chequeDate,
        contactId: cheque.payToId,
        userId: cheque.createdBy,
        systemDate: cheque.lastUpdated,
        matterIds: [...matterIdSet],
        // description: '',
        isHidden: cheque.isHidden,
        isReversed: cheque.reversed,
        // notes: '',
        memo: cheque.chequeMemo, //US only
        expenseIds: cheque.expenseIds,
        reason: cheque.reversalReason,
      };
    };

    function validate() {
      ctrl.errors.reason = !ctrl.model.reason || !ctrl.model.reason.trim();

      return Object.keys(ctrl.errors).every((key) => !ctrl.errors[key]);
    }

    function toggleReversalUI (opts = {}) {
      ctrl.view.isReverseCollapsed = !ctrl.view.isReverseCollapsed;
      ctrl.model.delete = opts.delete;

      if (!ctrl.view.isReverseCollapsed) {
        focusService.focusOn('reason-field');
      }
    }

    function processReversal(){
      if (!validate()) {
        return;
      }

      const type = sbLocalisationService.t('capitalizeAllWords', { val: 'operatingCheque' });

      return sbOperatingChequesService.reverseChequeP(ctrl.view.cheque.chequeId, ctrl.model.delete, ctrl.model.reason)
        .then(() => {
          closeModal();
          messageDisplayService.success(
            messageDisplayService
            .builder()
            .text(`${type} ${ctrl.model.delete ? 'deletion' : 'reversal'} processed`)
          );
        })
        .catch((err) => {
          closeModal();
          log.error('Problem processing reversal', err);
          messageDisplayService.error(
            messageDisplayService
            .builder()
            .title(`${ctrl.model.delete ? 'deletion' : 'reversal'} not processed`)
            .text(`Failed to process ${type} ${ctrl.model.delete ? 'deletion' : 'reversal'}. Please check your connection and try again`)
          );
        });
    }

    function closeModal(){
      ctrl.onClose({dismiss : true});
    }

  }
});

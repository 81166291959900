import React from 'react';
import PropTypes from 'prop-types';
import { CommunicateEmbeddedContainer } from 'web/containers';

export const ViewMatterCommunicateRouteContainer = ({ matterId }) => (
  <CommunicateEmbeddedContainer matterId={matterId} />
);

ViewMatterCommunicateRouteContainer.displayName = 'ViewMatterCommunicateRouteContainer';

ViewMatterCommunicateRouteContainer.propTypes = {
  matterId: PropTypes.string.isRequired,
};

ViewMatterCommunicateRouteContainer.defaultProps = {};

import { CLEAR_ALL, TOGGLE_EXPENSES } from './types';

export const clearAllSelection = () => ({
  type: CLEAR_ALL,
  payload: [],
});

export const toggleExpenses = ({ expenseIds }) => ({
  type: TOGGLE_EXPENSES,
  payload: {
    expenseIds,
  },
});

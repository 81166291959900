import { useState } from 'react';
import composeHooks from '@sb-itops/react-hooks-compose';
import { useCreateLetter } from 'web/hooks';
import { useMatterParties } from 'web/hooks/use-matter-parties';
import { CreateLetterModal } from './CreateLetterModal';

export interface ICreateLetterModalContainerProps {
  matterId: string;
  selectedEntityId?: string;
  body?: string;
  onClose: () => void;
}

const hooks = ({ matterId, selectedEntityId, body, onClose }: ICreateLetterModalContainerProps) => ({
  useModalData: () => {
    const { selectableContacts, loading: selectableContactsLoading } = useMatterParties(matterId);
    const { authorizationLoading, isUnauthorized, isCreatingLetter, createLetter } = useCreateLetter();

    const firstContact = selectableContacts?.[0];
    const initialContact = selectedEntityId
      ? selectableContacts?.find((contact) => contact.id === selectedEntityId) ?? firstContact
      : firstContact;

    const [selectedContact, setSelectedContact] = useState<{ id: string; name: string } | undefined>(initialContact);

    const [letterUrl, setLetterUrl] = useState<string | undefined>();

    const onCreateLetter = async () => {
      const createLetterUrl = await createLetter(matterId, selectedContact!.id, selectedContact!.name, body);

      if (createLetterUrl) {
        window.open(createLetterUrl, '_blank');
        setLetterUrl(createLetterUrl);
      }
    };

    const isLoading = selectableContactsLoading || authorizationLoading;

    return {
      isLoading,
      isUnauthorized,
      selectableContacts,
      selectedContact,
      setSelectedContact,
      onCreateLetter,
      isCreatingLetter,
      letterUrl,
      onClose,
    };
  },
});

export const CreateLetterModalContainer = composeHooks(hooks)(CreateLetterModal);

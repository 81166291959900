import { validateMaxEmailsNumber as isOverMaxEmailsNumber } from '@sb-itops/email'
import { getMatterDisplayById } from '@sb-matter-management/redux/matters';
import { getById as getBankAccountById, ACCOUNT_TYPE } from '@sb-billing/redux/bank-account';
import { getById as getMatterEmailSettings } from '@sb-billing/redux/matter-email-settings';

angular.module('sb.billing.webapp').component('sbCbuiSendTransactionReceipt', {
  templateUrl: 'ng-composable-components/callback-ui/send-transaction-receipt/cbui-send-transaction-receipt.html',
  bindings: { debtorId: '=?', callbackFn: '&', matterId: '<', transactionId: '<' },
  controller: function (sbSimpleContactMbService, sbFirmManagementMbService, focusService, sbMattersMbService, sbTransactionReceiptService, sbCbuiSendTransactionReceiptValidator) {
    const ctrl = this;

    ctrl.send = send;
    ctrl.validateTo = validateTo;
    ctrl.validateFrom = validateFrom;
    ctrl.validateCc = validateCc;
    ctrl.validateBcc = validateBcc;
    ctrl.validateToEmailsNumber = validateToEmailsNumber;
    ctrl.validateCcEmailsNumber = validateCcEmailsNumber;
    ctrl.validateBccEmailsNumber = validateBccEmailsNumber;

    ctrl.$onInit = async () => {
      ctrl.view = {
        salutation: '',
        staffName: '',
        firmName: '',
        firmPhoneNumber: '',
      };

      ctrl.model = {
        from: '',
        to: '',
        cc: '',
        bcc: '',
        subject: '',
        message: '',
      };

      if (ctrl.debtorId) {
        await updateClientAddress();
        await updateSenderP()
        updateContent();
        initCcBcc(ctrl.matterId);
      }
      ctrl.validator = new sbCbuiSendTransactionReceiptValidator(() => ctrl.model);
    };

    function initCcBcc(matterId) {
      const matterEmailSettings = getMatterEmailSettings(matterId) || {};
      ctrl.model.cc = (matterEmailSettings.cCAddresses || []).join(', ');
      ctrl.model.bcc = (matterEmailSettings.bCCAddresses || []).join(', ');
    }

    async function updateClientAddress() {
      const contact = await sbSimpleContactMbService.getPeopleP(ctrl.debtorId);

      const { to, salutation } = sbSimpleContactMbService.getEmails(contact);

      ctrl.model.to = to
      ctrl.view.salutation = salutation;
    }

    async function updateSenderP() {
      const { email, name } = await sbFirmManagementMbService.getStaffEmailDetails();

      ctrl.view.firmName = sbFirmManagementMbService.getLoggedInStaffFirmName();
      ctrl.view.firmPhoneNumber = sbFirmManagementMbService.getLoggedInStaffFirmPhoneNumber();
      ctrl.view.staffName = name;
      ctrl.model.from = email;
    }

    function updateContent() {
      const matter = sbMattersMbService.getById(ctrl.matterId);
      const transactionReceipt = sbTransactionReceiptService.getById(ctrl.transactionId);
      const bankAccount = getBankAccountById(transactionReceipt.bankAccountId);
      const messageSignature = bankAccount.accountType === ACCOUNT_TYPE.controlled_money.toUpperCase() ? `${ctrl.view.staffName}<br>${ctrl.view.firmPhoneNumber}<br>${ctrl.view.firmName}` : `${ctrl.view.staffName}`;

      ctrl.model.subject = `${bankAccount.accountTypeDisplay} Receipt #${transactionReceipt.receiptNumber} for ${getMatterDisplayById(matter.matterId)}`;
      ctrl.model.message = `
        <p>Dear ${ctrl.view.salutation},</p>
        <p><br></p>
        <p>Please see ${bankAccount.accountTypeDisplay} deposit receipt attached.</p>
        <p><br></p>
        <p>Kind regards,</p>
        <p>${messageSignature}</p>
      `;
    }

    function validateTo(skipDirtyChecking) {
      return ctrl.validator ? ctrl.validator.validateField('to', skipDirtyChecking) : false;
    }

    function validateFrom(skipDirtyChecking) {
      return ctrl.validator ? ctrl.validator.validateField('from', skipDirtyChecking) : false;
    }

    function validateCc(skipDirtyChecking) {
      return ctrl.validator ? (ctrl.model.cc === '' || ctrl.validator.validateField('cc', skipDirtyChecking)) : false;
    }

    function validateBcc(skipDirtyChecking) {
      return ctrl.validator ? (ctrl.model.bcc === '' || ctrl.validator.validateField('bcc', skipDirtyChecking)) : false;
    }

    function validateToEmailsNumber() {
      return isOverMaxEmailsNumber(ctrl.model.to);
    }
    function validateCcEmailsNumber() {
      return isOverMaxEmailsNumber(ctrl.model.cc) || ctrl.model.cc === '';
    }
    function validateBccEmailsNumber() {
      return isOverMaxEmailsNumber(ctrl.model.bcc) || ctrl.model.bcc === '';
    }

    function validate(skipDirtyChecking) {
      const isOverMaxEmailsNumber = validateToEmailsNumber() && validateCcEmailsNumber() && validateBccEmailsNumber();
      return validateTo(skipDirtyChecking) && validateFrom(skipDirtyChecking) && validateCc(skipDirtyChecking) && validateBcc(skipDirtyChecking) && isOverMaxEmailsNumber;
    }

    function marshal() {
      return {
        receiptId: ctrl.transactionId,
        sendTo: ctrl.model.to,
        from: ctrl.model.from,
        // empty string would not pass endpoint validation so either email or undefined
        cc: ctrl.model.cc ? ctrl.model.cc : undefined,
        bcc: ctrl.model.bcc ? ctrl.model.bcc : undefined,
        subject: ctrl.model.subject,
        message: ctrl.model.message,
      };
    }

    function send() {
      if (validate(true)) {
        ctrl.callbackFn({ data: marshal() });
      } else {
        if (!validateTo(true) || !validateToEmailsNumber()) {
          focusService.focusOn('to-email');
        } else if (!validateCc(true) || !validateCcEmailsNumber()) {
          focusService.focusOn('cc-email');
        } else if (!validateBcc(true) || !validateBccEmailsNumber()) {
          focusService.focusOn('bcc-email');
        } else if (!validateFrom(true)) {
          focusService.focusOn('from-email');
        }
      }
    }
  }
});

import { toCamelCase } from '@sb-itops/camel-case';
import { processNotification as processCallbackNotification } from 'web/services/subscription-manager';
import {
  processNotification as processApolloNotification,
  processCacheNotification as processApolloCacheNotification,
} from 'web/services/apollo/subscription-manager';

/**
 * @typedef {object} Notification
 * @property {string} providerId Notification provider ID
 * @property {string|Object} message Notification message
 */

/**
 * Handle Notification - Standardise the format of incoming notifications
 *
 * @param {object} params
 * @param {object} params.log - Log functions
 * @param {string} [params.userId] - User ID
 * @returns {(notification: Notification) => Promise<undefined>}
 */
const handleNotification =
  ({ log }) =>
  async (notification) => {
    log.debug('saw notification: ', notification);

    const { providerId, message } = notification;

    if (!providerId || !message) {
      log.warn('Malformed notification received: ', notification);
      return;
    }

    const provider = providerId.replace(/Notfications/, 'Notifications');

    let action;

    switch (provider) {
      case 'AccountsNotifications':
      case 'WebQueryAccountsNotifications':
      case 'BillingMattersNotifications':
      case 'WebQueryBillingMattersNotifications':
      case 'DebtorsNotifications':
      case 'WebQueryDebtorsNotifications':
      case 'ExpensesNotifications':
      case 'WebQueryExpensesNotifications':
      case 'FeesNotifications':
      case 'WebQueryFeesNotifications':
      case 'InvoicingNotifications':
      case 'WebQueryInvoicingNotifications':
      case 'TaxesNotifications': {
        // {providerId: '<PROVIDER>', message: '{"MessageId":"<MESSAGE>","EntityId":"<GUID>"}'}
        action = toCamelCase(JSON.parse(message) || {}).messageId;
        break;
      }

      case 'XeroIntegration':
      case 'BillingSharedNotifications':
      case 'WebQueryBillingSharedNotifications': {
        action = message.includes('MessageId') ? toCamelCase(JSON.parse(message)).messageId : message;
        break;
      }

      case 'BrowseMattersNotifications':
      case 'CustomerManagementWebQuery': // CustomerManagementNotifications WebQuery equivalent
      case 'FirmManagementWebQuery':
      case 'MatterManagementWebQuery': // BrowseMattersNotifications WebQuery equivalent
      case 'RelateEntitiesToMatter': {
        // Weirdly shit '<ID>|<ENTITY>' separated format.
        action = message.split('|')[0];
        break;
      }

      case 'Authorization':
      case 'Security':
      case 'BillingActivitiesNotifications':
      case 'FirmManagementNotifications': {
        // message is a string, e.g. "FirmUpdated" or "PersonUpdated"
        action = message;
        break;
      }

      // Ignore action, essentially catch all providerId notifications
      case 'CalendarSyncSettingsNotifications': {
        // Weirdly shit '<ID>_<ENTITY>' separated format.
        action = message.split('_')[0];
        break;
      }
      // The below are providerId only catch-all notifications, this is just to highlight
      // their existence, we don't technically need to handle these here as they are pass throughs
      case 'CustomerManagementNotifications':
      case 'InvoiceStatementPDFSyncNotification':
      case 'TaskingNotifications': // TaskHistoryUpdated
      case 'PaymentProviderChargeCompleted':
      case 'PaymentProviderRefundUpdated': {
        break;
      }
    }

    await Promise.all([
      processCallbackNotification({ notificationId: provider, payload: message, action }),
      processApolloNotification({ provider, action }),
      processApolloCacheNotification({ provider, action }),
    ]);
  };

module.exports = {
  handleNotification,
};

import React from 'react';
import { getRegion, regionType } from '@sb-itops/region';
import { Pause as LucidePause, Play as LucidePlay } from 'lucide-react';
import * as Styles from './Icons.module.scss';

const region = getRegion();

export const Pause = () => <LucidePause className={Styles.pause} />;
export const Play = () => <LucidePlay className={Styles.play} />;

const InvoiceAUUSIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16 1H5a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-5-5Z" />
    <path d="M12 5v14M16 7H9.6C9.067 7 8 7.5 8 9.5S9.067 12 9.6 12h4.8c.533 0 1.6.5 1.6 2.5S14.933 17 14.4 17H8" />
  </svg>
);
const InvoiceDottedAUUSIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path strokeDasharray="5 5" d="M16 1H5a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-5-5Z" />
    <path d="M12 5v14M16 7H9.6C9.067 7 8 7.5 8 9.5S9.067 12 9.6 12h4.8c.533 0 1.6.5 1.6 2.5S14.933 17 14.4 17H8" />
  </svg>
);

const InvoiceDottedUKIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path strokeDasharray="5 5" d="M16 1H5a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-5-5ZM7 13h6" />
    <path d="M10 18V8.25c0-.862.316-1.689.879-2.298A2.888 2.888 0 0 1 13 5c.796 0 1.559.342 2.121.952.563.61.879 1.436.879 2.298M7 18h9" />
  </svg>
);

const InvoiceUKIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16 1H5a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-5-5ZM7 13h6" />
    <path d="M10 18V8.25c0-.862.316-1.689.879-2.298A2.888 2.888 0 0 1 13 5c.796 0 1.559.342 2.121.952.563.61.879 1.436.879 2.298M7 18h9" />
  </svg>
);

export const InvoiceIcon = () => (region === regionType.GB ? <InvoiceUKIcon /> : <InvoiceAUUSIcon />);
export const InvoiceDottedIcon = () => (region === regionType.GB ? <InvoiceDottedUKIcon /> : <InvoiceDottedAUUSIcon />);

export const LeadIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20 20a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-7.9a2 2 0 0 1-1.69-.9L9.6 3.9A2 2 0 0 0 7.93 3H4a2 2 0 0 0-2 2v13a2 2 0 0 0 2 2h16Z" />
    <path d="M11 8.5V16h4" />
  </svg>
);

// Deprecated
export const MatterIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20 20a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2h-7.9a2 2 0 0 1-1.69-.9L9.6 3.9A2 2 0 0 0 7.93 3H4a2 2 0 0 0-2 2v13a2 2 0 0 0 2 2h16Z" />
    <path d="M8 17v-7h1.23l2.462 6h.616l2.461-6H16v7" />
  </svg>
);

export const MatterIcon2 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512">
    <path d="M448 480L64 480c-35.3 0-64-28.7-64-64L0 96C0 60.7 28.7 32 64 32l128 0c20.1 0 39.1 9.5 51.2 25.6l19.2 25.6c6 8.1 15.5 12.8 25.6 12.8l160 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64zM369 241c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 241z" />
  </svg>
);

export const Cross = () => (
  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="#1C2230">
    <path d="M41 39C31.6 29.7 16.4 29.7 7 39S-2.3 63.6 7 73l183 183L7 439c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l183-183L407 473c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-183-183L441 73c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-183 183L41 39z" />
  </svg>
);
export const PlusFF = () => (
  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" fill="#0265DC" viewBox="0 0 448 512">
    <path d="M248 72c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 160L40 232c-13.3 0-24 10.7-24 24s10.7 24 24 24l160 0 0 160c0 13.3 10.7 24 24 24s24-10.7 24-24l0-160 160 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-160 0 0-160z" />
  </svg>
);
export const Add = ({ onClick }) => (
  <div onClick={onClick} className={Styles.add}>
    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" fill="#1C2230" viewBox="0 0 448 512">
      <path d="M248 72c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 160L40 232c-13.3 0-24 10.7-24 24s10.7 24 24 24l160 0 0 160c0 13.3 10.7 24 24 24s24-10.7 24-24l0-160 160 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-160 0 0-160z" />
    </svg>
  </div>
);
export const Remove = ({ onClick }) => (
  <div onClick={onClick} className={Styles.remove}>
    <svg fill="#D31510" width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM184 232l144 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-144 0c-13.3 0-24-10.7-24-24s10.7-24 24-24z" />
    </svg>
  </div>
);

import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { Button, LoadingBarInfinite, Spinner } from '@sb-itops/react';
import { Appointment, Person, EventGroup } from 'types';
import { isNewTheme } from 'web/services/theme';
import { IScheduleContainerProps, ScheduleContainer } from './Schedule.container';
import Styles from './Schedule.module.scss';
import { AddEventModal } from '../add-event-modal';
import { EventFilters } from './event-filters';

export interface IScheduleTabProps extends IScheduleContainerProps {
  loggedInStaff: Person;
  modalOpen: Appointment | false;
  onCloseModal: () => void;
  onOpenModal: (openedAppointment: Appointment | {}) => void;
  loading: boolean;
  filtersLoading: boolean;
  isSyncing: boolean;
  setStaffFilter: (newFilter: { [key: string]: boolean }) => void;
  staffFilter: { [key: string]: boolean };
  currentUsers: Person[];
  formerUsers: Person[];
  attendees: { id: string; name: string }[];
  eventGroups: EventGroup[];
  scope: string;
  selectedCells: any[];
  setSelectedCells: (selectedCells: any[]) => void;
}

export const ScheduleTab = ({
  calendar,
  loading,
  onSaveAppointment,
  matterSummaries,
  currentUsers,
  attendees,
  onDeleteAppointment,
  modalOpen,
  onCloseModal,
  onOpenModal,
  selectedView,
  setSelectedView,
  loggedInStaff,
  fetchMore,
  staffFilter,
  setStaffFilter,
  scope,
  eventGroups,
  filtersLoading,
  selectedCells,
  setSelectedCells,
  formerUsers,
  isSyncing,
}: IScheduleTabProps) => {
  const [showSyncMessage, setShowSyncMessage] = useState(isSyncing);
  useEffect(() => {
    // Clear sync complete message after 5 seconds
    if (!isSyncing && showSyncMessage) {
      setTimeout(() => {
        setShowSyncMessage(false);
      }, 5000);
    } else if (isSyncing) {
      setShowSyncMessage(true);
    }
  }, [isSyncing]);
  return (
    <>
      <div className="page-top-bar">
        <div className="page-name">
          {!isNewTheme() && <div className="page-name-icon icon-calendar-2" />}
          <h1 className="page-name-heading">Calendar</h1>
        </div>
        <div className="sb-tab-header">
          <ul className="nav nav-pills">
            <li className="active">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a>Calendar</a>
            </li>
          </ul>
        </div>
      </div>
      <div className={Styles.container}>
        <div className={classnames('master-detail-panel', Styles.scheduler)}>
          <EventFilters
            eventGroups={eventGroups}
            loading={filtersLoading}
            staffFilter={staffFilter}
            setStaffFilter={setStaffFilter}
            currentUsers={currentUsers}
            loggedInStaff={loggedInStaff}
            scope={scope}
            setSelectedCells={setSelectedCells}
            setSelectedView={setSelectedView}
            selectedView={selectedView}
            formerUsers={formerUsers}
            fetchMore={fetchMore}
          />
          <div className={classnames('panel-body', Styles.mainCalendar)}>
            <LoadingBarInfinite loading={loading} containerStyle={{ top: '56px' }} />
            <ScheduleContainer
              editing
              fetchMore={fetchMore}
              calendar={calendar}
              loggedInStaff={loggedInStaff}
              matterSummaries={matterSummaries}
              attendees={attendees}
              onDeleteAppointment={onDeleteAppointment}
              onSaveAppointment={onSaveAppointment}
              selectedView={selectedView}
              setSelectedView={setSelectedView}
              loading={loading}
              setSelectedCells={setSelectedCells}
              selectedDate={selectedCells && selectedCells[0].startDate}
            />
            <Button onClick={() => onOpenModal({})} className={Styles.newEventButton} type="tertiary">
              New Event
            </Button>
          </div>
          {showSyncMessage && isSyncing && (
            <div className={Styles.banner}>
              <div className={Styles.text}>
                <Spinner small /> M365 Calendar Sync in progress.{' '}
                <i className={classnames(Styles.closeIcon, 'close-icon')} onClick={() => setShowSyncMessage(false)} />
              </div>
            </div>
          )}
          {showSyncMessage && !isSyncing && (
            <div className={Styles.banner}>
              <div className={Styles.text}>
                <i className={`icon icon-icon-fat-check-circle-filled ${Styles.greenCheck}`} /> Sync up to date
                <i className={classnames(Styles.closeIcon, 'close-icon')} onClick={() => setShowSyncMessage(false)} />
              </div>
            </div>
          )}
          {!!modalOpen && (
            <AddEventModal
              attendees={attendees}
              matterSummaries={matterSummaries}
              onSaveAppointment={onSaveAppointment}
              onDeleteAppointment={onDeleteAppointment}
              onClose={onCloseModal}
              appointmentData={modalOpen}
              loggedInStaff={loggedInStaff}
            />
          )}
        </div>
      </div>
    </>
  );
};

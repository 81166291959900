import React from 'react';
import PropTypes from 'prop-types';
import { MemosTab } from 'web/components';

export const ViewMatterMemosRouteContainer = ({ matterId, selectedMemoId }) => (
  <MemosTab matterId={matterId} selectedMemoId={selectedMemoId} />
);

ViewMatterMemosRouteContainer.displayName = 'ViewMatterMemosRouteContainer';

ViewMatterMemosRouteContainer.propTypes = {
  matterId: PropTypes.string.isRequired,
  selectedMemoId: PropTypes.string,
};

ViewMatterMemosRouteContainer.defaultProps = { selectedMemoId: '' };

import React from 'react';
import { featureActive } from '@sb-itops/feature';
import { CONVERT_LEAD_MODAL_ID, RESTORE_MATTER_MODAL_ID } from 'web/react-redux';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { Button, Icon, LoadingPlaceholderElement } from '@sb-itops/react';
import { BookOpenCheck } from 'web/components/icons';
import classNames from 'classnames';
import Styles from './MatterDescriptionBar.module.scss';

export const MatterDescriptionBar = ({
  onClickLink,
  matter,
  defaultClient,
  matterId,
  loading,
  t,
  trustRetainerBelowLimit,
  totals,
  trustTooltip,
}) => (
  <div className={Styles.topLine}>
    {loading && <LoadingPlaceholderElement className={Styles.placeholder} isLine />}
    <div className={Styles.descriptionBlock}>
      <div className={Styles.title} onClick={() => onClickLink({ type: 'contact', id: defaultClient?.id })}>
        {defaultClient?.displayNameFirstLast || ''}
      </div>
      <div className={Styles.statusLine}>
        {!!matter?.matterNumber && <div className={Styles.matterNumber}>{matter.matterNumber}</div>}
        {!!matter?.status && (
          <div className={classNames(matter.status === 'Open' && Styles.openStatus, Styles.status)}>
            {matter.status}
          </div>
        )}
        {!!matter?.matterType?.name && <div>{matter.matterType.name}</div>}
      </div>
      {!!matter?.description && (
        <div title={matter.description} className={Styles.matterDescription}>
          {matter.description}
        </div>
      )}
    </div>
    <div className={classNames(Styles.hoverTooltip)}>
      <div className={classNames(Styles.icon, Styles.blueIcon)}>
        <BookOpenCheck />
        {trustTooltip}
      </div>
      <div
        onClick={() => {
          onClickLink({ type: 'matterTransactions', id: 'trust', params: [{ matterId }] });
        }}
        title={t('cents', { val: totals?.TRUST })}
        className={Styles.amount}
      >
        <span className={classNames(trustRetainerBelowLimit && Styles.redText)}>
          {t('cents', { val: totals?.TRUST })}
        </span>
        <div className={Styles.amountTitle}>
          {t('trust')}
          {trustRetainerBelowLimit && <Icon className={Styles.warningIcon} type="icon-fat-exclamation-circle-filled" />}
        </div>
      </div>
    </div>
    {featureActive('BB-7911') && matter?.status === 'Deleted' && (
      <Button
        type="secondary"
        onClick={() => setModalDialogVisible({ modalId: RESTORE_MATTER_MODAL_ID, props: { matterId } })}
      >
        {matter?.isLead ? 'Restore Lead' : 'Restore Matter'}
        <div className={classNames('icon icon-undo', Styles.restoreIcon)} />
      </Button>
    )}
    {matter?.isLead && featureActive('NUCWEB-133') && matter?.status !== 'Deleted' && (
      <Button
        type="secondary"
        onClick={() => setModalDialogVisible({ modalId: CONVERT_LEAD_MODAL_ID, props: { matterId } })}
        title="Convert Lead to Matter"
      >
        Convert Lead to Matter
      </Button>
    )}
    <Button
      type="tertiary"
      onClick={() => onClickLink({ type: 'editMatter', id: matterId })}
      className={Styles.editMatter}
      title={matter?.isLead ? 'Edit Lead' : 'Edit Matter'}
    >
      {matter?.isLead ? 'Edit Lead' : 'Edit Matter'}
      <Icon className={Styles.iconButton} type="edit" isFA />
    </Button>
  </div>
);

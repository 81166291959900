import React from 'react';
import imageSrc from '../../../assets/icon-file-image-1.svg';
import pdfSrc from '../../../assets/icon-file-pdf.svg';
import textSrc from '../../../assets/icon-file-text.svg';
import videoSrc from '../../../assets/icon-file-movie.svg';
import excelSrc from '../../../assets/icons-microsoft-excel-logos.svg';
import powerpointSrc from '../../../assets/icons-microsoft-powerpoint-logos.svg';
import wordSrc from '../../../assets/icons-microsoft-word-logos.svg';

export const IconSelector = ({ extension }: { extension: string }) => {
  switch (extension) {
    case '.png':
    case '.jpg':
    case '.jpeg':
      // eslint-disable-next-line jsx-a11y/img-redundant-alt
      return <img className="icon" alt="image file" src={imageSrc} />;
    case '.wav':
    case '.mp4':
      return <img className="icon" alt="video" src={videoSrc} />;
    case '.docx':
    case '.doc':
      return <img className="icon" alt="microsoft word" src={wordSrc} />;
    case '.csv':
    case '.xlsx':
    case '.xls':
      return <img className="icon" alt="microsoft excel" src={excelSrc} />;
    case '.ppt':
      return <img className="icon" alt="microsoft powerpoint" src={powerpointSrc} />;
    case '.txt':
      return <img className="icon" alt="text file" src={textSrc} />;
    case '.pdf':
      return <img className="icon" alt="PDF" src={pdfSrc} />;
    case '.eml':
      return <div style={{ color: 'black', fontSize: '1.2em' }} className="icon icon-mail-1" />;
    case '.msg':
      return <div style={{ color: 'black', fontSize: '1.2em' }} className="icon icon-mail-1" />;
    default:
      return <div style={{ fontSize: '1.2em' }} className="icon icon-file-1" />;
  }
};

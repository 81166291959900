'use strict';

const Yup = require('yup');
const { regionType } = require('@sb-itops/region');

const requiredIn = (required, type) => (required ? type.required() : type);

const CompanySchema = Yup.object().shape({
  companyName: Yup.string().required(),
  companyType: Yup.string(),
  companyPhoneAreaCode: Yup.string(),
  companyFaxAreaCode: Yup.string(),
  companyFax: Yup.string(),
  companyPhoneNumber: Yup.string(),
  companyEmail: Yup.string().email().nullable(),
  companyLedesClientId: Yup.string(),
});

const TrustSchema = Yup.object().shape({
  trustName: Yup.string().required(),
  trustStatus: Yup.string().nullable(),
  trustNumber: Yup.string().nullable(),
  trustPhoneAreaCode: Yup.string(),
  trustFaxAreaCode: Yup.string(),
  trustFax: Yup.string(),
  trustPhoneNumber: Yup.string(),
  trustDate: Yup.string().nullable(),
  trustees: Yup.array(
    Yup.object().shape({
      name: Yup.string().nullable(),
      type: Yup.string().nullable(),
    }),
  ),
  trustLedesClientId: Yup.string().nullable(),
});

const PersonSchema = Yup.object().shape({
  title: Yup.string(),
  firstName: Yup.string().required(),
  lastName: Yup.string().nullable(),
  birthDate: Yup.string().nullable(),
  phoneAreaCode: Yup.string(),
  phoneNumber: Yup.string(),
  cellAreaCode: Yup.string(),
  cellNumber: Yup.string(),
  email: Yup.string().email().nullable(),
  ledesClientId: Yup.string().nullable(),
});

const gbZipCodeRegex = /^[A-Z]{1,2}[0-9]{1,2}[A-Z]{0,1} ?[0-9][A-Z]{2}$/i;
const otherRegionZipCodeRegex = /^[0-9]{5}(?:-[0-9]{4})?$|^[0-9]{4}$/;

const AddressSchema = (region) => {
  if (!region) {
    throw new Error('AddressSchema requires a region to be injected');
  }

  return Yup.object().shape({
    buildingLevel: Yup.string().nullable(),
    unitNumber: Yup.string().nullable(),
    unitType: Yup.string().nullable(),
    streetNumber: requiredIn(region === 'AU', Yup.string().nullable()),
    streetName: requiredIn(region === 'AU', Yup.string().nullable()),
    streetType: requiredIn(region === 'AU', Yup.string().nullable()),
    addressLine1: requiredIn(region === 'US', Yup.string().nullable()),
    addressLine2: Yup.string().nullable(),
    city: Yup.string().required(),
    state: Yup.string().required(),
    zipCode: Yup.string()
      .matches(region === regionType.GB ? gbZipCodeRegex : otherRegionZipCodeRegex)
      .required(),
    county: Yup.string().nullable(),
    locality: Yup.string().nullable(),
    country: Yup.string().nullable(),
  });
};

const OptionalAddressSchema = (region) => {
  if (!region) {
    throw new Error('OptionalAddressSchema requires a region to be injected');
  }

  return Yup.object().shape({
    buildingLevel: Yup.string().nullable(),
    unitNumber: Yup.string().nullable(),
    unitType: Yup.string().nullable(),
    streetNumber: Yup.string(),
    streetName: Yup.string(),
    streetType: Yup.string(),
    addressLine1: Yup.string(),
    addressLine2: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    zipCode: Yup.string().matches(region === regionType.GB ? gbZipCodeRegex : otherRegionZipCodeRegex, {
      excludeEmptyString: true,
    }),
    county: Yup.string().nullable(),
    locality: Yup.string().nullable(),
    country: Yup.string().nullable(),
  });
};

module.exports = {
  OptionalAddressSchema,
  AddressSchema,
  CompanySchema,
  PersonSchema,
  TrustSchema,
};

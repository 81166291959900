import { fetchPostP } from '@sb-itops/redux/fetch';
import { PrintManually } from '@sb-billing/business-logic/cheques';
import { store } from '@sb-itops/redux';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import {
  getById as getExpenseById,
  opdateCache as expenseOpdateCache,
  rollbackOpdateCache as expenseRollbackOpdateCache,
} from './index';
import {
  opdateCache as expensePaymentDetailsOpdateCache,
  rollbackOpdateCache as rollbackExpensePaymentDetailsOpdateCache,
  getById as getExpensePaymentDetailsById,
} from '../expense-payment-details';

import {
  opdateCache as operatingChequeOpdateCache,
  rollbackOpdateCache as rollbackOperatingChequeOpdateCache,
} from '../operating-cheques';

const getUserId = () => authSelectors.getUserId(store.getState());

// paymentDetails are separate cache/entity but it is updated only throught expense endpoint
// This should be used only when we want to update paymentDetails without updating expenseVerison (for example when expense is locked)
// Otherwise, paymentDetails should be updated together with expenseVersion using saveExpense thunk
export const saveExpensePaymentDetailsForLockedExpense = (expensePaymentDetails) => {
  if (!expensePaymentDetails?.expenseId) {
    throw new Error('Expense Id must be specified.');
  }
  if (!expensePaymentDetails?.expensePaymentDetails) {
    throw new Error('Expense Payment Details must be specified.');
  }

  const expensePaymentDetailsPayload = { ...expensePaymentDetails };

  const saveExpensePaymentDetailsThunk = async () => {
    expensePaymentDetailsPayload.createdByUserId = getUserId();
    let chequeOpdate;
    let expenseOpdate;

    let updatedExpensePaymentDetails = { ...expensePaymentDetailsPayload.expensePaymentDetails };

    const existingExpensePaymentDetails = getExpensePaymentDetailsById(expensePaymentDetailsPayload.expenseId);

    if (existingExpensePaymentDetails) {
      updatedExpensePaymentDetails = { ...existingExpensePaymentDetails, ...updatedExpensePaymentDetails };
    }

    expensePaymentDetailsPayload.expensePaymentDetails = updatedExpensePaymentDetails;

    if (expensePaymentDetails?.operatingChequePrintOptions) {
      const existingExpense = getExpenseById(expensePaymentDetailsPayload.expenseId) || {};
      expenseOpdate = {
        ...existingExpense,
        operatingChequeId: expensePaymentDetails.operatingChequePrintOptions.chequeId,
      };

      // Add operating cheque optimistic update
      chequeOpdate = {
        chequeId: expensePaymentDetails.operatingChequePrintOptions.chequeId,
        bankAccountId: expensePaymentDetails.operatingChequePrintOptions.bankAccountId,
        chequeDate: expensePaymentDetails.operatingChequePrintOptions.chequeDate,
        expenseIds: [expensePaymentDetailsPayload.expenseId],
        isManual: expensePaymentDetails.operatingChequePrintOptions.chequePrintMethod === PrintManually,
        chequeNumber: expensePaymentDetails.operatingChequePrintOptions.chequeNumber,
        chequeMemo: expensePaymentDetails.operatingChequePrintOptions.chequeMemo,
        payToId: expensePaymentDetails.operatingChequePrintOptions.payToId,
      };
      operatingChequeOpdateCache({ optimisticEntities: [chequeOpdate] });
      expenseOpdateCache({ optimisticEntities: [expenseOpdate] });
    }
    // Apply to save optimistically.
    expensePaymentDetailsOpdateCache({ optimisticEntities: [updatedExpensePaymentDetails] });

    // Apply the save in the backend.
    try {
      const path = `/billing/expense/:accountId/`;
      const fetchOptions = { body: JSON.stringify(expensePaymentDetailsPayload) };
      await fetchPostP({ path, fetchOptions });
    } catch (err) {
      // Roll back the opdate.
      rollbackExpensePaymentDetailsOpdateCache({ optimisticEntities: [updatedExpensePaymentDetails] });
      if (chequeOpdate) {
        rollbackOperatingChequeOpdateCache({ optimisticEntities: [chequeOpdate] });
      }
      if (expenseOpdate) {
        expenseRollbackOpdateCache({ optimisticEntities: [expenseOpdate] });
      }
      // Rethrow error so UI can respond if necessary
      throw err;
    }
  };

  return store.dispatch(saveExpensePaymentDetailsThunk);
};

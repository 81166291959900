'use strict';

const { getLocalModeQueryString } = require('@sb-itops/business-logic').infra.services;

const allowedModifiers = {
  buttonColour: true,
  forwardChargeData: true,
  parentOrigin: true,
};

/**
 * Constructs a querystring for use in the payment portal URL.
 * The querystring is constructed from a modifiers object. Each modifier has a different effect on the payment portal.
 * The region is also provided so that local environments have the correct regional querystring added locally.
 *
 * @param {Object} params
 * @param {string} params.region
 * @param {Object} params.modifiers
 *
 * @returns {string}
 */
const buildPaymentPortalQuerystring = ({ region, modifiers = {} }) => {
  const whitelistedModifiers = Object.entries(modifiers).reduce((acc, [key, value]) => {
    if (allowedModifiers[key]) {
      acc[key] = value;
    }

    return acc;
  }, {});

  const searchParameters = new URLSearchParams(whitelistedModifiers);

  if (process.env.SB_IS_RUNNING_LOCALLY) {
    const localRegionQueryString = getLocalModeQueryString(region);
    if (localRegionQueryString) {
      searchParameters.append(localRegionQueryString, '');
    }
  }

  const queryString = searchParameters.toString();
  if (queryString.length) {
    return `?${queryString}`;
  }

  return '';
};

/**
 * getMerchantPaymentUrl
 * Returns a URL which can be used to make a payment via the payment portal.
 *
 * @param {object} params
 * @param {object} [params.host] The payment portal host base URL. Defaults to value of environment variable PAYMENT_PORTAL_HOST.
 * @param {object} params.merchantPaymentReference The reference id associated with the charge request.
 * @param {object} params.modifiers An object of payment portal behaviour modifiers that will be passed through as querystrings.
 * @returns {string}
 */
const getMerchantPaymentUrl = ({
  host = process.env.PAYMENT_PORTAL_HOST,
  merchantPaymentReference,
  region,
  modifiers,
}) => {
  const queryString = buildPaymentPortalQuerystring({ region, modifiers });
  return `${host}/${queryString}#/payment/${merchantPaymentReference}`;
};

module.exports = {
  getMerchantPaymentUrl,
};

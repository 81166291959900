import { RESET_ALL_REDUX_DATA, RESET_NON_PERSISTED_REDUX_DATA } from '@sb-itops/redux';
import * as types from './types';

export const initState = {
  bootstrapStatus: types.bootstrapStatuses.PRE_AUTH_REQUIRED,
  authorisationSuccessful: false,
  authorisationFailureType: undefined,
  serverVersion: undefined,
  isStorageQuotaSufficient: true,
  isUserDataClearing: false,
  loginFailureMessage: undefined,
  owningCompanyInsecure: undefined,
  loginFormSubheader: undefined,
  persisted: {
    // Everything in this object will be persisted and replenished on reload of the app.
    isFileManagerInstalled: false,
    isPrivateComputer: false,
    lastLoggedInUser: '',
    loginBlockedReason: undefined,
    mainMenuExpansionState: types.mainMenuExpansionStates.NEVER_EXPANDED,
  },
  lastAction: { type: '' },
};

export const reducer = (currentState = initState, action = {}) => {
  const state = { ...currentState, lastAction: action };
  switch (action.type) {
    case types.SET_AUTHORISATION_RESULT:
      return {
        ...state,
        authorisationSuccessful: action.payload.successful,
        authorisationFailureType: action.payload.failureType,
        userPermissions: action.payload.userPermissions,
      };

    case types.SET_STORAGE_QUOTA_CALCULATED:
      return {
        ...state,
        isStorageQuotaSufficient: action.payload.isQuotaSufficient,
      };

    case types.SET_SERVER_VERSION:
      return {
        ...state,
        serverVersion: action.payload.serverVersion,
      };
    case types.SET_FILE_MANAGER_INSTALLED:
      return {
        ...state,
        persisted: {
          ...state.persisted,
          isFileManagerInstalled: action.payload.isFileManagerInstalled,
        },
      };

    case types.SET_USER_DATA_CLEARING:
      return {
        ...state,
        isUserDataClearing: action.payload.isClearing,
      };

    case types.SET_MAIN_MENU_EXPANSION_STATE:
      return {
        ...state,
        persisted: {
          ...state.persisted,
          mainMenuExpansionState: action.payload.mainMenuExpansionState,
        },
      };

    case types.SET_PRIVATE_COMPUTER:
      return {
        ...state,
        persisted: {
          ...state.persisted,
          isPrivateComputer: action.payload.isPrivateComputer,
        },
      };

    case types.SET_LAST_LOGGED_IN_USER:
      return {
        ...state,
        persisted: {
          ...state.persisted,
          lastLoggedInUser: action.payload.username,
        },
      };

    case types.SET_LOGIN_FAILURE_MESSAGE:
      return {
        ...state,
        loginFailureMessage: action.payload.message ? action.payload : undefined,
      };

    case types.SET_OWNING_COMPANY_INSECURE:
      return {
        ...state,
        owningCompanyInsecure: action.payload.owningCompanyInsecure,
      };

    case types.SET_LOGIN_BLOCKED_REASON:
      return {
        ...state,
        persisted: {
          ...state.persisted,
          loginBlockedReason: action.payload.reason ? action.payload.reason : undefined,
        },
      };

    case types.SET_LOGIN_FORM_SUBHEADER:
      return {
        ...state,
        loginFormSubheader: action.payload.subheader ? action.payload.subheader : undefined,
      };

    case RESET_NON_PERSISTED_REDUX_DATA:
    case RESET_ALL_REDUX_DATA:
      return {
        ...initState,
        persisted: state.persisted,
      };

    default:
      return state;
  }
};

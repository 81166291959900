import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, forms2PropTypes } from '@sb-itops/react';
import { StatelessSelect } from '@sb-itops/react/select';
import { featureActive } from '@sb-itops/feature';

import Styles from './EditBillingUnitForm.module.scss';

export const EditBillingUnitForm = ({
  billingUnitOptions,
  formDirty,
  formInitialised,
  formValid,
  formSubmitting,
  // form fields
  customUnit,
  selectedBillingUnit,
  // callbacks
  onFieldValueUpdated,
  onSave,
}) => {
  if (!formInitialised) {
    return null;
  }

  return (
    <div className={classnames(Styles.editBillingUnitForm)}>
      <form name="EditBillingUnitForm">
        <div className="row">
          <div className="col-sm-2">
            <label htmlFor="feeInterval">Round time entries to</label>
          </div>
          {selectedBillingUnit.value === 'Custom' && (
            <div className="col-sm-1">
              <label htmlFor="customUnits">Minutes</label>
            </div>
          )}
        </div>
        <div className={classnames('row', Styles.unitRow)}>
          <div className="col-sm-2">
            <StatelessSelect
              onChange={(billingUnit) => {
                onFieldValueUpdated('selectedBillingUnit', billingUnit.value);
                onFieldValueUpdated('customUnit', null);
              }}
              options={billingUnitOptions}
              selectedOption={selectedBillingUnit.value}
            />
          </div>
          {selectedBillingUnit.value === 'Custom' && (
            <div className={classnames('col-sm-1', Styles.customUnit)}>
              <input
                type="number"
                min="1"
                max="60"
                className={classnames('form-control', !formValid ? Styles.hasError : '')}
                value={customUnit.value || ''}
                step={1}
                name="customUnits"
                onChange={(e) => {
                  onFieldValueUpdated('customUnit', e.target.value);
                }}
              />
              {!formValid && customUnit.value && <span className={Styles.customUnitError}>Valid: 1 to 60</span>}
            </div>
          )}
          <div className="col-sm-1">
            <Button
              onClick={() => onSave({ selectedBillingUnit: selectedBillingUnit.value, customUnit: customUnit.value })}
              disabled={
                formSubmitting ||
                !formDirty ||
                !formValid ||
                (!featureActive('BB-10195') && selectedBillingUnit.value === 'Custom')
              }
            >
              Save
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

const { Forms2Field } = forms2PropTypes;

EditBillingUnitForm.propTypes = {
  billingUnitOptions: PropTypes.array,
  formDirty: PropTypes.bool,
  formInitialised: PropTypes.bool,
  formValid: PropTypes.bool,
  formSubmitting: PropTypes.bool,
  // form fields
  customUnit: Forms2Field,
  selectedBillingUnit: Forms2Field,
  // callbacks
  onFieldValueUpdated: PropTypes.func,
  onSave: PropTypes.func,
};

EditBillingUnitForm.defaultProps = {
  billingUnitOptions: [],
  formDirty: false,
  formInitialised: false,
  formValid: true,
  formSubmitting: false,
  // form fields
  customUnit: undefined,
  selectedBillingUnit: undefined,
  // callbacks
  onFieldValueUpdated: undefined,
  onSave: undefined,
};

EditBillingUnitForm.displayName = 'EditBillingUnitForm';

import { Button, FloatingCard } from '@sb-itops/react';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import React, { useState } from 'react';
import { Cross, MatterIcon2, PlusFF } from 'web/components/icons/custom';
import * as messageDisplay from '@sb-itops/message-display';
import { getRegion } from '@sb-itops/region';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { getLogger } from '@sb-itops/fe-logger';
import { isUtbmsEnabled } from '@sb-billing/redux/utbms-settings';
import { contactTypes } from '@sb-customer-management/business-logic/contacts/entities';
import classNames from 'classnames';
import { CreateEditContactForm } from '../contact-create-edit';
import * as Styles from './Wizard.module.scss';
import { CreateEditContactSchema } from '../contact-create-edit/CreateEditContactSchema';

interface IWizardProps {
  scope: string;
  title: string;
  wizardStepSelector?: 'right';
  onSubmit: (event: React.MouseEvent<Element, MouseEvent>) => void;
  onClose: () => void;
  steps: {
    label: string;
    step: (form: any) => React.JSX.Element;
    canSave?: boolean;
    showAddContact?: boolean;
    validation: (formFields: any) => boolean;
  }[];
}

const createContactViaDispatcher = async (formFields) => {
  if (![contactTypes.PERSON, contactTypes.COMPANY, contactTypes.TRUST].includes(formFields.contactType)) {
    throw new Error(`Unsupported contact type '${formFields.contactType}'`);
  }

  let type = 'Integration.CreateCompanyContact';
  if (formFields.contactType === contactTypes.PERSON) {
    type = 'Integration.CreatePersonContact';
  } else if (formFields.contactType === contactTypes.TRUST) {
    type = 'Integration.CreateTrustContact';
  }

  const message = { isUtbmsEnabled: isUtbmsEnabled(), contactFields: formFields };

  const dispatchResult = await dispatchCommand({ type, message });
  return dispatchResult.contact.id;
};

const log = getLogger('Wizard');
const CONTACT_SCOPE = 'wizard-contact';

export const Wizard = (props: IWizardProps) => {
  const form = useForm({ scope: props.scope });
  const contactForm = useForm({ scope: CONTACT_SCOPE, schema: CreateEditContactSchema(getRegion()) });
  const [currentStep, setCurrentStep] = useState(0);
  const [addingContact, setAddingContact] = useState(false);

  let body;
  if (addingContact) {
    body = (
      <div className={Styles.body}>
        <div className={Styles.addContact}>
          <CreateEditContactForm isNewUI scope={CONTACT_SCOPE} />
        </div>
      </div>
    );
  } else {
    body = (
      <div className={Styles.body}>
        <div className={Styles.stepDetails}>
          <FloatingCard hideShadow applyMargin={false} classname={Styles.card}>
            <div className={Styles.stepHeading}>
              <div className={Styles.stepTitle}>{props.steps[currentStep].label}</div>
              {props.steps[currentStep].showAddContact && (
                <Button onClick={() => setAddingContact(true)} borderless type="secondary">
                  <span className={Styles.addContactButton}>
                    <PlusFF /> Add Contact
                  </span>
                </Button>
              )}
            </div>
            <div className={Styles.stepBody}>{props.steps[currentStep].step(form)}</div>
          </FloatingCard>
        </div>
        <div className={Styles.selector}>
          {props.steps.map((step, index) => {
            let isStepDisabled = false;
            for (let i = 0; i < index; i += 1) {
              if (!props.steps[i].validation(form.formValues)) {
                isStepDisabled = true;
                break;
              }
            }

            return (
              <div
                className={classNames(Styles.selectorItem, isStepDisabled && Styles.itemDisabled)}
                key={step.label}
                onClick={() => {
                  if (!isStepDisabled) {
                    setCurrentStep(index);
                  }
                }}
              >
                <div className={index === currentStep ? Styles.activeGuard : Styles.selectorGuard} />
                <div className={index === currentStep ? Styles.activeText : Styles.selectorText}>{`${index + 1}. ${
                  step.label
                }`}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div className={Styles.wizard}>
      <div className={Styles.titleBar}>
        <div className={Styles.matterIcon}>
          <MatterIcon2 />
        </div>
        <div className={Styles.heading}>{addingContact ? 'New Contact' : props.title}</div>
        <div onClick={props.onClose} style={{ marginLeft: 'auto', cursor: 'pointer' }}>
          <Cross />
        </div>
      </div>
      <div className={Styles.mainForm}>
        {body}
        <div className={Styles.footer}>
          {addingContact && (
            <Button onClick={() => setAddingContact(false)} type="tertiary">
              Back
            </Button>
          )}

          {currentStep + 1 !== props.steps.length && !addingContact && (
            <Button
              disabled={!props.steps[currentStep].validation(form.formValues)}
              onClick={() => setCurrentStep(currentStep + 1)}
              type="secondary"
            >
              Next
            </Button>
          )}
          {!!props.steps[currentStep].canSave && !addingContact && (
            <Button
              disabled={!props.steps[currentStep].validation(form.formValues) || form.formSubmitting}
              onClick={props.onSubmit}
            >
              Create Matter
            </Button>
          )}
          {addingContact && (
            <Button
              disabled={contactForm.formSubmitting}
              onClick={async (event) => {
                event.preventDefault();
                await contactForm.onValidateForm();
                try {
                  await contactForm.onSubmitFormWithValidation({
                    submitFnP: async (formFields) => {
                      await createContactViaDispatcher(formFields);
                      setAddingContact(false);
                    },
                  });
                } catch (err) {
                  log.error(err);
                  messageDisplay.error('Failed to save contact');
                }
              }}
            >
              Create Contact
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

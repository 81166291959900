import React from 'react';
import PropTypes from 'prop-types';
import { TasksTabContainer } from 'web/containers';

const getScope = ({ matterId }) => `view-matter-tasks-route-${matterId}`;

export const ViewMatterTasksRouteContainer = ({ matterId }) => (
  <TasksTabContainer scope={getScope({ matterId })} matterId={matterId} />
);

ViewMatterTasksRouteContainer.displayName = 'ViewMatterTasksRouteContainer';

ViewMatterTasksRouteContainer.propTypes = {
  matterId: PropTypes.string.isRequired,
};

ViewMatterTasksRouteContainer.defaultProps = {};

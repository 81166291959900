import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { activityCategories } from '@sb-billing/business-logic/activities/entities/constants';
import { entryType as activityEntryTypes } from '@sb-billing/business-logic/shared/entities';
import { featureActive } from '@sb-itops/feature';
import { capitalize } from '@sb-itops/nodash';
import { Button, PaginationSelector, PreviewPdfModal, useTranslation } from '@sb-itops/react';
import { ExpenseFilter, ExpenseTable, QuickExpenseEntry, CreateOperatingChequeModal } from 'web/components';

import Styles from './FirmExpenseEntries.module.scss';

export const FirmExpenseEntries = ({
  region,
  // Ribbon
  onNewExpense,
  printChequeCount,
  onOpenPrintChequeModal,
  onViewOperatingCheques,
  createChequeButtonEnabled,
  isCreateOperatingChequeModalVisible,
  onCreateChequeClick,
  exportFileUrl,
  isGeneratingPDFReport,
  onCloseExportModal,
  onGenerateCSVReport,
  onGeneratePDFReport,

  // Quick add
  scopeQuickAdd,
  activities,
  tasks,
  utbmsEnabledForFirm,
  utbmsCodesRequiredByFirm,
  staffMemberOptions,
  registeredForGst,
  taxRateBasisPoints,
  matterSummaries,
  matterSummariesDataLoading,
  matterSummariesHasMore,
  onFetchMatterSummaries,
  onFetchMoreMatterSummaries,

  // Filters
  filterScope,
  filterDateRange,
  onUpdateFilterDateRange,
  loggedInStaff,
  staffMembers,
  staffIds,
  onSelectStaffIds,
  anticipatedDisbursementsEnabled,
  expenseStatusCounts,
  expenseStatusCountsLoading,

  // Table data
  showAnticipatedDisbursementColumn,
  showChequeCreateSelectColumn,
  showMatterColumn,
  showTaskColumn,
  showTaxColumns,
  selectedExpenseIds,
  expenseDataLoading,
  expenses,
  sortBy,
  sortDirection,
  onSort,
  onClickLink,
  onDeselectAllExpenses,
  onOpenExpenseModal,
  onOpenChequeModal,
  onPrintCheque,
  onToggleExpenses,

  // Pagination
  hidePagination,
  currentExpensePage,
  totalNumberOfExpensePages,
  onExpenseListPageChange,

  // Other
  sbAsyncOperationsService,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classnames('panel-body', Styles.firmExpenseEntries)}>
      <div className="ribbon panel">
        <Button onClick={onNewExpense}>New {t('expense')} Entry</Button>
        <Button onClick={onOpenPrintChequeModal}>
          Print {t('cheques')} ({printChequeCount})
        </Button>
        <Button onClick={onViewOperatingCheques}>View {t('operatingCheques')}</Button>
        {showChequeCreateSelectColumn && (
          <>
            <Button onClick={onCreateChequeClick} disabled={!createChequeButtonEnabled}>
              Create {capitalize(t('cheque'))}
            </Button>
            {isCreateOperatingChequeModalVisible && (
              <CreateOperatingChequeModal
                expenseIds={Object.keys(selectedExpenseIds)}
                onClearSelectedExpenses={onDeselectAllExpenses}
                sbAsyncOperationsService={sbAsyncOperationsService}
              />
            )}
          </>
        )}
        {featureActive('BB-10347') && (
          <>
            <div className={Styles.btnSpacer} />
            <Button className={Styles.download} onClick={onGeneratePDFReport} locked={isGeneratingPDFReport}>
              Print
            </Button>
            <Button className={Styles.download} onClick={onGenerateCSVReport}>
              CSV
            </Button>
            <PreviewPdfModal isVisible={!!exportFileUrl} url={exportFileUrl} onClose={onCloseExportModal} />
          </>
        )}
      </div>
      <div className={Styles.quickExpenseEntrySection}>
        <QuickExpenseEntry
          scope={scopeQuickAdd}
          region={region}
          matterSummaries={matterSummaries}
          matterSummariesDataLoading={matterSummariesDataLoading}
          matterSummariesHasMore={matterSummariesHasMore}
          onFetchMatterSummaries={onFetchMatterSummaries}
          onFetchMoreMatterSummaries={onFetchMoreMatterSummaries}
          activities={activities}
          tasks={tasks}
          showMatterField
          showTasksField={utbmsEnabledForFirm}
          utbmsCodesRequiredByFirm={utbmsCodesRequiredByFirm}
          staffMemberOptions={staffMemberOptions}
          loggedInStaff={loggedInStaff}
          registeredForGst={registeredForGst}
          taxRateBasisPoints={taxRateBasisPoints}
        />
      </div>
      <div className={Styles.expenseListSection}>
        <ExpenseFilter
          filterScope={filterScope}
          filterDateRange={filterDateRange}
          onUpdateFilterDateRange={onUpdateFilterDateRange}
          loggedInStaff={loggedInStaff}
          staffMembers={staffMembers}
          staffIds={staffIds}
          onSelectStaffIds={onSelectStaffIds}
          anticipatedDisbursementsEnabled={anticipatedDisbursementsEnabled}
          expenseStatusCounts={expenseStatusCounts}
          expenseStatusCountsLoading={expenseStatusCountsLoading}
        />

        <div className={Styles.expenseTableSection}>
          <ExpenseTable
            selectedExpenseIds={selectedExpenseIds}
            currentExpensePage={currentExpensePage}
            dataLoading={expenseDataLoading}
            expenses={expenses}
            showAnticipatedDisbursementColumn={showAnticipatedDisbursementColumn}
            showChequeCreateSelectColumn={showChequeCreateSelectColumn}
            showMatterColumn={showMatterColumn}
            showTaskColumn={showTaskColumn}
            showTaxColumns={showTaxColumns}
            sortBy={sortBy}
            sortDirection={sortDirection}
            totalNumberOfExpensePages={totalNumberOfExpensePages}
            onClickLink={onClickLink}
            onOpenChequeModal={onOpenChequeModal}
            onOpenExpenseModal={onOpenExpenseModal}
            onExpenseListPageChange={onExpenseListPageChange}
            onSort={onSort}
            onPrintCheque={onPrintCheque}
            onToggleExpenses={onToggleExpenses}
          />
          <PaginationSelector
            name="firmExpense"
            className={Styles.paginationSection}
            hidePagination={hidePagination}
            numberOfPagesDisplayed={10}
            selectedPage={currentExpensePage}
            totalNumberOfPages={totalNumberOfExpensePages}
            onPageChange={onExpenseListPageChange}
          />
        </div>
      </div>
    </div>
  );
};

FirmExpenseEntries.displayName = 'FirmExpenseEntries';

const StaffEntityType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  initials: PropTypes.string.isRequired,
  isFormerStaff: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  rate: PropTypes.number.isRequired,
});

const StaffMemberOptionType = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  entity: StaffEntityType,
});

FirmExpenseEntries.propTypes = {
  region: PropTypes.string.isRequired,

  // Ribbon
  onNewExpense: PropTypes.func.isRequired,
  printChequeCount: PropTypes.number.isRequired,
  onOpenPrintChequeModal: PropTypes.func.isRequired,
  onViewOperatingCheques: PropTypes.func.isRequired,
  createChequeButtonEnabled: PropTypes.bool,
  isCreateOperatingChequeModalVisible: PropTypes.bool.isRequired,
  onCreateChequeClick: PropTypes.func.isRequired,
  exportFileUrl: PropTypes.string,
  isGeneratingPDFReport: PropTypes.bool.isRequired,
  onCloseExportModal: PropTypes.func.isRequired,
  onGenerateCSVReport: PropTypes.func.isRequired,
  onGeneratePDFReport: PropTypes.func.isRequired,

  // Quick add
  scopeQuickAdd: PropTypes.string.isRequired,
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      isBillable: PropTypes.bool.isRequired,
      type: PropTypes.oneOf(Object.values(activityEntryTypes)),
      units: PropTypes.number,
      isTaxInclusive: PropTypes.bool,
      isTaxExempt: PropTypes.bool,
      rateOverrideType: PropTypes.oneOf([0, 1, 2, undefined]),
      allStaffRate: PropTypes.number,
      ratesPerStaff: PropTypes.arrayOf(
        PropTypes.shape({
          staffId: PropTypes.string.isRequired,
          rate: PropTypes.number.isRequired,
        }).isRequired,
      ),
      description: PropTypes.string.isRequired,
      category: PropTypes.oneOf(Object.values(activityCategories)),
    }).isRequired,
  ).isRequired,
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  utbmsCodesRequiredByFirm: PropTypes.bool.isRequired,
  utbmsEnabledForFirm: PropTypes.bool.isRequired,
  registeredForGst: PropTypes.bool,
  taxRateBasisPoints: PropTypes.number,
  matterSummaries: PropTypes.arrayOf(
    PropTypes.shape({
      // Fields used in MatterTypeahead
      id: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      typeahead: PropTypes.string.isRequired,
      clientDisplay: PropTypes.string,
      otherSideDisplay: PropTypes.string,
      matterStarted: PropTypes.instanceOf(Date).isRequired,
      matterStartedISO: PropTypes.string.isRequired,
      matterNumber: PropTypes.string,
      // Fields required to create a fee
      billingConfiguration: PropTypes.shape({
        isUtbmsEnabled: PropTypes.bool.isRequired,
        billingType: PropTypes.string,
      }),
      matterType: PropTypes.shape({
        name: PropTypes.string,
      }),
    }).isRequired,
  ).isRequired,
  matterSummariesDataLoading: PropTypes.bool,
  matterSummariesHasMore: PropTypes.bool,
  onFetchMatterSummaries: PropTypes.func,
  onFetchMoreMatterSummaries: PropTypes.func,
  staffMemberOptions: PropTypes.arrayOf(StaffMemberOptionType),

  // Filters
  filterScope: PropTypes.string.isRequired,
  filterDateRange: PropTypes.shape({
    from: PropTypes.number,
    to: PropTypes.number,
  }),
  onUpdateFilterDateRange: PropTypes.func.isRequired,
  loggedInStaff: PropTypes.shape({
    id: PropTypes.string,
  }),
  staffMembers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      initials: PropTypes.string,
    }),
  ).isRequired,
  staffIds: PropTypes.arrayOf(PropTypes.string),
  onSelectStaffIds: PropTypes.func.isRequired,
  anticipatedDisbursementsEnabled: PropTypes.bool.isRequired,
  expenseStatusCounts: PropTypes.shape({
    disbursements: PropTypes.number,
    anticipatedDisbursements: PropTypes.number,
    anticipatedDisbursementsUnpaid: PropTypes.number,
    anticipatedDisbursementsOverdue: PropTypes.number,
    anticipatedDisbursementsPaid: PropTypes.number,
  }),
  expenseStatusCountsLoading: PropTypes.bool.isRequired,

  // Table data
  expenseDataLoading: PropTypes.bool.isRequired,
  expenses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      expenseDate: PropTypes.number,
      expenseEarnerStaff: PropTypes.shape({
        id: PropTypes.string,
        initials: PropTypes.string,
      }),
      matter: PropTypes.object,
      activityCode: PropTypes.string,
      utbmsTaskCode: PropTypes.string,
      description: PropTypes.string,
      duration: PropTypes.number,
      rate: PropTypes.number,
      amount: PropTypes.number,
      isBillable: PropTypes.oneOf([true, false, null]),
      invoiceNumber: PropTypes.string,
      amountExclTax: PropTypes.number,
      amountIncTax: PropTypes.number,
      tax: PropTypes.number,
    }),
  ).isRequired,
  selectedExpenseIds: PropTypes.object,
  showAnticipatedDisbursementColumn: PropTypes.bool,
  showChequeCreateSelectColumn: PropTypes.bool.isRequired,
  showMatterColumn: PropTypes.bool.isRequired,
  showTaskColumn: PropTypes.bool,
  showTaxColumns: PropTypes.bool.isRequired,
  sortBy: PropTypes.oneOf([
    'expenseDate',
    'expenseEarnerStaff',
    'activityCode',
    'utbmsTaskCode',
    'matter',
    'description',
    'isBillable',
    'invoiceNumber',
    'tax',
  ]).isRequired,
  sortDirection: PropTypes.oneOf(['asc', 'desc']).isRequired,
  onClickLink: PropTypes.func.isRequired,
  onDeselectAllExpenses: PropTypes.func.isRequired,
  onOpenChequeModal: PropTypes.func.isRequired,
  onOpenExpenseModal: PropTypes.func.isRequired,
  onPrintCheque: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  onToggleExpenses: PropTypes.func.isRequired,

  // Pagination
  hidePagination: PropTypes.bool.isRequired,
  currentExpensePage: PropTypes.number.isRequired,
  totalNumberOfExpensePages: PropTypes.number.isRequired,
  onExpenseListPageChange: PropTypes.func.isRequired,

  // Other
  sbAsyncOperationsService: PropTypes.object.isRequired,
};

FirmExpenseEntries.defaultProps = {
  exportFileUrl: '',
  createChequeButtonEnabled: undefined,
  registeredForGst: undefined,
  taxRateBasisPoints: undefined,
  matterSummariesDataLoading: undefined,
  matterSummariesHasMore: undefined,
  onFetchMatterSummaries: undefined,
  onFetchMoreMatterSummaries: undefined,
  filterDateRange: undefined,
  loggedInStaff: undefined,
  staffIds: undefined,
  expenseStatusCounts: undefined,
  selectedExpenseIds: {},
  showAnticipatedDisbursementColumn: undefined,
  showTaskColumn: undefined,
  staffMemberOptions: undefined,
};

import * as React from 'react';
import PropTypes from 'prop-types';

import { Select } from '@sb-itops/react';

import Styles from './ContactTypeahead.module.scss';

/**
 * LOD compatible ContactTypeahead
 */
export const ContactTypeahead = ({
  className,
  contactOptions,
  contactOptionsHasMore,
  defaultContactId,
  defaultContactOptions,
  disabled,
  hasError,
  isClearable,
  isLoading,
  maxMenuHeight,
  menuPlacement,
  optionalLabelHeight,
  placeholder,
  // Callbacks
  onContactSelected,
  onFetchContactOptions,
  onFetchMoreContactOptions,
}) => {
  const defaultOption = React.useMemo(
    () => defaultContactOptions.find((option) => option.value === defaultContactId),
    [defaultContactId, defaultContactOptions],
  );

  const actionList =
    contactOptionsHasMore === true
      ? [
          {
            displayComponent: (
              <span>
                <i className="fa fa-plus" /> &emsp;Show more results
              </span>
            ),
            callback: () => {
              if (isLoading) {
                return;
              }

              onFetchMoreContactOptions();
            },
          },
        ]
      : [];

  return (
    <div className={Styles.contactTypeaheadContainer}>
      <Select
        actionList={actionList}
        className={className}
        defaultValue={defaultOption}
        disabled={disabled}
        filterOption={() => true} // Show all values, we use isSearchable to send requests to the back-end and need to show all the options provided
        hasError={hasError}
        isClearable={isClearable}
        isLoading={isLoading}
        isSearchable
        maxMenuHeight={maxMenuHeight}
        menuPlacement={menuPlacement}
        noOptionsMessage={({ inputValue }) =>
          inputValue?.length ? 'No options' : 'Start typing to get a list of contacts...'
        }
        optionalLabelHeight={optionalLabelHeight}
        options={contactOptions}
        placeholder={placeholder}
        virtualizeList
        // Callbacks
        onInputChange={onFetchContactOptions}
        onLoadMore={onFetchMoreContactOptions}
        onValueChange={onContactSelected}
      />
    </div>
  );
};

ContactTypeahead.displayName = 'ContactTypeahead';

const contactOption = PropTypes.shape({
  data: PropTypes.object,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

ContactTypeahead.propTypes = {
  className: PropTypes.string,
  contactOptions: PropTypes.arrayOf(contactOption),
  contactOptionsHasMore: PropTypes.bool,
  defaultContactId: PropTypes.string,
  defaultContactOptions: PropTypes.arrayOf(contactOption),
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  isClearable: PropTypes.bool,
  isLoading: PropTypes.bool,
  maxMenuHeight: PropTypes.number,
  menuPlacement: PropTypes.oneOf(['bottom', 'top', 'auto']),
  optionalLabelHeight: PropTypes.number,
  placeholder: PropTypes.string,
  // Callbacks
  onContactSelected: PropTypes.func.isRequired,
  onFetchContactOptions: PropTypes.func.isRequired,
  onFetchMoreContactOptions: PropTypes.func.isRequired,
};

ContactTypeahead.defaultProps = {
  className: undefined,
  contactOptions: [],
  contactOptionsHasMore: false,
  defaultContactId: undefined,
  defaultContactOptions: [],
  disabled: false,
  hasError: false,
  isClearable: true,
  isLoading: false,
  maxMenuHeight: undefined,
  menuPlacement: 'auto',
  optionalLabelHeight: 70,
  placeholder: 'Select a contact...',
};

/**
 * NOTICE:
 *
 * This is a Load on Demand compatible route container, meaning that neither
 * this container nor any of the sub-component/containers should have a
 * dependency on:
 * - Entity caches
 * - Angular services
 */
import PropTypes from 'prop-types';
import { entryType as entryTypeEnum } from '@sb-billing/business-logic/shared/entities';
import composeHooks from '@sb-itops/react-hooks-compose';
import { facets, hasFacet } from '@sb-itops/region-facets';
import { getRegion } from '@sb-itops/region';
import billingBusinessLogic from '@sb-billing/business-logic';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';

import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { useCacheQuery, useSubscribedQuery } from 'web/hooks';
import { FinalisedInvoiceEntries as FinalisedInvoiceEntriesQuery, InitFirmTaxSettings } from 'web/graphql/queries';
import { EXPENSE_MODAL_ID, FEE_MODAL_ID } from 'web/components';
import { featureActive } from '@sb-itops/feature';

import { FinalisedInvoiceEntries } from './FinalisedInvoiceEntries';

const REGION = getRegion();
const { calculateFeeAmountTax } = billingBusinessLogic.fee.services;
const { getExcludingTaxAmount, getIncludingTaxAmount, getBillableOutputTax } = billingBusinessLogic.expense.services;

const SCOPE = 'FinalisedInvoicePageEntries';

function deriveFeeHoursColumnValue({ fee }) {
  if (fee.feeType === entryTypeEnum.FIXED) {
    return 'FIXED';
  }

  const durationInHours = (fee.duration / 60).toFixed(2);
  return durationInHours;
}

const hooks = ({ invoiceId }) => ({
  useFinalisedInvoiceEntriesData: () => {
    const { data: firmTaxSettingsData } = useCacheQuery(InitFirmTaxSettings.query);

    const { taxRate: firmTaxRateBasisPoints } = firmTaxSettingsData?.firmTaxSettings || {};

    // Fetch invoice entries
    const finalisedInvoiceEntriesQueryResult = useSubscribedQuery(FinalisedInvoiceEntriesQuery, {
      variables: {
        id: invoiceId,
      },
    });

    if (finalisedInvoiceEntriesQueryResult.error) {
      throw new Error(finalisedInvoiceEntriesQueryResult.error);
    }

    const invoice = finalisedInvoiceEntriesQueryResult?.data?.invoice;
    const entries = invoice?.entries || [];
    // Legacy comment (BB-6008):
    // this tax rate is only really needed if for whatever reason tax/billableTax for a fee is not set
    // e.g. billableTax is only introduced as part of BB-5548 non-billable grouped fees, so old fee records
    // would not have this field. Also invoice.feeTaxRate should be available in most invoices, but in case
    // it's not, the firm tax rate is fetched instead.
    const taxRate = invoice?.feeTaxRate ?? firmTaxRateBasisPoints;

    // Prepare fees and expenses table:
    // 1. Row data
    // 2. Totals
    const { expensesTableRows, expensesTotals, feesTableRows, feesTotals } = entries.reduce(
      (acc, entry) => {
        const fee = entry.feeEntity;
        const expense = entry.expenseEntity;

        if (fee) {
          const { billableAmountExclTax, billableTax, billableAmountInclTax, writtenOffAmountExclTax, writtenOffTax } =
            calculateFeeAmountTax({ fee, taxRate, region: REGION, invoice });
          const isFeeFullyOrPartiallyBillable = fee.isBillable || fee.isBillable === null;

          // Fee row data
          const feeRow = {
            amount: isFeeFullyOrPartiallyBillable ? billableAmountExclTax : 0,
            date: fee.feeDate,
            feeEarnerStaffName: fee.feeEarnerStaff.name,
            hours: deriveFeeHoursColumnValue({ fee }),
            id: fee.id,
            rate: isFeeFullyOrPartiallyBillable ? fee.rate : undefined,
            subject: fee.description,
            tax: isFeeFullyOrPartiallyBillable ? billableTax : 0,
            total: isFeeFullyOrPartiallyBillable ? billableAmountInclTax : 0,
            waived: fee.waived,
          };
          acc.feesTableRows.push(feeRow);

          // Fee totals
          if (feeRow.hours !== 'FIXED') {
            acc.feesTotals.hours += +(fee.duration / 60).toFixed(2);
          }
          acc.feesTotals.amount += billableAmountExclTax;
          acc.feesTotals.tax += billableTax;
          acc.feesTotals.total += billableAmountInclTax;
          acc.feesTotals.amountWrittenOff += writtenOffAmountExclTax;
          acc.feesTotals.taxWrittenOff += writtenOffTax;
        }

        if (expense) {
          const amount = getExcludingTaxAmount(expense);
          const tax = getBillableOutputTax(expense);
          const total = getIncludingTaxAmount(expense);
          const isNonBillableOrWaived = !expense.isBillable || expense.waived;

          const billableAmount = isNonBillableOrWaived ? 0 : amount;
          const billableTax = isNonBillableOrWaived ? 0 : tax;
          const billableTotal = isNonBillableOrWaived ? 0 : total;

          // Expense row data
          const expenseRow = {
            amount: billableAmount,
            date: expense.expenseDate,
            id: expense.id,
            price: expense.isBillable ? expense.price : undefined,
            quantity: (Math.abs(expense.quantity || 0) / 100).toFixed(2),
            subject: expense.description,
            tax: billableTax,
            total: billableTotal,
            waived: expense.waived,
            // Show the waived value as the displayed column value
            waivedAmount: expense.waived ? amount : 0,
            waivedTax: expense.waived ? tax : 0,
            waivedTotal: expense.waived ? total : 0,
          };

          acc.expensesTableRows.push(expenseRow);

          // Expense totals
          acc.expensesTotals.amount += billableAmount;
          acc.expensesTotals.tax += billableTax;
          acc.expensesTotals.total += billableTotal;
        }

        return acc;
      },
      {
        feesTableRows: [],
        feesTotals: {
          amount: 0,
          amountWrittenOff: 0,
          hours: 0,
          tax: 0,
          taxWrittenOff: 0,
          total: 0,
        },
        expensesTableRows: [],
        expensesTotals: {
          amount: 0,
          tax: 0,
          total: 0,
        },
      },
    );

    // Fee table totals
    const totalFeesAmount = feesTotals.amount - feesTotals.amountWrittenOff;
    const totalFeesTax = feesTotals.tax - feesTotals.taxWrittenOff;
    const totalFeesAmountInclTax = totalFeesAmount + totalFeesTax;
    const feesTableTotals = {
      amount: totalFeesAmount,
      hours: feesTotals.hours.toFixed(2),
      tax: totalFeesTax,
      total: totalFeesAmountInclTax,
    };
    // Expense table totals
    const expensesTableTotals = {
      amount: expensesTotals.amount,
      tax: expensesTotals.tax,
      total: expensesTotals.total,
    };

    const finalisedInvoiceEntriesTableData = {
      feesTableRows,
      feesTableTotals,
      expensesTableRows,
      expensesTableTotals,
    };

    return {
      ...finalisedInvoiceEntriesTableData,
      invoiceNumber: invoice?.invoiceNumber,
      loading: finalisedInvoiceEntriesQueryResult?.loading,
    };
  },
});

const dependentHooks = ({
  sbAsyncOperationsService,
  expensesTableRows,
  feesTableRows,
  invoiceId,
  invoiceNumber,
  // Callbacks
  onClickLink,
  onOpenExpenseModal,
}) => ({
  useDisplayContent: () => ({
    invoiceHasFees: feesTableRows.length > 0,
    invoiceHasExpenses: expensesTableRows.length > 0,
    showTax: hasFacet(facets.tax),
  }),
  useOnRowClick: () => {
    const onFeeRowClick = ({ feeId }) => {
      setModalDialogVisible({
        modalId: FEE_MODAL_ID,
        props: {
          feeId,
          scope: `${SCOPE}/fee-modal`,
          onNavigateToInvoice: () => onClickLink({ type: 'invoice', id: invoiceId }),
        },
      });
    };

    const onExpenseRowClick = ({ expenseId }) => {
      // LOD
      if (featureActive('BB-13186')) {
        setModalDialogVisible({
          modalId: EXPENSE_MODAL_ID,
          props: {
            scope: `${SCOPE}/expense-modal`,
            expenseId,
            onClickLink,
            sbAsyncOperationsService,
          },
        });
      } else {
        // Legacy
        onOpenExpenseModal({ expenseId, invoiceNumber });
      }
    };

    return {
      onFeeRowClick,
      onExpenseRowClick,
    };
  },
});

export const FinalisedInvoiceEntriesContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(composeHooks(dependentHooks)(FinalisedInvoiceEntries))),
);

FinalisedInvoiceEntriesContainer.displayName = 'FinalisedInvoiceEntriesContainer';

FinalisedInvoiceEntriesContainer.propTypes = {
  sbAsyncOperationsService: PropTypes.object.isRequired, // LOD Expense modal -> Operating Cheque Print now modal
  invoiceId: PropTypes.string.isRequired,
  // Callbacks
  onClickLink: PropTypes.func.isRequired,
  onOpenExpenseModal: PropTypes.func.isRequired,
};

FinalisedInvoiceEntriesContainer.defaultProps = {};

import { hasFacet, facets } from '@sb-itops/region-facets';
import { depositSlipFilter, transactionFilter } from '@sb-billing/business-logic/bank-reconciliation/transaction-list';
import { integerToDate } from '@sb-itops/date';
import momenttz from 'moment-timezone';
import * as actions from './actions';
import * as selectors from './selectors';

/**
 * Validates selected ids based on end date
 * @param {Object} param
 */
export const untoggleInvalidSelectedIds =
  ({ getTransactionById, getDepositSlipById, getTtoById, endDate, ianaTimezone }) =>
  (dispatch, getState) => {
    if (!getTransactionById || !getDepositSlipById || !getTtoById) {
      throw new Error('untoggleInvalidSelectedIds expects redux functions to be passed in');
    }

    if (!endDate) {
      return;
    }

    const selectedTransactionIds = selectors.getSelectedTransactionIds(getState());
    const selectedDepositSlipIds = selectors.getSelectedDepositSlipIds(getState());
    const selectedTtoIds = selectors.getSelectedTtoIds(getState());

    const filterByEnteredDate = hasFacet(facets.transactionsByEnteredDate);

    const invalidSelectedTransactions = selectedTransactionIds
      .map((id) => getTransactionById(id))
      .filter((txn) => !transactionFilter({ endDate, ianaTimezone }, filterByEnteredDate)(txn));

    const invalidSelectedDepositSlips = selectedDepositSlipIds
      .map((id) => getDepositSlipById(id))
      .filter((ds) => !depositSlipFilter({ endDate, ianaTimezone }, filterByEnteredDate)(ds));

    const invalidSelectedTtos = selectedTtoIds.filter((id) => {
      const tto = getTtoById(id);
      // If it's no longer valid it won't be returned by getTtoById
      if (!tto) {
        return true;
      }
      // Or if it is still being returned we need to check end date
      const endDateISO = momenttz.tz(integerToDate(endDate), ianaTimezone).startOf('day').add(1, 'days').toISOString();
      return filterByEnteredDate ? tto.timestamp > endDateISO : tto.effectiveDate > endDate;
    });

    if (invalidSelectedTransactions.length || invalidSelectedDepositSlips.length || invalidSelectedTtos.length) {
      dispatch(
        actions.toggleItems({
          transactionIds: invalidSelectedTransactions.map((txn) => txn.id),
          depositSlipIds: invalidSelectedDepositSlips.map((ds) => ds.id),
          ttoIds: invalidSelectedTtos,
        }),
      );
    }
  };

import PropTypes from 'prop-types';
import { useState, useEffect, useCallback } from 'react';
import composeHooks from '@sb-itops/react-hooks-compose';
import { loadStripe } from '@stripe/stripe-js';
import { getStripeCorporatePublicKey } from '@sb-itops/environment-config';
import { getRegion } from '@sb-itops/region';
import { getAccountId } from 'web/services/user-session-management';
import { createSetupIntent } from 'web/services/subscription';
import { SubscriptionPaymentFormInStripeElements } from './SubscriptionPaymentFormInStripeElements';

const REGION = getRegion();

const useStripeToCreateSetupIntent = () => {
  const [setupIntent, setSetupIntent] = useState(null);
  const [priceInformation, setPriceInformation] = useState(undefined);

  const createSetupIntentOnload = useCallback(async () => {
    const accountId = getAccountId();
    const { setupIntent: newSetupIntent, tiers } = await createSetupIntent({
      accountId,
    });

    setSetupIntent(newSetupIntent);
    setPriceInformation(tiers);
  }, []);

  useEffect(() => {
    createSetupIntentOnload();
  }, [createSetupIntentOnload]);

  return {
    setupIntent,
    priceInformation,
  };
};

const hooks = () => ({
  useRegion: () => ({
    region: REGION,
  }),
  useOnStripeSetupComplete: () => {
    const [isStripeSetupComplete, setIsStripeSetupComplete] = useState(false);
    const onStripeSetupComplete = () => {
      setIsStripeSetupComplete(true);
    };
    return {
      isStripeSetupComplete,
      onStripeSetupComplete,
    };
  },
  useStripePromise: () => {
    const [stripePromise, setStripePromise] = useState(null);

    useEffect(() => {
      const newStripePromise = loadStripe(getStripeCorporatePublicKey());
      setStripePromise(newStripePromise);
    }, []);

    return {
      stripePromise,
    };
  },
  useStripeOptions: () => {
    const { setupIntent, priceInformation } = useStripeToCreateSetupIntent();
    const stripeOptions = setupIntent ? { clientSecret: setupIntent.clientSecret } : undefined;

    return {
      setupIntent,
      stripeOptions,
      priceInformation,
    };
  },
});

// This container is required because we must wrap the part of the app that calls useStripe() in an <Elements> provider
export const SubscriptionPaymentFormInStripeElementsContainer = composeHooks(hooks)(
  SubscriptionPaymentFormInStripeElements,
);

SubscriptionPaymentFormInStripeElementsContainer.displayName = 'SubscriptionPaymentFormInStripeElementsContainer';

SubscriptionPaymentFormInStripeElementsContainer.propTypes = {
  isUpgradeFromFreeTier: PropTypes.bool,
  stripeProductId: PropTypes.string.isRequired,
  subscriptionRecord: PropTypes.object.isRequired,
  onPaymentMethodUpdated: PropTypes.func.isRequired,
  onPaymentMethodUpdateAbort: PropTypes.func.isRequired,
};

SubscriptionPaymentFormInStripeElementsContainer.defaultProps = {
  isUpgradeFromFreeTier: false,
};

angular.module('sb.billing.webapp').controller('sbOperatingChequeDetailsModalController', function ($rootScope, $scope, $uibModalInstance, chequeId, sbLocalisationService) {
  $scope.chequeId = chequeId;
  $scope.t = sbLocalisationService.t;

  const routeChangeSuccessHandler = $rootScope.$on('$locationChangeSuccess', function() {
    $uibModalInstance.dismiss();
  });

  $scope.cancel = function () {
    $uibModalInstance.dismiss();
  };

  $scope.onClose = function (saved, deleted, dismiss) {
    /*
     modal response is not used
     */
    if (saved) {
      $uibModalInstance.close({saved: saved});
    }
    if (deleted) {
      $uibModalInstance.close({deleted: deleted});
    }
    if (dismiss) {
      $uibModalInstance.dismiss();
    }
  };

  $scope.$on('$destroy', function() {
    routeChangeSuccessHandler();
  });
});

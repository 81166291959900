'use strict';

const { capitalize } = require('@sb-itops/nodash');

// We should use AU english (cheque, eCheque) but these values are used as `source` on Payment entity so can't be changed now
const PAYMENT_SOURCE = Object.freeze({
  check: 'Check',
  bankTransfer: 'Bank Transfer',
  cash: 'Cash',
  creditCard: 'Credit Card',
  eCheck: 'eCheck',
});

const PAYMENT_TYPE = Object.freeze({
  direct: 'Direct',
  trust: 'Trust',
  operating: 'Operating',
  credit: 'Credit',
});

const getDirectPaymentOptions = (t) => [
  {
    label: capitalize(t('cheque')),
    paymentType: PAYMENT_TYPE.direct,
    paymentSource: PAYMENT_SOURCE.check,
    isDefault: true,
    value: PAYMENT_SOURCE.check,
  },
  {
    label: 'Bank Transfer',
    paymentType: PAYMENT_TYPE.direct,
    paymentSource: PAYMENT_SOURCE.bankTransfer,
    value: PAYMENT_SOURCE.bankTransfer,
  },
  {
    label: 'Cash',
    paymentType: PAYMENT_TYPE.direct,
    paymentSource: PAYMENT_SOURCE.cash,
    value: PAYMENT_SOURCE.cash,
  },
  {
    label: 'Credit Card',
    paymentType: PAYMENT_TYPE.direct,
    paymentSource: PAYMENT_SOURCE.creditCard,
    value: PAYMENT_SOURCE.creditCard,
  },
];

module.exports = {
  PAYMENT_SOURCE,
  PAYMENT_TYPE,
  getDirectPaymentOptions,
};

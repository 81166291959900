import { gql } from '@apollo/client';

const query = gql`
  query BankAccountDetails($bankAccountIds: [ID], $bankAccountFilter: BankAccountFilter) {
    bankAccounts(ids: $bankAccountIds, filter: $bankAccountFilter) {
      id
      accountType
      accountTypeDisplay
      accountName
      displayName
      location
      trustToOfficeNumberingSettings {
        useManualNumbering
      }
    }
  }
`;

const notificationIds = ['AccountsNotifications.AccountBalanceUpdated', 'AccountsNotifications.BankAccountUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const BankAccountDetails = {
  query,
  notificationIds,
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, FormLabel } from '@sb-itops/react';
import { getAccountId } from 'web/services/user-session-management';
import { getClientRoleOptions, getOtherSideRole } from '@sb-matter-listing/redux/matter-type-configurations';
import { getById as getAutoNumberDefinitionByAccountId } from '@sb-matter-management/redux/auto-number-definition';
import { matterFieldsRegionStrategy } from '../add-edit-matter/matter-fields-region-strategy';
import Styles from './AddMatterWizardModal.module.scss';

import { MatterDetails } from './matter-details';
import { MatterStaff } from './matter-staff';
import { MatterContacts } from './matter-contacts';
import { MatterBilling } from './matter-billing';
import { Wizard } from '../wizard';
import { MatterTypeSelect } from '../matter-type-select';

export const ADD_MATTER_WIZARD_MODAL_ID = 'ADD_MATTER_WIZARD_MODAL_ID';

const Step1 =
  ({ onFieldValuesUpdated }) =>
  ({ formFields, formDisabled, submitFailed }) =>
    (
      <>
        <FormLabel uppercase={false} label="Matter Type" field={formFields.matterTypeId} submitFailed={submitFailed} />
        <div>
          <MatterTypeSelect
            locationId={formFields.locationId && formFields.locationId.value}
            matterTypeCategory={formFields.matterTypeCategory && formFields.matterTypeCategory.value}
            matterTypeId={formFields.matterTypeId && formFields.matterTypeId.value}
            onLocationUpdated={(newLocationId) => {
              onFieldValuesUpdated({
                locationId: newLocationId,
                matterTypeCategory: undefined,
                matterTypeId: undefined,
                clientRole: undefined,
              });
            }}
            onCategoryUpdated={(newCategory) => {
              onFieldValuesUpdated({
                matterTypeCategory: newCategory,
                matterTypeId: undefined,
                clientRole: undefined,
              });
            }}
            onMatterTypeUpdated={(newMatterType) => {
              // newMatterType has the shape of { label, value, originalMatterTypeId }
              const newMatterTypeId = newMatterType && newMatterType.value; // selected matter type id always has location suffix, e.g. [GUID]_[NSW]

              // pre-select client role for user if there's only one to choose from
              const originalMatterTypeId = newMatterType && newMatterType.originalMatterTypeId; // could look like [GUID] or [GUID]_[NSW]
              const clientRoleOptions = getClientRoleOptions(originalMatterTypeId);
              const clientRole =
                clientRoleOptions && clientRoleOptions.length === 1 ? clientRoleOptions[0].value : undefined;
              const otherSideRole = getOtherSideRole(originalMatterTypeId, clientRole);
              onFieldValuesUpdated({
                matterTypeId: newMatterTypeId,
                originalMatterTypeId,
                clientRole,
                otherSideRole,
              });
            }}
            disabled={formDisabled}
            isRequired
            showError={submitFailed}
            showOnlyLeads={false}
          />
        </div>
      </>
    );
const Step1Validation = (formFields) => !!formFields.matterTypeId;

const Step2 =
  ({ onClickLink, onFieldValuesUpdated }) =>
  (form) =>
    (
      <MatterContacts
        formInitialised={form.formInitialised}
        formFields={form.formFields}
        formFieldValues={form.formValues}
        formDisabled={form.formDisabled}
        formSubmitting={form.formSubmitting}
        submitFailed={form.submitFailed}
        onFieldValuesUpdated={onFieldValuesUpdated}
        onFieldValueSet={form.onFieldValueSet}
        onClickLink={onClickLink}
      />
    );

const Step2Validation = (formFields) =>
  !!formFields?.clientCustomerIds?.length && formFields.clientCustomerIds.length > 0;

const Step3 =
  ({ onFieldValuesUpdated }) =>
  (form) =>
    (
      <MatterDetails
        formInitialised={form.formInitialised}
        formFields={form.formFields}
        formFieldValues={form.formValues}
        formDisabled={form.formDisabled}
        formSubmitting={form.formSubmitting}
        submitFailed={form.submitFailed}
        onFieldValuesUpdated={onFieldValuesUpdated}
      />
    );
const Step3Validation = (formFields) => {
  const validMatterNumber =
    !getAutoNumberDefinitionByAccountId(getAccountId())?.useAutoReference || formFields.matterNumber;
  const validDescription = !matterFieldsRegionStrategy.matterDescriptionRequired || formFields.description;
  return validDescription && validMatterNumber;
};

const Step4 =
  ({ onFieldValuesUpdated }) =>
  (form) =>
    (
      <MatterStaff
        formInitialised={form.formInitialised}
        formFields={form.formFields}
        formFieldValues={form.formValues}
        formDisabled={form.formDisabled}
        formSubmitting={form.formSubmitting}
        submitFailed={form.submitFailed}
        onFieldValuesUpdated={onFieldValuesUpdated}
      />
    );
const Step4Validation = () => true;

const Step5 =
  ({ onClickLink, onFieldValuesUpdated }) =>
  (form) =>
    (
      <MatterBilling
        formInitialised={form.formInitialised}
        formFields={form.formFields}
        formFieldValues={form.formValues}
        formDisabled={form.formDisabled}
        formSubmitting={form.formSubmitting}
        submitFailed={form.submitFailed}
        onFieldValuesUpdated={onFieldValuesUpdated}
        onFieldValueSet={form.onFieldValueSet}
        onClickLink={onClickLink}
      />
    );
const Step5Validation = () => true;

export const AddMatterWizardModal = ({
  hasBillingAccess,
  isVisible,
  isLoading,
  scope,
  // callbacks
  onFieldValuesUpdated,
  onCancel,
  onClickLink,
  onSubmit,
}) => {
  const steps = [
    {
      label: 'Matter Type',
      step: Step1({ onClickLink, onFieldValuesUpdated }),
      validation: Step1Validation,
    },
    {
      label: 'Parties',
      step: Step2({ onClickLink, onFieldValuesUpdated }),
      validation: Step2Validation,
      showAddContact: true,
    },
    {
      label: 'Matter Details',
      step: Step3({ onClickLink, onFieldValuesUpdated }),
      validation: Step3Validation,
    },
    {
      label: 'Staff',
      step: Step4({ onClickLink, onFieldValuesUpdated }),
      validation: Step4Validation,
      showAddContact: true,
    },
  ];
  if (hasBillingAccess) {
    steps.push({
      label: 'Billing',
      step: Step5({ onClickLink, onFieldValuesUpdated }),
      validation: Step5Validation,
      showAddContact: true,
      canSave: true,
    });
  }

  return (
    <Modal
      title=""
      showModalBodyPadding={false}
      isModalBodyScrollable={false}
      showFooter={false}
      modalBodyClassName={Styles.modalBody}
      body={<Wizard scope={scope} title="New Matter" steps={steps} onSubmit={onSubmit} onClose={onCancel} />}
      isLoading={isLoading}
      isVisible={isVisible}
      onModalClose={onCancel}
      shouldCloseOnOverlayClick={false}
    />
  );
};

AddMatterWizardModal.propTypes = {
  hasBillingAccess: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  isVisible: PropTypes.bool,
  scope: PropTypes.string.isRequired,
  // callbacks
  onCancel: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  onFieldValueSet: PropTypes.func.isRequired,
  onFieldValuesUpdated: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

AddMatterWizardModal.defaultProps = {
  isLoading: false,
  isVisible: false,
};

AddMatterWizardModal.displayName = 'AddMatterWizardModal';

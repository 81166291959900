import React from 'react';
import PropTypes from 'prop-types';
import { getSupportHubUrl } from '@sb-itops/environment-config';
import { Pill, SlidingToggle, Spinner } from '@sb-itops/react';
import { Table, Column } from '@sb-itops/react/table';
import { featureActive, featureData } from '@sb-itops/feature';

import { AddEditRateSet } from '../add-edit-rate-set';
import Styles from './RateSetsSettings.module.scss';

const renderHeaderCell = ({ label }) => label;

const renderNameCell = ({ rowData }) => (
  <div className={Styles.nameCell} key={rowData.id}>
    <span>{rowData.name}</span>
    {rowData.isHidden && <Pill label="Hidden" className={Styles.hiddenPill} />}
  </div>
);

const renderStaffCell = ({ rowData }) => {
  const staffs = rowData.staffs || [];
  const first5Staffs = (staffs || []).slice(0, 5);
  const remainingStaffs = (staffs || []).slice(5);
  const remainingStaffCount = staffs.length > 0 ? staffs.length - 5 : 0;
  return (
    <div className={Styles.staffCell} key={rowData.id}>
      {first5Staffs.map((staff) => (
        <Pill label={staff.initials} className={Styles.staffInitials} key={`${rowData.id}:${staff.staffId}`} />
      ))}
      {remainingStaffCount > 0 && (
        <span title={remainingStaffs.map((staff) => staff.initials).join(', ')}>{`+${remainingStaffCount}`}</span>
      )}
    </div>
  );
};

const RateSetsSettings = React.memo(
  ({
    currentRateSet,
    currentRateSetId,
    rateSets,
    rateSetsLoading,
    showHidden,
    staffMembers,
    // func & callbacks
    addNewRateSet,
    clearCurrentRateSet,
    setCurrentRateSetId,
    setShowHidden,
  }) => {
    const rateSetHelpLink = (featureActive('BB-11099') && featureData('BB-11099')?.url) || getSupportHubUrl();
    const rateSetsToDisplay = rateSets.filter((rateSet) => showHidden || !rateSet.isHidden);

    return (
      <div className={Styles.rateSetsSettings}>
        <h3>Rate Sets</h3>
        <p className={Styles.description}>
          {`Rate Sets allow you to manage your hourly rates for your staff and matters. `}
          <a href={rateSetHelpLink} target="_blank" rel="noopener noreferrer">
            More info.{' '}
          </a>
        </p>
        <br />
        {currentRateSetId || currentRateSet ? (
          <AddEditRateSet
            currentRateSet={currentRateSet}
            rateSetId={currentRateSetId}
            rateSets={rateSets}
            staffMembers={staffMembers}
            onClose={() => clearCurrentRateSet()}
          />
        ) : (
          <>
            <div className={Styles.addRateSet}>
              <div className="link-button" onClick={() => addNewRateSet()}>
                <i className="icon icon-add-3" />
                ADD RATE SET
              </div>
              <div className={Styles.formerToggle}>
                <SlidingToggle
                  scope="show-hidden-rate-sets-toggle"
                  onChange={(name, value) => setShowHidden(value)}
                  selected={showHidden}
                />
                <div onClick={() => setShowHidden(!showHidden)} className={Styles.formerLabel}>
                  Show hidden
                </div>
              </div>
            </div>
            <Table
              className="table-shadow"
              style={{
                height: `${Math.min(600, 30 + rateSetsToDisplay.length * 38)}px`,
              }}
              onRowClick={({ rowData }) => setCurrentRateSetId(rowData.id)}
              list={rateSetsToDisplay}
            >
              <Column
                dataKey="name"
                label="Rate Set"
                flexGrow={6}
                className={Styles.nameColumn}
                headerRenderer={renderHeaderCell}
                cellRenderer={renderNameCell}
              />
              <Column
                flexGrow={3}
                dataKey="staff"
                label="Staff"
                className={Styles.staffColumn}
                headerRenderer={renderHeaderCell}
                cellRenderer={renderStaffCell}
              />
            </Table>
            {rateSetsLoading && <Spinner small />}
          </>
        )}
      </div>
    );
  },
);

RateSetsSettings.displayName = 'RateSetsSettings';

RateSetsSettings.propTypes = {
  currentRateSet: PropTypes.object,
  currentRateSetId: PropTypes.string,
  rateSets: PropTypes.array,
  rateSetsLoading: PropTypes.bool.isRequired,
  showHidden: PropTypes.bool.isRequired,
  staffMembers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      initials: PropTypes.string,
      isFormerStaff: PropTypes.bool,
      name: PropTypes.string,
      rate: PropTypes.number,
    }),
  ),
  // func & callback
  addNewRateSet: PropTypes.func.isRequired,
  clearCurrentRateSet: PropTypes.func.isRequired,
  setCurrentRateSetId: PropTypes.func.isRequired,
  setShowHidden: PropTypes.func.isRequired,
};

RateSetsSettings.defaultProps = {
  currentRateSet: undefined,
  currentRateSetId: undefined,
  rateSets: [],
  staffMembers: [],
};

export { RateSetsSettings };

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { forms2PropTypes, Button, FloatingCard, SlidingToggle, Spinner, FormLabel, DatePicker } from '@sb-itops/react';
import { dateToInteger, integerToDate, tomorrow } from '@sb-itops/date';

import { StaffRateOverrider } from '../staff-rate-overrider';
import { rateSetNameMustBeUniqueErrorMessage } from './AddEditRateSet.schema';
import Styles from './AddEditRateSet.module.scss';

const AddEditRateSet = React.memo(
  ({
    staffMembers,
    // form fields
    id,
    rateSetName,
    currentStaffRates,
    updateRatesOn,
    futureStaffRates,
    isHidden,
    // form state
    formDirty,
    formInitialised,
    formSubmitting,
    submitFailed,
    // func
    onClose,
    onFieldValueSet,
    onFieldValueUpdated,
    onIsHiddenToggled,
    onSave,
    validateForm,
  }) => {
    const isErrorClassnames = (field) => (field?.isInvalid && (field?.isDirty || submitFailed) ? Styles.hasError : '');

    return (
      <div className={Styles.addEditRateSet}>
        {!formInitialised ? (
          <Spinner small />
        ) : (
          <FloatingCard applyMargin={false}>
            <fieldset disabled={formSubmitting}>
              <h3 className={Styles.title}>{id?.value ? 'Edit Rate Set' : 'Add Rate Set'}</h3>
              <div className="row">
                <div className="col-xs-12">
                  <FormLabel label="Rate Set Name" field={rateSetName} submitFailed={submitFailed} uppercase={false} />
                  <input
                    name="rateSetName"
                    type="text"
                    className={classnames('form-control', isErrorClassnames(rateSetName))}
                    value={rateSetName.value}
                    onChange={(e) => {
                      onFieldValueUpdated({ rateSetName: e.target.value });
                    }}
                    onBlur={validateForm}
                  />
                </div>
              </div>
              {rateSetName.invalidReason === rateSetNameMustBeUniqueErrorMessage && (
                <div className="row">
                  <div className="col-xs-12">
                    <span className={Styles.errorMessage}>{rateSetNameMustBeUniqueErrorMessage}</span>
                  </div>
                </div>
              )}
              <br />
              <div>
                <h4>Current Rates</h4>
                <div className="row">
                  <div className="col-xs-12">
                    <FormLabel label="Staff Rates" submitFailed={submitFailed} uppercase={false} />
                    <StaffRateOverrider
                      staffMembers={staffMembers}
                      overrides={currentStaffRates}
                      hasError={submitFailed && currentStaffRates.length === 0}
                      onUpdate={(overrides) => {
                        onFieldValueSet({ field: 'currentStaffRates', value: overrides });
                      }}
                    />
                  </div>
                </div>
              </div>
              <br />
              <div>
                <h4>
                  Future Rates
                  <i
                    title="The future rates will be applied starting on the date selected."
                    className="icon icon-question-mark"
                    color="lightgrey"
                  />
                </h4>
                <div className="row">
                  <div className="col-xs-6">
                    <FormLabel label="Update Rates On" submitFailed={submitFailed} uppercase={false} optional />
                    <DatePicker
                      onSelect={(date) => {
                        onFieldValueUpdated({ updateRatesOn: date ? dateToInteger(date) : undefined });
                        if (currentStaffRates.length > 0 && futureStaffRates.length === 0) {
                          onFieldValueSet({
                            field: 'futureStaffRates',
                            value: JSON.parse(JSON.stringify(currentStaffRates)),
                          });
                        }
                      }}
                      value={updateRatesOn && updateRatesOn.value && integerToDate(updateRatesOn.value)}
                      hasError={updateRatesOn && updateRatesOn.isInvalid}
                      format="DD/MM/YYYY"
                      minDate={tomorrow()}
                      disabled={formSubmitting}
                    />
                  </div>
                </div>
                {updateRatesOn && updateRatesOn.value && updateRatesOn.isValid && (
                  <div className="row">
                    <div className="col-xs-12">
                      <FormLabel label="Staff Rates" submitFailed={submitFailed} uppercase={false} />
                      <StaffRateOverrider
                        staffMembers={staffMembers}
                        overrides={futureStaffRates}
                        hasError={submitFailed && futureStaffRates.length === 0}
                        onUpdate={(overrides) => {
                          onFieldValueSet({ field: 'futureStaffRates', value: overrides });
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <br />
              <div className="row">
                <div className="col-xs-12">
                  <div className={Styles.showRateSetToggle}>
                    <label>
                      <SlidingToggle
                        scope="show-rate-set-and-apply-to-matters"
                        onChange={onIsHiddenToggled}
                        selected={!isHidden.value}
                      />
                      <span>Show rate set and allow it to be applied to new and existing matters</span>
                    </label>
                    <i
                      title="Hiding a rate set will allow it to continue to work for existing matters that it has already been applied to, however it will not be selectable for additional matters."
                      className="icon icon-question-mark"
                      color="lightgrey"
                    />
                  </div>
                </div>
              </div>
            </fieldset>
            <div className={Styles.formFooter}>
              <Button
                locked={formSubmitting} // spinner
                disabled={!formDirty || formSubmitting}
                className={Styles.saveButton}
                size="full-width"
                onClick={onSave}
              >
                Save
              </Button>
              <Button
                disabled={formSubmitting}
                className={Styles.cancelButton}
                size="full-width"
                type="secondary"
                onClick={onClose}
              >
                Cancel
              </Button>
            </div>
          </FloatingCard>
        )}
      </div>
    );
  },
);

AddEditRateSet.displayName = 'AddEditRateSet';

const { Forms2Field } = forms2PropTypes;

AddEditRateSet.propTypes = {
  staffMembers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      initials: PropTypes.string,
      isFormerStaff: PropTypes.bool,
      name: PropTypes.string,
      rate: PropTypes.number,
    }),
  ),
  // form fields
  id: Forms2Field,
  rateSetName: Forms2Field,
  currentStaffRates: PropTypes.arrayOf(
    PropTypes.shape({
      staffId: PropTypes.string,
      rate: PropTypes.number,
    }),
  ),
  updateRatesOn: Forms2Field,
  futureStaffRates: PropTypes.arrayOf(
    PropTypes.shape({
      staffId: PropTypes.string,
      rate: PropTypes.number,
    }),
  ),
  isHidden: Forms2Field,
  // form state
  formDirty: PropTypes.bool.isRequired,
  formInitialised: PropTypes.bool.isRequired,
  formSubmitting: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  // func & callbacks
  onClose: PropTypes.func.isRequired,
  onFieldValueSet: PropTypes.func.isRequired, // needed to update array fields
  onFieldValueUpdated: PropTypes.func.isRequired,
  onIsHiddenToggled: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  validateForm: PropTypes.func.isRequired,
};

AddEditRateSet.defaultProps = {
  id: undefined,
  rateSetName: undefined,
  currentStaffRates: [],
  updateRatesOn: undefined,
  futureStaffRates: [],
  isHidden: undefined,
  staffMembers: [],
};

export { AddEditRateSet };

import store from '@sb-itops/redux/store';

angular.module('sb.billing.webapp').service('sbTabService', function ($rootScope, $state, $timeout, sbStateHistoryService, sbLoggerService, sbUnsavedChangesService) {
  'use strict';
  var svc = this, tabs = [], log = sbLoggerService.getLogger('sbTabService'),
    calculatedTabs = [], updateCbs = [],
    tabIndex = 0;

  // log.setLogLevel('debug');

  svc.onUpdate = function (cb) {
    updateCbs.push(cb);
  };

  svc.getTabs = function() {
    return tabs;
  };

  sbUnsavedChangesService.onSaveMemory(pushUpdates);

  $rootScope.$on('$locationChangeSuccess', function () {
    if ($state.current.name.match('^home.billing.advanced-search')) {
      processSpecialCases($state.current, $state.params);
    }
  });

  $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
    processSpecialCases(toState, toParams, fromState, fromParams);
    addTab(toState, toParams);
    pushUpdates();
  });

  $rootScope.$on('smokeball-data-update-invoice-deleted', function(event, invoice) {
    if (!invoice || !invoice.matterId) {
      return;
    }

    var invoiceTab = findTab(tabs, { type: 'draftinvoice', matterId: invoice.matterId, invoiceId: invoice.invoiceId || null});

    if (invoiceTab) {
      svc.close(invoiceTab);
    } else {
      log.error('not tab found for draft invoice with matterId ', invoice.matterId, ' invoiceId ', invoice.invoiceId);
    }
  });

  svc.deriveTab = deriveTab;

  svc.goto = function (tab) {
    var currentTab = findTab(tabs, deriveCurrentTab()), foundTab = findTab(tabs, tab);

    if (!foundTab || currentTab === foundTab) {
      return;
    }

    $state.go(foundTab.$state, {
      ...foundTab.$params,
      openedFromTab: true
    });
  };

  svc.closeCurrent = function() {
    svc.close(deriveCurrentTab());
  };

  svc.getCurrentTab = function() {
    return deriveCurrentTab();
  }

  svc.close = function (tab) {
    var currentTab = findTab(tabs, deriveCurrentTab()), foundTab = findTab(tabs, tab);

    log.debug('asked to close tab: ', tab, ' looked at ', tabs, ' found ', foundTab, ' current tab ', currentTab);

    if (!foundTab) {
      return;
    }

    $rootScope.$broadcast('smokeball-tab-closed', foundTab); // FFS FFFS. Backwards compatibility.

    store.dispatch({ type: 'smokeball-tab-closed', payload: foundTab });

    _.pull(tabs, foundTab);
    clearTabPersistance(foundTab);

    if (tab.onClose) {
      tab.onClose();
    }

    $rootScope.$broadcast('smokeball-tab-close', currentTab);

    if (currentTab !== foundTab) {
      pushUpdates();
      return; // Tab being closed is not the active tab.
    }

    // nav to the most recent 'prior' state relative to this tab's perspective.
    var foundNavigableState = _.some(foundTab.stateHistoryStack, function(stateHistoryItem) {
      if (isNavigableState(currentTab, stateHistoryItem))  {
        $state.go(stateHistoryItem.state.name, stateHistoryItem.params);
        return true;
      }
    });

    // If we couldn't find a navigable state, we head home.
    if (!foundNavigableState) {
      $state.go('home.billing.matters');
    }
  };

  svc.closeAll = function (preserveTab) {
    var tabsClone =  _.cloneDeep(tabs), // need to clone otherwise _.each and _.pull conflict
      current = findTab(tabsClone, deriveCurrentTab()),
      preserve = preserveTab && findTab(tabsClone, preserveTab);

    log.info('closing all tabs except: ', preserve, ' current is: ', current);

    // remove all non-current tabs
    _.each(tabsClone, function (tab) {
      if (tab !== current && tab !== preserve) {
        svc.close(tab);
        log.debug('closing tab: ', tab);
      } else {
        log.debug('skip closing tab: ', tab);
      }
    });

    // close current tab (if any) last
    if (current && current !== preserve) {
      svc.close(current);
      log.debug('closing current tab: ', current);
    }

    if (current && preserveTab) {
      svc.goto(preserveTab);
    }
  };

  svc.updateTabInfo = function(tab, infoToUpdate) {
    var foundTab = findTab(tabs, tab);

    if (!foundTab) {
      log.warn('No tab found to update with the info:', tab);
      return;
    }

    log.debug('Updating tab info:', foundTab, ' with ', infoToUpdate);

    // Update the properties of the found tab with the new information
    Object.keys(infoToUpdate).forEach(function(key) {
      foundTab[key] = infoToUpdate[key];
    });

    pushUpdates();
  };

  function pushUpdates() {
    var oldState = _.cloneDeep(calculatedTabs);

    recalculateTabs();

    if (!_.isEqual(oldState, calculatedTabs)) {
      $timeout(function () {
        _.each(updateCbs, function (cb) {
          cb(calculatedTabs);
        });
      });
    }
  }

  svc.isTabPresent = function isTabPresent(tabType) {
    const tab = findTab(tabs, {type: tabType});
    const tabIsPresentOnTabMenu = !!tab;
    return tabIsPresentOnTabMenu;
  }

  function findTab(collection, tab) {
    var found;

    if (!tab || tab.type === undefined) {
      return;
    }

    tab = stripTab(tab);

    // Special case for search term. We need access to the searchTerm property within the navTabs component.
    // But we cannot include it in our find logic, otherwise duplicate tabs are created whenever the term
    // changes within a tab. Prior to a more extensive refactor, the solution is to nuke the search term
    // outside of strip tab.
    delete tab.searchTerm;
    log.debug('looking for tab: ', tab);

    found = _.find(collection, tab);
    log.debug('found: ', found);

    return found;
  }

  function addTab(state, params) {
    const tab = deriveTab(state, params);
    let existingTab = findTab(tabs, tab);

    if (!tab) {
      return;
    }

    log.debug('new tab ', _.cloneDeep(tab), ' existing tab ', _.cloneDeep(existingTab));

    if (!existingTab) {
      log.debug('pushing tab');
      tab.tabId = ++tabIndex;
      tabs.push(tab);
      tab.stateHistoryStack = sbStateHistoryService.getStateHistory();
      existingTab = tab;
    } else {
      log.debug('not pushing tab');
    }

    existingTab.$state = state.name;
    existingTab.$params = _.cloneDeep(params);
  }

  function deriveTab(state, params) {
    if (state.data && state.data.tab) {
      switch (state.data.tab.type) {
        case 'view-combined-pdf':
          return { type : state.data.tab.type, id: params.operationId};
        case 'view-transaction-details-pdf':
          return { type: state.data.tab.type, id: params.operationId };
        case 'advanced-search':
          return { type : state.data.tab.type, id: params.searchId, searchTerm: params.searchTerm};
        case 'matter':
          return { type : state.data.tab.type, id : params.matterId, matterId : params.matterId };
        case 'contact':
          return { type : state.data.tab.type, id : params.contactId, contactId : params.contactId };
        case 'add-matter':
          return { type : state.data.tab.type, };
        case 'edit-matter':
          return { type : state.data.tab.type, id : params.matterId, matterId : params.matterId };
        case 'finalize-invoice-payments':
          return { type : state.data.tab.type, };
        case 'deposit-receipt':
          return { type: state.data.tab.type, id: params.transactionId, transactionId: params.transactionId };
        case 'transfer-receipt':
          return { type : state.data.tab.type, id : params.paymentId, paymentId: params.paymentId };
        case 'trust-to-office-transfer-receipt':
          return { type : state.data.tab.type, id : params.paymentId, paymentId: params.paymentId };
        case 'vendor-proof-of-payment':
          return { type : state.data.tab.type, id : params.transactionId, transactionId : params.transactionId };
        case 'invoice-proof-of-payment':
          return { type : state.data.tab.type, id :  params.invoiceId, invoiceId : params.invoiceId }
        case 'invoice-statement':
          return { type : state.data.tab.type, id :  params.invoiceStatementId, invoiceStatementId : params.invoiceStatementId }
        case 'bank-reconciliation':
          return { type : state.data.tab.type, id : params.trustAccountId, bankReconId : params.bankReconId, onClose: state.data.tab.onClose };
        case 'bank-reconciliation-setup':
          return { type : state.data.tab.type, id : params.bankReconId, bankReconId : params.bankReconId, onClose: state.data.tab.onClose };
        case 'bank-reconciliation-pdf':
          return { type : state.data.tab.type, id : params.bankReconciliationId, bankReconciliationId : params.bankReconciliationId };
        case 'end-of-month-pdf':
          return { type : state.data.tab.type, id : params.endOfMonthReportId, month: params.month };
        case 'create-deposit-slip':
          return { type : state.data.tab.type, account: state.data.accountType };
        case 'edit-deposit-slip':
          return { type : state.data.tab.type, id: params.depositSlipId };
        case 'deposit-slip-pdf':
          return { type : state.data.tab.type, id: params.depositSlipId };
        case 'trust-cheques-pdf':
          return { type : state.data.tab.type, id: params.operationId };
        case 'view-operating-cheque-pdf':
          return { type : state.data.tab.type, id: params.operationId };
        case 'support':
          return { type : state.data.tab.type };
        case 'invoice':
          if (params.matterId) { // a draft invoice
            if (params.invoiceId) {
              return { 'type' : 'draftinvoice', 'id' : params.matterId + '_' + params.invoiceId, matterId : params.matterId, invoiceId : params.invoiceId }; // saved draft
            } else {
              return { 'type' : 'draftinvoice', 'id' : params.matterId, matterId : params.matterId, invoiceId : null }; // pre-draft
            }
          } else if (params.invoiceId) { // finalized invoice
            return { 'type' : 'invoice', 'id' : params.invoiceId, invoiceId : params.invoiceId };
          }
          log.warn('tab entry found for invoice but no matterId or invoiceId: ', params);
          break;
        default:
          log.warn('tab entry found on route data but don\'t know how to handle it: ', state.name);
      }
    }
  }

  function deriveCurrentTab() {
    return deriveTab($state.current, $state.params);
  }

  function stripTab(tab) {
    var newTab = _.cloneDeep(tab);

    delete newTab.active;
    delete newTab.unsaved;
    delete newTab.$state;
    delete newTab.$params;
    delete newTab.stateHistoryStack;

    return newTab;
  }

  function getPersistanceKey(tab) {
    if (tab.type === 'draftinvoice') {
      if (tab.invoiceId) {
        return 'home.billing.create-bill.invoice-id.' + tab.invoiceId;
      }

      return 'home.billing.create-bill.matter-id.' + tab.matterId;
    }
    if (tab.type === 'edit-matter') {
      return `home.billing.edit-matter.matter-id.${tab.matterId}`;
    }

    if (tab.type === 'add-matter') {
      return `home.billing.add-matter`;
    }

    if (tab.type === 'create-deposit-slip') {
      return `home.billing.create-deposit-slip.${tab.account}`;
    }

    if (tab.type === 'edit-deposit-slip') {
      return `home.billing.edit-deposit-slip.${tab.id}`;
    }
  }

  function clearTabPersistance(tab) {
    var persistanceKey = getPersistanceKey(tab);

    // since this is called after $stateChangeSuccess when switching from draft->final it should work with one call
    // but actually data is saved after the $scope.$destroy of the old state's controller, so we need to keep later.
    if (persistanceKey) {
      $timeout(function () { sbUnsavedChangesService.saveMemory(persistanceKey); });
      $timeout(function () { sbUnsavedChangesService.saveMemory(persistanceKey); }, 1000);
      $timeout(function () { sbUnsavedChangesService.saveMemory(persistanceKey); }, 2000);
      $timeout(function () { sbUnsavedChangesService.saveMemory(persistanceKey); }, 5000);
    }
  }

  function hasUnsavedChanges(tab) {
    var persistanceKey = getPersistanceKey(tab),
      unsavedChanges = persistanceKey && sbUnsavedChangesService.loadMemory(persistanceKey);

    if (unsavedChanges) {
      if (tab.type === 'draftinvoice') {
        if (_.size(unsavedChanges) > 1 || (_.size(unsavedChanges) === 1 && !unsavedChanges.draftQuickPayments)) {
          return true;
        }
      }
    }
  }

  function recalculateTabs() {
    var foundTab, currentTab = deriveCurrentTab();

    calculatedTabs = _.map(tabs, stripTab);

    if (currentTab) {
      foundTab = findTab(calculatedTabs, currentTab);
      if (foundTab) {
        foundTab.active = true;
      }
    }

    _.each(calculatedTabs, function (tab) {
      tab.unsaved = hasUnsavedChanges(tab);
    });
  }

  function processSpecialCases(toState, toParams, fromState, fromParams) {
    var oldTab;

    // special case for finalizing invoice
    if (fromState && fromState.name === 'home.billing.create-bill' && toState && toState.name === 'home.billing.goto-invoice') {
      log.info('detected invoice finalization for draft ', fromParams.matterId, 'to invoice', toParams.invoiceId);
      oldTab = findTab(tabs, { type: 'draftinvoice', matterId: fromParams.matterId, invoiceId: fromParams.invoiceId || null });
      if (oldTab) {
        clearTabPersistance(oldTab);
        log.info('found existing tab draftInvoice matter tab, mutating to be invoice tab');
        oldTab.type = 'invoice';
        oldTab.id = toParams.invoiceId;
        oldTab.invoiceId = toParams.invoiceId;
        delete oldTab.matterId;
      }
    }

    // special case for changing search term
    if (toState && toState.name.match('^home.billing.advanced-search')) {
      oldTab = findTab(tabs, { type: 'advanced-search', id: toParams.searchId });
      if (oldTab) {
        oldTab.$params = toParams;
        oldTab.searchTerm = toParams.searchTerm;
        pushUpdates();
      }
    }
  }

  function isNavigableState(currentTab, stateHistoryItem) {
    // Treat holding states as non navigable.
    var holdingStates = ['login', 'pre-authentication', 'post-authentication', 'home.billing.goto-invoice'];
    if (holdingStates.indexOf(stateHistoryItem.state.name) !== -1) {
      return false;
    }

    // If the state history item refers to something other than a tab, we assume that it's always navigable.
    var derivedHistoryTab = deriveTab(stateHistoryItem.state, stateHistoryItem.params);

    if (!derivedHistoryTab) {
      return true;
    }

    // If the history item refers to a tab, we check to make sure that the tab is still open.
    var existingHistoryTab = findTab(tabs, derivedHistoryTab);
    if (existingHistoryTab) {
      // Tab is open, but it is the current tab. If that's the case, we treat as non navigable.
      return existingHistoryTab !== currentTab;
    }

    // Otherwise, the tab referred to by the state history item has already been closed an is
    // therefore non-navigable.
    return false;
  }
});

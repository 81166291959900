import { getById as getExpenseById } from '@sb-billing/redux/expenses';

angular.module('sb.billing.webapp').component('sbEntryModalClickable', {
  'templateUrl': 'ng-components/entry-modal-clickable/entry-modal-clickable.html',
  'bindings': { entryType: '@?', matterId: '<?', entry: '<?', onClickBindContext: '<?', onClickBindName: '@?' },
  'transclude': true,
  'controller': function ($state, sbLoggerService, $uibModal) {
    'use strict';

    var ctrl = this;
    var log = sbLoggerService.getLogger('sbEntryModalClickable');

    ctrl.$onInit = function () {
      if (ctrl.onClickBindContext && ctrl.onClickBindName) {
        ctrl.onClickBindContext[ctrl.onClickBindName] = function (event, entry, entryTypeOverride, matterIdOverride, prefillEntryFromCache, onSuccess) {
          ctrl.entry = entry;
          ctrl.openModal(event, entryTypeOverride, matterIdOverride, prefillEntryFromCache, onSuccess);
        };
      }
    };

    ctrl.openModal = function ($event, entryTypeOverride = null, matterIdOverride = null, prefillEntryFromCache = false, onSuccess = undefined) {
      var entryType = entryTypeOverride || ctrl.entryType, entry = ctrl.entry;

      log.info('loading entry : ', ctrl.entry);
      if ($event) {
        $event.stopPropagation();
        $event.preventDefault();
      }

      if (entry) {
        entry = _.cloneDeep(entry);
        if (!entryType) {
          // entryType not specified via binding
          if (entry.feeId || entry.feeVersionId) {
            entryType = 'FEE';
          }
          if (entry.expenseId || entry.expenseVersionId) {
            entryType = 'EXPENSE';
          }
        }
      }

      entryType = entryType && entryType.toUpperCase();

      switch (entryType) {
        case 'FEE':
          log.warn('Legacy fee modal is no longer available'); // LOD only
          break;
        case 'EXPENSE':
          // Remove the logic and add the log.warn same as the FEE one above as part of BB-13186 cleanup
          if (prefillEntryFromCache === true && entry && entry.expenseId) {
            const legacyExpense = getExpenseById(entry.expenseId);
            entry = {
              ...legacyExpense,
              ...entry,
            };
          }

          openExpenseModal(entry, matterIdOverride || ctrl.matterId, onSuccess);
          break;
        case 'PAYMENT':
          openPaymentModal(entry, matterIdOverride || ctrl.matterId);
          break;
        default:
          log.warn('don\'t know how to deal with entryType: ', entryType);
      }
    };

    function openExpenseModal(entry, matterId, onSuccess) {
      let prefill;

      if (entry) {
        prefill = {
          ...entry,
          date: entry.expenseDate,
          staffId: entry.expenseEarnerStaffId,
          matter: entry.matterId,
          activityCode: entry.expenseActivityId,
          units: entry.quantity / 100,  // TODO: decimal-operation
          rate: entry.price / 100,  // TODO: decimal-operation
          billable: !!entry.isBillable,
          waived: !!entry.waived,
        };
      }

      $uibModal.open({
        templateUrl: 'ng-components/expense-entry/modal/expense-modal.html',
        controller: 'SbExpenseModalController',
        resolve: {
          sbExpenseModalConfig: function () {
            return {
              preFill: prefill || matterId,
              features: { update: !!prefill, saveAndNew: !prefill, onSuccess },
            };
          }
        },
        backdrop: 'static',
      });
    }

    function openPaymentModal(entry, matterId) {
      var prefill;

      if (entry) {
        prefill = entry;
      }

      $uibModal.open({
        templateUrl: 'ng-components/payment-entry/modal/payment-view-modal.html',
        controller: 'SbPaymentViewModalController',
        resolve: {
          sbPaymentViewModalConfig: function () {
            return {
              preFill: prefill || matterId,
              features: {
                update: false,
                saveAndNew: false,
                state: $state.$current.name
              }
            };
          }
        },
        backdrop: 'static',
      });
    }

  }
});

/* eslint-disable import/no-cycle */
import { trustTransactionFilter, consolidateTrustTransactions } from '@sb-billing/business-logic/transactions/services';
import { getList as getAllTransactions } from './index';
import { getById as getPaymentById } from '../payments';
import { getInvoiceNumberById } from '../invoices';

/**
 * Get all trust transactions consolidated where applicable
 * 1. trust to office transactions associated with each trust to office transfer are
 *    consolidated into separate pseudo-transactions
 * 2. all other transactions are returned unchanged
 * @param {Object} filterValues
 * @param {boolean} filterValues.showHidden
 * @param {string} filterValues.matterId
 * @param {string} filterValues.contactId
 * @param {string} filterValues.trustAccountId
 * @param {function} filterValues.t i18next translate function
 */
export function getAllConsolidatedTrustTransactions({ showHidden, matterId, contactId, trustAccountId, t } = {}) {
  const transactions = getAllTransactions().filter((txn) =>
    trustTransactionFilter(txn, { showHidden, matterId, contactId, trustAccountId }),
  );

  const consolidatedTransactions = consolidateTrustTransactions({
    transactions,
    getInvoiceNumberById,
    getPaymentById,
    t,
  });
  return consolidatedTransactions;
}

/**
 * Get consolidated trust transaction record by id
 * @param {String} consolidatedTrustTransactionId
 * @param {Object} filterValues
 * @param {boolean} filterValues.showHidden
 * @param {string} filterValues.matterId
 * @param {string} filterValues.contactId
 * @param {function} t i18next translate function
 *
 */
export function getConsolidatedTrustTransactionById(id, t, { showHidden, matterId, contactId } = {}) {
  return getAllConsolidatedTrustTransactions({ showHidden, matterId, contactId, t }).find(
    (transaction) => transaction.id === id,
  );
}

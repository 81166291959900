import PropTypes from 'prop-types';
import { getStaffOptions } from '@sb-firm-management/redux/firm-management';
import { getContactTypeAheadSummaries } from 'web/redux/selectors/typeahead';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { MatterStaff } from './MatterStaff';

const hooks = ({ formInitialised, formFields, formDisabled, formSubmitting, submitFailed }) => ({
  useFilters: () => {
    const { attorneyResponsibleId, personAssistingId, originatingAttorneyId, referralType, referrerId } = formFields;

    const staffOptions = getStaffOptions();
    const contactOptions = getContactTypeAheadSummaries();

    return {
      // form state
      formInitialised,
      formDisabled,
      formSubmitting,
      submitFailed,
      // form fields
      attorneyResponsibleId,
      personAssistingId,
      originatingAttorneyId,
      referralType,
      referrerId,
      // select options
      staffOptions,
      contactOptions,
    };
  },
});

export const MatterStaffContainer = withReduxProvider(composeHooks(hooks)(MatterStaff));

MatterStaffContainer.displayName = 'MatterStaffContainer';

MatterStaffContainer.propTypes = {
  matterId: PropTypes.string,
  // form state
  formInitialised: PropTypes.bool.isRequired,
  formFields: PropTypes.object,
  formDisabled: PropTypes.bool,
  formSubmitting: PropTypes.bool,
  submitFailed: PropTypes.bool,
  // callbacks
  onFieldValuesUpdated: PropTypes.func.isRequired, // generic method for updating fields
};

MatterStaffContainer.defaultProps = {
  matterId: undefined,
};

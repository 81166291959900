/*
!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
The integration with the onboarding wizard is completely custom (i.e. they dont use the SDK).

This integration is full of random stuff we do NOT want to copy or repeat anywhere else.

Please talk to me if you are working on anything here.

This has been deliberately kept to a single file to quarantine it
*/

/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from 'react';
import { DocumentNode } from 'graphql';
import {
  FirmDetails,
  InitFirmFeeConfiguration,
  InitFirmUtbmsSettings,
  InitUserBillingAttributes,
} from 'web/graphql/queries';
import { useCacheQuery } from 'web/hooks';
import ReactModal from 'react-modal';
import { sendMetric } from 'web/services/metrics';
import { firmFeeConfigurationDefaults } from '@sb-billing/business-logic/shared/entities';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { firmManagement } from '@sb-firm-management/redux';
import { getAccountId } from 'web/services/user-session-management';
import { facets, hasFacet } from '@sb-itops/region-facets';
import { saveStaffHourlyRate } from '@sb-billing/redux/staff-hourly-rate/save-staff-hourly-rate';
import { setFeeInterval } from '@sb-billing/redux/fee-configuration/set-fee-interval';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { createMatter } from '@sb-matter-management/redux/matters';
import moment from 'moment';
import { getRegion, regionType } from '@sb-itops/region';
import { useQuery } from '@apollo/client';
import { LoggedInStaff } from 'web/graphql/queries/loggedInStaff';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { Spinner } from '@sb-itops/react';
import * as messageDisplay from '@sb-itops/message-display';
import { isValidLogoFileP } from 'web/ng-components/invoice-settings/invoice-settings-helpers';
import { featureActive, featureData } from '@sb-itops/feature';
import { getAppEnv } from '@sb-itops/app-env';

const { editFirm } = firmManagement;

const WELCOME_MODAL_ID = 'welcomeModal';

const REGION = getRegion();

function dataURLtoBlob(dataurl) {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n) {
    n -= 1;
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

const extract = (property: string, address: any) => {
  if (address[property]) {
    return `${address[property]}, `;
  }
  return '';
};
const extractStreetAddress = (property: string, address: any) => {
  if (property === 'unitNumber' && address[property]) {
    return `${address[property]}/`;
  }
  if (address[property]) {
    return `${address[property]} `;
  }
  return '';
};
const flattenAddress = (address: any) => {
  const streetAddressPropertyOrder = [
    'buildingLevel',
    'unitType',
    'unitNumber',
    'streetNumber',
    'streetName',
    'streetType',
  ];
  const addressPropertyOrder = ['addressLine1', 'addressLine2', 'city', 'state', 'zipCode', 'county', 'locality'];
  let flattenedAddress = '';
  // eslint-disable-next-line no-restricted-syntax
  for (const property of streetAddressPropertyOrder) {
    flattenedAddress += extractStreetAddress(property, address);
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const property of addressPropertyOrder) {
    flattenedAddress += extract(property, address);
  }
  if (flattenedAddress && REGION !== regionType.GB) {
    return flattenedAddress + REGION;
  }
  return '';
};

// Modified from https://devcopilot.smokeball.com/embed/code.js to accomodate our styling
const copilot = (() => {
  let iframe: HTMLIFrameElement | null = null;
  let url = '';
  let state = null;
  let actions = null;
  let funcs = {};
  let navigation = null;
  let authToken = null;
  let schema = null;
  let script = null;
  let onClose: Function | null = null;

  function receiveMessage(event) {
    if (event.data.source === 'copilot') {
      if (!iframe) {
        iframe = document.getElementById('onboarding-wizard') as HTMLIFrameElement | null;
      }
      if (event.data.action === 'init') {
        sendMessage({
          action: 'init',
          authToken,
          state,
          actions,
          schema,
          script,
          navigation,
        });
      } else if (event.data.action === 'action') {
        // Perform a copilot action...
        funcs[event.data.func](event.data.params);
      } else if (event.data.action === 'close') {
        if (onClose) {
          onClose();
        }
      }
    }
  }

  function sendMessage(data) {
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(data, url);
    }
  }

  function resizeFrame() {
    window.addEventListener('resize', () => {
      if (iframe) {
        iframe.style.border = 'none';
        iframe.style.backgroundColor = 'transparent';
        iframe.style.display = 'block';
        iframe.setAttribute('frameborder', '0');
      }
    });
    window.dispatchEvent(new Event('resize'));
  }

  function cleanObj(obj) {
    if (Array.isArray(obj)) {
      // Filter out empty arrays or null values after cleaning each element
      const cleanedArray = obj
        .map((item) => cleanObj(item))
        .filter((item) => item !== null && !(Array.isArray(item) && item.length === 0));
      return cleanedArray.length > 0 ? cleanedArray : null;
    }
    if (obj !== null && typeof obj === 'object') {
      // Process each property in the object
      Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (value === null || value === undefined || value === '') {
          // Remove the property if it is null, undefined, or an empty string
          delete obj[key];
        } else if (typeof value === 'object') {
          // Recursively clean the object or array
          const cleanedValue = cleanObj(value);
          if (
            cleanedValue === null ||
            (typeof cleanedValue === 'object' && Object.keys(cleanedValue).length === 0 && !Array.isArray(cleanedValue))
          ) {
            // Remove the property if the recursive clean returned an empty object or null
            delete obj[key];
          } else {
            obj[key] = cleanedValue;
          }
        }
      });
      return Object.keys(obj).length > 0 ? obj : null;
    }
    return obj;
  }

  return {
    /**
     * Creates a Copilot instance within the target iframe using the configuration provided and launches it.
     * @param {object} config - Configuration object.
     */
    init(config) {
      url = config.url;
      iframe = config.iframe;
      authToken = config.authToken || null;
      onClose = config.onClose || null;
      state = config.state || {};
      schema = config.schema || '';
      script = config.script || '';
      actions = config.actions || [];
      funcs = config.funcs || {};
      navigation = config.navigation || [];

      window.addEventListener('message', receiveMessage, false);
      resizeFrame();

      // wait for iframe to send an 'init' message...
    },
    remove() {
      window.removeEventListener('message', receiveMessage, false);
    },
    /**
     * Calling this function signals to Copilot that an action's function has completed executing.
     * @param {string} funcName - The name of the function that has been executed.
     * @param {string} resultMessage - The message to send back to the Copilot. This will be added to the prompt messages.
     * @param {array} props - Optional array of properties to set in Copilot's memory. Format: [{ prop: string, value: any }]
     */
    func(funcName, resultMessage, props) {
      const data = {
        action: 'func',
        func: funcName,
        message: resultMessage,
        props: props ?? null,
      };
      sendMessage(data);
    },
    /**
     * Sets a property in Copilot's memory. These properties are helpful when custom UIs require information to perform their tasks. Properties are name/value pairs.
     * @param {string} prop - Name of the property to set in Copilot's memory.
     * @param {*} value - Value of the property.
     */
    set(prop, value) {
      const data = {
        action: 'set',
        prop,
        value,
      };
      sendMessage(data);
    },
    open() {},
    close() {
      if (onClose) {
        onClose();
      }
    },
    prepObject(obj) {
      const prepared = JSON.parse(JSON.stringify(obj));
      const result = cleanObj(prepared);
      return result;
    },
  };
})();

// Right now there is no external source for the URL, so it might as well live in here
const copilotHosts = {
  [regionType.AU]: {
    local: 'https://devcopilot.smokeball.com.au',
    dev: 'https://devcopilot.smokeball.com.au',
    rc: 'https://stagingcopilot.smokeball.com.au',
    qa: 'https://copilot.smokeball.com.au',
    live: 'https://copilot.smokeball.com.au',
    production: 'https://copilot.smokeball.com.au',
  },
  [regionType.US]: {
    local: 'https://devcopilot.smokeball.com',
    dev: 'https://devcopilot.smokeball.com',
    rc: 'https://stagingcopilot.smokeball.com',
    qa: 'https://copilot.smokeball.com',
    live: 'https://copilot.smokeball.com',
    production: 'https://copilot.smokeball.com',
  },
  // No GB specific deployments are active right now but if there was, these would be the URLs
  [regionType.GB]: {
    local: 'https://devcopilot.smokeball.co.uk',
    dev: 'https://devcopilot.smokeball.co.uk',
    rc: 'https://stagingcopilot.smokeball.co.uk',
    qa: 'https://copilot.smokeball.co.uk',
    live: 'https://copilot.smokeball.co.uk',
    production: 'https://copilot.smokeball.co.uk',
  },
};

const Container = (props) => {
  const appEnv = getAppEnv().toLowerCase();

  const copilotHost = copilotHosts[getRegion()]?.[appEnv];

  const url = `${copilotHost}/copilot?url=${encodeURIComponent(window.location.href)}`;

  const { onClose, onClickLink } = props;

  const inited = useRef(false);
  const [showPage, setShowPage] = useState(false);

  const { data: firmUtbmsSettingsData, loading: ubtmsLoading } = useCacheQuery(InitFirmUtbmsSettings.query);
  const { data: staffData, loading: staffLoading } = useQuery(LoggedInStaff.query as DocumentNode);
  const { data: firmData, loading: firmLoading } = useQuery(FirmDetails.query as DocumentNode);
  const { data: firmFeeConfigData, loading: firmFeeConfigLoading } = useCacheQuery(InitFirmFeeConfiguration.query);
  const { data, loading: userViewedLoading } = useCacheQuery(InitUserBillingAttributes.query);

  const viewedMessages = data?.userBillingAttributes?.viewedMessages || [];

  const isUtbmsFacetActive = hasFacet(facets.utbms);

  const isUtbmsEnabledForFirm = isUtbmsFacetActive && firmUtbmsSettingsData?.firmUtbmsSettings?.isUtbmsEnabled;

  const shouldInitialize =
    !inited.current && !staffLoading && !firmLoading && !firmFeeConfigLoading && !ubtmsLoading && !userViewedLoading;

  if (shouldInitialize) {
    const details = firmData?.firm || {};
    const mergedStreetAddress = details.businessAddress || {};
    const currentBillingUnit =
      firmFeeConfigData?.firmFeeConfiguration?.billableMinutes || firmFeeConfigurationDefaults.BILLABLE_MINUTES;
    const billingRate = staffData?.loggedInStaff?.rate || 0;

    const config = {
      url,
      onClose,
      authToken: '',
      state: {
        first_name: staffData.loggedInStaff.firstName,
        last_name: staffData.loggedInStaff.lastName,
        client: {
          name: '',
        },
        firm_name: details?.firmName,
        firm: {
          firm_name: details?.firmName,
          name: details?.firmName,
        },
        firm_address: flattenAddress(mergedStreetAddress),
        email: staffData.loggedInStaff.email,
        billing: {
          rate: billingRate,
          increments: currentBillingUnit,
        },
      },
      // DONT change this schema randomly, the AI relies on the position of some of these parameters
      schema: `
      {
        first_name: string, 
        last_name: string, 
        firm: { firm_name: string },
        billing: { rate: number, increments: number },
        client: { name: string },
        matter_description: string,
        matter_client_role: string,
        matter_type_id: string,
        matter_client_name: { first: string, middle?: string, last: string },
      }`,
      script: '',
      navigation: [
        { step: 5, section: 3 },
        { step: 6, section: 4 },
        { step: 7, section: 5 },
      ],
      actions: [
        {
          name: 'saveInformation',
          description: 'Completes the onboarding by saving all collected information.',
          parameters: [
            {
              name: 'json_object',
              type: 'string',
              description: 'The JSON object containing the information',
            },
          ],
          func: 'saveInformation',
        },
      ],
      funcs: {
        uploadLogo: (input: string) => {
          // Trims out `data:image/png;base64,`
          const fileBlob = dataURLtoBlob(input);
          const fileObj = new File([fileBlob], 'data.png');
          fetchPostP({
            path: `/firm-management/upload-logo/firm/:accountId/`,
            fetchOptions: {
              file: fileObj,
            },
          });
          isValidLogoFileP(fileObj)
            .then(() => {
              // allow firms requiring higher resolution logo to apply a multiple of the default limit
              const { logoSizeMultipleAllowed } = (featureActive('BB-9654') && featureData('BB-9654')) || {};

              return fetchPostP({
                path: '/firm-management/upload-logo',
                fetchOptions: {
                  body: JSON.stringify({
                    logo: input,
                    filename: 'data.png',
                    templateId: null,
                    logoSizeMultipleAllowed,
                  }),
                },
              }).catch((err) => {
                messageDisplay.error(err || 'Upload logo file failed.');
              });
            })
            // Swallow validation errors, just dont upload the logo to the default invoice template
            .catch(() => {});
        },
        trackMetric: (params) => sendMetric(params.name, params.properties),
        saveInformation: async ({ json_object }) => {
          const formFields = JSON.parse(json_object);
          const accountId = getAccountId();

          let contactDispatch;
          if (formFields.matter_client_name?.first) {
            contactDispatch = await dispatchCommand({
              type: 'Integration.CreatePersonContact',
              message: {
                isUtbmsEnabled: isUtbmsEnabledForFirm,
                contactFields: {
                  contactType: 'person',
                  personFields: {
                    firstName: formFields.matter_client_name.first,
                    middleName: formFields.matter_client_name.middle,
                    lastName: formFields.matter_client_name.last,
                  },
                },
              },
            });
          }

          let matterId;
          if (formFields.matter_type_id && contactDispatch?.contact?.id) {
            matterId = await createMatter({
              accountId,
              clientIds: [contactDispatch.contact.id],
              description: formFields.matter_description,
              attorneyResponsibleId: staffData?.loggedInStaff?.id,
              matterNumber: `${moment().format('YYYY-MM-DD')}-0001`,
              matterTypeId: formFields.matter_type_id,
              clientRole: formFields.matter_client_role,
            } as any).catch(() => {
              messageDisplay.error(
                "The matter failed to save, please use the 'Create New' button to create your matter",
              );
            });
          }

          const marshalledAddress = {
            state: formFields.firm_address?.state,
            ...(formFields.firm_address?.us_address || {}),
            ...(formFields.firm_address?.au_address || {}),
            ...(formFields.firm_address?.gb_address || {}),
          };

          if (formFields.firm?.firm_name) {
            await editFirm({
              name: formFields.firm.firm_name,
              acn: details.acn || '',
              abn: details.abn || '',
              phoneAreaCode: details.phoneAreaCode || '',
              faxAreaCode: details.faxAreaCode || '',
              phoneNumber: details.phoneNumber || '',
              faxNumber: details.faxNumber || '',
              businessAddress: marshalledAddress,
              mailingAddress: marshalledAddress || {},
            });
          }

          if (formFields.billing) {
            await saveStaffHourlyRate({
              staffId: staffData?.loggedInStaff?.id,
              rate: formFields.billing.rate || 0,
            });

            const feeInterval = formFields.billing.increments || firmFeeConfigurationDefaults.BILLABLE_MINUTES;
            await setFeeInterval(feeInterval);
          }

          if (!viewedMessages.includes(WELCOME_MODAL_ID)) {
            await dispatchCommand({
              type: 'Billing.Shared.Messages.Commands.SaveBillingUserAttributes',
              message: { viewedMessage: WELCOME_MODAL_ID },
            });
          }

          onClose();
          if (matterId) {
            onClickLink({ type: 'matter', id: matterId });
          }
        },
      },
    };
    copilot.init(config);
    setShowPage(true);
    inited.current = true;
  }

  useEffect(
    () => () => {
      if (inited.current) {
        copilot.remove();
      }
    },
    [],
  );

  return (
    <ReactModal
      overlayClassName="overlay"
      className="content"
      ariaHideApp={false}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={false}
      isOpen
    >
      {url && showPage ? (
        <iframe
          style={{ border: 0, background: 'none', width: '100%', height: '100%' }}
          src={url}
          id="onboarding-wizard"
          title="onboarding-wizard"
        />
      ) : (
        <div>
          <Spinner />
        </div>
      )}
    </ReactModal>
  );
};

export const OnboardingWizardIframeContainer = withReduxProvider(Container);

'use strict';

import { getRegion } from '@sb-itops/environment-config';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { store } from '@sb-itops/redux';
import {
  isOperatingChequesEnabled,
} from '@sb-billing/redux/operating-cheque-print-settings';
import * as billingExpenses from 'web/redux/route/home-billing-entries-expenses/feature';
import { getById as getExpenseById } from '@sb-billing/redux/expenses';

import { hasBillingAccess } from 'web/services/user-session-management';
import { isNewTheme } from 'web/services/theme';
import { EXPENSE_MODAL_ID, OPERATING_CHEQUE_DETAILS_MODAL_ID } from 'web/components';
import { featureActive } from '@sb-itops/feature';

const { getSelectedExpenses } = billingExpenses.selectors;
const { toggleExpenses } = billingExpenses.actions;
const billingExpensesReduxPath = billingExpenses.defaultPath;

angular
  .module('sb.billing.webapp')
  .controller('EntriesController', function ($scope, $state, $rootScope, $uibModal, sbDateService, sbLinkService, sbAsyncOperationsService, sbLocalisationService, sbOverrideRedirectService) {
    var that = this;
    that.onClickLink = sbLinkService.onClickLink;
    that.overrideRedirect = sbOverrideRedirectService.overrideNext;
    that.hasBillingAccess = hasBillingAccess;
    that.isNewTheme = isNewTheme();

    // Edit existing expense
    that.onEditApolloExpenseInModal = (apolloExpense) => {
      // LOD
      if (featureActive('BB-13186')) {
        setModalDialogVisible({
          modalId: EXPENSE_MODAL_ID,
          props: {
            scope: 'FirmExpenseEntries/expense-modal',
            expenseId: apolloExpense.id,
            sbAsyncOperationsService,
            onClickLink: sbLinkService.onClickLink,
          },
        });
      } else {
        const legacyExpense = getExpenseById(apolloExpense.id);
        // Legeacy
        const preFillData = {
          ...legacyExpense,
          invoiceNumber: apolloExpense.invoice && apolloExpense.invoice.invoiceNumber,
          date: apolloExpense.expenseDate,
          staffId: legacyExpense.expenseEarnerStaffId,
          matter: legacyExpense.matterId,
          activityCode: legacyExpense.expenseActivityId,
          // defined as 'units' for legacy purposes (expense-entry-controller),
          // should be changed to 'quantity' when refactoring the expense modal
          units: legacyExpense.quantity / 100,  // TODO: decimal-operation
          // defined as 'rate' for legacy purposes (expense-entry-controller),
          // should be changed to 'price' when refactoring the expense modal
          rate: legacyExpense.price / 100, // TODO: decimal-operation
          billable: !!legacyExpense.isBillable,
          waived: !!legacyExpense.waived,
        };

        openExpenseModal(preFillData, { update: true, noClearForm: true, useCommandDispatcher: true });
      }


    };
    // End of Load on Demand

    that.t = sbLocalisationService.t;

    $scope.billingNav.savedFilters.generalFees = $scope.billingNav.savedFilters.generalFees || {};
    $scope.listFilters = $scope.billingNav.savedFilters.generalFees;
    $scope.summaryEnabled = false;
    $scope.summaryStyle = {};
    that.summaryVisible = true;
    that.region = getRegion();
    that.view = {
      isOperatingChequeEnabled: isOperatingChequesEnabled(),
    };

    that.filters = {
      date: $scope.listFilters.date ? sbDateService.to($scope.listFilters.date) : undefined,
      staff: $scope.listFilters.staff ? $scope.listFilters.staff.id : '',
    };

    that.sbData = {};
    that.feeListData = [];
    that.selectedExpenseIds = getSelectedExpenseIds();

    that.showExpenseModal = showExpenseModal;
    that.editExpense = editExpense;
    that.openExpenseModal = openExpenseModal;
    that.toggleSummary = toggleSummary;
    that.dataChangeFunction = dataChangeFunction;
    that.onVisibilityChange = onVisibilityChange;

    that.onExportExpenseCsv = sbAsyncOperationsService.startExportExpenseCsv;
    that.onPrintCheque = (chequeId) => sbAsyncOperationsService.startOperatingChequeCreation([chequeId]);
    that.onNavigateToInvoice = (id) => sbLinkService.onClickLink({ type: 'invoice', id });
    that.onChequeClick = onChequeClick;
    that.onToggleExpense = onToggleExpense;
    that.navOperatingCheques = navOperatingCheques;
    that.getSelectedExpenseIds = getSelectedExpenseIds;
    that.newExpenseEntryLabel = `New ${sbLocalisationService.t('expense')} Entry`;
    that.operatingChequesLabel = `View ${sbLocalisationService.t('operatingCheques')}`;

    $scope.$watch('listFilters.date', function (newVal, oldVal) {
      var newDate = sbDateService.to(newVal);
      if (newVal && newVal !== oldVal && !angular.equals(that.filters.date, newDate)) {
        that.filters.date = newDate;
      }
    });

    $scope.$watch('entries.filters.date', function (newVal, oldVal) {
      var newDate = sbDateService.from(newVal);
      if (newVal && newVal !== oldVal && !angular.equals($scope.listFilters.date, newDate)) {
        $scope.listFilters.date = newDate;
      }
    });

    $scope.$watch('listFilters.staff', function (newVal, oldVal) {
      var staffId = (newVal && newVal.id) || '';
      if (newVal !== oldVal && !angular.equals($scope.listFilters.staff, staffId)) {
        that.filters.staff = staffId;
      }
    });

    $scope.$on('smokeball-data-update-sbOperatingChequePrintSettingsService', () => {
      that.view.isOperatingChequeEnabled = isOperatingChequesEnabled();
    });

    $scope.$on('smokeball-data-update-sbOperatingChequesService', () => {
      that.selectedExpenseIds = getSelectedExpenseIds();
    });

    $scope.$on('smokeball-data-update-sbExpenseService', () => {
      const selectedExpenseIds = getSelectedExpenseIds();

      // deselect any selected expense already attached to an operating cheque
      // e.g. another user may have created an operating cheque with this expense.
      // although this particular multi-user scenario is highly unlikely as we really
      // only support one cheque book with contiguous reference nubmers, it is still
      // good to be able to handle this as it sets us up well to be able to deal with
      // all multi-user scenarios from the onset.
      const expenseIdsToDeselect = selectedExpenseIds
        .filter((expenseId) => getExpenseById(expenseId).operatingChequeId);
      store.dispatch(toggleExpenses({ expenseIds: expenseIdsToDeselect }));

      // get updated selected expense ids
      that.selectedExpenseIds = getSelectedExpenseIds();
    });

    function navOperatingCheques() {
      $state.go('home.billing.accounts.transactions.operating-cheques');
    }

    function onVisibilityChange(value) {
      that.summaryVisible = value;
    }

    function onChequeClick(chequeId) {
      if (featureActive('BB-11425')) {
        setModalDialogVisible({
          modalId: OPERATING_CHEQUE_DETAILS_MODAL_ID,
          props: {
            chequeId,
          },
        });

        return;
      }

      $uibModal.open({
        templateUrl: 'ng-components/operating-cheque-details/modal/operating-cheque-details-modal.html',
        controller: 'sbOperatingChequeDetailsModalController',
        size: 'lg',
        resolve: {
          chequeId: () => chequeId,
        },
        backdrop: 'static',
      });
    }

    function toggleSummary() {
      $scope.summaryEnabled = !$scope.summaryEnabled;

      if (!$scope.summaryEnabled) {
        $scope.summaryStyle = {};
      } else {
        $scope.summaryStyle = { display: 'block' };
      }
    }

    function openExpenseModal(preFill, features) {
      $uibModal.open({
        templateUrl: 'ng-components/expense-entry/modal/expense-modal.html',
        controller: 'SbExpenseModalController',
        resolve: {
          sbExpenseModalConfig: function () {
            return {
              preFill: preFill,
              features: features,
            };
          },
        },
        backdrop: 'static',
      });
    }

    // Create new expense
    function showExpenseModal() {
      // LOD
      if(featureActive('BB-13186')) {
        setModalDialogVisible({
          modalId: EXPENSE_MODAL_ID,
          props: {
            scope: 'FirmExpenseEntries/expense-modal',
            sbAsyncOperationsService,
          },
        });
      } else {
        // Legacy
        openExpenseModal(undefined, { saveAndNew: true });
      }
    }

    function editExpense(expense) {
      const line = {
        ...expense,
        staffId: expense.expenseEarnerStaffId,
        activityCode: expense.expenseActivityId,
        matter: expense.matterId,
        units: expense.quantity / 100,  // TODO: decimal-operation
        rate: expense.price / 100,  // TODO: decimal-operation
        billable: !!expense.isBillable,
        waived: !!expense.waived,
      };

      openExpenseModal(line, { update: true, noClearForm: true });
    }

    function onToggleExpense() {
      that.selectedExpenseIds = getSelectedExpenseIds();
    }

    function getSelectedExpenseIds() {
      const state = store.getState();

      return [...getSelectedExpenses(state[billingExpensesReduxPath])];
    }

    function dataChangeFunction(key, doc) {
      if (doc && doc.data) {
        _.set(that.sbData, key, doc.data);
      } else {
        _.set(that.sbData, key, doc);
      }
    }
  });

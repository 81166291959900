angular.module('sb.billing.webapp').controller('sbBankReconController', function ($scope, $state, $uibModal, sbTabService, sbLinkService) {
  const that = this;
  that.trustAccountId = $state.params.trustAccountId; 

  that.start;
  that.end;
  that.onClickLink = sbLinkService.onClickLink;
  that.closeCurrentTab = sbTabService.closeCurrent;
  that.openTransactionDetailsModal = openTransactionDetailsModal;

  $scope.$on('$stateChangeSuccess', () => {
    that.trustAccountId = $state.params.trustAccountId;  
  });

  function openTransactionDetailsModal(transactionId, isDepositSlip) {
    if (!isDepositSlip) {
      $uibModal.open({
        templateUrl: 'ng-components/bank-account-transaction-details/modal/bank-account-transaction-details-modal.html',
        controller: 'SbBankAccountTransactionDetailsModalController',
        size: 'lg',
        resolve: {
          transactionId: () => transactionId,
          transaction: null,
          matterId: null,
          contactId: null,
          showHidden: null,
        },
        backdrop: 'static',
      });
    }
  }
});
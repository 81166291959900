import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Styles from './StaffSettings.module.scss';

import { StaffAccounts } from '../staff-settings-accounts';
import { StaffPermissions } from '../staff-settings-permissions';

const StaffSettings = ({ availableTabs, selectedTab, onTabChange, options }) => (
  <div className={Styles.staffSettings}>
    <h3>Staff & Users</h3>
    {
      <div className="sb-tab-header">
        <ul className={classnames('nav nav-pills')}>
          {availableTabs.map((tabLabel) => (
            <li key={tabLabel} className={classnames({ active: tabLabel === selectedTab })}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={() => onTabChange(tabLabel)} className={Styles.providerSelector}>
                {tabLabel}
              </a>
            </li>
          ))}
        </ul>
      </div>
    }
    {selectedTab === options.ACCOUNTS && <StaffAccounts />}
    {selectedTab === options.PERMISSIONS && <StaffPermissions />}
  </div>
);

StaffSettings.displayName = 'StaffSettings';

StaffSettings.propTypes = {
  availableTabs: PropTypes.array.isRequired,
  selectedTab: PropTypes.string.isRequired,
  onTabChange: PropTypes.func.isRequired,
  options: PropTypes.object,
};

StaffSettings.defaultProps = {
  options: undefined,
};

export default StaffSettings;

'use strict';

const { capitalize, sortByOrder } = require('@sb-itops/nodash');
const { paymentSource, bankTransferSource } = require('../entities/constants');

function getDepositSources({ t, supportsCMA, supportsChequeTrustPaymentTypes, supportsDirectDebit }) {
  const localizedCheck = capitalize(t('cheque'));

  // accountDisplay is what the angular modals call label
  const sources = {
    paymentDepositSource: [
      { label: 'Bank Transfer', accountDisplay: 'Bank Transfer', value: paymentSource.BANK_TRANSFER },
      { label: `${localizedCheck}`, accountDisplay: `${localizedCheck}`, value: paymentSource.CHECK },
      { label: 'Cash', accountDisplay: 'Cash', value: paymentSource.CASH },
      { label: 'Credit Card', accountDisplay: 'Credit Card', value: paymentSource.CREDIT_CARD },
    ],
    trustPaymentSource: [
      { label: 'Bank Transfer', accountDisplay: 'Bank Transfer', value: paymentSource.BANK_TRANSFER },
    ],
    cmaPaymentSource: [],
  };

  if (supportsCMA) {
    sources.cmaPaymentSource = [
      { label: `Bank ${localizedCheck}`, accountDisplay: `Bank ${localizedCheck}`, value: paymentSource.BANK_CHECK },
      { label: 'Bank Transfer', accountDisplay: 'Bank Transfer', value: paymentSource.BANK_TRANSFER },
    ];
  }

  if (supportsChequeTrustPaymentTypes) {
    sources.trustPaymentSource.push({
      label: `Bank ${localizedCheck}`,
      accountDisplay: `Bank ${localizedCheck}`,
      value: paymentSource.BANK_CHECK,
    });
    sources.trustPaymentSource.push({
      label: `${t('trustCheque')}`,
      accountDisplay: `${t('trustCheque')}`,
      value: paymentSource.TRUST_CHECK,
    });
    if (supportsDirectDebit) {
      sources.trustPaymentSource.push({
        label: 'Direct Debit',
        accountDisplay: 'Direct Debit',
        value: paymentSource.DIRECT_DEBIT,
      });
    }
  } else {
    sources.trustPaymentSource.push({
      label: `${localizedCheck}`,
      accountDisplay: `${localizedCheck}`,
      value: paymentSource.CHECK,
    });
  }

  const sortedTrustPaymentSource = sortByOrder(sources.trustPaymentSource, ['accountDisplay'], ['asc']);
  const sortedDepositPaymentSource = sortByOrder(sources.paymentDepositSource, ['accountDisplay'], ['asc']);

  return {
    paymentDepositSource: sortedDepositPaymentSource,
    trustPaymentSource: sortedTrustPaymentSource,
    cmaPaymentSource: sources.cmaPaymentSource,
  };
}

function getDefaultSource(sources) {
  // BB-7638, payment deposit source default to bank transfer
  return sources.find((source) => source.value === paymentSource.BANK_TRANSFER);
}

// AU trust payments only
function getBankTransferDepositSources(supportsTrustPaymentBankTransferType) {
  const sources = {
    trustPaymentSource: [
      { label: 'Direct Deposit', accountDisplay: 'Direct Deposit', value: bankTransferSource.DIRECT_DEPOSIT },
      { label: 'BPAY', accountDisplay: 'BPAY', value: bankTransferSource.BPAY },
    ],
  };
  return supportsTrustPaymentBankTransferType ? sources : {};
}

const getSources = ({ sourceType, t, supportsCMA, supportsChequeTrustPaymentTypes, supportsDirectDebit }) =>
  getDepositSources({ t, supportsCMA, supportsChequeTrustPaymentTypes, supportsDirectDebit })[sourceType] || [];
const getBankTransferSources = ({ sourceType, supportsTrustPaymentBankTransferType }) =>
  getBankTransferDepositSources(supportsTrustPaymentBankTransferType)[sourceType] || [];

module.exports = {
  getSources,
  getDefaultSource,
  getBankTransferSources,
};

import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { roundCents } from '@sb-billing/bankers-rounding';
import {
  calculateTaxAmount as calculateExpenseTaxAmount,
  getBillableAmountIncludingOutputTax,
  getBillableOutputTax,
  getRawAmount as getRawExpenseAmount,
} from '@sb-billing/business-logic/expense/services';
import { calculateInvoiceTotals } from '@sb-billing/business-logic/invoice-totals';
import { calculateFeeAmountTax } from '@sb-billing/business-logic/fee/services';
import {
  constants as invoiceSettingsConstants,
  interpolateCustomTextTitle,
} from '@sb-billing/business-logic/invoice-settings';
import { PrintNotApplicable } from '@sb-billing/business-logic/cheques';
import { entryType as entryTypeEnum, emailMessages } from '@sb-billing/business-logic/shared/entities';
import { decodeHtml, encodeHtml } from '@sb-billing/encode-decode-html-invoice-settings';
import { featureActive } from '@sb-itops/feature';
import { getTextContent } from '@sb-itops/html';
import { Spinner, useTranslation } from '@sb-itops/react';
import composeHooks from '@sb-itops/react-hooks-compose';
import * as forms from '@sb-itops/redux/forms2';
import { useForm } from '@sb-itops/redux/forms2/use-form';
import { store } from '@sb-itops/redux';
import { withScopedFeature } from '@sb-itops/redux/hofs';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import {
  EXPENSE_MODAL_ID,
  FEE_MODAL_ID,
  DRAFT_INVOICE_PREVIEW_MODAL_ID,
  INVOICE_EMAIL_MODAL_ID,
  INVOICE_COMMUNICATE_MODAL_ID,
} from 'web/components';
import { useReduxActionOnce, useSingleLedesDownload, useAsyncWithRetries } from 'web/hooks';
import {
  defaultSurchargeSettings,
  determineSurchargeSettings,
  surchargeTypeValues,
  surchargeApplyToValues,
} from '@sb-billing/business-logic/invoice-surcharge';
import { capitalize } from '@sb-itops/nodash';
import { getLogger } from '@sb-itops/fe-logger';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { actions as preDraftActions } from '@sb-billing/redux/invoice-pre-draft';
import { useDispatch } from 'react-redux';
import { builder, error as displayError, getError, info as displayInfo } from '@sb-itops/message-display';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { getFirmPaymentProviderPaymentSettings } from '@sb-billing/business-logic/payment-provider/services';
import { sentViaTypes } from '@sb-billing/business-logic/correspondence-history';

import { BillingDraftInvoiceRoute } from './BillingDraftInvoiceRoute';
import { draftInvoiceSchema } from './BillingDraftInvoiceRoute.yup';
import { getDefaultFieldValues, getInitialQuickPayments } from './default-form-values';
import { debouncedSave, onSaveExpense, onSaveFee } from './entry-save';
import { getInvoiceConfiguration } from './get-invoice-configuration';
import { marshallInvoice } from './marshall-invoice';

const log = getLogger('BillingDraftInvoiceRouteFormsContainer');
const { savePreDraftInvoice } = preDraftActions;

const hooks = () => ({
  useFormHooks: ({
    activityCodes,
    firmTaxRateBasisPoints,
    invoice,
    invoiceId,
    invoiceSettingsTemplateEntity,
    invoiceSettingsTemplateFirmDefault,
    isNewInvoice,
    matter,
    matterId,
    matterInvoiceSettings,
    onFetchInvoiceSettingsTemplateEntity,
    onFetchUnbilledExpenses,
    onFetchUnbilledFees,
    openModal,
    preselectedExpenseIds,
    preselectedFeeIds,
    region,
    scope,
    unbilledExpenses,
    unbilledFees,
    bankBalanceType,
    balances,
    protectedTrustFundsAmount,
    preferredBankAccountTypes,
    lastTrustChequeNumber,
    nextTrustChequeNumber,
    trustChequeNumberLoading,
    onFetchAvailableTrustChequeNumbers,
    activeProviderType,
    activeProviderFormattedSettings,
    provideShowRetainerOption,
    onClickLink,
    closeCurrentTab,
    sbSaveInvoiceCommand,
    sbInvoiceSendService,
    lastInvoice,
    lastInvoiceLoading,
    sbAsyncOperationsService,
  }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const entriesInitialised = useRef();
    const templateResetRef = useRef();
    const draftInvoiceForm = useForm({ scope, schema: draftInvoiceSchema });
    const formValues = draftInvoiceForm.formValues;
    const isDataReady =
      invoiceSettingsTemplateFirmDefault &&
      matter &&
      (invoice || isNewInvoice) &&
      preferredBankAccountTypes !== undefined;
    const isAdditionalDataReady = !!unbilledFees && !!unbilledExpenses && !lastInvoiceLoading;
    const [triggerValidate, setTriggerValidate] = useState(false);

    const debouncedSaveExpense = useRef(null);
    const [editedExpenseFields, setEditedExpenseFields] = useState({});
    if (debouncedSaveExpense.current === null) {
      debouncedSaveExpense.current = debouncedSave({ setEditedFields: setEditedExpenseFields, onSave: onSaveExpense });
    }

    const debouncedSaveFee = useRef(null);
    const [editedFeeFields, setEditedFeeFields] = useState({});
    if (debouncedSaveFee.current === null) {
      debouncedSaveFee.current = debouncedSave({ setEditedFields: setEditedFeeFields, onSave: onSaveFee });
    }

    const isEvergreenRetainerOnInvoiceEnabled = featureActive('BB-6908');
    const isEntryLineNumbersEnabled = featureActive('BB-12394');
    const isSurchargeEnabled = featureActive('BB-7270');
    const isShowLessFundsInTrustEnabled = featureActive('BB-6398');
    const isSupplementaryTablesPageBreakEnabled = featureActive('BB-12385');
    const isProtectedTrustFundsEnabled = featureActive('BB-8671');

    const config = isDataReady
      ? getInvoiceConfiguration({
          invoice,
          invoiceSettingsTemplateFirmDefault,
          matter,
          matterInvoiceSettings,
          matterBillingConfiguration: matter.billingConfiguration,
          preferredTemplate: formValues.preferredTemplate,
          configOverrides: formValues.configOverrides,
          isEvergreenRetainerOnInvoiceEnabled,
          isEntryLineNumbersEnabled,
          isSupplementaryTablesPageBreakEnabled,
        })
      : {};

    // Show the current template in template dropdown. This avoids having to
    // fetch additional templates until the user opts to use the typeahead.
    const invoiceSettingsTemplateOptionValue = config?.template
      ? {
          value: config.template.id,
          label: config.template.isDeleted ? `${config.template.name} (Deleted)` : config.template.name,
        }
      : undefined;

    // These are the display values for the title/subtitle. Performed here
    // rather than in getInvoiceConfiguration because this needs to be done
    // after we apply form overrides
    config.titleText = config.titleLine1Overridden ? config.titleLine1CustomText : config.titleLine1DefaultText;
    config.subtitleText = config.titleLine2Overridden ? config.titleLine2CustomText : config.titleLine2DefaultText;
    if (matter && featureActive('BB-12386')) {
      config.titleText = interpolateCustomTextTitle({
        t,
        text: config.titleText,
        matterNumber: matter.matterNumber,
        matterClientString: matter.clientDisplay,
        matterTypeName: matter.matterType.name,
        matterDescription: matter.description,
      });
      config.subtitleText = interpolateCustomTextTitle({
        t,
        text: config.subtitleText,
        matterNumber: matter.matterNumber,
        matterClientString: matter.clientDisplay,
        matterTypeName: matter.matterType.name,
        matterDescription: matter.description,
      });
    }
    const { footer: decodedFooter } = decodeHtml({ footer: config.footer });
    config.footer = decodedFooter;

    const finalFormData = {
      ...formValues,
      config,
      invoiceSettingsTemplateOptionValue,
      originalInvoice: {
        status: invoice?.status,
      },
    };

    // includeNonBillableItems determines whether non-billable fees and expenses
    // should be fetched by default. showNonBillableFees / showNonBillableExpenses
    // can override this and determine whether they should be displayed
    const { includeNonBillableItems } = invoice?.layout
      ? invoice.layout
      : config?.template?.settings?.defaultLayout || {};

    // Need to make sure that all the relevant data has been fetched to prevent
    // unnecessary requests to the back end before we fetch unbilled entries.
    // This will allow the useEffect below to run and refetch as needed
    if (!entriesInitialised.current && isDataReady) {
      entriesInitialised.current = true;
    }

    useEffect(() => {
      if (!entriesInitialised.current) {
        return undefined;
      }

      onFetchUnbilledFees({
        includeNonBillableItems:
          formValues.showNonBillableFeesEverSelected || formValues.showNonBillableFees || includeNonBillableItems,
      });
      return undefined;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entriesInitialised.current, includeNonBillableItems, formValues.showNonBillableFees]);

    // Fee tax rate is saved on an invoice
    const taxRate = invoice?.feeTaxRate ?? firmTaxRateBasisPoints;

    // Split invoice entries by type
    const { invoiceFees, invoiceExpenses } = useMemo(() => {
      const entries = (invoice?.entries || []).reduce(
        (acc, entry) => {
          if (!entry) {
            return acc;
          }

          if (entry.type === entryTypeEnum.FIXED || entry.type === entryTypeEnum.TIME) {
            acc.invoiceFees.push(entry.feeEntity);
          }

          if (entry.type === entryTypeEnum.EXPENSE) {
            acc.invoiceExpenses.push(entry.expenseEntity);
          }

          return acc;
        },
        { invoiceFees: [], invoiceExpenses: [] },
      );

      return entries;
    }, [invoice?.entries]);

    // Merge invoice fees and unbilled matter fees. If unbilledFees is falsy,
    // it means that the data has not finished loading
    // Under a race condition where a fee is add to an invoice but has not been marked as billed yet, the same fee is present in both and results in duplications in feeList
    const feeList = unbilledFees ? [...invoiceFees, ...unbilledFees] : [];

    let { sortedFeeList } = feeList.reduce(
      (acc, feeEntry) => {
        // If we have already seen this fee, skip it to remove duplications in feeList.
        if (!acc.seenFeeIds.has(feeEntry.id)) {
          const fee = {
            ...feeEntry,
            ...(editedFeeFields[feeEntry.id] || {}),
          };

          const { amountInclTax, amountExclTax, billableAmountInclTax } = calculateFeeAmountTax({
            fee,
            taxRate,
            region,
          });
          fee.amount = fee.amountIncludesTax ? amountInclTax : amountExclTax;
          fee.billableAmountInclTax = billableAmountInclTax;

          if (
            // if this field has a designated index
            formValues.feeIndex?.[fee.id] !== undefined
          ) {
            // if there is another item currently occupying that index, move it to the back
            if (acc.sortedFeeList[formValues.feeIndex[fee.id]]) {
              acc.sortedFeeList.push(acc.sortedFeeList[formValues.feeIndex[fee.id]]);
            }
            acc.sortedFeeList[formValues.feeIndex[fee.id]] = fee;
          } else {
            acc.sortedFeeList.push(fee);
          }
          acc.seenFeeIds.add(feeEntry.id);
        }
        return acc;
      },
      { sortedFeeList: [], seenFeeIds: new Set() },
    );
    // Since sorted fees are places in their position after filtering, there may be empty items in the array
    sortedFeeList = sortedFeeList.filter((item) => item);

    // While we filter out unbilled matter fees in the query, both billable
    // and non-billable entries can be saved on an invoice. At the time of
    // fetching invoice data, we don't have access to the `showNonBillableFees`
    // value. Fetching all entries on an invoice is desired, however we need
    // to hide the non-billable fees unless `showNonBillableFees` is true.
    const filteredFeeList = formValues.showNonBillableFees
      ? sortedFeeList
      : sortedFeeList.filter((fee) => fee.isBillable || fee.isBillable === null || !draftInvoiceForm.formInitialised);

    useEffect(() => {
      if (!entriesInitialised.current) {
        return undefined;
      }

      onFetchUnbilledExpenses({
        includeNonBillableItems:
          formValues.showNonBillableExpensesEverSelected ||
          formValues.showNonBillableExpenses ||
          includeNonBillableItems,
      });
      return undefined;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entriesInitialised.current, includeNonBillableItems, formValues.showNonBillableExpenses]);

    // Merge invoice expenses and unbilled matter expenses. If unbilledExpenses is falsy,
    // it means that the data has not finished loading
    // Under a race condition where an expense is add to an invoice but has not been marked as billed yet, the same expense is present in both and results in duplications in expenseList
    const expenseList = unbilledExpenses ? [...invoiceExpenses, ...unbilledExpenses] : [];

    let { sortedExpenseList } = expenseList.reduce(
      (acc, expenseEntry) => {
        // If we have already seen this expense, skip it to remove duplications in expenseList
        if (!acc.seenExpenseIds.has(expenseEntry.id)) {
          const expense = {
            ...expenseEntry,
            ...(editedExpenseFields[expenseEntry.id] || {}),
          };

          const billableTax = roundCents(getBillableOutputTax(expense));
          const billableAmountInclTax = roundCents(getBillableAmountIncludingOutputTax(expense));
          expense.billableTax = billableTax;
          expense.billableAmountInclTax = billableAmountInclTax;
          expense.amount = getRawExpenseAmount(expense);

          if (
            // if this field has a designated index
            formValues.expenseIndex?.[expense.id] !== undefined
          ) {
            // if there is another item currently occupying that index, move it to the back
            if (acc.sortedExpenseList[formValues.expenseIndex[expense.id]]) {
              acc.sortedExpenseList.push(acc.sortedExpenseList[formValues.expenseIndex[expense.id]]);
            }
            acc.sortedExpenseList[formValues.expenseIndex[expense.id]] = expense;
          } else {
            acc.sortedExpenseList.push(expense);
          }
          acc.seenExpenseIds.add(expenseEntry.id);
        }
        return acc;
      },
      { sortedExpenseList: [], seenExpenseIds: new Set() },
    );
    // Since sorted expenses are places in their position after filtering, there may be empty items in the array
    sortedExpenseList = sortedExpenseList.filter((item) => item);

    // While we filter out unbilled matter expenses in the query, both billable
    // and non-billable entries can be saved on an invoice. At the time of
    // fetching invoice data, we don't have access to the `showNonBillableExpenses`
    // value. Fetching all entries on an invoice is desired, however we need
    // to hide the non-billable expenses unless `showNonBillableExpenses` is true.
    const filteredExpenseList = formValues.showNonBillableExpenses
      ? sortedExpenseList
      : sortedExpenseList.filter((expense) => expense.isBillable || !draftInvoiceForm.formInitialised);

    const [expandedExpenses, setExpandedExpenses] = useState(true);
    const [expandedTimeAndFees, setExpandedTimeAndFees] = useState(true);
    const [expandedSummary, setExpandedSummary] = useState(false);
    const descriptionOnDemandEnabled =
      featureActive('BB-5725') && featureActive('BB-6865') && config.eInvoiceOptions?.enableDescriptionOnDemand;

    // Using useEffect here to set the value as we need to wait for the invoice
    // to be fetched in order to generate the config object correctly
    useEffect(() => {
      if (!descriptionOnDemandEnabled) {
        return undefined;
      }

      setExpandedSummary(!!descriptionOnDemandEnabled);

      return undefined;
    }, [descriptionOnDemandEnabled]);

    // Placeholder text depends on DoD being enabled for this invoice or not
    const summaryPlaceholderText = descriptionOnDemandEnabled
      ? `This summary will be displayed at the top of your eInvoice. Try and be descriptive to help ${t(
          'minimise',
        )} requests for additional information.`
      : 'This summary will be included in your invoice email.';

    const showDodSummaryError = !!(
      descriptionOnDemandEnabled &&
      draftInvoiceForm.submitFailed &&
      draftInvoiceForm.formFields.summary?.invalidReason
    );

    // Initialise the form.
    if (!draftInvoiceForm.formInitialised && isDataReady && isAdditionalDataReady) {
      draftInvoiceForm.onInitialiseForm(
        getDefaultFieldValues({
          invoice,
          matter,
          paymentDueDays: config.paymentDueDays,
          sortedFees: sortedFeeList,
          sortedExpenses: sortedExpenseList,
          preselectedExpenseIds,
          preselectedFeeIds,
          creditBankAccountId: balances.CREDIT.bankAccountId,
          operatingBankAccountId: balances.OPERATING.bankAccountId,
          trustBankAccountId: balances.TRUST.bankAccountId,
          surchargeEnabled: isSurchargeEnabled,
          matterInvoiceSettings,
          template: config.template,
          bankBalanceType,
          lastInvoiceIssuedDate: lastInvoice?.issuedDate,
        }),
      );
      setTriggerValidate(!triggerValidate);

      if (!isNewInvoice) {
        // Tag invoice as recent if it already exists
        saveRecentInvoice(invoiceId);
      }
    }

    // Basic draft invoice info - original draftInvoice is named draftInvoiceMarshalled in this file
    const draftInvoice = {
      isNewInvoice,
      invoiceNumber: invoice?.invoiceNumber,
    };

    const onSetFieldValue = (field, value) => {
      draftInvoiceForm.onFieldValueSet(field, value);
      setTriggerValidate(!triggerValidate);
    };
    const onUpdateFieldValue = (field, newValue) => {
      draftInvoiceForm.onUpdateFields({ [field]: newValue });
      setTriggerValidate(!triggerValidate);
    };
    const onUpdateFieldValues = (fieldValues) => {
      draftInvoiceForm.onUpdateFields(fieldValues);
      setTriggerValidate(!triggerValidate);
    };

    const onUpdateContacts = (newContacts) => {
      onSetFieldValue('selectedContacts', newContacts);
    };

    const onUpdateTemplate = (newTemplateId) => {
      if (!newTemplateId) {
        return;
      }

      // If it is the currently fetched template, apply it
      if (newTemplateId === invoiceSettingsTemplateEntity?.id) {
        onSetFieldValue('configOverrides', undefined);
        // Set the template in state so that it can be retrieved if the user
        // navigates away from the page then returns. Must use onFieldValueSet
        // to force overwriting any existing object, as onUpdateFields will
        // throw if attempting to overwrite nested objects.
        onSetFieldValue('preferredTemplate', invoiceSettingsTemplateEntity);
        onSetFieldValue('isTemplateWithDefaults', false);

        if (draftInvoiceForm.formInitialised && isDataReady) {
          const invoiceConfig = getInvoiceConfiguration({
            invoice,
            invoiceSettingsTemplateFirmDefault,
            matter,
            matterInvoiceSettings,
            matterBillingConfiguration: matter.billingConfiguration,
            preferredTemplate: invoiceSettingsTemplateEntity,
            isEvergreenRetainerOnInvoiceEnabled,
            isEntryLineNumbersEnabled,
          });

          const currentInvoice = {
            ...invoice,
            layout: undefined,
            dueDate: undefined,
            issuedDate: moment(formValues.issueDate).format('YYYYMMDD'),
          };

          const {
            showExpenseEntriesAs,
            showFeesEntriesAs,
            expenseListAppend,
            feeListAppend,
            feeSummaryLineDescription,
            expenseSummaryLineDescription,
            dueDate,
          } = getDefaultFieldValues({
            invoice: currentInvoice,
            matter,
            matterInvoiceSettings,
            paymentDueDays: invoiceConfig.paymentDueDays,
            preselectedExpenseIds,
            preselectedFeeIds,
            sortedExpenses: sortedExpenseList,
            sortedFees: sortedFeeList,
            template: invoiceSettingsTemplateEntity || invoiceSettingsTemplateFirmDefault,
          });

          onUpdateFieldValues({
            showExpenseEntriesAs,
            showFeesEntriesAs,
            expenseListAppend,
            feeListAppend,
            feeSummaryLineDescription,
            expenseSummaryLineDescription,
            dueDate,
          });

          // Summary should expand or collapse depending on the new templates DoD settings
          // The exception is we will keep the summary open if it is populated already
          setExpandedSummary(
            !!invoiceSettingsTemplateEntity?.settings?.eInvoiceOptions?.options?.enableDescriptionOnDemand ||
              !!getTextContent(formValues.summary),
          );
        }

        return;
      }

      // Fetch a new template
      onFetchInvoiceSettingsTemplateEntity(newTemplateId);
    };

    useEffect(() => {
      // We are using a invoice settings template typeahead component, which
      // initiates a fetch via onFetchInvoiceSettingsTemplateEntity. Once an
      // invoiceSettingsTemplateEntity is fetched template, we apply it using
      // this hook.
      if (!invoiceSettingsTemplateEntity?.id) {
        return;
      }

      // The first render that gets called after the user executes
      // onResetDefaultTemplate causes the template to be re-applied via this
      // hook. We use a ref to prevent this occurring.
      if (templateResetRef.current) {
        templateResetRef.current = false;
        return;
      }

      if (invoiceSettingsTemplateEntity.id !== formValues.preferredTemplate?.id) {
        // User changed the template ID
        onUpdateTemplate(invoiceSettingsTemplateEntity.id);

        return;
      }

      if (invoiceSettingsTemplateEntity.lastUpdated !== formValues.preferredTemplate?.lastUpdated) {
        // Update likely came through a notification, update with latest values
        // draftInvoiceForm.onFieldValueSet('preferredTemplate', invoiceSettingsTemplateEntity);
        onUpdateTemplate(invoiceSettingsTemplateEntity.id);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoiceSettingsTemplateEntity, formValues.preferredTemplate?.id]);

    // If any of the invoice options are changed by the user, we provide a
    // "Reset to default" button that calls this function
    const onResetDefaultTemplate = () => {
      onSetFieldValue('configOverrides', undefined);
      onSetFieldValue('preferredTemplate', undefined);
      onSetFieldValue('isTemplateWithDefaults', true);
      templateResetRef.current = true;
    };

    const onUpdateInvoiceConfigurationField = (field, newValue) => {
      onUpdateFieldValues({
        [`configOverrides.invoiceAdditionalOptions.${field}`]: newValue,
        isTemplateWithDefaults: false,
      });
    };

    const onUpdateInvoiceConfigurationTitleField = (newValue) => {
      onUpdateFieldValues({
        'configOverrides.titleLine1Option': invoiceSettingsConstants.titleLine1TypeById.Custom,
        'configOverrides.titleLine1CustomText': newValue,
        'configOverrides.titleLine1Overridden': true,
        isTemplateWithDefaults: false,
      });
    };

    const onUpdateInvoiceConfigurationSubtitleField = (newValue) => {
      onUpdateFieldValues({
        'configOverrides.titleLine2Option': invoiceSettingsConstants.titleLine2TypeById.Custom,
        'configOverrides.titleLine2CustomText': newValue,
        'configOverrides.titleLine2Overridden': true,
        isTemplateWithDefaults: false,
      });
    };

    const onUpdateInvoiceConfigurationFooterField = (value, delta, source) => {
      if (source !== 'user') {
        // Ignore any changes made by react-quill itself instead of user input
        return;
      }

      if (value !== finalFormData.config.footer) {
        const { footer: encodedFooter } = encodeHtml({ footer: value });

        onUpdateFieldValues({
          'configOverrides.footer': encodedFooter,
          'configOverrides.footerOverridden': true,
          isTemplateWithDefaults: false,
        });
      }
    };

    const onUpdateSelectedFees = (newSelectedFees) => {
      onSetFieldValue('selectedFeeIds', newSelectedFees);
    };

    const onUpdateSelectedExpenses = (newSelectedExpenses) => {
      onSetFieldValue('selectedExpenseIds', newSelectedExpenses);
    };

    const onChangeFeeOrder = (indexToMove, targetIndex) => {
      // This function is a little complicated because the formFields.feeIndex
      // map contains all entries, regardless of whether they are hidden via
      // the `showNonBillableFees` option, and the indexToMove/targetIndex
      // relate to the filtered entries

      // Find the actual index that needs to be moved by matching the fee.id
      // from the `filteredFeeList` with the full sortedFeeList
      const feeIdToMove = filteredFeeList[indexToMove]?.id;
      // We cannot use formFields.feeIndex[feeIdTarget] below because for
      // new invoices with new fees the formFields.feeIndex map is empty
      const actualIndexToMove = sortedFeeList.findIndex((fee) => fee.id === feeIdToMove);

      const feeIdTarget = filteredFeeList[targetIndex]?.id;
      const actualTargetIndex = sortedFeeList.findIndex((fee) => fee.id === feeIdTarget);

      const newSortedFeeList = [...sortedFeeList];
      newSortedFeeList.splice(actualIndexToMove, 1);
      newSortedFeeList.splice(actualTargetIndex, 0, sortedFeeList[actualIndexToMove]);

      onUpdateFieldValue(
        'feeIndex',
        newSortedFeeList.reduce((acc, fee, index) => {
          acc[fee.id] = index;
          return acc;
        }, {}),
      );
    };

    const onChangeExpenseOrder = (indexToMove, targetIndex) => {
      // Find the actual index that needs to be moved by matching the expense.id
      // from the `filteredExpenseList` with the full sortedExpenseList
      const expenseIdToMove = filteredExpenseList[indexToMove]?.id;
      // We cannot use formFields.expenseIndex[expenseIdTarget] below because for
      // new invoices with new expenses the formFields.expenseIndex map is empty
      const actualIndexToMove = sortedExpenseList.findIndex((expense) => expense.id === expenseIdToMove);

      const expenseIdTarget = filteredExpenseList[targetIndex]?.id;
      const actualTargetIndex = sortedExpenseList.findIndex((expense) => expense.id === expenseIdTarget);

      const newSortedExpenseList = [...sortedExpenseList];
      newSortedExpenseList.splice(actualIndexToMove, 1);
      newSortedExpenseList.splice(actualTargetIndex, 0, sortedExpenseList[actualIndexToMove]);

      onUpdateFieldValue(
        'expenseIndex',
        newSortedExpenseList.reduce((acc, expense, index) => {
          acc[expense.id] = index;
          return acc;
        }, {}),
      );
    };

    const onSortFeesByDate = () => {
      if (!sortedFeeList.length) {
        return;
      }

      const byDate = (a, b) =>
        a.feeDate - b.feeDate || new Date(a.createdTimestamp).getTime() - new Date(b.createdTimestamp).getTime();

      onUpdateFieldValue(
        'feeIndex',
        sortedFeeList.sort(byDate).reduce((acc, fee, index) => {
          acc[fee.id] = index;
          return acc;
        }, {}),
      );
    };

    const onSortExpensesByDate = () => {
      if (!sortedExpenseList.length) {
        return;
      }

      const byDate = (a, b) =>
        a.expenseDate - b.expenseDate ||
        new Date(a.createdTimestamp).getTime() - new Date(b.createdTimestamp).getTime();

      onUpdateFieldValue(
        'expenseIndex',
        sortedExpenseList.sort(byDate).reduce((acc, expense, index) => {
          acc[expense.id] = index;
          return acc;
        }, {}),
      );
    };

    // Clear the form if the tab is closed, otherwise old values will stick
    // around if it is opened again. Unlike other LOD containers, we want to
    // keep the form data until the user decides to close the tab.
    useReduxActionOnce('smokeball-tab-closed', ([tab]) => {
      if (
        tab.type === 'draftinvoice' &&
        (tab?.invoiceId || 'draft-default') === invoiceId &&
        tab?.matterId === matterId
      ) {
        // User will see a flicker if form cleared in current tick as react will
        // re-render faster than angular can close the tab, meaning that the
        // form will be re-initialised with the default values which can cause
        // issues if preselectedExpenseIds or preselectedFeeIds are passed in
        // the next time the matter pre-draft mode is opened.
        setTimeout(() => {
          // Clear the draft invoice form
          draftInvoiceForm.onClearForm();
        }, 100);
      }
    });

    /**
     * Updates a property of a fee and, if necessary, recalculates the tax on the fee
     *
     * @param {object} arguments
     * @param {string} arguments.field - The property on the fee to be changed
     * @param {string} arguments.value - The new value of the property
     * @param {object} arguments.currentItem - The fee to be edited
     */
    const onChangeFee = ({ field, value, currentItem }) => {
      // Update tax amount if rate or duration changes so the tax is updated immediately.
      // This will have to be saved anyway, so it is convenient to do it here
      if (['rate', 'duration', 'isBillable'].includes(field)) {
        // Recalculate billableDuration if the user changes the duration or isBillable fields
        const updatedBillableDuration = ['duration', 'isBillable'].includes(field)
          ? undefined
          : currentItem.billableDuration;

        const { tax, billableTax, amountInclTax, amountExclTax, billableDuration } = calculateFeeAmountTax({
          // Unset tax and billable tax otherwise the old value will be used
          // instead of calculating a new one
          fee: {
            ...currentItem,
            [field]: value,
            billableDuration: updatedBillableDuration,
            tax: undefined,
            billableTax: undefined,
          },
          taxRate,
          region,
        });
        debouncedSaveFee.current(currentItem.id, 'tax', tax);
        debouncedSaveFee.current(currentItem.id, 'billableTax', billableTax);
        debouncedSaveFee.current(
          currentItem.id,
          'amount',
          currentItem.amountIncludesTax ? amountInclTax : amountExclTax,
        );
        debouncedSaveFee.current(currentItem.id, 'billableDuration', billableDuration);
      }
      debouncedSaveFee.current(currentItem.id, field, value);
      debouncedSaveFee.current(currentItem.id, '_currentItem', currentItem);
    };

    /**
     * Updates a property of a expense and, if necessary, recalculates the tax on the expense
     *
     * @param {object} arguments
     * @param {string} arguments.field - The property on the expense to be changed
     * @param {string} arguments.value - The new value of the property
     * @param {object} arguments.currentItem - The expense to be edited
     */
    const onChangeExpense = ({ field, value, currentItem }) => {
      // Calculate expense tax and amount (if necessary) as these will change
      // if the user edits the expense inline
      if (['price', 'quantity', 'isBillable'].includes(field)) {
        const updatedExpense = { ...currentItem, [field]: value };
        updatedExpense.tax = roundCents(calculateExpenseTaxAmount(updatedExpense, firmTaxRateBasisPoints));

        const isOutputTaxOverridden = featureActive('BB-12987') && !!updatedExpense.isOutputTaxOverridden;
        updatedExpense.isOutputTaxOverridden = isOutputTaxOverridden;
        updatedExpense.outputTax = isOutputTaxOverridden ? updatedExpense.outputTax : updatedExpense.tax;

        if (currentItem.expenseActivityId) {
          // Does NOT look up UTBMS activity codes
          const re = new RegExp(`^${currentItem.expenseActivityId.trim()}$`, 'i');
          const activityCode = activityCodes.find(
            (act) => !act.isDeleted && act.type === entryTypeEnum.EXPENSE && act.code.match(re),
          );

          if (activityCode?.isTaxExempt) {
            updatedExpense.tax = 0;
          }
        }

        debouncedSaveExpense.current(currentItem.id, 'tax', updatedExpense.tax);
        debouncedSaveExpense.current(currentItem.id, 'isOutputTaxOverridden', updatedExpense.isOutputTaxOverridden);
        debouncedSaveExpense.current(currentItem.id, 'outputTax', updatedExpense.outputTax);
      }

      debouncedSaveExpense.current(currentItem.id, field, value);
      // output tax is always set by .Net even when BB-12987 Input/Output Tax is not enabled
      // for this reason, we always display output tax for the disbursement entries
      // this means that even when BB-12987 is not enabled and tax is edited inline,
      // we need to ensure output tax is also updated accordingly for display purpose
      // note that tax is only editable inline when BB-12987 is not enabled
      if (!featureActive('BB-12987') && field === 'tax') {
        debouncedSaveExpense.current(currentItem.id, 'outputTax', value);
      }
      debouncedSaveExpense.current(currentItem.id, '_currentItem', currentItem);
    };

    const onOpenModal = async ({ entry, entryType, matterIdOverride }) => {
      // If we flush the debounce and save the current edits, the modal will not receive the correct fee versionId
      const entryId = entry?.id;

      if (entryType === 'FEE') {
        const inlineEdited = editedFeeFields[entryId];

        if (inlineEdited) {
          await debouncedSaveFee.current.flush();
        }

        const isNewFee = !entryId;
        let currentFeeCount = sortedFeeList.length;

        setModalDialogVisible({
          modalId: FEE_MODAL_ID,
          props: {
            scope: `${scope}/fee-modal`,
            feeId: entryId,
            matterId: matterIdOverride,
            onFeeSave: ({ marshalledData }) => {
              // Igor: the reason why this needs to be refetched is because
              // if we make use of formValues or finalFormData we may end up
              // getting stale data from the last render cycle due to this being in a closure
              const draftInvoicePageFields = withScopedFeature({
                scope,
              })(forms).selectors.getFieldValues(store.getState());

              // This is a post-save callback
              // Scenario 1. Select the newly created fee to be added to the draft invoice
              if (isNewFee) {
                // Ensure the value appears at the bottom of the list
                onUpdateFieldValue('feeIndex', {
                  ...draftInvoicePageFields.feeIndex,
                  [marshalledData.feeId]: currentFeeCount,
                });

                // As this is a closure, we only get access to the original
                // sortedFeeList.length. Need to manually increment the number
                // of entries in case the user uses "Save & New" to add more
                // entries to make sure they are added to the end of the list
                currentFeeCount += 1;

                const addToSelectedFeeList =
                  (includeNonBillableItems || marshalledData.isBillable || marshalledData.isBillable === null) &&
                  !sortedFeeList.find((sortedFee) => sortedFee.id === marshalledData.feeId);

                if (addToSelectedFeeList) {
                  onSetFieldValue('selectedFeeIds', [
                    ...(draftInvoicePageFields.selectedFeeIds || []),
                    marshalledData.feeId,
                  ]);
                }
              }

              // Scenario 2. Deselect fee if it no longer belongs to the matter
              if (marshalledData.matterId !== matterId) {
                onSetFieldValue(
                  'selectedFeeIds',
                  draftInvoicePageFields.selectedFeeIds.filter((id) => id !== marshalledData.feeId),
                );
              }
            },
          },
        });

        return;
      }

      if (entryType === 'EXPENSE') {
        const inlineEdited = editedExpenseFields[entryId] ? { ...editedExpenseFields[entryId] } : undefined;

        if (inlineEdited) {
          await debouncedSaveExpense.current.flush();
        }

        const legacyEntry = entry
          ? {
              ...inlineEdited,
              expenseId: entry.id,
              invoiceNumber: formValues.selectedExpenseIds.includes(entry.id) ? invoice?.invoiceNumber : undefined,
            }
          : entry;

        const isNewExpense = !entryId;

        // Auto-select new expense after the modal is successfully submitted
        const onSuccess = ({ saved }) => {
          // saved returns true when just closing the modal without saving
          // need to make sure that saved is an object
          if (saved && typeof saved === 'object') {
            const draftInvoicePageFields = withScopedFeature({
              scope,
            })(forms).selectors.getFieldValues(store.getState());

            // Scenario 1. Select the newly created expense to be added to the draft invoice
            if (isNewExpense) {
              const addToSelectedExpenseList =
                (includeNonBillableItems || saved.isBillable) &&
                !sortedExpenseList.find((sortedExpense) => sortedExpense.id === saved.expenseId);

              if (addToSelectedExpenseList) {
                onSetFieldValue('selectedExpenseIds', [
                  ...(draftInvoicePageFields.selectedExpenseIds || []),
                  saved.expenseId,
                ]);
              }
            }

            // Scenario 2. Deselect expense if it no longer belongs to the matter
            if (saved.matterId !== matterId) {
              onSetFieldValue(
                'selectedExpenseIds',
                draftInvoicePageFields.selectedExpenseIds.filter((id) => id !== saved.expenseId),
              );
            }
          }
        };

        // LOD
        if (featureActive('BB-13186')) {
          setModalDialogVisible({
            modalId: EXPENSE_MODAL_ID,
            props: {
              scope: 'DraftInvoiceRoute/expense-modal',
              expenseId: entry && entry.id,
              matterId,
              sbAsyncOperationsService,
              onExpenseSave: ({ marshalledData }) => onSuccess({ saved: marshalledData }),
            },
          });
        } else {
          // Legacy
          // The second-last argument is prefillEntryFromCache, that allows the legacy
          // expense modal to retrieve the entity from cache
          // TODO: Remove onSuccess handling in entry-modal-clickable and expense-entry-controller when replacing this modal with LOD version
          openModal(null, legacyEntry, entryType, matterIdOverride, true, onSuccess);
        }
      }
    };

    const selectedFeeEntriesMap = (finalFormData.selectedFeeIds || []).reduce((acc, id) => {
      acc[id] = true;
      return acc;
    }, {});
    const selectedExpenseEntriesMap = (finalFormData.selectedExpenseIds || []).reduce((acc, id) => {
      acc[id] = true;
      return acc;
    }, {});

    const selectedFeeEntities = sortedFeeList.filter((fee) => selectedFeeEntriesMap[fee.id]);
    const selectedExpenseEntities = [];
    let hasSelectedUnpaidAD = false;
    sortedExpenseList.forEach((expense) => {
      if (selectedExpenseEntriesMap[expense.id]) {
        selectedExpenseEntities.push(expense);
        if (expense.isAnticipated && expense.expensePaymentDetails && !expense.expensePaymentDetails.isPaid) {
          hasSelectedUnpaidAD = true;
        }
      }
    });

    const totals = calculateInvoiceTotals({
      fees: selectedFeeEntities,
      expenses: selectedExpenseEntities,
      discount: finalFormData.discount,
      surchargeEnabled: isSurchargeEnabled,
      surcharge: finalFormData.surcharge,
      taxRate,
    });

    const lessFundsInTrustAmount = Math.min(
      isShowLessFundsInTrustEnabled && balances.TRUST?.balance > 0 ? balances.TRUST.balance : 0,
      totals.total,
    );

    // We use triggerValidate to trigger this function in next render in order to make sure totals are recalculated with latest values
    const validateForm = () => {
      const validateCtx = {
        descriptionOnDemandEnabled,
        isProtectedTrustFundsEnabled,
        availableTrustFunds: balances.TRUST.balance,
        isShowLessFundsInTrustEnabled,
        lessFundsInTrustAmount,
        total: totals.total,
        preferredBankAccountTypes,
        lastTrustChequeNumber,
        nextTrustChequeNumber,
        t,
      };
      draftInvoiceForm.onValidateForm(validateCtx);
    };

    useEffect(() => {
      // Skip if data not ready as we would run validation with worng values (such as wrong totals). This is mainly to cover case when
      // we leave draft invoice tab and then return to it which triggers refetch of gql data and at the same time triggers this useEffect.
      // It should be safe to ignore validation when data is not ready as we validate form on save/submit anyway.
      if (!isDataReady || !isAdditionalDataReady) {
        return;
      }
      validateForm();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerValidate]);

    // Discount
    // Can apply discount when
    // 1. fee subtracts any written off amount is great than 0
    const canApplyDiscount = totals.feeTotal - totals.writtenOffFeeTotal > 0;

    const onConfirmDiscount = (discountType, absoluteDiscount, percentageDiscount) => {
      switch (discountType) {
        case 0:
          onUpdateFieldValue('discount', {
            enabled: true,
            type: 0,
            fixedDiscount: absoluteDiscount,
            percentage: 0,
            applyOnlyToFees: true,
          });
          break;
        case 1:
          onUpdateFieldValue('discount', {
            enabled: true,
            type: 1,
            percentage: Math.min(percentageDiscount, 100),
            fixedDiscount: 0,
            applyOnlyToFees: true,
          });
          break;
        default:
          break;
      }
    };

    const onDiscardDiscount = () => {
      if (!totals.discount) {
        onUpdateFieldValue('discount', { enabled: false, type: 0, percentage: 0, fixedDiscount: 0 });
      }
    };

    const onApplyDiscountChecked = (checked) => {
      onUpdateFieldValue('discount', { enabled: checked });
      if (!checked) {
        onUpdateFieldValue('discount', {
          type: 0,
          percentage: 0,
          fixedDiscount: 0,
        });
      }
    };

    // Surcharge
    // Can apply surcharge when
    // 1) feature is turned on
    // 2) amount to apply surcharge on is > 0
    const canApplySurcharge =
      isSurchargeEnabled &&
      formValues.surcharge &&
      ((formValues.surcharge.applyTo === surchargeApplyToValues.FEES &&
        totals.feeTotal - totals.writtenOffFeeTotal - totals.discount > 0) ||
        (formValues.surcharge.applyTo === surchargeApplyToValues.FEES_AND_EXPENSES &&
          totals.feeTotal -
            totals.writtenOffFeeTotal +
            totals.expenseTotal -
            totals.writtenOffExpenseTotal -
            totals.discount >
            0));

    let surchargeSettings;
    if (isSurchargeEnabled && isDataReady) {
      const invoiceSettingsTemplate = config.template.settings;
      surchargeSettings = determineSurchargeSettings({
        surchargeEnabled: isSurchargeEnabled,
        invoiceVersion: undefined,
        matterInvoiceSettings,
        invoiceSettingsTemplate,
        defaultSurchargeSettings,
      });
    }

    const onConfirmSurcharge = (surchargeType, fixedSurcharge, percentageBp) => {
      switch (surchargeType) {
        case surchargeTypeValues.FIXED:
          onUpdateFieldValue('surcharge', {
            enabled: true,
            type: surchargeTypeValues.FIXED,
            fixedSurcharge,
            percentageBp: 0,
            applyTo: surchargeSettings.applyTo,
            description: surchargeSettings.description,
          });
          break;
        case surchargeTypeValues.PERCENTAGE:
          onUpdateFieldValue('surcharge', {
            enabled: true,
            type: surchargeTypeValues.PERCENTAGE,
            percentageBp,
            fixedSurcharge: 0,
            applyTo: surchargeSettings.applyTo,
            description: surchargeSettings.description,
          });
          break;
        default:
          break;
      }
    };

    const onDiscardSurcharge = () => {
      if (!totals.surcharge) {
        onUpdateFieldValue('surcharge', {
          enabled: false,
          type: surchargeTypeValues.NONE,
          percentageBp: 0,
          fixedSurcharge: 0,
          applyTo: surchargeSettings.applyTo,
          description: surchargeSettings.description,
        });
      }
    };

    const onApplySurchargeChecked = (checked) => {
      if (checked) {
        onUpdateFieldValue('surcharge', {
          enabled: true,
          type: surchargeSettings.type,
          percentageBp: surchargeSettings.percentageBp,
          fixedSurcharge: surchargeSettings.fixedSurcharge,
          applyTo: surchargeSettings.applyTo,
          description: surchargeSettings.description,
        });
      } else {
        onUpdateFieldValue('surcharge', {
          enabled: false,
          type: surchargeTypeValues.NONE,
          percentageBp: 0,
          fixedSurcharge: 0,
          applyTo: surchargeSettings.applyTo,
          description: surchargeSettings.description,
        });
      }
    };

    // Allocations
    if (
      draftInvoiceForm.formInitialised &&
      isDataReady &&
      formValues.quickPayments.trust.sourceAccountId !== balances.TRUST.bankAccountId
    ) {
      // Default account has changed after init, we have to reset amounts as they use wrong source account
      const allocatedAmount =
        (formValues.quickPayments?.trust?.amount || 0) +
        (formValues.quickPayments?.operating?.amount || 0) +
        (formValues.quickPayments?.credit?.amount || 0);

      if (allocatedAmount > 0) {
        displayInfo(`Default ${t('trustAccount').toLowerCase()} for matter has changed. Please allocate money again.`);
      }
      onUpdateFieldValue('autoAllocate', false);
      onUpdateFieldValue('chequePrintingMethod', PrintNotApplicable);
      onSetFieldValue(
        'quickPayments',
        getInitialQuickPayments({
          matterId,
          trustBankAccountId: balances.TRUST.bankAccountId,
          operatingBankAccountId: balances.OPERATING.bankAccountId,
          creditBankAccountId: balances.CREDIT.bankAccountId,
        }),
      );
      onSetFieldValue('multiPayments', []);
    }

    const onChangeMatterBalanceAllocations = (trust = 0, operating = 0, credit = 0) => {
      if (
        formValues.quickPayments.trust.amount !== trust ||
        formValues.quickPayments.operating.amount !== operating ||
        formValues.quickPayments.credit.amount !== credit
      ) {
        onUpdateFieldValue('quickPayments', {
          trust: { amount: trust },
          operating: { amount: operating },
          credit: { amount: credit },
        });
        if (trust > 0) {
          onUpdateFieldValue('showLessFundsInTrust', false);
        }
      }
    };

    const onChangeContactBalanceAllocations = (trustPayments = [], operatingPayments = [], creditPayments = []) => {
      try {
        const trust = trustPayments.reduce((total, allocation) => total + allocation.amount, 0);
        const operating = operatingPayments.reduce((total, allocation) => total + allocation.amount, 0);
        const credit = creditPayments.reduce((total, allocation) => total + allocation.amount, 0);

        if (
          formValues.quickPayments.trust.amount !== trust ||
          formValues.quickPayments.operating.amount !== operating ||
          formValues.quickPayments.credit.amount !== credit
        ) {
          const multiPayments = trustPayments
            .concat(operatingPayments)
            .concat(creditPayments)
            .map((payment) => {
              const paymentType = capitalize(payment.type);

              return {
                amount: payment.amount,
                source: paymentType,
                sourceAccountType: paymentType,
                sourceAccountId: payment.bankAccountId,
                payorId: payment.contactId,
                matterId,
                hasErrors: false,
              };
            });

          onUpdateFieldValue('quickPayments', {
            trust: { amount: trust },
            operating: { amount: operating },
            credit: { amount: credit },
          });
          onSetFieldValue('multiPayments', multiPayments);
          if (trust > 0) {
            onUpdateFieldValue('showLessFundsInTrust', false);
          }
        }
      } catch (err) {
        log.error(err);
      }
    };

    // Fetch available Trust Cheque numbers if the user has opted to pay from
    // the Trust account. Refetch the cheque numbers if the user changes the
    // cheque number (trustChequeReference) manually in order to validate the
    // value
    useEffect(() => {
      if (
        !!balances.TRUST.bankAccountId &&
        formValues.quickPayments?.trust?.amount > 0 &&
        +nextTrustChequeNumber !== +formValues.trustChequeReference
      ) {
        onFetchAvailableTrustChequeNumbers({
          bankAccountId: balances.TRUST.bankAccountId,
          trustChequeReference: formValues.trustChequeReference || undefined,
        });
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      balances.TRUST.bankAccountId,
      formValues.quickPayments?.trust?.amount,
      formValues.trustChequeReference,
      nextTrustChequeNumber,
    ]);

    // Set the default trustChequeReference the first time we fetch nextTrustChequeNumber
    useEffect(() => {
      if (formValues.trustChequeReference === undefined && !!nextTrustChequeNumber) {
        onSetFieldValue('trustChequeReference', nextTrustChequeNumber);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextTrustChequeNumber]);

    // Changing the form value will trigger immediate validation before we can
    // fetch the new nextTrustChequeNumber value. Instead we wait until loading
    // is complete. Can't rely on nextTrustChequeNumber changing because if the
    // user enters any used value, nextTrustChequeNumber is likely going to stay
    // the same
    useEffect(() => {
      if (nextTrustChequeNumber && trustChequeNumberLoading === false) {
        setTriggerValidate(!triggerValidate);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextTrustChequeNumber, trustChequeNumberLoading]);

    const onUpdateTrustChequeReference = (trustChequeReference) => {
      // Skip validation as we need to perform it once we have fetched the
      // nextTrustChequeNumber value. This is provided via validateForm as
      // context to yup schema
      draftInvoiceForm.onUpdateFields({ trustChequeReference });
    };

    const getMarshalledDraftInvoice = () => {
      const { showInvoiceScanToPay: showScanToPay, showInvoicePaymentLinks: showInvoiceLink } =
        getFirmPaymentProviderPaymentSettings({
          activeProviderType,
          activeProviderFormattedSettings,
          operatingAccountId: balances.OPERATING.bankAccountId,
        });
      const draftInvoiceMarshalled =
        marshallInvoice({
          invoice,
          matter,
          selectedFeeEntities,
          selectedExpenseEntities,
          formValues,
          totals,
          surchargeEnabled: featureActive('BB-7270'),
          supportsTtoNumbering: hasFacet(facets.ttoNumbering),
          allowOverdraw: hasFacet(facets.allowOverdraw),
          config,
          provideShowRetainerOption,
          feeTaxRate: firmTaxRateBasisPoints,
          // Include merchant payment reference provided:
          // 1. A payment provider is connected
          // 2. The Operating account is configured for the payment provider in firm settings
          // 3. The Show Invoice Link and/or Show Scan to Pay options are selected in the payment provider settings
          // If showInvoiceLink or showScanToPay is true then it satisfies the rules above.
          merchantPaymentReference: showInvoiceLink || showScanToPay ? formValues.merchantPaymentReference : undefined,
        }) || {};
      return draftInvoiceMarshalled;
    };

    const { downloadLedes } = useSingleLedesDownload();
    const { executeFunction: downloadLedesFile } = useAsyncWithRetries({
      fn: () => downloadLedes([formValues.invoiceId]),
      retries: 5,
      onRetriesExhausted: () => {
        displayError('Failed to generate LEDES file.');
      },
    });

    const onSaveInvoice = async (sendInfo) => {
      const draftInvoiceMarshalled = getMarshalledDraftInvoice();
      validateForm();

      try {
        await draftInvoiceForm.onSubmitFormWithValidation({
          submitFnP: async () => {
            await saveInvoiceSubmitFnP({
              debouncedSaveExpense,
              debouncedSaveFee,
              draftInvoiceMarshalled,
              isFinal: formValues.saveType === 'FINAL',
              sendInfo,
              defaultBankAccountIdForMatter: balances.TRUST.bankAccountId,
              // callbacks
              onClickLink,
              closeCurrentTab,
              saveRecentInvoice,
              sbSaveInvoiceCommand,
              downloadLedesFile,
            });
          },
          onValidationFailed: () => {
            displayError(
              `Please ensure there are no errors and at least one fee or ${t('expense')} is selected before saving`,
            );
          },
        });
      } catch (err) {
        if (
          (err.data && err.data.message === emailMessages.notAllowedToSendEmailsServer) ||
          (err.payload && err.payload.body && err.payload.body.message === emailMessages.notAllowedToSendEmailsServer)
        ) {
          displayError(
            builder()
              .text('Failed to save invoice')
              .conditionalText(' #{0}', draftInvoice?.invoiceVersion?.invoiceNumber)
              .text(`. ${emailMessages.notAllowedToSendEmailsDisplay}`),
          );
        } else {
          displayError(
            builder()
              .text('Failed to save invoice')
              .conditionalText(' #{0}', draftInvoice?.invoiceVersion?.invoiceNumber)
              .conditionalText(': {0}', getError(err)),
          );
        }
      }
    };

    // TODO Evaluate if we need this. It seems this is used for invoice sending so depending
    // on how we send invoice we could pass the invoice directly or through redux
    const onSavePreDraftInvoice = () => {
      const draftInvoiceMarshalled = getMarshalledDraftInvoice();
      const preDraftInvoice = {
        ...draftInvoiceMarshalled,
        invoiceVersion: {
          ...draftInvoiceMarshalled.invoiceVersion,
          preDraftInvoiceTotals: totals, // this is needed as before draft is saved, there is no InvoiceTotal record
        },
      };

      dispatch(savePreDraftInvoice({ preDraftInvoice }));
    };

    const onPreviewInvoice = async ({ retainerRequestAmount }) => {
      // we could await flushing of debounced save, but this introduces
      // a delay before the modal is opened which is quite noticeable and could
      // prompt user to click preview again as they may think it's doing nothing
      debouncedSaveExpense.current.flush();
      debouncedSaveFee.current.flush();

      const draftInvoiceMarshalled = getMarshalledDraftInvoice();
      setModalDialogVisible({
        modalId: DRAFT_INVOICE_PREVIEW_MODAL_ID,
        props: {
          invoiceId: draftInvoiceMarshalled.invoiceId,
          invoiceNumber: draftInvoiceMarshalled.invoiceVersion.invoiceNumber,
          draftInvoiceOverrides: {
            invoiceVersion: {
              ...draftInvoiceMarshalled.invoiceVersion,
              template: config?.template,
              retainerRequestAmount,
            },
            invoiceTotals: totals,
            quickPayments: formValues.quickPayments,
          },
        },
      });
    };

    // For both email and communicate
    const onShowSendInvoiceModal = ({ sendVia, onSend }) => {
      if (sendVia !== sentViaTypes.EMAIL && sendVia !== sentViaTypes.COMMUNICATE) {
        throw new Error(`Invalid sendVia value: ${sendVia}`);
      }

      // We could technically use form fields directly in this function
      // but we use draftInvoiceMarshalled just to be sure we use same data as other callbacks
      const draftInvoiceMarshalled = getMarshalledDraftInvoice();

      // Only require fee entries to calculate the invoice's total duration
      const feeIds = draftInvoiceMarshalled.invoiceVersion.entries.reduce((acc, entry) => {
        if (entry.type === entryTypeEnum.TIME || entry.type === entryTypeEnum.FIXED) {
          acc.push(entry.id);
        }
        return acc;
      }, []);

      const quickPaymentsTotalAmount = Object.values(draftInvoiceMarshalled.quickPayments || {}).reduce(
        (acc, { amount }) => acc + amount,
        0,
      );

      const modalProps = {};
      let modalId = '';
      if (sendVia === sentViaTypes.EMAIL) {
        modalId = INVOICE_EMAIL_MODAL_ID;
        modalProps.onPreview = ({ invoiceEmailRequest }) =>
          sbInvoiceSendService.createInvoiceEmailPreviewP({
            invoiceEmailRequest,
            preDraftMode: true, // Includes an invoice that may have unsaved changes (create/edit invoice)
            quickPaymentsTotalAmount, // Total amount in quickPayments, to be subtracted from total owing for the invoice/debtor in preview
          });
        modalProps.onSendEmails = onSend;
      } else {
        // communicate
        modalId = INVOICE_COMMUNICATE_MODAL_ID;
        modalProps.onPreview = ({ invoiceCommunicateRequest }) =>
          sbInvoiceSendService.createInvoiceCommunicatePreviewP({
            invoiceCommunicateRequest,
            preDraftMode: true, // Includes an invoice that may have unsaved changes (create/edit invoice)
            quickPaymentsTotalAmount, // Total amount in quickPayments, to be subtracted from total owing for the invoice/debtor in preview
          });
        modalProps.onSend = onSend;
      }

      setModalDialogVisible({
        modalId,
        props: {
          draftInvoice: {
            debtorIds: draftInvoiceMarshalled.invoiceVersion.debtors.map((debtor) => debtor.id),
            matterId: draftInvoiceMarshalled.matterId,
            feeIds,
          },
          invoiceIds: [draftInvoiceMarshalled.invoiceId],
          scope: `draft-invoice-route-${sendVia}-modal`,
          ...modalProps,
        },
      });
    };

    return {
      draftInvoice,
      expenseList: filteredExpenseList,
      feeList: filteredFeeList,
      formReady: draftInvoiceForm.formInitialised,
      formData: finalFormData,
      formErrors: {
        ...draftInvoiceForm.formFields,
      },
      isDataReady,
      expandedExpenses,
      onSetExpandedExpenses: setExpandedExpenses,
      expandedSummary,
      onSetExpandedSummary: setExpandedSummary,
      expandedTimeAndFees,
      onSetExpandedTimeAndFees: setExpandedTimeAndFees,
      onChangeExpense,
      onChangeExpenseOrder,
      onChangeFee,
      onChangeFeeOrder,
      onOpenModal,
      onResetDefaultTemplate,
      onSortExpensesByDate,
      onSortFeesByDate,
      onUpdateContacts,
      onUpdateField: onUpdateFieldValue,
      onUpdateInvoiceConfigurationField,
      onUpdateInvoiceConfigurationTitleField,
      onUpdateInvoiceConfigurationSubtitleField,
      onUpdateInvoiceConfigurationFooterField,
      onUpdateSelectedExpenses,
      onUpdateSelectedFees,
      onUpdateTemplate,
      onUpdateTrustChequeReference,
      formValid: draftInvoiceForm.formValid,
      submitFailed: draftInvoiceForm.submitFailed,
      descriptionOnDemandEnabled,
      summaryPlaceholderText,
      showDodSummaryError,
      totals,
      lessFundsInTrustAmount,
      protectedTrustFundsAmount,
      onSaveInvoice,
      onSavePreDraftInvoice,
      onPreviewInvoice,
      onShowSendInvoiceModal,
      hasSelectedUnpaidAD,
      activeProviderFormattedSettings,
      // Discount props
      canApplyDiscount,
      onConfirmDiscount,
      onDiscardDiscount,
      onApplyDiscountChecked,
      // Surcharge props
      canApplySurcharge,
      onConfirmSurcharge,
      onDiscardSurcharge,
      onApplySurchargeChecked,
      // Allocations
      preferredBankAccountTypes,
      onChangeMatterBalanceAllocations,
      onChangeContactBalanceAllocations,
    };
  },
});

const saveRecentInvoice = (invoiceId) => {
  if (!featureActive('BB-8047')) {
    return;
  }
  // Fire and forget
  dispatchCommand({ type: 'Billing.Invoicing.Messages.Commands.SaveRecentInvoice', message: { invoiceId } }).catch(
    (err) => {
      log.error('Failed to save recent invoice', err);
    },
  );
};

const saveInvoiceSubmitFnP = async ({
  debouncedSaveExpense,
  debouncedSaveFee,
  draftInvoiceMarshalled,
  isFinal,
  sendInfo,
  defaultBankAccountIdForMatter,
  // callbacks
  onClickLink,
  closeCurrentTab,
  sbSaveInvoiceCommand,
  downloadLedesFile,
}) => {
  await (debouncedSaveExpense.current.flush() || Promise.resolve());
  await (debouncedSaveFee.current.flush() || Promise.resolve());

  if (!draftInvoiceMarshalled.invoiceVersion?.dueDate || !draftInvoiceMarshalled.invoiceVersion?.issuedDate) {
    throw new Error('An invoice must have both an issue date and a due date');
  }

  if (isFinal && !draftInvoiceMarshalled.invoiceVersion?.entries?.length) {
    throw new Error('An invoice must have one or more entries');
  }

  // TODO replace sbSaveInvoiceCommand with LOD compatible service
  const saveInvoiceCmdResult = await sbSaveInvoiceCommand.executeP(
    draftInvoiceMarshalled.invoiceId,
    draftInvoiceMarshalled.invoiceVersion,
    isFinal ? draftInvoiceMarshalled.quickPayments : undefined,
    isFinal ? sendInfo : undefined,
    isFinal,
  );

  if (!isFinal && sendInfo?.generateLedes && saveInvoiceCmdResult?.invoiceVersion) {
    const { entries } = saveInvoiceCmdResult.invoiceVersion;

    // const nonOptimisticUpdates = [];
    // nonOptimisticUpdates.push(
    //   reduxActionWithTimeout(
    //     'billing/invoice-totals/UPDATE_CACHE',
    //     (payloads) => payloads.find((payload) => invoiceId === payload.invoiceId && !payload.optimistic),
    //     3000,
    //   ),
    // );
    // nonOptimisticUpdates.push(
    //   reduxActionWithTimeout(
    //     'billing/invoices/UPDATE_CACHE',
    //     (payloads) => payloads.find((payload) => invoiceId === payload.invoiceId && !payload.optimistic),
    //     3000,
    //   ),
    // );
    // await Promise.all(nonOptimisticUpdates);

    // TODO the original code above waits for specific cache updates for up to 3s.
    // We could possibly replace it with listening for notifications
    await new Promise((resolve) => {
      setTimeout(resolve, 3000);
    });

    const allUtbms = entries.every(({ feeEntity, expenseEntity }) => {
      const { utbmsTaskCode, utbmsActivityCode } = feeEntity || expenseEntity || {};
      return !!utbmsTaskCode || !!utbmsActivityCode;
    });

    if (!allUtbms) {
      displayInfo('This Invoice has non-UTBMS entries.');
    }

    log.info('generated ledes file');
    await downloadLedesFile();
  }

  saveRecentInvoice(draftInvoiceMarshalled.invoiceId);
  closeCurrentTab();

  if (isFinal) {
    const checkId = draftInvoiceMarshalled.quickPayments?.[0]?.transferBetweenAccountsTransactionId;
    const params = [checkId || '', defaultBankAccountIdForMatter || ''];
    onClickLink({
      type: 'invoice',
      id: draftInvoiceMarshalled.invoiceId,
      params,
    });
  }
};

export const BillingDraftInvoiceRouteFormsContainer = composeHooks(hooks)((props) => {
  // Prevent rendering the display component if the form or the data is not ready
  // to prevent a janky UI and loading components with internal state management.
  // Once the form is initialised, if the user navigates away then returns,
  // the form will still be initialised but the data needs to be re-fetched
  if (!props.formReady || !props.isDataReady) {
    return <Spinner />;
  }

  return <BillingDraftInvoiceRoute {...props} />;
});

BillingDraftInvoiceRouteFormsContainer.displayName = 'BillingDraftInvoiceRouteFormsContainer';

BillingDraftInvoiceRouteFormsContainer.propTypes = {
  sbAsyncOperationsService: PropTypes.object.isRequired,
  activityCodes: PropTypes.arrayOf(PropTypes.object),
  firmTaxRateBasisPoints: PropTypes.number.isRequired,
  invoice: PropTypes.object,
  invoiceId: PropTypes.string,
  invoiceSettingsTemplateEntity: PropTypes.object,
  invoiceSettingsTemplateFirmDefault: PropTypes.object,
  isNewInvoice: PropTypes.bool.isRequired,
  matter: PropTypes.object,
  matterId: PropTypes.string.isRequired,
  matterInvoiceSettings: PropTypes.object,
  onFetchInvoiceSettingsTemplateEntity: PropTypes.func.isRequired,
  onFetchUnbilledExpenses: PropTypes.func.isRequired,
  onFetchUnbilledFees: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired, // Required for opening legacy Expense modal. Remove after LOD conversion
  preselectedExpenseIds: PropTypes.arrayOf(PropTypes.string),
  preselectedFeeIds: PropTypes.arrayOf(PropTypes.string),
  region: PropTypes.string.isRequired,
  scope: PropTypes.string.isRequired,
  unbilledExpenses: PropTypes.arrayOf(PropTypes.object),
  unbilledFees: PropTypes.arrayOf(PropTypes.object),
  bankBalanceType: PropTypes.number, // #(e.g. 0:MatterContact, 1:Matter)
  balances: PropTypes.object.isRequired,
  protectedTrustFundsAmount: PropTypes.number.isRequired,
  preferredBankAccountTypes: PropTypes.arrayOf(PropTypes.string),
  lastTrustChequeNumber: PropTypes.string,
  nextTrustChequeNumber: PropTypes.string,
  onFetchAvailableTrustChequeNumbers: PropTypes.func.isRequired,
  activeProviderType: PropTypes.string,
  activeProviderFormattedSettings: PropTypes.object,
  provideShowRetainerOption: PropTypes.bool.isRequired,
  onClickLink: PropTypes.func.isRequired,
  closeCurrentTab: PropTypes.func.isRequired,
  // Injected in bridge file
  sbSaveInvoiceCommand: PropTypes.object.isRequired,
  sbInvoiceSendService: PropTypes.object.isRequired,
};

BillingDraftInvoiceRouteFormsContainer.defaultProps = {
  activityCodes: undefined,
  invoice: undefined,
  invoiceId: undefined,
  invoiceSettingsTemplateEntity: undefined,
  invoiceSettingsTemplateFirmDefault: undefined,
  matter: undefined,
  matterInvoiceSettings: undefined,
  preselectedExpenseIds: undefined,
  preselectedFeeIds: undefined,
  unbilledExpenses: undefined,
  unbilledFees: undefined,
  bankBalanceType: undefined,
  preferredBankAccountTypes: undefined,
  lastTrustChequeNumber: undefined,
  nextTrustChequeNumber: undefined,
  activeProviderType: undefined,
  activeProviderFormattedSettings: undefined,
};

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CardContainer, ContextMenu, FloatingCard, LinkableText, TextEditor, useTranslation } from '@sb-itops/react';
import { DebugEntity } from 'web/react-redux'; // Added for convenience, remove when Invoice entity cache is removed
import Styles from './BillingDraftInvoiceRoute.module.scss';
import { DraftInvoiceSettings } from './DraftInvoiceSettings';
import { InlineEditTable } from './InlineEditTable';
import { FinalisePanel } from './FinalisePanel';

export const BillingDraftInvoiceRoute = ({
  draftInvoice,
  expandedExpenses,
  expandedSummary,
  expandedTimeAndFees,
  expenseList,
  feeList,
  formData,
  formReady,
  formValid,
  formErrors,
  hasOpenTrustAccountsForMatter,
  invoiceId,
  matterDisplay,
  matterId,
  provideShowRetainerOption,
  descriptionOnDemandEnabled,
  showDodSummaryError,
  summaryPlaceholderText,
  supportsTax,
  unbilledExpensesLoading,
  unbilledFeesLoading,
  userViewedMessages,
  onChangeExpense,
  onChangeExpenseOrder,
  onChangeFee,
  onChangeFeeOrder,
  onClickLink,
  overrideRedirect,
  onOpenModal,
  onResetDefaultTemplate,
  onSetExpandedExpenses,
  onSetExpandedSummary,
  onSetExpandedTimeAndFees,
  onSortExpensesByDate,
  onSortFeesByDate,
  onUpdateContacts,
  onUpdateField,
  onUpdateInvoiceConfigurationField,
  onUpdateInvoiceConfigurationFooterField,
  onUpdateInvoiceConfigurationSubtitleField,
  onUpdateInvoiceConfigurationTitleField,
  onUpdateSelectedExpenses,
  onUpdateSelectedFees,
  onUpdateTemplate,
  onUpdateTrustChequeReference,

  // Debtor dropdowns
  contactOptions,
  contactOptionsDataLoading,
  contactOptionsHasMore,
  onFetchContactOptions,
  onFetchMoreContactOptions,

  // Invoice Template dropdown
  invoiceSettingsTemplateEntityLoading,
  invoiceSettingsTemplateOptions,
  invoiceSettingsTemplateOptionsDataLoading,
  invoiceSettingsTemplateOptionsHasMore,
  onFetchInvoiceSettingsTemplateOptions,
  onFetchMoreInvoiceSettingsTemplateOptions,

  // Finalise panel
  balances,
  totals,
  trustAccountsDisabled,
  matterTrustBalance,
  isBalanceAvailable,
  matter,
  lessFundsInTrustAmount,
  protectedTrustFundsAmount,
  onChangeContactBalanceAllocations,
  onChangeMatterBalanceAllocations,
  preferredBankAccountTypes,
  canApplyDiscount,
  onConfirmDiscount,
  onDiscardDiscount,
  onApplyDiscountChecked,
  canApplySurcharge,
  onConfirmSurcharge,
  onDiscardSurcharge,
  onApplySurchargeChecked,
  closeCurrentTab,
  onSaveInvoice,
  onSavePreDraftInvoice,
  onPreviewInvoice,
  onShowSendInvoiceModal,
  isUtbmsEnabledForFirm,
  hasSelectedUnpaidAD,
  trustChequeEnabled,
  activeProviderFormattedSettings,
}) => {
  const { t } = useTranslation();

  return (
    <div className={Styles.draftInvoice}>
      <DebugEntity entityCacheName="sbInvoicingService" entityId={invoiceId} />

      <div className={Styles.title}>
        {draftInvoice?.isNewInvoice ? (
          <strong>
            <div className={classnames('icon icon-currency-round', Styles.icon)} />
            New Invoice
          </strong>
        ) : (
          <>
            <strong>
              <div className={classnames('icon icon-currency-round', Styles.icon)} />
              Draft Invoice
            </strong>
            <span className={Styles.invoiceNumber}>#{draftInvoice?.invoiceNumber}</span>
          </>
        )}{' '}
        <LinkableText
          text={matterDisplay}
          onClickLink={() => onClickLink({ type: 'matter', id: matterId })}
          asLink={matterDisplay && onClickLink}
          inline
        />
      </div>

      <div className={Styles.draftInvoiceBody}>
        <CardContainer>
          <FloatingCard>
            <DraftInvoiceSettings
              formData={formData}
              formErrors={formErrors}
              hasOpenTrustAccountsForMatter={hasOpenTrustAccountsForMatter}
              onResetDefaultTemplate={onResetDefaultTemplate}
              onUpdateContacts={onUpdateContacts}
              onUpdateField={onUpdateField}
              onUpdateInvoiceConfigurationField={onUpdateInvoiceConfigurationField}
              onUpdateInvoiceConfigurationFooterField={onUpdateInvoiceConfigurationFooterField}
              onUpdateInvoiceConfigurationSubtitleField={onUpdateInvoiceConfigurationSubtitleField}
              onUpdateInvoiceConfigurationTitleField={onUpdateInvoiceConfigurationTitleField}
              onUpdateTemplate={onUpdateTemplate}
              provideShowRetainerOption={provideShowRetainerOption}
              supportsTax={supportsTax}
              // Debtor dropdowns
              contactOptions={contactOptions}
              contactOptionsDataLoading={contactOptionsDataLoading}
              contactOptionsHasMore={contactOptionsHasMore}
              onFetchContactOptions={onFetchContactOptions}
              onFetchMoreContactOptions={onFetchMoreContactOptions}
              // Invoice template dropdown
              invoiceSettingsTemplateOptions={invoiceSettingsTemplateOptions}
              invoiceSettingsTemplateOptionsDataLoading={invoiceSettingsTemplateOptionsDataLoading}
              invoiceSettingsTemplateOptionsHasMore={invoiceSettingsTemplateOptionsHasMore}
              onFetchInvoiceSettingsTemplateOptions={onFetchInvoiceSettingsTemplateOptions}
              onFetchMoreInvoiceSettingsTemplateOptions={onFetchMoreInvoiceSettingsTemplateOptions}
              invoiceSettingsTemplateEntityLoading={invoiceSettingsTemplateEntityLoading}
            />
          </FloatingCard>
          <FloatingCard>
            {descriptionOnDemandEnabled && (
              <div>
                <h3>
                  <span className="pointer" onClick={() => onSetExpandedSummary(!expandedSummary)}>
                    <span className={expandedSummary ? 'icon icon-arrow-37' : 'icon icon-arrow-40'} />
                    SUMMARY
                  </span>
                </h3>
                {(showDodSummaryError || expandedSummary) && (
                  <div className={Styles.invoiceSummary}>
                    <TextEditor
                      value={formData.summary || ''}
                      className={showDodSummaryError ? 'text-editor-error' : ''}
                      fixedHeight
                      placeholder={summaryPlaceholderText}
                      onChange={(html) => onUpdateField('summary', html)}
                    />
                    {showDodSummaryError && (
                      <div className={Styles.errorMessage}>{formErrors.summary.invalidReason}</div>
                    )}
                  </div>
                )}
              </div>
            )}
            <h3>
              <span className="pointer" onClick={() => onSetExpandedTimeAndFees(!expandedTimeAndFees)}>
                <span className={expandedTimeAndFees ? 'icon icon-arrow-37' : 'icon icon-arrow-40'} />
                TIME & FEES
              </span>
            </h3>
            {expandedTimeAndFees && (
              <>
                {formData.showFeesEntriesAs === 'SUMMARY' && (
                  <div className="row">
                    <div className={classnames(Styles.summaryText, 'col-md-12 col-lg-8')}>
                      <label>SUMMARY TEXT</label>
                      <textarea
                        value={formData.feeSummaryLineDescription || ''}
                        className={classnames(
                          'form-control',
                          formErrors.feeSummaryLineDescription.isInvalid && Styles.hasError,
                        )}
                        onChange={(e) => {
                          onUpdateField('feeSummaryLineDescription', e.target.value);
                        }}
                      />
                    </div>
                  </div>
                )}
                <div className={Styles.blurb}>
                  Select entries below to add them to the invoice
                  <button
                    className={classnames('link-button', Styles.linkButton)}
                    onClick={() => onOpenModal({ entryType: 'FEE', matterIdOverride: matterId })}
                    type="button"
                  >
                    <i className="icon icon-add-3" />
                    ADD ENTRY
                  </button>
                  <ContextMenu
                    className={Styles.entriesContextMenu}
                    distance={5}
                    position="top-right"
                    arrow={false}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    body={({ close }) => (
                      <div className={classnames('list-group', Styles.contextMenuBody)}>
                        <div
                          type="button"
                          className={classnames('list-group-item', Styles.listGroupItem)}
                          onClick={() => {
                            onSortFeesByDate();
                            close();
                          }}
                        >
                          Sort fees by date
                        </div>
                        <div
                          type="button"
                          className={classnames('list-group-item', Styles.listGroupItem)}
                          onClick={() => {
                            if (formData.showFeesEntriesAs === 'SUMMARY') {
                              onUpdateField('showFeesEntriesAs', 'LIST');
                            } else {
                              onUpdateField('showFeesEntriesAs', 'SUMMARY');
                            }
                            close();
                          }}
                        >
                          {`Show as a ${formData.showFeesEntriesAs === 'SUMMARY' ? 'list' : 'summary'}`}
                        </div>
                        <div
                          type="button"
                          className={classnames('list-group-item', Styles.listGroupItem)}
                          onClick={() => {
                            const newValue = !formData.showNonBillableFees;
                            onUpdateField('showNonBillableFees', newValue);

                            // If non-billable entries are ever displayed, we will need to keep
                            // fetching their data to include on the invoice if any are selected
                            // even if hidden
                            if (newValue === true && !formData.showNonBillableFeesEverSelected) {
                              onUpdateField('showNonBillableFeesEverSelected', true);
                            }
                          }}
                        >
                          {formData.showNonBillableFees
                            ? 'Hide non-billable fees on invoice'
                            : 'Show non-billable fees on invoice'}
                        </div>
                        {formData.showFeesEntriesAs === 'SUMMARY' && (
                          <div
                            type="button"
                            className={classnames('list-group-item', Styles.listGroupItem)}
                            onClick={() => {
                              onUpdateField('feeListAppend', !formData.feeListAppend);
                            }}
                          >
                            {formData.feeListAppend
                              ? 'Do not append a list of entries to the invoice'
                              : 'Append a list of entries to the invoice'}
                          </div>
                        )}
                      </div>
                    )}
                  >
                    <div className="icon icon-kebab-fill" />
                  </ContextMenu>
                </div>
                <InlineEditTable
                  dataLoading={unbilledFeesLoading}
                  feesOrExpenses={feeList}
                  isExpenseMode={false}
                  onChangeOrder={onChangeFeeOrder}
                  onChange={onChangeFee}
                  openModal={onOpenModal}
                  onSelect={onUpdateSelectedFees}
                  selectedFeeOrExpenseIds={formData.selectedFeeIds || []}
                  supportsTax={supportsTax}
                />
              </>
            )}
            <h3>
              <span className="pointer" onClick={() => onSetExpandedExpenses(!expandedExpenses)}>
                <div className={expandedExpenses ? 'icon icon-arrow-37' : 'icon icon-arrow-40'} />
                {t('expenses').toUpperCase()}
              </span>
            </h3>
            {expandedExpenses && (
              <>
                {formData.showExpenseEntriesAs === 'SUMMARY' && (
                  <div className="row">
                    <div className={classnames(Styles.summaryText, 'col-md-12 col-lg-8')}>
                      <label>SUMMARY TEXT</label>
                      <textarea
                        value={formData.expenseSummaryLineDescription || ''}
                        className={classnames(
                          'form-control',
                          formErrors.expenseSummaryLineDescription.isInvalid && Styles.hasError,
                        )}
                        onChange={(e) => {
                          onUpdateField('expenseSummaryLineDescription', e.target.value);
                        }}
                      />
                    </div>
                  </div>
                )}
                <div className={Styles.blurb}>
                  Select entries below to add them to the invoice
                  <button
                    className={classnames('link-button', Styles.linkButton)}
                    onClick={() => {
                      onOpenModal({ entryType: 'EXPENSE', matterIdOverride: matterId });
                    }}
                    type="button"
                  >
                    <i className="icon icon-add-3" />
                    ADD ENTRY
                  </button>
                  <ContextMenu
                    className={Styles.entriesContextMenu}
                    distance={5}
                    position="top-right"
                    arrow={false}
                    // eslint-disable-next-line react/no-unstable-nested-components
                    body={({ close }) => (
                      <div className={classnames('list-group', Styles.contextMenuBody)}>
                        <div
                          type="button"
                          className={classnames('list-group-item', Styles.listGroupItem)}
                          onClick={() => {
                            onSortExpensesByDate();
                            close();
                          }}
                        >
                          Sort {t('expenses')} by date
                        </div>
                        <div
                          type="button"
                          className={classnames('list-group-item', Styles.listGroupItem)}
                          onClick={() => {
                            if (formData.showExpenseEntriesAs === 'SUMMARY') {
                              onUpdateField('showExpenseEntriesAs', 'LIST');
                            } else {
                              onUpdateField('showExpenseEntriesAs', 'SUMMARY');
                            }
                            close();
                          }}
                        >
                          {`Show as a ${formData.showExpenseEntriesAs === 'SUMMARY' ? 'list' : 'summary'}`}
                        </div>
                        <div
                          type="button"
                          className={classnames('list-group-item', Styles.listGroupItem)}
                          onClick={() => {
                            const newValue = !formData.showNonBillableExpenses;
                            onUpdateField('showNonBillableExpenses', newValue);

                            // If non-billable entries are ever displayed, we will need to keep
                            // fetching their data to include on the invoice if any are selected
                            // even if hidden
                            if (newValue === true && !formData.showNonBillableExpensesEverSelected) {
                              onUpdateField('showNonBillableExpensesEverSelected', true);
                            }
                          }}
                        >
                          {`${formData.showNonBillableExpenses ? 'Hide' : 'Show'} non-billable ${t(
                            'expenses',
                          )} on invoice`}
                        </div>
                        {formData.showExpenseEntriesAs === 'SUMMARY' && (
                          <div
                            type="button"
                            className={classnames('list-group-item', Styles.listGroupItem)}
                            onClick={() => {
                              onUpdateField('expenseListAppend', !formData.expenseListAppend);
                            }}
                          >
                            {formData.expenseListAppend
                              ? 'Do not append a list of entries to the invoice'
                              : 'Append a list of entries to the invoice'}
                          </div>
                        )}
                      </div>
                    )}
                  >
                    <div className="icon icon-kebab-fill" />
                  </ContextMenu>
                </div>
                <InlineEditTable
                  dataLoading={unbilledExpensesLoading}
                  feesOrExpenses={expenseList}
                  isExpenseMode
                  onChange={onChangeExpense}
                  onChangeOrder={onChangeExpenseOrder}
                  onSelect={onUpdateSelectedExpenses}
                  openModal={onOpenModal}
                  selectedFeeOrExpenseIds={formData.selectedExpenseIds || []}
                  supportsTax={supportsTax}
                />
              </>
            )}
          </FloatingCard>
        </CardContainer>

        <FinalisePanel
          userViewedMessages={userViewedMessages}
          balances={balances}
          totals={totals}
          trustAccountsDisabled={trustAccountsDisabled}
          matterTrustBalance={matterTrustBalance}
          isBalanceAvailable={isBalanceAvailable}
          matterBillingConfiguration={matter?.billingConfiguration}
          hasOpenTrustAccountsForMatter={hasOpenTrustAccountsForMatter}
          lessFundsInTrustAmount={lessFundsInTrustAmount}
          protectedTrustFundsAmount={protectedTrustFundsAmount}
          matterId={matterId}
          onClickLink={onClickLink}
          overrideRedirect={overrideRedirect}
          closeCurrentTab={closeCurrentTab}
          onSaveInvoice={onSaveInvoice}
          onSavePreDraftInvoice={onSavePreDraftInvoice}
          onPreviewInvoice={onPreviewInvoice}
          onShowSendInvoiceModal={onShowSendInvoiceModal}
          isUtbmsEnabledForFirm={isUtbmsEnabledForFirm}
          draftInvoice={draftInvoice}
          hasSelectedUnpaidAD={hasSelectedUnpaidAD}
          trustChequeEnabled={trustChequeEnabled}
          activeProviderFormattedSettings={activeProviderFormattedSettings}
          // form
          formData={formData}
          formErrors={formErrors}
          formReady={formReady}
          formValid={formValid}
          onUpdateField={onUpdateField}
          onUpdateTrustChequeReference={onUpdateTrustChequeReference}
          // discount
          canApplyDiscount={canApplyDiscount}
          onConfirmDiscount={onConfirmDiscount}
          onDiscardDiscount={onDiscardDiscount}
          onApplyDiscountChecked={onApplyDiscountChecked}
          // surcharge
          canApplySurcharge={canApplySurcharge}
          onConfirmSurcharge={onConfirmSurcharge}
          onDiscardSurcharge={onDiscardSurcharge}
          onApplySurchargeChecked={onApplySurchargeChecked}
          // allocation
          preferredBankAccountTypes={preferredBankAccountTypes}
          onChangeMatterBalanceAllocations={onChangeMatterBalanceAllocations}
          onChangeContactBalanceAllocations={onChangeContactBalanceAllocations}
        />
      </div>
    </div>
  );
};

BillingDraftInvoiceRoute.displayName = 'BillingDraftInvoiceRoute';

BillingDraftInvoiceRoute.propTypes = {
  draftInvoice: PropTypes.object,
  matter: PropTypes.object,
  expandedExpenses: PropTypes.bool.isRequired,
  expandedSummary: PropTypes.bool.isRequired,
  expandedTimeAndFees: PropTypes.bool.isRequired,
  expenseList: PropTypes.arrayOf(PropTypes.object).isRequired,
  feeList: PropTypes.arrayOf(PropTypes.object).isRequired,
  formData: PropTypes.object.isRequired,
  formReady: PropTypes.bool.isRequired,
  formValid: PropTypes.bool.isRequired,
  formErrors: PropTypes.object.isRequired,
  hasOpenTrustAccountsForMatter: PropTypes.bool.isRequired,
  invoiceId: PropTypes.string,
  matterDisplay: PropTypes.string,
  matterId: PropTypes.string.isRequired,
  onChangeExpense: PropTypes.func.isRequired,
  onChangeExpenseOrder: PropTypes.func.isRequired,
  onChangeFee: PropTypes.func.isRequired,
  onChangeFeeOrder: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  closeCurrentTab: PropTypes.func.isRequired,
  overrideRedirect: PropTypes.func.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onResetDefaultTemplate: PropTypes.func.isRequired,
  onSetExpandedExpenses: PropTypes.func.isRequired,
  onSetExpandedSummary: PropTypes.func.isRequired,
  onSetExpandedTimeAndFees: PropTypes.func.isRequired,
  onSortExpensesByDate: PropTypes.func.isRequired,
  onSortFeesByDate: PropTypes.func.isRequired,
  onUpdateContacts: PropTypes.func.isRequired,
  onUpdateField: PropTypes.func.isRequired,
  onUpdateInvoiceConfigurationField: PropTypes.func.isRequired,
  onUpdateInvoiceConfigurationFooterField: PropTypes.func.isRequired,
  onUpdateInvoiceConfigurationSubtitleField: PropTypes.func.isRequired,
  onUpdateInvoiceConfigurationTitleField: PropTypes.func.isRequired,
  onUpdateSelectedExpenses: PropTypes.func.isRequired,
  onUpdateSelectedFees: PropTypes.func.isRequired,
  onUpdateTemplate: PropTypes.func.isRequired,
  provideShowRetainerOption: PropTypes.bool.isRequired,
  descriptionOnDemandEnabled: PropTypes.bool.isRequired,
  showDodSummaryError: PropTypes.bool.isRequired,
  summaryPlaceholderText: PropTypes.string.isRequired,
  supportsTax: PropTypes.bool.isRequired,
  unbilledExpensesLoading: PropTypes.bool.isRequired,
  unbilledFeesLoading: PropTypes.bool.isRequired,
  userViewedMessages: PropTypes.arrayOf(PropTypes.string),

  // Debtor dropdowns
  contactOptions: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })).isRequired,
  contactOptionsDataLoading: PropTypes.bool.isRequired,
  contactOptionsHasMore: PropTypes.bool.isRequired,
  onFetchContactOptions: PropTypes.func.isRequired,
  onFetchMoreContactOptions: PropTypes.func.isRequired,

  // Invoice Template dropdown
  invoiceSettingsTemplateEntityLoading: PropTypes.bool.isRequired,
  invoiceSettingsTemplateOptions: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
  ).isRequired,
  invoiceSettingsTemplateOptionsDataLoading: PropTypes.bool.isRequired,
  invoiceSettingsTemplateOptionsHasMore: PropTypes.bool.isRequired,
  onFetchInvoiceSettingsTemplateOptions: PropTypes.func.isRequired,
  onFetchMoreInvoiceSettingsTemplateOptions: PropTypes.func.isRequired,

  // Finalise panel
  balances: PropTypes.object.isRequired,
  totals: PropTypes.object.isRequired,
  trustAccountsDisabled: PropTypes.bool.isRequired,
  matterTrustBalance: PropTypes.number.isRequired,
  isBalanceAvailable: PropTypes.bool.isRequired,
  lessFundsInTrustAmount: PropTypes.number.isRequired,
  canApplyDiscount: PropTypes.bool.isRequired,
  onConfirmDiscount: PropTypes.func.isRequired,
  onDiscardDiscount: PropTypes.func.isRequired,
  onApplyDiscountChecked: PropTypes.func.isRequired,
  canApplySurcharge: PropTypes.bool.isRequired,
  onConfirmSurcharge: PropTypes.func.isRequired,
  onDiscardSurcharge: PropTypes.func.isRequired,
  onApplySurchargeChecked: PropTypes.func.isRequired,
  protectedTrustFundsAmount: PropTypes.number.isRequired,
  preferredBankAccountTypes: PropTypes.arrayOf(PropTypes.string),
  onChangeContactBalanceAllocations: PropTypes.func.isRequired,
  onChangeMatterBalanceAllocations: PropTypes.func.isRequired,
  onSaveInvoice: PropTypes.func.isRequired,
  onSavePreDraftInvoice: PropTypes.func.isRequired,
  onPreviewInvoice: PropTypes.func.isRequired,
  onShowSendInvoiceModal: PropTypes.func.isRequired,
  isUtbmsEnabledForFirm: PropTypes.bool.isRequired,
  hasSelectedUnpaidAD: PropTypes.bool.isRequired,
  trustChequeEnabled: PropTypes.bool.isRequired,
  onUpdateTrustChequeReference: PropTypes.func.isRequired,
  activeProviderFormattedSettings: PropTypes.object.isRequired,
};

BillingDraftInvoiceRoute.defaultProps = {
  matter: undefined,
  draftInvoice: undefined,
  invoiceId: undefined,
  matterDisplay: undefined,
  userViewedMessages: undefined,
  preferredBankAccountTypes: undefined,
};

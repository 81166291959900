import { store } from '@sb-itops/redux';
import { isModalVisible, getModalDialogInfo } from '@sb-itops/redux/modal-dialog';

angular.module('sb.billing.webapp').component('sbPrintOperatingChequeModal', {
  templateUrl: 'ng-components/print-operating-cheque-modal/print-operating-cheque-modal.html',
  bindings: {},
  controller: function($scope) {
    const ctrl = this;

    const unsubscribeFromStore = store.subscribe(_.throttle(() => {
      ctrl.isOperatingChequeModalVisible = isOperatingChequeModalVisible();
      ctrl.selectedOperatingChequeIds = getSelectedOperatingChequeIds();
      $scope.$applyAsync();
    }, 100));

    ctrl.$onDestroy = () => {
      unsubscribeFromStore();
    };

    function isOperatingChequeModalVisible() {
      return isModalVisible({ modalId: 'print-operating-cheque-modal' });
    }
  
    function getSelectedOperatingChequeIds() {
      const modalState = getModalDialogInfo({ modalId: 'print-operating-cheque-modal' });
      const chequeIds = modalState && modalState.props && modalState.props.operatingChequeIds;
      return chequeIds;
    }  
  }
});

import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';

import { FEE_MODAL_ID } from 'web/components';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';

import { UnbilledMatterEntries } from './UnbilledMatterEntries';

const SCOPE = 'unbilled-matter-entries';

const hooks = ({ matterId, onNavigateTo, onOpenExpenseModal }) => ({
  useActions: () => {
    const onGoToDraft = () => {
      onNavigateTo('home.billing.create-bill', { matterId });
    };

    const onViewMatter = () => {
      onNavigateTo('home.billing.view-matter.fees', { matterId });
    };

    const onEditFee = (feeId) => {
      setModalDialogVisible({
        modalId: FEE_MODAL_ID,
        props: {
          scope: `${SCOPE}/fee-modal`,
          feeId,
          matterId,
        },
      });
    };

    const onEditExpense = (expenseId) => {
      onOpenExpenseModal(expenseId);
    };

    const onEditEntry = (entry) => {
      if (entry.feeType !== undefined) {
        onEditFee(entry.id);
      } else {
        onEditExpense(entry.id);
      }
    };

    return {
      onGoToDraft,
      onViewMatter,
      onEditEntry,
    };
  },
});

export const UnbilledMatterEntriesContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(UnbilledMatterEntries)),
);

UnbilledMatterEntriesContainer.displayName = 'UnbilledMatterEntriesContainer';

UnbilledMatterEntriesContainer.propTypes = {
  matterId: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  billableEntries: PropTypes.arrayOf(PropTypes.object),
  nonBillableEntries: PropTypes.arrayOf(PropTypes.object),
  // callback/functions
  onClickLink: PropTypes.func.isRequired,
  onNavigateTo: PropTypes.func.isRequired,
  onOpenExpenseModal: PropTypes.func.isRequired, // will be deprecated after expense is converted to LOD
};

UnbilledMatterEntriesContainer.defaultProps = {
  loading: false,
  billableEntries: [],
  nonBillableEntries: [],
};

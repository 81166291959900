angular.module('sb.billing.webapp').controller('SbExpenseModalController', function ($rootScope, $scope, $uibModalInstance, sbExpenseModalConfig, sbLoggerService) {
  'use strict';

  var log = sbLoggerService.getLogger('SbExpenseModalController');

  $scope.preFill = sbExpenseModalConfig.preFill;
  $scope.features = sbExpenseModalConfig.features || {};

  log.info('edit expense line', $scope.preFill);

  const routeChangeSuccessHandler = $rootScope.$on('$locationChangeSuccess', function() {
    $uibModalInstance.dismiss();
  });

  $scope.onClose = function (saved, deleted, dismiss) {
    if (saved) {
      $uibModalInstance.close({saved: saved});
    }
    if (deleted) {
      $uibModalInstance.close({deleted: deleted});
    }
    if (dismiss) {
      $uibModalInstance.dismiss();
    }
  };

  $scope.$on('$destroy', function() {
    routeChangeSuccessHandler();
  });
});

import React from 'react';
import PropTypes from 'prop-types';
import { PanelExpander } from '@sb-itops/react';

const ReportConfigSelector = React.memo(
  ({ reportConfigCategories, selectedId, onSelectionChange, showFilters, toggleShowFilters }) => (
    <div className="report-config-selector">
      <PanelExpander className="report-config-width" expanded={showFilters} toggleExpanded={toggleShowFilters}>
        {reportConfigCategories.map((reportConfigCategory) => (
          <div key={reportConfigCategory.name} className="category">
            <h3>{reportConfigCategory.title}</h3>
            <ul>
              {reportConfigCategory.reportConfigs.map((reportConfig) => (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <li
                  key={reportConfig.reportId}
                  onClick={() => onSelectionChange({ id: reportConfig.reportId })}
                  className={reportConfig.reportId === selectedId ? 'active' : ''}
                  title={reportConfig.name}
                >
                  {reportConfig.name}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </PanelExpander>
    </div>
  ),
);

ReportConfigSelector.displayName = 'ReportConfigSelector';

ReportConfigSelector.propTypes = {
  reportConfigCategories: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      reportConfigs: PropTypes.arrayOf(PropTypes.object),
    }),
  ),
  selectedId: PropTypes.string,
  onSelectionChange: PropTypes.func,
  showFilters: PropTypes.bool.isRequired,
  toggleShowFilters: PropTypes.func.isRequired,
};

ReportConfigSelector.defaultProps = {
  selectedId: undefined,
  onSelectionChange: () => {},
  reportConfigCategories: [],
};

export default ReportConfigSelector;

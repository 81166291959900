import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Typeahead } from '@sb-itops/react';
import { Add, Remove } from 'web/components/icons';
import styles from './SimpleContactMultiSelect.module.scss';

const SimpleContactMultiSelect = ({
  selectedContactIds,
  contactOptions,
  max,
  className,
  placeholder,
  disabled,
  isRequired,
  menuPlacement,
  maxMenuHeight,
  // callbacks and functions
  onContactsChanged,
}) => {
  const finalContactOptions = contactOptions;

  // internal state to keep track of cleared contact that should be auto focused on
  const [contactIndexToFocus, setContactIndexToFocus] = useState(undefined);

  // construct contact options map to enable faster lookup
  const contactOptionsMap = finalContactOptions.reduce((acc, contactOption) => {
    acc[contactOption.value] = contactOption;
    return acc;
  }, {});

  const updateSelectedContactIdsIfChanged = (newSelectedContactIds) => {
    if (
      newSelectedContactIds.length !== selectedContactIds.length ||
      !newSelectedContactIds.every((contactId, index) => contactId === selectedContactIds[index])
    ) {
      onContactsChanged(newSelectedContactIds);
    }
  };

  // one of the subtle use case here is user can clear a contact selection, when they do we
  // still need to render this cleared selection, thus selectedContactIds can be undefined
  const selectedDebtorIdsNonEmpty = selectedContactIds.filter((contactId) => contactId);
  const noContactSelected = selectedDebtorIdsNonEmpty.length === 0;

  const onContactChanged = (selectedIndex, selectedContactOption) => {
    const newSelectedContactIds = [...selectedContactIds];

    // The selection of a duplicate contacts should update the position of the contact rather than insert a duplicate
    const existingContactIdIndex = newSelectedContactIds.indexOf(selectedContactOption?.value);
    if (selectedContactOption && existingContactIdIndex !== -1) {
      newSelectedContactIds[existingContactIdIndex] = newSelectedContactIds[selectedIndex];
    }
    newSelectedContactIds[selectedIndex] = selectedContactOption?.value;

    // improves UX by focusing on cleared contact so user can start typing to find the right contact
    if (!selectedContactOption) {
      setContactIndexToFocus(selectedIndex);
    }
    updateSelectedContactIdsIfChanged(newSelectedContactIds);
  };

  const idsToRender = selectedContactIds?.length === 0 ? [''] : selectedContactIds;

  return (
    <>
      {idsToRender.slice(0, max).map((selectedContactId, index) => {
        // this look up is required in order to set a default selection
        const selectedContactOption = contactOptionsMap[selectedContactId];

        return (
          <div className={styles.contactWrapper} key={`contact-select-${index}`} id={`contact-select-${index}`}>
            <div className={classnames(styles.contactField, className)}>
              <Typeahead
                options={finalContactOptions}
                disabled={disabled}
                selectedOption={selectedContactOption}
                onSelect={(contactOption) => onContactChanged(index, contactOption)}
                placeholder={placeholder}
                className={styles.contactSelect}
                containerClassName={isRequired && noContactSelected && index === 0 ? styles.hasError : undefined}
                menuPlacement={menuPlacement}
                maxMenuHeight={maxMenuHeight}
                autoFocus={index === contactIndexToFocus}
              />
              {index === idsToRender.length - 1 && (
                <div className={styles.add}>
                  <Add onClick={() => onContactsChanged(selectedContactIds.concat(['']))} />
                </div>
              )}
              {index === idsToRender.length - 1 && index !== 0 && (
                <div className={styles.remove}>
                  <Remove onClick={() => onContactsChanged(selectedContactIds.slice(0, -1))} />
                </div>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

SimpleContactMultiSelect.displayName = 'SimpleContactMultiSelect';

SimpleContactMultiSelect.propTypes = {
  selectedContactIds: PropTypes.arrayOf(PropTypes.string),
  contactOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
      searchText: PropTypes.string,
    }),
  ).isRequired,
  max: PropTypes.number,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  // callbacks & functions
  onContactsChanged: PropTypes.func.isRequired,
  menuPlacement: PropTypes.oneOf(['bottom', 'top', 'auto']),
  maxMenuHeight: PropTypes.number,
  onClickLink: PropTypes.func,
};

SimpleContactMultiSelect.defaultProps = {
  selectedContactIds: [],
  max: 3,
  className: undefined,
  placeholder: 'Select a contact ...',
  disabled: false,
  isRequired: true,
  menuPlacement: 'auto',
  maxMenuHeight: undefined,
  onClickLink: () => {},
};

export default SimpleContactMultiSelect;

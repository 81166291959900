angular.module('sb.billing.webapp').directive('sbExpenseLongEntry', function() {
  'use strict';
  return {
    'restrict': 'E',
    'replace': true,
    'scope': {
      'features': '=?',
      'preFill': '=?',
      'date': '=?',
      'staff': '=?',
      'onClose': '&?'
    },
    'controller': 'SbExpenseEntryController',
    'controllerAs': 'expenseEntry',
    'templateUrl': 'ng-components/expense-entry/long/expense-long-entry.html'
  };
});

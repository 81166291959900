import React from 'react';
import PropTypes from 'prop-types';
import { featureActive } from '@sb-itops/feature';
import { Table, Column, utils } from '@sb-itops/react/table';
import { useTranslation } from '@sb-itops/react';
import { services as expenseService } from '@sb-billing/business-logic/expense';
import { MattersDisplayFromMatters } from '@sb-matter-management/react/matters-display';
import { MatterDisplay } from '@sb-matter-management/react/matter-display';

const { yyyymmddLocalisedRenderer, amountCellLocalisedRenderer } = utils;

const ExpenseTable = React.memo(({ expenses, onSort, sortDirection, sortBy }) => {
  const { t } = useTranslation();
  const includingTaxAmountCellRenderer = ({ rowData }) =>
    t('cents', { val: expenseService.getIncludingTaxAmount(rowData) });
  return (
    <Table list={expenses} sort={onSort} sortDirection={sortDirection} sortBy={sortBy}>
      <Column dataKey="expenseDate" label="Date" width={90} cellRenderer={yyyymmddLocalisedRenderer} />
      <Column dataKey="matterDisplay" label="Matter" flexGrow={1} cellRenderer={matterCellRenderer} />
      <Column dataKey="description" label="Subject" flexGrow={1} />
      <Column
        className="right-align"
        dataKey="quantity"
        label="Quantity"
        width={80}
        cellRenderer={quantityCellRenderer}
      />
      <Column
        className="right-align"
        dataKey="price"
        label="Price"
        width={130}
        cellRenderer={amountCellLocalisedRenderer}
      />
      <Column
        className="right-align"
        dataKey="amount"
        label={t('amountLabel')}
        width={130}
        cellRenderer={includingTaxAmountCellRenderer}
      />
    </Table>
  );
});

function matterCellRenderer({ rowData }) {
  return featureActive('BB-11425') ? (
    <MattersDisplayFromMatters matters={[rowData.matter]} />
  ) : (
    <MatterDisplay matterId={rowData.matterId} />
  );
}

function quantityCellRenderer({ cellData }) {
  return (Math.abs(cellData) / 100).toFixed(2);
}

ExpenseTable.propTypes = {
  expenses: PropTypes.array,
  onSort: PropTypes.func,
  sortDirection: PropTypes.string,
  sortBy: PropTypes.string,
};

ExpenseTable.defaultProps = {
  expenses: [],
  onSort: () => {},
  sortDirection: undefined,
  sortBy: undefined,
};

ExpenseTable.displayName = 'ExpenseTable';

export default ExpenseTable;

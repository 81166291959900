import { store } from '@sb-itops/redux';
import { selectors as filterSelectors } from 'web/redux/route/home-billing-matter-transactions';
import { getRememberedParams } from "web/redux/features/transactions-recent-account-selection/selectors";
import { rememberAccountStateParams } from "web/redux/features/transactions-recent-account-selection/actions";
import { filterTrustAccountsByMatter } from 'web/redux/selectors/filter-trust-accounts';

angular.module('sb.billing.webapp').config(function ($stateProvider) {
  'use strict';
  const BASE_DIRECTORY = 'ng-routes/home/billing/view-matter/transactions';
  const PARENT_PAGE_NAME = 'home.billing.view-matter.transactions';

  $stateProvider
    .state('home.billing.view-matter.transactions', {
      url: '/transactions',
      templateUrl: `${BASE_DIRECTORY}/transactions.html`,
      controller: 'SbPartialStateController',
      controllerAs: '$ctrl',
      abstract: true,
      reloadOnSearch: false,
      onEnter: function(sbOverrideRedirectService, $timeout, $stateParams, $state) {
        if (sbOverrideRedirectService.override) {
          sbOverrideRedirectService.resetOverride();
          return;
        }

        // If entering trust overview and not from a child page of transactions
        // Load the previously remembered page if there is one
        // $state.current.name is the state we are coming from, $stateParams is the params of the state we are going to
        if (!$state.current.name.includes(PARENT_PAGE_NAME)) {
          const rememberedAccountParams = getRememberedParams(store.getState()['web/transactions-recent-account-selection'], { pageName: PARENT_PAGE_NAME, entityId: $stateParams.matterId });
          if (rememberedAccountParams) {
            $timeout(() => {
              $state.go(...rememberedAccountParams);
            })
          } else {
            // If account only has one trust account, go straight to the account page
            if (filterTrustAccountsByMatter($stateParams.matterId).length === 1) {
              $timeout(function(){
                $state.go('home.billing.view-matter.transactions.trust', { trustAccountId: filterTrustAccountsByMatter($stateParams.matterId)[0].id });
              })
            }
          }
        }
      }
    })
    .state('home.billing.view-matter.transactions.trust', {
      url: '/trust/:trustAccountId',
      templateUrl: `${BASE_DIRECTORY}/trust-transactions.html`,
      reloadOnSearch: false,
      params: {
        trustAccountId: 'all',
      },
      data: {
        authorized: true,
        navHighlights: ['billing', 'matters', 'view-matter', 'matter-transactions', 'trust'],
        tab: { type: 'matter' },
        accountType: 'TRUST',
        // see SbPartialStateController for comments about 'subscribeToReduxStore' feature
        subscribeToReduxStore: () => ({
          showHidden: filterSelectors.getFilters(store.getState()).showHidden,
        }),
      },
      onEnter: function($stateParams) {
        store.dispatch(rememberAccountStateParams({ stateGoParams: ['home.billing.view-matter.transactions.trust', { ...$stateParams }], entityId: $stateParams.matterId, parentPageName: PARENT_PAGE_NAME }));
      },
    })
    .state('home.billing.view-matter.transactions.trust-default', {
      url: '/trust',
      onEnter: function($timeout, $state) {
          $timeout(function(){
            $state.go('home.billing.view-matter.transactions.trust', { trustAccountId: 'all' });
          })
      }
    })
    .state('home.billing.view-matter.transactions.controlled-money', {
      url: '/controlled-money/:cmaId',
      templateUrl: `${BASE_DIRECTORY}/controlled-money-accounts.html`,
      reloadOnSearch: false,
      params: {
        cmaId: null,
      },
      data: {
        authorized: true,
        navHighlights: ['billing', 'matters', 'view-matter', 'matter-transactions', 'controlled-money'],
        tab: { type: 'matter' },
        accountType: 'CONTROLLED_MONEY',
      },
      onEnter: function($stateParams) {
        store.dispatch(rememberAccountStateParams({ stateGoParams: ['home.billing.view-matter.transactions.controlled-money', { ...$stateParams }], entityId: $stateParams.matterId, parentPageName: PARENT_PAGE_NAME }));
      },
    })
    .state('home.billing.view-matter.transactions.protected-funds', {
      url: '/protected-funds/:trustAccountId',
      templateUrl: `${BASE_DIRECTORY}/protected-funds.html`,
      reloadOnSearch: false,
      params: {
        trustAccountId: null,
      },
      data: {
        authorized: true,
        accountType: 'PROTECTED_FUNDS',
        navHighlights: ['billing', 'matters', 'view-matter', 'matter-transactions', 'protected-funds'],
        tab: { type: 'matter' },
      },
      onEnter: function($stateParams) {
        store.dispatch(rememberAccountStateParams({ stateGoParams: ['home.billing.view-matter.transactions.protected-funds', { ...$stateParams }], entityId: $stateParams.matterId, parentPageName: PARENT_PAGE_NAME }));
      },
    })
    .state('home.billing.view-matter.transactions.operating', {
      url: '/operating',
      templateUrl: `${BASE_DIRECTORY}/operating-transactions.html`,
      reloadOnSearch: false,
      data: {
        authorized: true,
        navHighlights: ['billing', 'matters', 'view-matter', 'matter-transactions', 'operating'],
        tab: { type: 'matter' },
        accountType: 'OPERATING',
        // see SbPartialStateController for comments about 'subscribeToReduxStore' feature
        subscribeToReduxStore: () => ({
          showHidden: filterSelectors.getFilters(store.getState()).showHidden,
        }),
      },
      onEnter: function($stateParams) {
        store.dispatch(rememberAccountStateParams({ stateGoParams: ['home.billing.view-matter.transactions.operating', { ...$stateParams }], entityId: $stateParams.matterId, parentPageName: PARENT_PAGE_NAME }));
      },
    })
    .state('home.billing.view-matter.transactions.ledger', {
      url: '/ledger',
      templateUrl: `${BASE_DIRECTORY}/ledger.html`,
      reloadOnSearch: false,
      data: {
        authorized: true,
        navHighlights: ['billing', 'matters', 'view-matter', 'ledger'],
        tab: { type: 'matter' },
        accountType: 'LEDGER',
        // see SbPartialStateController for comments about 'subscribeToReduxStore' feature
        subscribeToReduxStore: () => ({
          startDate: filterSelectors.getFilters(store.getState()).dateListFilter.startDate,
          endDate: filterSelectors.getFilters(store.getState()).dateListFilter.endDate,
        })
      },
      onEnter: function($stateParams) {
        store.dispatch(rememberAccountStateParams({ stateGoParams: ['home.billing.view-matter.transactions.ledger', { ...$stateParams }], entityId: $stateParams.matterId, parentPageName: PARENT_PAGE_NAME }));
      },
    })
    .state('home.billing.view-matter.transactions.credit-note-ledger', {
      url: '/credit-note-ledger',
      templateUrl: `${BASE_DIRECTORY}/matter-credit-note-ledger.html`,
      reloadOnSearch: false,
      params: {
        showHidden: null,
        startDate: null,
        endDate: null
      },
      data: {
        authorized: true,
        navHighlights: ['billing', 'matters', 'view-matter', 'matter-transactions', 'credit-note-ledger'],
        tab: { type: 'matter' },
        accountType: 'CREDIT-NOTE-LEDGER',
        // see SbPartialStateController for comments about 'subscribeToReduxStore' feature
        subscribeToReduxStore: () => ({
          showHidden: filterSelectors.getFilters(store.getState()).showHidden,
        }),
      },
      onEnter: function($stateParams) {
        store.dispatch(rememberAccountStateParams({ stateGoParams: ['home.billing.view-matter.transactions.credit-note-ledger', { ...$stateParams }], entityId: $stateParams.matterId, parentPageName: PARENT_PAGE_NAME }));
      },
    })
    .state('home.billing.view-matter.transactions.client-communications', {
      url: '/client-communications',
      templateUrl: `${BASE_DIRECTORY}/client-communications.html`,
      reloadOnSearch: false,
      data: {
        authorized: true,
        navHighlights: ['billing', 'matters', 'view-matter', 'client-communications'],
        tab: { type: 'matter' },
        accountType: 'CLIENT-COMMUNICATIONS',
        subscribeToReduxStore: () => ({
          showMarkAsSentEntries: filterSelectors.getFilters(store.getState()).showMarkAsSentEntries,
        }),
      },
      onEnter: function($stateParams) {
        store.dispatch(rememberAccountStateParams({ stateGoParams: ['home.billing.view-matter.transactions.client-communications', { ...$stateParams }], entityId: $stateParams.matterId, parentPageName: PARENT_PAGE_NAME }));
      },
    })
});

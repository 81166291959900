import React from 'react';
import PropTypes from 'prop-types';
import { Translation, Button, PaginationSelector } from '@sb-itops/react';
import { AddEventModal } from 'web/components';
import { Table, Column } from '@sb-itops/react/table';
import classnames from 'classnames';
import { cleanHtml } from '@sb-itops/html';
import * as Styles from './ViewMatterCalendarRoute.module.scss';

const commentRenderer = ({ cellData }) => (
  // eslint-disable-next-line react/no-danger
  <div dangerouslySetInnerHTML={{ __html: cleanHtml({ dirtyHtml: cellData }) }} className={Styles.commentField} />
);
const staffRenderer = ({ cellData }) => (cellData || []).map((i) => i?.initials).join(', ');
const timestampRenderer = ({ cellData, rowData, dataKey }) => {
  if (rowData.allDay && dataKey === 'startTime') {
    return cellData ? (
      <Translation>{(t) => `${t('date', { ts: cellData, format: 'ddd DD/MM/YYYY' })} (all day)`}</Translation>
    ) : null;
  }
  if (rowData.allDay && dataKey === 'endTime') {
    return null;
  }

  return cellData ? (
    <Translation>{(t) => t('date', { ts: cellData, format: 'ddd DD/MM/YYYY hhmm A' })}</Translation>
  ) : null;
};

export const ViewMatterCalendarRoute = ({
  calendar,
  loading,
  onDeleteAppointment,
  onSaveAppointment,
  sortDirection,
  sortBy,
  sort,
  modalOpen,
  attendees,
  matterSummaries,
  onCloseModal,
  onOpenModal,
  loggedInStaff,
  currentCalendarPage,
  totalNumberOfCalendarPages,
  onCalendarListPageChange,
  matterId,
}) => (
  <div className="master-detail-panel">
    <div className={classnames(Styles.container, 'panel-body')}>
      {!!modalOpen && (
        <AddEventModal
          attendees={attendees}
          matterSummaries={matterSummaries}
          loggedInStaff={loggedInStaff}
          onSaveAppointment={onSaveAppointment}
          onDeleteAppointment={onDeleteAppointment}
          onClose={onCloseModal}
          appointmentData={modalOpen}
          matterId={matterId}
        />
      )}
      <div className={classnames('ribbon', 'panel', 'panel-primary')}>
        <Button onClick={() => onOpenModal({})} className={Styles.newEventButton} type="tertiary">
          New Event
        </Button>
      </div>

      <Table
        className={Styles.calendarTable}
        onRowClick={({ rowData }) => {
          // edit appointment
          onOpenModal(rowData);
        }}
        list={calendar}
        sort={sort}
        sortBy={sortBy}
        sortDirection={sortDirection}
        dataLoading={loading}
        resetScrollPositionOnDataChange
      >
        <Column dataKey="subject" label="Subject" disableSort flexGrow={2} />
        <Column dataKey="startTime" cellRenderer={timestampRenderer} label="Start" width={180} />
        <Column dataKey="endTime" cellRenderer={timestampRenderer} width={180} label="End" />
        <Column dataKey="location" label="Location" disableSort flexGrow={1} />
        <Column dataKey="staff" cellRenderer={staffRenderer} label="Calendar" width={100} disableSort />
        <Column dataKey="description" cellRenderer={commentRenderer} label="Comment" flexGrow={4} disableSort />
      </Table>
      <div className={Styles.paginationSection}>
        <PaginationSelector
          name="matterCalendar"
          numberOfPagesDisplayed={10}
          selectedPage={currentCalendarPage}
          totalNumberOfPages={totalNumberOfCalendarPages}
          onPageChange={onCalendarListPageChange}
        />
      </div>
    </div>
  </div>
);

ViewMatterCalendarRoute.displayName = 'ViewMatterCalendarRoute';

ViewMatterCalendarRoute.propTypes = {
  onSaveAppointment: PropTypes.func.isRequired,
  onDeleteAppointment: PropTypes.func.isRequired,
  calendar: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  sortDirection: PropTypes.string,
  matterId: PropTypes.string,
  sortBy: PropTypes.string,
  sort: PropTypes.func,
  matterSummaries: PropTypes.array.isRequired,
  attendees: PropTypes.array.isRequired,
  loggedInStaff: PropTypes.object,
  modalOpen: PropTypes.any.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  currentCalendarPage: PropTypes.number.isRequired,
  totalNumberOfCalendarPages: PropTypes.number.isRequired,
  onCalendarListPageChange: PropTypes.func.isRequired,
};

ViewMatterCalendarRoute.defaultProps = {
  loggedInStaff: undefined,
  matterId: '',
  loading: false,
  sortDirection: 'asc',
  sortBy: 'endTime',
  sort: () => {},
};

'use strict';

const { roundCents } = require('@sb-billing/bankers-rounding');

/**
 * Returns the raw expense amount calculated from quantity and price, quantity is stored in basis points
 *
 * Gotchas: this function is really confusing in that it divides the final amount by 100
 * I think the reason why it does this is because expense.quantity is stored in basis points
 * e.g. quantity of 1 should be specified as 100. Use this with care if you try to pass
 * quantity as a normal number rather than in basis points. This will result in a dollar amount.
 *
 * @param {Object} expense
 * @returns {number} expense amount in cents (if expense.quantity is specified in basis points)
 */
function getRawAmount(expense) {
  return roundCents((expense.quantity * expense.price) / 100); // TODO: decimal-operation
}

// TODO: rename to getAmountExcludingTax
function getExcludingTaxAmount(expense) {
  const rawAmount = getRawAmount(expense);
  if (expense.amountIncludesTax) {
    return rawAmount - expense.tax;
  }
  return rawAmount;
}

// TODO: rename to getAmountIncludingTax
function getIncludingTaxAmount(expense) {
  const rawAmount = getRawAmount(expense);
  if (expense.amountIncludesTax) {
    return rawAmount;
  }
  return rawAmount + expense.tax;
}

function getAmountIncludingOutputTax(expense) {
  return getExcludingTaxAmount(expense) + getOutputTax(expense);
}

function getBillableAmountExcludingTax(expense) {
  // waived (written off) amounts are still considered billable
  return expense.isBillable ? getExcludingTaxAmount(expense) : 0;
}

// TODO: probably should be renamed to getWrittenOffAmount
function getWaivedAmount(expense) {
  return expense.waived ? getExcludingTaxAmount(expense) : 0;
}

// BB-786 billed amount should not include non-billable
// This function is only used in the expense list controller
function getBilledAmount(expense) {
  return (expense.invoiceId || expense.invoice?.id) && expense.isBillable ? getExcludingTaxAmount(expense) : 0;
}

/**
 * Get output tax for an expense, this caters for the case where output tax
 * is not defined which is the case for all old expense entities before we
 * introduced Input/Output tax.
 * @param {Object} expense
 * @returns output tax
 */
function getOutputTax(expense) {
  return expense.outputTax !== undefined && expense.outputTax !== null ? expense.outputTax : expense.tax;
}

function calculateTaxAmount(expense, firmTaxRate) {
  if (!expense.isTaxOverridden) {
    const taxRate = firmTaxRate / 10000.0; // The tax rate is stored in basis points (1/10,000), so 1000 basis points = 10%.
    const factor = expense.amountIncludesTax ? taxRate / (1 + taxRate) : taxRate;
    return roundCents(getRawAmount(expense) * factor);
  }
  return expense.tax;
}

function calculateOutputTaxAmount({ expense, outputTaxRate }) {
  if (!expense.isOutputTaxOverridden) {
    const outputTaxRateInPercentagePoint = outputTaxRate / 10000.0; // The tax rate is stored in basis points (1/10,000), so 1000 basis points = 10%.
    // Output tax should be applied to the expense amount excluding tax
    const outputTax = roundCents(getExcludingTaxAmount(expense) * outputTaxRateInPercentagePoint);
    return outputTax;
  }
  // realistically when isOutputTaxOverridden is true, expense.outputTax should alway have a value
  return getOutputTax(expense);
}

function getBillableOutputTax(expense) {
  return expense.isBillable ? getOutputTax(expense) : 0;
}

function getBillableAmountIncludingOutputTax(expense) {
  // billable amount is not necessarily the amount that goes on the invoice
  // this is the amount shown on the draft invoice screen, if the expense is
  // waived (written off) then the final amount that goes on the invoice is $0
  if (!expense.isBillable) {
    return 0;
  }
  return getAmountIncludingOutputTax(expense);
}

module.exports = {
  calculateTaxAmount,
  calculateOutputTaxAmount,
  getRawAmount,
  getExcludingTaxAmount,
  getIncludingTaxAmount,
  getBillableAmountExcludingTax,
  getBillableAmountIncludingOutputTax,
  getWaivedAmount,
  getBilledAmount,
  getBillableOutputTax,
  getOutputTax,
};

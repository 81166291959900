/* eslint-disable import/no-cycle */
import moment from 'moment';
import { cacheFactory, syncTypes, indexTypes } from '@sb-itops/redux';
import { featureActive } from '@sb-itops/feature';
import { bankAccountTypes } from '@sb-billing/business-logic/bank-account/entities/constants';
import { getById as getBankAccountById } from '../bank-account';
import domain from '../domain';
import { getActiveByMatterBankAccountId } from '../balance-protection';

const getMatterIdBankAccountIdIndex = (matterId, bankAccountId) => `${matterId}-${bankAccountId}`;

const api = cacheFactory({
  domain,
  name: 'transactions',
  keyPath: 'id',
  ngCacheName: 'sbTransactionService',
  syncType: syncTypes.SINCE,
  immutable: false,
  indexes: [
    {
      name: 'getByEffectiveDate',
      indexer: (tx) => tx && tx.effectiveDate,
      type: indexTypes.ONE_TO_MANY,
      reducer: (accumulator, entity) => {
        const matterIdBankAccountIndex = getMatterIdBankAccountIdIndex(entity.matterId, entity.bankAccountId);
        const lastAccumulatedValue = accumulator[matterIdBankAccountIndex] || 0;

        const newEntity = {
          ...accumulator,
          [matterIdBankAccountIndex]: lastAccumulatedValue + entity.amount || 0,
        };

        return newEntity;
      },
    },
  ],
  changesetProcessor: ({ entities = [] }) =>
    entities.map((transaction) => {
      const bankAccount = getBankAccountById(transaction.bankAccountId);
      const bankAccountType = bankAccountTypes[bankAccount?.accountType];
      const transformed = { ...transaction, bankAccountType };

      return transformed;
    }),
});

export const { getMap, updateCache, clearCache, UPDATE_CACHE, getIndex } = api;

export const TRANSACTION_SOURCES = {
  cheque: 'Check',
};

// selectors
export const getById = (id) => formatTransaction(getMap()[id]);
export const getList = () => api.getList().map(formatTransaction);

export const getByContactId = (contactId) => getList().filter((txn) => txn.contactId === contactId);
export const getByMatterId = (matterId) => getList().filter((txn) => txn.matterId === matterId);
export const getByInvoiceId = (invoiceId) => getList().filter((txn) => txn.invoiceId === invoiceId);
export const getByPaymentId = (paymentId) => getList().filter((txn) => txn.paymentId === paymentId);
export const getByAccountType = (type) => getList().filter((txn) => txn.bankAccountType === type);
export const getByBankAccountId = (bankAccountId) => getList().filter((txn) => txn.bankAccountId === bankAccountId);

// helper functions
export function formatTransaction(transaction) {
  if (!transaction) {
    return transaction;
  }

  if (transaction.type === 'VendorPaymentReversal' || transaction.type === 'VendorPayment') {
    const description = transaction.description.replace(
      / for matter matterId:........-....-....-....-............$/,
      '',
    );
    return { ...transaction, description };
  }

  return transaction;
}

/**
 * Calculates and returns the trust balance for the matter until the date
 *
 * @param {String} matterId Matter id for which the trust balance should be calculated.
 * @param {Number} date date in YYYYMMDD format as an integer.
 * @param {String} bankAccountId Trust bank account Id we want balance for
 *
 * @return {Number} The trust balance for the matter as of the date.
 */
export const getMatterTrustBalanceByDate = (matterId, date, bankAccountId) => {
  const indexedEffectiveDates = getIndex('getByEffectiveDate');
  const effectiveDates = Object.keys(indexedEffectiveDates);

  const balanceAtDate = effectiveDates.reduce((acc, effectiveDate) => {
    if (effectiveDate <= date) {
      const matterIdBankAccountPairs = indexedEffectiveDates[effectiveDate] || {};
      const amount = matterIdBankAccountPairs[getMatterIdBankAccountIdIndex(matterId, bankAccountId)] || 0;
      return acc + amount;
    }
    return acc;
  }, 0);
  if (featureActive('BB-8671')) {
    const protectedBalance = getActiveByMatterBankAccountId(matterId, bankAccountId).reduce(
      (acc, protectedFund) => acc + protectedFund.amount,
      0,
    );
    // Balance should not go below 0 OR the existing balance if the existing balance is already below 0
    return Math.max(balanceAtDate - protectedBalance, Math.min(balanceAtDate, 0));
  }

  return balanceAtDate;
};

export const calculateBankAccountBalanceForDate = ({ bankAccountId, yyyymmdd, byEnteredDate }) => {
  const filterDate = byEnteredDate ? moment(`${yyyymmdd}`).toISOString() : yyyymmdd;

  return getList().reduce((total, tx) => {
    if (tx.type === 'Transfer') {
      return total;
    }

    if (tx.bankAccountId !== bankAccountId) {
      return total;
    }

    const includeAmount = byEnteredDate ? tx.timestamp < filterDate : tx.effectiveDate < filterDate;

    if (includeAmount) {
      return total + tx.amount;
    }

    return total;
  }, 0);
};

export * from './transaction-opdates';
export * from './reverse-deposit';
export { buildReversalTransactionOpdates } from './reversal-transaction-opdates';
export * from './consolidated-trust-transactions';
export { addBankFee } from './add-bank-fee';
export { addBankInterest } from './add-bank-interest';
export { reverseBankFee } from './reverse-bank-fee';
export { reverseBankInterest } from './reverse-bank-interest';

angular.module('sb.billing.webapp').component('sbTrustToOffice', {
  templateUrl: 'ng-routes/home/billing/bills/trust-to-office/trust-to-office.html',
  controller: function($scope, $location, sbLinkService, sbLocalisationService) {
    const ctrl = this;

    ctrl.onClickLink = sbLinkService.onClickLink;
    ctrl.t = sbLocalisationService.t;
    // this is to pass through query strings, which can be used to help remember filter selection
    $scope.$on('$locationChangeSuccess', () => {
      ctrl.sbQuery = _.cloneDeep($location.search());
    });
  }
});
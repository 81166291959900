import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from './TabList.module.scss';

const TabListV2 = memo(({ className, list, selectedId, onTabSelectionChange }) => (
  <div className={classnames(Styles.tab, className)}>
    <div className={Styles.tabBackground}>
      {list.map(({ label, id }) => (
        <div
          key={id}
          onClick={() => onTabSelectionChange(id)}
          className={classnames(selectedId === id && Styles.active, Styles.tabText)}
        >
          {label}
        </div>
      ))}
    </div>
  </div>
));

TabListV2.propTypes = {
  className: PropTypes.string,
  selectedId: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onTabSelectionChange: PropTypes.func.isRequired,
};

TabListV2.defaultProps = {
  className: undefined,
  selectedId: undefined,
};

export default TabListV2;

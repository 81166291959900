import { connect } from 'react-redux';
import { withReduxStore } from '@sb-itops/react';
import { hasFacet, facets } from '@sb-itops/region-facets';
import PropTypes from 'prop-types';
import * as billingAccountsTransactions from 'web/redux/route/home-billing-accounts-transactions';
import { toggleShowSystemDateApi as toggleShowSystemDateMatterApi } from 'web/redux/route/home-billing-matter-transactions';
import { toggleShowSystemDateApi as toggleShowSystemDateContactApi } from 'web/redux/route/home-billing-contact-transactions';
import TrustTransactionTable from './TrustTransactionTable';

const mapStateToProps = (state, props) => {
  let showSystemDate = billingAccountsTransactions.toggleShowSystemDateApi.getState().showSystemDate;
  if (props.listType === 'matter') {
    showSystemDate = toggleShowSystemDateMatterApi.getState().showSystemDate;
  } else if (props.listType === 'contact') {
    showSystemDate = toggleShowSystemDateContactApi.getState().showSystemDate;
  }

  const { showHidden } = billingAccountsTransactions.selectors.getFilters(state);
  return {
    ...props,
    showSystemDate,
    showHidden,
    allowReferenceSort: hasFacet(facets.autoGeneratedReference),
  };
};

const Wrapper = withReduxStore(connect(mapStateToProps)(TrustTransactionTable));

Wrapper.propTypes = {
  showMatter: PropTypes.bool,
  showContact: PropTypes.bool,
  showHidden: PropTypes.bool,
  modal: PropTypes.func,
  transactions: PropTypes.array,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  summary: PropTypes.object,
  sort: PropTypes.func,
  onClickLink: PropTypes.func,
  onReceiptLink: PropTypes.func, // provided by withLinkHandler
  onTransferReceiptLink: PropTypes.func, // provided by withLinkHandler
  onVendorProofOfPaymentLink: PropTypes.func,
  onTrustToOfficeTransferReceiptLink: PropTypes.func,
  listType: PropTypes.oneOf(['accounts', 'matter', 'contact']).isRequired,
};

Wrapper.defaultProps = {
  sortBy: 'effectiveDate',
  sortDirection: 'desc',
  transactions: [],
  summary: {},
};

export default Wrapper;

import React from 'react';
import { Checkbox } from '@sb-itops/react/checkbox';
import { Table, Column, utils } from '@sb-itops/react/table';
import { ExpandCollapseToggler } from '@sb-itops/react/expand-collapse-toggler';
import { MatterDisplay } from '@sb-matter-management/react/matter-display';
import { InterpolatedDescription } from '../interpolated-description';
import Styles from './PaymentsReceiptsTable.module.scss';

const {
  yyyymmddLocalisedRenderer,
  timestampLocalisedRenderer,
  balanceCellLocalisedRenderer,
  checkboxCellWrapperRenderer,
} = utils;

const expandCellRenderer =
  ({ onToggleExpand }) =>
  ({ rowData }) =>
    rowData.isDepositSlip || rowData.isConsolidatedTrustToOffice ? (
      <ExpandCollapseToggler onToggled={() => onToggleExpand({ id: rowData.id })} isExpanded={rowData.expanded} />
    ) : (
      ''
    );

const checkboxCellRenderer =
  ({ onToggleItem }) =>
  ({ rowData }) =>
    !rowData.expandChild && (
      <Checkbox
        checked={rowData.selected || rowData.reconciled}
        disabled={rowData.reconciled}
        onChange={() => {
          if (rowData.isDepositSlip) {
            onToggleItem({
              depositSlip: rowData,
            });
          } else if (rowData.isConsolidatedTrustToOffice) {
            onToggleItem({
              tto: rowData,
            });
          } else {
            onToggleItem({
              transaction: rowData,
            });
          }
        }}
      />
    );

const checkboxHeaderRenderer =
  ({ selectAll, clearAll, allItemsSelected }) =>
  () =>
    (
      <div className={Styles.tableHeader}>
        <Checkbox checked={allItemsSelected} onChange={allItemsSelected ? clearAll : selectAll} />
      </div>
    );

const effectiveDateCellRenderer = ({ cellData, rowData }) => (
  <span className={rowData.expandChild ? Styles.expandChild : ''}>{yyyymmddLocalisedRenderer({ cellData })}</span>
);

const isoDateCellRenderer = ({ cellData, rowData }) => (
  <span className={rowData.expandChild ? Styles.expandChild : ''}>{timestampLocalisedRenderer({ cellData })}</span>
);

const descriptionCellRenderer =
  ({ onClickLink }) =>
  ({ cellData, rowData }) =>
    (
      <span className={rowData.expandChild ? Styles.expandChild : ''}>
        <InterpolatedDescription description={cellData} onClickLink={onClickLink} deleted={rowData.isHidden} />
      </span>
    );

const referenceRenderer = ({ cellData, rowData }) => (
  <span className={rowData.expandChild ? Styles.expandChild : ''}>{cellData}</span>
);

const matterCellRenderer =
  ({ onClickLink }) =>
  ({ rowData }) =>
    rowData.matterId ? (
      <span className={rowData.expandChild ? Styles.expandChild : ''}>
        <MatterDisplay matterId={rowData.matterId} onClickLink={onClickLink} asLink />
      </span>
    ) : (
      ''
    );

const amountRenderer = ({ cellData, rowData }) => (
  <span className={rowData.expandChild ? Styles.expandChild : ''}>
    {cellData && !rowData.expanded ? balanceCellLocalisedRenderer({ cellData: Math.abs(cellData) }) : ''}
  </span>
);

const PaymentsReceiptsTable = (props) => {
  const {
    onRowClick,
    items,
    onClickLink,
    onToggleItem,
    onToggleExpand,
    onSort,
    sortBy,
    sortDirection,
    selectAll,
    clearAll,
    useEnteredDate,
  } = props;

  // expandChild are items which are displayed only when parent item is expanded
  // we can ignore them as the parent item determines if they are selected or not
  const allItemsSelected = items.every((item) => item.selected || item.expandChild);

  // TX Row   : -, [], effectiveDate, enteredDate, description, reference, matter, debit, credit
  // DS Row   : >, [], effectiveDate, enteredDate, description, reference,      -, debit, credit
  // DS Child : -,  -, effectiveDate, enteredDate, description, reference,      -, debit, credit
  // TTO Row  : >, [], effectiveDate, enteredDate, description, reference,      -, debit, credit
  // TTO Child: -,  -, effectiveDate, enteredDate, description, reference,      -, debit, credit

  return (
    <Table
      className="billing-payments-receipts-table"
      onRowClick={({ rowData }) =>
        onRowClick({ id: rowData.id, isDepositSlipOrTto: rowData.isDepositSlip || rowData.isConsolidatedTrustToOffice })
      }
      list={items}
      sort={onSort}
      sortBy={sortBy}
      sortDirection={sortDirection}
    >
      <Column
        dataKey="UNNEEDED"
        label=""
        width={30}
        cellRenderer={expandCellRenderer({ onToggleExpand })}
        disableSort
      />
      <Column
        dataKey="selected"
        label=""
        width={30}
        className={Styles.toggle}
        cellRenderer={checkboxCellWrapperRenderer(checkboxCellRenderer({ onToggleItem }))}
        headerRenderer={checkboxCellWrapperRenderer(checkboxHeaderRenderer({ selectAll, clearAll, allItemsSelected }))}
        disableSort
      />
      <Column dataKey="effectiveDate" label="Transaction Date" width={120} cellRenderer={effectiveDateCellRenderer} />
      {useEnteredDate && (
        <Column dataKey="enteredDate" label="Entered Date" width={120} cellRenderer={isoDateCellRenderer} />
      )}
      <Column
        dataKey="description"
        label="Description"
        flexGrow={2.5}
        cellRenderer={descriptionCellRenderer({ onClickLink })}
      />
      <Column dataKey="reference" label="Reference" flexGrow={2} cellRenderer={referenceRenderer} />
      <Column
        dataKey="matterDisplay"
        label="Matter"
        flexGrow={2.5}
        cellRenderer={matterCellRenderer({ onClickLink })}
      />
      <Column dataKey="debit" label="Debit" width={120} cellRenderer={amountRenderer} className={Styles.amount} />
      <Column dataKey="credit" label="Credit" width={120} cellRenderer={amountRenderer} className={Styles.amount} />
    </Table>
  );
};

export default PaymentsReceiptsTable;

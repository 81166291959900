import * as PropTypes from 'prop-types';
import React from 'react';
import { CalendarDataContainer } from 'web/containers';
import { useMetric } from 'web/services/metrics';
import { ViewMatterCalendarRoute } from './ViewMatterCalendarRoute';

const scope = 'view-matter-calendar-route';

export const ViewMatterCalendarRouteContainer = ({ matterId }) => {
  const WrappedComponent = CalendarDataContainer(ViewMatterCalendarRoute);
  useMetric('NavMatterEvents');

  return <WrappedComponent scope={scope} matterId={matterId} />;
};

ViewMatterCalendarRouteContainer.displayName = 'ViewMatterCalendarRouteContainer';

ViewMatterCalendarRouteContainer.propTypes = {
  matterId: PropTypes.string,
};

ViewMatterCalendarRouteContainer.defaultProps = {
  matterId: undefined,
};

import { CLEAR_ALL, TOGGLE_EXPENSES } from './types';

const initialState = {
  selectedExpenses: [],
};

export const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case CLEAR_ALL: {
      return {
        ...state,
        selectedExpenses: [],
      };
    }

    case TOGGLE_EXPENSES: {
      return {
        ...state,
        selectedExpenses: action.payload.expenseIds.reduce(
          (selected, expenseId) => {
            const index = selected.indexOf(expenseId);
            if (index > -1) {
              selected.splice(index, 1);
            } else {
              selected.push(expenseId);
            }
            return selected;
          },
          [...state.selectedExpenses],
        ),
      };
    }

    default: {
      return state;
    }
  }
};

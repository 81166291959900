import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import * as forms from '@sb-itops/redux/forms2';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { todayAsInteger } from '@sb-itops/date';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { findCurrentAndFutureVersions } from '@sb-billing/business-logic/rate-sets';
import PropTypes from 'prop-types';
import * as messageDisplay from '@sb-itops/message-display';
import uuid from '@sb-itops/uuid';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';

import { AddEditRateSetSchema, rateSetNameMustBeUniqueErrorMessage } from './AddEditRateSet.schema';
import { AddEditRateSet } from './AddEditRateSet';

const hooks = (props) => ({
  useForm: () => {
    const {
      selectors: formSelectors,
      actions: formActions,
      operations: formOperations,
    } = useScopedFeature(forms, 'add-edit-rate-set-form');

    const {
      formInitialised,
      fields: formFields,
      submitFailed,
      formSubmitting,
      formDirty,
    } = useSelector(formSelectors.getFormState);
    const formFieldValues = useSelector(formSelectors.getFieldValues);
    const { currentStaffRates, futureStaffRates } = formFieldValues;
    const { id, rateSetName, updateRatesOn, isHidden } = formFields;

    const dispatch = useDispatch();

    if (!formInitialised && props.currentRateSet && props.currentRateSet.id) {
      // edit mode form initialisation
      const rateSet = props.currentRateSet;
      const { currentVersion, futureVersion } = rateSet ? findCurrentAndFutureVersions({ rateSet }) : {};
      dispatch(
        formActions.initialiseForm({
          fieldValues: {
            id: rateSet?.id || undefined,
            rateSetName: rateSet?.name || '',
            currentStaffRates: currentVersion?.ratesPerStaff || [],
            updateRatesOn: futureVersion?.validFrom || undefined,
            futureStaffRates: futureVersion?.ratesPerStaff || [],
            isHidden: rateSet?.isHidden || false,
          },
        }),
      );
    }

    useEffect(() => {
      // add mode form initialisation
      if (!props.rateSetId) {
        dispatch(
          formActions.initialiseForm({
            fieldValues: {
              id: undefined,
              rateSetName: '',
              currentStaffRates: [],
              updateRatesOn: undefined,
              futureStaffRates: [],
              isHidden: false,
            },
          }),
        );
      }
      const onUnload = () => {
        dispatch(formActions.clearForm());
      };
      return onUnload;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validateFn = (fieldValues) => {
      const formErrors = {};

      // ensure rate set name is unique
      const rateSetNameMustBeUnique = props.rateSets.some((rateSet) => {
        if (rateSet.id === id.value) {
          return false;
        }

        return rateSet.name.trim() === fieldValues.rateSetName.trim();
      });

      if (rateSetNameMustBeUnique) {
        formErrors.rateSetName = rateSetNameMustBeUniqueErrorMessage;
      }

      return formErrors;
    };

    const onFieldValueSet = ({ field, value }) => {
      dispatch(formActions.setFieldValue({ field, value }));
      dispatch(formOperations.validateForm({ schema: AddEditRateSetSchema, validateFn }));
    };

    const onFieldValueUpdated = (fieldValues) => {
      dispatch(formActions.updateFieldValues({ fieldValues }));
      dispatch(formOperations.validateForm({ schema: AddEditRateSetSchema, validateFn }));
    };

    const validateForm = () => {
      dispatch(formOperations.validateForm({ schema: AddEditRateSetSchema, validateFn }));
    };

    const onIsHiddenToggled = () => {
      onFieldValueUpdated({ isHidden: !isHidden.value });
    };

    const onSave = async () => {
      try {
        await dispatch(
          formOperations.submitFormWithValidationP({
            submitFnP: async (formData) => {
              // load rate set
              const rateSet = props.rateSetId ? props.currentRateSet : undefined;
              let { currentVersion, futureVersion } = rateSet ? findCurrentAndFutureVersions({ rateSet }) : {};
              const versions = rateSet?.versions || [];

              // validTo is the day before the updateRatesOn date
              const currentVersionValidTo = formData.updateRatesOn
                ? parseInt(moment(`${formData.updateRatesOn}`, 'YYYYMMDD').subtract(1, 'days').format('YYYYMMDD'), 10)
                : null;

              // update current version if it exists
              if (currentVersion?.versionId) {
                currentVersion.validTo = currentVersionValidTo;
                currentVersion.customRatesPerStaff = formData.currentStaffRates;
              } else {
                currentVersion = {
                  versionId: uuid(),
                  validFrom: todayAsInteger(),
                  validTo: currentVersionValidTo,
                  customRatesPerStaff: formData.currentStaffRates,
                };
                versions.push(currentVersion);
              }

              // no updateRatesOn date signify no future version has been defined
              if (formData.updateRatesOn) {
                if (futureVersion?.versionId) {
                  futureVersion.validFrom = formData.updateRatesOn;
                  futureVersion.customRatesPerStaff = formData.futureStaffRates;
                } else {
                  futureVersion = {
                    versionId: uuid(),
                    validFrom: formData.updateRatesOn,
                    validTo: null,
                    customRatesPerStaff: formData.futureStaffRates,
                  };
                  versions.push(futureVersion);
                }
              } else if (futureVersion?.versionId) {
                // if there is no updateRatesOn date, and a future version exists, remove it
                versions.splice(versions.indexOf(futureVersion), 1);
              }

              const saveRateSetMessage = {
                rateSet: {
                  id: rateSet?.id || uuid(),
                  name: formData.rateSetName.trim(),
                  isHidden: formData.isHidden,
                  versions,
                },
              };

              await dispatchCommand({ type: 'Billing.Fees.Commands.SaveRateSet', message: saveRateSetMessage });

              props.onClose();
              await dispatch(formActions.clearForm());
              messageDisplay.success('Rate set has been saved');
            },
          }),
        );
      } catch (err) {
        messageDisplay.error('Failed to save rate set');
      }
    };

    return {
      staffMembers: props.staffMembers,
      // form fields
      id,
      rateSetName,
      currentStaffRates,
      updateRatesOn,
      futureStaffRates,
      isHidden,
      // form state
      formDirty,
      formInitialised,
      formSubmitting,
      submitFailed,
      // func & callbacks
      onClose: props.onClose,
      onFieldValueSet,
      onFieldValueUpdated,
      onIsHiddenToggled,
      onSave,
      validateForm,
    };
  },
});

export const AddEditRateSetContainer = withApolloClient(withReduxProvider(composeHooks(hooks)(AddEditRateSet)));

AddEditRateSetContainer.displayName = 'AddEditRateSetContainer';

AddEditRateSetContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
  currentRateSet: PropTypes.object,
  rateSetId: PropTypes.string,
  rateSets: PropTypes.arrayOf(PropTypes.object).isRequired,
  staffMembers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      initials: PropTypes.string,
      isFormerStaff: PropTypes.bool,
      name: PropTypes.string,
      rate: PropTypes.number,
    }),
  ),
};

AddEditRateSetContainer.defaultProps = {
  rateSetId: undefined,
  currentRateSet: {},
  staffMembers: [],
};

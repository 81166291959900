import PropTypes from 'prop-types';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTrustLedgerReportDownload } from 'web/hooks';
import { featureActive } from '@sb-itops/feature';
import { getDebtorId as getMatterDebtorId } from '@sb-matter-management/redux/matters';
import { getByType as getBankAccountByType, getById as getBankAccountById } from '@sb-billing/redux/bank-account';
import { getMap as getAccountBalancesMap } from '@sb-billing/redux/bank-account-balances';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { withScopedFeatures } from '@sb-itops/redux/hofs';
import * as sortDirectionFeature from '@sb-itops/redux/sort';
import * as controlledMoneyAccountsFeature from 'web/redux/features/controlled-money-accounts';
import { getBankAccountBalanceById } from '@sb-billing/redux/bank-account-balances.2/selectors';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { DEPOSIT_FUNDS_MODAL_ID, VENDOR_PAYMENT_ENTRY_SPLIT_PAYORS_MODAL_ID } from 'web/react-redux';
import { getList as getTransactions } from '@sb-billing/redux/transactions';
import { bankAccountState as BANK_ACCOUNT_STATE } from '@sb-billing/business-logic/bank-account/entities/constants';

import { useTranslation } from '@sb-itops/react';
import { MatterControlledMoneyAccounts } from './MatterControlledMoneyAccounts';

function openTxnModal($uibModal, matterId) {
  return ({ rowData: txn, showHidden }) => {
    $uibModal.open({
      templateUrl: 'ng-components/bank-account-transaction-details/modal/bank-account-transaction-details-modal.html',
      controller: 'SbBankAccountTransactionDetailsModalController',
      size: 'lg',
      resolve: {
        transactionId: () => txn.id,
        transaction: null,
        matterId: () => matterId,
        contactId: () => undefined,
        showHidden: () => showHidden,
      },
      backdrop: 'static',
    });
  };
}

const findControlledMoneyAccounts = (matterId, showClosedAccounts) => {
  const controlledMoneyAccountsByMatter = getBankAccountByType('CONTROLLEDMONEY').filter(
    (acc) => acc.associatedMatterId === matterId && (showClosedAccounts ? true : acc.state === BANK_ACCOUNT_STATE.OPEN),
  );
  return controlledMoneyAccountsByMatter;
};

const findTransactions = ({ matterId, cmaId, showClosedAccounts }) => {
  const transactions = getTransactions().reduce((acc, transaction) => {
    const bankAccount = getBankAccountById(transaction.bankAccountId);

    const isControlledMoneyTransactionForMatter =
      transaction.matterId === matterId && bankAccount && bankAccount.id === cmaId;

    if (isControlledMoneyTransactionForMatter && (!transaction.isHidden || showClosedAccounts)) {
      acc.push({
        ...transaction,
        debit: transaction.amount < 0 ? transaction.amount : 0,
        credit: transaction.amount > 0 ? transaction.amount : 0,
      });
    }
    return acc;
  }, []);
  return transactions;
};

// we need separate features for Accounts and Transactions as the columns name differs
// and we wanna preserve order for both even when switching between all CMAs and specific CMA
const getControlledMoneyAccountsFeature = (state, matterId) => {
  const FEATURE_SCOPE = `controlled-money-accounts-${matterId}`;
  return withScopedFeatures({ state, scope: FEATURE_SCOPE })({
    sortDirection: sortDirectionFeature,
    filters: controlledMoneyAccountsFeature,
  });
};

const getControlledMoneyTransactionsFeature = (state, matterId) => {
  const FEATURE_SCOPE = `controlled-money-accounts-transactions-${matterId}`;
  return withScopedFeatures({ state, scope: FEATURE_SCOPE })({
    sortDirection: sortDirectionFeature,
  });
};

export const MatterControlledMoneyAccountsContainer = withReduxProvider((props) => {
  const { matterId, cmaId, onClickLink, $uibModal, sbAsyncOperationsService, sbReportingService } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { downloadReport, isDownloading } = useTrustLedgerReportDownload({
    sbReportingService,
    sbAsyncOperationsService,
    t,
  });

  const { sortDirection: sortAccounts, filters: cmaFilters } = useSelector((state) =>
    getControlledMoneyAccountsFeature(state, matterId),
  );
  const { sortDirection: sortTransactions } = useSelector((state) =>
    getControlledMoneyTransactionsFeature(state, matterId),
  );
  const sortAccountsBy = sortAccounts.selectors.getSortBy() || undefined;
  const sortAccountsDirection = sortAccounts.selectors.getSortDirection();
  const sortTransactionsBy = sortTransactions.selectors.getSortBy() || undefined;
  const sortTransactionsDirection = sortTransactions.selectors.getSortDirection();

  const showClosedAccounts = useSelector((state) => cmaFilters.selectors.getFilters(state).showHidden);

  let controlledMoneyAccountsByMatter = findControlledMoneyAccounts(matterId, showClosedAccounts);
  const getAccountBalances = (cmas) => {
    const accountBalanceMap = getAccountBalancesMap();
    if (!accountBalanceMap) {
      return cmas;
    }
    return cmas.map((acc) => ({
      ...acc,
      balance:
        getBankAccountBalanceById(accountBalanceMap, {
          bankAccountId: acc.id,
        })?.balance || 0,
    }));
  };

  controlledMoneyAccountsByMatter = getAccountBalances(controlledMoneyAccountsByMatter, matterId);

  const modal = openTxnModal($uibModal, matterId);

  const transactions = findTransactions({ matterId, showClosedAccounts, cmaId });
  const cma = getBankAccountById(cmaId);
  const isCmaInactive = cma?.state === BANK_ACCOUNT_STATE.CLOSED || false;
  const matterDebtorId = getMatterDebtorId(matterId);

  return (
    <MatterControlledMoneyAccounts
      {...{
        ...props,
        downloadReport,
        isDownloading,
        modal,
        matterId,
        cmaId,
        cmaAccountName: cma?.accountName,
        cmaBsb: cma?.branchNumber,
        cmaAccountNumber: cma?.accountNumber,
        isCmaInactive,
        controlledMoneyAccountsByMatter,
        sortAccountsBy,
        sortAccountsDirection,
        sortTransactionsBy,
        sortTransactionsDirection,
        showClosedAccounts,
        onClickLink,
        transactions,
        openDepositFundsModal: () => {
          setModalDialogVisible({
            modalId: DEPOSIT_FUNDS_MODAL_ID,
            props: { bankAccountId: cmaId, matterId, contactId: matterDebtorId },
          });
        },
        openVendorPaymentEntrySplitPayorsModal: () => {
          if (featureActive('BB-13254')) {
            // new react CMA payment modal
            setModalDialogVisible({
              modalId: VENDOR_PAYMENT_ENTRY_SPLIT_PAYORS_MODAL_ID,
              props: { bankAccountId: cmaId, matterId, contactId: matterDebtorId },
            });
          } else {
            // legacy angular CMA payment modal
            props?.paymentModal();
          }
        },
        onAccountsSort: ({ sortDirection, sortBy }) => {
          dispatch(sortAccounts.actions.setSortDirection({ sortDirection }));
          dispatch(sortAccounts.actions.setSortBy({ sortBy }));
        },
        onTransactionsSort: ({ sortDirection, sortBy }) => {
          dispatch(sortTransactions.actions.setSortDirection({ sortDirection }));
          dispatch(sortTransactions.actions.setSortBy({ sortBy }));
        },
        toggleClosedAccounts: (name, checked) => {
          dispatch(cmaFilters.actions.setFilter({ filterName: 'showHidden', filterValue: checked }));
        },
        addToVisibleClosedIdMap: ({ cmaId: accountId }) => {
          dispatch(cmaFilters.actions.addToVisibleClosedIdMap({ cmaId: accountId }));
        },
      }}
    />
  );
});

MatterControlledMoneyAccountsContainer.displayName = 'MatterControlledMoneyAccountsContainer';

MatterControlledMoneyAccountsContainer.propTypes = {
  matterId: PropTypes.string.isRequired,
  cmaId: PropTypes.string.isRequired,
  onClickLink: PropTypes.func.isRequired,
  depositFundsModal: PropTypes.func,
  paymentModal: PropTypes.func,
  sbReportingService: PropTypes.object,
  sbAsyncOperationsService: PropTypes.object,
  $uibModal: PropTypes.object,
};

MatterControlledMoneyAccountsContainer.defaultProps = {
  depositFundsModal: undefined,
  paymentModal: undefined,
  $uibModal: undefined,
  sbReportingService: undefined,
  sbAsyncOperationsService: undefined,
};

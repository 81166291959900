import PropTypes from 'prop-types';
import { useState } from 'react';
import composeHooks from '@sb-itops/react-hooks-compose';
import { getRawAmount as getRawExpenseAmount } from '@sb-billing/business-logic/expense/services';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { sort } from '@sb-itops/sort';
import { featureActive } from '@sb-itops/feature';
import { getMap as getExpenseMap } from '@sb-billing/redux/expenses';
import { getMatterDisplayById } from '@sb-matter-management/redux/matters';
import OperatingChequeExpenseTable from './OperatingChequeExpenseTable';

const hooks = ({ expenseIds, expenses }) => ({
  useOperatingChequeExpenseTable: () => {
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortBy, setSortBy] = useState('expenseDate');
    let expensesList;

    const onSort = (sortProps) => {
      setSortDirection(sortProps.sortDirection);
      setSortBy(sortProps.sortBy);
    };

    if (featureActive('BB-11425')) {
      expensesList = expenses.map((expense) => ({
        ...expense,
        amount: getRawExpenseAmount(expense),
        matterDisplay: getMatterDisplayById(expense.matter?.id),
      }));
    } else {
      const expenseMap = getExpenseMap();
      expensesList = expenseIds.map((expenseId) => {
        const expense = expenseMap[expenseId];
        return {
          ...expense,
          matterDisplay: getMatterDisplayById(expense.matterId),
        };
      });
    }

    return {
      expenses: sort(expensesList, [sortBy], [sortDirection]),
      sortDirection,
      sortBy,
      onSort,
    };
  },
});

const Container = withApolloClient(withReduxProvider(composeHooks(hooks)(OperatingChequeExpenseTable)));

Container.propTypes = {
  expenseIds: PropTypes.array,
  expenses: PropTypes.array,
};

Container.defaultProps = {
  expenseIds: [],
  expenses: [],
};

Container.displayName = `Container(${OperatingChequeExpenseTable.displayName})`;

export default Container;

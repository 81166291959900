/* eslint-disable react/static-property-placement */
import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tippy';

import 'react-tippy/dist/tippy.css';

// Implementation will change, interface should not.

class ContextMenu extends React.Component {
  static propTypes = {
    arrow: PropTypes.bool,
    body: PropTypes.func.isRequired,
    children: PropTypes.node,
    className: PropTypes.string,
    distance: PropTypes.number,
    tabIndex: PropTypes.number,
    position: PropTypes.string,
    render: PropTypes.func,
    showOnHover: PropTypes.bool,
    timeout: PropTypes.number,
  };

  static defaultProps = {
    arrow: true,
    children: undefined,
    className: undefined,
    distance: 20,
    position: 'left',
    render: undefined,
    showOnHover: false,
    tabIndex: undefined,
    timeout: 500,
  };

  // eslint-disable-next-line react/state-in-constructor
  state = {
    open: false,
    focus: false,
    timerId: undefined,
  };

  componentWillUnmount() {
    clearTimeout(this.state.timerId);
  }

  mouseEnter = () => {
    clearTimeout(this.state.timerId);
    this.setState((prevState) => ({ open: prevState.open || this.props.showOnHover, focus: true }));
  };

  mouseLeave = () => {
    clearTimeout(this.state.timerId);
    const timerId = setTimeout(() => {
      if (!this.state.focus) {
        this.setState({ open: false, focus: false });
      }
    }, this.props.timeout);

    this.setState((state) => ({
      ...state,
      focus: false,
      timerId,
    }));
  };

  keyUp = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.stopPropagation();
      this.setState({ open: true, focus: true });
    }
  };

  open = (e) => {
    e.stopPropagation();
    this.setState({ open: true, focus: true });
  };

  requestClose = () => {
    this.setState({ open: false, focus: false });
  };

  render() {
    const { children, body, tabIndex, position, distance, arrow, render, className } = this.props;
    const { open } = this.state;

    return (
      <Tooltip
        position={position}
        html={
          <div onMouseLeave={this.mouseLeave} onMouseEnter={this.mouseEnter}>
            {body({ close: this.requestClose })}
          </div>
        }
        onRequestClose={this.requestClose}
        open={open}
        interactive
        animateFill={false}
        distance={distance}
        theme="light"
        arrow={arrow}
        className={className}
      >
        <span
          onClick={this.open}
          onMouseLeave={this.mouseLeave}
          onMouseEnter={this.mouseEnter}
          onKeyUp={this.keyUp}
          onBlur={this.requestClose}
          role="button"
          tabIndex={tabIndex}
        >
          {render ? render({ open }) : children}
        </span>
      </Tooltip>
    );
  }
}

ContextMenu.displayName = 'ContextMenu';

export default ContextMenu;

import { featureActive } from '@sb-itops/feature';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { OPERATING_CHEQUE_DETAILS_MODAL_ID } from 'web/components';

angular.module('sb.billing.webapp').component('sbTransactionsOperatingCheques', {
  templateUrl: 'ng-routes/home/billing/accounts/transactions/operating-cheques/transactions-operating-cheques.html',
  controller: function($scope, $location, $uibModal, sbLinkService, sbAsyncOperationsService) {
    const ctrl = this;

    ctrl.printCheque = (chequeId) => sbAsyncOperationsService.startOperatingChequeCreation([chequeId]);
    ctrl.onClickLink = sbLinkService.onClickLink;
    ctrl.onClickRow = onClickRow;

    $scope.$on('$locationChangeSuccess', () => {
      ctrl.sbQuery = _.cloneDeep($location.search());
    });

    function onClickRow (e, chequeId) {
      if (featureActive('BB-11425')) {
        setModalDialogVisible({
          modalId: OPERATING_CHEQUE_DETAILS_MODAL_ID,
          props: {
            chequeId,
          },
        });
      } else {
        $uibModal.open({
          templateUrl: 'ng-components/operating-cheque-details/modal/operating-cheque-details-modal.html',
          controller: 'sbOperatingChequeDetailsModalController',
          size: 'lg',
          resolve: {
            chequeId: () => chequeId,
          },
          backdrop: 'static',
        });
      }
    }
  }
});
'use strict';

const { regionType, getRegion } = require('@sb-itops/region');
const { transactionType } = require('../entities/constants');

const { Deposit, InvoicePayment, MatterAdjustment, VendorPayment, BankFees, Interest } = transactionType;

const reversibleTypesByRegion = {
  [regionType.AU]: [VendorPayment, Deposit, InvoicePayment, BankFees, Interest],
  [regionType.GB]: [VendorPayment, Deposit, InvoicePayment, BankFees, Interest],
  [regionType.US]: [VendorPayment, Deposit, InvoicePayment, MatterAdjustment],
};

/**
 * @param {object} param
 * @param {{ type: string, reversed: boolean|undefined }} param.transaction
 * @param {Array<string>} [param.paymentIds]
 * @param {Array<string>} [param.reversibleTypes]
 * @param {function} param.isReversiblePaymentFn function that takes a paymentId and returns a boolean
 * @returns {boolean}
 */
const isReversible = ({ transaction, paymentIds = [], isReversiblePaymentFn, reversibleTypes }) => {
  const types = reversibleTypes || reversibleTypesByRegion[getRegion()];

  if (types && !Array.isArray(types)) {
    throw new Error(`reversibleTypes must be an array`);
  }

  if (types === undefined) {
    throw new Error(`Unsupported region: ${getRegion()}`);
  }

  if (!types.includes(transaction.type)) {
    return false;
  }

  if (transaction.type === InvoicePayment) {
    // For trust to office we combine transactions, each with a different paymentId
    // Even though the payments are linked via multiPaymentId and are created/reversed/deleted together
    // we check all the payments here to make sure
    if (paymentIds) {
      return paymentIds.every((paymentId) => isReversiblePaymentFn(paymentId));
    }

    return isReversiblePaymentFn(transaction.paymentId);
  }

  return !transaction.reversed;
};

module.exports = {
  isReversible,
  reversibleTypesByRegion,
};

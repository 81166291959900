import { useState, useCallback, useEffect } from 'react';
import composeHooks from '@sb-itops/react-hooks-compose';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { getLogger } from '@sb-itops/fe-logger';
import {
  subscriptionStatusByType,
  subscriptionIntervals,
} from '@sb-finance/business-logic/subscription/entities/constants';
import { error as displayErrorToUser, success as displaySuccessToUser } from '@sb-itops/message-display';
import { featureActive, featureData } from '@sb-itops/feature';
import { getAccountId, getProductTier, refreshTokenP, getIsFirmOwner } from 'web/services/user-session-management';
import {
  fetchSmokeballSubscriptionRecord,
  createSetupIntent as fetchTierInformation,
  changeProductTier,
} from 'web/services/subscription';
import { ManageSubscriptionRoute } from './ManageSubscriptionRoute';

// #manage-subscription route is special as it can execute by bypassing post-authentication steps.
// This is to allow users with a valid login but invalid subscription to manage their subscription / update payment details.
const log = getLogger('ManageSubscriptionRoute.container');

const updateProductTier = async ({ smokeballProductId, externalProductId, externalSubscriptionId, accountId }) => {
  await changeProductTier({ externalProductId, externalSubscriptionId, accountId });

  // Let the backend know to change the firm's product tier.
  await dispatchCommand({
    type: 'ProductRegistration.Commands.SetProductIdForFirmProductAccount',
    message: { productTierId: smokeballProductId },
  });

  // Otherwise it means the user already has an active subscription and is just switching tiers.
  // TODO: There is a better way to do this.
  await new Promise((resolve) =>
    // eslint-disable-next-line no-promise-executor-return
    setTimeout(async () => {
      await refreshTokenP();
      resolve();
    }, 2500),
  );
};

const hooks = () => ({
  useNonFirmOwnerView: ({ onClickLink }) => ({
    isCurentUserFirmOwner: getIsFirmOwner(),
    onRedirectNonFirmOwner: () => onClickLink({ type: 'dashboard' }),
  }),
  usePaymentManagement: ({ onClickLink }) => {
    const subPages = featureActive('BB-13250')
      ? {
          tiers: 'TIERS',
          payments: 'PAYMENTS',
        }
      : { tiers: 'PAYMENTS' };

    const [managePaymentMethodRequestParams, setManagePaymentMethodRequestParams] = useState();

    // The current sub page being displayed on the route, either the tier selection or payment entry component.
    const [currentSubPage, setCurrentSubPage] = useState(
      featureActive('BB-13250') ? subPages.tiers : subPages.payments,
    );

    return {
      subPages,
      currentSubPage,
      managePaymentMethodRequestParams,
      onManagePaymentMethod: (params) => {
        setManagePaymentMethodRequestParams(params);
        setCurrentSubPage(subPages.payments);
      },
      onPaymentMethodUpdated: async () => {
        // Perform tier update if required.
        if (managePaymentMethodRequestParams?.requiresTierUpdate) {
          try {
            managePaymentMethodRequestParams?.setUpdatingToSmokeballProductId(
              managePaymentMethodRequestParams.smokeballProductId,
            );
            await updateProductTier(managePaymentMethodRequestParams);
          } catch (err) {
            log.error('Failed to change tier', err);
            displayErrorToUser(
              'Unfortunately we could not update your subscription at this time. Please contact Smokeball support for assistance.',
            );
          } finally {
            managePaymentMethodRequestParams?.setUpdatingToSmokeballProductId('');
          }
        }

        displaySuccessToUser('Your subscription has been updated');
        if (featureActive('BB-13250')) {
          setCurrentSubPage(subPages.tiers);
        } else {
          onClickLink({ type: 'dashboard' });
        }
      },
      onPaymentMethodUpdateAbort: () =>
        featureActive('BB-13250') ? setCurrentSubPage(subPages.tiers) : onClickLink({ type: 'dashboard' }),
    };
  },
  useSubscriptionFrequency: () => {
    const [subscriptionFrequency, setSubscriptionFrequency] = useState(subscriptionIntervals.YEARLY);
    return {
      subscriptionFrequency,
      onMonthlySubscriptionSelected: () => setSubscriptionFrequency(subscriptionIntervals.MONTHLY),
      onYearlySubscriptionSelected: () => setSubscriptionFrequency(subscriptionIntervals.YEARLY),
    };
  },
  useDynamicProductDataFromLaunchDarkly: () => ({
    tierMarketingInfo: featureData('BB-13460'),
  }),
  useSubscriptionManagement: () => {
    const [subscriptionRecord, setSubscriptionRecord] = useState(undefined);
    const [freeTiersOnCoupon, setFreeTiersOnCoupon] = useState({});
    const [couponFreeDescription, setCouponFreeDescription] = useState('');
    const [showConfirmCancelSubscription, setShowConfirmCancelSubscription] = useState(false);
    const [cancelSubscriptionInProgress, setCancelSubscriptionInProgress] = useState(false);

    const onCancelSubscription = async () => {
      try {
        setCancelSubscriptionInProgress(true);
        await dispatchCommand({ type: 'Finance.Subscriptions.Messages.Commands.CancelSubscription', message: {} });
        setSubscriptionRecord({ ...subscriptionRecord, cancelAtPeriodEnd: true });
        displaySuccessToUser('Your subscription will expire at the end of your billing period');
      } catch (err) {
        log.error('Failed to cancel subscription', err);
        displayErrorToUser('Failed to cancel subscription, please contact Smokeball support');
      } finally {
        setCancelSubscriptionInProgress(false);
        setShowConfirmCancelSubscription(false);
      }
    };

    const downloadSubscriptionData = useCallback(async () => {
      const downloadedSubscriptionRecord = await fetchSmokeballSubscriptionRecord({ accountId: getAccountId() });
      const couponData = (downloadedSubscriptionRecord?.externalCoupon?.metadata || []).reduce(
        (acc, metadataItem) => {
          if (metadataItem.key === 'couponFreeDescription') {
            acc.couponFreeDescription = metadataItem.value;
            return acc;
          }

          if (metadataItem.key === 'freeTiers') {
            metadataItem.value.split(',').forEach((freeTier) => {
              acc.freeCouponTiers[freeTier] = true;
            });
          }

          return acc;
        },
        {
          freeCouponTiers: {},
        },
      );

      setSubscriptionRecord(downloadedSubscriptionRecord);
      setCouponFreeDescription(couponData.couponFreeDescription || 'Free with your Bar Association Membership');
      setFreeTiersOnCoupon(couponData.freeCouponTiers);
    }, []);

    useEffect(() => {
      downloadSubscriptionData();
    }, [downloadSubscriptionData]);

    return {
      subscriptionRecord,
      freeTiersOnCoupon,
      couponFreeDescription,
      currentTier: getProductTier(),
      showConfirmCancelSubscription,
      onCancelSubscriptionAbort: () => setShowConfirmCancelSubscription(false),
      onCancelSubscriptionRequested: () => setShowConfirmCancelSubscription(true),
      cancelSubscriptionInProgress,
      onCancelSubscription,
    };
  },
  useTierManagement: () => {
    const [updatingToSmokeballProductId, setUpdatingToSmokeballProductId] = useState('');
    const [isUpgradeFromFreeTier, setIsUpgradeFromFreeTier] = useState(false);

    const onTierSwitch = async ({
      externalProductId,
      externalSubscriptionId,
      accountId,
      smokeballProductId,
      subscriptionRecord,
      freeTiersOnCoupon,
      onManagePaymentMethod,
      onCloseTierSwitchModal,
    }) => {
      try {
        setUpdatingToSmokeballProductId(smokeballProductId);
        setIsUpgradeFromFreeTier(
          getProductTier() === 'SMK001' && freeTiersOnCoupon.SMK001 && smokeballProductId === 'SMK004',
        );

        onCloseTierSwitchModal();

        // If the user is past due on their subscription, or if they are yet to enter credit card details and are not in trial,
        // we need the user to first update their credit card information. After updating their credit card info, we will update the tier
        // in onManagePaymentMethod().
        if (
          (subscriptionRecord.status === subscriptionStatusByType.PAST_DUE ||
            subscriptionRecord.externalPaymentMethodIds.length === 0) &&
          !freeTiersOnCoupon?.[smokeballProductId] &&
          subscriptionRecord.status !== subscriptionStatusByType.TRIALING
        ) {
          onManagePaymentMethod({
            requiresTierUpdate: true,
            smokeballProductId,
            externalProductId,
            externalSubscriptionId,
            accountId,
            setUpdatingToSmokeballProductId,
          });
          return;
        }

        // Credit card is already on record, we can simply update the product tier without collecting card info.
        await updateProductTier({
          smokeballProductId,
          externalProductId,
          externalSubscriptionId,
          accountId,
        });
        displaySuccessToUser('Your subscription has been updated');
      } catch (err) {
        log.error('Failed to change tier', err);
        displayErrorToUser(
          'Unfortunately we could not update your subscription at this time. Please contact Smokeball support for assistance.',
        );
      } finally {
        setUpdatingToSmokeballProductId('');
      }
    };

    return {
      updatingToSmokeballProductId,
      isUpgradeFromFreeTier,
      onTierSwitch,
    };
  },
  useFinanceTierPricingData: () => {
    const [tierPricingInfo, setTierPricingInfo] = useState();
    const [setupIntentError, setSetupIntentError] = useState(false);

    const downloadTierInformation = useCallback(async () => {
      const accountId = getAccountId();
      try {
        const { tiers } = await fetchTierInformation({
          accountId,
        });

        const tierPricingInformation = Object.values(tiers).reduce(
          (acc, tier) => {
            const smokeballProductId = tier.smokeballProductId;
            const monthlyInfo = tier.prices.find((price) => price.interval === 'month');
            const yearlyInfo = tier.prices.find((price) => price.interval === 'year');

            // We normalise prices to monthly interval amounts so that users can compare more
            // easily between different payment options.
            const yearlyPerMonthPrice = Math.round(yearlyInfo.price / 12);

            acc[subscriptionIntervals.MONTHLY][smokeballProductId] = {
              ...monthlyInfo,
              normalisedPrice: monthlyInfo.price,
              smokeballProductId,
            };
            acc[subscriptionIntervals.YEARLY][smokeballProductId] = {
              ...yearlyInfo,
              normalisedPrice: yearlyPerMonthPrice,
              smokeballProductId,
            };

            return acc;
          },
          {
            [subscriptionIntervals.MONTHLY]: {},
            [subscriptionIntervals.YEARLY]: {},
          },
        );

        setTierPricingInfo(tierPricingInformation);
      } catch (e) {
        setSetupIntentError(true);
      }
    }, []);

    useEffect(() => {
      downloadTierInformation();
    }, [downloadTierInformation]);

    return {
      tierPricingInfo,
      setupIntentError,
    };
  },
  useSubscriptionActionConfirmations: () => {
    const [tierSwitchRequestData, setTierSwitchRequestData] = useState(undefined);
    const [showTierSwitchConfirmModal, setShowTierSwitchConfirmModal] = useState(false);

    return {
      showTierSwitchConfirmModal,
      tierSwitchRequestData,
      onCloseTierSwitchModal: () => setShowTierSwitchConfirmModal(false),
      onTierSwitchRequested: (switchRequest) => {
        setTierSwitchRequestData(switchRequest);
        setShowTierSwitchConfirmModal(true);
      },
    };
  },
  useOnForceAppReload: () => ({
    onSubscriptionManagementComplete: () => {
      // TODO: Only do this if the route was loaded without post authentication having executed.
      const urlParams = new URLSearchParams(window.location.search.replace('/', ''));
      // setting url param which forces a browser refresh. This will trigger post authentication
      // to run if this route was loaded as part of a cancelled subscription
      // bootstrap process can complete itself, e.g. loading all caches, launch darkly
      urlParams.append('subscription-updated', 1);
      window.location.href = `?${urlParams.toString()}/#/billing/matters`;
    },
  }),
});

export const ManageSubscriptionRouteContainer = composeHooks(hooks)(ManageSubscriptionRoute);

ManageSubscriptionRouteContainer.displayName = 'ManageSubscriptionRouteContainer';

ManageSubscriptionRouteContainer.propTypes = {};

ManageSubscriptionRouteContainer.defaultProps = {};

'use strict';

import { store } from '@sb-itops/redux';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import {
  updateCache as updateRedux,
  getList,
}  from '@sb-billing/redux/invoice-settings';
import { constants as invoiceSettingsConstants } from '@sb-billing/business-logic/invoice-settings';
import { featureActive } from '@sb-itops/feature';

const getAccountId = () => authSelectors.getAccountId(store.getState());

angular.module('@sb-billing/services').service('sbInvoiceSettingsService', function (sbLoggerService, $window, sbGenericCacheService,
  sbGenericEndpointService, sbEndpointType, sbUuidService,
  sbFirmManagementMbService) {
  const that = this;
  const endpoint = 'billing/invoice/settings';
  const log = sbLoggerService.getLogger('sbInvoiceSettingsService');
  sbGenericCacheService.setupCache({
    name: 'sbInvoiceSettingsService',
    sync: {
      endpoint: {type: sbEndpointType.SYNC_ALL, stub: endpoint},
      poll: 60,
      subscriptions: 'notifier.InvoicingNotifications.InvoiceSettingsUpdated'
    },
    updateRedux
  });

  that.get = get;
  that.saveP = saveP;

  /*
   We noticed that encoding and decoding '> ' or ' <' caused strange results.
   These functions fix The "Unicode Problem". See here : https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#The_.22Unicode_Problem.22
   Could also use https://github.com/coolaj86/unibabel-js
   */
  function b64EncodeUnicode(str) {
    return $window.btoa($window.encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode('0x' + p1)));
  }

  function b64DecodeUnicode(str) {
    return $window.decodeURIComponent(Array.prototype.map.call($window.atob(str), (c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));
  }

  function doEncode(data) {
    try {
      //if decode succeeds then data is already encoded
      return b64EncodeUnicode(data);
    } catch (err) {
      //only encode if not already encoded
      return $window.btoa(data);
    }
  }

  function doDecode(data) {
    try {
      return b64DecodeUnicode(data);
    } catch (err) {
      return data;
    }
  }

  function encodeHtml(entity) {
    if (!_.isEmpty(entity.letterhead)) {
      entity.letterhead = doEncode(entity.letterhead);
    }
    if (!_.isEmpty(entity.footer)) {
      entity.footer = doEncode(entity.footer);
    }
    if (!_.isEmpty(entity.statementFooter)) {
      entity.statementFooter = doEncode(entity.statementFooter);
    }
  }

  function decodeHtml(entity) {
    if (entity) {
      if (!_.isEmpty(entity.letterhead)) {
        entity.letterhead = doDecode(entity.letterhead);
      }
      if (!_.isEmpty(entity.footer)) {
        entity.footer = doDecode(entity.footer);
      }
      if (!_.isEmpty(entity.statementFooter)) {
        entity.statementFooter = doDecode(entity.statementFooter);
      }
    }
  }

  function get() {
    const cached = _.get(getList(), '[0]'); // Will only ever be 1 record in this cache
    if (!cached) {
      createDefaultSettingsP(); // will broadcast event so retry will pick-up result
    } else {
      decodeHtml(cached);
      return cached;
    }
  }

  function stringsToEnums(data) {
    if (!_.isEmpty(data) && !_.isEmpty(data.titleLine1Option)) {
      data.titleLine1Option = invoiceSettingsConstants.titleLine1TypeById[data.titleLine1Option];
      data.details = 'details'; // dummy key
    }
    if (!_.isEmpty(data) && !_.isEmpty(data.titleLine2Option)) {
      data.titleLine2Option = invoiceSettingsConstants.titleLine2TypeById[data.titleLine2Option];
      data.details = 'details'; // dummy key
    }
    return data;
  }

  function saveP(data) {
    data.versionId = sbUuidService.get();
    data.accountId = getAccountId();
    encodeHtml(data);
    const change = JSON.parse(JSON.stringify(data));
    stringsToEnums(data);

    return sbGenericEndpointService.postPayloadP(endpoint, undefined, data, 'POST', { changeset: { sbInvoiceSettingsService: [change]}});
  }

  function createDefaultSettingsP() {
    const footer = '<p></p>';
    const font = 'Lato';
    const settings = {
      letterhead: getFirmAddress(),
      headingFont: font,
      bodyFont: font,
      letterHeadLayout: 'lh-justified-left',
      paymentDueDays: 7,
      initialInvoiceNumber: 0,
      footer,
      defaultLayout: {
        expenseLineItemConfiguration: 0,
        feeLineItemConfiguration: 0,
        showStaffNameOnEntries: false,
        feeSummaryLineDescription: null,
        expenseSummaryLineDescription: null,
      },
      invoiceAdditionalOptions: {
        showInvoiceSummary: false,
        showAccountSummary: false,
        showTransactionHistory: false,
        hidePriorBalance: false,
        hidePaymentSummary: false,
        showSummaryForTimekeepers: false,
        showTimekeeperRole: false,
        showHoursSummary: false,
        hideFeeTotal: false,
        hideFeeTotalSummary: false,
        hideDueDate: false,
        showLessFundsInTrust : false,
        appendExpenseReceipts: false,
        showItemNumbers: featureActive('BB-12394') ? false : undefined,
      },
      eInvoiceOptions: featureActive('BB-5725') && featureActive('BB-6865')
        ? {
          enableDescriptionOnDemand: true,
          descriptionOnDemandLineItemText: '',
        }
        : undefined,
      statementFooter: footer,
      statementAdditionalOptions: {
        showInvoiceSummary: false,
        showAccountSummary: false,
        showTransactionHistory: false,
      },
      titleLine2Option: 'None',
      titleLine2CustomText: '' ,
      titleLine1Option: 'MatterDescription',
      titleLine1CustomText: '',
      signatureName: ''
    };

    log.info('about to save default invoice settings', settings);

    return saveP(settings);
  }

  function getFirmAddress() {
    const address = sbFirmManagementMbService.getFirmBusinessAddress(); // sync service, should be loaded at login
    const properties = [
      'AddressLine1',
      'AddressLine2',
      'Locality',
      'City',
      'State',
      'ZipCode',
      'County',
      'Country'
    ];
    let displayAddress;

    if (address) {
      displayAddress = _.reduce(properties, (displayAddress, prop) => address[prop] ? `<div>${address[prop]}</div>` : displayAddress, '');
    }

    return displayAddress ? `<p>${displayAddress}</p>` : '';
  }

});

import { getById as getExpenseById } from '@sb-billing/redux/expenses';

angular.module('sb.billing.webapp').controller('sbViewBillEntriesController', function ($stateParams, $uibModal, sbLinkService) {
  'use strict';

  var ctrl = this;

  ctrl.invoiceId = $stateParams.invoiceId;
  ctrl.onClickLink = sbLinkService.onClickLink;
  ctrl.onOpenExpenseModal = onOpenExpenseModal;

  function onOpenExpenseModal({ expenseId, invoiceNumber }) {
    const legacyExpense = getExpenseById(expenseId);

    const preFillData = {
      ...legacyExpense,
      activityCode: legacyExpense.expenseActivityId,
      billable: !!legacyExpense.isBillable,
      date: legacyExpense.expenseDate,
      invoiceNumber,
      matter: legacyExpense.matterId,
      staffId: legacyExpense.expenseEarnerStaffId,
      waived: !!legacyExpense.waived,
      // defined as 'units' for legacy purposes (expense-entry-controller),
      // should be changed to 'quantity' when refactoring the expense modal
      units: legacyExpense.quantity / 100,  // TODO: decimal-operation
      // defined as 'rate' for legacy purposes (expense-entry-controller),
      // should be changed to 'price' when refactoring the expense modal
      rate: legacyExpense.price / 100, // TODO: decimal-operation
    };

    openLegacyExpenseModal({
      preFillData,
      features: {
        update: true,
        useCommandDispatcher: true,
      }
    });
  }

  function openLegacyExpenseModal({ preFillData, features }) {
    $uibModal.open({
      templateUrl: 'ng-components/expense-entry/modal/expense-modal.html',
      controller: 'SbExpenseModalController',
      resolve: {
        sbExpenseModalConfig: function () {
          return {
            preFill: preFillData,
            features: features
          };
        }
      },
      backdrop: 'static',
    });
  }
});

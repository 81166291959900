import PropTypes from 'prop-types';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import * as messageDisplay from '@sb-itops/message-display';
import { setModalDialogVisible, setModalDialogHidden } from '@sb-itops/redux/modal-dialog';
import { paymentPlanStatuses } from '@sb-billing/business-logic/payment-plan/entities/constants';
import { findPaymentPlansByDebtorId } from '@sb-billing/redux/payment-plans/selectors';
import {
  getList as getPaymentPlansList,
  getById as getPaymentPlanById,
  closePaymentPlanP,
} from '@sb-billing/redux/payment-plans';
import { debugAutoPayment } from '@sb-billing/redux/payment-provider';
import { getPersonByUserId } from '@sb-firm-management/redux/firm-management';
import { findMatterIdsWithUnpaidBalanceForDebtor } from 'web/redux/selectors/payment-plans';
import { getMap as getAllInvoicesMap } from '@sb-billing/redux/invoices';
import { getList as getAllInvoiceTotals } from '@sb-billing/redux/invoice-totals';
import { useReduxActionOnce } from 'web/hooks';
import { useDispatch, useSelector } from 'react-redux';
import composeHooks from '@sb-itops/react-hooks-compose';
import { getList as getPaymentPlanEventsList } from '@sb-billing/redux/payment-plan-events';
import { getById as getMatterById } from '@sb-matter-management/redux/matters';
import { getTypeOrBaseTypeById as getMatterTypeById } from '@sb-matter-types/redux/matter-types';
import { selectors as supportDebugSelectors } from 'web/redux/route/billing-support-debug';
import { getAppEnv, envType } from '@sb-itops/app-env';
import { featureActive } from '@sb-itops/feature';
import { ADD_PAYMENT_MODAL_ID as ADD_PAYMENT_NEW_MODAL_ID } from 'web/components';
import ContactPaymentPlan from './ContactPaymentPlan';
import { contactPaymentPlanFeature } from './contact-payment-plan-feature';
import { ADD_PAYMENT_MODAL_ID } from '../../components/payment-add-modal';

const tabTypes = {
  CONTACT: 'contact',
};

const addEditModalId = 'add-edit-payment-plan';
const closePaymentPlanModalId = 'close-payment-plan-confirmation';

const onShowModal = ({ modalId }) => setModalDialogVisible({ modalId });
const onCloseModal = ({ modalId }) => setModalDialogHidden({ modalId });

const hooks = ({ contactId, onClickLink }) => ({
  useState: () => {
    const dispatch = useDispatch();

    // get the payment plan redux feature
    const {
      sort: {
        selectors: { getSortDirection },
        actions: { reset: resetSortDirection, setSortDirection },
      },
      expandableLists: {
        selectors: { getExpandedItems },
        actions: { reset: resetExpandCollapse, toggleItem },
      },
      selectedPaymentPlan: {
        selectors: { getSelectedPaymentPlan },
        actions: { clearSelectedPaymentPlan, setSelectedPaymentPlan },
      },
    } = useSelector(contactPaymentPlanFeature)({ contactId });

    // Clear store if tab closed
    useReduxActionOnce('smokeball-tab-closed', ([{ type, contactId: closedContactId }]) => {
      // Check if the tab containing this component is being closed.
      if (type === tabTypes.CONTACT && closedContactId === contactId) {
        // clear payment plan redux state only when contact tab is closed
        dispatch(resetSortDirection());
        dispatch(resetExpandCollapse());
        dispatch(clearSelectedPaymentPlan());
      }
    });

    const paymentPlanResult = findPaymentPlansByDebtorId(getPaymentPlansList(), { debtorId: contactId });

    const { activePaymentPlan, pastPaymentPlans } = paymentPlanResult.reduce(
      (acc, pp) => {
        if (pp.status === paymentPlanStatuses.ACTIVE) {
          acc.activePaymentPlan = pp;
        }
        if (pp.status === paymentPlanStatuses.CLOSED) {
          acc.pastPaymentPlans.push(pp);
        }
        return acc;
      },
      { activePaymentPlan: {}, pastPaymentPlans: [] },
    );

    const selectedPaymentPlanId = getSelectedPaymentPlan();
    const currentPaymentPlan = selectedPaymentPlanId ? getPaymentPlanById(selectedPaymentPlanId) : activePaymentPlan;
    const { id, startDate, userId, installments, status, lastUpdated, $optimistic } = currentPaymentPlan;

    const staff = getPersonByUserId(userId);
    const lastInstallment = installments && installments[installments.length - 1];

    const matterIdsWithUnpaidBalanceForDebtor = findMatterIdsWithUnpaidBalanceForDebtor(
      {
        allInvoicesMap: getAllInvoicesMap(),
        allInvoiceTotals: getAllInvoiceTotals(),
      },
      {
        debtorId: contactId,
      },
    );

    const matterIdsNotIncludedInPaymentPlan = matterIdsWithUnpaidBalanceForDebtor.filter((matterId) =>
      activePaymentPlan.matterIds ? !activePaymentPlan.matterIds.includes(matterId) : true,
    );

    const isContactOwingMoney = matterIdsWithUnpaidBalanceForDebtor.length > 0;
    const paymentsTableSortDirection = getSortDirection({ defaultSortDirection: 'asc' });

    const onClosePaymentPlan = async () => {
      try {
        await closePaymentPlanP(id);
        messageDisplay.success('Payment plan was closed successfully.');
      } catch (ex) {
        messageDisplay.error('Failed to close payment plan.');
      } finally {
        onCloseModal({ modalId: closePaymentPlanModalId });
      }
    };

    const mattersIncludedInPaymentPlan = (currentPaymentPlan.matterIds || []).map((matterId) => {
      const matter = getMatterById(matterId);
      return {
        ...matter,
        matterType: getMatterTypeById(matter.matterTypeId),
      };
    });

    const mattersNotIncludedInPaymentPlan = matterIdsNotIncludedInPaymentPlan.map((matterId) => {
      const matter = getMatterById(matterId);
      return {
        ...matter,
        matterType: getMatterTypeById(matter.matterTypeId),
      };
    });

    const showDebug = useSelector(supportDebugSelectors.getShowDebug) && envType.PRODCTION !== getAppEnv();

    const onAddPayment = (cId) => {
      if (featureActive('BB-13936')) {
        setModalDialogVisible({
          modalId: ADD_PAYMENT_NEW_MODAL_ID,
          props: { scope: 'ContactPaymentPlan/add-payment-modal', contactId: cId },
        });
      } else {
        // Non-LOD version
        setModalDialogVisible({ modalId: ADD_PAYMENT_MODAL_ID, props: { contactId: cId } });
      }
    };

    return {
      showDebug,
      triggerAutoCharge: () => debugAutoPayment(activePaymentPlan.accountId, activePaymentPlan.id),
      // when there is an optimistic update we need to wait for the notification to remove the optimistic flag.
      isLoading: !!$optimistic,
      paymentPlanId: id,
      paymentPlan: currentPaymentPlan,
      paymentPlanEvents: getPaymentPlanEventsList(),
      paymentPlanModalId: addEditModalId,
      onClickLink,
      showRightPanel: !!id,
      onAddPayment,
      onClosePaymentPlan,
      startDate,
      lastUpdatedOn: lastUpdated,
      lastUpdatedBy: staff && staff.name,
      lastInstallmentDate: lastInstallment && lastInstallment.date,
      isCurrentPaymentPlanActive: status === paymentPlanStatuses.ACTIVE,
      isContactOwingMoney,
      paymentsTableSortDirection,
      mattersIncludedInPaymentPlan,
      mattersNotIncludedInPaymentPlan,
      activePaymentPlanId: activePaymentPlan.id,
      expandedLists: getExpandedItems(),
      pastPaymentPlans,
      closePaymentPlanModalId,
      onCreateNewPaymentPlan: () => onShowModal({ modalId: addEditModalId }),
      onEditPaymentPlan: () => onShowModal({ modalId: addEditModalId }),
      onShowClosePaymentPlanConfirmation: () => onShowModal({ modalId: closePaymentPlanModalId }),
      onNewPaymentPlanCreated: () => {
        dispatch(clearSelectedPaymentPlan());
      },
      onPaymentsTableSortDirectionChange: (direction) => {
        dispatch(setSortDirection({ sortDirection: direction }));
      },
      onFilterChanged: (paymentPlanId) => {
        dispatch(setSelectedPaymentPlan({ paymentPlanId }));
      },
      onToggleClick: (listName) => {
        dispatch(toggleItem({ id: listName }));
      },
    };
  },
});

const ContactPaymentPlanContainerWithReduxEntities = withReduxProvider(composeHooks(hooks)(ContactPaymentPlan));

ContactPaymentPlanContainerWithReduxEntities.displayName = 'ContactPaymentPlanContainerWithReduxEntities';

ContactPaymentPlanContainerWithReduxEntities.propTypes = {
  contactId: PropTypes.string.isRequired,
  onClickLink: PropTypes.func.isRequired,
};

ContactPaymentPlanContainerWithReduxEntities.defaultProps = {
  printCheques: () => {},
};

export default ContactPaymentPlanContainerWithReduxEntities;

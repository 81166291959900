import { print as printGqlString } from 'graphql/language/printer'

import { featureActive } from '@sb-itops/feature';
import { warn as displayWarningToUser } from '@sb-itops/message-display';
import { store } from '@sb-itops/redux';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { hasFacet, facets } from '@sb-itops/region-facets';

import { billingBulkActionTypes } from '@sb-billing/business-logic/billing-bulk-actions';
import * as warningMessages from '@sb-billing/business-logic/invoice/entities/warning-messages';
import { getCurrentConfigurationByMatterId, getById as getBillingConfigById } from '@sb-billing/redux/billing-configuration';
import { hasPendinginvoices } from '@sb-billing/redux/invoices';
import { getTotalsCents } from '@sb-billing/redux/invoice-totals';
import { getById as getExpensePaymentDetailsById } from '@sb-billing/redux/expense-payment-details';
import { getById as getExpenseById } from '@sb-billing/redux/expenses';
import { hasUnpaidAnticipatedDisbursements } from '@sb-billing/business-logic/invoice/services';
import { isUtbmsEnabled } from '@sb-billing/redux/utbms-settings';
import { INVOICE_COMMUNICATE_MODAL_ID, INVOICE_EMAIL_MODAL_ID, ADD_PAYMENT_MODAL_ID } from 'web/components';
import { BillingBulkActions } from 'web/graphql/queries';
import * as viewContactBillsFilters from 'web/redux/route/home-billing-view-contact-bills';
import { fetchGraphqlData } from 'web/services/network';
import { subscribeToNotifications } from 'web/services/subscription-manager';

import { createInvoiceFilters } from '../../bills/list/create-invoice-filters';
import { loadInvoiceListData } from '../../bills/list/load-invoice-list-data';
import { sortInvoices } from '../../bills/list/sort-invoices';

angular
  .module('sb.billing.webapp')
  .controller('ViewContactBillsController', function (
    $scope,
    sbUnsavedChangesService,
    sbSaveInvoiceCommand,
    sbAsyncOperationsService,
    sbInvoiceSendService,
    sbMessageDisplayService,
    sbLocalisationService,
    sbLinkService,
    sbLoggerService,
    $stateParams,
  ) {
    const ctrl = this;
    const log = sbLoggerService.getLogger('ViewContactBillsController');
    const contactId = $stateParams.contactId;
    const SAVED_DATA_KEY = `ContactBillsController-filters-${contactId}`;

    ctrl.contactId = contactId
    ctrl.sbAsyncOperationsService = sbAsyncOperationsService;
    ctrl.sbSaveInvoiceCommand = sbSaveInvoiceCommand;
    ctrl.sbInvoiceSendService = sbInvoiceSendService;
    ctrl.isDownloadLedesInProgress = isDownloadLedesInProgress;

    // Command handlers.
    ctrl.onOpenSendEmailsModal = onOpenSendEmailsModal;
    ctrl.onOpenSendCommunicatesModal = onOpenSendCommunicatesModal;
    ctrl.onOpenMarkAsSentModal = onOpenMarkAsSentModal;
    ctrl.onCloseSendEmailsModal = onCloseSendEmailsModal;
    ctrl.onCloseSendCommunicatesModal = onCloseSendCommunicatesModal;
    ctrl.onCloseMarkAsSentModal = onCloseMarkAsSentModal;
    ctrl.onSendEmails = onSendEmails;
    ctrl.onSendCommunicates = onSendCommunicates;
    ctrl.onSendEmailFromIndicatorIcon = onSendEmailFromIndicatorIcon;
    ctrl.onSendCommunicateFromIndicatorIcon = onSendCommunicateFromIndicatorIcon;
    ctrl.onMarkAsSentFromIndicatorIcon = onMarkAsSentFromIndicatorIcon;
    ctrl.onCombineInPdf = onCombineInPdf;
    ctrl.onCombineInPdfWithCover = onCombineInPdfWithCover;
    ctrl.onDownloadLedes = onDownloadLedes;
    ctrl.onBulkFinalise = onBulkFinalise;
    ctrl.onOpenModal = onOpenModal;
    ctrl.closeModal = closeModal;
    ctrl.setModalDialogVisible = setModalDialogVisible;
    ctrl.onOpenAddPaymentModal = onOpenAddPaymentModal;

    // Finalize invoice with zero balance or/and with unpaid AD
    ctrl.onInvoiceProceedConfirm = onInvoiceProceedConfirm;
    ctrl.onInvoiceProceedCancel = onInvoiceProceedCancel;

    // List callbacks.
    ctrl.onSelectInvoice = onSelectInvoice;
    ctrl.onSortInvoices = onSortInvoices;
    ctrl.onSelectAllInvoices = onSelectAllInvoices;
    ctrl.onClickLink = sbLinkService.onClickLink;

    // Bulk action handling
    ctrl.isBulkCreateInvoicesInProgress = false;
    ctrl.unsubscribeToBulkActionNotifications = undefined;  

    // Misc.
    ctrl.toggleHideFilters = toggleHideFilters;
    ctrl.isCombineInProgress = isCombineInProgress;
    ctrl.showDownloadLedesButton = showDownloadLedesButton;

    // Initialise controller.
    ctrl.supportsUtbms = hasFacet(facets.utbms);
    ctrl.t = sbLocalisationService.t;
    ctrl.personResponsible = ctrl.t('capitalizeAll', { val: 'personResponsible' });
    ctrl.finalise = ctrl.t('capitalize', { val: 'finalise' });
    ctrl.invoiceIdsForEmailToSend = [];

    loadViewState();
    updateInvoiceListData();
    checkBulkCreateInvoicesInProgress();
    subscribeToBulkActionNotifications();  

    // All of our data comes from redux, until all of this logic gets moved to react, we will need to manually subscribe to changes and update data.
    const unsubscribeFromStore = store.subscribe(_.debounce(() => {
      updateInvoiceListData();
      updateInvoiceActionAvailability();
      $scope.$applyAsync();
    }, 100));

    // Prepares all of the "view" state.
    // The view state manages selected invoices, sort orderings, filter values and other UI related state.
    function loadViewState() {
      const defaultViewState = {
        selectedInvoices: {},
        contactId,
        invoiceActions: {
          combineInPdf: new Set(),
          combineInPdfWithCoverLetter: new Set(),
          downloadLedes: new Set(),
          email: new Set(),
          markAsSent: new Set(),
          finalise: new Set(),
          delete: new Set(),
        },
        showEmailModal: false,
        showCommunicateModal: false,
        showMarkAsSentModal: false,
        showInvoiceConfirmProceedModal: false,
        hasZeroBalance: false,
        hasUnpaidAD: false,
        zeroBalanceInvoiceCount: 1,
        sorting: {
          column: 'invoiceNumber',
          direction: 'desc',
        },
      };

      store.dispatch(viewContactBillsFilters.actions.setContactId(contactId));

      const memory = sbUnsavedChangesService.loadMemory(SAVED_DATA_KEY) || {};
      ctrl.viewState = { ...defaultViewState, ...memory };
    }

    // Reloads the invoice data which powers the invoice list.
    function updateInvoiceListData() {
      const { filterFn, statusFilterFn } = createInvoiceFilters(viewContactBillsFilters.selectors.getFilters(store.getState()));
      ctrl.invoiceListData = loadInvoiceListData({
        filterFn,
        statusFilterFn,
        getInvoiceTotalsFn: getTotalsCents,
      });

      const { invoices } = ctrl.invoiceListData;
      const { column, direction } = ctrl.viewState.sorting;
      ctrl.invoiceListData.invoices = sortInvoices({ invoices, sortBy: column, sortDirection: direction });
    }

    // Updates the tracking of which actions are available to be performed on the invoice list selections.
    function updateInvoiceActionAvailability() {
      // Clear current invoice IDs for each invoice action.
      const { invoiceActions, selectedInvoices } = ctrl.viewState;

      if (!invoiceActions) {
        // depending on when a digest occurs, this function can be called after the $destroy event occurs
        // meaning that an exception gets thrown as invoiceActions is undefined.
        // this doesn't affect the UX (as the component is already destroyed), but shows up as an exception
        // on the console
        return;
      }

      Object.values(invoiceActions).forEach((setOfInvoiceIdsForAction) => setOfInvoiceIdsForAction.clear());

      // Determine new invoice IDs for each invoice action based on the currently selected invoices.
      Object.keys(selectedInvoices).forEach((invoiceId) => {
        // If the selected invoice is not currently being displayed in the invoice list (e.g. due to filtering),
        // then we remove it from the selected invoices to prevent user surprise by it re-appearing on filter change.
        const invoice = ctrl.invoiceListData.invoicesById[invoiceId];
        if (!invoice) {
          delete selectedInvoices[invoiceId];
          return;
        }

        const { pseudoStatus } = ctrl.invoiceListData.invoicesById[invoiceId];

        // Finalise.
        if (pseudoStatus === 'DRAFT') {
          invoiceActions.finalise.add(invoiceId);
          invoiceActions.delete.add(invoiceId);
        }

        // Combine in PDF.
        if (['DRAFT', 'FINAL', 'OVERDUE', 'PAID'].includes(pseudoStatus)) {
          invoiceActions.combineInPdf.add(invoiceId);
        }

        // Combine in PDF with Cover Letter
        if (['DRAFT', 'FINAL', 'OVERDUE'].includes(pseudoStatus)) {
          invoiceActions.combineInPdfWithCoverLetter.add(invoiceId);
        }

        // Email and Mark as Sent
        if (['FINAL', 'OVERDUE', 'PAID'].includes(pseudoStatus)) {
          invoiceActions.email.add(invoiceId);
          invoiceActions.markAsSent.add(invoiceId);
        }

        // Download LEDES
        const matterBillingConfiguration = getCurrentConfigurationByMatterId(invoice.matterId);
        if (matterBillingConfiguration && matterBillingConfiguration.isUtbmsEnabled) {
          invoiceActions.downloadLedes.add(invoiceId);
        }
      });

      ctrl.invoiceIdsForEmailToSend = Array.from(invoiceActions.email);
      ctrl.invoiceIdsForMarkAsSent = Array.from(invoiceActions.markAsSent);
      ctrl.invoiceIdsForDeletion = Array.from(invoiceActions.delete);
      ctrl.isInvoiceBulkActionable = ctrl.viewState.invoiceActions.finalise.size ||
        ctrl.invoiceIdsForDeletion.length ||
        ctrl.invoiceIdsForEmailToSend.length ||
        ctrl.invoiceIdsForMarkAsSent.length;
    }

    async function checkBulkCreateInvoicesInProgress () {
      try {
        const bulkActionsQuery = [{
          operationName: "BillingBulkActions",
          query: printGqlString(BillingBulkActions.query),
          variables: { type: billingBulkActionTypes.BULK_CREATE_INVOICES, contactIds: [contactId] },
        }];
    
        const { data } = await fetchGraphqlData(bulkActionsQuery);
  
        ctrl.isBulkCreateInvoicesInProgress = data.billingBulkActionList && data.billingBulkActionList.totalCount > 0;
       } catch (err) {
        log.error('Failed to fetch billingBulkActionList for contact invoices tab:', err);
      }
    }
  
    function subscribeToBulkActionNotifications () {
      ctrl.unsubscribeToBulkActionNotifications = subscribeToNotifications({
        notificationIds: BillingBulkActions.notificationIds,
        callback: () => {
          checkBulkCreateInvoicesInProgress();
        },
      });
    }

    // Destroy controller.
    $scope.$on('$destroy', () => {
      // IinvoiceIdsForEmailToSendnvoice selections and actions are not to be remembered across controller visits.
      delete ctrl.viewState.selectedInvoices;
      delete ctrl.viewState.invoiceActions;
      delete ctrl.viewState.showEmailModal;
      delete ctrl.viewState.showCommunicateModal;
      delete ctrl.viewState.showMarkAsSentModal;

      sbUnsavedChangesService.saveMemory(SAVED_DATA_KEY, ctrl.viewState);
      unsubscribeFromStore();
      
      if (this.unsubscribeToBulkActionNotifications) {
        this.unsubscribeToBulkActionNotifications();
      }
    });

    // Called when an expand/collapse event occurs on a filter.
    function toggleHideFilters(event) {
      const filter = event.currentTarget.id;
      ctrl.viewState.hideFilters[filter] = !ctrl.viewState.hideFilters[filter];
    }

    // Called when a single invoice selection checkbox is toggled in the invoice list.
    function onSelectInvoice({ invoice }) {
      const { selectedInvoices } = ctrl.viewState;
      const selected = !selectedInvoices[invoice.invoiceId];

      if (!selected) {
        delete selectedInvoices[invoice.invoiceId];
      } else {
        selectedInvoices[invoice.invoiceId] = true;
      }

      ctrl.viewState.selectedInvoices = { ...selectedInvoices };

      updateInvoiceActionAvailability();
    }

    // Called when the "select all" invoice checkbox is toggled in the invoice list.
    function onSelectAllInvoices(selected) {
      ctrl.viewState.selectedInvoices = {};

      if (!selected) {
        return updateInvoiceActionAvailability();
      }

      ctrl.invoiceListData.invoices.forEach(({ invoiceId, currentVersion: { status } }) => {
        if (status !== "VOID") {
          ctrl.viewState.selectedInvoices[invoiceId] = true;
        }
      });

      return updateInvoiceActionAvailability();
    }

    // Called when a new sort column/direction is applied to the invoice list.
    function onSortInvoices({ sortBy, sortDirection }) {
      // Remember the sort order
      const newSortOrder = { column: sortBy, direction: sortDirection };
      ctrl.viewState.sorting = newSortOrder;

      // Perform the sort.
      ctrl.invoiceListData.invoices = sortInvoices({ invoices: ctrl.invoiceListData.invoices, sortBy, sortDirection });
    }

    //////////////////////////////
    // Start action handlers
    //////////////////////////////
    function onOpenModal(id) {
      ctrl.showModal = id;
    }

    function closeModal() {
      ctrl.showModal = undefined;
    }

    function onOpenSendEmailsModal() {
      // LOD 
      if (featureActive('BB-12977')) {
        setModalDialogVisible({
          modalId: INVOICE_EMAIL_MODAL_ID,
          props: {
            consolidateEmails: true,
            debtorId: ctrl.viewState.contactId,
            invoiceIds: ctrl.indicatorStatusEmailInvoiceIds || ctrl.invoiceIdsForEmailToSend,
            scope: 'contact-invoices-list-invoice-email-modal',
            onPreview: ({
              invoiceEmailRequest,
            }) => sbInvoiceSendService.createInvoiceEmailPreviewP({
              invoiceEmailRequest,
            }),
            onSendEmails: async ({ invoiceEmailRequests }) => {
              try {
                await sbInvoiceSendService.sendInvoiceEmailRequestsP(invoiceEmailRequests);
              } catch (err) {
                log.error('Failed to send emails', err);
              }
            }
          }
        });
      } else {
        // Legacy
        ctrl.viewState.showEmailModal = true;
      }
    }

    function onOpenSendCommunicatesModal() {
      // Invoice via Communicate feature switch
      if (!featureActive('BB-9097')) {
        return;
      }

      // LOD
      if (featureActive('BB-13005')) {
        setModalDialogVisible({
          modalId: INVOICE_COMMUNICATE_MODAL_ID,
          props: {
            debtorId: ctrl.viewState.contactId,
            invoiceIds: ctrl.indicatorStatusCommunicateInvoiceIds,
            scope: 'contact-bills-list-invoice-communicate-modal',
            onPreview: ({ invoiceCommunicateRequest }) =>
              sbInvoiceSendService.createInvoiceCommunicatePreviewP({
                invoiceCommunicateRequest,
              }),
            onSend: async ({ invoiceCommunicateRequests }) => {
              try {
                await sbInvoiceSendService.sendInvoiceCommunicateRequestsP(invoiceCommunicateRequests);
              } catch (err) {
                log.error('Failed to send invoice communicate requests: ', err);
              }
            },
          },
        });
      } else {
        // Legacy
        ctrl.viewState.showCommunicateModal = true;
      }
    }

    function onOpenMarkAsSentModal() {
      ctrl.viewState.showMarkAsSentModal = true;
    }

    async function onSendEmails(invoiceEmailRequest) {
      sbInvoiceSendService.sendInvoiceEmailRequestsP(invoiceEmailRequest);
      onCloseSendEmailsModal();
    }

    function onSendCommunicates(invoiceCommunicateRequest) {
      sbInvoiceSendService.sendInvoiceCommunicateRequestsP(invoiceCommunicateRequest);
      onCloseSendCommunicatesModal();
    }

    function onSendEmailFromIndicatorIcon({ invoiceId }) {
      ctrl.indicatorStatusEmailInvoiceIds = [invoiceId];
      onOpenSendEmailsModal()
    }

    function onSendCommunicateFromIndicatorIcon({ invoiceId }) {
      ctrl.indicatorStatusCommunicateInvoiceIds = [invoiceId];
      onOpenSendCommunicatesModal()
    }

    function onMarkAsSentFromIndicatorIcon({ invoiceId, operationType }) {
      ctrl.indicatorStatusMarkAsSentInvoiceIds = [ invoiceId ];
      ctrl.operationType = operationType;
      ctrl.viewState.showMarkAsSentModal = true;
    }

    function onCloseSendEmailsModal() {
      ctrl.indicatorStatusEmailInvoiceIds = undefined;
      ctrl.viewState.showEmailModal = false;
    }

    function onCloseSendCommunicatesModal() {
      ctrl.indicatorStatusCommunicateInvoiceIds = undefined;
      ctrl.viewState.showCommunicateModal = false;
    }

    function onCloseMarkAsSentModal() {
      ctrl.indicatorStatusMarkAsSentInvoiceIds = undefined;
      ctrl.viewState.showMarkAsSentModal = false;
    }

    async function onInvoiceProceedConfirm() {
      ctrl.viewState.showInvoiceConfirmProceedModal = false;
      await bulkFinalise();
    }

    function onInvoiceProceedCancel() {
      ctrl.viewState.showInvoiceConfirmProceedModal = false;
      ctrl.viewState.hasZeroBalance = false;
      ctrl.viewState.hasUnpaidAD = false;
    }

    function onCombineInPdf() {
      // Cannot disable non-interactive elements so we double check here that the operation is valid.
      if (!ctrl.viewState.invoiceActions.combineInPdf.size) {
        return;
      }

      const invoiceIdsToCombine = [...ctrl.viewState.invoiceActions.combineInPdf];
      const someInvoicesPendingCreation = hasPendinginvoices(invoiceIdsToCombine);
      if (someInvoicesPendingCreation) {
        displayWarningToUser(warningMessages.WARNING_UNABLE_TO_COMBINE_PENDING_INVOICES);
        return;
      }

      sbAsyncOperationsService.startCombineInvoices(invoiceIdsToCombine);
    }

    function onCombineInPdfWithCover() {
      // Cannot disable non-interactive elements so we double check here that the operation is valid.
      if (!ctrl.viewState.invoiceActions.combineInPdfWithCoverLetter.size) {
        return;
      }

      const invoiceIdsToCombine = [...ctrl.viewState.invoiceActions.combineInPdfWithCoverLetter];
      const someInvoicesPendingCreation = hasPendinginvoices(invoiceIdsToCombine);
      if (someInvoicesPendingCreation) {
        displayWarningToUser(warningMessages.WARNING_UNABLE_TO_COMBINE_PENDING_INVOICES);
        return;
      }

      sbAsyncOperationsService.startCombineInvoices(invoiceIdsToCombine, true, contactId);
    }

    function onDownloadLedes() {
      // Cannot disable non-interactive elements so we double check here that the operation is valid.
      if (!ctrl.viewState.invoiceActions.downloadLedes.size) {
        return;
      }

      const invoiceIdsToDownloadLedes = [...ctrl.viewState.invoiceActions.downloadLedes];
      const someInvoicesPendingCreation = hasPendinginvoices(invoiceIdsToDownloadLedes);
      if (someInvoicesPendingCreation) {
        displayWarningToUser(warningMessages.WARNING_UNABLE_TO_COMBINE_PENDING_INVOICES);
        return;
      }

      sbAsyncOperationsService.startDownloadLedes(invoiceIdsToDownloadLedes);
    }

    async function onBulkFinalise() {
      const invoiceIdsToFinalise = [...ctrl.viewState.invoiceActions.finalise];

      let hasUnpaidAD = false;
      let hasZeroBalance = false;
      const supportsAD = featureActive("BB-9573");
      const supportsZeroBalance = featureActive("BB-7088");

      for (const invoiceId of invoiceIdsToFinalise) {
        const invoice = ctrl.invoiceListData.invoicesById[invoiceId];
        if (supportsZeroBalance && invoice && invoice.total === 0) {
          hasZeroBalance = true;
        }
        if (
          supportsAD &&
          invoice &&
          hasUnpaidAnticipatedDisbursements({
            invoice: invoice.currentVersion,
            getExpenseById,
            getExpensePaymentDetailsById,
          })
        ) {
          hasUnpaidAD = true;
        }
      }

      ctrl.viewState.hasZeroBalance = hasZeroBalance;
      ctrl.viewState.hasUnpaidAD = hasUnpaidAD;

      if (hasZeroBalance || hasUnpaidAD) {
        ctrl.viewState.showInvoiceConfirmProceedModal = true;
        ctrl.viewState.zeroBalanceInvoiceCount = invoiceIdsToFinalise.length;
      } else {
        await bulkFinalise();
      }
    }

    async function bulkFinalise() {
      try {
        ctrl.isFinaliseInProgress = true;

        const invoiceIdsToFinalise = [...ctrl.viewState.invoiceActions.finalise];
        const bulk = await sbSaveInvoiceCommand.executeBulkP(invoiceIdsToFinalise);
        const finalisedDraftCount = bulk.filter((i) => i.success).length;
        const draftErrorCount = bulk.filter((i) => !i.success).length;

        if (finalisedDraftCount) {
          sbMessageDisplayService.success(
            sbMessageDisplayService
              .builder()
              .text(`{0} Draft invoice(s) ${ctrl.t('finalised')}`, finalisedDraftCount),
          );
        }
        if (draftErrorCount) {
          sbMessageDisplayService.error(
            sbMessageDisplayService
              .builder()
              .text(
                `{0} Draft invoice(s) could not be ${ctrl.t('finalised')}`,
                draftErrorCount,
              ),
          );
          log.error('bulk finalise error', bulk);
        }
      } catch (ex) {
        sbMessageDisplayService.error(
          sbMessageDisplayService
            .builder()
            .text(`Draft invoice could not be ${ctrl.t('finalised')}`),
        );
        log.error(ex);
      } finally {
        ctrl.isFinaliseInProgress = false;
      }
    }

    function onOpenAddPaymentModal() {
      if (featureActive('BB-13936')) {
        setModalDialogVisible({
          modalId: ADD_PAYMENT_MODAL_ID,
          props: {
            scope: 'ViewContactBills/add-payment-modal',
            contactId: ctrl.viewState.contactId,
          }
        });
      } else {
        // Non-LOD version
        ctrl.onOpenModal('add-payment-modal');
      }
    }

    //////////////////////////////
    // End action handlers
    //////////////////////////////

    function isCombineInProgress() {
      return (
        sbAsyncOperationsService.nbActiveOperations(sbAsyncOperationsService.supportedOperations.COMBINE_INVOICES) > 0
      );
    }

    function isDownloadLedesInProgress() {
      return sbAsyncOperationsService.nbActiveOperations(sbAsyncOperationsService.supportedOperations.DOWNLOAD_LEDES) > 0;
    }

    function showDownloadLedesButton() {
      return isUtbmsEnabled() && isUtbmsEnabledForSomeMatters();
    }

    function isUtbmsEnabledForSomeMatters() {
      if (!(ctrl.invoiceListData && ctrl.invoiceListData.invoices)) {
        return false;
      }

      // gather a list of unique matterIds from the invoices
      const uniqueMatterIdsMap = ctrl.invoiceListData.invoices.reduce((acc, invoice) => {
        if (invoice.matterId) {
          acc[invoice.matterId] = invoice.matterId;
        }
        return acc;
      }, {});

      // see if some of the matters have UTBMS enabled
      const uniqueMatterIds = Object.keys(uniqueMatterIdsMap);
      return uniqueMatterIds.some((matterId) => {
        const billingConfig = getBillingConfigById(matterId);
        const isUtbmsEnabledForMatter = billingConfig && billingConfig.currentWorkItem && billingConfig.currentWorkItem.isUtbmsEnabled;
        return isUtbmsEnabledForMatter;
      });
    }
  });

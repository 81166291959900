import React from 'react';
import { Button, Modal } from '@sb-itops/react';
import classnames from 'classnames';
import { Staff, File, Document } from 'types';
import { FileDetailQuery } from 'web/graphql/types/graphql';
import Styles from './Modal.module.scss';
import { ViewFileContainer as ViewFile } from './ViewFileContainer';

export interface IViewFileModalProps {
  onClose: () => void;
  formSubmitting: boolean;
  matterId: string;
  file: File;
  getPersonByUserId: (id: string) => Staff;
  fileDetailResult: { data: FileDetailQuery | undefined; loading: boolean; refetch?: Function };
  getFileDetail: Function;
  setShowShareModal: (payload: { documents: Document[]; isLiving: boolean } | false) => void;
}

export const ViewFileModal = (props: IViewFileModalProps) => (
  <Modal
    className={classnames(Styles.modal, Styles.largeModal, Styles.noPadding)}
    title="File Details"
    body={
      <ViewFile
        getFileDetail={props.getFileDetail}
        fileDetailResult={props.fileDetailResult}
        file={props.file}
        matterId={props.matterId}
        getPersonByUserId={props.getPersonByUserId}
        setShowShareModal={props.setShowShareModal}
      />
    }
    footer={
      <Button
        size="full-width"
        onClick={() => props.onClose()}
        disabled={props.formSubmitting}
        locked={props.formSubmitting}
      >
        Close
      </Button>
    }
    onModalClose={props.onClose}
  />
);

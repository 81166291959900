import React from 'react';
import PropTypes from 'prop-types';
import {
  SlidingToggle,
  forms2PropTypes,
  Icon,
  StatelessSelect,
  Button,
  Spinner,
  useTranslation,
} from '@sb-itops/react';
import { Table, Column } from '@sb-itops/react/table';
import { featureActive } from '@sb-itops/feature';
import classNames from 'classnames';
import { bankAccountState } from '@sb-billing/business-logic/bank-account/entities/constants';
import { ALL_STATES, getStateNameFromId } from '@sb-itops/region';
import Styles from './TrustAccountSettings.module.scss';
import { TrustAccountAddEditCard } from '../trust-account-add-edit-card';

export const TrustAccountSettings = React.memo(
  ({
    region,
    setShowClosedAccount,
    showClosedAccounts,
    setAddEditTrustAccount,
    addEditTrustAccount,
    trustAccountsList,
    activeTrustAccountsByLocation,
    hasMissingTrustAccountLocation,
    isAuthorised,
    restrictTrustAccountEdit,
    // form fields
    allowOnlyFirmOwnersEditTrustAccountDetails,
    createPDFReceiptOnDeposit,
    createPDFReceiptOnTrustPayment,
    createPDFReceiptOnOperatingPayment,
    createPDFReceiptOnMatterTransfer,
    createPDFReceiptOnDepositCMA,
    createPDFReceiptOnPaymentCMA,
    locationsDefault,
    // form
    onFieldValueUpdated,
    formInitialised,
    formSubmitting,
    submitFailed,
    onSave,
    // config
    supportsOperatingAccount,
    supportsCMA,
    supportsTrustAccountPerState,
    supportsFirmOwnerBankDetailChanges,
    abbrState,
  }) => {
    const { t } = useTranslation();

    if (!formInitialised) {
      return null;
    }

    const isError = (field) => field?.isInvalid && (field?.isDirty || submitFailed);

    const allLocations = Object.keys(activeTrustAccountsByLocation);
    const blockSending = hasMissingTrustAccountLocation && trustAccountsList.length > 1;

    return (
      <div className={Styles.trustSettings}>
        {addEditTrustAccount?.show ? (
          <TrustAccountAddEditCard
            bankAccountId={addEditTrustAccount.id}
            onCloseCard={() => setAddEditTrustAccount({ show: false, id: undefined })}
            restrictTrust={restrictTrustAccountEdit}
          />
        ) : (
          <>
            <br />
            <div>
              <h4>{t('trustAccount')} Settings</h4>
              <div className={Styles.addTrustAccount}>
                <div className="link-button" onClick={() => setAddEditTrustAccount({ show: true, id: undefined })}>
                  <i className="icon icon-add-3" />
                  ADD {t('trustAccount').toUpperCase()}
                </div>
                <div className={Styles.toggleWrapper}>
                  <SlidingToggle
                    scope="show-closed-trust-accounts"
                    onChange={(name, value) => setShowClosedAccount(value)}
                    selected={showClosedAccounts}
                  />
                  <div onClick={() => setShowClosedAccount(!showClosedAccounts)}>Show closed accounts</div>
                </div>
              </div>
              <div className="form-group">
                <Table
                  style={{
                    height: `${30 + trustAccountsList.length * 38}px`,
                  }}
                  className="table-shadow"
                  onRowClick={({ rowData }) => setAddEditTrustAccount({ show: true, id: rowData.id })}
                  list={trustAccountsList}
                >
                  <Column
                    dataKey="accountName"
                    label="Account Name"
                    flexGrow={1}
                    cellRenderer={accountNameRenderer({ t })}
                  />
                  <Column dataKey="displayName" label="Display Name" flexGrow={1} />
                  <Column dataKey="bankName" label="Bank Name" flexGrow={1} headerClassName={Styles.centeredHeading} />
                  {supportsTrustAccountPerState && (
                    <Column
                      dataKey="location"
                      label={t('capitalize', { val: 'addressState' })}
                      flexGrow={0.5}
                      cellRenderer={locationRenderer(region, abbrState)}
                    />
                  )}
                </Table>
              </div>

              {allLocations.length ? (
                allLocations.map((locationId) => (
                  <div className="row" key={locationId}>
                    <div className="form-group col-md-6">
                      <label>
                        Default Payment Account{' '}
                        {supportsTrustAccountPerState && `for ${getLocationName(locationId, region)}`}
                      </label>
                      <StatelessSelect
                        className={classNames({ [Styles.hasError]: isError(locationsDefault?.[locationId]) })}
                        selectedOption={locationsDefault?.[locationId]?.value}
                        stateless
                        options={activeTrustAccountsByLocation[locationId]}
                        onChange={(option) => {
                          onFieldValueUpdated({ [`locationsDefault.${locationId}`]: option.value });
                        }}
                        placeholder="Select..."
                      />
                    </div>
                  </div>
                ))
              ) : (
                <div className="row">
                  <div className="form-group col-md-6">
                    <label>Default Payment Account</label>
                    <StatelessSelect
                      disabled
                      stateless
                      placeholder={
                        supportsTrustAccountPerState
                          ? `No states found on ${t('trustAccounts').toLowerCase()}`
                          : `No applicable ${t('trustAccounts').toLowerCase()} found`
                      }
                    />
                  </div>
                </div>
              )}

              {featureActive('BB-12792') && supportsFirmOwnerBankDetailChanges && (
                <div className={(Styles.togglesSection, 'form-group')}>
                  <div className={Styles.toggleWrapper}>
                    <SlidingToggle
                      scope="'allow-only-firm-owners-to-edit-trust-account-details'"
                      onChange={(name, value) =>
                        onFieldValueUpdated({ allowOnlyFirmOwnersEditTrustAccountDetails: value })
                      }
                      selected={allowOnlyFirmOwnersEditTrustAccountDetails.value}
                      disabled={!isAuthorised}
                    />
                    <div
                      onClick={() =>
                        isAuthorised
                          ? onFieldValueUpdated({
                              allowOnlyFirmOwnersEditTrustAccountDetails:
                                !allowOnlyFirmOwnersEditTrustAccountDetails.value,
                            })
                          : {}
                      }
                      className={!isAuthorised ? Styles.disabled : ''}
                    >
                      Allow only Firm Owners to edit {t('trustAccount')} details
                    </div>
                  </div>
                </div>
              )}

              {featureActive('BB-6381') && <h4>{t('trustOptionsLabel')}</h4>}
              <div className={(Styles.togglesSection, 'form-group')}>
                <div className={Styles.toggleWrapper}>
                  <SlidingToggle
                    scope="'create-PDF-receipt-on-deposit'"
                    onChange={(name, value) => onFieldValueUpdated({ createPDFReceiptOnDeposit: value })}
                    selected={createPDFReceiptOnDeposit.value}
                  />
                  <div
                    onClick={() =>
                      onFieldValueUpdated({
                        createPDFReceiptOnDeposit: !createPDFReceiptOnDeposit.value,
                      })
                    }
                  >
                    Always open PDF receipt when creating a {t('trust')} Deposit
                  </div>
                </div>

                <div className={Styles.toggleWrapper}>
                  <SlidingToggle
                    scope="'create-PDF-receipt-on-trust-payment'"
                    onChange={(name, value) => onFieldValueUpdated({ createPDFReceiptOnTrustPayment: value })}
                    selected={createPDFReceiptOnTrustPayment.value}
                  />
                  <div
                    onClick={() =>
                      onFieldValueUpdated({
                        createPDFReceiptOnTrustPayment: !createPDFReceiptOnTrustPayment.value,
                      })
                    }
                  >
                    Always open PDF when creating a {t('trust')} Payment
                  </div>
                </div>

                {supportsOperatingAccount && (
                  <div className={Styles.toggleWrapper}>
                    <SlidingToggle
                      scope="'create-PDF-receipt-on-operating-payment'"
                      onChange={(name, value) => onFieldValueUpdated({ createPDFReceiptOnOperatingPayment: value })}
                      selected={createPDFReceiptOnOperatingPayment.value}
                    />
                    <div
                      onClick={() =>
                        onFieldValueUpdated({
                          createPDFReceiptOnOperatingPayment: !createPDFReceiptOnOperatingPayment.value,
                        })
                      }
                    >
                      Always open PDF when creating an {t('operating')} Payment
                    </div>
                  </div>
                )}

                <div className={Styles.toggleWrapper}>
                  <SlidingToggle
                    scope="'create-PDF-receipt-on-matter-transfer'"
                    onChange={(name, value) => onFieldValueUpdated({ createPDFReceiptOnMatterTransfer: value })}
                    selected={createPDFReceiptOnMatterTransfer.value}
                  />
                  <div
                    onClick={() =>
                      onFieldValueUpdated({
                        createPDFReceiptOnMatterTransfer: !createPDFReceiptOnMatterTransfer.value,
                      })
                    }
                  >
                    Always open PDF detail when creating a {t('trust')} Transfer
                  </div>
                </div>

                {supportsCMA && featureActive('BB-6381') && (
                  <>
                    <div className={Styles.toggleWrapper}>
                      <SlidingToggle
                        scope="'create-PDF-receipt-on-cma-deposit'"
                        onChange={(name, value) => onFieldValueUpdated({ createPDFReceiptOnDepositCMA: value })}
                        selected={createPDFReceiptOnDepositCMA.value}
                      />
                      <div
                        onClick={() =>
                          onFieldValueUpdated({
                            createPDFReceiptOnDepositCMA: !createPDFReceiptOnDepositCMA.value,
                          })
                        }
                      >
                        Always open PDF receipt when creating a {t('CMA')} deposit
                      </div>
                    </div>
                    <div className={Styles.toggleWrapper}>
                      <SlidingToggle
                        scope="'create-PDF-receipt-on-cma-payment'"
                        onChange={(name, value) => onFieldValueUpdated({ createPDFReceiptOnPaymentCMA: value })}
                        selected={createPDFReceiptOnPaymentCMA.value}
                      />
                      <div
                        onClick={() =>
                          onFieldValueUpdated({
                            createPDFReceiptOnPaymentCMA: !createPDFReceiptOnPaymentCMA.value,
                          })
                        }
                      >
                        Always open PDF receipt when creating a {t('CMA')} payment
                      </div>
                    </div>
                  </>
                )}
              </div>
              {allLocations.some((loc) => isError(locationsDefault[loc])) && (
                <div className={Styles.errorMessage}>
                  You must select a default payment account {supportsTrustAccountPerState && 'for all states'}
                </div>
              )}
              {blockSending && (
                <div className={Styles.errorMessage}>
                  {supportsTrustAccountPerState
                    ? `All ${t('trustAccounts').toLowerCase()} must have a state selected`
                    : `At least one ${t('trustAccount').toLowerCase()} is not configured correctly`}
                </div>
              )}
              <Button disabled={blockSending || formSubmitting} onClick={onSave}>
                Save {formSubmitting && <Spinner small />}
              </Button>
            </div>
          </>
        )}
      </div>
    );
  },
);

const accountNameRenderer =
  ({ t }) =>
  ({ rowData }) => {
    const accountName = rowData.accountName || t('trustAccount');

    if (rowData.state === bankAccountState.CLOSED) {
      return (
        <div className={Styles.flex}>
          <div className={Styles.accountName}>{accountName}</div>
          <div>
            <span className={Styles.closedBadge}>CLOSED</span>
          </div>
        </div>
      );
    }

    return accountName;
  };

const locationRenderer =
  (region, abbrState) =>
  ({ rowData }) => {
    if (!rowData.location) {
      return rowData.state === bankAccountState.OPEN ? (
        <div className={Styles.noStateSelected}>
          <Icon type="alert-1" tooltip="Click to select a state for this account" color="red" />
        </div>
      ) : null;
    }

    return getLocationName(rowData.location, region, abbrState);
  };

// We want to display abbrv. for AU but full state name for US
const getLocationName = (location, region, abbrState) => {
  if (location === ALL_STATES.value) {
    return ALL_STATES.label;
  }
  return abbrState ? location : getStateNameFromId(location, region);
};

TrustAccountSettings.displayName = 'TrustAccountSettings';

const { Forms2Field } = forms2PropTypes;

TrustAccountSettings.propTypes = {
  region: PropTypes.string.isRequired,
  supportsOperatingAccount: PropTypes.bool.isRequired,
  supportsCMA: PropTypes.bool.isRequired,
  supportsTrustAccountPerState: PropTypes.bool.isRequired,
  supportsFirmOwnerBankDetailChanges: PropTypes.bool.isRequired,
  isAuthorised: PropTypes.bool.isRequired,
  abbrState: PropTypes.bool.isRequired,
  setShowClosedAccount: PropTypes.func.isRequired,
  showClosedAccounts: PropTypes.bool.isRequired,
  setAddEditTrustAccount: PropTypes.func.isRequired,
  addEditTrustAccount: PropTypes.shape({
    show: PropTypes.bool,
    id: PropTypes.string,
  }).isRequired,
  trustAccountsList: PropTypes.array.isRequired,
  activeTrustAccountsByLocation: PropTypes.object.isRequired,
  hasMissingTrustAccountLocation: PropTypes.bool.isRequired,
  restrictTrustAccountEdit: PropTypes.bool.isRequired,

  // form fields
  allowOnlyFirmOwnersEditTrustAccountDetails: Forms2Field,
  createPDFReceiptOnDeposit: Forms2Field,
  createPDFReceiptOnTrustPayment: Forms2Field,
  createPDFReceiptOnOperatingPayment: Forms2Field,
  createPDFReceiptOnMatterTransfer: Forms2Field,
  createPDFReceiptOnDepositCMA: Forms2Field,
  createPDFReceiptOnPaymentCMA: Forms2Field,
  locationsDefault: Forms2Field,
  // form
  onFieldValueUpdated: PropTypes.func.isRequired,
  formInitialised: PropTypes.bool.isRequired,
  formSubmitting: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
};

TrustAccountSettings.defaultProps = {
  allowOnlyFirmOwnersEditTrustAccountDetails: undefined,
  createPDFReceiptOnDeposit: undefined,
  createPDFReceiptOnTrustPayment: undefined,
  createPDFReceiptOnOperatingPayment: undefined,
  createPDFReceiptOnMatterTransfer: undefined,
  createPDFReceiptOnDepositCMA: undefined,
  createPDFReceiptOnPaymentCMA: undefined,
  locationsDefault: undefined,
};

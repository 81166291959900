/*
 * Due to problems with the current version of ui-router we are using (0.X), we need to use $location
 * to update the url rather than $state.go.
 *
 * The problem isnt with changing the params themselves, but with how ui-router looks at those changes
 * and reloading EVERYTHING if it sees changes.
 *
 * The ui-router team know this and they have tackled the problem in 1.X.
 *
 * Many pages on it on GH, but https://github.com/angular-ui/ui-router/issues/125 is one that the
 * ui-router team kept linking to.
 *
 * Most of this file is this way because of work arounds due to the old version of ui-router
 *
 * */

import { getPseudoStatusById as getInvoiceStatusById } from '@sb-billing/redux/invoices';
import { getCurrentConfigurationByMatterId } from '@sb-billing/redux/billing-configuration';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { sendMetric } from 'web/services/metrics';
import { hasBillingAccess } from 'web/services/user-session-management';
import { featureActive } from '@sb-itops/feature';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { INVOICE_COMMUNICATE_MODAL_ID, INVOICE_EMAIL_MODAL_ID } from 'web/components';

angular.module('sb.billing.webapp').controller('SbAdvancedSearchController', function ($scope, $state, sbLoggerService, $location, focusService, sbAsyncOperationsService, sbInvoiceSendService, sbLinkService, sbSaveInvoiceCommand) {
  const ctrl = this;
  const log = sbLoggerService.getLogger('SbAdvancedSearchController');

  ctrl.searchOptions = {
    allMatters: true
  };

  sendMetric('NavAdvancedsearch');

  let initialLoad = true;

  //log.setLogLevel('info');

  // START: LOD Adv. Search Page Invoices (BB-14223)
  ctrl.sbAsyncOperationsService = sbAsyncOperationsService;
  ctrl.sbInvoiceSendService = sbInvoiceSendService;
  ctrl.sbSaveInvoiceCommand = sbSaveInvoiceCommand;
  ctrl.isDownloadLedesInProgress = isDownloadLedesInProgress;
  ctrl.invoiceCount = undefined;
  ctrl.areInvoicesLoading = true;
  ctrl.getInvoiceCountData = getInvoiceCountData;

  // When the LOD Invoice list is used, we need to reflect:
  //  1. The query results from the LOD implementation (both rows and count)
  //  2. Loading state while fetching data
  // 
  // The above two are handled in Angular, so we need to fetch and set data from React/LOD
  function getInvoiceCountData({ isLoading, invoiceCount }) {
    if (isLoading) {
      ctrl.invoiceCount = undefined; // only the spinner should be displayed upon loading
      ctrl.areInvoicesLoading = true;
      return;
    }

    ctrl.areInvoicesLoading = false;
    ctrl.invoiceCount = invoiceCount;
  }
  // END: LOD Adv. Search Page Invoices (BB-14223)

  cleanSearchTerm();
  ctrl.sbData = { stateName: $state.current.name };

  ctrl.updateUrlParams = updateUrlParams;
  ctrl.dataChangeFunction = dataChangeFunction;
  ctrl.isTabActive = isTabActive;
  ctrl.hasBillingAccess = hasBillingAccess;

  ctrl.onOpenSendEmailsModal = onOpenSendEmailsModal;
  ctrl.onSendEmails = onSendEmails;
  ctrl.onCloseSendEmailsModal = onCloseSendEmailsModal;
  ctrl.onOpenSendCommunicatesModal = onOpenSendCommunicatesModal;
  ctrl.onSendCommunicates = onSendCommunicates;
  ctrl.onCloseSendCommunicatesModal = onCloseSendCommunicatesModal;

  ctrl.isCombineInProgress = isCombineInProgress;
  ctrl.combineInPdf = combineInPdf;
  ctrl.combineInPdfWithCover = combineInPdfWithCover;
  ctrl.onDownloadLedes = onDownloadLedes;
  ctrl.onClickLink = sbLinkService.onClickLink;
  ctrl.onSendEmailFromIndicatorIcon = onSendEmailFromIndicatorIcon;
  ctrl.onSendCommunicateFromIndicatorIcon = onSendCommunicateFromIndicatorIcon;
  ctrl.onSelectInvoice = onSelectInvoice;
  ctrl.selectedInvoices = [];
  ctrl.invoiceActions = {
    combineInPdf: [],
    combineInPdfWithCoverLetter: [],
    downloadLedes: [],
    email: []
  };

  ctrl.showEmailModal = false;
  ctrl.supportsUtbms = hasFacet(facets.utbms);

  $scope.$on('$stateChangeSuccess', () => {
    focusService.focusOn('advanced-search-input');
    cleanSearchTerm();
  });

  function cleanSearchTerm() {
    const dirty = $location.search().searchTerm;
    dirty && updateUrlParams(dirty.replace(/~2f/gi, '/'));
  }

  function dataChangeFunction(key, doc) {
    log.info('data change', key, doc);

    if (doc && doc.data) {
      ctrl.sbData[key] = doc.data;
    } else {
      ctrl.sbData[key] = doc;
    }
    $scope.$applyAsync();

    if (key === 'searchResults') {
      updateInvoiceActionAvailability();

      if (initialLoad) {
        initialLoad = false;
        selectTab(doc);
      }
    }
  }

  //if the person is on a tab that has data, stay at that one
  function selectTab(searchData) {
    const lodAdvSearchPageInvoiceList = featureActive('BB-14223');

    if (searchData.matters.length) {
      changeTab('matters');
    } else if (searchData.leads.length) {
      changeTab('leads');
    } else if (searchData.contacts.length) {
      changeTab('contacts');
    } else if (lodAdvSearchPageInvoiceList || searchData.invoices.length) {
      changeTab('invoices');
    }
  }

  function getSubSState(name) {
    const subState = name.match(/^.*\.([^.]*)$/);
    return subState ? subState[1] : name;
  }

  function changeTab(tab) {
    $state.go(`home.billing.advanced-search.${tab}`, { searchTerm: ctrl.searchTerm });
    sendMetric('NavQuicksearchTab', { tab });
  }

  function updateUrlParams(text) {
    ctrl.searchTerm = text;
    $location.search({ searchTerm: ctrl.searchTerm });
  }

  function isTabActive(tab) {
    return getSubSState($state.current.name) === tab ? 'active' : '';
  }

  function updateInvoiceActionAvailability() {
    Object.keys(ctrl.invoiceActions).forEach((action) => ctrl.invoiceActions[action] = []);

    // Re-calculate valid operations based on invoice selections.
    ctrl.selectedInvoices.forEach((invoiceId) => {
      // If the selected invoice is not currently being displayed in the invoice list (i.e. due to search term change),
      // then we remove it from the selected invoices to prevent user surprises.
      const invoice = ctrl.sbData.invoiceSummaries.invoicesById[invoiceId];
      if (!invoice) {
        delete ctrl.selectedInvoices[invoiceId];
        return;
      }

      const invoiceStatus = getInvoiceStatusById(invoiceId);

      // Combine in PDF.
      if (['DRAFT', 'FINAL', 'OVERDUE', 'PAID'].includes(invoiceStatus)) {
        ctrl.invoiceActions.combineInPdf.push(invoiceId);
      }

      // Combine in PDF with Cover Letter
      if (['DRAFT', 'FINAL', 'OVERDUE'].includes(invoiceStatus)) {
        ctrl.invoiceActions.combineInPdfWithCoverLetter.push(invoiceId);
      }

      // Email
      if (['FINAL', 'OVERDUE', 'PAID'].includes(invoiceStatus)) {
        ctrl.invoiceActions.email.push(invoiceId);
      }

      // Download LEDES
      const matterBillingConfiguration = getCurrentConfigurationByMatterId(invoice.matterId);
      if (matterBillingConfiguration && matterBillingConfiguration.isUtbmsEnabled) {
        ctrl.invoiceActions.downloadLedes.push(invoiceId);
      }
    });
  }

  function onOpenSendEmailsModal() {
    // LOD 
    if (featureActive('BB-12984')) {
      setModalDialogVisible({
        modalId: INVOICE_EMAIL_MODAL_ID,
        props: {
          debtorId: ctrl.indicatorStatusEmailDebtorId,
          invoiceIds: ctrl.indicatorStatusEmailInvoiceIds || ctrl.invoiceActions.email,
          scope: 'advanced-search-invoices-list-invoice-email-modal',
          onPreview: ({
            invoiceEmailRequest,
            preDraftMode,
            quickPaymentsTotalAmount,
          }) => sbInvoiceSendService.createInvoiceEmailPreviewP({
            invoiceEmailRequest,
            preDraftMode,
            quickPaymentsTotalAmount,
          }),
          onSendEmails: async ({ invoiceEmailRequests }) => {
            try {
              await sbInvoiceSendService.sendInvoiceEmailRequestsP(invoiceEmailRequests);
            } catch (err) {
              log.error('Failed to send emails', err);
            }
          }
        }
      });
    } else {
      // Legacy
      ctrl.showEmailModal = true;
    }
  }

  function onOpenSendCommunicatesModal() {
    // Invoice via Communicate feature switch
    if (!featureActive('BB-9097')) {
      return;
    }

    // LOD
    if (featureActive('BB-13005')) {
      setModalDialogVisible({
        modalId: INVOICE_COMMUNICATE_MODAL_ID,
        props: {
          debtorId: ctrl.indicatorStatusCommunicateDebtorId,
          invoiceIds: ctrl.indicatorStatusCommunicateInvoiceIds,
          scope: 'advanced-search-invoices-communicate-modal',
          onPreview: ({ invoiceCommunicateRequest }) =>
            sbInvoiceSendService.createInvoiceCommunicatePreviewP({
              invoiceCommunicateRequest,
            }),
          onSend: async ({ invoiceCommunicateRequests }) => {
            try {
              await sbInvoiceSendService.sendInvoiceCommunicateRequestsP(invoiceCommunicateRequests);
            } catch (err) {
              log.error('Failed to send invoice communicate requests: ', err);
            }
          },
        },
      });
    } else {
      // Legacy
      ctrl.showCommunicateModal = true;
    }
  }

  function onSendEmails(invoiceEmailRequests) {
    sbInvoiceSendService.sendInvoiceEmailRequestsP(invoiceEmailRequests);
    onCloseSendEmailsModal();
  }

  function onSendCommunicates(invoiceCommunicateRequest) {
    sbInvoiceSendService.sendInvoiceCommunicateRequestsP(invoiceCommunicateRequest);
    onCloseSendCommunicatesModal();
  }

  function onSendEmailFromIndicatorIcon({ invoiceId, contactId }) {
    const isInvalidSendStatus = ['DRAFT', 'VOID', 'DELETED'].includes(getInvoiceStatusById(invoiceId));
    if (isInvalidSendStatus) {
      return;
    }

    ctrl.indicatorStatusEmailInvoiceIds = [invoiceId];
    ctrl.indicatorStatusEmailDebtorId = contactId;

    onOpenSendEmailsModal();
  }

  function onSendCommunicateFromIndicatorIcon({ invoiceId, contactId }) {
    const isInvalidSendStatus = ['DRAFT', 'VOID', 'DELETED'].includes(getInvoiceStatusById(invoiceId));
    if (isInvalidSendStatus) {
      return;
    }

    ctrl.indicatorStatusCommunicateInvoiceIds = [invoiceId];
    ctrl.indicatorStatusCommunicateDebtorId = contactId;

    onOpenSendCommunicatesModal();
  }

  function onCloseSendEmailsModal() {
    ctrl.indicatorStatusEmailInvoiceIds = undefined;
    ctrl.indicatorStatusEmailDebtorId = undefined;
    ctrl.showEmailModal = false;
  }

  function onCloseSendCommunicatesModal() {
    ctrl.indicatorStatusEmailInvoiceIds = undefined;
    ctrl.indicatorStatusCommunicateDebtorId = undefined;
    ctrl.showCommunicateModal = false;
  }

  function onSelectInvoice(invoices = []) {
    ctrl.selectedInvoices = invoices;
    updateInvoiceActionAvailability();
  }

  function isCombineInProgress() {
    return sbAsyncOperationsService.nbActiveOperations(sbAsyncOperationsService.supportedOperations.COMBINE_INVOICES) > 0;
  }

  function combineInPdf() {
    // Cannot disable non-interactive elements so we double check here that the operation is valid.
    if (!ctrl.invoiceActions.combineInPdf.length) {
      return;
    }

    sbAsyncOperationsService.startCombineInvoices(ctrl.invoiceActions.combineInPdf);
  }

  // we can only combine-with-cover-letter when the invoices are not paid or void.
  // this isn't a problem anywhere else in the app except for here, where we don't
  // seperate invoices by their status
  function combineInPdfWithCover() {
    // Cannot disable non-interactive elements so we double check here that the operation is valid.
    if (!ctrl.invoiceActions.combineInPdfWithCoverLetter.length) {
      return;
    }

    sbAsyncOperationsService.startCombineInvoices(ctrl.invoiceActions.combineInPdfWithCoverLetter, true);
  }

  function onDownloadLedes() {
    // Cannot disable non-interactive elements so we double check here that the operation is valid.
    if (!ctrl.invoiceActions.downloadLedes.length) {
      return;
    }

    sbAsyncOperationsService.startDownloadLedes(ctrl.invoiceActions.downloadLedes);
  }

  function isDownloadLedesInProgress() {
    return sbAsyncOperationsService.nbActiveOperations(sbAsyncOperationsService.supportedOperations.DOWNLOAD_LEDES) > 0;
  }
});

import { getById as getExpenseById } from '@sb-billing/redux/expenses';
import { featureActive } from '@sb-itops/feature';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { OPERATING_CHEQUE_DETAILS_MODAL_ID } from 'web/components';

angular.module('sb.billing.webapp').controller('sbViewMatterExpensesController', function ($scope, $state, $uibModal, $stateParams, sbLinkService, sbAsyncOperationsService) {
  'use strict';

  var that = this;

  that.view = {
    matterId: $stateParams.matterId
  };

  that.showExpenseModal = showExpenseModal;
  that.onChequeClick = onChequeClick;
  that.onClickLink = sbLinkService.onClickLink;
  that.onPrintCheque = (chequeId) => sbAsyncOperationsService.startOperatingChequeCreation([chequeId]);
  that.onExportExpenseCsv = sbAsyncOperationsService.startExportExpenseCsv;

  function openExpenseModal(preFill, features) {
    $uibModal.open({
      templateUrl: 'ng-components/expense-entry/modal/expense-modal.html',
      controller: 'SbExpenseModalController',
      resolve: {
        sbExpenseModalConfig: function () {
          return {
            preFill: preFill,
            features: features
          };
        }
      },
      backdrop: 'static',
    });
  }

  function onChequeClick(chequeId) {
    if (featureActive('BB-11425')) {
      setModalDialogVisible({
        modalId: OPERATING_CHEQUE_DETAILS_MODAL_ID,
        props: {
          chequeId,
        },
      });

      return;
    }

    $uibModal.open({
      templateUrl: 'ng-components/operating-cheque-details/modal/operating-cheque-details-modal.html',
      controller: 'sbOperatingChequeDetailsModalController',
      size: 'lg',
      resolve: {
        chequeId: () => chequeId,
      },
      backdrop: 'static',
    });
  }

  function showExpenseModal() {
    openExpenseModal($stateParams.matterId, { matterMode: true, saveAndNew: true });
  }

  that.onClickLink = sbLinkService.onClickLink;

  that.onCreateInvoice = (matterId) => $state.go('home.billing.create-bill', { matterId });

  // Until the modal is converted to React + Load on Demand we need these functions

  that.onEditApolloExpenseInModal = (apolloExpense) => {
    const legacyExpense = getExpenseById(apolloExpense.id);

    const preFillData = {
      ...legacyExpense,
      invoiceNumber: apolloExpense.invoice && apolloExpense.invoice.invoiceNumber,
      date: apolloExpense.expenseDate,
      staffId: legacyExpense.expenseEarnerStaffId,
      matter: legacyExpense.matterId,
      activityCode: legacyExpense.expenseActivityId,
      // defined as 'units' for legacy purposes (expense-entry-controller),
      // should be changed to 'quantity' when refactoring the expense modal
      units: legacyExpense.quantity / 100,  // TODO: decimal-operation
      // defined as 'rate' for legacy purposes (expense-entry-controller),
      // should be changed to 'price' when refactoring the expense modal
      rate: legacyExpense.price / 100, // TODO: decimal-operation
      billable: !!legacyExpense.isBillable,
      waived: !!legacyExpense.waived,
    };

    openExpenseModal(preFillData, {  matterMode: true, update: true, noClearForm: true, useCommandDispatcher: true });
  };
  // End of Load on Demand
});

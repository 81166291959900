const { isValidDepositSlipTransaction } = require('@sb-billing/business-logic/deposit-slips/services');

angular.module('sb.billing.webapp').component('sbDataDeposits', {
  require: { composer: '^sbCompose' },
  bindings: { filter: '<?', showReconciled: '<?', entityDataKey: '@' },
  controller: function ($scope, sbLoggerService, sbTransactionService) {
    'use strict';
    const that = this;
    const log = sbLoggerService.getLogger('sbDataDeposits');
    const entityDataKey = that.entityDataKey || 'deposits';

    // log.setLogLevel('info');

    that.$onChanges = (changes) => {
      log.info('bindings changed:', changes);
      updateTransactions();
    };

    $scope.$on('smokeball-data-update-sbTransactionService', () => {
      log.info('saw data update');
      updateTransactions();
    });

    $scope.$on('funds-deposited', () => {
      log.info('saw funds deposited');
      updateTransactions();
    });

    function updateTransactions () {
      if (that.filter) {
        let deposits = sbTransactionService.getByFilter((tx) => isValidDepositSlipTransaction(tx) && that.filter(tx)) || [];

        log.info('deposits: ', deposits);
        that.composer.setComposeData(deposits, entityDataKey);
      }
    }
  }
});

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Spinner, useTranslation, Icon } from '@sb-itops/react';
import React from 'react';
import classNames from 'classnames';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { NoBillingAccessWarning } from '@sb-billing/react';
import {
  CREDIT_NOTE_MODAL_ID,
  ADD_PAYMENT_MODAL_ID,
  ADD_TIME_ENTRY_MODAL_ID,
  DEPOSIT_FUNDS_MODAL_ID,
} from 'web/react-redux';
import { ADD_PAYMENT_MODAL_ID as ADD_PAYMENT_NEW_MODAL_ID } from 'web/components';
import { featureActive } from '@sb-itops/feature';
import { WidgetTitle } from './libs';
import * as WidgetStyles from './Widgets.module.scss';
import * as Styles from './BillableOverviewWidget.module.scss';

export const BillableOverviewWidget = ({
  hasBillingAccess,
  isEditing,
  isStatutoryDepositMatter,
  creditTooltip,
  showTrustAccount,
  trustTooltip,
  matterId,
  onFilterByRecent,
  onFilterInvoiceStatus,
  totals,
  onClickLink,
  loading,
  onChange,
  onRemove,
  settings,
  showFeeEstimate,
  showControlledMoneyAccount,
  trustRetainerBelowLimit,
  feeEstimateTooltip,
  unpaidTooltip,
  feeEstimateWarning,
  defaultTrustAccount,
  defaultCMA,
  defaultOperatingAccount,
}) => {
  const { t } = useTranslation();
  const useNewAddPaymentModal = featureActive('BB-13936'); // use LOD version of Add Payment modal

  return (
    <div className={classNames(WidgetStyles.container, Styles.container)}>
      <WidgetTitle isEditing={isEditing} onChange={onChange} onRemove={onRemove} settings={settings} />
      {hasBillingAccess &&
        (loading ? (
          <div className={WidgetStyles.loading}>
            <Spinner small />
          </div>
        ) : (
          <div className={classNames(WidgetStyles.content, Styles.content)}>
            {!isStatutoryDepositMatter && (
              <div className={Styles.item}>
                <div className={Styles.box} onClick={() => onClickLink({ type: 'matterFees', id: matterId })}>
                  <div className={Styles.title}>UNBILLED</div>
                  <div className={Styles.number}>{t('cents', { val: totals.UNBILLED })}</div>
                </div>
                <div className={Styles.link}>
                  <a onClick={() => setModalDialogVisible({ modalId: ADD_TIME_ENTRY_MODAL_ID, props: { matterId } })}>
                    ADD TIME ENTRY
                  </a>
                </div>
              </div>
            )}
            {!isStatutoryDepositMatter && (
              <div className={Styles.item}>
                <div
                  className={classNames(Styles.box, WidgetStyles.hoverTooltip)}
                  onClick={() => {
                    onFilterByRecent(false);
                    onFilterInvoiceStatus(['FINAL', 'OVERDUE']);
                    onClickLink({ type: 'matterBills', id: matterId });
                  }}
                >
                  <div className={Styles.title}>UNPAID</div>
                  <div className={Styles.number}>{t('cents', { val: totals.UNPAID })}</div>
                  {unpaidTooltip}
                </div>
                <div className={Styles.link}>
                  {useNewAddPaymentModal ? (
                    <a
                      onClick={() =>
                        setModalDialogVisible({
                          modalId: ADD_PAYMENT_NEW_MODAL_ID,
                          props: { scope: 'BillableOverviewWidget/add-payment-modal', matterId },
                        })
                      }
                    >
                      ADD PAYMENT
                    </a>
                  ) : (
                    <a onClick={() => setModalDialogVisible({ modalId: ADD_PAYMENT_MODAL_ID, props: { matterId } })}>
                      ADD PAYMENT
                    </a>
                  )}
                </div>
              </div>
            )}
            {showTrustAccount && (
              <div className={Styles.item}>
                <div
                  className={classNames(Styles.box, WidgetStyles.hoverTooltip)}
                  onClick={() => {
                    onClickLink({ type: 'matterTransactions', id: 'trust', params: [{ matterId }] });
                  }}
                >
                  <div className={Styles.title}>
                    {t('trust').toUpperCase()}
                    {trustRetainerBelowLimit && (
                      <Icon className={Styles.icon} type="icon-fat-exclamation-circle-filled" color="#EC5B57" />
                    )}
                  </div>
                  <div className={classNames(Styles.number, trustRetainerBelowLimit && Styles.redText)}>
                    {t('cents', { val: totals.TRUST })}
                  </div>
                  {trustTooltip}
                </div>
                <div
                  onClick={() =>
                    setModalDialogVisible({
                      modalId: DEPOSIT_FUNDS_MODAL_ID,
                      props: { matterId, bankAccountId: defaultTrustAccount },
                    })
                  }
                  className={Styles.link}
                >
                  <a>ADD DEPOSIT</a>
                </div>
              </div>
            )}
            {showControlledMoneyAccount && (
              <div className={Styles.item}>
                <div
                  className={Styles.box}
                  onClick={() => {
                    onClickLink({
                      type: 'matterTransactions',
                      id: 'controlled-money',
                      params: [{ matterId, cmaId: 'all' }],
                    });
                  }}
                >
                  <div className={Styles.title}>
                    {t('CMA')}
                    {totals.CONTROLLEDMONEY < 0 && (
                      <Icon className={Styles.icon} type="icon-fat-exclamation-circle-filled" color="#EC5B57" />
                    )}
                  </div>
                  <div className={classNames(Styles.number, totals.CONTROLLEDMONEY < 0 && Styles.redText)}>
                    {t('cents', { val: totals.CONTROLLEDMONEY })}
                  </div>
                </div>
                <div
                  onClick={() =>
                    setModalDialogVisible({
                      modalId: DEPOSIT_FUNDS_MODAL_ID,
                      props: { matterId, bankAccountId: defaultCMA },
                    })
                  }
                  className={Styles.link}
                >
                  <a>ADD DEPOSIT</a>
                </div>
              </div>
            )}
            {!isStatutoryDepositMatter && totals.CREDIT > 0 && (
              <div className={Styles.item}>
                <div
                  className={classNames(Styles.box, WidgetStyles.hoverTooltip)}
                  onClick={() => {
                    onClickLink({ type: 'matterTransactions', id: 'credit-note-ledger', params: [{ matterId }] });
                  }}
                >
                  <div className={Styles.title}>CREDIT</div>
                  <div className={Styles.number}>{t('cents', { val: totals.CREDIT })}</div>
                  {creditTooltip}
                </div>
                <div className={Styles.link}>
                  <a onClick={() => setModalDialogVisible({ modalId: CREDIT_NOTE_MODAL_ID, props: { matterId } })}>
                    ADD CREDIT
                  </a>
                </div>
              </div>
            )}
            {!isStatutoryDepositMatter && totals.OPERATING > 0 && (
              <div className={Styles.item}>
                <div
                  className={Styles.box}
                  onClick={() => {
                    onClickLink({ type: 'matterTransactions', id: 'operating', params: [{ matterId }] });
                  }}
                >
                  <div className={Styles.title}>{t('operatingRetainer')}</div>
                  <div className={Styles.number}>{t('cents', { val: totals.OPERATING })}</div>
                </div>
                <div
                  onClick={() =>
                    setModalDialogVisible({
                      modalId: DEPOSIT_FUNDS_MODAL_ID,
                      props: { matterId, bankAccountId: defaultOperatingAccount },
                    })
                  }
                  className={Styles.link}
                >
                  <a>ADD DEPOSIT</a>
                </div>
              </div>
            )}
            {showFeeEstimate && (
              <div className={Styles.item}>
                <div
                  className={classNames(Styles.box, WidgetStyles.hoverTooltip)}
                  onClick={() => {
                    onClickLink({ type: 'matterFees', id: matterId });
                  }}
                >
                  <div className={Styles.title}>
                    FEE/ESTIMATE
                    {feeEstimateWarning && (
                      <Icon
                        className={Styles.icon}
                        type="icon-fat-exclamation-circle-filled"
                        color={feeEstimateWarning}
                      />
                    )}
                    {feeEstimateTooltip}
                  </div>
                  <div className={Styles.number}>{t('cents', { val: totals.FEE_ESTIMATE })}</div>
                </div>
              </div>
            )}
          </div>
        ))}
      {!hasBillingAccess && <NoBillingAccessWarning />}
    </div>
  );
};

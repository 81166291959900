/**
 * NOTICE:
 *
 * This is a Load on Demand compatible route container, meaning that neither
 * this container nor any of the sub-component/containers should have a
 * dependency on:
 * - Entity caches
 * - Angular services
 */
import PropTypes from 'prop-types';
import composeHooks from '@sb-itops/react-hooks-compose';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { useDispatch, useSelector } from 'react-redux';
import { dateRangeTypes } from '@sb-itops/date';
import { featureActive } from '@sb-itops/feature';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import * as messageDisplay from '@sb-itops/message-display';
import * as firmOperatingCheques from 'web/redux/route/firm-accounts-transactions-operating-cheques/feature';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { OperatingChequeCountData, OperatingChequeListData } from 'web/graphql/queries';
import { useSubscribedQuery, usePagination } from 'web/hooks';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import { PRINT_OPERATING_CHEQUE_MODAL_ID as LOD_PRINT_OPERATING_CHEQUE_MODAL_ID } from 'web/components';
import * as billingAccountsTransactions from 'web/redux/route/home-billing-accounts-transactions';
import { useSort } from '@sb-itops/redux/sort/use-sort';
import FirmAccountsTransactionsOperatingCheques from './FirmAccountsTransactionsOperatingCheques';

const reportType = 'operating-cheques';
const PRINT_OPERATING_CHEQUE_MODAL_ID = 'print-operating-cheque-modal';
const OPERATING_CHEQUE_LIST_SCOPE = 'firm-accounts-transactions-operating-cheque-list';
const FETCH_LIMIT = 50;

const hooks = (props) => ({
  useChequeActions: () => {
    const operatingChequesQueryResult = useSubscribedQuery(OperatingChequeCountData, {
      variables: {
        offset: 0,
        filter: {
          isPrinted: false,
          isReversed: false,
        },
        // Whilst we are only fetching the totals, this value does not matter
        // However, if we need to fetch the relevant IDs, it may well be different
        // from the page FETCH_LIMIT
        limit: FETCH_LIMIT,
      },
    });

    const { data: chequeData } = operatingChequesQueryResult;
    const printChequeCount = chequeData?.operatingChequeList?.totalCount || 0;
    return {
      printChequeCount,
    };
  },
  useSort: () => {
    const { sortBy, setSortBy, sortDirection, setSortDirection } = useSort({
      scope: OPERATING_CHEQUE_LIST_SCOPE,
      initialSortBy: 'chequeDate',
      initialSortDirection: 'desc',
    });

    const onSort = (sortProps) => {
      setSortBy(sortProps.sortBy);
      setSortDirection(sortProps.sortDirection);
    };

    return {
      sortBy,
      sortDirection,
      onSort,
    };
  },
  useSelectors: () => {
    const dispatch = useDispatch();
    const { selectors, actions } = firmOperatingCheques;
    const { setExportState } = actions;
    const { getReportGenerationInProgress } = selectors;

    const reportGenerationInProgress = useSelector(getReportGenerationInProgress);

    const { showHidden: showDeletedTransactions, dateListFilter } = useSelector(
      billingAccountsTransactions.selectors.getFilters,
    );
    const { showSystemDate } = useSelector(() => billingAccountsTransactions.toggleShowSystemDateApi.getState());

    return {
      showSystemDate,
      showChequeMemo: hasFacet(facets.chequeMemo),
      reportGenerationInProgress,
      onShowPrintChequesModal: () => {
        if (featureActive('BB-13415')) {
          // LOD print operating cheques modal, expecting different props
          const { sbAsyncOperationsService } = props;
          setModalDialogVisible({ modalId: LOD_PRINT_OPERATING_CHEQUE_MODAL_ID, props: { sbAsyncOperationsService } });
        } else {
          setModalDialogVisible({ modalId: PRINT_OPERATING_CHEQUE_MODAL_ID });
        }
      },
      onExportCheques: async ({ sortBy, sortDirection }) => {
        const { sbReportingService, sbAsyncOperationsService } = props;
        const reportConfig = {
          reportType,
          runByEnteredDate: showSystemDate,
          showDeletedTransactions,
          sortBy,
          sortDirection,
        };

        const dates = getFilterDates(dateListFilter);
        if (dates) {
          reportConfig.fromDate = dates.startDate;
          reportConfig.toDate = dates.endDate;
        }

        dispatch(setExportState({ reportGenerationInProgress: true }));

        try {
          const { reportGenerationId } = await sbReportingService.generateReportP(reportConfig);
          dispatch(setExportState({ reportGenerationInProgress: false }));
          sbAsyncOperationsService.startGenerateReport('csv', {
            reportType,
            reportGenerationId,
          });
        } catch (err) {
          messageDisplay.error(
            messageDisplay
              .builder()
              .text('Failed to export CSV')
              .conditionalText('{0}', err.message ? `: ${err.message}` : ''),
          );
          dispatch(setExportState({ reportGenerationInProgress: false }));
        }
      },
    };
  },
});

// These hooks will be called once the hooks above have completed, and will
// receive their props. This is required as we cannot retrieve values from
// adjacent hooks in composeHooks
const dependentHooks = ({ sortBy, sortDirection }) => ({
  useChequeData: () => {
    const { showHidden: showDeletedTransactions, dateListFilter } = useSelector(
      billingAccountsTransactions.selectors.getFilters,
    );
    const operatingChequeListFilter = getFilters({ showDeletedTransactions, dateListFilter });

    const {
      currentPage: currentOperatingChequePage,
      setPageNumber,
      getPagination,
    } = usePagination({
      scope: `${OPERATING_CHEQUE_LIST_SCOPE}-pagination`,
      fetchLimit: FETCH_LIMIT,
    });

    const onOperatingChequeListPageChange = ({ selected: pageNumber }) => setPageNumber(pageNumber);

    const operatingChequesQueryResult = useSubscribedQuery(OperatingChequeListData, {
      variables: {
        filter: operatingChequeListFilter,
        offset: currentOperatingChequePage * FETCH_LIMIT,
        limit: FETCH_LIMIT,
        sort: !sortBy ? undefined : { fieldNames: [sortBy], directions: [`${sortDirection || 'ASC'}`.toUpperCase()] },
      },
    });

    const { data: chequeData } = operatingChequesQueryResult;
    const chequeList = chequeData?.operatingChequeList?.results || [];

    const {
      totalCount: operatingChequesCount,
      hidePagination,
      totalNumberOfPages: totalNumberOfOperatingChequePages,
    } = getPagination({
      totalCount: chequeData?.operatingChequeList?.totalCount,
      loading: operatingChequesQueryResult.loading,
    });

    return {
      currentOperatingChequePage,
      cheques: chequeList,
      chequesLoading: chequeList.length === 0 && operatingChequesQueryResult.loading,
      operatingChequesCount,
      onOperatingChequeListPageChange,
      totalNumberOfOperatingChequePages,
      hidePagination,
    };
  },
});

const getFilters = ({ showDeletedTransactions, dateListFilter }) => {
  const filter = {
    isHidden: false,
  };

  if (showDeletedTransactions) {
    delete filter.isHidden;
  }

  if (dateListFilter) {
    const filterDates = getFilterDates(dateListFilter);
    filter.chequeDate = {
      from: filterDates.startDate,
      to: filterDates.endDate,
    };
  }
  return filter;
};

const getFilterDates = (filters) => {
  const firstOfMonth = moment(moment().format('YYYYMM'), 'YYYYMM');
  const dates = {};

  switch (filters.restrictDate) {
    case dateRangeTypes.BEFORE:
      dates.startDate = 0;
      dates.endDate = formatDate(moment(filters.before, 'YYYYMMDD').add(-1, 'day').toDate());
      break;
    case dateRangeTypes.CUSTOM:
      dates.startDate = formatDate(moment(filters.startDate, 'YYYYMMDD').toDate());
      dates.endDate = formatDate(moment(filters.endDate, 'YYYYMMDD').toDate());
      break;
    case dateRangeTypes.THIS_MONTH:
      dates.startDate = formatDate(moment(firstOfMonth).toDate());
      dates.endDate = formatDate(moment(firstOfMonth).add(1, 'month').add(-1, 'day').toDate());
      break;
    case dateRangeTypes.LAST_MONTH:
      dates.startDate = formatDate(moment(firstOfMonth).add(-1, 'month').toDate());
      dates.endDate = formatDate(moment(firstOfMonth).add(-1, 'day').toDate());
      break;
    default:
  }

  return dates;
};

const formatDate = (inDate) =>
  Object.prototype.toString.call(inDate) === '[object Date]' ? +moment(inDate).format('YYYYMMDD') : NaN;

const FirmAccountsTransactionsOperatingChequesContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(composeHooks(dependentHooks)(FirmAccountsTransactionsOperatingCheques))),
);

FirmAccountsTransactionsOperatingChequesContainer.displayName = 'FirmAccountsTransactionsOperatingChequesContainer';

FirmAccountsTransactionsOperatingChequesContainer.propTypes = {
  onClickLink: PropTypes.func,
  onClickRow: PropTypes.func,
  onPrintCheque: PropTypes.func,
};
FirmAccountsTransactionsOperatingChequesContainer.defaultProps = {
  onClickLink: undefined,
  onClickRow: undefined,
  onPrintCheque: undefined,
};

export default FirmAccountsTransactionsOperatingChequesContainer;

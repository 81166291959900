import { store } from '@sb-itops/redux';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { split as splitDescription, interpolate as interpolateDescription } from '@sb-billing/interpolate-description';
import { getInvoiceNumberById } from '@sb-billing/redux/invoices';
import { getMatterDisplayById, getById as getMatterById } from '@sb-matter-management/redux/matters';
import { isClosedMatter } from '@sb-matter-management/business-logic/matters/services';
import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import { getBankAccountName, isBankAccountClosed } from '@sb-billing/business-logic/bank-account/services';
import { bankAccountTypeEnum } from '@sb-billing/business-logic/bank-account/entities/constants';
import { localiseDescription } from '@sb-billing/transaction-descriptions';
import { isReconciled } from '@sb-billing/redux/bank-reconciliations';
import { getConsolidatedTrustTransactionById } from '@sb-billing/redux/transactions';
import {
  BANK_BALANCE_TYPE,
  getBalanceType,
  isMatterContactBalanceType,
  isMatterBalanceType,
} from '@sb-billing/redux/bank-account-settings';
import { getMap } from '@sb-billing/redux/bank-account-balances';
import { selectors } from '@sb-billing/redux/bank-account-balances.2';
import { getById as getBankAccountById, getTrustAccount } from '@sb-billing/redux/bank-account';
import { reverseBankFee, reverseBankInterest } from '@sb-billing/redux/transactions';
import { selectors as supportDebugSelectors } from 'web/redux/route/billing-support-debug';
const { getMatterContactBalance, getMatterBalance, getFirmBalance } = selectors;
import { bankTransferTypeEnum } from '@sb-billing/business-logic/accounts/payment/bank-transfer-types';
import {
  getById as getTrustChequeById,
} from '@sb-billing/redux/trust-cheques';
import {
  getById as getPaymentById,
  getPaymentsByMultiPaymentId
} from '@sb-billing/redux/payments';
import { balanceTypes } from '@sb-billing/business-logic/bank-account-balances/entities/constants';
import { capitalize } from '@sb-itops/nodash';
import { isReversible } from '@sb-billing/business-logic/transactions/services';
import strategy from './bank-account-transaction-details-strategy';

angular.module('sb.billing.webapp').controller('SbBankAccountTransactionDetailsController', function ($scope, sbLoggerService, sbTransactionService, sbPaymentService, sbFirmManagementMbService,
  sbDateService, sbVendorPaymentsService, sbMessageDisplayService, focusService, sbLocalisationService, sbAsyncOperationsService, sbBankAccountService, sbLinkService) {

  const {
    Deposit,
    DepositReversal,
    InvoicePayment,
    MatterAdjustment,
    MatterAdjustmentReversal,
    VendorPayment,
    BankFees,
    BankFeesReversal,
    Interest,
    InterestReversal,
  } = sbTransactionService.getTransactionTypes();
  const that = this;
  const log = sbLoggerService.getLogger('SbBankAccountTransactionDetailsController');
  const trustBankAccountId = getTrustAccount().id;

  that.view = {
    isReverseTransactionCollapsed: true,
    showDebug: supportDebugSelectors.getShowDebug(store.getState()),
  };

  that.errors = {};
  that.model = {};
  that.onClickLink = (...args) => {
    sbLinkService.onClickLink(...args);
    closeModal();
  }
  that.t = sbLocalisationService.t;
  that.isTransactionReversible = isTransactionReversible;
  that.toggleReversalUI = toggleReversalUI;
  that.closeModal = closeModal;
  that.processReversal = processReversal;
  that.validate = validate;
  that.getDescription = getDescription;
  that.getSource = getSource;
  that.getFormerStaffName = getFormerStaffName;
  that.hidePaidByField = hidePaidByField;
  that.hideInternalNote = hideInternalNote;
  that.showReasonField = showReasonField;
  that.isTransferTransaction = isTransferTransaction;
  that.isTrustToOffice = isTrustToOffice;
  that.isPrintable = isPrintable;
  that.getCheckMemo = getCheckMemo;
  that.getReason = getReason;
  that.isMatterContactBalanceType = isMatterContactBalanceType;
  that.showBankDetails = showBankDetails;
  that.showPayeeDetails = showPayeeDetails;
  that.downloadAsPdf = downloadAsPdf;
  that.showBpay = showBpay;
  that.showSource = showSource;
  that.isTransactionReversibleForMatter = isTransactionReversibleForMatter;
  that.isTransactionReversibleForBankAccount = isTransactionReversibleForBankAccount;

  loadTransaction();

  // get the transaction source from the current ctrl.view
  function getViewTransactionSource() {
    if (!that.view.transaction) {
      return undefined;
    }
    
    const bankAccount = that.view.transaction.bankAccountId && getBankAccountById(that.view.transaction.bankAccountId);
    return that.view.transaction.source || (bankAccount && getBankAccountName(bankAccount, sbLocalisationService.t));
  }

  /**
 * Get the cheque Id from the transaction, if it is a reversed transaction will return the cheque id from the original transaction.
 * @param {Transaction} transaction object
 * @return {uuid|undefined} uuid of the cheque id or undefined if the transaction does not have a cheque associated
 */
  function getChequeIdFromTransaction(transaction) {
    if (transaction.reversedFromTransactionId) {
      const reversedTransaction = sbTransactionService.getById(transaction.reversedFromTransactionId);
      return reversedTransaction.chequeId;
    }
    // Trust to Office
    if (transaction.reversedFromTransactionIds && transaction.reversedFromTransactionIds.length) {
      // Retrieve any transaction as they will all share the same chequeId
      const reversedTransaction = sbTransactionService.getById(transaction.reversedFromTransactionIds[0]);
      return reversedTransaction.chequeId;
    }
    return transaction.chequeId;
  }

  function loadTransaction() {
    log.info('loading transaction');
    that.view.transactionId = $scope.transactionId;
    that.view.matterId = $scope.matterId;
    that.view.contactId = $scope.contactId;
    that.view.showHidden = $scope.showHidden;

    if (that.view.transactionId) {
      // when the transaction is passed and we cannot find the transaction in the transaction list, it is because it is a
      // consolidated trust to office transaction, or an automatically generated transaction from the payment.
      that.view.transaction = sbTransactionService.getById(that.view.transactionId) ||
        getConsolidatedTrustTransactionById(that.view.transactionId, that.t, { matterId: that.view.matterId, contactId: that.view.contactId, showHidden: that.view.showHidden }) ||
        $scope.transaction;

      if (that.view.transaction && that.view.transaction.isTrustToOffice && (that.view.matterId || that.view.contactId)) {
        that.view.firmLevelTransaction = getConsolidatedTrustTransactionById(that.view.transactionId, that.t, { showHidden: that.view.showHidden });
      }
    }

    if (that.view.transaction) {
      that.view.originalNote = that.view.transaction.note;
      that.view.staff = getStaffMember();
      that.view.transaction.effectiveDateDisplay = sbDateService.from(that.view.transaction.effectiveDate);
      if (that.view.transaction.type === 'VendorPayment') {
        that.view.vendorPayment = sbVendorPaymentsService.getById(that.view.transaction.paymentId);
      }

      that.view.isTransactionFromCheque = !!getChequeIdFromTransaction(that.view.transaction);
    }

    if (that.view.transaction && that.view.transaction.isTrustToOffice) {
      const multiPayments = that.view.transaction.multiPaymentId
        ? getPaymentsByMultiPaymentId(that.view.transaction.multiPaymentId)
        : [getPaymentById(that.view.transaction.paymentIds[0])];

      // Prepare data for the payment invoices table
      that.view.paymentIds = multiPayments.map(({ paymentId }) => paymentId);

      // Matter and Contact view
      if (that.view.matterId || that.view.contactId) {
        // Matter total for Trust to Office
        const trustToOfficeLabel = sbLocalisationService.t('trustToOfficeTransferLabel');
        that.view.trustToOfficeTotalLabel = `Total amount of this ${trustToOfficeLabel}`;
        that.view.multiPaymentTotalAmount = multiPayments.reduce((acc, payment) => acc + payment.totalAmount, 0);

        // Payments always show positive amounts, however the transaction may be a debit
        if (that.view.transaction.amount < 0) {
          that.view.multiPaymentTotalAmount = -that.view.multiPaymentTotalAmount;
        }
      }
    }

    if (!that.view.paymentIds && that.view.transaction && that.view.transaction.paymentId) {
      that.view.paymentIds = [that.view.transaction.paymentId];
    }

    log.info('loaded transaction', that.view.transaction);
  }

  function getCheckMemo() {
    if (that.view.isTransactionFromCheque) {
      const chequeId = getChequeIdFromTransaction(that.view.transaction);
      const trustCheque = getTrustChequeById(chequeId);
      return trustCheque && trustCheque.chequeMemo;
    }
    return undefined;
  }

  function getStaffMember() {
    if (!that.view.transaction && !that.view.transaction.userId) {
      return;
    }

    return _.first(sbFirmManagementMbService.getByUserIds([that.view.transaction.userId]));
  }

  function getFormerStaffName(formerUserId) {
    if (!formerUserId) {
      return formerUserId;
    }
    const formerStaff = sbFirmManagementMbService.getFormerStaffByUserId(formerUserId);
    return formerStaff && `${formerStaff.name} (former staff)` || null;
  }

  function isTransactionReversible() {
    if (!that.view.transaction) {
      return false;
    }

    // reversal transaction will have effective date of TODAY so check that is valid
    const reversibleIfTrust =
      that.view.transaction.bankAccountType &&
      that.view.transaction.bankAccountType.toUpperCase() === bankAccountTypeEnum.TRUST
        ? !isReconciled({
            yyyymmdd: moment().format('YYYYMMDD'),
            trustAccountId: that.view.transaction.bankAccountId,
          })
        : true;

    const reconciled =
      that.view.transaction.isTrustToOffice
        ? that.view.transaction.transactionIds.every(id => sbTransactionService.isReconciled(id))
        : sbTransactionService.isReconciled(that.view.transaction.id);

    return reversibleIfTrust && !reconciled && isTransactionReversibleByType();
  }

  // Currently, this is only used to disable trust transaction reversal in AU/UK when matter is closed
  function isTransactionReversibleForMatter() {
    const isTrust =
      that.view.transaction.bankAccountType &&
      that.view.transaction.bankAccountType.toUpperCase() === bankAccountTypeEnum.TRUST;
  
    if (!hasFacet(facets.blockTrustTransactionsOnClosedMatter) || !isTrust) {
      return true;
    }
  
    // TTO has special handling
    if (that.view.transaction.isTrustToOffice) {
      return (that.view.transaction.matterIds || []).every((matterId) => !isClosedMatter(getMatterById(matterId)));
    }
  
    // trust transactions in AU/GB are not reversible if matter is closed
    return !isClosedMatter(getMatterById(that.view.transaction.matterId));
  }
  
  // Currently, this is only used to disable CMA transaction reversal in AU/UK when bank account is closed
  function isTransactionReversibleForBankAccount() {
    const bankAccount = getBankAccountById(that.view.transaction.bankAccountId);
    const bankAccountIsClosed = isBankAccountClosed({ bankAccount });
    const isCMA = bankAccount.accountType === bankAccountTypeEnum.CONTROLLEDMONEY;

    // we can't reverse CMA transaction if the bank account is closed
    return isCMA ? !bankAccountIsClosed : true;
  }

  function isTransactionReversibleByType() {
    return isReversible({
      transaction: that.view.transaction,
      paymentIds: that.view.paymentIds,
      isReversiblePaymentFn: sbPaymentService.isReversiblePayment,
    });
  }

  // txn deletion UI toggle uses this function too, so set the delete txn flag based off of the `opts` parameter
  function toggleReversalUI(opts = {}) {
    that.view.isReverseTransactionCollapsed = !that.view.isReverseTransactionCollapsed;

    that.model.deleteTx = opts.deleteTx;

    that.view.showReverseWarning = hasFacet(facets.transactionReverseWarning) && !opts.deleteTx;

    if (!that.view.isReverseTransactionCollapsed) {
      focusService.focusOn('reason-field');
    }
  }

  function downloadAsPdf() {
    const transaction = that.view.transaction;
    const showDebtors = isMatterContactBalanceType();

    const invoicePayments = that.view.paymentIds.reduce((acc, paymentId) => {
      const payment = getPaymentById(paymentId);

      if (!payment || !payment.invoices.length > 0) {
        return acc;
      }

      payment.invoices.forEach(({ invoiceId, amount }) => {
        acc.push({
          matterDisplay: getMatterDisplayById(payment.matterId),
          payorDisplay: showDebtors && getContactDisplay(payment.payorId, { showLastNameFirst: true }),
          invoiceNumber: getInvoiceNumberById(invoiceId),
          amount,
        });
      });

      return acc;
    }, []);

    const descriptionParts = splitDescription(getDescription({ useFirmLevelDescription: true }));
    const interpolatedDescription = interpolateDescription(descriptionParts, (type, id) => `#${getInvoiceNumberById(id)}`).replaceAll(' ,', ',');
    const multiPaymentTotalAmount = that.view.multiPaymentTotalAmount;
    
    const trustBankAccount = getBankAccountById(transaction.bankAccountId);
    const sourceAccountName = (trustBankAccount && trustBankAccount.accountName) || sbLocalisationService.t('trustAccount');
    const sourceBranchNumber = (hasFacet(facets.sourceAccountDetails) && trustBankAccount && trustBankAccount.branchNumber) || "";
    const sourceAccountNumber = (hasFacet(facets.sourceAccountDetails) && trustBankAccount && trustBankAccount.accountNumber) || "";

    sbAsyncOperationsService.startDownloadTransactionDetailsPdf({
      amount: multiPaymentTotalAmount || transaction.amount,
      effectiveDate: transaction.effectiveDate,
      description: interpolatedDescription,
      isHidden: transaction.isHidden,
      reference: transaction.reference,
      reason: getReason(transaction.reason),
      memo: hasFacet(facets.chequeMemo) && getCheckMemo(),
      showDebtors,
      processedBy: `${(that.view.staff && that.view.staff.name) || that.getFormerStaffName(transaction.userId)}`,
      processedDate: moment(transaction.timestamp).format('YYYY-MM-DD'),
      processedTime: moment(transaction.timestamp).format('hh:mm:ss A'),
      source: that.getSource(),
      isTrustToOffice: true,  // download as PDF only support for TTO atm.
      otherBankAccountName: transaction.otherBankAccountName, // destination
      bankBranchNumber: transaction.bankBranchNumber, // destination
      otherBankAccountNumber: transaction.otherBankAccountNumber, // destination
      invoicePayments,
      sourceAccountName,
      sourceBranchNumber,
      sourceAccountNumber,
    });

    closeModal();
  }

  function hasSufficientFunds(bankAccountId) {
    // Statutory deposit matter can go negative
    // We don't care about protected funds for statutory deposits - law society still wants their money even if it's 'protected'
    // hence we use balanceTypes.BALANCE instead of balanceTypes.AVAILABLE
    if (hasFacet(facets.allowOverdraw) && sbBankAccountService.isStatutoryDepositMatter(that.view.transaction.matterId)) {
      const firmBalance = getFirmBalance(getMap(), { bankAccountId, balanceType: balanceTypes.BALANCE });
      return that.view.transaction.amount <= firmBalance;
    }

    const filter = {
      contactId: that.view.transaction.contactId,
      matterId: that.view.transaction.matterId,
      bankAccountId,
    };
    const balanceValue = getBalance(filter);
    const hasFunds = that.view.transaction.amount <= balanceValue;

    log.info(`transaction amount: ${that.view.transaction.amount}, balance: ${balanceValue}, has sufficient funds? ${hasFunds}`);

    return hasFunds;
  }

  function getBalance({ matterId, contactId, bankAccountId }) {
    if (isMatterContactBalanceType() && matterId && contactId) {
      return getMatterContactBalance(getMap(), { matterId, contactId, bankAccountId });
    }
    if (isMatterBalanceType() && matterId) {
      return getMatterBalance(getMap(), { matterId, bankAccountId });
    }

    return 0;
  }

  function validate() {
    that.errors.reason = _.isEmpty(_.trim(that.model.reason));

    const formIsValid = Object.keys(that.errors).every(key => !that.errors[key]);
    log.info('valid?', formIsValid);
    return formIsValid;
  }

  async function processDepositReversalRequest({ bankAccountId, accountType, checkMatterBalance }) {
    try {
      await sbTransactionService.reverseDeposit({
        bankAccountId,
        accountType,
        transactionId: that.view.transaction.id,
        reason: that.model.reason,
        checkMatterBalance,
        deleteTransaction: that.model.deleteTx,
        allowOverdraw: hasFacet(facets.allowOverdraw),
      });
      sbMessageDisplayService.success(
        sbMessageDisplayService
          .builder()
          .text(`Deposit ${that.model.deleteTx ? 'deletion' : 'reversal'} processed`)
          .group('insufficient-funds')
      );
    } catch (err) {
      that.view.transaction.reversed = false;

      log.error('Problem processing reversal', err);
      sbMessageDisplayService.error(
        sbMessageDisplayService
          .builder()
          .title(`Deposit ${that.model.deleteTx ? 'deletion' : 'reversal'} not processed`)
          .text(`Failed to process ${that.model.deleteTx ? 'deletion' : 'reversal'}. Please check your connection and try again`)
      );
    }
  }

  async function processDepositReversal() {
    log.info(`process deposit reversal`);
    const bankAccount = getBankAccountById(that.view.transaction.bankAccountId);
    const accountType = bankAccount.accountType; // uppercase
    let checkMatterBalance;

    let hasFunds = hasSufficientFunds(that.view.transaction.bankAccountId);
    let errorMessage = `There are insufficient funds in the account to process the ${that.model.deleteTx ? 'deletion' : 'reversal'}`;

    // in AU we allow over draw on reversals, for compliance
    if (hasFunds || hasFacet(facets.allowOverdraw)) {
      log.info('proceeding with request: ');
      processDepositReversalRequest({ bankAccountId: bankAccount.id, accountType, checkMatterBalance });
    } else {
      sbMessageDisplayService.error(
        sbMessageDisplayService
          .builder()
          .title('Reversal not processed')
          .text(errorMessage)
      );
    }

    closeModal();
  }

  function processVendorPaymentReversal() {
    const bankAccount = getBankAccountById(that.view.transaction.bankAccountId);
    const accountType = bankAccount.accountType; // uppercase

    log.info(`process vendor payment reversal: ${accountType} transaction ${that.view.transaction.id} reason of ${that.model.reason}`);
    sbVendorPaymentsService.reversalPayToVendorP({
      accountType,
      bankAccountId: bankAccount.id,
      transactionId: that.view.transaction.id,
      reason: that.model.reason,
      deleteTransaction: that.model.deleteTx
    })
      .then(() => {
        closeModal();
        sbMessageDisplayService.success(
          sbMessageDisplayService
            .builder()
            .text(`Payment ${that.model.deleteTx ? 'deletion' : 'reversal'} processed`)
            .group('insufficient-funds')
        );
      })
      .catch((err) => {
        closeModal();
        log.error('Problem processing reversal', err);
        sbMessageDisplayService.error(
          sbMessageDisplayService
            .builder()
            .title(`${that.model.deleteTx ? 'deletion' : 'reversal'} not processsed`)
            .text(`Failed to process payment ${that.model.deleteTx ? 'deletion' : 'reversal'}. Please check your connection and try again`)
        );
      });
  }

  async function processInvoicePaymentReversal() {
    //Reversals can over draw matter or firm trust balance in Au for compliance
    if (hasSufficientFunds(that.view.transaction.bankAccountId) || hasFacet(facets.allowOverdraw)) {
      try {
        log.info(`process invoice payment reversal: transaction ${that.view.transaction.id} reason of ${that.model.reason}`);

        let paymentId = undefined;
        let txId = that.view.transaction.id;

        // Trust to Office transaction objects contain all related transactionIds
        // We need any one of them to process reversal as the back end will collate
        // all relevant transactions by paymentId or multiPaymentId
        if (that.view.transaction.transactionIds && that.view.transaction.transactionIds.length) {
          txId = that.view.transaction.transactionIds[0];
        }

        // In some cases there is no transaction for a payment and for display
        // purposes we generate a dummy transaction from the payment. In that
        // case we need to use the payment ID and not the transaction ID for reversals
        //
        if (that.view.transaction.generatedFrom === 'PAYMENT') {
          paymentId = that.view.transaction.paymentId;
          txId = undefined;
        }

        await sbPaymentService.reverseInvoicePaymentP({
          paymentId,
          transactionId: txId,
          reason: that.model.reason,
          deleteTransaction: that.model.deleteTx,
          allowOverdraw: hasFacet(facets.allowOverdraw),
        })

        sbMessageDisplayService.success(
          sbMessageDisplayService
            .builder()
            .text(`Invoice payment ${that.model.deleteTx ? 'deletion' : 'reversal'} processed`)
        );
      }
      catch (err) {
        log.error('Problem processing reversal', err);
        sbMessageDisplayService.error(
          sbMessageDisplayService
            .builder()
            .title(`${that.model.deleteTx ? 'Deletion' : 'Reversal'} not processsed`)
            .text(`Failed to process ${that.model.deleteTx ? 'deletion' : 'reversal'}. Please check your connection and try again`)
        );
      }
    } else {
      sbMessageDisplayService.error(
        sbMessageDisplayService
          .builder()
          .title('Reversal not processed')
          .text(`There are insufficient funds in the account to process the ${that.model.deleteTx ? 'deletion' : 'reversal'}`)
      );
    }
    closeModal();
  }

  // TODO clean up the way we deal with rejections - use actual error objects etc
  function rejectReversal(type) {
    const text = type === 'insufficient funds'
      ? `There are insufficient funds in the account to process the ${that.model.deleteTx ? 'deletion' : 'reversal'}`
      : `Failed to process ${that.model.deleteTx ? 'deletion' : 'reversal'}. Please check your connection and try again.`;

    sbMessageDisplayService.error(
      sbMessageDisplayService
        .builder()
        .title(`${that.model.deleteTx ? 'Deletion' : 'Reversal'} not processsed.`)
        .text(text)
    );
  }

  // Obsolete functionality, no longer used, just here for backwards compatibility for broken testing data
  async function processAdjustmentReversal() {
    try {
      const accountType = that.view.transaction.bankAccountType;
      const firmTrustBalanceCents = getFirmBalance(getMap(), { bankAccountId: trustBankAccountId });

      if (that.view.transaction.amount > 0) {
        if (firmTrustBalanceCents < that.view.transaction.amount) {
          throw 'insufficient funds';
        }
      }

      await sbTransactionService.reverseAdjustment({
        accountType,
        transactionId: that.view.transaction.id,
        reason: that.model.reason,
        deleteTransaction: !!that.model.deleteTx,
      });

      sbMessageDisplayService.success(
        sbMessageDisplayService
          .builder()
          .text(`Adjustment ${that.model.deleteTx ? 'deletion' : 'reversal'} processed`)
      );
    } catch (err) {
      log.error('Problem processing adjustment reversal', err);
      rejectReversal(err);
    }

    closeModal();
  }


  // currently for INTEREST and BANKFEE only, CMA only
  async function processFeeInterestReversal(reversalType) {
    const bankAccount = getBankAccountById(that.view.transaction.bankAccountId);
    const transaction = {
      bankAccountId: bankAccount.id,
      accountType: bankAccount.accountType, // UPPERCASE
      transactionId: that.view.transaction.id,
      reason: that.model.reason,
      deleteTransaction: that.model.deleteTx,
      allowOverdraw: hasFacet(facets.allowOverdraw),
    }

    try {
      let successTitle = ""
      switch (reversalType) {
        case BankFeesReversal:
          successTitle = "Bank fee";
          await reverseBankFee(transaction);
          break;
        case InterestReversal:
          successTitle = "Interest";
          await reverseBankInterest(transaction);
          break;
        default:
          throw "Unsupported type";
      }

      sbMessageDisplayService.success(
        sbMessageDisplayService
          .builder()
          .text(`${successTitle} ${that.model.deleteTx ? 'deletion' : 'reversal'} processed`)
          .group('insufficient-funds')
      );
    } catch (err) {
      that.view.transaction.reversed = false;

      log.error('Problem processing reversal', err);
      sbMessageDisplayService.error(
        sbMessageDisplayService
          .builder()
          .title(`${that.model.deleteTx ? 'Deletion' : 'Reversal'} not processed`)
          .text(`Failed to process ${that.model.deleteTx ? 'deletion' : 'reversal'}. Please check your connection and try again`)
      );
    }

    closeModal();
  }

  function processReversal() {
    if (!validate()) {
      return;
    }

    log.info(`processing reversal`);
    switch (that.view.transaction.type) {
      case Deposit:
        processDepositReversal();
        break;
      case VendorPayment:
        processVendorPaymentReversal();
        break;
      case InvoicePayment:
        processInvoicePaymentReversal();
        break;
      case MatterAdjustment:
        processAdjustmentReversal();
        break;
      case BankFees:
        processFeeInterestReversal(BankFeesReversal);
        break;
      case Interest:
        processFeeInterestReversal(InterestReversal);
        break;
      default:
        log.error('Attempt to reverse transaction for unsupported type', that.view.transaction.type);
    }
  }

  function getDescription({ useFirmLevelDescription } = {}) {
    if (!that.view || !that.view.transaction) {
      return '';
    }

    if (useFirmLevelDescription && that.view.firmLevelTransaction) {
      return localiseDescription(sbLocalisationService.t, that.view.firmLevelTransaction.description);
    }

    return localiseDescription(sbLocalisationService.t, that.view.transaction.description);
  }

  // this function is already called like this, this should be `getTransactionSourceLabel`
  function getSource() {
    const source = getViewTransactionSource();

    if (source && source.toLowerCase() === 'check') {
      return capitalize(sbLocalisationService.t('cheque'));
    }
    if (source && source.toLowerCase() === 'bank check') {
      return `Bank ${capitalize(sbLocalisationService.t('cheque'))}`;
    }
    if (source && source.toLowerCase() === 'trust check') {
      return sbLocalisationService.t('trustCheque');
    }

    if (source && source.toLowerCase() === 'operating') {
      return sbLocalisationService.t('operatingRetainer');
    }

    return source;
  }

  function closeModal() {
    $scope.onClose({ dismiss: true });
  }

  function hidePaidByField() {
    if (!that.view.transaction) {
      return false;
    }
    return (
      (
        that.view.transaction.type !== Deposit
        && that.view.transaction.type !== DepositReversal
        && getBalanceType() !== BANK_BALANCE_TYPE.matterContact
      )
      || that.view.transaction.type === MatterAdjustment
      || that.view.transaction.type === MatterAdjustmentReversal
      || that.view.transaction.isTrustToOffice
    );
  }

  function hideInternalNote() {
    return strategy.hideInternalNote({ transaction: that.view.transaction });
  }

  function showReasonField() {
    return strategy.showReasonField({ transaction: that.view.transaction });
  }

  function isTrustToOffice() {
    return that.view.transaction && that.view.transaction.isTrustToOffice;
  }

  function isPrintable() {
    const { transaction } = that.view;
    return transaction && transaction.isTrustToOffice && (!transaction.reversedFromTransactionIds || transaction.reversedFromTransactionIds.length === 0);
  }

  function isTransferTransaction() {
    return strategy.isTransferTransaction({ transaction: that.view.transaction });
  }

  function getReason(reason) {
    if (reason && reason.includes('#invoiceId')) {
      return interpolateDescription(splitDescription(reason), (type, id) => `#${getInvoiceNumberById(id)}`);
    }
    return reason;
  }

  function showBankDetails() {
    return strategy.showBankDetails({ transaction: that.view.transaction, payment: that.view.vendorPayment });
  }

  function showBpay() {
    return (
      hasFacet(facets.BPAY) &&
      that.view.transaction &&
      that.view.vendorPayment &&
      that.view.transaction.type === VendorPayment &&
      that.view.vendorPayment.source === "Bank transfer" &&
      that.view.vendorPayment.bankTransferType === bankTransferTypeEnum.BPAY
    );
  }

  function showPayeeDetails() {
    return strategy.showPayeeDetails({ transaction: that.view.transaction, payment: that.view.vendorPayment });
  }

  function showSource() {
    // These transaction types have no source
    return ![BankFees, BankFeesReversal, Interest, InterestReversal].includes(that.view.transaction.type);
  }
});
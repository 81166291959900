import { useSelector, useDispatch } from 'react-redux';
import { useScopedFeature } from '../hooks';
import * as forms from './index';

export const useForm = ({ scope, validateFn, schema }) => {
  const dispatch = useDispatch();
  const { selectors, actions, operations } = useScopedFeature(forms, scope);

  const {
    formInitialised,
    fields: formFields,
    formDirty,
    formSubmitting,
    formValid,
    formValidation,
    submitFailed,
  } = useSelector((state) => selectors.getFormState(state, { emptyArraysAsFields: false }));
  const formValues = useSelector(selectors.getFieldValues);

  const onInitialiseForm = (initialValues) => dispatch(actions.initialiseForm({ fieldValues: initialValues }));

  const onValidateForm = (context) => dispatch(operations.validateForm({ validateFn, schema, context }));
  const onUpdateFields = (fieldValues) => dispatch(actions.updateFieldValues({ fieldValues }));
  const onUpdateFieldValues = (field, value) => {
    dispatch(actions.updateFieldValues({ fieldValues: { [field]: value } }));
    if (validateFn || schema) {
      onValidateForm();
    }
  };
  const onFieldValueSet = (field, value) => {
    dispatch(actions.setFieldValue({ field, value }));
    if (validateFn || schema) {
      onValidateForm();
    }
  };
  const onClearForm = () => dispatch(actions.clearForm());
  const onResetForm = (fieldValues) => dispatch(actions.resetForm({ fieldValues }));
  const onSubmitForm = async ({ submitFnP }) => dispatch(operations.submitFormP({ submitFnP }));
  const onSubmitFormWithValidation = async ({ submitFnP, onValidationFailed }) =>
    dispatch(operations.submitFormWithValidationP({ submitFnP, onValidationFailed }));

  return {
    formInitialised,
    formFields,
    formDirty,
    formSubmitting,
    formValid,
    formValues,
    formValidation,
    submitFailed,
    onInitialiseForm,
    onUpdateFields,
    onUpdateFieldValues,
    onFieldValueSet,
    onClearForm,
    onResetForm,
    onSubmitForm,
    onValidateForm,
    onSubmitFormWithValidation,
  };
};

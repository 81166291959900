import {reducerRegistry, addResetAllDataActionHandler} from '@sb-itops/redux';
import { persistReducer } from 'redux-persist';
import createIdbStorage from '@piotr-cz/redux-persist-idb-storage';

// ITOPS
import * as auth from '@sb-itops/redux/auth.2';

import * as dateRangeSelect from '@sb-itops/redux/date-range-select';
import * as expandCollapse from '@sb-itops/redux/expand-collapse';
import * as forms from '@sb-itops/redux/forms2';
import * as multiItemSelect from '@sb-itops/redux/multi-item-select';
import * as panelExpander from '@sb-itops/redux/panel-expander';
import * as selectParentChild from '@sb-itops/redux/select-parent-child';
import * as singleItemSelect from '@sb-itops/redux/single-item-select';
import * as sort from '@sb-itops/redux/sort';
import * as tabs from '@sb-itops/redux/tabs';

// BILLING
import * as invoicePreDraft from '@sb-billing/redux/invoice-pre-draft';

// WEB
import * as applicationState from 'web/redux/features/application-state';
import * as bankReconciliationsFilters from 'web/redux/route/home-billing-accounts-bank-reconciliations/feature';
import * as createBankReconciliations from 'web/redux/route/home-billing-create-bank-reconciliation';
import * as dashboard from 'web/redux/features/dashboard';
import * as bulkBillFilters from 'web/redux/route/home-billing-bills-bulk-bill/feature';
import * as reminderFilters from 'web/redux/route/home-billing-bills-account-reminders/feature';
import * as evergreenRequestsFilters from 'web/redux/route/home-billing-accounts-evergreen-requests/feature';
import * as depositSlipsFilters from 'web/redux/route/home-billing-accounts-deposit-slips/feature';
import * as accountsTransactionsFilters from 'web/redux/route/home-billing-accounts-transactions/feature';
import * as contactTransactionsFilters from 'web/redux/route/home-billing-contact-transactions/feature';
import * as matterTransactionsFilters from 'web/redux/route/home-billing-matter-transactions/feature';
import * as controlledMoneyAccountsFilters from 'web/redux/features/controlled-money-accounts';
import * as contactPaymentPlan from 'web/redux/route/home-billing-contact-payment-plan';
import * as expenseFilters from 'web/redux/features/expense-filters';
import * as billingReports from 'web/redux/route/home-billing-reports/feature';
import * as billingExpenses from 'web/redux/route/home-billing-entries-expenses/feature';
import * as billingList from 'web/redux/route/home-billing-bills-list/feature';
import * as advancedSearch from 'web/redux/route/billing-advanced-search';
import * as viewMatterBills from 'web/redux/route/home-billing-view-matter-bills/feature';
import * as viewContactBills from 'web/redux/route/home-billing-view-contact-bills/feature';
import * as billingTrustCheques from 'web/redux/route/billing-accounts-transactions-trust-cheques/feature';
import * as firmOperatingCheques from 'web/redux/route/firm-accounts-transactions-operating-cheques/feature';
import * as trustToOffice from 'web/redux/route/home-billing-bills-trust-to-office/feature';
import * as endOfMonthReports from 'web/redux/route/home-billing-accounts-end-of-month/feature';
import * as billingSupportDebug from 'web/redux/route/billing-support-debug';
import * as recentlyViewed from 'web/redux/features/recently-viewed-filter';
import * as transactionsRecentAccount from 'web/redux/features/transactions-recent-account-selection';
import * as taskFilters from 'web/redux/features/task-filters';
import * as calendarFilters from 'web/redux/features/calendar-filters';
import * as communicate from 'web/redux/features/communicate';

const reduxPersistStorage = createIdbStorage({
  name: 'smokeballStateDB',
  storeName: 'persisted-state',
  version: 3,
  upgradeCallback: (upgradeDB) => {
    switch (upgradeDB.oldVersion) {
      case 0:
        upgradeDB.createObjectStore('persisted-state');
        break;
      case 1:
        upgradeDB.transaction.objectStore('persisted-state').delete('persist:web/dashboards');
        break;
      case 2:
        if (!upgradeDB.transaction.objectStoreNames.contains('persisted-state')) {
          upgradeDB.createObjectStore('persisted-state');
        }
        break;
    }
  },
});

export const wrappedPersistReducer = ({ defaultPath, reducer }, { persist = false, whitelist, blacklist } = {}) =>
  !persist
    ? reducer
    : persistReducer(
        {
          key: defaultPath,
          storage: reduxPersistStorage,
          serialize: false, // On the way in
          deserialize: false, // On the way out.
          whitelist,
          blacklist,
        },
        reducer,
      );

const registerFeature = (
  { defaultPath, reducer },
  { persist = false, whitelist, blacklist, immutable = true, autoClearOnLogout = true } = {},
) => {
  let wrappedReducer = persist && autoClearOnLogout ? addResetAllDataActionHandler(reducer) : reducer;

  wrappedReducer = wrappedPersistReducer({ defaultPath, reducer: wrappedReducer }, { persist, whitelist, blacklist });

  reducerRegistry.register(defaultPath, wrappedReducer, immutable, autoClearOnLogout, persist);
};

export const registerReduxReducers = () => {
  // ITOPS
  registerFeature(auth, { persist: true, whitelist: ['userIdentity'], autoClearOnLogout: false }); // Global feature, no scoping.
  registerFeature(dateRangeSelect); // Scoped feature
  registerFeature(expandCollapse, { persist: true }); // scoped feature
  registerFeature(forms);
  registerFeature(multiItemSelect);
  registerFeature(panelExpander, { persist: true }); // Scoped feature
  registerFeature(selectParentChild);
  registerFeature(singleItemSelect);
  registerFeature(sort);
  registerFeature(tabs);

  // BILLING
  registerFeature(invoicePreDraft); // Global feature, no scoping.

  // WEB
  // One reducer in applicationState records the last payload and therefore this should not be immutable
  // This feature manually handles RESET_ALL_REDUX_DATA so does not need to be cleared on logout
  registerFeature(applicationState, { persist: true, immutable: false, whitelist: ['persisted'], autoClearOnLogout: false }); // Global feature, no scoping.
  registerFeature(contactPaymentPlan);
  registerFeature(bankReconciliationsFilters, { persist: true, immutable: false, whitelist: ['showFilters'] });
  registerFeature(dashboard, {
    persist: true,
    immutable: false,
    whitelist: ['dashboards', 'settings', 'currentDashboard'],
  }); // Global feature, no scoping.
  registerFeature(createBankReconciliations, { immutable: false }); // Feature is scoped
  registerFeature(bulkBillFilters, { persist: true, immutable: false, whitelist: ['showFilters'] });
  registerFeature(reminderFilters, { persist: true, immutable: false, whitelist: ['showFilters'] });
  registerFeature(evergreenRequestsFilters, {
    persist: true,
    immutable: false,
    whitelist: ['showFilters', 'selectedFilters', 'viewState.sortDirection', 'viewState.sortBy'],
  });
  registerFeature(billingReports, { persist: true, immutable: false, whitelist: ['showFilters'] });
  registerFeature(expenseFilters, { persist: true, immutable: false }); // Feature is scoped
  registerFeature(controlledMoneyAccountsFilters, { immutable: false }); // Feature is scoped
  registerFeature(accountsTransactionsFilters, { persist: true, immutable: false, whitelist: ['showFilters'] });
  registerFeature(contactTransactionsFilters, { persist: true, immutable: false, whitelist: ['showFilters'] });
  registerFeature(matterTransactionsFilters, { persist: true, immutable: false, whitelist: ['showFilters'] });
  registerFeature(depositSlipsFilters, { persist: true, immutable: false, whitelist: ['showFilters'] });
  registerFeature(recentlyViewed, { persist: true });
  registerFeature(billingExpenses);
  registerFeature(billingTrustCheques);
  registerFeature(firmOperatingCheques);
  registerFeature(advancedSearch, { persist: true, immutable: false, whitelist: ['sortBy', 'sortDirection'] }); // Global feature, no scoping.
  registerFeature(billingList, { persist: true, immutable: false }); // Global feature, no scoping.
  registerFeature(viewContactBills, { persist: true, immutable: false }); // Global feature, no scoping.
  registerFeature(viewMatterBills, { persist: true, immutable: false }); // Global feature, no scoping.
  registerFeature(trustToOffice, { persist: true, immutable: false, whitelist: ['showFilters'] });
  registerFeature(endOfMonthReports, { persist: true, immutable: false, whitelist: ['expanded'] });
  registerFeature(billingSupportDebug, { persist: true, immutable: false, whitelist: ['debugEntityHistory'] });
  registerFeature(transactionsRecentAccount); // Global feature, no scoping.

  // PRODUCTIVITY
  registerFeature(taskFilters, { persist: true });
  registerFeature(calendarFilters, { persist: true });
  registerFeature(communicate);
};

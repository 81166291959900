'use strict';

const { sort } = require('@sb-itops/sort');

// Helper function to consolidate transactions belonging to each trust to office transfer into separate pseudo transactions
function consolidateTrustToOfficeTransactions({ trustToOfficeTransactionsMap, getInvoiceNumberById, t }) {
  const trustToOfficeLabel = t('trustToOfficeTransferLabel');

  // trustToOfficeTransactionsMap has the shape of
  // {
  //    pseudoTransactionId1: [transactions]
  //    pseudoTransactionId2: [transactions]
  //    ...
  // }
  const trustToOfficeTransactions = Object.values(trustToOfficeTransactionsMap);
  const txns = trustToOfficeTransactions
    .map((transactions) =>
      transactions.reduce(
        (acc, txn) => {
          const isReversal = !!txn.reversedFromTransactionId;

          txn.payment.invoices.forEach((invoice) => {
            // Gather a list of invoice ids that belong to the same trust to office transfer
            // This is so that a new description for the group record can be compiled
            if (!acc.descriptionInvoices[invoice.invoiceId]) {
              const invoiceNumber = getInvoiceNumberById(invoice.invoiceId);
              acc.descriptionInvoices[invoice.invoiceId] = {
                invoiceId: invoice.invoiceId,
                invoiceNumber,
              };
            }

            // Group together payment details for each invoice for the consolidated trust to office transaction
            // These will be have the same shape as transactions so they can be displayed as child records
            // 1) Contact Balance (US): payment for the same invoice could be spread over multiple payment records
            // 2) Matter Balance (AU): payment record for matter can contain multiple invoices
            if (acc.invoicePayments[invoice.invoiceId]) {
              acc.invoicePayments[invoice.invoiceId] = {
                ...acc.invoicePayments[invoice.invoiceId],
                amount: isReversal
                  ? acc.invoicePayments[invoice.invoiceId].amount + invoice.amount
                  : acc.invoicePayments[invoice.invoiceId].amount - invoice.amount,
              };
            } else {
              acc.invoicePayments[invoice.invoiceId] = {
                invoiceId: invoice.invoiceId,
                matterId: txn.payment.matterId,
                effectiveDate: txn.payment.effectiveDate,
                timestamp: txn.payment.timestamp,
                reference: txn.payment.reference,
                description: `${isReversal ? 'Reversal: ' : ''}Transfer to ${t(
                  'operating',
                )} for payment of invoice #invoiceId:${invoice.invoiceId}`,
                amount: isReversal ? invoice.amount : -invoice.amount, // negative amount to signify a debit in transaction table display (i.e. a debit from trust account)
              };
            }
          });

          return {
            id: transactions[0].consolidatedId, // Custom transaction ID for consolidated transactions
            amount: (acc.amount || 0) + txn.amount,
            isTrustToOffice: transactions[0].payment.isTrustToOffice,
            isConsolidatedTrustToOffice: true,
            multiPaymentId: transactions[0].payment.multiPaymentId,
            description: acc.description || `${isReversal ? 'Reversal: ' : ''}${trustToOfficeLabel} for`,
            descriptionInvoices: acc.descriptionInvoices,
            accountId: txn.accountId,
            bankAccountId: txn.bankAccountId,
            bankAccountType: txn.bankAccountType,
            otherBankAccountName: txn.otherBankAccountName,
            bankBranchNumber: txn.bankBranchNumber,
            otherBankAccountNumber: txn.otherBankAccountNumber,
            effectiveDate: txn.effectiveDate,
            matterIds: acc.matterIds.includes(txn.matterId) ? acc.matterIds : [...acc.matterIds, txn.matterId],
            contactIds: acc.contactIds.includes(txn.contactId) ? acc.contactIds : [...acc.contactIds, txn.contactId],
            note: !acc.note || acc.note === txn.note ? txn.note : `${acc.note}\n${txn.note}`,
            paymentIds: [...acc.paymentIds, txn.paymentId],
            invoicePayments: acc.invoicePayments,
            chequeId: txn.chequeId,
            reason: !acc.reason || acc.reason === txn.reason ? txn.reason : `${acc.reason}\n${txn.reason}`,
            reference: txn.reference,
            reversed: txn.reversed,
            reversedFromTransactionIds: txn.reversedFromTransactionId
              ? [...acc.reversedFromTransactionIds, txn.reversedFromTransactionId]
              : acc.reversedFromTransactionIds,
            isHidden: txn.isHidden,
            timestamp: transactions[0].timestamp,
            transactionIds: [...acc.transactionIds, txn.id],
            type: txn.type,
            userId: txn.userId,
          };
        },
        {
          descriptionInvoices: {},
          invoicePayments: {},
          matterIds: [],
          contactIds: [],
          paymentIds: [],
          transactionIds: [],
          reversedFromTransactionIds: [],
        },
      ),
    )
    .map(({ descriptionInvoices, invoicePayments, ...txn }) => {
      const sortedInvoices = sort(Object.values(descriptionInvoices), ['invoiceNumber'], ['ASC']);

      return {
        ...txn,
        invoicePayments: Object.values(invoicePayments), // turn map into array
        description: `${txn.description} ${sortedInvoices.length === 1 ? 'invoice' : 'invoices'} ${sortedInvoices
          .map((invoice) => `#invoiceId:${invoice.invoiceId}`)
          .join(', ')}`,
      };
    });

  return txns;
}

/**
 * Consolidate transactions belonging to the same trust to office transfer into
 * one pseudo transaction, leaving other type of transaction entities unchanged
 * @param {Object} params
 * @param {Object[]} params.transactions - list of transaction entities
 * @param {function} params.getInvoiceNumberById - a function to returns invoice number by invoice id
 * @param {function} params.getPaymentById - a function to return payment entities given payment id
 * @param {function} params.t - i18next translate function
 * @param {boolean} params.ttoOnly - boolean to determine whether we want the return array to include regular
 * transactions or TTO pseudo-transactions only, if true will return child transactions as additional attribute
 * @returns a list of transactions in roughly the same shape as existing transaction entities
 */
function consolidateTrustTransactions({ transactions, getInvoiceNumberById, getPaymentById, t, ttoOnly = false }) {
  // this is specifically named consolidateTrustTransactions because we don't usually mix trust
  // transactions with other types of transactions like operating, ledger or cheques on the UI

  // Keep a temporary map of transactions for each Trust to Office transfer
  const trustToOfficeTransactionsMap = {};

  const regularTransactions = transactions.reduce((acc, txn) => {
    const payment = getPaymentById(txn.paymentId);

    if (txn.bankAccountType === 'Trust' && payment && payment.isTrustToOffice) {
      const id = `${payment.multiPaymentId || payment.paymentId}${txn.reversed ? '_reversed' : ''}`;

      if (trustToOfficeTransactionsMap[id]) {
        trustToOfficeTransactionsMap[id].push({ ...txn, consolidatedId: id, payment });
      } else {
        trustToOfficeTransactionsMap[id] = [{ ...txn, consolidatedId: id, payment }];
      }
    } else {
      acc.push(txn);
    }

    return acc;
  }, []);

  // consolidate trust to office transactions into one pseudo transaction
  const trustToOfficeConsolidatedTransactions = consolidateTrustToOfficeTransactions({
    trustToOfficeTransactionsMap,
    getInvoiceNumberById,
    t,
  });

  if (ttoOnly) {
    const ttoConsolidatedTransactionWithChildTxs = trustToOfficeConsolidatedTransactions.map((tx) => ({
      ...tx,
      transactions: trustToOfficeTransactionsMap[tx.id],
    }));
    return ttoConsolidatedTransactionWithChildTxs;
  }

  // return the combined list of transactions/pseudo transactions
  return [...trustToOfficeConsolidatedTransactions, ...regularTransactions];
}

module.exports = {
  consolidateTrustTransactions,
  consolidateTrustToOfficeTransactions,
};

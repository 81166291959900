import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { withReduxStore } from '@sb-itops/react';
import { getRegion } from '@sb-itops/region';
import { getFeeInterval } from '@sb-billing/redux/fee-configuration';
import { getActiveRateSets, getById as getRateSetById } from '@sb-billing/redux/rate-sets';
import { getAllStaff } from '@sb-firm-management/redux/firm-management';
import { getById as getStaffHourlyRateById } from '@sb-billing/redux/staff-hourly-rate';
import {
  billingFrequencyOptions,
  billingUnitOptions,
  billingUnitOptionValues,
  getBillingFrequencySubTypeOptions,
} from '@sb-billing/business-logic/matters/billing-config';

import composeHooks from '@sb-itops/react-hooks-compose';
import { MatterBilling } from './MatterBilling';
import { getBillingTypeOptions } from '../../add-edit-matter/matter-fee-and-rate-settings/billing-types';

const getBillingUnitOptions = () => {
  const firmBillingUnit = getFeeInterval();

  const billingUnitOptionsForMatterLevel = billingUnitOptions.map((option) => {
    if (option.value === billingUnitOptionValues.FIRM_DEFAULT) {
      return {
        value: option.value,
        label: `${option.label} (Currently ${firmBillingUnit} minute${parseInt(firmBillingUnit, 10) === 1 ? '' : 's'})`,
      };
    }
    return option;
  });

  return billingUnitOptionsForMatterLevel;
};

const hooks = ({
  formInitialised,
  formFieldValues,
  formFields,
  formDisabled,
  formSubmitting,
  submitFailed,
  onFieldValuesUpdated,
  onFieldValueSet,
}) => ({
  useForm: () => {
    const { customRatePerStaffMatterHourlyRates, debtorIds } = formFieldValues;

    const {
      billingType,
      contingencyAmount,
      amount,
      billingUnitOption,
      customUnit,
      rateType,
      rate,
      rateSetId,
      billingFrequency,
      billingFrequencySubType,
    } = formFields;

    const rateSets = getActiveRateSets();
    // make sure selected rate set is included in the list even if it's hidden
    if (rateSetId) {
      const selectedRateSet = getRateSetById(rateSetId.value);
      if (selectedRateSet && selectedRateSet.isHidden) {
        rateSets.unshift(selectedRateSet);
      }
    }

    const allStaffMembers = getAllStaff();

    return {
      allStaffMembers,
      billingTypeOptions: getBillingTypeOptions(getRegion()),
      billingUnitOptions: getBillingUnitOptions(),
      rateSets,
      debtorIds,
      billingFrequencyOptions,
      billingFrequencySubTypeOptions: getBillingFrequencySubTypeOptions({ billingFrequency: billingFrequency.value }),
      // form state
      formInitialised,
      formDisabled,
      formSubmitting,
      submitFailed,
      // form fields
      billingType,
      contingencyAmount,
      amount,
      billingUnitOption,
      customUnit,
      rateType,
      rate,
      customRatePerStaffMatterHourlyRates, // form field values
      rateSetId,
      billingFrequency,
      billingFrequencySubType,
      onHourlyRateChange: (newHourlyRates) => {
        onFieldValuesUpdated({
          rateType: newHourlyRates.rateType,
          rate: newHourlyRates.rate,
          rateSetId: newHourlyRates.rateSetId,
        });
        onFieldValueSet('customRatePerStaffMatterHourlyRates', newHourlyRates.customRatePerStaff); // Need to use onFieldValueSet on array fields
      },
      onDebtorsChanged: async (newSelectedContactIds) => {
        onFieldValueSet('debtorIds', newSelectedContactIds);
      },
    };
  },
});

export const MatterBillingContainer = withReduxStore(
  composeHooks(hooks)((props) => {
    const staffMembers = useMemo(() => {
      const staffMembersWithRate = props.allStaffMembers.map((staffMember) => {
        const staffHourlyRate = getStaffHourlyRateById(staffMember.id);
        return {
          ...staffMember,
          rate: staffHourlyRate?.rate,
        };
      });
      return staffMembersWithRate;
    }, [props.allStaffMembers]);
    return <MatterBilling {...props} staffMembers={staffMembers} />;
  }),
);

MatterBillingContainer.displayName = 'MatterBillingContainer';

MatterBillingContainer.propTypes = {
  // form state
  formInitialised: PropTypes.bool.isRequired,
  formFieldValues: PropTypes.object,
  formDisabled: PropTypes.bool,
  formSubmitting: PropTypes.bool,
  submitFailed: PropTypes.bool,
  // callbacks
  onFieldValuesUpdated: PropTypes.func.isRequired, // generic method for updating fields
  onFieldValueSet: PropTypes.func.isRequired, // generic method for set a field
};

import PropTypes from 'prop-types';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { createComparer, sortByOrder } from '@sb-itops/nodash';
import { useSort } from '@sb-itops/redux/sort/use-sort';
import composeHooks from '@sb-itops/react-hooks-compose';
import { useSelector, useDispatch } from 'react-redux';

import * as bankReconciliationsFeature from 'web/redux/route/home-billing-create-bank-reconciliation';
import { getLatest as getLatestBankReconciliation, printDraftPreview } from '@sb-billing/redux/bank-reconciliations';

import { getById as getMatterById, getMatterDisplayById } from '@sb-matter-management/redux/matters';
import { getContactDisplay } from '@sb-customer-management/redux/contacts-summary';
import { getById as getBankAccountById } from '@sb-billing/redux/bank-account';
import { getInvoiceLatestVersion as getInvoiceLatestVersionByInvoiceId } from '@sb-billing/redux/invoices';
import * as messageDisplay from '@sb-itops/message-display';
import * as forms from '@sb-itops/redux/forms2';

import { split as splitDescription, interpolate as interpolateDescription } from '@sb-billing/interpolate-description';
import { isReconciled } from '@sb-billing/business-logic/bank-reconciliation';
import { generateTransactionList } from '@sb-billing/business-logic/bank-reconciliation/transaction-list';
import { useState } from 'react';
import { calcOffset } from '@sb-billing/business-logic/bank-reconciliation/summary';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { useTranslation } from '@sb-itops/react';
import CreateBankReconciliation from './CreateBankReconciliation';

const getterMap = {
  account: (id) => getBankAccountById(id)?.accountTypeDisplay,
  contact: getContactDisplay,
  invoice: (id) => `#${getInvoiceLatestVersionByInvoiceId(id)?.invoiceNumber || ''}`,
  matter: getMatterDisplayById,
};

const getDescription = (tx) =>
  `${tx.isHidden ? '(Deleted) ' : ''}${interpolateDescription(splitDescription(tx.description), (type, id) =>
    getterMap[type](id),
  )}`;

const sortProperty = hasFacet(facets.transactionsByEnteredDate) ? 'enteredDate' : 'effectiveDate';
const sortItems = createComparer(sortProperty);

const searchItem = ({ tx, searchFilter, t }) => {
  if (!searchFilter) {
    return true;
  }
  const regEx = searchFilter.toLowerCase();

  const matter = getMatterById(tx.matterId);
  if (matter) {
    const matterDisplay = getMatterDisplayById(tx.matterId) || '';

    if (matter.matterNumber?.toLowerCase().includes(regEx)) {
      return true;
    }
    if (matterDisplay.toLowerCase().includes(regEx)) {
      return true;
    }
  }

  const payee = getContactDisplay(tx.contactId);

  if (payee && payee.toLowerCase().includes(regEx)) {
    return true;
  }

  if (tx.timestamp && t('date', { ts: tx.timestamp }).includes(regEx)) {
    return true;
  }
  if (tx.depositDate && t('date', { yyyymmdd: tx.depositDate }).includes(regEx)) {
    return true;
  }
  if (getDescription(tx).toLowerCase()?.includes(regEx)) {
    return true;
  }

  const amount = tx.totalDepositSlip || tx.amount || 0;
  if (
    `${t('currencySymbol')}${Math.abs(amount / 100).toFixed(2)}`.includes(regEx) ||
    t('cents', { val: Math.abs(amount) }).includes(regEx)
  ) {
    return true;
  }
  if (String(tx.reference)?.toLowerCase()?.includes(regEx)) {
    return true;
  }

  return false;
};

// coerce the transaction and deposit slips into a structure suitable for display
// done in a couple of passes to favour readability, but could be optimised if needed
export const buildPaymentsReceipts = ({
  transactions,
  depositSlips,
  ttoTransactions,
  showUnreconciled,
  showDeleted,
  selectedIds,
  expandedIds,
  bankReconciliation,
  searchFilter,
  sortBy,
  sortDirection,
  t,
}) => {
  const txItems = transactions.reduce((list, tx) => {
    if (showUnreconciled && selectedIds[tx.id]) {
      return list;
    }
    if (tx.depositSlipId) {
      return list;
    }
    if (!showDeleted && tx.isHidden) {
      return list;
    }
    const newItem = {
      ...tx,
      descriptionDisplay: getDescription(tx),
      matterDisplay: getMatterDisplayById(tx.matterId) || '',
      enteredDate: tx.timestamp,
      reference: tx.reference || '',
      selected: !!selectedIds[tx.id],
      reconciled: isReconciled(tx, bankReconciliation),
      debit: tx.amount <= 0 ? tx.amount : 0,
      credit: tx.amount >= 0 ? tx.amount : 0,
    };
    if (!searchItem({ tx: newItem, searchFilter, t })) {
      return list;
    }
    list.push(newItem);
    return list;
  }, []);

  const dsItems = depositSlips.reduce((list, ds) => {
    if (showUnreconciled && selectedIds[ds.id]) {
      return list;
    }
    if (!showDeleted && ds.isHidden) {
      return list;
    }
    const item = {
      ...ds,
      effectiveDate: ds.depositDate,
      enteredDate: ds.timestamp || ds.lastUpdated,
      selected: !!selectedIds[ds.id],
      description: 'Deposit Slip',
      descriptionDisplay: 'Deposit Slip',
      reference: ds.reference || '',
      debit: ds.totalDepositSlip <= 0 ? ds.totalDepositSlip : 0,
      credit: ds.totalDepositSlip >= 0 ? ds.totalDepositSlip : 0,
      isDepositSlip: true,
      expanded: expandedIds[ds.id],
      reconciled: isReconciled(ds, bankReconciliation),
      matterDisplay: getMatterDisplayById(ds.matterId) || '',
      transactions: ds.transactions
        .map((tx) => ({
          ...tx,
          enteredDate: tx.timestamp,
          debit: tx.amount <= 0 ? tx.amount : 0,
          credit: tx.amount >= 0 ? tx.amount : 0,
          reconciled: isReconciled(tx, bankReconciliation),
          expandChild: true,
        }))
        .sort(sortItems),
    };
    if (!searchItem({ tx: item, searchFilter, t })) {
      return list;
    }
    list.push(item);
    return list;
  }, []);

  const ttoItems = ttoTransactions.reduce((list, tto) => {
    if (showUnreconciled && selectedIds[tto.id]) {
      return list;
    }
    if (!showDeleted && tto.isHidden) {
      return list;
    }
    if (!searchItem({ tx: tto, searchFilter, t })) {
      return list;
    }
    list.push({
      ...tto,
      descriptionDisplay: getDescription(tto),
      enteredDate: tto.timestamp,
      selected: !!selectedIds[tto.id],
      reference: tto.reference || '',
      debit: tto.amount <= 0 ? tto.amount : 0,
      credit: tto.amount >= 0 ? tto.amount : 0,
      isTrustToOffice: true,
      expanded: expandedIds[tto.id],
      reconciled: isReconciled(tto, bankReconciliation),
      matterDisplay: getMatterDisplayById(tto.matterId) || '',
      transactions: tto.transactions
        .map((tx) => ({
          ...tx,
          enteredDate: tx.timestamp,
          debit: tx.amount <= 0 ? tx.amount : 0,
          credit: tx.amount >= 0 ? tx.amount : 0,
          reconciled: isReconciled(tx, bankReconciliation),
          expandChild: true,
        }))
        .sort(sortItems),
    });
    return list;
  }, []);

  const items = sortByOrder(
    [...txItems, ...dsItems, ...ttoItems],
    [sortBy === 'description' ? 'descriptionDisplay' : sortBy, sortProperty],
    [sortDirection, sortDirection],
    false,
    '',
  );

  return items.reduce((list, item) => {
    list.push(item);

    // insert deposit slip transactions if the row is expanded
    if (item.isDepositSlip && item.expanded) {
      list.push(...item.transactions);
    }
    // insert tto transactions if the row is expanded
    if (item.isConsolidatedTrustToOffice && item.expanded) {
      list.push(...item.transactions);
    }
    return list;
  }, []);
};

const hooks = (props) => ({
  useSelectors: () => {
    const { t } = useTranslation();
    const { transactions, depositSlips, ttoTransactions, summaryData, scope, trustAccountId } = props;
    const [generatingDraftRecon, setGeneratingDraftRecon] = useState(false);
    const [draftReconURL, setDraftReconURL] = useState('');

    const { selectors: formSelectors } = useScopedFeature(forms, scope);
    const formState = useSelector(formSelectors.getFormState);
    // eslint-disable-next-line no-unsafe-optional-chaining
    const { startDate, endDate, bankStatementBalance } = formState?.fields;

    const bankRecScope = `billing-bank-reconciliations-${trustAccountId}`;
    const {
      selectors: {
        getShowPayments,
        getShowReceipts,
        getSearchFilter,
        getSelectedIds,
        getExpandedIds,
        getShowUnreconciled,
        getShowDeleted,
      },
    } = useScopedFeature(bankReconciliationsFeature, bankRecScope);

    const bankReconciliation = getLatestBankReconciliation(trustAccountId);
    const selectedIds = useSelector(getSelectedIds);
    const expandedIds = useSelector(getExpandedIds);
    const showPayments = useSelector(getShowPayments);
    const showReceipts = useSelector(getShowReceipts);
    const showDeleted = useSelector(getShowDeleted);
    const searchFilter = useSelector(getSearchFilter);
    const showUnreconciled = useSelector(getShowUnreconciled);

    const { sortBy, setSortBy, sortDirection, setSortDirection } = useSort({
      scope: 'bank-rec-sort',
      initialSortBy: sortProperty,
      initialSortDirection: 'desc',
    });

    // The transactions passed from parent are all transactions
    // Need to filter again by show payments/receipts
    const paymentReceiptFilteredTransactions = generateTransactionList({
      transactions,
      filters: { showPayments, showReceipts, bankReconciliation },
      filterByEnteredDate: hasFacet(facets.transactionsByEnteredDate),
    });

    const items = selectedIds
      ? buildPaymentsReceipts({
          transactions: paymentReceiptFilteredTransactions,
          ttoTransactions: showPayments ? ttoTransactions : [],
          depositSlips: showReceipts ? depositSlips : [],
          showUnreconciled,
          selectedIds,
          expandedIds,
          bankReconciliation,
          showDeleted,
          searchFilter,
          sortBy,
          sortDirection,
          t,
        })
      : null;

    const onSort = (sortProps) => {
      setSortBy(sortProps.sortBy);
      setSortDirection(sortProps.sortDirection);
    };

    return {
      trustAccountId,
      showDeletedToggle: hasFacet(facets.deleteTransaction),
      useEnteredDate: hasFacet(facets.transactionsByEnteredDate),
      items,
      showPayments,
      showReceipts,
      showDeleted,
      searchFilter,
      showUnreconciled,
      sortBy,
      sortDirection,
      onSort,
      draftReconURL,
      generatingDraftRecon,
      setDraftReconURL,
      printDraftPreview: () => {
        setGeneratingDraftRecon(true);

        const draftReconData = {
          ...summaryData,
          trustAccountId,
          sortBy,
          sortDirection,
          variance: calcOffset(summaryData, bankStatementBalance.value),
          startDate: startDate?.value,
          endDate: endDate?.value,
          adjustments: summaryData.adjustments ? summaryData.adjustments.filter((a) => !a.isDeleted) : [],
          adjustmentTotal: summaryData.adjustments
            ? summaryData.adjustments.reduce((acc, a) => (a.isReconciled || a.isDeleted ? acc : acc - a.amount), 0)
            : 0,
          bankStatement: bankStatementBalance?.value || 0,
          // transactionIdsMap = { 'sd23-sds3-sfd': true } where true/false is selected or not
          transactionIdsMap: items.reduce((acc, tx) => {
            if (!tx.isDepositSlip && !tx.expandChild) {
              return {
                ...acc,
                [tx.id]: !!selectedIds[tx.id],
              };
            }
            return acc;
          }, {}),
          depositSlipIdsMap: items.reduce((acc, ds) => {
            if (ds.isDepositSlip && !ds.expandChild) {
              return {
                ...acc,
                [ds.id]: !!selectedIds[ds.id],
              };
            }
            return acc;
          }, {}),
          // trustToOfficeTxnsMap: { 'dfk3f-f443': { pseudo transaction obj we want }, '3h24-f233': { same pseudo transaction obj we want } }
          // the keys are the individual transaction uuids BY MATTER in the bulk trust payment.
          // If multiple matters are bulk trust paid at once there will be multiple transaction keys to same pseudo-transaction parent object.
          // We just want a list of unique pseudo-transaction objects (values of that.trustToOfficeTxnsMap).
          trustToOfficeTxns: Object.values(
            items.reduce((acc, tto) => {
              if (
                tto.isTrustToOffice &&
                !tto.expandChild &&
                tto.isConsolidatedTrustToOffice &&
                tto.transactionIds.length
              ) {
                acc[tto.id] = tto;
              }
              return acc;
            }, {}),
          ),
        };

        printDraftPreview(draftReconData)
          .then((res) => {
            setDraftReconURL(res.body.pdfLocation);
            setGeneratingDraftRecon(false);
            return undefined;
          })
          .catch((err) => {
            messageDisplay.error(
              messageDisplay
                .builder()
                .text('Failed to generate PDF')
                .conditionalText('{0}', err.message ? `: ${err.message}` : ''),
            );
            setGeneratingDraftRecon(false);
          });
      },
    };
  },
  useActions: () => {
    const dispatch = useDispatch();
    const { onRowClick, onClickLink, validateForm, trustAccountId } = props;

    const bankRecScope = `billing-bank-reconciliations-${trustAccountId}`;
    const {
      actions: {
        setShowPayments,
        setShowReceipts,
        setShowDeleted,
        setSearchFilter,
        toggleItems,
        toggleShowUnreconciled,
        toggleExpand,
      },
    } = useScopedFeature(bankReconciliationsFeature, bankRecScope);

    return {
      onShowPayments: (option) => {
        dispatch(setShowPayments({ option }));
      },
      onShowReceipts: (option) => {
        dispatch(setShowReceipts({ option }));
      },
      onShowDeleted: (option) => {
        dispatch(setShowDeleted({ option }));
      },
      setSearchFilter: (option) => {
        dispatch(setSearchFilter({ option }));
      },

      onToggleItem: ({ depositSlip, transaction, tto }) => {
        dispatch(
          toggleItems({
            transactionIds: transaction?.id ? [transaction.id] : [],
            depositSlipIds: depositSlip?.id ? [depositSlip.id] : [],
            ttoIds: tto?.id ? [tto.id] : [],
          }),
        );
        validateForm();
      },
      onToggleItems: ({ transactionIds, depositSlipIds, ttoIds }) => {
        dispatch(toggleItems({ transactionIds, depositSlipIds, ttoIds }));
        validateForm();
      },

      onToggleUnreconciled: () => dispatch(toggleShowUnreconciled()),

      onToggleExpand: ({ id }) => dispatch(toggleExpand({ id })),

      onRowClick: ({ id, isDepositSlipOrTto }) => {
        if (isDepositSlipOrTto) {
          dispatch(toggleExpand({ id }));
        } else {
          onRowClick(id);
        }
      },

      onClickLink,
    };
  },
});

const CreateBankReconciliationContainer = withReduxProvider(composeHooks(hooks)(CreateBankReconciliation));

CreateBankReconciliationContainer.propTypes = {
  onRowClick: PropTypes.func,
  trustAccountId: PropTypes.string.isRequired,
  validateForm: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  summaryData: PropTypes.object.isRequired,
  scope: PropTypes.string.isRequired,
};

CreateBankReconciliationContainer.defaultProps = {
  onRowClick: () => {},
};

export default CreateBankReconciliationContainer;

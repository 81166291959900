'use strict';

import { getLogger } from '@sb-itops/fe-logger';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { initialiseBankAccountSettings } from '@sb-billing/redux/bank-account-settings/initialise-bank-account-settings';
import { createFirmDefault as createFirmDefaultInvoiceSettings } from '@sb-billing/redux/invoice-settings-template';
import { createDefaultTaxSettings } from '@sb-billing/redux/gst-tax-settings';
import { featureActive } from '@sb-itops/feature';

import { getOwningCompany } from 'web/services/user-session-management';
import { postAuthenticationBootstrapP, checkForOauthFlowRedirects, NoTwoFactorXeroUserError } from 'web/services/bootstrapper';
import { getAccountId } from 'web/services/user-session-management';
import { isNewTheme } from 'web/services/theme';

const log = getLogger('sbPostAuthenticationController');
log.setLogLevel('info');

angular.module('sb.billing.webapp').controller('sbPostAuthenticationController', function ($rootScope, $state, $interval, sbGenericCacheService, sbNsbNotificationService, sbUserTimerService, sbDefaultBankAccountsService, sbUserPermissionsService) {
  const ctrl = this;

  ctrl.progress = 0;
  ctrl.maxProgress = 0;
  ctrl.isTriConveyBranding = getOwningCompany() === 'TriConvey';

  let cacheProgressPoller;

  (async function performPostAuthentication() {
    const destroyListener = $rootScope.destroyListenerBootstrapRouteHandling;
    
    // The application can be bootstrapped due to an oauth 2 flow callback
    // as a result of a succesful login to a third party integration. In this situation
    // we let checkForOauthFlowRedirects tell us the right state to handle the return call.
    // If oauth2Redirect is undefined, bootstrapping will continue on to post
    // authentication. If bootrapRedirect is truthy, post authentication bootstrapping
    // will be skipped in lieu of the redirect.
    const oauth2Redirect = checkForOauthFlowRedirects(); // undefined if not required.
    if (oauth2Redirect) {
      destroyListener();
      $state.go(oauth2Redirect.state, oauth2Redirect.params);
      return;
    }

    try {
      log.info('Starting post authentication bootstrap');

      // Keep track of progress while loading the generic cache.
      cacheProgressPoller = $interval(function () {
        const { loadedCachesCount, totalCachesCount, hasCacheUpgrades } = sbGenericCacheService.getCacheLoadProgress();
        ctrl.maxProgress = totalCachesCount;
        ctrl.progress = Math.min(totalCachesCount, loadedCachesCount);
        ctrl.hasCacheUpgrades = hasCacheUpgrades;
        // log.info(`Loaded ${loadedCachesCount} of ${totalCachesCount} caches.`);
      }, 500);

      // Start post authentication bootstrap tasks.
      const { skipRouteRedirection } = await postAuthenticationBootstrapP({
        startUserTimerPolling: ({ defaultTeardown }) => {
          // new theme polling defined in InitTimers
          if (isNewTheme()) {
            return defaultTeardown;
          }

          // old timer polling, can be removed once new UI refresh is rolled out
          sbUserTimerService.startPolling();            
          return () => {
            sbUserTimerService.stopPolling();
          };
        },
        loadGenericCacheDataP: async ({ skipInitialUpdateBecauseLocalMode, defaultTeardown }) => {
          const initP = sbGenericCacheService.initializeCachesP(['sbBankAccountService'], skipInitialUpdateBecauseLocalMode);
          if (!skipInitialUpdateBecauseLocalMode) {
            await initP; // usual case outside of local mode
          }

          // TODO @luke not sure if we have enough info to teardown cache at this stage or should leave it to logour controller
          return defaultTeardown;
        },
        connectToNotificationServerP: async (skipAwaitBecauseLocalMode) => {
          const connectP = sbNsbNotificationService.connect();
          if (!skipAwaitBecauseLocalMode) {
            await connectP; // usual case outside of local mode
          }

          return () => {
            try {
              sbNsbNotificationService.disconnect();
            } catch (err) {
              log.warn('Failed to disconnect from notification service, continuing with remaining clean-up tasks', err);
            }
          }
        },
        createNewFirmEntitiesP: async ({ skipAwaitBecauseLocalMode, defaultTeardown }) => {
          if (!skipAwaitBecauseLocalMode) {
            await createNewFirmEntitiesP();
          }

          return defaultTeardown;
        },
      });

      // Return user back to the requested route.
      log.info('Post authentication bootstrap completed, navigating to requested screen');
      destroyListener(); // allow routing to any page once bootstrap process completes
      
      sbUserPermissionsService.initAndSubscribe();

      if (!skipRouteRedirection) {
        if (featureActive('NUCWEB-123')) {
          $state.go(
            $rootScope.returnToState || 'home.billing.dashboard',
            $rootScope.returnToStateParams,
          );
        } else {
          $state.go(
            $rootScope.returnToState || 'home.billing.matters',
            $rootScope.returnToStateParams,
          );
        }

        $rootScope.returnToState = undefined;
        $rootScope.returnToStateParams = undefined;
      }
    } catch (err) {
      if (err instanceof NoTwoFactorXeroUserError) {
        // We want to logout user without any error message here
      } else {
        log.error('Failed to complete post authentication bootstrap', err);
      }

      $state.go('logout');

    } finally {
      sbGenericCacheService.resetCacheLoadProgress();

      if (cacheProgressPoller) {
        $interval.cancel(cacheProgressPoller);
      }
    }
  })();

  const createNewFirmEntitiesP = async () => {
    try {
      const newFirmEntityCreationsP = [
        sbDefaultBankAccountsService.createP(),
        createFirmDefaultInvoiceSettings(),
        initialiseBankAccountSettings(getAccountId()),
      ];

      if (hasFacet(facets.tax)) {
        // TODO: update with VAT tax settings
        newFirmEntityCreationsP.push(createDefaultTaxSettings());
      }

      await Promise.all(newFirmEntityCreationsP);

    } catch (err) {
      // Why do we consume rather than throw?
      // The call to create new firm entities is attempted on every login.
      // Even though it's only required once. That means that if it fails, it's likely
      // that a user could continue safely without them.
      log.error('Failed to create new firm entities', err);
    }
  };
});

import { react2angular } from '@sb-itops/react2angular';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';

/** **************************************************************************************************** */
/** **************************************** WEB DOMAIN ************************************************* */
/** **************************************************************************************************** */
import * as Web from 'web/react-redux';
import * as WebComponents from 'web/components';

/** **************************************************************************************************** */
/** **************************************** CM DOMAIN ************************************************* */
/** **************************************************************************************************** */
import * as CustomerManagement from '@sb-customer-management/react';

/** **************************************************************************************************** */
/** **************************************** ITOPS DOMAIN ************************************************* */
/** **************************************************************************************************** */
import * as Itops from '@sb-itops/react';

/** **************************************************************************************************** */
/** **************************************** MM DOMAIN ************************************************* */
/** **************************************************************************************************** */
import * as MatterManagement from '@sb-matter-management/react';

/** **************************************************************************************************** */
/** **************************************** MT DOMAIN ************************************************* */
/** **************************************************************************************************** */

import * as MatterTypes from '@sb-matter-types/react';

/** **************************************************************************************************** */
/** ************************************* BILLING DOMAIN *********************************************** */
/** **************************************************************************************************** */
import * as BillingReactComponents from '@sb-billing/react';

import * as BillingCreditCardEntryGroup from '@sb-billing/credit-card-entry-group';

/** **************************************************************************************************** */
/** **************************************** PRODUCTIVITY DOMAIN ************************************************* */
/** **************************************************************************************************** */
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import {
  CommunicateEmbeddedContainer,
  CommunicateIframeContainer,
  DocumentListContainer,
  IntakeIframeContainer,
  DataMigrationIframeContainer,
  ArchieIframeContainer,
} from 'web/containers';

// Routes
angular.module(`sb.billing.webapp`).component(`sbbContactPaymentPlan`, react2angular(Web.ContactPaymentPlan));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbContactPaymentPlanWithReduxEntities`, react2angular(Web.ContactPaymentPlanWithReduxEntities));
angular.module(`sb.billing.webapp`).component(`sbbContactCreditCard`, react2angular(Web.ContactCreditCard));
angular.module(`sb.billing.webapp`).component(`sbbBillingSupportRestLogs`, react2angular(Web.BillingSupportRestLogs));
angular.module(`sb.billing.webapp`).component(`sbbBillingSupportDataExplorer`, react2angular(Web.BillingSupportDataExplorer));
angular.module(`sb.billing.webapp`).component(`sbbBillingSupportDebug`, react2angular(Web.BillingSupportDebug));
angular.module(`sb.billing.webapp`).component(`sbbGenerateLogoModal`, react2angular(Web.GenerateLogoModal));
angular.module(`sb.billing.webapp`).component(`sbbBillingSupportUpcoming`, react2angular(Web.BillingSupportUpcoming));
angular.module(`sb.billing.webapp`).component(`sbbBillingSupportAccountingTransactions`, react2angular(Web.BillingSupportAccountingTransactions));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbBillingBillsFinaliseWithPayments`, react2angular(Web.BillingBillsFinaliseWithPayments));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbBillingBillsTrustToOffice`, react2angular(Web.BillingBillsTrustToOffice));
angular
  .module(`sb.billing.webapp`)
  .component(
    `sbbBillingReporting`,
    react2angular(
      Web.BillingReporting,
      ['stateOverride', 'onStateOverrideChange'],
      ['sbReportingService', 'sbAsyncOperationsService'],
    ),
  );
angular
  .module(`sb.billing.webapp`)
  .component(
    `sbbFirmAccountsTransactionsOperatingCheques`,
    react2angular(Web.FirmAccountsTransactionsOperatingCheques, null, [
      'sbReportingService',
      'sbAsyncOperationsService',
    ]),
  );
angular
  .module(`sb.billing.webapp`)
  .component(`sbbBillingAccountsTransactionsTrustCheques`, react2angular(Web.BillingAccountsTransactionsTrustCheques, null, ['sbAsyncOperationsService']));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbBillingContactMattersRoute`, react2angular(Web.BillingContactMattersRoute));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbBillingContactInvoicesRoute`, react2angular(Web.BillingContactInvoicesRoute));
angular.module(`sb.billing.webapp`).component(`sbbBillingDashboardRoute`, react2angular(Web.BillingDashboardRoute));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbBillingMatterInvoicesRoute`, react2angular(Web.BillingMatterInvoicesRoute));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbBillingMatterDashboardRoute`, react2angular(Web.BillingMatterDashboardRoute));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbMatterDescriptionBar`, react2angular(Web.MatterDescriptionBar, ['onClickLink', 'matterId', 'trustRetainerBelowLimit']));
angular.module(`sb.billing.webapp`).component(`sbbBillingLeadsRoute`, react2angular(Web.BillingLeadsRoute));
angular.module(`sb.billing.webapp`).component(`sbbBillingMattersRoute`, react2angular(Web.BillingMattersRoute));
angular.module(`sb.billing.webapp`).component(`sbbBillingContactsRoute`, react2angular(Web.BillingContactsRoute));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbBillingContactsPaymentPlansRoute`, react2angular(Web.BillingContactsPaymentPlansRoute));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbBillingCreateBankReconcilationRoute`, react2angular(Web.BillingCreateBankReconcilationRoute));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbBillingViewContactDetailsRoute`, react2angular(Web.BillingViewContactDetailsRoute));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbBillingViewInvoiceStatementPaymentsRoute`, react2angular(Web.BillingViewInvoiceStatementPaymentsRoute, null, ['$state', '$uibModal', 'sbPaymentService', 'sbTabService']));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbBillingViewInvoiceStatementPdfRoute`, react2angular(Web.BillingViewInvoiceStatementPdfRoute, null, ['sbTabService']));
angular
  .module(`sb.billing.webapp`)
  .component(
    `sbbBillingViewContactDetailsRouteWithReduxEntities`,
    react2angular(Web.BillingViewContactDetailsRouteWithReduxEntities),
  );
angular
  .module(`sb.billing.webapp`)
  .component(`sbbBillingAccountsEndOfMonth`, react2angular(Web.BillingAccountsEndOfMonth));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbBillingBankReconciliationsHome`, react2angular(Web.BillingBankReconciliationsHome));
angular.module(`sb.billing.webapp`).component(`sbbLoginRoute`, react2angular(Web.LoginRoute));
angular
.module(`sb.billing.webapp`)
.component(`sbbCreateNewButton`, react2angular(Web.CreateNewButton));
angular
.module(`sb.billing.webapp`)
.component(`sbbTopbar`, react2angular(Web.Topbar));
angular
.module(`sb.billing.webapp`)
.component(`sbbTimers`, react2angular(Web.Timers));
angular.module(`sb.billing.webapp`).component(`sbbBillingDraftInvoiceRoute`, react2angular(Web.BillingDraftInvoiceRoute, null, [
  'sbSaveInvoiceCommand',
  'sbInvoiceSendService',
  'sbAsyncOperationsService',
]));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbBillsListRouteInvoiceTable`, react2angular(Web.BillsListRouteInvoiceTable));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbBillingInvoicesListRoute`, react2angular(Web.BillingInvoicesListRoute));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbBulkCreateInvoicesRoute`, react2angular(Web.BulkCreateInvoicesRoute, null, []));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbBulkTrustToOfficeRoute`, react2angular(Web.BulkTrustToOfficeRoute));
angular.module(`sb.billing.webapp`).component(`sbbFirmExpenseEntries`, react2angular(Web.FirmExpenseEntries, null, ['sbAsyncOperationsService']));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbFirmFeeEntries`, react2angular(Web.FirmFeeEntries, null, ['sbAsyncOperationsService']));
angular.module(`sb.billing.webapp`).component(`sbbFirmRateSets`, react2angular(Web.FirmRateSets));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbMatterExpenseEntries`, react2angular(Web.MatterExpenseEntries, null, ['sbAsyncOperationsService']));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbMatterFeeEntries`, react2angular(Web.MatterFeeEntries, null, ['sbAsyncOperationsService']));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbViewContactBillsRouteInvoiceTable`, react2angular(Web.ViewContactBillsRouteInvoiceTable));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbManageSubscriptionRoute`, react2angular(Web.ManageSubscriptionRoute, ['onClickLink']));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbViewMatterBillsRouteInvoiceTable`, react2angular(Web.ViewMatterBillsRouteInvoiceTable));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbSubscriptionCompleteRoute`, react2angular(Web.SubscriptionCompleteRoute));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbUpdatePaymentMethodCompleteRoute`, react2angular(Web.UpdatePaymentMethodCompleteRoute));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbUpdatePaymentMethodRoute`, react2angular(Web.UpdatePaymentMethodRoute, ['onClickLink']));
angular
  .module(`sb.billing.webapp`)
  .component(
    `sbbProtectedFunds`,
    react2angular(Web.ProtectedFunds, null, ['sbReportingService', 'sbAsyncOperationsService']),
  );
angular.module(`sb.billing.webapp`).component(`sbbViewMatterTasksRoute`, react2angular(Web.ViewMatterTasksRoute));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbViewMatterDocumentsRoute`, react2angular(Web.ViewMatterDocumentsRoute, ['matterId']));
angular.module(`sb.billing.webapp`).component(`sbbViewMatterMemosRoute`, react2angular(Web.ViewMatterMemosRoute));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbViewMatterCommunicateRoute`, react2angular(Web.ViewMatterCommunicateRoute));
angular.module(`sb.billing.webapp`).component(`sbbViewMatterCalendarRoute`, react2angular(Web.ViewMatterCalendarRoute));
angular.module(`sb.billing.webapp`).component(`sbbProductivityTasksRoute`, react2angular(Web.ProductivityTasksRoute));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbProductivityCalendarRoute`, react2angular(Web.ProductivityCalendarRoute));
angular.module(`sb.billing.webapp`).component(`sbbDepositRequestModal`, react2angular(Web.DepositRequestModal));
angular.module(`sb.billing.webapp`).component(`sbbTrustDepositRequestModal`, react2angular(Web.TrustDepositRequestModal));
angular.module(`sb.billing.webapp`).component(`sbbDataBackup`, react2angular(Web.DataBackup));
angular.module(`sb.billing.webapp`).component(`sbbBillingModals`, react2angular(Web.BillingModals));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbBillingAccountsPaymentProviderPaymentsRoute`, react2angular(Web.BillingAccountsPaymentProviderPaymentsRoute));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbFinalisedInvoiceEntries`, react2angular(Web.FinalisedInvoiceEntries, ['invoiceId', 'onClickLink', 'onOpenExpenseModal'], ['sbAsyncOperationsService']));
angular
  .module(`sb.billing.webapp`)
    .component(`sbbMarketplace`, react2angular(Web.Marketplace));
  
// Web Components.
angular
  .module(`sb.billing.webapp`)
  .component(`sbbAdjustmentsTable`, react2angular(Web.AdjustmentsTable, ['route', 'id', 'onUpdate']));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbAddAdjustmentModalButton`, react2angular(Web.AddAdjustmentModalButton, ['route', 'id', 'onUpdate']));
angular.module(`sb.billing.webapp`).component(`sbbAddEditMatterForm`, react2angular(Web.AddEditMatterForm));
angular.module(`sb.billing.webapp`).component(`sbbMatterTypeaheadField`, react2angular(Web.MatterTypeaheadBridge));
angular.module(`sb.billing.webapp`).component(`sbbTabReconDateRange`, react2angular(Web.TabReconDateRange));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbDraftInvoiceTemplateSettings`, react2angular(Web.DraftInvoiceTemplateSettings));
angular
  .module(`sb.billing.webapp`)
  .component(
    `sbbAttorneyDropdownField`,
    react2angular(Web.AttorneyDropDownField, ['defaultEntityId', 'handleChange'], ['$scope']),
  );
angular
  .module(`sb.billing.webapp`)
  .component(`sbbAdvancedSearchInvoiceTable`, react2angular(Web.AdvancedSearchInvoiceTable));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbBillingAdvancedSearchInvoicesRoute`, react2angular(Web.BillingAdvancedSearchInvoicesRoute));
angular
  .module(`sb.billing.webapp`)
  .component(
    `sbbTrustTransactionTable`,
    react2angular(Web.TrustTransactionTable, null, ['$uibModal', 'sbAsyncOperationsService']),
  );
angular.module(`sb.billing.webapp`).component(`sbbPaymentInvoicesTable`, react2angular(Web.PaymentInvoicesTable));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbPaymentTable`, react2angular(Web.PaymentTable, null, ['$state', '$uibModal']));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbEvergreenRequestTable`, react2angular(Web.EvergreenRequestTable, null, ['$state']));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbShowSystemDateToggleAccounts`, react2angular(Web.ShowSystemDateToggleAccounts));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbShowSystemDateToggleMatter`, react2angular(Web.ShowSystemDateToggleMatter));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbShowSystemDateToggleContact`, react2angular(Web.ShowSystemDateToggleContact));
angular
  .module(`sb.billing.webapp`)
  .component(
    `sbbShowDeletedTransactionsToggle`,
    react2angular(Web.ShowDeletedTransactionsToggleBridge, null, ['$state']),
  );
angular.module(`sb.billing.webapp`).component(`sbbPaymentPlanModalDialog`, react2angular(Web.PaymentPlanModalDialog));
angular.module(`sb.billing.webapp`).component(`sbbUnprintedChequeTable`, react2angular(Web.UnprintedChequeTable));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbLedgerTransactionTable`, react2angular(Web.LedgerTransactionTableContainer));
angular.module(`sb.billing.webapp`).component(`sbbPaymentPlanIndicator`, react2angular(Web.PaymentPlanIndicator));
angular
  .module(`sb.billing.webapp`)
  .component(
    `sbbPrintOperatingChequeModal`,
    react2angular(Web.PrintOperatingChequeModal, null, ['sbAsyncOperationsService']),
  );
angular.module(`sb.billing.webapp`).component(`sbbInvoiceReminderTable`, react2angular(Web.InvoiceReminderTable));
angular.module(`sb.billing.webapp`).component(`sbbSplitPayorsList`, react2angular(Web.SplitPayorsList));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbTrustTransactionNumberingTab`, react2angular(Web.TrustTransactionNumberingTab));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbCmaTransactionNumberingTab`, react2angular(Web.CMATransactionNumberingTab));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbOperatingAccountDetailsSettings`, react2angular(Web.OperatingAccountDetailsSettings));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbFinaliseWithPaymentsSettings`, react2angular(Web.FinaliseWithPaymentsSettings));
angular.module(`sb.billing.webapp`).component(`sbbPaymentSourceDropdown`, react2angular(Web.PaymentSourceDropdown));
angular.module('sb.billing.webapp').component('sbbDateListFilter', react2angular(Web.DateListFilter));
angular.module(`sb.billing.webapp`).component(`sbbEvergreenEmailSettings`, react2angular(Web.EvergreenEmailSettings));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbDepositRequestEmailSettings`, react2angular(Web.DepositRequestEmailSettings));
angular.module(`sb.billing.webapp`).component(`sbbStaffSettings`, react2angular(Web.StaffSettings));
angular.module(`sb.billing.webapp`).component(`sbbEditStaffUserById`, react2angular(Web.EditStaffUserById));
angular.module(`sb.billing.webapp`).component(`sbbMatterNumbering`, react2angular(Web.ConfigureMatterNumbering));
angular.module(`sb.billing.webapp`).component(`sbbDailyDigestPreference`, react2angular(Web.DailyDigestPreference));
angular.module(`sb.billing.webapp`).component(`sbbCalendarSettings`, react2angular(Web.CalendarSettings));
angular.module(`sb.billing.webapp`).component(`sbbFirmDetails`, react2angular(Web.FirmDetails));
angular.module(`sb.billing.webapp`).component(`sbbAccountSecurity`, react2angular(Web.AccountSecurity));
angular.module('sb.billing.webapp').component('sbbMatterTypeAccordian', react2angular(Web.MatterTypeAccordian));
angular
  .module('sb.billing.webapp')
  .component('sbbOperatingChequeExpenseTable', react2angular(Web.OperatingChequeExpenseTable));
angular
  .module('sb.billing.webapp')
  .component('sbbCreateBankReconciliation', react2angular(Web.CreateBankReconciliation));
angular.module('sb.billing.webapp').component('sbbBankReconciliationsList', react2angular(Web.BankReconciliationsList));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbInvoiceEmailTemplateSettings`, react2angular(Web.InvoiceEmailTemplateSettingsContainer));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbInvoiceEmailModal`, react2angular(Web.InvoiceEmailModal, null, ['sbInvoiceSendService']));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbInvoiceReminderEmailModal`, react2angular(Web.InvoiceReminderEmailModal));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbInvoiceCommunicateModal`, react2angular(Web.InvoiceCommunicateModal, null, ['sbInvoiceSendService']));
angular.module(`sb.billing.webapp`).component(`sbbMarkAsSentModal`, react2angular(Web.MarkAsSentModal));
angular.module(`sb.billing.webapp`).component(`sbbInvoicePaymentReversalWarning`, react2angular(Web.InvoicePaymentReversalWarning));
angular.module(`sb.billing.webapp`).component(`sbbVendorPaymentReversalWarning`, react2angular(Web.VendorPaymentReversalWarning));
angular.module(`sb.billing.webapp`).component(`sbbTrustToOfficeReversalWarning`, react2angular(Web.TrustToOfficeReversalWarning));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbMultiContactCorrespondenceDetailsInline`, react2angular(Web.MultiContactCorrespondenceDetailsInline));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbInvoiceViewedOnlineDetails`, react2angular(Web.InvoiceViewedOnlineDetails));
angular
  .module(`sb.billing.webapp`)
  .component('sbbPaymentProviderIntegration', react2angular(Web.PaymentProviderIntegrationContainer));
angular.module(`sb.billing.webapp`).component(`sbbCreditCardDepositModal`, react2angular(Web.CreditCardDepositModal));
angular.module(`sb.billing.webapp`).component(`sbbCreditCardPaymentModal`, react2angular(Web.CreditCardPaymentModal));
angular.module(`sb.billing.webapp`).component(`sbbInvoicesFilterPanel`, react2angular(Web.InvoicesFilterPanel));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbViewMatterInvoicesFilterPanel`, react2angular(Web.ViewMatterInvoicesFilterPanel));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbViewContactInvoicesFilterPanel`, react2angular(Web.ViewContactInvoicesFilterPanel));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbAccountsTransactionsFilterPanel`, react2angular(Web.AccountsTransactionsFilterPanel));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbContactTransactionsFilterPanel`, react2angular(Web.ContactTransactionsFilterPanel));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbMatterTransactionsFilterPanel`, react2angular(Web.MatterTransactionsFilterPanel));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbEvergreenRequestsSidePanel`, react2angular(Web.EvergreenRequestsSidePanel));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbBankReconciliationsFilterPanel`, react2angular(Web.BankReconciliationsFilterPanel));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbBankReconciliationsSetup`, react2angular(Web.BankReconciliationsSetup));
angular.module(`sb.billing.webapp`).component(`sbbDepositSlipsFilterPanel`, react2angular(Web.DepositSlipsFilterPanel));
angular.module(`sb.billing.webapp`).component(`sbbRemindersFilterPanel`, react2angular(Web.RemindersFilterPanel));
angular.module(`sb.billing.webapp`).component(`sbbDebugEntity`, react2angular(Web.DebugEntity));
angular.module(`sb.billing.webapp`).component(`sbbDebugEntityHistory`, react2angular(Web.DebugEntityHistory));
angular.module(`sb.billing.webapp`).component(`sbbDebugInvoicePdfButton`, react2angular(Web.DebugInvoicePdfButton));
angular.module(`sb.billing.webapp`).component(`sbbDebugExpireInvoicePaymentLinksButton`, react2angular(Web.DebugExpireInvoicePaymentLinksButton));
angular.module(`sb.billing.webapp`).component(`sbbDebugRefreshInvoicePaymentLinksButton`, react2angular(Web.DebugRefreshInvoicePaymentLinksButton));
angular.module(`sb.billing.webapp`).component(`sbbDebugCheckInvoicePaymentLinkExpiryButton`, react2angular(Web.DebugCheckInvoicePaymentLinkExpiryButton));
angular.module(`sb.billing.webapp`).component(`sbbDebugSetPaymentLinkExpiryTo1DayButton`, react2angular(Web.DebugSetPaymentLinkExpiryTo1DayButton));
angular.module(`sb.billing.webapp`).component(`sbbDebugRegenerateInvoicePdfButton`, react2angular(Web.DebugRegenerateInvoicePdfButton));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbFinancialSummaryByMatter`, react2angular(Web.FinancialSummaryByMatter));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbFinancialSummaryByContact`, react2angular(Web.FinancialSummaryByContact));
angular.module(`sb.billing.webapp`).component(`sbbFinancialSummaryByFirm`, react2angular(Web.FinancialSummaryByFirm));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbFinancialSummaryForTrust`, react2angular(Web.FinancialSummaryForTrust));
angular.module(`sb.billing.webapp`).component(`sbbCreditNoteModal`, react2angular(Web.CreditNoteModal));
angular.module(`sb.billing.webapp`).component(`sbbFirmCreditNoteLedger`, react2angular(Web.FirmCreditNoteLedger));
angular.module(`sb.billing.webapp`).component(`sbbClientCommunications`, react2angular(Web.ClientCommunications, null, ['sbAsyncOperationsService']));
angular.module(`sb.billing.webapp`).component(`sbbMatterActions`, react2angular(Web.MatterActions));
angular.module(`sb.billing.webapp`).component(`sbbMatterCreditNoteLedger`, react2angular(Web.MatterCreditNoteLedger));
angular
  .module(`sb.billing.webapp`)
  .component(
    `sbbMatterControlledMoneyAccounts`,
    react2angular(Web.MatterControlledMoneyAccounts, null, [
      '$uibModal',
      'sbReportingService',
      'sbAsyncOperationsService',
    ]),
  );
angular.module(`sb.billing.webapp`).component(`sbbContactCreditNoteLedger`, react2angular(Web.ContactCreditNoteLedger));
angular.module(`sb.billing.webapp`).component(`sbbAddContactInlineForm`, react2angular(Web.AddContactInlineForm));
angular.module(`sb.billing.webapp`).component(`sbbContactMultiSelect`, react2angular(Web.ContactMultiSelect));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbCreditNotePaymentDetailsModal`, react2angular(Web.CreditNotePaymentDetailsModal));
angular.module(`sb.billing.webapp`).component(`sbbContactCreateEditModal`, react2angular(Web.ContactCreateEditModal));
angular.module(`sb.billing.webapp`).component(`sbbTrialExpiryIndicator`, react2angular(Web.TrialExpiryIndicator, ['onClickLink']));
angular
  .module(`sb.billing.webapp`)
  .component('sbbSubscriptionPaymentModal', react2angular(Web.SubscriptionPaymentModal));
angular.module(`sb.billing.webapp`).component(`sbbProductivityTest`, react2angular(Web.ProductivityTest));
angular.module(`sb.billing.webapp`).component(`sbbProtectBalanceAddModal`, react2angular(Web.ProtectBalanceAddModal));
angular.module(`sb.billing.webapp`).component(`sbbDepositFundsModal`, react2angular(Web.DepositFundsModal));
angular.module(`sb.billing.webapp`).component(`sbbUserNameById`, react2angular(Web.UserNameById));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbInvoiceReminderEmailSettings`, react2angular(Web.InvoiceReminderEmailSettings));
angular.module(`sb.billing.webapp`).component(`sbbAddSplitPayment`, react2angular(Web.PaymentAddModal));
angular.module(`sb.billing.webapp`).component(`sbbStaffRateOverrider`, react2angular(Web.StaffRateOverrider));
angular.module(`sb.billing.webapp`).component(`sbbTrustAccountsOverview`, react2angular(Web.TrustAccountsOverview));
angular.module(`sb.billing.webapp`).component(`sbbTrustAccountSettings`, react2angular(Web.TrustAccountSettings));
angular.module(`sb.billing.webapp`).component(`sbbFirmUtbmsSettings`, react2angular(Web.FirmUtbmsSettings));
angular.module(`sb.billing.webapp`).component(`sbbChequeSettings`, react2angular(Web.ChequeSettings));
angular.module(`sb.billing.webapp`).component(`sbbNavTabs`, react2angular(Web.NavTabs));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbInvoiceConfirmFinaliseModal`, react2angular(Web.InvoiceConfirmFinaliseModal));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbXeroInvoiceIntegrationConfirmationModal`, react2angular(Web.XeroInvoiceIntegrationConfirmationModal));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbMyobInvoiceIntegrationConfirmationModal`, react2angular(Web.MyobInvoiceIntegrationConfirmationModal));
angular.module(`sb.billing.webapp`).component(`sbbInvoiceAddPaymentButton`, react2angular(Web.InvoiceAddPaymentButton));
angular.module(`sb.billing.webapp`).component(`sbbInvoicePreview`, react2angular(WebComponents.InvoicePreview));
angular.module(`sb.billing.webapp`).component(`sbbDepositAddressIncompleteButton`, react2angular(Web.DepositAddressIncompleteButton));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbAddBankFeeInterestFormModal`, react2angular(Web.AddBankFeeInterestFormModal));

angular.module(`sb.billing.webapp`).component(`sbbBillingNav`, react2angular(WebComponents.BillingNav, ['onGotoOrReset', 'onClickLink', 'navHighlights']));
angular.module(`sb.billing.webapp`).component(`sbbMatterDebugEntities`, react2angular(WebComponents.MatterDebugEntities));
angular.module(`sb.billing.webapp`).component(`sbbPageNav`, react2angular(WebComponents.PageNav));
angular.module(`sb.billing.webapp`).component(`sbbSetupUserWarningBanner`, react2angular(WebComponents.SetupUserWarningBanner));
angular.module(`sb.billing.webapp`).component(`sbbRateSetsSettings`, react2angular(Web.RateSetsSettings));

angular.module(`sb.billing.webapp`).component(`sbbContactDisplay`, react2angular(CustomerManagement.ContactDisplay));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbContactTypeahead`, react2angular(CustomerManagement.ContactTypeahead));
angular
  .module(`sb.billing.webapp`)
  .component(
    `sbbContactTable`,
    react2angular(
      CustomerManagement.ContactTable,
      ['contacts', 'onSelect', 'sort', 'sortBy', 'sortDirection'],
      ['$scope'],
    ),
  );

angular.module(`sb.billing.webapp`).component(`sbbLinkableText`, react2angular(Itops.LinkableText));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbClickableIcon`, react2angular(Itops.ClickableIcon), ['id', 'className', 'onClick'], []);
angular.module(`sb.billing.webapp`).component(`sbbCollapsibleHeader`, react2angular(Itops.CollapsibleHeader));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbCurrencyDisplay`, react2angular(Itops.CurrencyDisplayBridge, null, []));
angular.module(`sb.billing.webapp`).component(`sbbCurrencyInput`, react2angular(Itops.CurrencyInput2));
angular.module(`sb.billing.webapp`).component(`sbExpandCollapseToggler`, react2angular(Itops.ExpandCollapseToggler));
angular.module(`sb.billing.webapp`).component(`sbbMultiSelectFilters`, react2angular(Itops.MultiSelectFilters));
angular.module(`sb.billing.webapp`).component(`sbbToggleListFilter`, react2angular(Itops.ToggleListFilter));
angular.module(`sb.billing.webapp`).component(`sbbDatePicker`, react2angular(Itops.DatePicker));
angular.module(`sb.billing.webapp`).component(`sbbDateRangePicker`, react2angular(Itops.DateRangePicker));
angular.module(`sb.billing.webapp`).component(`sbbMessageBar`, react2angular(Itops.MessageBar));
angular.module(`sb.billing.webapp`).component(`sbbMessageList`, react2angular(Itops.MessageListContainer));
angular.module(`sb.billing.webapp`).component(`sbbTextEditor`, react2angular(Itops.TextEditor));
angular.module(`sb.billing.webapp`).component(`sbbDropdownMenu`, react2angular(Itops.DropdownMenuButton));
angular.module(`sb.billing.webapp`).component(`sbbButton`, react2angular(Itops.ButtonAngularCompatible));
angular.module(`sb.billing.webapp`).component(`sbbSpinner`, react2angular(Itops.Spinner));
angular.module(`sb.billing.webapp`).component(`sbbSlidingToggle`, react2angular(Itops.SlidingToggle));
angular.module(`sb.billing.webapp`).component(`sbbIcon`, react2angular(Itops.Icon, ['type', 'onClick', 'tooltip'], []));
angular.module(`sb.billing.webapp`).component(`sbbCheckboxLabelInline`, react2angular(Itops.CheckboxLabelInline));
angular.module(`sb.billing.webapp`).component(`sbbInputNumberFilter`, react2angular(Itops.InputNumberFilter));
angular.module(`sb.billing.webapp`).component(`sbbAccordian`, react2angular(Itops.Accordian));
angular.module(`sb.billing.webapp`).component(`sbbModalDialog`, react2angular(Itops.ModalDialog));
angular.module(`sb.billing.webapp`).component('sbbSmokeballSpinner', react2angular(Itops.SmokeballSpinner));
angular.module(`sb.billing.webapp`).component('sbbTriConveySpinner', react2angular(Itops.TriConveySpinner));
angular.module(`sb.billing.webapp`).component('sbbStatusPill', react2angular(Itops.StatusPill));
angular.module(`sb.billing.webapp`).component(`sbbTemplateMacroSelector`, react2angular(Itops.TemplateMacroSelector));
angular.module(`sb.billing.webapp`).component(`sbbPreviewPdf`, react2angular(Itops.PreviewPdf));
angular.module(`sb.billing.webapp`).component(`sbbTypeahead`, react2angular(Itops.Typeahead));
angular.module(`sb.billing.webapp`).component(`sbbStatelessSelect`, react2angular(Itops.StatelessSelect));
angular.module(`sb.billing.webapp`).component('sbbRadioButtons', react2angular(Itops.RadioButtons));

angular.module(`sb.billing.webapp`).component(`sbbMatterDisplay`, react2angular(MatterManagement.MatterDisplay));
angular.module(`sb.billing.webapp`).component(`sbbMattersDisplay`, react2angular(MatterManagement.MattersDisplay));
angular.module(`sb.billing.webapp`).component(`sbbMatterTable`, react2angular(MatterManagement.MatterTable));
angular.module(`sb.billing.webapp`).component(`sbbMatterTypeDisplay`, react2angular(MatterTypes.MatterTypeDisplay));

angular
  .module(`sb.billing.webapp`)
  .component(`sbbBankReconciliationTable`, react2angular(BillingReactComponents.BankReconciliationTable));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbDepositSlipsTable`, react2angular(BillingReactComponents.DepositSlipsTable));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbInvoiceDisplay`, react2angular(BillingReactComponents.InvoiceDisplay));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbInvoiceTotalById`, react2angular(BillingReactComponents.InvoiceTotalDisplay));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbInvoiceSettingTitleStateless`, react2angular(BillingReactComponents.InvoiceSettingTitle)); // :'(
angular
  .module(`sb.billing.webapp`)
  .component(`sbbInvoiceSettingTitle`, react2angular(BillingReactComponents.InvoiceSettingTitle2));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbMatterSettingTitle`, react2angular(BillingReactComponents.MatterSettingTitle));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbMatterSettingSubtitle`, react2angular(BillingReactComponents.MatterSettingSubtitle));
angular
  .module(`sb.billing.webapp`)
  .component(
    `sbbRemindersRecommendedFilter`,
    react2angular(BillingReactComponents.RemindersRecommendedFilterBridge, null, ['$scope']),
  );
angular
  .module(`sb.billing.webapp`)
  .component(`sbbNonPaymentTransactionReversalWarning`, react2angular(BillingReactComponents.NonPaymentTransactionReversalWarning));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbBankAccountNumberDisplay`, react2angular(BillingReactComponents.BankAccountNumberDisplay));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbBankAccountNameDisplay`, react2angular(BillingReactComponents.BankAccountNameDisplay));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbDownloadLedesDropdownOption`, react2angular(BillingReactComponents.DownloadLedesDropdownOption));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbDeleteInvoicesButton`, react2angular(BillingReactComponents.DeleteInvoicesButtonContainer));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbInvoiceSettingsTemplateButtons`, react2angular(BillingReactComponents.InvoiceSettingsTemplateButtons));
angular
  .module(`sb.billing.webapp`)
  .component(
    `sbbDeleteInvoicesProceedModalDialog`,
    react2angular(BillingReactComponents.DeleteInvoicesProceedModalDialog),
  );
angular
  .module(`sb.billing.webapp`)
  .component(`sbbEndOfMonthPreview`, react2angular(BillingReactComponents.EndOfMonthPreview));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbBankAccountSettings`, react2angular(BillingReactComponents.BankAccountSettings));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbOverdrawWarning`, react2angular(BillingReactComponents.OverdrawWarning));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbQuickbooksLoginButton`, react2angular(BillingReactComponents.QuickbooksLoginButton));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbApplyPaymentsRadioList`, react2angular(BillingReactComponents.ApplyPaymentsRadioList));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbChequePrintMethodsDropDown`, react2angular(BillingReactComponents.ChequePrintMethodsDropDown));
angular
  .module(`sb.billing.webapp`)
  .component('sbbCheckboxListPopup', react2angular(BillingReactComponents.CheckboxListPopup));
angular
  .module(`sb.billing.webapp`)
  .component('sbbEinvoiceSettingsForm', react2angular(BillingReactComponents.EInvoiceSettingsForm));
angular
  .module(`sb.billing.webapp`)
  .component('sbbSurchargeSettings', react2angular(BillingReactComponents.SurchargeSettings));
angular
  .module(`sb.billing.webapp`)
  .component('sbbConfirmationModalDialog', react2angular(BillingReactComponents.ConfirmationModalDialog));
angular
  .module(`sb.billing.webapp`)
  .component('sbbEditBillingUnitForm', react2angular(BillingReactComponents.EditBillingUnitForm));
angular
  .module(`sb.billing.webapp`)
  .component('sbbSignatureSettings', react2angular(BillingReactComponents.SignatureSettings));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbIntegrationConnectionPanel`, react2angular(BillingReactComponents.IntegrationConnectionPanel));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbNoBillingAccessWarning`, react2angular(BillingReactComponents.NoBillingAccessWarning));

angular
  .module(`sb.billing.webapp`)
  .component(`sbbCreditCardEntryGroup`, react2angular(BillingCreditCardEntryGroup.CreditCardEntryGroupFields));

angular.module(`sb.billing.webapp`).component(`sbbDataMigrationIframe`, react2angular(DataMigrationIframeContainer, ['onClickLink', 'onClose']));
angular.module(`sb.billing.webapp`).component(`sbbArchieIframe`, react2angular(ArchieIframeContainer, ['onClickLink', 'matterId']));

angular
  .module(`sb.billing.webapp`)
  .component(`sbbIntakeIframe`, react2angular(IntakeIframeContainer, ['onClickLink', 'matterId', 'mode']));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbCommunicateIframe`, react2angular(CommunicateIframeContainer, ['onClickLink']));
angular
  .module(`sb.billing.webapp`)
  .component(`sbbCommunicate`, react2angular(CommunicateEmbeddedContainer, ['matterId']));
angular
  .module(`sb.billing.webapp`)
  .component(
    `sbbDocumentList`,
    react2angular(withApolloClient(withReduxProvider(DocumentListContainer(WebComponents.DocumentList))), [
      'entities',
      'onClickLink',
      'searchMeetsMinLength',
    ]),
  );
angular
  .module(`sb.billing.webapp`)
  .component(
    `sbbMemoList`,
    react2angular(withApolloClient(withReduxProvider(DocumentListContainer(WebComponents.MemoList))), [
      'entities',
      'onClickLink',
      'searchMeetsMinLength',
    ]),
  );
angular
  .module(`sb.billing.webapp`)
  .component(
    `sbbTaskList`,
    react2angular(withApolloClient(withReduxProvider(DocumentListContainer(WebComponents.TaskList))), [
      'entities',
      'searchTerm',
      'onClickLink',
      'searchMeetsMinLength',
    ]),
  );
angular
  .module(`sb.billing.webapp`)
  .component(
    `sbbEventList`,
    react2angular(withApolloClient(withReduxProvider(DocumentListContainer(WebComponents.EventList))), [
      'entities',
      'onClickLink',
      'searchMeetsMinLength',
    ]),
  );
angular
  .module(`sb.billing.webapp`)
  .component(
    `sbbRedactedInfo`,
    react2angular(withApolloClient(withReduxProvider(WebComponents.RedactedInfo)), [
      'redacted',
      'field',
      'singular',
      'plural',
    ]),
  );

angular.module(`sb.billing.webapp`).component(`sbbTopnavSearch`, react2angular(withApolloClient(Web.TopnavSearch), ['onClickLink']));
angular.module(`sb.billing.webapp`).component(`sbbPageBannerXero`, react2angular(WebComponents.PageBannerXero));

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DropDownList } from '@sb-itops/react/drop-down-list';
import { forms2PropTypes, ClickableIcon, FormLabel, Typeahead, useTranslation, Spinner } from '@sb-itops/react';
import classnames from 'classnames';
import { contactTypes } from '@sb-customer-management/business-logic/contacts/entities';
import { capitalize } from '@sb-itops/nodash';
import { getCompanyTypes } from '@sb-customer-management/business-logic/contacts/services/get-company-types';
import { schemeMappings, testingGetAllValuesForKey } from '@sb-itops/region-schemes';
import { phonePlaceholders } from '@sb-customer-management/business-logic/contacts/entities/constantsTypescript';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { featureActive } from '@sb-itops/feature';
import { ContactPersonForm } from '../contact-person-form';
import Styles from './ContactCompanyForm.module.scss';
import { AddressForm } from '../address-form';
import { ContactTypeahead2 } from '../contact-typeahead.2';

const ContactCompanyForm = ({
  formInitialised,
  phoneNumberScheme,
  addressScheme,
  companyName,
  companyType,
  companyPhoneAreaCode,
  companyPhoneNumber,
  companyEmail,
  companyFaxAreaCode,
  companyFax,
  companyScheme,
  companyLedesClientId,
  accountName,
  bankName,
  bankBranchNumber,
  accountNumber,
  onFieldValueUpdated,
  deleteField,
  formDisabled,
  addressFields,
  submitFailed,
  additionalContactProps,
  validateForm,
  contactId,
  optionalAddress,
  inline,
  isUtbmsEnabled,
  onAddressAutocompleteSelected,
  showBankDetailsTab,
  peopleOptions,
  peopleOptionsDataLoading,
  peopleOptionsHasMore,
  onFetchPeopleOptions,
  onFetchMorePeopleOptions,
}) => {
  const { t } = useTranslation();
  const [contactIndex, setContactIndex] = useState(0);
  if (!formInitialised) {
    return <Spinner small />;
  }

  const showPhoneAreaCodeField = phoneNumberScheme === schemeMappings.phoneNumberScheme.US;

  const isErrorClassnames = (field, overrideStyle) =>
    field?.isInvalid && (field?.isDirty || submitFailed) ? classnames(Styles.hasError, overrideStyle) : '';

  const onBankFieldsUpdated = (payload) => onFieldValueUpdated({ bankAccountFields: payload });
  const onCompanyFieldsUpdated = (payload) => onFieldValueUpdated({ companyFields: payload });
  const onAddressFieldsUpdated = (payload) => onFieldValueUpdated({ addressFields: payload });

  const companyTypes = getCompanyTypes(companyScheme);

  const additionalContacts = Object.keys(additionalContactProps || {}).map((key) => {
    const onPersonFieldsUpdated = (payload) => onCompanyFieldsUpdated({ additionalContactProps: { [key]: payload } });
    const contact = additionalContactProps[key];
    return (
      <div key={key} className={classnames(Styles.additionalContact, inline ? Styles.mutedShadow : '')}>
        <h5>
          Contact Details{' '}
          <ClickableIcon
            className={classnames('icon-bin-2', Styles.binIcon)}
            onClick={() => {
              deleteField(`companyFields.additionalContactProps.${key}`);
            }}
          />
        </h5>
        <div className={Styles.addExistingContact}>
          <label>Select an Existing Contact?</label>
          {featureActive('NUCWEB-726') ? (
            <ContactTypeahead2
              contactOptions={
                additionalContactProps
                  ? peopleOptions.filter(
                      (option) =>
                        Object.entries(additionalContactProps).filter(
                          (k, c) => c.id?.value === option.value.entityId && k !== key,
                        ).length === 0,
                    )
                  : peopleOptions
              }
              contactOptionsHasMore={peopleOptionsHasMore}
              defaultContactId={contact.id?.value}
              defaultContactOptions={peopleOptions}
              disabled={formDisabled}
              isClearable
              isLoading={peopleOptionsDataLoading}
              maxMenuHeight={250}
              // Callbacks
              onContactSelected={(option) => {
                onCompanyFieldsUpdated({
                  additionalContactProps: {
                    [key]: {
                      contactType: contactTypes.PERSON,
                      title: '',
                      id: option?.value?.entityId || '',
                      firstName: option?.value?.firstName || '',
                      lastName: option?.value?.lastName || '',
                      phoneAreaCode: '',
                      phoneNumber: option?.value?.phone || '',
                      email: option?.value?.email || '',
                      cellAreaCode: '',
                      cellNumber: option?.value?.phone2 || '',
                      businessRole: '',
                      existing: !!option?.value?.entityId,
                    },
                  },
                });
              }}
              onFetchContactOptions={onFetchPeopleOptions}
              onFetchMoreContactOptions={onFetchMorePeopleOptions}
              menuPlacement="top"
              placeholder="Select contact..."
              className={classnames(Styles.formSelect)}
            />
          ) : (
            <Typeahead
              menuPlacement="top"
              selectedOption={contact.id?.value}
              options={
                // Remove already selected options
                additionalContactProps
                  ? peopleOptions.filter(
                      (option) =>
                        Object.entries(additionalContactProps).filter(
                          (k, c) => c.id?.value === option.value.entityId && k !== key,
                        ).length === 0,
                    )
                  : peopleOptions
              }
              disabled={formDisabled}
              placeholder="Select contact..."
              className={classnames(Styles.formSelect)}
              onSelect={(option) => {
                onCompanyFieldsUpdated({
                  additionalContactProps: {
                    [key]: {
                      contactType: contactTypes.PERSON,
                      title: '',
                      id: option?.value?.entityId || '',
                      firstName: option?.value?.firstName || '',
                      lastName: option?.value?.lastName || '',
                      phoneAreaCode: '',
                      phoneNumber: option?.value?.phone || '',
                      email: option?.value?.email || '',
                      cellAreaCode: '',
                      cellNumber: option?.value?.phone2 || '',
                      businessRole: '',
                      existing: !!option?.value?.entityId,
                    },
                  },
                });
              }}
            />
          )}
        </div>
        {!contact.id?.value && (
          <ContactPersonForm
            formInitialised={formInitialised}
            phoneNumberScheme={phoneNumberScheme}
            key={key}
            {...(contact || {})}
            validateForm={validateForm}
            onFieldValueUpdated={onPersonFieldsUpdated}
            submitFailed={submitFailed}
            formDisabled={formDisabled || contact.existing?.value}
            removeRequired={contact.existing?.value}
            isUtbmsEnabled={isUtbmsEnabled}
          />
        )}
        <div className="row">
          <div className="col-xs-6">
            <label>Business Role</label>
            <input
              name="businessRole"
              type="text"
              className={classnames('form-control', isErrorClassnames(contact.businessRole, Styles.formControl))}
              onBlur={validateForm}
              value={contact.businessRole?.value || ''}
              onChange={(e) => {
                onPersonFieldsUpdated({ businessRole: e.target.value });
              }}
            />
          </div>
        </div>
      </div>
    );
  });

  return (
    <div>
      <fieldset disabled={formDisabled}>
        <div className="row">
          <div className="col-xs-8">
            <FormLabel label="Name" field={companyName} submitFailed={submitFailed} />
            <input
              name="companyName"
              type="text"
              className={classnames('form-control', isErrorClassnames(companyName, Styles.formControl))}
              value={companyName?.value || ''}
              onBlur={validateForm}
              onChange={(e) => {
                onCompanyFieldsUpdated({ companyName: e.target.value });
              }}
            />
          </div>
          <div className="col-xs-4">
            <label>Company Type</label>
            <DropDownList
              selectedOption={companyTypes.find(
                (option) => option.value === companyType?.value || option.label === companyType?.value,
              )}
              options={companyTypes}
              disabled={formDisabled}
              onChange={({ value }) => {
                validateForm();
                onCompanyFieldsUpdated({ companyType: value });
              }}
              className={classnames(isErrorClassnames(companyType, Styles.formSelect))}
              stateless
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-6">
            <FormLabel label="Phone" field={companyPhoneNumber} submitFailed={submitFailed} optional />
            <div className="row">
              {showPhoneAreaCodeField && (
                <div className="col-xs-3">
                  <input
                    name="companyPhoneAreaCode"
                    type="text"
                    pattern="[0-9]+"
                    className={classnames('form-control', isErrorClassnames(companyPhoneAreaCode, Styles.formControl))}
                    value={companyPhoneAreaCode?.value || ''}
                    placeholder={phonePlaceholders.phoneAreaCode[phoneNumberScheme]}
                    onBlur={validateForm}
                    onChange={(e) => {
                      onCompanyFieldsUpdated({ companyPhoneAreaCode: e.target.value });
                    }}
                  />
                </div>
              )}
              <div className={showPhoneAreaCodeField ? 'col-xs-9' : 'col-xs-12'}>
                <input
                  name="companyPhoneNumber"
                  type="text"
                  pattern="[0-9]+"
                  className={classnames('form-control', isErrorClassnames(companyPhoneNumber, Styles.formControl))}
                  value={companyPhoneNumber?.value || ''}
                  placeholder={phonePlaceholders.phoneNumber[phoneNumberScheme]}
                  onBlur={validateForm}
                  onChange={(e) => {
                    onCompanyFieldsUpdated({ companyPhoneNumber: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-xs-6">
            <label>Fax</label>
            <div className="row">
              {showPhoneAreaCodeField && (
                <div className="col-xs-3">
                  <input
                    name="companyFaxAreaCode"
                    type="text"
                    pattern="[0-9]+"
                    className={classnames('form-control', isErrorClassnames(companyFaxAreaCode, Styles.formControl))}
                    value={companyFaxAreaCode?.value || ''}
                    onBlur={validateForm}
                    onChange={(e) => {
                      onCompanyFieldsUpdated({ companyFaxAreaCode: e.target.value });
                    }}
                  />
                </div>
              )}
              <div className={showPhoneAreaCodeField ? 'col-xs-9' : 'col-xs-12'}>
                <input
                  name="companyFax"
                  type="text"
                  pattern="[0-9]+"
                  className={classnames('form-control', isErrorClassnames(companyFax, Styles.formControl))}
                  value={companyFax?.value || ''}
                  onBlur={validateForm}
                  onChange={(e) => {
                    onCompanyFieldsUpdated({ companyFax: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <label>Email</label>
            <input
              name="companyEmail"
              type="email"
              className={classnames('form-control', isErrorClassnames(companyEmail, Styles.formControl))}
              value={companyEmail?.value || ''}
              onBlur={validateForm}
              onChange={(e) => {
                onCompanyFieldsUpdated({ companyEmail: e.target.value });
              }}
              required
            />
            {companyEmail?.isInvalid && companyEmail?.invalidReason?.indexOf('valid email') !== -1 && (
              <span className={Styles.errorMessage}>Invalid email format</span>
            )}
          </div>
        </div>
        {showBankDetailsTab && inline && hasFacet(facets.contactBankBalance) && (
          <>
            <hr className={Styles.hr} />
            <div>
              <div className={classnames('form-group')}>
                <div className="row">
                  <div className="form-group col-lg-6">
                    <label>Account Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={accountName.value}
                      onChange={(e) => onBankFieldsUpdated({ accountName: e.target.value })}
                    />
                  </div>
                  <div className="form-group col-lg-6">
                    <label>Bank</label>
                    <input
                      type="text"
                      className="form-control"
                      value={bankName.value}
                      onChange={(e) => onBankFieldsUpdated({ bankName: e.target.value })}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-lg-6">
                    <label>{t('bankBranchNumber')}</label>
                    <input
                      type="text"
                      className="form-control"
                      value={bankBranchNumber.value}
                      onChange={(e) => {
                        const re = /^(\d{1,3}|\d{3}-?\d{0,3})$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                          onBankFieldsUpdated({ bankBranchNumber: e.target.value });
                        }
                      }}
                    />
                  </div>

                  <div>
                    <div className="form-group col-lg-6">
                      <label>Account Number</label>
                      <input
                        type="text"
                        className="form-control"
                        value={accountNumber.value}
                        onChange={(e) => {
                          const re = /^[0-9]*$/;
                          if (e.target.value === '' || re.test(e.target.value)) {
                            onBankFieldsUpdated({ accountNumber: e.target.value });
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <hr className={Styles.hr} />
        <AddressForm
          formInitialised={formInitialised}
          addressScheme={addressScheme}
          {...addressFields}
          onFieldValueUpdated={onAddressFieldsUpdated}
          validateForm={validateForm}
          submitFailed={submitFailed}
          formDisabled={formDisabled}
          optional={optionalAddress}
          onAddressAutocompleteSelected={onAddressAutocompleteSelected}
          forceShowState
        />
        {!!isUtbmsEnabled && (
          <div className="row">
            <div className="col-xs-12">
              <label>Ledes Client Id</label>
              <input
                name="companyLedesClientId"
                type="text"
                className={classnames('form-control', isErrorClassnames(companyLedesClientId, Styles.formControl))}
                value={companyLedesClientId?.value || ''}
                onBlur={validateForm}
                onChange={(e) => {
                  onCompanyFieldsUpdated({ companyLedesClientId: e.target.value });
                }}
                required
              />
            </div>
          </div>
        )}
        {!contactId && (
          <>
            <hr className={classnames(Styles.hr, Styles.hrTop)} />
            <h4>{capitalize(t('organisation'))} Contacts</h4>
            {additionalContacts}
            <div
              className={classnames('link-button', Styles.addContact)}
              onClick={() => {
                onCompanyFieldsUpdated({
                  additionalContactProps: {
                    [`contact${contactIndex}`]: {
                      contactType: contactTypes.PERSON,
                      id: '',
                      title: '',
                      firstName: '',
                      lastName: '',
                      phoneAreaCode: '',
                      phoneNumber: '',
                      email: '',
                      cellAreaCode: '',
                      cellNumber: '',
                      businessRole: '',
                      existing: false,
                    },
                  },
                });
                setContactIndex(contactIndex + 1);
              }}
            >
              <i className="icon icon-add-3" />
              ADD CONTACT
            </div>
          </>
        )}
      </fieldset>
    </div>
  );
};

const { Forms2Field } = forms2PropTypes;

ContactCompanyForm.displayName = 'ContactCompanyForm';
ContactCompanyForm.propTypes = {
  formInitialised: PropTypes.bool,
  showBankDetailsTab: PropTypes.bool,
  submitFailed: PropTypes.bool,
  addressFields: PropTypes.object,
  additionalContactProps: PropTypes.object,
  phoneNumberScheme: PropTypes.oneOf(testingGetAllValuesForKey('phoneNumberScheme')).isRequired,
  addressScheme: PropTypes.oneOf(testingGetAllValuesForKey('addressScheme')).isRequired,
  companyScheme: PropTypes.oneOf(testingGetAllValuesForKey('companyScheme')).isRequired,
  companyName: Forms2Field,
  companyType: Forms2Field,
  companyPhoneAreaCode: Forms2Field,
  companyPhoneNumber: Forms2Field,
  companyEmail: Forms2Field,
  companyFax: Forms2Field,
  companyFaxAreaCode: Forms2Field,
  companyLedesClientId: Forms2Field,
  accountName: Forms2Field,
  accountNumber: Forms2Field,
  bankBranchNumber: Forms2Field,
  bankName: Forms2Field,
  onAddressAutocompleteSelected: PropTypes.func.isRequired,
  onFieldValueUpdated: PropTypes.func.isRequired,
  deleteField: PropTypes.func.isRequired,
  formDisabled: PropTypes.bool,
  validateForm: PropTypes.func.isRequired,
  peopleOptions: PropTypes.arrayOf(PropTypes.object),
  contactId: PropTypes.string,
  optionalAddress: PropTypes.bool,
  inline: PropTypes.bool,
  isUtbmsEnabled: PropTypes.bool,
  peopleOptionsDataLoading: PropTypes.bool,
  peopleOptionsHasMore: PropTypes.bool,
  onFetchPeopleOptions: PropTypes.func,
  onFetchMorePeopleOptions: PropTypes.func,
};

ContactCompanyForm.defaultProps = {
  formInitialised: false,
  showBankDetailsTab: false,
  submitFailed: false,
  addressFields: undefined,
  additionalContactProps: undefined,
  companyName: undefined,
  companyType: undefined,
  accountName: undefined,
  accountNumber: undefined,
  bankBranchNumber: undefined,
  bankName: undefined,
  companyPhoneAreaCode: undefined,
  companyPhoneNumber: undefined,
  companyEmail: undefined,
  companyFax: undefined,
  companyFaxAreaCode: undefined,
  companyLedesClientId: undefined,
  formDisabled: false,
  peopleOptions: [],
  contactId: undefined,
  optionalAddress: false,
  inline: false,
  isUtbmsEnabled: undefined,
  peopleOptionsDataLoading: undefined,
  peopleOptionsHasMore: undefined,
  onFetchPeopleOptions: undefined,
  onFetchMorePeopleOptions: undefined,
};

export default ContactCompanyForm;

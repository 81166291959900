/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import { StatelessSelect, CurrencyInput2, forms2PropTypes, FormLabel } from '@sb-itops/react';
import { featureActive } from '@sb-itops/feature';
import {
  billingFrequency as billingFrequencyValues,
  billingFrequencySubType as billingFrequencySubTypeValues,
  billingUnitOptionValues,
  getBillingFrequencySubTypeFieldLabel,
} from '@sb-billing/business-logic/matters/billing-config';
import classnames from 'classnames';

import { getContactTypeAheadSummaries } from 'web/redux/selectors/typeahead';
import { SimpleContactMultiSelect } from '../../simple-contact-multi-select';
import { billingTypes } from '../../add-edit-matter/matter-fee-and-rate-settings/billing-types';
import { shouldShowFieldError, includeErrorClassnameIfInvalid } from '../../add-edit-matter/include-error-classname-if-invalid';
import { HourlyRateConfigV2 } from '../../hourly-rate-config';
import Styles from './MatterBilling.module.scss';

export const MatterBilling = ({
  staffMembers,
  billingTypeOptions,
  billingUnitOptions,
  rateSets,
  billingFrequencyOptions,
  billingFrequencySubTypeOptions,
  onClickLink,
  // form state
  formInitialised,
  formDisabled,
  submitFailed,
  // form fields
  billingType,
  amount,
  contingencyAmount,
  billingUnitOption,
  customUnit,
  rateType,
  rate,
  customRatePerStaffMatterHourlyRates,
  rateSetId,
  billingFrequency,
  billingFrequencySubType,
  debtorIds,
  // callbacks
  onDebtorsChanged,
  onFieldValuesUpdated,
  onHourlyRateChange,
}) => {
  if (!formInitialised) {
    return null;
  }

  const contactOptions = getContactTypeAheadSummaries().reduce(
    (acc, { display: label, id: value, typeahead: searchText, isDeleted }) => {
      if (!isDeleted) {
        acc.push({
          value,
          label,
          searchText,
        });
      }
      return acc;
    },
    [],
  );

  const hasHourlyRateConfigError =
    shouldShowFieldError(rate, submitFailed) ||
    (submitFailed && rateType.value === 2 && customRatePerStaffMatterHourlyRates.length < 1) || // this is due to the way form2 handles arrays differently to other fields
    shouldShowFieldError(rateSetId, submitFailed);

  /**
   * If Billing Type is null, not set or ““ (empty string), the fee/estimate field will be hidden
   * If Billing Type is "Not Billable", the fee/estimate field will be disabled.
   * If Billing Type is set to "Contingency (%)":
   * the label of the field will be "Contingency (%)" (instead of “Fee/Estimate”), the value entered will be saved to  contingencyAmount
   * If Billing Type is set to any other type:
   * the label of the field will be "Fee/Estimate", the value will be saved to `amount`
   */
  return (
    <fieldset className="section-fieldset" disabled={formDisabled}>
            <div className="row">
        <div className="col-lg-12 form-group">
          <FormLabel
            label="Debtor"
            explainerText="If left blank, the first client will be used as the debtor on this matter"
            submitFailed={submitFailed}
            optional
            uppercase={false}
          />
          <div>
            <SimpleContactMultiSelect
              selectedContactIds={debtorIds}
              contactOptions={contactOptions}
              onContactsChanged={onDebtorsChanged}
              max={3}
              isRequired={false}
              disabled={formDisabled}
              onClickLink={onClickLink}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 form-group">
          <FormLabel label="Billing Type" uppercase={false} field={billingType} submitFailed={submitFailed} optional />
          <div>
            <StatelessSelect
              options={billingTypeOptions}
              disabled={formDisabled}
              selectedOption={billingType && billingType.value}
              onChange={(selectedBillingType) => onFieldValuesUpdated({ billingType: selectedBillingType.value })}
            />
          </div>
        </div>
        {billingType.value && (
          <div className="col-sm-6 form-group">
            {billingType.value !== billingTypes.CONTINGENCY_P && (
              <>
                <FormLabel uppercase={false} label="Fee/Estimate" field={amount} submitFailed={submitFailed} optional />
                <div>
                  <CurrencyInput2
                    disabled={formDisabled || billingType.value === billingTypes.NOT_BILLABLE}
                    value={amount.value}
                    onChange={(e) => onFieldValuesUpdated({ amount: e.target.value })}
                    hasError={shouldShowFieldError(amount, submitFailed)}
                    useValue
                  />
                </div>
              </>
            )}
            {billingType.value === billingTypes.CONTINGENCY_P && (
              <>
                <FormLabel uppercase={false} label="Contingency (%)" field={contingencyAmount} submitFailed={submitFailed} optional />
                <div>
                  <input
                    className={classnames(
                      'form-control',
                      includeErrorClassnameIfInvalid(contingencyAmount, submitFailed),
                    )}
                    type="number"
                    disabled={formDisabled}
                    value={Number.isFinite(contingencyAmount.value) ? contingencyAmount.value : ''}
                    onChange={(e) =>
                      onFieldValuesUpdated({
                        contingencyAmount:
                          // isNaN('') returns false, but parseFloat('') returns NaN
                          // basically we want to make it so the field is undefined if it's not
                          // a number, we can then use this to decide to send a default of 0
                          // why not just set it to 0 here? because from a UX perspective it's
                          // not nice that the user's trying to clear the field and we keep
                          // setting it back to 0 for them, it would be a very clunky experience
                          e.target.value !== '' && !Number.isNaN(e.target.value)
                            ? Math.round(parseFloat(e.target.value) * 100) / 100
                            : undefined,
                      })
                    }
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>

      {featureActive('BB-7624') && (
        <div className="row">
          <div className="col-sm-6 form-group">
            <FormLabel uppercase={false} label="Billing Units Round To" field={billingType} submitFailed={submitFailed} optional />
            <div>
              <StatelessSelect
                options={billingUnitOptions}
                disabled={formDisabled}
                selectedOption={billingUnitOption && billingUnitOption.value}
                onChange={(selectedBillingUnit) => {
                  onFieldValuesUpdated({ billingUnitOption: selectedBillingUnit.value });
                  onFieldValuesUpdated({ customUnit: null });
                }}
              />
            </div>
          </div>
          {billingUnitOption?.value === billingUnitOptionValues.CUSTOM && (
            <div className={classnames('col-sm-6 form-group', Styles.customUnitFormGroup)}>
              <FormLabel uppercase={false} label="Minutes" field={customUnit} submitFailed={submitFailed} optional />
              <div>
                <input
                  type="number"
                  min="1"
                  max="60"
                  className={classnames('form-control', includeErrorClassnameIfInvalid(customUnit, submitFailed))}
                  value={customUnit?.value || ''}
                  step={1}
                  name="customUnit"
                  onChange={(e) => {
                    onFieldValuesUpdated({ customUnit: e.target.value });
                  }}
                />
                {shouldShowFieldError(customUnit, submitFailed) && customUnit?.value && (
                  <span className={Styles.customUnitErrorMessage}>Valid: 1 to 60</span>
                )}
              </div>
            </div>
          )}
        </div>
      )}

      <div className="row">
        <div className="col-lg-12 form-group">
          <FormLabel uppercase={false} label="Hourly Rates" field={rateType} submitFailed={submitFailed} optional />
          <HourlyRateConfigV2
            staffMembers={staffMembers}
            rateOverrideType={rateType.value}
            rate={rate && rate.value}
            customRatePerStaff={customRatePerStaffMatterHourlyRates}
            rateSetId={rateSetId && rateSetId.value}
            onChange={onHourlyRateChange}
            rateSets={rateSets}
            // HourlyRateConfig updates all fields onChange, thus all fields are marked as dirty
            hasError={hasHourlyRateConfigError}
            disabled={formDisabled}
          />
        </div>
      </div>

      {featureActive('BB-11704') && (
        <div className="row">
          <div className="col-sm-6 form-group">
            <FormLabel
              label="Billing Frequency"
              field={billingFrequency}
              explainerText="Billing frequency allows you to filter your invoices by this value. It won’t affect creating and sending invoices."
              submitFailed={submitFailed} uppercase={false}
              optional />
            <div>
              <StatelessSelect
                options={billingFrequencyOptions}
                disabled={formDisabled}
                selectedOption={billingFrequency && billingFrequency.value}
                onChange={(selectedBillingFrequency) =>
                  onFieldValuesUpdated({
                    billingFrequency: selectedBillingFrequency.value,
                    billingFrequencySubType: selectedBillingFrequency.value === billingFrequencyValues.HOLD
                      ? billingFrequencySubTypeValues.HOLD
                      : billingFrequencySubTypeValues.NONE,
                  })
                }
              />
            </div>
          </div>
          {[billingFrequencyValues.MONTHLY, billingFrequencyValues.QUARTERLY, billingFrequencyValues.ANNUALLY].includes(billingFrequency.value) && (
            <div className="col-sm-6 form-group">
              <FormLabel
                label={getBillingFrequencySubTypeFieldLabel({ billingFrequency: billingFrequency.value})}
                field={billingFrequencySubType}
                submitFailed={submitFailed} uppercase={false}
                optional />
              <div>
                <StatelessSelect
                  className={classnames(
                    billingFrequencySubType.isInvalid ? Styles.hasError : undefined,
                  )}
                  options={billingFrequencySubTypeOptions}
                  disabled={formDisabled}
                  selectedOption={billingFrequencySubType && billingFrequencySubType.value}
                  onChange={(selectedBillingFrequencySubType) =>
                    onFieldValuesUpdated({ billingFrequencySubType: selectedBillingFrequencySubType.value })
                  }
                />
              </div>
            </div>
          )}
        </div>
      )}
    </fieldset>
  );
};

const { Forms2Field } = forms2PropTypes;

MatterBilling.propTypes = {
  staffMembers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      initials: PropTypes.string,
      isFormerStaff: PropTypes.bool,
      name: PropTypes.string,
      rate: PropTypes.number,
    }),
  ),
  billingTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  billingUnitOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  rateSets: PropTypes.arrayOf(PropTypes.object),
  billingFrequencyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  billingFrequencySubTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
  // form state
  formInitialised: PropTypes.bool.isRequired,
  formDisabled: PropTypes.bool,
  submitFailed: PropTypes.bool,
  // form fields
  billingType: Forms2Field,
  amount: Forms2Field,
  contingencyAmount: Forms2Field,
  billingUnitOption: Forms2Field,
  customUnit: Forms2Field,
  rateType: Forms2Field,
  rate: Forms2Field,
  customRatePerStaffMatterHourlyRates: PropTypes.arrayOf(
    PropTypes.shape({
      staffId: PropTypes.string,
      rate: PropTypes.number,
    }),
  ),
  debtorIds: PropTypes.arrayOf(PropTypes.string),
  rateSetId: Forms2Field,
  billingFrequency: Forms2Field,
  billingFrequencySubType: Forms2Field,
  // callbacks
  onFieldValuesUpdated: PropTypes.func.isRequired, // generic method for updating fields
  onHourlyRateChange: PropTypes.func.isRequired, // method for updating fields of hourly rate settings.
  onDebtorsChanged: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
};

MatterBilling.defaultProps = {
  staffMembers: [],
  billingTypeOptions: [],
  billingUnitOptions: [],
  debtorIds: [],
  rateSets: [],
  billingFrequencyOptions: [],
  billingFrequencySubTypeOptions: [],
  // form state
  formDisabled: undefined,
  submitFailed: undefined,
  // form fields
  billingType: undefined,
  amount: undefined,
  contingencyAmount: undefined,
  billingUnitOption: undefined,
  customUnit: undefined,
  rateType: undefined,
  rate: undefined,
  customRatePerStaffMatterHourlyRates: undefined, // form field values
  rateSetId: undefined,
  billingFrequency: undefined,
  billingFrequencySubType: undefined,
};

MatterBilling.displayName = 'MatterBilling';

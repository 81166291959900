import {
  getById as getTxById,
  updateCache as updateRedux,
  clearCache as clearRedux,
} from '@sb-billing/redux/transaction-receipts';

angular.module('@sb-billing/services').service('sbTransactionReceiptService', function (sbGenericEndpointService, sbGenericCacheService, sbEndpointType, sbLoggerService, sbTransactionService) {
  'use strict';

  const that = this;
  const log = sbLoggerService.getLogger('sbTransactionReceiptService');
  const transactionReceiptEndpoint = 'billing/transaction-receipts';

  sbGenericCacheService.setupCache({
    name: 'sbTransactionReceiptService',
    sync: {
      endpoint: { type: sbEndpointType.SYNC_SINCE, stub: transactionReceiptEndpoint },
      poll: 60,
      subscriptions: 'notifier.AccountsNotifications.ReceiptUpdated'
    },
    updateRedux,
    clearRedux,
  });

  that.hasReceipt = hasReceipt;
  that.getById = getById;
  that.sendReceipt = sendReceipt;

  function hasReceipt(id) {
    return !!getById(id);
  }

  // if its a reversal, the receipt for the transaction will the receipt of the original transaction
  function getById(id) {
    const transaction = sbTransactionService.getById(id);

    if (transaction && transaction.type === 'DepositReversal') {
      return getById(transaction.reversedFromTransactionId);
    }

    return getTxById(id);
  }

  function sendReceipt (receiptId, receiptEmail) {
    return sbGenericEndpointService.postPayloadP(transactionReceiptEndpoint, `email/${receiptId}`, receiptEmail)
      .then((resp) => {
        log.debug('sending receipt done: ', JSON.stringify(resp));
      })
      .catch((err) => {
        const errMsg = 'ERROR' || err || err.message;
        log.error('failed to send transaction receipt', errMsg);
        log.debug('failed to send transaction receipt', receiptEmail, 'ERROR:', err);
        throw err;
      });
  }
});

/**
 * NOTICE:
 *
 * This is a Load on Demand compatible route container, meaning that neither
 * this container nor any of the sub-component/containers should have a
 * dependency on:
 * - Entity caches
 * - Angular services
 */
import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { debounce } from '@sb-itops/nodash';
import { useSelector } from 'react-redux';
import { todayAsInteger } from '@sb-itops/date';
import { featureActive } from '@sb-itops/feature';
import { getLogger } from '@sb-itops/fe-logger';
import { error as displayErrorToUser } from '@sb-itops/message-display';
import composeHooks from '@sb-itops/react-hooks-compose';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import { setModalDialogVisible, isModalVisible } from '@sb-itops/redux/modal-dialog';
import { getRegion } from '@sb-itops/region';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { sort as sortItems } from '@sb-itops/sort';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { useSort } from '@sb-itops/redux/sort/use-sort';
import { useMultipleItemSelection } from '@sb-itops/redux/multi-item-select/use-multiple-item-selection';
import { useTranslation } from '@sb-itops/react';
import uuid from '@sb-itops/uuid';
import {
  filterUtbmsTaskCodesExpenses,
  mapActivitiesByCategory,
  mapTasksByCategory,
} from '@sb-billing/business-logic/activities/services';
import { entryType as entryTypesEnum, entryTypeLabels } from '@sb-billing/business-logic/shared/entities';
import { getDefaultOperatingChequePrintSettings, isChequesEnabledForAccount } from '@sb-billing/business-logic/cheques';
import { getMatterDisplay } from '@sb-matter-management/business-logic/matters/services';
import {
  CREATE_OPERATING_CHEQUE_MODAL_ID,
  PRINT_OPERATING_CHEQUE_MODAL_ID as LOD_PRINT_OPERATING_CHEQUE_MODAL_ID,
} from 'web/components';
import {
  ExpenseCounts,
  ExpenseTableData,
  InitActivityCodes,
  InitFirmTaxSettings,
  InitFirmUtbmsSettings,
  InitOperatingChequePrintSettings,
  InitStaffSettings,
  MatterSummaries,
  OperatingChequeCountData,
} from 'web/graphql/queries';
import { useCacheQuery, useSubscribedQuery, useSubscribedLazyQuery, usePagination } from 'web/hooks';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import * as expenseFiltersFeature from 'web/redux/features/expense-filters';
import { FirmExpenseEntries } from './FirmExpenseEntries';

const REGION = getRegion();

const log = getLogger('FirmExpenseEntries.container');

const FETCH_LIMIT = 50;

const SCOPE = 'FirmExpenseEntriesRoute';
const SCOPE_QUICK_ADD = `${SCOPE}/quick-expense-add`;
const SCOPE_FILTER = `firm-expense-list`; // Keeping the same scope in order to retain any existing settings
const expenseTableScope = `${SCOPE}/expense-table`;

const hooks = ({ onOpenEditModal, onChequeClick, onClickLink, overrideRedirect }) => ({
  useScope: () => ({
    scope: SCOPE,
    scopeQuickAdd: SCOPE_QUICK_ADD,
    filterScope: SCOPE_FILTER,
    region: REGION,
  }),
  useFilterDateRange: () => {
    const [filterDateRange, setFilterDateRange] = useState();
    const onUpdateFilterDateRange = ({ from, to }) => {
      setFilterDateRange({ from, to });
    };

    return {
      filterDateRange,
      onUpdateFilterDateRange,
    };
  },
  useStaffMemberData: () => {
    const { data: staffData } = useCacheQuery(InitStaffSettings.query);

    const { staffMembers, staffMemberOptions } = useMemo(() => {
      const allStaffMembers = staffData?.staffMembers || [];
      const allStaffMembersSorted = sortItems(allStaffMembers, ['initials', 'name'], ['ASC', 'ASC']);

      const sortedOptions = allStaffMembersSorted.map((staffMemberEntity) => ({
        value: staffMemberEntity.id,
        label: `${staffMemberEntity.initials} (${staffMemberEntity.name})`,
        entity: {
          ...staffMemberEntity,
          rate: staffMemberEntity.rate || 0,
        },
      }));

      const currentStaff = allStaffMembersSorted.filter((staff) => !staff.isFormerStaff);

      return {
        staffMembers: currentStaff,
        staffMemberOptions: sortedOptions,
      };
    }, [staffData?.staffMembers]);

    return { staffMembers, loggedInStaff: staffData?.loggedInStaff, staffMemberOptions };
  },
  useFilterStaff: () => {
    // If persisted state does not contain selectedStaffIds, the ExpenseFilter
    // container will trigger an update and feed the logged in user's ID here.
    // It will also do the same for any change to selectedStaffIds.
    const [staffIds, onSelectStaffIds] = useState();

    return { staffIds, onSelectStaffIds };
  },
  useFilterStatus: () => {
    const expenseFilters = useScopedFeature(expenseFiltersFeature, SCOPE_FILTER);
    const showDisbursements = useSelector(expenseFilters.selectors.getShowDisbursements);
    const showAnticipatedDisbursements = useSelector(expenseFilters.selectors.getShowAnticipatedDisbursements);
    const showAnticipatedDisbursementsUnpaid = useSelector(
      expenseFilters.selectors.getShowAnticipatedDisbursementsUnpaid,
    );
    const showAnticipatedDisbursementsOverdue = useSelector(
      expenseFilters.selectors.getShowAnticipatedDisbursementsOverdue,
    );
    const showAnticipatedDisbursementsPaid = useSelector(expenseFilters.selectors.getShowAnticipatedDisbursementsPaid);
    const hideIfOnACheck = useSelector(expenseFilters.selectors.getHideIfOnACheck) || false; // US naming for consistency
    const hideIfOnFinalisedInvoice = useSelector(expenseFilters.selectors.getHideIfOnFinalisedInvoice) || false;

    // If status filters are null, fetch all available expenses (do not filter)
    const expenseStatusFilters = !featureActive('BB-9573')
      ? null
      : {
          includeDisbursements: showDisbursements,
          includeAnticipated: showAnticipatedDisbursements,
          includeAnticipatedUnpaid: showAnticipatedDisbursementsUnpaid,
          includeAnticipatedOverdue: showAnticipatedDisbursementsOverdue,
          includeAnticipatedPaid: showAnticipatedDisbursementsPaid,
          anticipatedOverdueStartDate: todayAsInteger(),
        };

    const expenseOtherFilters = {
      excludeIfOnCheque: hideIfOnACheck,
      excludeIfOnFinalisedInvoice: hideIfOnFinalisedInvoice,
    };

    return {
      expenseStatusFilters,
      showAnticipatedDisbursementColumn: expenseStatusFilters?.includeAnticipated,
      expenseOtherFilters,
    };
  },
  useSort: () => {
    const { sortBy, setSortBy, sortDirection, setSortDirection } = useSort({
      scope: expenseTableScope,
      initialSortBy: 'expenseDate',
      initialSortDirection: 'desc',
    });

    const onSort = (sortProps) => {
      setSortBy(sortProps.sortBy);
      setSortDirection(sortProps.sortDirection);
    };

    return {
      sortBy,
      sortDirection,
      onSort,
    };
  },
  useBulkActions: () => {
    const {
      selectedItems: selectedExpenseIds,
      toggleItems,
      deselectAllItems: onDeselectAllExpenses,
    } = useMultipleItemSelection({
      scope: expenseTableScope,
    });

    return {
      selectedExpenseIds,
      onDeselectAllExpenses,
      onToggleExpenses: (expenses) => {
        toggleItems(expenses.map((expense) => expense.id));
      },
    };
  },
  useMatterSummaryData: () => {
    const [getMatterSummaries, matterSummariesResult] = useSubscribedLazyQuery(MatterSummaries, {
      context: { skipRequestBatching: true },
      variables: {
        includeMatterHourlyRate: false,
        filter: {
          matterStatus: ['pending', 'open'],
        },
        limit: 25,
        sort: {
          fieldNames: ['statusOpen', 'matterStarted'],
          directions: ['DESC', 'DESC'],
        },
      },
    });

    const results = matterSummariesResult.data?.matterSearch?.results;

    const matterSummaries = useMemo(() => {
      const summaries = !results?.length
        ? []
        : results.map((matter) => {
            const typeahead = [
              matter.matterNumber,
              matter.clientDisplay,
              matter.otherSideDisplay,
              matter.matterType?.name,
              matter.attorneyResponsible?.name,
              matter.attorneyResponsible?.initials,
              matter.description,
            ];

            const matterStartedISO = matter.matterStarted ? moment(matter.matterStarted, 'YYYYMMDD').toISOString() : '';

            return {
              ...matter,
              display: getMatterDisplay(matter, matter.matterType?.name),
              matterClientNames: matter.clientNames,
              matterStarted: matter.matterStarted ? new Date(matter.matterStarted) : undefined,
              matterStartedISO,
              typeahead: typeahead.filter((m) => m).join(' '),
            };
          });

      return summaries;
    }, [results]);

    const getMatterSummariesBySearchText = debounce(
      (searchText = '') => {
        getMatterSummaries({
          variables: {
            searchText,
            offset: 0,
          },
        });
      },
      300, // wait in milliseconds
      { leading: false },
    );

    const onFetchMatterSummaries = (searchText = '') => {
      // When the matter typeahead (Select) loses focus, it executes this
      // function with an empty string, returning different results.
      if (searchText.length > 2) {
        getMatterSummariesBySearchText(searchText);
      }

      return searchText;
    };

    const onFetchMoreMatterSummaries = async () => {
      if (!matterSummariesResult.data?.matterSearch?.pageInfo?.hasNextPage) {
        return undefined;
      }

      const fetchMoreResults = await matterSummariesResult.fetchMore({
        variables: {
          offset: matterSummariesResult.data.matterSearch.results.length || 0,
        },
      });

      return fetchMoreResults;
    };

    return {
      matterSummaries,
      matterSummariesDataLoading: matterSummariesResult.loading,
      matterSummariesHasMore: matterSummariesResult.data?.matterSearch?.pageInfo?.hasNextPage || false,
      onFetchMatterSummaries,
      onFetchMoreMatterSummaries,
    };
  },
  useTaxSettings: () => {
    const { data: firmTaxSettingsData } = useCacheQuery(InitFirmTaxSettings.query);

    const { taxRate: taxRateBasisPoints, registeredForGst } = firmTaxSettingsData?.firmTaxSettings || {};

    return {
      taxRateBasisPoints,
      registeredForGst,
    };
  },
  useUtbmsSettings: () => {
    const { data: firmUtbmsSettingsData } = useCacheQuery(InitFirmUtbmsSettings.query);

    const utbmsEnabledForFirm =
      (hasFacet(facets.utbms) && firmUtbmsSettingsData?.firmUtbmsSettings?.isUtbmsEnabled) || false;
    const utbmsCodesRequiredByFirm =
      utbmsEnabledForFirm && firmUtbmsSettingsData?.firmUtbmsSettings?.utbmsCodesRequired;

    const utbmsCodeSetsUsedByFirm = firmUtbmsSettingsData?.firmUtbmsSettings?.selectedCodeSets;

    return {
      utbmsEnabledForFirm,
      utbmsCodeSetsUsedByFirm,
      utbmsCodesRequiredByFirm,
      showTaskColumn: utbmsEnabledForFirm,
    };
  },
  useActivityCodes: () => {
    const activityCodesResult = useCacheQuery(InitActivityCodes.query, {
      // The variables must match init query
      variables: {
        includeUtbmsCodes: hasFacet(facets.utbms),
        isUtbmsEnabledCheck: true,
      },
    });

    const activities = useMemo(
      () =>
        mapActivitiesByCategory({
          activityCodes: activityCodesResult?.data?.activityCodes,
          utbmsActivityCodes: activityCodesResult?.data?.utbmsActivityCodes,
          filterCustomCodeByTypes: [entryTypesEnum.EXPENSE],
        }),
      [activityCodesResult?.data?.activityCodes, activityCodesResult?.data?.utbmsActivityCodes],
    );

    const tasks = useMemo(
      () =>
        mapTasksByCategory({
          utbmsTaskCodes: activityCodesResult?.data?.utbmsTaskCodes,
          utbmsCustomTaskCodes: activityCodesResult?.data?.utbmsCustomTaskCodes,
          filterCustomCodeByTypes: [entryTypeLabels.EXPENSE],
        }),
      [activityCodesResult?.data?.utbmsTaskCodes, activityCodesResult?.data?.utbmsCustomTaskCodes],
    );

    return {
      activities,
      tasks,
    };
  },
  useCheques: () => {
    const { data: chequePrintData } = useCacheQuery(InitOperatingChequePrintSettings.query);

    const bankAccountChequePrintSettings =
      chequePrintData?.operatingChequePrintSettings?.[0] || getDefaultOperatingChequePrintSettings({ region: REGION });

    return {
      showChequeCreateSelectColumn: isChequesEnabledForAccount({ bankAccountChequePrintSettings }),
      onViewOperatingCheques: () => {
        // The forget account state params is an override for the tab remembering on the transactions page
        // It means the next navigation will be guaranteed to navigate to the page specified
        overrideRedirect();
        onClickLink({ type: 'transactions', id: 'operating-cheques' });
      },
    };
  },
  useModal: () => ({
    onOpenExpenseModal: onOpenEditModal,
    onOpenChequeModal: onChequeClick,
  }),
  useColumnDisplay: () => ({
    anticipatedDisbursementsEnabled: featureActive('BB-9573'),
    showBulkUpdateSelectColumn: false, // Matter level only
    showMatterColumn: true,
    showTaxColumns: hasFacet(facets.tax),
  }),
});

// These hooks will be called once the hooks above have completed, and will
// receive their props. This is required as we cannot retrieve values from
// adjacent hooks in composeHooks
const queryHooks = ({
  anticipatedDisbursementsEnabled,
  expenseOtherFilters,
  expenseStatusFilters,
  filterDateRange,
  selectedExpenseIds,
  sortBy,
  sortDirection,
  staffIds,
  tasks,
  utbmsEnabledForFirm,
  utbmsCodeSetsUsedByFirm,
  onExportExpenseCsv,
  sbAsyncOperationsService,
}) => ({
  useChequeActions: () => {
    const { t } = useTranslation();
    const operatingChequesQueryResult = useSubscribedQuery(OperatingChequeCountData, {
      variables: {
        filter: {
          isPrinted: false,
          isReversed: false,
        },
        offset: 0,
        // Whilst we are only fetching the totals, this value does not matter
        // However, if we need to fetch the relevant IDs, it may well be different
        // from the page FETCH_LIMIT
        limit: FETCH_LIMIT,
      },
    });

    const { data: chequeData } = operatingChequesQueryResult;
    const printChequeCount = chequeData?.operatingChequeList?.totalCount || 0;

    const PRINT_OPERATING_CHEQUE_MODAL_ID = 'print-operating-cheque-modal';

    const onOpenPrintChequeModal = () => {
      if (featureActive('BB-13415')) {
        // LOD print operating cheques modal, expecting different props
        setModalDialogVisible({ modalId: LOD_PRINT_OPERATING_CHEQUE_MODAL_ID, props: { sbAsyncOperationsService } });
      } else {
        setModalDialogVisible({
          modalId: PRINT_OPERATING_CHEQUE_MODAL_ID,
        });
      }
    };

    const onCreateChequeClick = () => {
      const numberOfSelectedExpenses = Object.keys(selectedExpenseIds).length;
      if (numberOfSelectedExpenses > 100) {
        displayErrorToUser(
          `A maximum of 100 ${t('expenses')} can be added to an ${t('operatingCheque')}. Please deselect ${
            numberOfSelectedExpenses - 100
          } ${t('expenses')} and try again.`,
        );
      } else {
        setModalDialogVisible({
          modalId: CREATE_OPERATING_CHEQUE_MODAL_ID,
        });
      }
    };

    const isCreateOperatingChequeModalVisible = isModalVisible({ modalId: CREATE_OPERATING_CHEQUE_MODAL_ID });

    return {
      printChequeCount,
      createChequeButtonEnabled: Object.keys(selectedExpenseIds).length > 0,
      isCreateOperatingChequeModalVisible,
      onCreateChequeClick,
      onOpenPrintChequeModal,
    };
  },
  useExport: () => {
    const [generatingPdf, setGeneratingPdf] = useState(false);
    const [exportFileUrl, setExportFileUrl] = useState('');

    const filters = {
      selectedFromDate: filterDateRange?.from,
      selectedToDate: filterDateRange?.to,
      selectedStaffIds: staffIds,
      hideIfOnFinalisedInvoice: expenseOtherFilters.excludeIfOnFinalisedInvoice,
      hideIfOnACheck: expenseOtherFilters.excludeIfOnCheque,
      showDisbursements: expenseStatusFilters?.includeDisbursements,
      showAnticipatedDisbursements: expenseStatusFilters?.includeAnticipated,
      showAnticipatedDisbursementsUnpaid: expenseStatusFilters?.includeAnticipatedUnpaid,
      showAnticipatedDisbursementsOverdue: expenseStatusFilters?.includeAnticipatedOverdue,
      showAnticipatedDisbursementsPaid: expenseStatusFilters?.includeAnticipatedPaid,
    };

    const sort = {
      sortBy,
      sortDirection,
    };

    /**
     * Export Expenses
     *
     * @param {object} data
     * @param {string} [data.exportId]
     * @param {PDF|CSV} data.type
     * @param {object} data.filters
     * @param {object} data.sort
     * @param {string} data.matterId 'all' for all matters, otherwise the matterId
     * @param {string} [data.matterName] Required if for a single matter
     * @param {boolean} [data.isUtbmsEnabled]
     * @returns {Promise<object>} fetch response object
     */
    const printExportExpenses = async (data) => {
      const exportId = data.exportId || uuid();
      const path = `/billing/expenses/export/:accountId/${exportId}`;
      const fetchOptions = { body: JSON.stringify({ ...data, exportId }) };
      return fetchPostP({ path, fetchOptions });
    };

    const onGeneratePDFReport = async () => {
      const type = 'PDF';
      const exportId = uuid();
      setGeneratingPdf(true);

      try {
        const res = await printExportExpenses({
          type,
          exportId,
          filters,
          sort,
          matterId: 'all',
          isUtbmsEnabled: utbmsEnabledForFirm,
          includeLeads: featureActive('BB-6595'),
        });

        setExportFileUrl(res.body.pdfLocation);
      } catch (err) {
        log.error(`Failed to export '${type}', exportId: ${exportId}`, err);
        displayErrorToUser(`Export failed to generate ${type} export - please try again later.`);
      } finally {
        setGeneratingPdf(false);
      }
    };

    const onGenerateCSVReport = async () => {
      const type = 'CSV';
      const exportId = uuid();

      try {
        // Currently using sbAsyncOperationsService from the controller
        onExportExpenseCsv({
          type,
          exportId,
          filters,
          sort,
          matterId: 'all',
          isUtbmsEnabled: utbmsEnabledForFirm,
        });
      } catch (err) {
        log.error(`Failed to export '${type}', exportId: ${exportId}`, err);
        displayErrorToUser(`Export failed to generate ${type} export - please try again later.`);
      }
    };

    return {
      exportFileUrl,
      isGeneratingPDFReport: generatingPdf,
      onGeneratePDFReport,
      onGenerateCSVReport,
      onCloseExportModal: () => setExportFileUrl(''),
    };
  },
  useFirmUtbmsTasks: () => {
    // If we haven't yet received the task codes used by the firm, we aren't
    // able to filter from the full list.
    // In this case we won't show any utbms tasks.
    if (!utbmsEnabledForFirm || !utbmsCodeSetsUsedByFirm) {
      return {
        tasks: [],
      };
    }

    const filteredTasks = filterUtbmsTaskCodesExpenses({
      utbmsTaskCodes: tasks, // both standard and custom task codes
      utbmsCodeSetsUsedByFirm,
    });

    return {
      tasks: filteredTasks,
    };
  },
  useExpenseCountsData: () => {
    const { data, error, loading } = useSubscribedQuery(ExpenseCounts, {
      skip: !anticipatedDisbursementsEnabled || !filterDateRange,
      variables: {
        filter: {
          expenseDate: filterDateRange,
          expenseEarnerStaffIds: staffIds,
          includeStatus: { current: true },
          excludeIfOnCheque: expenseOtherFilters.excludeIfOnCheque,
          excludeIfOnFinalisedInvoice: expenseOtherFilters.excludeIfOnFinalisedInvoice,
          includePseudoStatus: !expenseStatusFilters
            ? undefined
            : { anticipatedOverdueStartDate: expenseStatusFilters.anticipatedOverdueStartDate },
        },
      },
    });

    if (error) {
      throw new Error(error);
    }

    return {
      expenseStatusCounts: !anticipatedDisbursementsEnabled ? null : data?.expenseCounts,
      expenseStatusCountsLoading: loading,
    };
  },
  useExpenseListData: () => {
    const {
      currentPage: currentExpensePage,
      setPageNumber,
      getPagination,
    } = usePagination({
      scope: `${SCOPE}-pagination`,
      fetchLimit: FETCH_LIMIT,
    });

    const onExpenseListPageChange = ({ selected: pageNumber }) => setPageNumber(pageNumber);

    const expensesQueryResult = useSubscribedQuery(ExpenseTableData, {
      context: { skipRequestBatching: true },
      skip: !filterDateRange,
      variables: {
        expenseFilter: {
          expenseDate: filterDateRange,
          expenseEarnerStaffIds: staffIds,
          includeStatus: { current: true },
          excludeIfOnCheque: expenseOtherFilters.excludeIfOnCheque,
          excludeIfOnFinalisedInvoice: expenseOtherFilters.excludeIfOnFinalisedInvoice,
          includePseudoStatus: !expenseStatusFilters ? undefined : expenseStatusFilters,
        },
        offset: currentExpensePage * FETCH_LIMIT,
        limit: FETCH_LIMIT,
        sort: !sortBy ? undefined : { fieldNames: [sortBy], directions: [`${sortDirection || 'ASC'}`.toUpperCase()] },
      },
    });

    if (expensesQueryResult.error) {
      throw new Error(expensesQueryResult.error);
    }

    const { data: expenseData } = expensesQueryResult;
    const expenseList = expenseData?.expenseList?.results || [];

    const {
      totalCount: expenseCount,
      hidePagination,
      totalNumberOfPages: totalNumberOfExpensePages,
    } = getPagination({ totalCount: expenseData?.expenseList?.totalCount, loading: expensesQueryResult.loading });

    return {
      currentExpensePage,
      expenses: expenseList,
      expenseCount,
      expenseDataLoading: expenseList.length === 0 && expensesQueryResult.loading,
      totalNumberOfExpensePages,
      hidePagination,
      onExpenseListPageChange,
    };
  },
});

export const FirmExpenseEntriesContainer = withApolloClient(
  withReduxProvider(composeHooks(hooks)(composeHooks(queryHooks)(FirmExpenseEntries))),
);

FirmExpenseEntriesContainer.displayName = 'FirmExpenseEntriesContainer';

FirmExpenseEntriesContainer.propTypes = {
  onChequeClick: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  overrideRedirect: PropTypes.func,
  onExportExpenseCsv: PropTypes.func.isRequired,
  onNewExpense: PropTypes.func.isRequired,
  onOpenEditModal: PropTypes.func.isRequired,
  onPrintCheque: PropTypes.func.isRequired,
};

FirmExpenseEntriesContainer.defaultProps = {
  overrideRedirect: () => {},
};

/* eslint-disable no-param-reassign, no-shadow */
const { isEmpty, isNumber } = require('lodash');
const { getFirmNameAndAddress } = require('@sb-firm-management/business-logic/firm-details/services');

module.exports = {
  encodeHtml,
  decodeHtml,
  stringsToEnums,
  enumsToStrings,
  createDefaultSettings,
};

const titleLineType = {
  None: 'None',
  MatterDescription: 'MatterDescription',
  MatterTitle: 'MatterTitle',
  Custom: 'Custom',
};

const constants = {
  fonts: [
    { label: 'Arvo', value: 'Arvo' },
    { label: 'Lato', value: 'Lato' },
    { label: 'Merriweather', value: 'Merriweather' },
    { label: 'Open Sans', value: 'Open Sans' },
    { label: 'Roboto', value: 'Roboto' },
    { label: 'Slabo', value: 'Slabo 27px' },
  ],
  firmNameFontSizes: [
    { label: '8pt', value: '8pt' },
    { label: '10pt', value: '10pt' },
    { label: '12pt', value: '12pt' },
    { label: '14pt', value: '14pt' },
    { label: '16pt', value: '16pt' },
    { label: '18pt', value: '18pt' },
  ],
  defaultPageMargins: { top: 100, right: 100, bottom: 100, left: 100, unit: 'in' },
  maxLogoSize: 3145728, // 3MB
  minLogoDimension: 300, // 300*300 pixel
  entryDisplayOptions: {
    list: 'LIST',
    summary: 'SUMMARY',
    summaryWithList: 'SUMMARYWITHLIST',
  },
  lineItemConfig: {
    0: 'list',
    1: 'summary',
    2: 'summaryWithList',
  },
  titleLine1Type: titleLineType,
  titleLine1TypeByValue: {
    0: titleLineType.None,
    1: titleLineType.MatterDescription,
    2: titleLineType.Custom,
    3: titleLineType.MatterTitle,
  },
  titleLine1TypeById: {
    [titleLineType.None]: 0,
    [titleLineType.MatterDescription]: 1,
    [titleLineType.Custom]: 2,
    [titleLineType.MatterTitle]: 3,
  },
  titleLine2Type: titleLineType,
  titleLine2TypeByValue: {
    0: titleLineType.None,
    1: titleLineType.MatterDescription,
    2: titleLineType.Custom,
    3: titleLineType.MatterTitle,
  },
  titleLine2TypeById: {
    [titleLineType.None]: 0,
    [titleLineType.MatterDescription]: 1,
    [titleLineType.Custom]: 2,
    [titleLineType.MatterTitle]: 3,
  },
};

/*
  We noticed that encoding and decoding '> ' or ' <' caused strange results.
  These functions fix The "Unicode Problem". See here : https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#The_.22Unicode_Problem.22
  Could also use https://github.com/coolaj86/unibabel-js
  */
function b64EncodeUnicode(str) {
  // eslint-disable-next-line no-undef
  return window.btoa(
    window.encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode(`0x${p1}`)),
  );
}

function b64DecodeUnicode(str) {
  // eslint-disable-next-line no-undef
  return window.decodeURIComponent(
    Array.prototype.map.call(window.atob(str), (c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`).join(''),
  );
}

function doEncode(data) {
  try {
    // if decode succeeds then data is already encoded
    return b64EncodeUnicode(data);
  } catch (err) {
    // only encode if not already encoded
    // eslint-disable-next-line no-undef
    return window.btoa(data);
  }
}

function doDecode(data) {
  try {
    return b64DecodeUnicode(data);
  } catch (err) {
    return data;
  }
}

function encodeHtml(entity) {
  if (!entity) {
    return null;
  }

  if (!isEmpty(entity.letterhead)) {
    entity.letterhead = doEncode(entity.letterhead);
  }
  if (!isEmpty(entity.footer)) {
    entity.footer = doEncode(entity.footer);
  }
  if (!isEmpty(entity.statementFooter)) {
    entity.statementFooter = doEncode(entity.statementFooter);
  }

  return entity;
}

function decodeHtml(entity) {
  if (entity) {
    if (!isEmpty(entity.letterhead)) {
      entity.letterhead = doDecode(entity.letterhead);
    }
    if (!isEmpty(entity.footer)) {
      entity.footer = doDecode(entity.footer);
    }
    if (!isEmpty(entity.summary)) {
      entity.summary = doDecode(entity.summary);
    }
    if (!isEmpty(entity.statementFooter)) {
      entity.statementFooter = doDecode(entity.statementFooter);
    }
  }

  return entity;
}

function stringsToEnums(data) {
  if (!isEmpty(data) && !isEmpty(data.titleLine1Option)) {
    data.titleLine1Option = constants.titleLine1TypeById[data.titleLine1Option];
    data.details = 'details'; // dummy key
  }
  if (!isEmpty(data) && !isEmpty(data.titleLine2Option)) {
    data.titleLine2Option = constants.titleLine2TypeById[data.titleLine2Option];
    data.details = 'details'; // dummy key
  }

  return data;
}

function enumsToStrings(data) {
  if (!isEmpty(data) && isNumber(data.titleLine1Option)) {
    data.titleLine1Option = constants.titleLine1TypeByValue[data.titleLine1Option];
    data.details = 'details'; // dummy key
  }
  if (!isEmpty(data) && isNumber(data.titleLine2Option)) {
    data.titleLine2Option = constants.titleLine2TypeByValue[data.titleLine2Option];
    data.details = 'details'; // dummy key
  }

  return data;
}

function createDefaultSettings({ firmDetails, descriptionOnDemandFeatureEnabled }) {
  const footer = '<p></p>';
  const font = 'Lato';
  const settings = {
    letterhead: getFirmNameAndAddressDetails({ firmDetails }),
    letterHeadLayout: 'lh-justified-left',
    headingFont: font,
    bodyFont: font,
    paymentDueDays: 7,
    footer,
    defaultLayout: {
      expenseLineItemConfiguration: 0,
      expenseSummaryLineDescription: null,
      feeLineItemConfiguration: 0,
      feeSummaryLineDescription: null,
      includeNonBillableItems: false,
      showDescriptionForEntries: false,
      showDurationOnEntries: false,
      showRateOnEntries: false,
      showStaffNameOnEntries: false,
    },
    invoiceAdditionalOptions: {
      showInvoiceSummary: false,
      showAccountSummary: false,
      showTransactionHistory: false,
      hidePriorBalance: false,
      hidePaymentSummary: false,
      showSummaryForTimekeepers: false,
      showTimekeeperRole: false,
      showHoursSummary: false,
      hideFeeTotal: false,
      hideFeeTotalSummary: false,
      hideDueDate: false,
      showLessFundsInTrust: false,
      hideAmountAndTaxPerLineItem: false,
      appendExpenseReceipts: false,
      showItemNumbers: false,
    },
    eInvoiceOptions: descriptionOnDemandFeatureEnabled
      ? {
          enableDescriptionOnDemand: false,
          descriptionOnDemandLineItemText:
            'We are pleased to provide additional information about the time spent by [TIMEKEEPER_NAME] on [DATE]. We will come back to you within 7 working days, and you will not be charged for our time to provide this information.',
        }
      : undefined,
    statementFooter: footer,
    statementAdditionalOptions: {
      showInvoiceSummary: false,
      showAccountSummary: false,
      showTransactionHistory: false,
    },
    titleLine2Option: 'None',
    titleLine2CustomText: '',
    titleLine1Option: 'None',
    titleLine1CustomText: '',
    sampleMatterDescription: '[matter description will appear here]',
    sampleMatterTitle: '[matter title will appear here]',
    pageMargins: constants.defaultPageMargins,
    signatureName: '',
  };

  return settings;
}

function getFirmNameAndAddressDetails({ firmDetails }) {
  const formattedLetterhead = getFirmNameAndAddress({ firmDetails });
  // If firm address is empty, we don't want to just return firm name
  // If firm address is empty formattedLetterhead will be <div>Firm Name</div><div>State short name</div>
  // We only want to overwrite letterhead if address was filled from desktop with at least 3 lines
  if (
    !formattedLetterhead ||
    !formattedLetterhead.addressLines ||
    formattedLetterhead.addressLines.split('</div>').length <= 3
  ) {
    return '';
  }
  return `<p>${formattedLetterhead.addressLines}</p>`;
}

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withReduxStore, withTranslation } from '@sb-itops/react';
import { withOnLoad } from '@sb-itops/react/hoc';
import { getLogger } from '@sb-itops/fe-logger';
import { capitalize, capitalizeAllWords } from '@sb-itops/nodash';
import { sort } from '@sb-itops/sort';
import * as messageDisplay from '@sb-itops/message-display';
import { withScopedFeatures } from '@sb-itops/redux/hofs';

import * as sortFeature from '@sb-itops/redux/sort';
import * as formsFeature from '@sb-itops/redux/forms2';
import { getById as getMatterById, getMatterDisplay } from '@sb-matter-management/redux/matters';
import {
  getById as getOperatingChequeById,
  getList as getOperatingChequeList,
  getByChequeNumber as getOperatingChequeByChequeNumber,
  findOperatingChequesNotPrinted,
  printOperatingCheques,
  chequeExists,
} from '@sb-billing/redux/operating-cheques';
import { findLastChequeNumber as findLastOperatingChequeNumber, getNextChequeNumber } from 'web/services/cheques';
import { hasFacet, facets } from '@sb-itops/region-facets';

import { getById as getContactById } from '@sb-customer-management/redux/contacts-summary';
import { getById as getExpenseById } from '@sb-billing/redux/expenses';

import { getOperatingAccount } from '@sb-billing/redux/bank-account';
import PrintOperatingChequeModal from './PrintOperatingChequeModal';

const logger = getLogger('web/react-redux/PrintOperatingChequeModalContainer');
const modalId = 'print-operating-cheque-modal';
const REDUX_DATE_FORMAT = 'YYYYMMDD';

function getScopedPrintChequesFormFeature(state) {
  const scope = 'print-operating-cheque-modal';
  return withScopedFeatures({ state, scope })({
    sortFeature,
    formsFeature,
  });
}

function mapUnprintedOperatingCheques() {
  const operatingChequesNotPrintedYet = findOperatingChequesNotPrinted(getOperatingChequeList());
  const operatingChequesForPrintChequesForm = operatingChequesNotPrintedYet.map((cheque) => {
    const contactSummary = getContactById(cheque.payToId);

    // comma separated list of matter displays based on each matter referenced by the expenses
    const matterIds = cheque.expenseIds.reduce((ids, expenseId) => {
      const expense = getExpenseById(expenseId);
      if (expense && expense.matterId && !ids.includes(expense.matterId)) {
        ids.push(expense.matterId);
      }

      return ids;
    }, []);

    const matterDisplay = matterIds.map((matterId) => getMatterDisplay(getMatterById(matterId))).join(', ');

    // sum of all expenses
    const amount = cheque.expenseIds.reduce((total, expenseId) => {
      const expense = getExpenseById(expenseId);

      if (expense) {
        return total + (expense.amountIncludesTax ? expense.amount : expense.amount + expense.tax);
      }

      return total;
    }, 0);

    return {
      chequeId: cheque.chequeId,
      effectiveDate: cheque.chequeDate,
      matterIds,
      matterDisplay,
      payee: contactSummary && contactSummary.displayName,
      reference: cheque.reference,
      timestamp: cheque.lastUpdated,
      chequeMemo: cheque.chequeMemo,
      amount,
    };
  });
  return operatingChequesForPrintChequesForm;
}

function allPreselectedOperatingChequesAvailable(preselectedOperatingChequeIds) {
  const allSelected = preselectedOperatingChequeIds.every((chequeId) => !!getOperatingChequeById(chequeId));
  return allSelected;
}

const mapStateToProps = (state, { chequeIds, t }) => {
  const {
    sortFeature: {
      selectors: { getSortDirection },
    },
    formsFeature: {
      selectors: { getFormState, getFieldValues },
    },
  } = getScopedPrintChequesFormFeature(state);

  const unprintedCheques = mapUnprintedOperatingCheques();
  const preselectedChequeIds = chequeIds;
  const lastChequeNumberFound = findLastOperatingChequeNumber(getOperatingChequeList());
  let firstChequeNumber = getNextChequeNumber(getOperatingChequeList());
  const sortDirection = getSortDirection() || 'asc';

  const accountName = capitalizeAllWords(t('operatingAccount'));

  // set form defaults
  let overrideChequeDate;
  let chequeNumberPadding = firstChequeNumber.length;
  let selectedChequeIds = {};
  let overrideChequeMemos = {};
  let allowDuplicateChequeNumbers = false;

  const formState = getFormState();
  let fieldValues = {};
  const errors = {};

  // if form initialised override form defaults with user entered value
  if (formState.formInitialised) {
    fieldValues = getFieldValues();

    overrideChequeDate = fieldValues.overrideChequeDate
      ? moment(fieldValues.overrideChequeDate, REDUX_DATE_FORMAT).toDate()
      : overrideChequeDate;
    firstChequeNumber = fieldValues.firstChequeNumber !== undefined ? fieldValues.firstChequeNumber : firstChequeNumber;
    chequeNumberPadding = fieldValues.firstChequeNumber ? fieldValues.firstChequeNumber.length : chequeNumberPadding;
    selectedChequeIds = fieldValues.selectedChequeIds || selectedChequeIds;
    overrideChequeMemos = fieldValues.overrideChequeMemos || overrideChequeMemos;
    allowDuplicateChequeNumbers = hasFacet(facets.allowDuplicateCheque) && fieldValues.allowDuplicateChequeNumbers;

    // start form validation

    if (fieldValues.firstChequeNumber !== undefined) {
      // validate first cheque number only when it's overridden by user
      if (!fieldValues.firstChequeNumber) {
        errors.firstChequeNumber = true;
        errors.firstChequeNumberIsRequired = true;
      } else if (!/^[0-9]+$/.test(fieldValues.firstChequeNumber)) {
        errors.firstChequeNumber = true;
        errors.firstChequeNumberMustBeNumeric = true;
      } else if (!allowDuplicateChequeNumbers && chequeExists(+fieldValues.reference)) {
        errors.firstChequeNumber = true;
        errors.firstChequeNumberIsAlreadyInUse = true;
      } else if (!allowDuplicateChequeNumbers && chequeExists(+fieldValues.firstChequeNumber)) {
        errors.firstChequeNumber = true;
        errors.firstChequeNumberIsAlreadyInUse = true;
      }
    }

    const numberOfSelectedCheques = fieldValues.selectedChequeIds
      ? Object.values(fieldValues.selectedChequeIds).filter((selected) => selected === true).length
      : 0;
    if (numberOfSelectedCheques === 0) {
      errors.selectedChequeIds = true;
    }
  }

  const showLoadingIndicator =
    preselectedChequeIds &&
    preselectedChequeIds.length > 0 &&
    !allPreselectedOperatingChequesAvailable(preselectedChequeIds);

  const disableSubmit = Object.keys(errors).length > 0;
  return {
    modalId,
    modalDialogTitle: `Print ${capitalize(t('cheques'))}`,
    formInitialised: formState.formInitialised,
    unprintedCheques,
    sortDirection,
    accountName,
    overrideChequeDate,
    lastChequeNumberFound,
    firstChequeNumber,
    chequeNumberPadding,
    selectedChequeIds,
    overrideChequeMemos,
    showLoadingIndicator,
    disableSubmit,
    errors,
    showDuplicateChequeNumberToggle: hasFacet(facets.allowDuplicateCheque),
    allowDuplicateChequeNumbers,
    showChequeMemo: hasFacet(facets.chequeMemo),
  };
};

function buildPrintOperatingChequesMessage(formData) {
  logger.info('printOperatingChequeFormData:', formData);

  const selectedChequeIds = formData.selectedChequeIds;
  const overrideChequeMemos = formData.overrideChequeMemos;
  const assignedChequeReference = formData.assignedChequeReference;

  const selectedCheques = Object.keys(selectedChequeIds).reduce((acc, chequeId) => {
    if (selectedChequeIds[chequeId] === true) {
      acc.push({
        chequeId,
        chequeMemo:
          (overrideChequeMemos && overrideChequeMemos[chequeId]) || getOperatingChequeById(chequeId).chequeMemo,
        reference: assignedChequeReference && assignedChequeReference[chequeId],
      });
    }
    return acc;
  }, []);

  let firstChequeNumber;
  let chequeNumberPadding;
  if (formData.firstChequeNumber) {
    firstChequeNumber = formData.firstChequeNumber;
    chequeNumberPadding = firstChequeNumber.length;
  } else {
    firstChequeNumber = getNextChequeNumber(getOperatingChequeList());
    chequeNumberPadding = firstChequeNumber.length;
  }

  const bankAccountId = getOperatingAccount().id;

  // sorting by assigned reference number is important as .net will use the order of cheque sent
  // to assign an actual reference number to each cheque. This should match what's shown to the
  // user on the UI. In single user scenario, which should be the case for the printing of cheques
  // as there's only one cheque book, this will result in an exact match between the previewed
  // reference and the allocated reference for each cheque.
  const operatingCheques = sort(selectedCheques, ['reference'], ['ASC']).map((cheque) => ({
    chequeId: cheque.chequeId,
    chequeMemo: cheque.chequeMemo,
  }));

  return {
    bankAccountId,
    firstChequeNumber,
    chequeDateOverride: formData.overrideChequeDate,
    padding: chequeNumberPadding,
    operatingCheques,
    allowChequeNumberDuplication: hasFacet(facets.allowDuplicateCheque),
  };
}

const mapDispatchToProps = (dispatch, { chequeIds, sbAsyncOperationsService, t }) => {
  const {
    sortFeature: {
      actions: { setSortDirection },
    },
    formsFeature: {
      actions: { initialiseForm, updateFieldValues, clearForm, resetForm },
      operations: { submitFormP },
    },
  } = getScopedPrintChequesFormFeature();

  const preselectedChequeIds =
    chequeIds &&
    chequeIds.reduce((acc, chequeId) => {
      acc[chequeId] = true;
      return acc;
    }, {});

  return {
    onLoad: () => {
      dispatch(
        initialiseForm({
          fieldValues: {
            firstChequeNumber: undefined,
            overrideChequeDate: undefined,
            overrideChequeMemos: undefined,
            selectedChequeIds: preselectedChequeIds,
            assignedChequeReference: undefined,
            allowDuplicateChequeNumbers: false,
          },
        }),
      );
      return () => {
        dispatch(clearForm());
      };
    },
    onSortDirectionChanged: ({ sortDirection }) => {
      dispatch(setSortDirection({ sortDirection }));
    },
    onFieldValueChanged: (field, value) => {
      dispatch(updateFieldValues({ fieldValues: { [field]: value } }));
    },
    onFieldValuesChanged: (fieldValues) => {
      dispatch(updateFieldValues({ fieldValues }));
    },
    onSubmit: async () => {
      try {
        // flag selected operating cheques as printed and assign cheque reference
        let operatingChequesOptimisticallyPrinted;
        await dispatch(
          submitFormP({
            submitFnP: (formData) => {
              const printOperatingChequesMessage = buildPrintOperatingChequesMessage(formData);
              logger.info('printOperatingChequesMessage:', printOperatingChequesMessage);
              operatingChequesOptimisticallyPrinted = printOperatingCheques(printOperatingChequesMessage);
            },
          }),
        );

        // trigger cheque pdf regeneration & download link with progress bar
        const printedChequeIds = operatingChequesOptimisticallyPrinted.map(
          (operatingCheque) => operatingCheque.chequeId,
        );
        sbAsyncOperationsService.startOperatingChequeCreation(printedChequeIds);

        dispatch(resetForm());
      } catch (error) {
        logger.error('Error:', error);
        messageDisplay.error(`Failed to print ${t('operatingCheque')}(s).`);
      }
    },
    onCloseModal: () => {
      dispatch(resetForm());
    },
    findOperatingChequeByChequeNumber: (chequeNumber) => getOperatingChequeByChequeNumber(chequeNumber),
  };
};

const PrintOperatingChequeModalContainer = withReduxStore(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withOnLoad(PrintOperatingChequeModal))),
);

PrintOperatingChequeModalContainer.displayName = 'PrintOperatingChequeModalContainer';

PrintOperatingChequeModalContainer.propTypes = {
  chequeIds: PropTypes.array,
};

PrintOperatingChequeModalContainer.defaultProps = {};

export default PrintOperatingChequeModalContainer;

import PropTypes from 'prop-types';
import { withReduxStore } from '@sb-itops/react';
import {
  getRoleOptions,
  getClientRoleOptions,
  getOtherSideRole,
} from '@sb-matter-listing/redux/matter-type-configurations';

import composeHooks from '@sb-itops/react-hooks-compose';
import MatterContacts from './MatterContacts';

const hooks = ({
  formInitialised,
  formFields,
  formFieldValues,
  formDisabled,
  formSubmitting,
  submitFailed,
  onFieldValueSet,
  onFieldValuesUpdated,
}) => ({
  useForm: () => {
    const { clientCustomerIds, otherSideRole, otherSideCustomerIds } = formFieldValues;

    const { clientCustomerIdsIsRequired, matterTypeId, clientRole, originalMatterTypeId } = formFields;
    const clientRoleOptions = originalMatterTypeId?.value ? getClientRoleOptions(originalMatterTypeId.value) : [];

    const rolesMap = originalMatterTypeId?.value ? getRoleOptions(originalMatterTypeId.value, true) : {};
    const clientRoleLabel = rolesMap[clientRole?.value];
    const otherSideRoleLabel = rolesMap[otherSideRole];

    return {
      // form state
      formInitialised,
      formDisabled,
      formSubmitting,
      submitFailed,
      // form fields values
      clientCustomerIds,
      otherSideRole,
      otherSideCustomerIds,
      // form fields
      clientRole,
      matterTypeId,
      clientCustomerIdsIsRequired,
      // other
      clientRoleOptions,
      clientRoleLabel,
      otherSideRoleLabel,
      onClientsChanged: async (newSelectedContactIds) => {
        onFieldValueSet('clientCustomerIds', newSelectedContactIds);
      },
      onClientRoleUpdated: (newClientRole) => {
        onFieldValuesUpdated({
          clientRole: newClientRole,
          otherSideRole: getOtherSideRole(originalMatterTypeId.value, newClientRole),
        });
      },
      onOtherSidesChanged: (newSelectedContactIds) => {
        // onFieldValuesUpdated doesn't work in all scenarios, this is a redux/form2 api
        // bug related to dealing with arrays, I don't have time to chase down now
        onFieldValueSet('otherSideCustomerIds', newSelectedContactIds);
      },
    };
  },
});

export const MatterContactsContainer = withReduxStore(composeHooks(hooks)(MatterContacts));

MatterContactsContainer.displayName = 'MatterContactsContainer';

MatterContactsContainer.propTypes = {
  matterId: PropTypes.string,
  // form state
  formInitialised: PropTypes.bool.isRequired,
  formFields: PropTypes.object,
  formDisabled: PropTypes.bool,
  formSubmitting: PropTypes.bool,
  submitFailed: PropTypes.bool,
  // callbacks
  onFieldValuesUpdated: PropTypes.func.isRequired, // generic method for updating fields
  onFieldValueSet: PropTypes.func.isRequired, // generic method for set a field
  onClickLink: PropTypes.func.isRequired,
};

MatterContactsContainer.defaultProps = {
  matterId: undefined,
};

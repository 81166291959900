/* eslint-disable import/no-cycle */
import { store } from '@sb-itops/redux';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import moment from 'moment';
import { transactionType } from '@sb-billing/business-logic/transactions/entities/constants';
import { getById as getTransactionById } from './index';

const getAccountId = () => authSelectors.getAccountId(store.getState());
const getUserId = () => authSelectors.getUserId(store.getState());

export const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbTransactionService',
  keyPath: 'id',
});

/**
 * Build reversal transaction opdates entity
 * Transaction must be type of 'Deposit'|'Interest'|'BankFee'
 * @param {transaction like {  transactionId, reason, checkMatterBalance, deleteTransaction, allowOverdraw }} Transaction
 * @returns {[reversalTransaction, originalTransactionUpdated]} OpdateEntity
 */
export const buildReversalTransactionOpdates = ({ originalTransaction, reversalTransactionId }) => {
  const { transactionId, deleteTransaction } = originalTransaction;

  if (!originalTransaction.transactionId) {
    throw new Error('Invalid Transaction');
  }

  const txToReverse = getTransactionById(transactionId);

  if (txToReverse.reversed) {
    throw new Error(`Transaction already reversed, id: ${transactionId}`);
  }

  const newTx = buildReversalTransactionFromTransaction({ txToReverse, originalTransaction, reversalTransactionId });

  const opdate = [newTx, { ...txToReverse, reversed: true, isHidden: deleteTransaction }];

  return opdate;
};

function buildReversalTransactionFromTransaction({ txToReverse, originalTransaction, reversalTransactionId }) {
  const { deleteTransaction, allowOverdraw, reason } = originalTransaction;

  const newTx = {
    accountId: getAccountId(),
    userId: getUserId(),
    bankAccountId: txToReverse.bankAccountId,
    contactId: txToReverse.contactId,
    description: `Reversal: ${txToReverse.description}`,
    id: reversalTransactionId,
    matterId: txToReverse.matterId,
    note: reason,
    reference: txToReverse.reference,
    source: txToReverse.source,
    timestamp: moment().toISOString(),
    effectiveDate: +moment().format('YYYYMMDD'),
    isHidden: !!deleteTransaction,
    allowOverdraw: !!allowOverdraw,
    amount: -txToReverse.amount,
  };

  switch (txToReverse.type) {
    case transactionType.BankFees:
      newTx.type = transactionType.BankFeesReversal;
      break;
    case transactionType.Interest:
      newTx.type = transactionType.InterestReversal;
      break;
    case transactionType.Deposit:
      newTx.type = transactionType.DepositReversal;
      break;
    case transactionType.VendorPayment:
      newTx.type = transactionType.VendorPaymentReversal;
      break;

    default:
      throw new Error('Invalid Transaction type to reverse');
  }

  return newTx;
}

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, Modal, buttonTypes, Spinner, useTranslation } from '@sb-itops/react';
import { SubscriptionPaymentForm } from 'web/react-redux';
import {
  subscriptionIntervals,
  subscriptionStatusByType,
} from '@sb-finance/business-logic/subscription/entities/constants';
import { ManageSubscription } from 'web/components/manage-subscription';
import { isNewTheme } from 'web/services/theme';
import Styles from './ManageSubscriptionRoute.module.scss';

export const ManageSubscriptionRoute = (props) => {
  const {
    isCurentUserFirmOwner,
    onRedirectNonFirmOwner,
    currentTier,
    freeTiersOnCoupon,
    couponFreeDescription,
    tierPricingInfo,
    tierMarketingInfo,
    subscriptionFrequency,
    subscriptionRecord,
    updatingToSmokeballProductId,
    subPages,
    currentSubPage,
    setupIntentError,
    onTierSwitchRequested,
    onMonthlySubscriptionSelected,
    onYearlySubscriptionSelected,
    onCancelSubscriptionRequested,
    showConfirmCancelSubscription,
    onSubscriptionManagementComplete,
    cancelSubscriptionInProgress,
    onManagePaymentMethod,
    onPaymentMethodUpdated,
    onPaymentMethodUpdateAbort,
    isUpgradeFromFreeTier,
    tierSwitchRequestData,
    managePaymentMethodRequestParams,
    showTierSwitchConfirmModal,
    onCloseTierSwitchModal,
  } = props;

  const { t } = useTranslation();

  if (!isCurentUserFirmOwner) {
    onRedirectNonFirmOwner();
    return null;
  }

  // Error
  if (setupIntentError) {
    return (
      <div className={Styles.manageSubscriptionRoute}>
        <div className="page-top-bar">
          <div className="page-name">
            {!isNewTheme() && <div className="page-name-icon icon-matter" />}
            <h1 className="page-name-heading">Subscription</h1>
          </div>
        </div>
        <div className={classnames('master-detail-panel', Styles.mainBody)}>
          <div className={classnames('panel-body', Styles.panelContent)}>
            <div className={Styles.errorMessage}>
              <div className={Styles.errorHeading}>Error</div>
              <div>Something has gone wrong, please contact Smokeball Support.</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const loading = !tierPricingInfo || !subscriptionRecord;

  // If some of the data is not yet available, show loading screen.
  if (loading) {
    return (
      <div className={Styles.manageSubscriptionRoute}>
        <div className="page-top-bar">
          <div className="page-name">
            {!isNewTheme() && <div className="page-name-icon icon-matter" />}
            <h1 className="page-name-heading">Subscription</h1>
          </div>
        </div>
        <div className={classnames('master-detail-panel', Styles.mainBody)}>
          <div className={classnames('panel-body', Styles.panelContent)}>
            <div className={Styles.loadingSpinner}>
              <Spinner message="Loading subscription information" width={200} height={250} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const isMonthlyFrequency = subscriptionFrequency === subscriptionIntervals.MONTHLY;
  const tierPricingInfoForFrequency = isMonthlyFrequency ? tierPricingInfo.MONTHLY : tierPricingInfo.YEARLY;

  return (
    <div className={Styles.manageSubscriptionRoute}>
      <div className="page-top-bar">
        <div className="page-name">
          {!isNewTheme() && <div className="page-name-icon icon-matter" />}
          <h1 className="page-name-heading">Subscription</h1>
        </div>
      </div>
      {showConfirmCancelSubscription && cancelSubscriptionConfirmationModal(props)}
      {showTierSwitchConfirmModal && tierSwitchConfirmationModal(props, t)}
      <div className={classnames('master-detail-panel', Styles.mainBody)}>
        <div className={classnames('panel-body', Styles.panelContent)}>
          <div className={Styles.subscriptionContainer}>
            {currentSubPage === subPages.tiers && (
              <ManageSubscription
                currentTier={currentTier}
                freeTiers={freeTiersOnCoupon}
                freeTierPriceDescription={couponFreeDescription}
                tierPricingInfo={tierPricingInfoForFrequency}
                tierMarketingInfo={tierMarketingInfo}
                subscriptionFrequency={subscriptionFrequency}
                updatingToSmokeballProductId={updatingToSmokeballProductId}
                subscriptionRecord={subscriptionRecord}
                onTierSwitchRequested={({ stripeProductId, smokeballProductId }) =>
                  onTierSwitchRequested({
                    externalProductId: stripeProductId,
                    externalSubscriptionId: subscriptionRecord.externalSubscriptionId,
                    accountId: subscriptionRecord.accountId,
                    smokeballProductId,
                    subscriptionRecord,
                    freeTiersOnCoupon,
                    onManagePaymentMethod,
                    onCloseTierSwitchModal,
                  })
                }
                onSubscriptionManagementComplete={onSubscriptionManagementComplete}
                onMonthlySubscriptionSelected={onMonthlySubscriptionSelected}
                onYearlySubscriptionSelected={onYearlySubscriptionSelected}
                onCancelSubscription={onCancelSubscriptionRequested}
                cancelSubscriptionInProgress={cancelSubscriptionInProgress}
                onManagePaymentMethod={onManagePaymentMethod}
              />
            )}
            {currentSubPage === subPages.payments && (
              <SubscriptionPaymentForm
                smokeballProductId={currentTier}
                stripeProductId={
                  tierSwitchRequestData?.externalProductId || managePaymentMethodRequestParams?.externalProductId
                }
                subscriptionRecord={subscriptionRecord}
                onPaymentMethodUpdated={onPaymentMethodUpdated}
                onPaymentMethodUpdateAbort={onPaymentMethodUpdateAbort}
                isUpgradeFromFreeTier={isUpgradeFromFreeTier}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function cancelSubscriptionConfirmationModal(props) {
  const {
    cancelSubscriptionInProgress,
    showConfirmCancelSubscription,
    onCancelSubscription,
    onCancelSubscriptionAbort,
  } = props;

  return (
    <Modal
      className={Styles.modal}
      isVisible={showConfirmCancelSubscription}
      title="Confirm Smokeball Subscription Cancellation"
      body={
        <div>
          <p>Are you sure you want to cancel your Smokeball subscription?</p>
          <p>
            If you cancel your subscription, after your payment period expires you and your staff members will no longer
            be able to login to Smokeball.
          </p>
        </div>
      }
      footer={
        <div className={Styles.modalButtonSection}>
          <Button
            className={Styles.modalButton}
            type={buttonTypes.warning}
            onClick={() => onCancelSubscription()}
            disabled={cancelSubscriptionInProgress}
          >
            Confirm Cancellation
            {'  '}
            {cancelSubscriptionInProgress && <Spinner small />}
          </Button>
          <Button
            className={Styles.modalButton}
            type={buttonTypes.secondary}
            disabled={cancelSubscriptionInProgress}
            onClick={() => onCancelSubscriptionAbort()}
          >
            Cancel
          </Button>
        </div>
      }
      onModalClose={onCancelSubscriptionAbort}
    />
  );
}

function tierSwitchConfirmationModal(props, t) {
  const {
    showTierSwitchConfirmModal,
    tierSwitchRequestData,
    subscriptionFrequency,
    tierPricingInfo,
    onCloseTierSwitchModal,
    onTierSwitch,
    subscriptionRecord,
  } = props;

  const isMonthlyFrequency = subscriptionFrequency === subscriptionIntervals.MONTHLY;
  const tierPricingInfoForFrequency = isMonthlyFrequency ? tierPricingInfo.MONTHLY : tierPricingInfo.YEARLY;
  const isUpgrade = tierSwitchRequestData.smokeballProductId === 'SMK004';
  const { SMK004: boostPricingInfo } = tierPricingInfoForFrequency;
  const formattedBoostPrice = t('cents', { val: boostPricingInfo?.price });
  const hasCardRegistered = subscriptionRecord?.externalPaymentMethodIds?.length !== 0;

  return (
    <Modal
      className={Styles.modal}
      isVisible={showTierSwitchConfirmModal}
      title={isUpgrade ? `Upgrade to Boost Today!` : `Change your Plan to Bill`}
      body={
        <div>
          {isUpgrade && (
            <div>
              <p>
                Upgrade to Boost and get access to your firm productivity tools in addition to your existing Bill
                functionality.
              </p>
              {hasCardRegistered && (
                <p>Your credit card on file will be charged {formattedBoostPrice} per user today.</p>
              )}
            </div>
          )}
          {!isUpgrade && (
            <div>
              <p>
                Changing your plan from Boost to Bill will mean you lose access to the firm productivity features within
                Smokeball.
              </p>
              <p>You will keep your contacts and matters and still have access to your billing functionality.</p>
              <p>Are you sure you want to change your plan?</p>
            </div>
          )}
        </div>
      }
      footer={
        <div className={Styles.modalButtonSection}>
          <Button
            className={Styles.modalButton}
            type={buttonTypes.warning}
            onClick={() => onTierSwitch(tierSwitchRequestData)}
          >
            {isUpgrade ? `Upgrade Now` : `Downgrade Now`}
          </Button>
          <Button className={Styles.modalButton} type={buttonTypes.secondary} onClick={() => onCloseTierSwitchModal()}>
            Cancel
          </Button>
        </div>
      }
      onModalClose={onCloseTierSwitchModal}
    />
  );
}

ManageSubscriptionRoute.displayName = 'ManageSubscriptionRoute';

ManageSubscriptionRoute.propTypes = {
  isCurentUserFirmOwner: PropTypes.bool.isRequired,
  currentTier: PropTypes.oneOf(['SMK001', 'SMK004']).isRequired, // Subscription management is currently only available for bill and boost tiers
  subscriptionFrequency: PropTypes.oneOf(Object.values(subscriptionIntervals)).isRequired,
  onMonthlySubscriptionSelected: PropTypes.func.isRequired,
  onYearlySubscriptionSelected: PropTypes.func.isRequired,
  tierPricingInfo: PropTypes.object,
  tierMarketingInfo: PropTypes.object.isRequired,
  subPages: PropTypes.object.isRequired,
  currentSubPage: PropTypes.string.isRequired,
  targetExternalProductId: PropTypes.string,
  freeTiersOnCoupon: PropTypes.object.isRequired,
  couponFreeDescription: PropTypes.string.isRequired,
  isUpgradeFromFreeTier: PropTypes.bool.isRequired,
  setupIntentError: PropTypes.bool.isRequired,
  subscriptionRecord: PropTypes.shape({
    accountId: PropTypes.string.isRequired,
    externalSubscriptionId: PropTypes.string.isRequired,
    externalCustomerId: PropTypes.string.isRequired,
    status: PropTypes.oneOf(Object.values(subscriptionStatusByType)).isRequired,
    externalPaymentMethodIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }),
  managePaymentMethodRequestParams: PropTypes.object,
  onTierSwitch: PropTypes.func.isRequired,
  onSubscriptionManagementComplete: PropTypes.func.isRequired,
  showConfirmCancelSubscription: PropTypes.bool.isRequired,
  updatingToSmokeballProductId: PropTypes.string,
  onCancelSubscriptionAbort: PropTypes.func.isRequired,
  onCancelSubscriptionRequested: PropTypes.func.isRequired,
  onCancelSubscription: PropTypes.func.isRequired,
  cancelSubscriptionInProgress: PropTypes.bool.isRequired,
  showTierSwitchConfirmModal: PropTypes.bool.isRequired,
  tierSwitchRequestData: PropTypes.object,
  onCloseTierSwitchModal: PropTypes.func.isRequired,
  onTierSwitchRequested: PropTypes.func.isRequired,
  onManagePaymentMethod: PropTypes.func.isRequired,
  onPaymentMethodUpdated: PropTypes.func.isRequired,
  onPaymentMethodUpdateAbort: PropTypes.func.isRequired,
  onRedirectNonFirmOwner: PropTypes.func.isRequired,
};

ManageSubscriptionRoute.defaultProps = {
  tierPricingInfo: undefined,
  subscriptionRecord: undefined,
  tierSwitchRequestData: undefined,
  managePaymentMethodRequestParams: undefined,
  updatingToSmokeballProductId: '',
  targetExternalProductId: '',
};

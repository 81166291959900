'use strict';

const get = require('lodash.get');

const isNotEmptyField = (addressComponent) => addressComponent && addressComponent.trim();

const organisationHasStreetAddress = (organisation) => {
  const { city, zipCode } = get(organisation, 'businessAddress', {}) || {};
  const { streetName } = get(organisation, 'businessAddress.detailedStreetAddress', {}) || {};
  return [city, zipCode, streetName].every((addressComponent) => isNotEmptyField(addressComponent));
};

const organisationHasPOBoxAddress = (organisation) => {
  // State and PO Box Type is not required for PO Boxes in GB
  const { pOBoxNumber, city, zipCode } = get(organisation, 'businessAddress.pOBoxAddress', {}) || {};
  return [city, zipCode, pOBoxNumber].every((addressComponent) => isNotEmptyField(addressComponent));
};

const personOrGroupOfPeopleHasStreetAddress = (personOrGroupOfPeople) => {
  const { city, zipCode } = get(personOrGroupOfPeople, 'residentialAddress', {}) || {};
  const { streetName } = get(personOrGroupOfPeople, 'residentialAddress.detailedStreetAddress', {}) || {};
  return [city, zipCode, streetName].every((addressComponent) => isNotEmptyField(addressComponent));
};

const personOrGroupOfPeopleHasPOBoxAddress = (personOrGroupOfPeople) => {
  // State PO Box Type is not required for PO Boxes in GB
  const { pOBoxNumber, city, zipCode } = get(personOrGroupOfPeople, 'residentialAddress.pOBoxAddress', {}) || {};
  return [city, zipCode, pOBoxNumber].every((addressComponent) => isNotEmptyField(addressComponent));
};

const personHasStreetAddress = (person) =>
  personOrGroupOfPeopleHasStreetAddress(person) || personOrGroupOfPeopleHasPOBoxAddress(person);

const groupOfPeopleHasStreetAddress = (groupOfPeople) =>
  personOrGroupOfPeopleHasStreetAddress(groupOfPeople) || personOrGroupOfPeopleHasPOBoxAddress(groupOfPeople);

const contactHasStreetOrPOAddress = (contact) => {
  if (contact.person) {
    return personHasStreetAddress(contact.person);
  }

  if (contact.organisation) {
    return organisationHasStreetAddress(contact.organisation) || organisationHasPOBoxAddress(contact.organisation);
  }

  if (contact.groupOfPeople) {
    return groupOfPeopleHasStreetAddress(contact.groupOfPeople);
  }

  return false;
};

const findContactsWithMissingStreetOrPOAddress = (contacts) => {
  const contactsWithMissingStreetAddress = (contacts || []).filter((contact) => !contactHasStreetOrPOAddress(contact));
  return contactsWithMissingStreetAddress;
};

module.exports = {
  contactHasStreetOrPOAddress,
  findContactsWithMissingStreetOrPOAddress,
};

import { isReconciled } from '@sb-billing/redux/bank-reconciliations';
import { localiseDescription } from '@sb-billing/transaction-descriptions';
import { Cent } from '@sb-itops/money';
import { getOperatingAccount, getById as getBankAccountById } from '@sb-billing/redux/bank-account';
import { bankAccountTypeEnum  } from '@sb-billing/business-logic/bank-account/entities/constants';
import { getBankAccountName } from '@sb-billing/business-logic/bank-account/services';

angular.module('sb.billing.webapp').component('sbCreateDepositSlip', {
  templateUrl: 'ng-components/create-deposit-slip/create-deposit-slip.html',
  bindings: { accountType: '<?', depositSlipId: '<?', trustAccountId: '<?'},
  controller: function ($scope, $state, sbLoggerService, sbLocalisationService, $uibModal,
    sbUuidService, sbDepositSlipService, sbTransactionService, sbEnvironmentConfigService,
    sbUnsavedChangesService, sbTabService, sbLinkService) {
    'use strict';
    const ctrl = this;
    const log = sbLoggerService.getLogger('sbCreateDepositSlip');
    let unsavedChangesKey;

    // log.setLogLevel('info');

    let initialised = false;

    $scope.$on('smokeball-data-update-sbBankAccountService', getAccountName);

    $scope.$on('$destroy', () => {
      if (ctrl.clearUnsavedChanges) {
        sbUnsavedChangesService.saveMemory(unsavedChangesKey);
      } else {
        const changes = _.cloneDeep(ctrl.model);
        sbUnsavedChangesService.saveMemory(unsavedChangesKey, changes);
      }
    });

    $scope.$watch('$ctrl.model.depositDate', () => {
      prepField('depositdate');
    });

    ctrl.onSelectDeposit    = onSelectDeposit;
    ctrl.onSelectDate       = onSelectDate;
    ctrl.onChangeFromDate   = onChangeFromDate;
    ctrl.onChangeToDate     = onChangeToDate;
    ctrl.dataChangeFunction = dataChangeFunction;
    ctrl.selectAll          = selectAll;
    ctrl.clearAll           = clearAll;
    ctrl.viewTransaction    = viewTransaction;
    ctrl.isDepositSelected  = isDepositSelected;
    ctrl.process            = process;
    ctrl.deleteDepositSlip  = deleteDepositSlip;
    ctrl.t                  = sbLocalisationService.t;

    ctrl.onClickLink = sbLinkService.onClickLink;

    ctrl.sbData = {};
    ctrl.listConfig = { // TODO move all list stuff out into its own component
      stateKey: 'create-deposit-list',
      type: 'infinite',
      showFooter: true,
      columns: [
        {class: 'add', label: 'Add'},
        {class: 'date', label: 'Date', sort: sortByDate, defaultSort: true},
        {class: 'description', label: 'Description'},
        {class: 'matter', label: 'Matter'},
        {class: 'amount', label: 'Amount'},
        {class: 'end-spacer'},
      ],
    };

    ctrl.$onInit = () => {
      unsavedChangesKey  = `${$state.current.name}.${ctrl.trustAccountId || ctrl.accountType || ctrl.depositSlipId}`;
      ctrl.clearUnsavedChanges = false;

      ctrl.model = {
        reference: '',
        depositDate: moment().toDate(),
        selectedDeposits: {},
        fromDate: moment().toDate(), //for the list component
        toDate:   moment().toDate(), //for the list component
        accountType: (ctrl.accountType || "").toUpperCase(), // accountType is empty when we editing DS
        bankAccountId: getBankAccountId()
      };

      ctrl.errors = {
        selectedDeposits: false,
        depositDate: false,
        fromDate: false,
        toDate: false,
      };

      ctrl.view = {
        title: '',
        accountName: '',
        totalDeposit: 0,
        totalDepositStr: '0.00',
        totals: {
          amount: 0,
        },
        processEnabled: false,
        format: 'DD/MM/YYYY',
        region: sbEnvironmentConfigService.getRegion(),
        disableSelect: true,
        hasLabels: true,
        isProcessing: false,
      };

      ctrl.onSelectDate(undefined, 'fromDate', ctrl.model.fromDate);
      ctrl.onSelectDate(undefined, 'toDate', ctrl.model.toDate);

      createDepositFilter();
      
      const savedChanges = sbUnsavedChangesService.loadMemory(unsavedChangesKey);
      log.info('loading saved changes', savedChanges, unsavedChangesKey);
      if (!_.isEmpty(savedChanges)) {
        loadUnsavedChanges(savedChanges);
        prepField('selectedDeposits');
      }
      else if (ctrl.depositSlipId) {
        getDepositSlip();
      }
      
      ctrl.view.title = getPageTitle();
      ctrl.view.accountName = getAccountName();


      log.info('initialised: account type, name, title', ctrl.model.accountType, ctrl.view.accountName, ctrl.view.title);

      initialised = true;
    };

    ctrl.$onChanges = (changes) => {
      log.info('changes for component inputs into deposit slip', changes);
      if (initialised) {
        ctrl.view.title = getPageTitle();
        ctrl.view.accountName = getAccountName();
      }
    };

    //TODO doing setPostProcessor here as there seems to be a timing issue somewhere
    ctrl.$postLink = () => {
      ctrl.sbData.depositSlipProvider.setPostProcessor(postDataProcess);
    };

    function getBankAccountId() {
      if (ctrl.accountType === bankAccountTypeEnum.TRUST) {
        return ctrl.trustAccountId;
      }
      if (ctrl.accountType === bankAccountTypeEnum.OPERATING) {
        return getOperatingAccount().id;
      }
    }

    function createDepositFilter () {
      ctrl.depositFilter = (deposit) => {
        const effectiveDate = moment(deposit.effectiveDate, 'YYYYMMDD').toDate();

        return deposit.bankAccountId
          && deposit.bankAccountId === ctrl.model.bankAccountId
          && (deposit.depositSlipId === ctrl.depositSlipId || !deposit.depositSlipId)
          && effectiveDate >= ctrl.model.fromDate
          && effectiveDate <= ctrl.model.toDate
          && !sbTransactionService.isReconciled(deposit.id) // check if the transaction is part of completed reconciliation
          && !deposit.reversed // transaction has not been reversed or deleted (both using the same flag)
      };
    }

    function postDataProcess (deposits) {
      ctrl.view.totals.amount = deposits ? deposits.reduce((total, deposit) => total + deposit.amount, 0) : 0;

      // i dont like mutating like this, but i'm not sure on the ramifications if this function returns new objects - TH TODO investigate
      deposits.forEach((deposit) => {
        deposit.description = localiseDescription(sbLocalisationService.t, deposit.description);
      });

      return deposits;
    }

    function sortByDate(deposits, sortOrder) {
      return _.sortByOrder(deposits, ['effectiveDate', 'timestamp'], [sortOrder, sortOrder]);
    }

    function loadUnsavedChanges (changes) {
      ctrl.model = changes;
      const deposits = Object.keys(changes.selectedDeposits).map(sbTransactionService.getById);
      const selectedDeposits = deposits.filter(deposit => {
        if (deposit.depositSlipId) {
          // ignore deposits with depositSlipId as they are already part of a deposit slip
          delete ctrl.model.selectedDeposits[deposit.id];
          return false
        }
        return true;
      })
      //dont want to call onSelectDeposit as the deposits are already selected and that will toggle them off
      selectedDeposits.forEach(updateTotal);
    }

    //////////// USED ONLY when editing a deposit slip //////////////////////
    function getDepositSlip () {
      const depositSlip = sbDepositSlipService.get(ctrl.depositSlipId);

      log.info('loading from deposit slip', depositSlip);

      ctrl.model.accountType = depositSlip.bankAccountType.toUpperCase();
      ctrl.model.bankAccountId = depositSlip.bankAccountId;
      log.info('Bank account', ctrl.model.bankAccountId, ctrl.model.accountType);
      const deposits = depositSlip.transactionIds.map(sbTransactionService.getById);
      ctrl.model.reference = depositSlip.reference;
      ctrl.model.depositDate = moment(depositSlip.depositDate, 'YYYYMMDD').toDate();

      const {from, to} = determineDateRange(deposits);
      log.info('determined date range', from, to);
      ctrl.model.fromDate = moment(from, 'YYYYMMDD').toDate();
      ctrl.model.toDate = moment(to, 'YYYYMMDD').toDate();

      deposits.forEach(onSelectDeposit);

      ctrl.model.depositSlip = depositSlip; //storing this for the id only (for now)
    }

    function determineDateRange (deposits) {
      return deposits.reduce((dates, deposit) => {
        return {
          from: Math.min(dates.from, deposit.effectiveDate),
          to: Math.max(dates.to, deposit.effectiveDate)
        };
      }, { from: deposits[0].effectiveDate, to: deposits[0].effectiveDate});
    }
    ////////////////////////////////////////////////////////////////////////

    function viewTransaction (event, transaction) {
      // we need to do this as the events between angularjs and react dont bubble from child to parent
      if (event.target.className.includes('sb-table-cell')) {
        $uibModal.open({
          templateUrl: 'ng-components/bank-account-transaction-details/modal/bank-account-transaction-details-modal.html',
          controller: 'SbBankAccountTransactionDetailsModalController',
          size: 'lg',
          resolve: {
            transactionId: () => transaction.id,
            transaction: null,
            matterId: null,
            contactId: null,
            showHidden: null,
          },
          backdrop: 'static',
        });
      }
    }

    function selectAll () {
      ctrl.view.totalDeposit = 0;
      ctrl.model.selectedDeposits = {};

      ctrl.sbData.deposits.forEach((deposit) => {
        onSelectDeposit(deposit);
      });
    }

    function clearAll () {
      ctrl.model.selectedDeposits = {};
      ctrl.view.totalDeposit = 0;
      ctrl.view.totalDepositStr = new Cent(ctrl.view.totalDeposit).toString();
      prepField('selectedDeposits');
      prepField('depositDate');
    }

    function onSelectDate (doc, key, dateTime) {
      log.info('deposit date range selected', key, dateTime);
      const date = moment(dateTime).startOf('day').toDate(); //remove time from date object // TODO remove this once sb-datepicker removes the time component

      //only update stuff if the date is not the same
      if (ctrl.model[key].getTime() !== date.getTime()) {
        log.info('date changed');
        ctrl.model[key] = date;
        prepField('depositDate');
        createDepositFilter();
        clearAll();

        if (key === 'fromDate') {
          ctrl.model.depositDate = date;
        }
      }
    }

    const fromDateHasError = (fromDate, toDate) => !fromDate || (toDate && fromDate ? fromDate.getTime() > toDate.getTime() || fromDate.getTime() > Date.now() : false);
    const toDateHasError = (fromDate, toDate) => !toDate || (toDate && fromDate ? toDate.getTime() < fromDate.getTime() : false);

    function onChangeFromDate(fromDate){
      log.info(`from date: ${fromDate}`);
      ctrl.errors.fromDate = fromDateHasError(fromDate, ctrl.model.toDate);
      fromDate = moment(fromDate).startOf('day').toDate();
      ctrl.onSelectDate(undefined, 'fromDate', fromDate);
    }

    function onChangeToDate(toDate){
      log.info(`to date: ${toDate}`);
      ctrl.errors.toDate = toDateHasError(ctrl.model.fromDate, toDate);
      toDate = moment(toDate).startOf('day').toDate();
      ctrl.onSelectDate(undefined, 'toDate', toDate);
    }

    function onSelectDeposit (deposit) {
      if (ctrl.model.selectedDeposits[deposit.id]) {
        delete ctrl.model.selectedDeposits[deposit.id];
      }
      else {
        ctrl.model.selectedDeposits[deposit.id] = deposit;
      }

      updateTotal(deposit);

      prepField('selectedDeposits');
      prepField('depositdate');
    }

    function updateTotal (deposit) {
      if (ctrl.model.selectedDeposits[deposit.id]) {
        ctrl.view.totalDeposit += deposit.amount;
      } else {
        ctrl.view.totalDeposit -= deposit.amount;
      }

      log.info('deposit selected, total deposit:', deposit, ctrl.view.totalDeposit);

      ctrl.view.totalDepositStr = new Cent(ctrl.view.totalDeposit).toString();
    }

    function prepField (field) {
      log.info('prepfield', field);
      switch(field.toUpperCase()) {
        case 'DEPOSITDATE': {
          ctrl.errors.depositDate = !ctrl.model.depositDate || !depositsBeforeSlipDate() || depositSlipBeforeLastReconciliation();
          ctrl.errors.depositsBeforeSlipDate = !depositsBeforeSlipDate();
          ctrl.errors.depositSlipBeforeLastReconciliation = depositSlipBeforeLastReconciliation();
          break;
        }
        case 'SELECTEDDEPOSITS': {
          // if there's no value selected, that we dont want them clicking process
          ctrl.errors.selectedDeposits = !ctrl.view.totalDeposit;
          break;
        }
      }
      setProcessEnabled();
    }

    function depositSlipBeforeLastReconciliation() {
      const depositDate = +moment(ctrl.model.depositDate).format('YYYYMMDD');
      if (ctrl.model.accountType === bankAccountTypeEnum.TRUST && ctrl.model.bankAccountId) {
        // check if this specific trust account was reconciled
        return isReconciled({ yyyymmdd: depositDate, trustAccountId: ctrl.model.bankAccountId});
      }
      return false;
    }

    function depositsBeforeSlipDate () {
      const depositDate = +moment(ctrl.model.depositDate).format('YYYYMMDD');
      log.info(depositDate, ctrl.model.selectedDeposits);
      return Object.keys(ctrl.model.selectedDeposits).every((depositId) => ctrl.model.selectedDeposits[depositId].effectiveDate <= depositDate);
    }

    //process button is enabled if all the error fields are false
    function setProcessEnabled () {
      ctrl.view.processEnabled = !Object.values(ctrl.errors).some((e) => e);
    }

    function isDepositSelected (id) {
      return !!ctrl.model.selectedDeposits[id];
    }

    function dataChangeFunction(key, doc) {
      if (doc && doc.data) {
        _.set(ctrl.sbData, key, doc.data);
      } else {
        _.set(ctrl.sbData, key, doc);
      }
    }

    function getPageTitle () {
      return `${ctrl.t('capitalizeAllWords', { val: ctrl.model.accountType.toLowerCase() })} Deposit Slip`;
    }

    function getAccountName () {
      if (ctrl.model.accountType === bankAccountTypeEnum.OPERATING) {
        return 'Operating Account';
      }

      if (ctrl.model.accountType === bankAccountTypeEnum.TRUST) {
        //get the bank account settings for the trust account
        const trustAccount = getBankAccountById(ctrl.model.bankAccountId);
        const accountName = getBankAccountName(trustAccount, sbLocalisationService.t);

        log.info('trust account name', accountName);
        return accountName;
      }
    }

    // deleteSlip is added onto the controller via the view - it involves a modal, so this seems to be the easier way
    // of doing it.
    function deleteDepositSlip () {
      ctrl.deleteSlip(ctrl.depositSlipId);
    }

    function getSelectedDeposits () {
      return Object.keys(ctrl.model.selectedDeposits);
    }

    function isValid () {
      return !!(ctrl.model.depositDate && ctrl.view.totalDeposit);
    }

    function process () {
      if (isValid()) {
        ctrl.view.isProcessing = true;
        const data = marshal();
        log.info(data);
        sbDepositSlipService.saveP(data)
          .then(() => {
            log.info('done saving');
            ctrl.clearUnsavedChanges = true;
            sbTabService.closeCurrent();
            if (ctrl.model.accountType === bankAccountTypeEnum.OPERATING) {
              $state.go(`home.billing.accounts.deposit-slips.operating`);
            } else {
              $state.go(`home.billing.accounts.deposit-slips.trust.account`, {trustAccountId: ctrl.model.bankAccountId});
            }
          })
          .catch((e) => {
            log.info(e);
          })
          .finally(() => {
            ctrl.view.isProcessing = false;
          });
      } else {
        prepField('depositDate');
        prepField('selectedDeposits');
        log.info(ctrl.errors);
      }
    }

    function marshal () {
      return {
        versionId:           sbUuidService.get(),
        id:                 (ctrl.model.depositSlip && ctrl.model.depositSlip.id) || sbUuidService.get(),
        bankAccountId:      ctrl.model.bankAccountId,
        depositDate:        +moment(ctrl.model.depositDate).format('YYYYMMDD'),
        reference:          ctrl.model.reference,
        totalDepositSlip:   ctrl.view.totalDeposit,
        transactionIds:     getSelectedDeposits(),
      };
    }
  }
});

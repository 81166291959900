import PropTypes from 'prop-types';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import composeHooks from '@sb-itops/react-hooks-compose';
import { isModalVisible, getModalDialogInfo, setModalDialogHidden } from '@sb-itops/redux/modal-dialog';
import {
  CREDIT_NOTE_MODAL_ID,
  ADD_PAYMENT_MODAL_ID,
  ADD_LEAD_MODAL_ID,
  CONTACT_CREATE_EDIT_MODAL_ID,
  RESTORE_MATTER_MODAL_ID,
  CONVERT_LEAD_MODAL_ID,
  DEPOSIT_FUNDS_MODAL_ID,
  PROTECT_BALANCE_ADD_MODAL_ID,
  TRANSFER_FUNDS_MODAL_ID,
  VENDOR_PAYMENT_ENTRY_SPLIT_PAYORS_MODAL_ID,
  INVOICE_STATEMENT__PAYMENT_ADD_MODAL_ID,
  MARK_AS_SENT_MODAL_ID,
  ADD_MATTER_WIZARD_MODAL_ID,
} from 'web/react-redux';
import { ADD_EVENT_MODAL_ID, ADD_TASK_MODAL_ID, ADD_PHONE_MESSAGE_MODAL_ID } from 'web/containers';
import {
  ADD_PAYMENT_MODAL_ID as ADD_PAYMENT_NEW_MODAL_ID,
  CREDIT_CARD_PAYMENT_MODAL_ID,
  DRAFT_INVOICE_DELETE_PROCEED_MODAL_ID,
  DRAFT_INVOICE_PREVIEW_MODAL_ID,
  EXPENSE_MODAL_ID,
  FEE_MODAL_ID,
  INVOICE_COMMUNICATE_MODAL_ID,
  INVOICE_EMAIL_MODAL_ID,
  INVOICE_STATEMENT_EMAIL_MODAL_ID,
  OPERATING_CHEQUE_DETAILS_MODAL_ID,
  PRINT_OPERATING_CHEQUE_MODAL_ID,
  UNPAID_ANTICIPATED_DISBURSEMENT_ACKNOWLEDGEMENT_MODAL_ID,
  FINALISE_INVOICE_CONFIRMATION_MODAL_ID,
} from 'web/components';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { withApolloClient } from '../../hocs/withApolloClient';
import BillingModals from './BillingModals';

export const ADD_TIME_ENTRY_MODAL_ID = 'add-time-entry-modal';

const hooks = ({ onTimeEntryModalOpen }) => ({
  useRedux: () => {
    const isConvertLeadModalVisible = useSelector(() => isModalVisible({ modalId: CONVERT_LEAD_MODAL_ID }));
    const convertLeadModalProps =
      useSelector(() => getModalDialogInfo({ modalId: CONVERT_LEAD_MODAL_ID }))?.props || {};
    const isCreditNoteModalVisible = useSelector(() => isModalVisible({ modalId: CREDIT_NOTE_MODAL_ID }));
    const creditModalProps = useSelector(() => getModalDialogInfo({ modalId: CREDIT_NOTE_MODAL_ID }))?.props || {};

    const isDraftInvoicePreviewModalVisible = useSelector(() =>
      isModalVisible({ modalId: DRAFT_INVOICE_PREVIEW_MODAL_ID }),
    );
    const draftInvoicePreviewModalProps =
      useSelector(() => getModalDialogInfo({ modalId: DRAFT_INVOICE_PREVIEW_MODAL_ID }))?.props || {};

    const isAddMatterWizardModalVisible = useSelector(() => isModalVisible({ modalId: ADD_MATTER_WIZARD_MODAL_ID }));
    const addMatterWizardModalProps =
      useSelector(() => getModalDialogInfo({ modalId: ADD_MATTER_WIZARD_MODAL_ID }))?.props || {};
    const isAddPaymentModalVisible = useSelector(() => isModalVisible({ modalId: ADD_PAYMENT_MODAL_ID }));
    const paymentModalProps = useSelector(() => getModalDialogInfo({ modalId: ADD_PAYMENT_MODAL_ID }))?.props || {};
    // LOD add payment modal
    const isAddPaymentNewModalVisible = useSelector(() => isModalVisible({ modalId: ADD_PAYMENT_NEW_MODAL_ID }));
    const addPaymentNewModalProps =
      useSelector(() => getModalDialogInfo({ modalId: ADD_PAYMENT_NEW_MODAL_ID }))?.props || {};

    const isRestoreMatterModalVisible = useSelector(() => isModalVisible({ modalId: RESTORE_MATTER_MODAL_ID }));
    const restoreMatterModalProps =
      useSelector(() => getModalDialogInfo({ modalId: RESTORE_MATTER_MODAL_ID }))?.props || {};

    const isAddEventModalVisible = useSelector(() => isModalVisible({ modalId: ADD_EVENT_MODAL_ID }));
    const isAddLeadModalVisible = useSelector(() => isModalVisible({ modalId: ADD_LEAD_MODAL_ID }));
    const isAddTaskModalVisible = useSelector(() => isModalVisible({ modalId: ADD_TASK_MODAL_ID }));
    const isAddPhoneMessageModalVisible = useSelector(() => isModalVisible({ modalId: ADD_PHONE_MESSAGE_MODAL_ID }));

    const isTransferFundsModalVisible = useSelector(() => isModalVisible({ modalId: TRANSFER_FUNDS_MODAL_ID }));
    const transferFundsModalProps =
      useSelector(() => getModalDialogInfo({ modalId: TRANSFER_FUNDS_MODAL_ID }))?.props || {};

    const isContactCreateEditModalVisible = useSelector(() =>
      isModalVisible({ modalId: CONTACT_CREATE_EDIT_MODAL_ID }),
    );
    const contactCreateEditModalProps =
      useSelector(() => getModalDialogInfo({ modalId: CONTACT_CREATE_EDIT_MODAL_ID }))?.props || {};

    const taskModalProps = useSelector(() => getModalDialogInfo({ modalId: ADD_TASK_MODAL_ID }))?.props || {};
    const newPhoneNumberModalProps =
      useSelector(() => getModalDialogInfo({ modalId: ADD_PHONE_MESSAGE_MODAL_ID }))?.props || {};
    const eventModalProps = useSelector(() => getModalDialogInfo({ modalId: ADD_EVENT_MODAL_ID }))?.props || {};

    const isDraftInvoiceDeleteModalVisible = useSelector(() =>
      isModalVisible({ modalId: DRAFT_INVOICE_DELETE_PROCEED_MODAL_ID }),
    );
    const draftInvoiceDeleteModalProps =
      useSelector(() => getModalDialogInfo({ modalId: DRAFT_INVOICE_DELETE_PROCEED_MODAL_ID }))?.props || {};

    const isDepositModalVisible = useSelector(() => isModalVisible({ modalId: DEPOSIT_FUNDS_MODAL_ID }));
    const depositModalProps = useSelector(() => getModalDialogInfo({ modalId: DEPOSIT_FUNDS_MODAL_ID }))?.props || {};

    const isVendorPaymentEntrySplitPayorsModalVisible = useSelector(() =>
      isModalVisible({ modalId: VENDOR_PAYMENT_ENTRY_SPLIT_PAYORS_MODAL_ID }),
    );
    const vendorPaymentEntrySplitPayorsProps =
      useSelector(() => getModalDialogInfo({ modalId: VENDOR_PAYMENT_ENTRY_SPLIT_PAYORS_MODAL_ID }))?.props || {};

    const isTimeEntryModalVisible = useSelector(() => isModalVisible({ modalId: ADD_TIME_ENTRY_MODAL_ID }));
    const timeEntryModalProps =
      useSelector(() => getModalDialogInfo({ modalId: ADD_TIME_ENTRY_MODAL_ID }))?.props || {};

    const isProtectedFundsModalVisible = useSelector(() => isModalVisible({ modalId: PROTECT_BALANCE_ADD_MODAL_ID }));
    const protectedFundsModalProps =
      useSelector(() => getModalDialogInfo({ modalId: PROTECT_BALANCE_ADD_MODAL_ID }))?.props || {};

    const isInvoiceStatementAddPaymentModalVisible = useSelector(() =>
      isModalVisible({ modalId: INVOICE_STATEMENT__PAYMENT_ADD_MODAL_ID }),
    );
    const invoiceStatementPaymentModalProps =
      useSelector(() => getModalDialogInfo({ modalId: INVOICE_STATEMENT__PAYMENT_ADD_MODAL_ID }))?.props || {};

    // LOD invoice statement email modal
    const isInvoiceStatementEmailModalVisible = useSelector(() =>
      isModalVisible({ modalId: INVOICE_STATEMENT_EMAIL_MODAL_ID }),
    );
    const invoiceStatementEmailModalProps =
      useSelector(() => getModalDialogInfo({ modalId: INVOICE_STATEMENT_EMAIL_MODAL_ID }))?.props || {};

    // LOD fee modal
    const isFeeModalVisible = useSelector(() => isModalVisible({ modalId: FEE_MODAL_ID }));
    const feeModalProps = useSelector(() => getModalDialogInfo({ modalId: FEE_MODAL_ID }))?.props || {};

    // LOD invoice email modal
    const isInvoiceEmailModalVisible = useSelector(() => isModalVisible({ modalId: INVOICE_EMAIL_MODAL_ID }));
    const invoiceEmailModalProps =
      useSelector(() => getModalDialogInfo({ modalId: INVOICE_EMAIL_MODAL_ID }))?.props || {};

    // LOD invoice communicate modal
    const isInvoiceCommunicateModalVisible = useSelector(() =>
      isModalVisible({ modalId: INVOICE_COMMUNICATE_MODAL_ID }),
    );
    const invoiceCommunicateModalProps =
      useSelector(() => getModalDialogInfo({ modalId: INVOICE_COMMUNICATE_MODAL_ID }))?.props || {};

    // LOD expense modal
    const isExpenseModalVisible = useSelector(() => isModalVisible({ modalId: EXPENSE_MODAL_ID }));
    const expenseModalProps = useSelector(() => getModalDialogInfo({ modalId: EXPENSE_MODAL_ID }))?.props || {};

    // LOD operating cheque details modal
    const isOperatingChequeDetailsModalVisible = useSelector(() =>
      isModalVisible({ modalId: OPERATING_CHEQUE_DETAILS_MODAL_ID }),
    );
    const operatingChequeDetailsModalProps =
      useSelector(() => getModalDialogInfo({ modalId: OPERATING_CHEQUE_DETAILS_MODAL_ID }))?.props || {};

    // LOD print operating cheque modal
    const isPrintOperatingChequeModalVisible = useSelector(() =>
      isModalVisible({ modalId: PRINT_OPERATING_CHEQUE_MODAL_ID }),
    );
    const printOperatingChequeModalProps =
      useSelector(() => getModalDialogInfo({ modalId: PRINT_OPERATING_CHEQUE_MODAL_ID }))?.props || {};

    useEffect(() => {
      if (isTimeEntryModalVisible) {
        onTimeEntryModalOpen(timeEntryModalProps.matterId);
        setModalDialogHidden({ modalId: ADD_TIME_ENTRY_MODAL_ID });
      }
    }, [isTimeEntryModalVisible, timeEntryModalProps.matterId]);

    // LOD credit card payment modal
    const isCreditCardPaymentModalVisible = useSelector(() =>
      isModalVisible({ modalId: CREDIT_CARD_PAYMENT_MODAL_ID }),
    );
    const creditCardPaymentModalProps =
      useSelector(() => getModalDialogInfo({ modalId: CREDIT_CARD_PAYMENT_MODAL_ID }))?.props || {};

    // LOD unpaid AD acknowledgement modal
    const isUnpaidAnticipatedDisbursementAcknowledgementModalVisible = useSelector(() =>
      isModalVisible({ modalId: UNPAID_ANTICIPATED_DISBURSEMENT_ACKNOWLEDGEMENT_MODAL_ID }),
    );
    const unpaidAnticipatedDisbursementAcknowledgementModalProps =
      useSelector(() => getModalDialogInfo({ modalId: UNPAID_ANTICIPATED_DISBURSEMENT_ACKNOWLEDGEMENT_MODAL_ID }))
        ?.props || {};

    // Mark As Sent modal
    const isMarkAsSentModalVisible = useSelector(() => isModalVisible({ modalId: MARK_AS_SENT_MODAL_ID }));
    const markAsSentModalProps = useSelector(() => getModalDialogInfo({ modalId: MARK_AS_SENT_MODAL_ID }))?.props || {};

    // Finalise Invoice Confirmation Modal
    const isFinaliseInvoiceConfirmationModalVisible = useSelector(() =>
      isModalVisible({ modalId: FINALISE_INVOICE_CONFIRMATION_MODAL_ID }),
    );
    const isFinaliseInvoiceConfirmationModalProps =
      useSelector(() => getModalDialogInfo({ modalId: FINALISE_INVOICE_CONFIRMATION_MODAL_ID }))?.props || {};

    return {
      addPaymentNewModalProps,
      contactCreateEditModalProps,
      convertLeadModalProps,
      creditCardPaymentModalProps,
      creditModalProps,
      depositModalProps,
      draftInvoiceDeleteModalProps,
      draftInvoicePreviewModalProps,
      eventModalProps,
      expenseModalProps,
      feeModalProps,
      invoiceCommunicateModalProps,
      invoiceEmailModalProps,
      isAddEventModalVisible,
      isAddLeadModalVisible,
      isAddPaymentNewModalVisible,
      addMatterWizardModalProps,
      isAddMatterWizardModalVisible,
      isAddPaymentModalVisible,
      isAddPhoneMessageModalVisible,
      isAddTaskModalVisible,
      isContactCreateEditModalVisible,
      isConvertLeadModalVisible,
      isCreditCardPaymentModalVisible,
      isCreditNoteModalVisible,
      isDepositModalVisible,
      isDraftInvoiceDeleteModalVisible,
      isDraftInvoicePreviewModalVisible,
      isExpenseModalVisible,
      isFeeModalVisible,
      isFinaliseInvoiceConfirmationModalProps,
      isFinaliseInvoiceConfirmationModalVisible,
      isInvoiceCommunicateModalVisible,
      isInvoiceEmailModalVisible,
      isInvoiceStatementAddPaymentModalVisible,
      isInvoiceStatementEmailModalVisible,
      isMarkAsSentModalVisible,
      isOperatingChequeDetailsModalVisible,
      isPrintOperatingChequeModalVisible,
      isProtectedFundsModalVisible,
      isRestoreMatterModalVisible,
      isTransferFundsModalVisible,
      isUnpaidAnticipatedDisbursementAcknowledgementModalVisible,
      isVendorPaymentEntrySplitPayorsModalVisible,
      invoiceStatementEmailModalProps,
      invoiceStatementPaymentModalProps,
      markAsSentModalProps,
      newPhoneNumberModalProps,
      operatingChequeDetailsModalProps,
      paymentModalProps,
      printOperatingChequeModalProps,
      protectedFundsModalProps,
      restoreMatterModalProps,
      taskModalProps,
      transferFundsModalProps,
      unpaidAnticipatedDisbursementAcknowledgementModalProps,
      vendorPaymentEntrySplitPayorsProps,
    };
  },
});

export const BillingModalsContainer = withApolloClient(withReduxProvider(composeHooks(hooks)(BillingModals)));

BillingModalsContainer.displayName = 'BillingModalsContainer';

BillingModalsContainer.propTypes = {
  onTimeEntryModalOpen: PropTypes.func.isRequired,
  onClickLink: PropTypes.func.isRequired,
  onClickLinks: PropTypes.func.isRequired,
  printCheques: PropTypes.func,
};

BillingModalsContainer.defaultProps = {
  printCheques: () => {},
};

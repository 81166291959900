import { DocumentsTab } from 'web/components';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { getPersonByUserId } from '@sb-firm-management/redux/firm-management';
import { getMatterDisplayById } from '@sb-matter-management/redux/matters';
import composeHooks from '@sb-itops/react-hooks-compose';
import { useState, useRef } from 'react';
import { fetchPostP, fetchGetP } from '@sb-itops/redux/fetch';
import { useSort } from '@sb-itops/redux/sort/use-sort';
import { Folder, File, Expanded, Document, DocumentResults } from 'types';
import { useSubscribedLazyQuery } from 'web/hooks';
import { withApolloClient } from 'web/react-redux/hocs/withApolloClient';
import * as messageDisplay from '@sb-itops/message-display';
import { debounce } from '@sb-itops/nodash';
import { FileDetailDocument } from 'web/graphql/types/graphql';
import { getApolloClient } from 'web/services/apollo';
import { sendMetric } from 'web/services/metrics';
import { selectors as appSelectors } from 'web/redux/features/application-state';
import { useSelector } from 'react-redux';
import { useOpenMatterDocumentOnWeb } from 'web/hooks/use-open-matter-document-on-web';
import { useCreateLetter } from 'web/hooks/use-create-letter';
import { useMatterDocumentsHook } from './useMatterDocumentsHook';

const scope = 'MATTER_DOCUMENTS';

interface IDocumentsTabContainerProps {
  matterId: string;
}

const hooks = ({ matterId }: IDocumentsTabContainerProps) => ({
  useDocumentsTabStore: () => {
    const {
      createNewFolder,
      uploadFiles,
      replaceFile,
      setDocuments,
      selectedFolder,
      documents,
      loading,
      selectedPath,
      setSelectedPath,
      error,
    } = useMatterDocumentsHook({ matterId });

    const { isUnauthorized: oneDriveIsUnauthorized } = useCreateLetter();

    const { isOpeningDocumentOnWeb, openDocumentOnWeb } = useOpenMatterDocumentOnWeb();

    const [expanded, setExpanded] = useState({ root: true } as Expanded);
    const [showRenameModal, setShowRenameModal] = useState<Folder | File | false>(false);
    const [showMoveModal, setShowMoveModal] = useState<Document[] | false>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<Document[] | false>(false);
    const [showReplaceModal, setShowReplaceModal] = useState<{ onReplace: () => void; onRename: () => void } | false>(
      false,
    );
    const [showCreateLetterModal, setShowCreateLetterModal] = useState<boolean>(false);
    const [searchResults, setSearchResults] = useState<DocumentResults[] | undefined>([]);
    const [searchFilter, setSearchFilter] = useState('');
    const searchDocumentsRef = useRef<(searchTerm: string) => Promise<void>>();
    if (!searchDocumentsRef.current) {
      searchDocumentsRef.current = debounce(
        async (searchTerm: string) => {
          if (searchTerm.length > 2) {
            setSearchResults(undefined);
            const fetchOptions = { body: JSON.stringify({ searchTerm, matterId }) };
            const response = await fetchPostP({
              path: `/matter-management/document-search/document/:accountId/`,
              fetchOptions,
            });
            setSearchResults(response.body.results);
          }
        },
        2000, // wait in milliseconds
        { leading: false },
      );
    }
    const [showNewFolderModal, setShowNewFolderModal] = useState(false);
    const [showShareModal, setShowShareModal] = useState<{ documents: Document[]; isLiving: boolean } | false>(false);
    const { sortBy, setSortBy, sortDirection, setSortDirection } = useSort({
      scope: scope + matterId,
      initialSortBy: 'Name',
      initialSortDirection: 'asc',
    });

    const [getFileDetail, fileDetailResult] = useSubscribedLazyQuery(
      FileDetailDocument,
      { variables: { id: '' } },
      { notificationIds: ['none'] },
    );

    const [showFileDetailModal, setShowFileDetailModal] = useState<File | false>(false);

    const [showDownloadFileManagerModal, setShowDownloadFileManagerModal] = useState<Document | false>(false);

    const isFileManagerInstalled: boolean = useSelector(appSelectors.getFileManagerInstalled);

    return {
      isFileManagerInstalled,
      searchFilter,
      searchResults,
      setSearchFilter: (searchTerm = '') => {
        if (searchDocumentsRef.current) {
          searchDocumentsRef.current(searchTerm);
        }
        setSearchResults(undefined);
        setSearchFilter(searchTerm);
      },
      onDownload: async ({ rowData }) => {
        sendMetric('DocumentsDownload');
        if (rowData.loading) {
          messageDisplay.error(`${rowData.data.name + rowData.data.fileExtension} has not finished uploading yet`);
          return;
        }
        const DOWNLOAD_GROUP_NAME = 'download-group';
        messageDisplay.info(
          messageDisplay.builder().text('Your download will start shortly, please wait').group(DOWNLOAD_GROUP_NAME),
        );

        const apolloClient = getApolloClient();
        const fileDetail = await apolloClient.query({
          query: FileDetailDocument,
          variables: { id: rowData.data.id },
          fetchPolicy: 'network-only',
        });

        if (fileDetail?.data?.fileDetail?.versions?.[0]?.isCancelled) {
          messageDisplay.dismissGroup(DOWNLOAD_GROUP_NAME);
          messageDisplay.error(`The upload of ${rowData.data.name + rowData.data.fileExtension} was cancelled`);
          return;
        }
        if (fileDetail?.data?.fileDetail?.versions?.[0]?.isUploaded === false) {
          messageDisplay.dismissGroup(DOWNLOAD_GROUP_NAME);
          messageDisplay.error(`${rowData.data.name + rowData.data.fileExtension} has not finished uploading yet`);
          return;
        }

        const {
          body: { downloadUrl },
        } = await fetchGetP({
          path: `/matter-management/matter/file/:accountId/${matterId}/${rowData.data.id}/download`,
          fetchOptions: {},
        });
        // Download the file
        const link = document.createElement('a');

        link.href = downloadUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      showFileDetailModal,
      setShowFileDetailModal,
      getFileDetail,
      fileDetailResult,
      getPersonByUserId,
      matterName: getMatterDisplayById(matterId),
      showNewFolderModal,
      setShowNewFolderModal,
      showRenameModal,
      setShowRenameModal,
      showMoveModal,
      setShowMoveModal,
      showDeleteModal,
      setShowDeleteModal,
      showReplaceModal,
      setShowReplaceModal,
      showShareModal,
      setShowShareModal,
      uploadFiles,
      replaceFile,
      expanded,
      setExpanded,
      setDocuments,
      createNewFolder,
      selectedFolder,
      selectedPath,
      setSelectedPath: (p: string) => {
        setSelectedPath(p);
        setSearchFilter('');
      },
      documents,
      loading: !!loading,
      error,
      sortDirection,
      sortBy,
      setSort: (stuff) => {
        setSortBy(stuff);
        setSortDirection(stuff);
      },
      showCreateLetterModal,
      setShowCreateLetterModal,
      showDownloadFileManagerModal,
      setShowDownloadFileManagerModal,
      oneDriveIsUnauthorized,
      isOpeningDocumentOnWeb,
      openDocumentOnWeb,
    };
  },
});

export const ViewMatterDocumentsRoute = withApolloClient(withReduxProvider(composeHooks(hooks)(DocumentsTab)));

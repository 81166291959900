import React from 'react';

import { Button, buttonTypes, useTranslation, FloatingCard, StatelessSelect, Icon, Spinner } from '@sb-itops/react';
import classNames from 'classnames';
import * as Styles from './CalendarSettings.module.scss';
import microsoft365Logo from '../../../assets/Microsoft_365_(2022).svg';

type TProps = {
  loading: boolean;
  calendarLoading: boolean;
  isSyncing: boolean;
  disableForm: boolean;
  selectedCalendar: string;
  microsoftAccountName: string;
  calendars: {
    id: string;
    name: string;
    ownerName: string;
    ownerEmailAddress: string;
    isPublic: boolean;
  }[];
  isAuthed: boolean;
  changedForm: boolean;
  inProgress: boolean;
  setSelectedCalendar: (value: string) => void;
  onDisableCalendar: () => void;
  onAuthorise: () => void;
  onDeauthorise: () => void;
  onCancel: () => void;
  onSave: (option: any) => void;
  refreshCalendars: () => void;
};

export const CalendarSettings = ({
  loading,
  calendarLoading,
  isSyncing,
  disableForm,
  selectedCalendar,
  microsoftAccountName,
  calendars,
  isAuthed,
  inProgress,
  onDisableCalendar,
  onAuthorise,
  setSelectedCalendar,
  refreshCalendars,
  onDeauthorise,
  onCancel,
  changedForm,
  onSave,
}: // calendars,
TProps) => {
  const { t } = useTranslation();

  if (loading) {
    return <i className="fa fa-spinner fa-pulse fa-fw" />;
  }

  let authState = 'inactive';
  if (!isAuthed && inProgress) {
    authState = 'in progress';
  } else if (isAuthed) {
    authState = `${t('authorise')}d`;
  }

  return (
    <div className={Styles.page}>
      <FloatingCard applyMargin={false}>
        <div className={Styles.title}>
          <div>
            <img className={`icon ${Styles.icon}`} alt="microsoft excel" src={microsoft365Logo} />
          </div>
          <h3>Microsoft 365</h3>
          <div
            className={classNames(
              Styles.activeTag,
              authState === 'in progress' && Styles.blueHighlight,
              isAuthed && Styles.greenHighlight,
            )}
          >
            {authState}
          </div>
        </div>
        {!isAuthed && (
          <p>
            Enable two-way sync of calendar appointments between Microsoft Outlook and Smokeball if you have a Microsoft
            365 account. Note that read only calendars cannot be synced.
          </p>
        )}
        {(!isAuthed || authState === 'in progress') && (
          <div className={Styles.buttonsRow}>
            {!isAuthed && (
              <Button disabled={authState !== 'inactive'} onClick={onAuthorise}>
                {t('authorise')} M365
              </Button>
            )}
            {authState === 'in progress' && (
              <Button
                className={classNames(Styles.disconnectButton, Styles.alignRight)}
                type={buttonTypes.secondary}
                onClick={onCancel}
              >
                Cancel {t('authorisation')}
              </Button>
            )}
          </div>
        )}
      </FloatingCard>
      {isAuthed && (
        <div className={Styles.accountInfo}>
          {!!microsoftAccountName && (
            <>
              <h4>Microsoft 365 Account</h4>
              <input className="form-control" type="text" value={microsoftAccountName} disabled />
            </>
          )}

          <h4 className={Styles.calendarTitle}>
            Calendar{' '}
            <Icon
              className={Styles.infoIcon}
              type="question-mark"
              tooltip="You can select one M365 calendar to sync to Smokeball. The initial sync of any calendar may take some time to complete."
            />
          </h4>
          <div className={Styles.calendarList}>
            {calendarLoading ? (
              <Spinner small />
            ) : (
              <StatelessSelect
                options={[{ id: '', name: ' ' }, ...calendars].map((i) => ({ value: i.id, label: i.name }))}
                selectedOption={selectedCalendar}
                onChange={(option) => setSelectedCalendar(option.value)}
                disabled={disableForm}
              />
            )}
            <Button disabled={calendarLoading || disableForm} type="secondary" onClick={() => refreshCalendars()}>
              Refresh List
            </Button>
          </div>
          {isSyncing && (
            <div className={Styles.syncMessage}>
              <Spinner small /> Sync in progress. You can safely navigate away from this page.
            </div>
          )}
          {!isSyncing && selectedCalendar && !changedForm && (
            <div className={Styles.syncMessage}>
              <i className={`icon icon-icon-fat-check-circle-filled ${Styles.greenCheck}`} /> Sync up to date
            </div>
          )}
          <div className={Styles.buttonsRow}>
            <Button disabled={disableForm} onClick={onSave}>
              Save
            </Button>
            {selectedCalendar && !changedForm && (
              <Button
                disabled={disableForm}
                className={Styles.disconnectButton}
                type={buttonTypes.secondary}
                onClick={onDisableCalendar}
              >
                Disable Calendar
              </Button>
            )}
            <Button
              disabled={disableForm}
              className={Styles.disconnectButton}
              type={buttonTypes.secondary}
              onClick={onDeauthorise}
            >
              De{t('authorise')} Account
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

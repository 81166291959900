import { getById as getExpenseById } from '@sb-billing/redux/expenses';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { featureActive } from '@sb-itops/feature';

import { EXPENSE_MODAL_ID } from 'web/components';

angular
  .module('sb.billing.webapp')
  .controller('BulkBillController', function (
    $uibModal,
    $state,
    sbLinkService,
    sbAsyncOperationsService,
  ) {
    const that = this;

    that.openExpenseModal = openExpenseModal;
    that.onNavigateTo = onNavigateTo;
    that.onClickLink = sbLinkService.onClickLink;

    function openExpenseModal(expenseId) {
      if (featureActive('BB-13186')) {
        setModalDialogVisible({
          modalId: EXPENSE_MODAL_ID,
          props: {
            scope: 'BulkCreateInvoicesRoute/expense-modal',
            expenseId,
            sbAsyncOperationsService,
          },
        });
      } else {
        const entry = getExpenseById(expenseId);
        const preFill = {
          ...entry,
          date: entry.expenseDate,
          staffId: entry.expenseEarnerStaffId,
          matter: entry.matterId,
          activityCode: entry.expenseActivityId,
          units: entry.quantity / 100,
          rate: entry.price / 100,
          billable: !!entry.isBillable,
          waived: !!entry.waived,
        };

        $uibModal.open({
          templateUrl: 'ng-components/expense-entry/modal/expense-modal.html',
          controller: 'SbExpenseModalController',
          resolve: {
            sbExpenseModalConfig: function () {
              return {
                preFill,
                features: { update: !!preFill, saveAndNew: !preFill }
              };
            }
          },
          backdrop: 'static',
        });
      }
    }

    function onNavigateTo(route, params) {
      $state.go(route, params);
    }
  });

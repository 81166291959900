export const getSelectedIds = (state) => ({
  ...(state.transactionIds || {}),
  ...(state.depositSlipIds || {}),
  ...(state.ttoIds || {}),
});

export const getSelectedTransactionIds = (state) =>
  state.transactionIds ? Object.keys(state.transactionIds).filter((id) => state.transactionIds[id]) : [];

export const getSelectedDepositSlipIds = (state) =>
  state.depositSlipIds ? Object.keys(state.depositSlipIds).filter((id) => state.depositSlipIds[id]) : [];

export const getSelectedTtoIds = (state) =>
  state.ttoIds ? Object.keys(state.ttoIds).filter((id) => state.ttoIds[id]) : [];

export const getExpandedIds = (state) => state.expandedIds;

export const getShowPayments = (state) => state.showPayments;
export const getShowReceipts = (state) => state.showReceipts;
export const getShowDeleted = (state) => state.showDeleted;

export const getSearchFilter = (state) => state.searchFilter;

export const getShowSummary = (state) => state.showSummary;

export const getShowUnreconciled = (state) => state.showUnreconciled;

export const getAdjustments = (state) => state.adjustments;

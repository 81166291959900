import { onCreateEditPersonContact } from './opdaters/create-edit-person-contact';
import { onCreateEditCompanyContact } from './opdaters/create-edit-company-contact';
import { onCreateEditTrustContact } from './opdaters/create-edit-trust-contact';
import { onSaveExpense } from './opdaters/save-expense';
import { onUpdateExpenseBulkIsBillable } from './opdaters/update-expense-bulk-billable';
import { onSetSubscriptionPaymentMethod } from './opdaters/set-subscription-payment-method';
import { onPatchSmokeballContact } from './opdaters/patch-smokeball-contact';
import { onBulkDeleteDraftInvoices } from './opdaters/bulk-delete-draft-invoices'

const opdateHandlers = {
  'Integration.CreatePersonContact': onCreateEditPersonContact,
  'Integration.CreateCompanyContact': onCreateEditCompanyContact,
  'Integration.CreateTrustContact': onCreateEditTrustContact,
  'Integration.ExpensesBulkUpdateBillable': onUpdateExpenseBulkIsBillable,
  'Billing.Expenses.Commands.SaveExpense': onSaveExpense,
  'Finance.SetSubscriptionPaymentMethod': onSetSubscriptionPaymentMethod,
  'CustomerManagement.PatchSmokeballContact': onPatchSmokeballContact,
  'Integration.BulkDeleteDraftInvoices': onBulkDeleteDraftInvoices,
};

/**
 * Applies entity opdates required by a dispatched commands into the legacy cache.
 * Compatible with subscribeToCommandDispatchEvents() in @sb-integration/web-client-sdk.
 *
 * This function is connected to the web-client-sdk in the bootstrapper service.
 *
 * @param {Object} event
 * @param {string} event.commandType The type of command that was dispatched
 * @param {Object} event.commandMessage The message component of the command that was dispatched
 * @param {Object} event.commandResult The result of the dispatched command
 */
export const opdateLegacyCacheForCommandResult = ({ commandType, commandMessage, commandResult }) => {
  const opdateLegacyCaches = opdateHandlers[commandType];

  if (!opdateLegacyCaches) {
    return;
  }

  opdateLegacyCaches({ commandType, commandMessage, commandResult });
};

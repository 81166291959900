import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as forms from '@sb-itops/redux/forms2';
import { withReduxProvider } from 'web/react-redux/hocs/withReduxProvider';
import { useDispatch, useSelector } from 'react-redux';
import { useScopedFeature } from '@sb-itops/redux/hooks';
import { getSummaryData } from '@sb-billing/business-logic/bank-reconciliation/summary';
import { isBankAccountClosed } from '@sb-billing/business-logic/bank-account/services';
import { getById as getBankAccount } from '@sb-billing/redux/bank-account';
import * as bankReconciliationsFeature from 'web/redux/route/home-billing-create-bank-reconciliation';
import { integerToDate } from '@sb-itops/date';
import { getReconciliationsForMonth } from '@sb-billing/redux/bank-reconciliations';
import { getById as getTransactionById } from '@sb-billing/redux/transactions';
import { getById as getDepositSlipById } from '@sb-billing/redux/deposit-slip';
import { getLatest as getLatestEndOfMonthReport } from '@sb-billing/redux/end-of-month-reports';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { BankReconciliationSummaryPanel } from './BankReconciliationSummaryPanel';

const finaliseReconModalId = 'billing-create-bank-reconciliation-finalise-confirm-modal';

const isFirstBankReconciliationInTheSameMonth = (momentFirstBankRecDate, momentBankRecDate) =>
  momentFirstBankRecDate.format('YYYYMM') === momentBankRecDate.format('YYYYMM');

export const BankReconciliationSummaryPanelContainer = (props) => {
  const {
    onCancel,
    onSave,
    onRecon,
    summaryData,
    validateFn,
    finalisingRecon,
    getTtoById,
    trustAccountId,
    onValidateFinalise,
    ianaTimezone,
  } = props;
  const scope = `billing-create-bank-reconciliation-form-${trustAccountId}`;

  const { selectors: formSelectors, actions: formActions, operations: formOperations } = useScopedFeature(forms, scope);
  const { formInitialised, fields: formFields, formValid } = useSelector(formSelectors.getFormState);

  const bankRecScope = `billing-bank-reconciliations-${trustAccountId}`;
  const {
    selectors: { getShowSummary },
    actions: { toggleShowSummary },
    operations: { untoggleInvalidSelectedIds },
  } = useScopedFeature(bankReconciliationsFeature, bankRecScope);

  const isPanelExpanded = useSelector(getShowSummary);
  const trustBankAccount = getBankAccount(trustAccountId);
  const isAccountClosed = isBankAccountClosed({
    bankAccount: trustBankAccount,
  });

  const {
    endDate: formEndDateAsInt,
    startDate: formStartDateAsInt,
    bankStatementBalance: formBankStatementBalance,
    adjustments: formAdjustments, // Doesn't actually contain the adjustments, just a field to track errors
  } = formFields;

  const withComputedData = getSummaryData({
    summaryData,
    endDate: formEndDateAsInt?.value,
    startDate: formStartDateAsInt?.value,
    bankStatementBalance: formBankStatementBalance?.value,
    cannotReconcileAcrossMultipleMonth: hasFacet(facets.bankRecCannotReconcileAcrossMultipleMonths),
    cannotReconcileOnToday: hasFacet(facets.bankRecCannotReconcileOnToday),
  });

  const {
    openingCashBookBalance,
    addReceipts,
    lessPayments,
    ledgerBalance,
    addUnbankedReceipts,
    lessUnpresentedPayments,
    cashBookBalanceAsAtBsd,
    reconciliationBalance,
    adjustmentsTotal,
    offset,
    maxDate,
  } = withComputedData;

  const [showRunEndOfMonthReporting, setShowRunEndOfMonthReporting] = useState(false);
  const [runEndOfMonthReporting, setRunEndOfMonthReporting] = useState(false);
  const [listenForConfirmFinalise, setListenForConfirmFinalise] = useState(false);

  if (listenForConfirmFinalise) {
    if (formValid) {
      setModalDialogVisible({ modalId: finaliseReconModalId });
    }
    setListenForConfirmFinalise(false);
  }

  useEffect(
    () => {
      const getShowRunEndOfMonthReporting = () => {
        if (!hasFacet(facets.endOfMonthReport) || !formStartDateAsInt?.value || !formEndDateAsInt?.value) {
          return false;
        }

        const endOfMonthDate = moment(integerToDate(formStartDateAsInt?.value)).endOf('month').format('YYYYMMDD');
        const endDate = moment(integerToDate(formEndDateAsInt?.value));
        const endDateFormatted = endDate.format('YYYYMMDD');

        if (endOfMonthDate !== endDateFormatted) {
          return false;
        }

        const latestEndOfMonth = getLatestEndOfMonthReport(trustAccountId);

        const nextEndOfMonthReportDate =
          latestEndOfMonth &&
          latestEndOfMonth.date &&
          moment(latestEndOfMonth.date, 'YYYYMMDD').add(1, 'months').format('YYYYMM');

        if (latestEndOfMonth && nextEndOfMonthReportDate !== endDate.format('YYYYMM')) {
          return false;
        }

        // we are valdiating not overlapping bank recs. (between 2 month)
        // in that case we cannot run end of month report
        const allBankRecsInAMonth = getReconciliationsForMonth({ date: endDate.toDate(), trustAccountId });
        const firstBankReconciliationOfTheMonth = allBankRecsInAMonth && allBankRecsInAMonth[0];

        const momentFirstBankRecDate =
          firstBankReconciliationOfTheMonth &&
          firstBankReconciliationOfTheMonth.startDate &&
          moment(firstBankReconciliationOfTheMonth.startDate, 'YYYYMMDD');

        if (!momentFirstBankRecDate) {
          return true;
        }

        if (!isFirstBankReconciliationInTheSameMonth(momentFirstBankRecDate, endDate)) {
          return false;
        }

        return true;
      };
      setShowRunEndOfMonthReporting(getShowRunEndOfMonthReporting());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formEndDateAsInt?.value, formStartDateAsInt?.value],
  );

  const dispatch = useDispatch();

  return (
    <BankReconciliationSummaryPanel
      {...{
        trustAccountId,
        finaliseReconModalId,
        runEndOfMonthReporting,
        showRunEndOfMonthReporting,
        // computedData
        bankStatementBalance: formBankStatementBalance,
        reconciliationBalance,
        offset,
        adjustmentsTotal,
        cashBookBalanceAsAtBsd,
        startDate: formStartDateAsInt,
        endDate: formEndDateAsInt,
        maxDate,
        // summaryData
        adjustments: formAdjustments,
        openingCashBookBalance,
        addReceipts,
        lessPayments,
        ledgerBalance,
        addUnbankedReceipts,
        lessUnpresentedPayments,
        // form
        formInitialised,
        formValid,
        // panel
        expanded: isPanelExpanded,
        // functions
        onToggleExpanded: () => dispatch(toggleShowSummary()),
        onFieldValueUpdated: (fieldValues) => {
          dispatch(formActions.updateFieldValues({ fieldValues }));
          dispatch(formOperations.validateForm({ validateFn }));
        },
        validateFinalise: () => {
          onValidateFinalise();
          setListenForConfirmFinalise(true);
        },
        onRunEndOfMonthReportingChange: (checked) => {
          setRunEndOfMonthReporting(checked);
        },
        onEndDateUpdated: (fieldValues) => {
          const { endDate } = fieldValues;
          dispatch(formActions.updateFieldValues({ fieldValues }));
          dispatch(formOperations.validateForm({ validateFn }));
          dispatch(
            untoggleInvalidSelectedIds({ getTransactionById, getDepositSlipById, getTtoById, endDate, ianaTimezone }),
          );
        },
        onCancel,
        onSave,
        onRecon,
        finalisingRecon,
        isAccountClosed,
      }}
    />
  );
};

BankReconciliationSummaryPanelContainer.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onRecon: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onValidateFinalise: PropTypes.func.isRequired,
  summaryData: PropTypes.object.isRequired,
  validateFn: PropTypes.func.isRequired,
  getTtoById: PropTypes.func.isRequired,
  finalisingRecon: PropTypes.bool.isRequired,
  trustAccountId: PropTypes.string.isRequired,
  ianaTimezone: PropTypes.string.isRequired,
};

BankReconciliationSummaryPanelContainer.defaultProps = {};

export default withReduxProvider(BankReconciliationSummaryPanelContainer);

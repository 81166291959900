import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withLinkHandler } from '@sb-itops/react/hoc';
import TrustTransactionTableContainer from './TrustTransactionTable.container';

function openTxnModal($uibModal, matterId, contactId) {
  return ({ rowData: txn, showHidden }) => {
    $uibModal.open({
      templateUrl: 'ng-components/bank-account-transaction-details/modal/bank-account-transaction-details-modal.html',
      controller: 'SbBankAccountTransactionDetailsModalController',
      size: 'lg',
      resolve: {
        transactionId: () => txn.id,
        transaction: null,
        matterId: () => matterId,
        contactId: () => contactId,
        showHidden: () => showHidden,
      },
      backdrop: 'static',
    });
  };
}

const TrustTransactionTableBridge = memo(
  ({
    $uibModal,
    sbAsyncOperationsService,
    matterId,
    contactId,
    showContact,
    showMatter,
    transactions,
    sortBy,
    sortDirection,
    sort,
    summary,
    onClickLink,
    showSystemDate,
    listType,
    onReceiptLink, // provided by withLinkHandler
    onTransferReceiptLink, // provided by withLinkHandler
    onVendorProofOfPaymentLink,
    onTrustToOfficeTransferReceiptLink,
  }) => {
    const printCheque = (chequeId) => sbAsyncOperationsService.startChequeCreation([chequeId]);
    const modal = openTxnModal($uibModal, matterId, contactId);

    return (
      <TrustTransactionTableContainer
        transactions={transactions}
        summary={summary}
        onClickLink={onClickLink}
        sortBy={sortBy}
        sortDirection={sortDirection}
        sort={sort}
        printCheque={printCheque}
        modal={modal}
        showSystemDate={showSystemDate}
        showContact={showContact}
        showMatter={showMatter}
        listType={listType}
        onReceiptLink={onReceiptLink}
        onTransferReceiptLink={onTransferReceiptLink}
        onVendorProofOfPaymentLink={onVendorProofOfPaymentLink}
        onTrustToOfficeTransferReceiptLink={onTrustToOfficeTransferReceiptLink}
      />
    );
  },
);

const Wrapper = withLinkHandler('transferReceipt')(
  withLinkHandler('trustToOfficeTransferReceipt')(
    withLinkHandler('receipt')(withLinkHandler('vendorProofOfPayment')(TrustTransactionTableBridge)),
  ),
);

Wrapper.propTypes = {
  showMatter: PropTypes.bool,
  showContact: PropTypes.bool,
  transactions: PropTypes.array,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  summary: PropTypes.object,
  sort: PropTypes.func,
  onClickLink: PropTypes.func,
  listType: PropTypes.oneOf(['accounts', 'matter', 'contact']).isRequired,
  matterId: PropTypes.string,
  contactId: PropTypes.string,
};

Wrapper.defaultProps = {
  sortBy: 'effectiveDate',
  sortDirection: 'asc',
  transactions: [],
  summary: {},
};

export default Wrapper;

'use strict';

import { store } from '@sb-itops/redux';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';

import { getList, getExpenseByIdAndVersion } from '@sb-billing/redux/expenses';

const getUserId = () => authSelectors.getUserId(store.getState());

angular.module('@sb-billing/services').service('sbExpenseService', function ($rootScope, sbUuidService, sbGenericEndpointService, sbLoggerService) {
  var that = this,
    log = sbLoggerService.getLogger('sbExpenseService'),
    endpoint = 'billing/expense';

  //log.setLogLevel('info');

  that.getExpenseById = getExpenseByIdAndVersion;
  that.saveExpenseP = saveExpenseP;
  that.getMatterExpenses = getMatterExpenses;
  that.getUnbilledMatterExpenses = getUnbilledMatterExpenses;
  that.deleteExpenseP = deleteExpenseP;
  that.getExpenseVersions = getExpenseVersions;
  that.isLatestVersion = isLatestVersion;

  function saveExpenseP({ expenseId, expenseVersion, expenseVersionId }) {
    expenseVersion.expenseId = expenseId || sbUuidService.get();
    expenseVersion.expenseVersionId = expenseVersionId || sbUuidService.get();

    var savePostP = function (userId) {
      expenseVersion.createdByUserId = userId;
      log.info('save expense', expenseVersion);
      return sbGenericEndpointService.postPayloadP(endpoint, undefined, expenseVersion, 'POST', {changeset: { sbExpenseService: [expenseVersion]}});
    };

    var processSaveRespP = function () {
      const payload = _.cloneDeep(expenseVersion);
      $rootScope.$broadcast('smokeball-data-update-expense-saved', payload);
      // Some react code is listening to angular events via the redux store. This duplicates the event for those listeners
      store.dispatch({ type: 'smokeball-data-update-expense-saved', payload });
      return expenseVersion;
    };

    var handleErr = function (err) {
      log.error('failed to save expense', err);
      return Promise.reject('Failed to save expense. Please check your connection and try again.');
    };

    const userId = getUserId();
    return savePostP(userId)
      .then(processSaveRespP)
      .catch(handleErr);
  }

  function deleteExpenseP(expenseId) {
    var expenseVersion = getExpenseByIdAndVersion(expenseId);
    expenseVersion.expenseVersionId = sbUuidService.get();
    expenseVersion.isDeleted = true;
    log.info('delete expense', expenseVersion);

    var processDeleteRespP = function () {
      $rootScope.$broadcast('smokeball-data-update-expense-deleted', _.cloneDeep(expenseVersion));
      return expenseVersion;
    };

    var handleErr = function (err) {
      log.error('error deleting expense %s', expenseVersion.expenseId, err);
      return Promise.reject('Failed to delete expense. Please check your connection and try again.');
    };

    return sbGenericEndpointService.postPayloadP(endpoint, expenseId + '/' + expenseVersion.expenseVersionId, {}, 'DELETE', {changeset: { sbExpenseService: [expenseVersion]}})
      .then(processDeleteRespP)
      .catch(handleErr);
  }


  function extractExpensesByMatter(matterId) {
    return getList().filter((expense) => {
      return expense && expense.matterId === matterId;
    });
  }

  function getMatterExpenses(matterId) {
    return extractExpensesByMatter(matterId);
  }

  /**
   * DON'T USE - unless if there's a really good reason to not just fetch the latest
   *
   * Check if we need any version other than the latest on the frontend
   * if not retire this, the issue with using this is the version doesn't have
   * some information like operatingChequeId
   */
  function getExpenseVersions(versions) {
    if (!_.isEmpty(versions)) {
      return _.map(versions, function (version) {
        return getExpenseByIdAndVersion(version.id, version.versionId);
      });
    }
  }

  function isLatestVersion(expenseId, expenseVersionId) {
    var expenseVersion = getExpenseByIdAndVersion(expenseId);
    return expenseVersion && expenseVersion.expenseVersionId === expenseVersionId;
  }

  /**
   * @param mattersIncludedNonBillableItems An associative array of matter IDs to booleans, specifying for each matter
   * whether to include non-billable fees.
   */
  function getUnbilledMatterExpenses(startDate, endDate, mattersIncludeNonBillableItems) {
    return getList().filter((expense) => {
      return (
        expense &&
          expense.matterId &&
          !expense.invoiceId &&
          !expense.isDeleted &&
          (expense.isBillable || mattersIncludeNonBillableItems[expense.matterId]) &&
          expense.expenseDate &&
          expense.expenseDate >= startDate &&
          expense.expenseDate <= endDate &&
          !expense.isInvoicedExternally
      );
    });
  }
});

import composeHooks from '@sb-itops/react-hooks-compose';
import React, { useState, useEffect, useRef } from 'react';
import { fetchGetP, fetchPatchP, fetchPostP } from '@sb-itops/redux/fetch';
import { useIsMounted } from '@sb-itops/react';
import * as messageDisplay from '@sb-itops/message-display';
import { getLogger } from '@sb-itops/fe-logger';
import { dispatchCommand } from '@sb-integration/web-client-sdk';
import { toCamelCase } from '@sb-itops/camel-case';
import { subscribeToNotifications } from 'web/services/subscription-manager';
import { Staff, File, Document } from 'types';
import { FileDetailQuery } from 'web/graphql/types/graphql';
import { useMetric, sendMetric } from 'web/services/metrics';
import { ViewFile } from './ViewFile';

const scope = 'VIEW_FILE_DETAILS';

const log = getLogger(scope);

interface IViewFileModalContainerProps {
  matterId: string;
  file: File;
  getPersonByUserId: (id: string) => Staff;
  fileDetailResult: { data: FileDetailQuery | undefined; loading: boolean; refetch?: Function };
  getFileDetail: Function;
  setShowShareModal: (payload: { documents: Document[]; isLiving: boolean } | false) => void;
}

const getS3KeyFromURL = /\/(managefiles3\/.*?)\?/;

const previewableMap = {
  '.pdf': true,
  '.jpg': true,
  '.jpeg': true,
  '.png': true,
  '.gif': true,
  '.tiff': true,
};

const convertableMap = {
  '.doc': true,
  '.docx': true,
  '.txt': true,
  '.rtf': true,
  '.xls': true,
  '.xlsx': true,
  '.csv': true,
  '.msg': true,
  '.eml': true,
};

const hooks = ({
  getFileDetail,
  fileDetailResult,
  file,
  matterId,
  setShowShareModal,
}: IViewFileModalContainerProps) => ({
  useDocumentsTabStore: () => {
    const [versionId, setVersionId] = useState('');
    // S3 has pdfs set to download automatically with content-disposition: attachment. Unfortunately this cannot be overridden so we must save the pdf locally
    const [documentURL, setDocumentURL] = useState<string | undefined>('');
    const [loading, setLoading] = useState(false);
    const interval: React.MutableRefObject<NodeJS.Timer | undefined> = useRef();
    const isMounted = useIsMounted();

    useMetric('NavMatterFileDetails');

    const replaceFile = async (files: FileList | globalThis.File[] | null) => {
      const fileId = file?.data?.id;
      sendMetric('DocumentsReplace');

      if (!files || files.length > 1 || !fileId) {
        return;
      }
      const fileBlob = files[0];

      setLoading(true);

      try {
        const {
          body: { uploadUrl },
        } = await fetchPatchP({
          path: `/matter-management/matter/file/:accountId/${matterId}/${fileId}`,
          fetchOptions: {
            body: JSON.stringify({
              fileName: file.data.name + file.data.fileExtension,
            }),
          },
        });

        // XHR request required here so we can track the progress of the upload. This feature is unlikely to be implemented in the fetch API anytime soon.
        const xhr = new XMLHttpRequest();

        xhr.open('PUT', uploadUrl);
        xhr.setRequestHeader('Content-Type', '');
        xhr.addEventListener('load', () => {
          if (isMounted?.current) {
            setLoading(false);
          }
          if (xhr.status === 200) {
            messageDisplay.success('File upload complete');
          } else {
            messageDisplay.error('File upload failed');
          }
        });
        xhr.addEventListener('error', () => {
          if (isMounted?.current) {
            setLoading(false);
          }
          messageDisplay.error('File upload failed');
        });

        xhr.send(fileBlob);
      } catch (e) {
        messageDisplay.error('Something went wrong uploading your document. Please try again later');
        log.error(e);
      }
    };

    const restoreFile = async (vId: string) => {
      try {
        sendMetric('DocumentsRestore');
        const path = `/matter-management/matter/file/:accountId/${matterId}/${file.data.id}/download?versionId=${vId}`;
        setLoading(true);
        const {
          body: { downloadUrl },
        } = await fetchGetP({
          path,
          fetchOptions: {},
        });

        const fileBlob = await fetch(downloadUrl).then((res) => res.blob());
        replaceFile([new window.File([fileBlob], file.data.name + file.data.fileExtension)]);
      } catch (e: any) {
        messageDisplay.error('Something went wrong restoring your document. Please try again later');
      }
    };

    const downloadFile = async (vId: string) => {
      try {
        let path = `/matter-management/matter/file/:accountId/${matterId}/${file.data.id}/download`;
        if (vId) {
          path += `?versionId=${vId}`;
        }

        const {
          body: { downloadUrl },
        } = await fetchGetP({
          path,
          fetchOptions: {},
        });

        const pdfBlob = await fetch(downloadUrl).then((res) => res.blob());
        const downloadBlobURL = URL.createObjectURL(pdfBlob);

        const link = document.createElement('a');
        link.download = file.data.name + file.data.fileExtension;

        link.href = downloadBlobURL;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(downloadBlobURL);
      } catch (e: any) {
        messageDisplay.error('Something went wrong downloading your document. Please try again later');
      }
    };

    useEffect(() => {
      getFileDetail({ variables: { id: file.data.id } });
    }, []);
    useEffect(() => {
      if (!fileDetailResult.refetch) {
        return undefined;
      }
      const unsub = subscribeToNotifications({
        notificationIds: ['ManageFiles2Notifications'],
        callback: (documentUpdate: string) => {
          const message = documentUpdate.includes('MessageId')
            ? toCamelCase(JSON.parse(documentUpdate))
            : documentUpdate;
          if (message?.messageId === 'FilesUpdated' && message?.entityIds?.length) {
            message.entityIds.forEach(async (mId: string) => {
              if (matterId === mId && fileDetailResult.refetch) {
                fileDetailResult.refetch({ id: file.data.id });
              }
            });
          }
        },
      });
      return unsub;
    }, [fileDetailResult.refetch]);

    useEffect(() => {
      (async () => {
        if (documentURL) {
          URL.revokeObjectURL(documentURL);
        }
        setDocumentURL('');
        let path = `/matter-management/matter/file/:accountId/${matterId}/${file.data.id}/download`;
        if (versionId) {
          path += `?versionId=${versionId}`;
        }
        if (!convertableMap[file.data.fileExtension]) {
          if (previewableMap[file.data.fileExtension]) {
            const {
              body: { downloadUrl },
            } = await fetchGetP({
              path,
              fetchOptions: {},
            });

            let pdfBlob = await fetch(downloadUrl).then((res) => res.blob());
            if (file.data.fileExtension === '.pdf') {
              pdfBlob = pdfBlob.slice(0, pdfBlob.size, 'application/pdf');
            }
            if (isMounted) {
              setDocumentURL(URL.createObjectURL(pdfBlob));
            }
            return;
          }
          setDocumentURL(undefined);
          return;
        }
        if (file.data.id) {
          const {
            body: { downloadUrl },
          } = await fetchGetP({
            path,
            fetchOptions: {},
          });

          const bucketKey = downloadUrl.match(getS3KeyFromURL)[1];

          try {
            sendMetric('DocumentsGeneratePreview');
            const {
              body: { url },
            } = await fetchPostP({
              path: `/matter-management/document-preview/:accountId/sign`,
              fetchOptions: {
                body: JSON.stringify({
                  key: bucketKey,
                }),
              },
            });
            let pdfBlob = await fetch(url).then((res) => res.blob());
            // Workaround to display PDFs, other types work innately
            if (file.data.fileExtension === '.pdf') {
              pdfBlob = pdfBlob.slice(0, pdfBlob.size, 'application/pdf');
            }
            if (isMounted) {
              setDocumentURL(URL.createObjectURL(pdfBlob));
            }
          } catch (e: any) {
            if (e?.payload?.status === 404) {
              // file doesnt exist
              await dispatchCommand({
                type: 'ItOps.Communicate.Messages.Commands.ConvertFileToPdf',
                message: {
                  sourceKey: bucketKey,
                  fileName: file.data.name,
                  fileExtension: file.data.fileExtension,
                },
              });
              if (interval.current) {
                clearInterval(interval.current);
              }
              let count = 0;
              interval.current = setInterval(async () => {
                try {
                  const {
                    body: { url },
                  } = await fetchPostP({
                    path: `/matter-management/document-preview/:accountId/sign`,
                    fetchOptions: {
                      body: JSON.stringify({
                        key: bucketKey,
                      }),
                    },
                  });
                  clearInterval(interval.current);
                  let pdfBlob = await fetch(url).then((res) => res.blob());
                  if (file.data.fileExtension === '.pdf') {
                    pdfBlob = pdfBlob.slice(0, pdfBlob.size, 'application/pdf');
                  }
                  if (isMounted) {
                    setDocumentURL(URL.createObjectURL(pdfBlob));
                  }
                } catch (err: any) {
                  count += 1;
                  log.info('Preview not ready yet');
                  if (!isMounted || count > 12) {
                    clearInterval(interval.current);
                    setDocumentURL(undefined);
                  }
                }
              }, 10000);
            } else {
              log.error('Failed to get document preview', e);
            }
          }
        }
      })();
    }, [versionId, fileDetailResult.data]);

    const shareFile = ({ file: fileToShare, isLiving }: { file: File; isLiving: boolean }) => {
      setShowShareModal({ documents: [fileToShare], isLiving });
    };

    return {
      documentURL,
      setVersionId,
      versionId,
      fileDetailResult,
      loading,
      setLoading,
      downloadFile,
      replaceFile,
      restoreFile,
      shareFile,
    };
  },
});

export const ViewFileContainer = composeHooks(hooks)(ViewFile);

const { buildContactToAddress } = require('./build-contact-to-address');
const { filterContacts } = require('./filter-contacts');
const { getContactMailingAddress } = require('./get-contact-mailing-address');
const { getContactName } = require('./get-contact-name');
const { getContactDisplay } = require('./get-contact-display'); // This is for getting contact display name from LOD contact object
const { getContactOrganisationIdsForMatter } = require('./get-contact-organisation-ids-for-matter');
const { getContactSalutation } = require('./get-contact-salutation');
const { getContactStatusLabel } = require('./get-contact-status-label');
const { getFirstOrganisationInList } = require('./get-first-organisation-in-list');
const { getPersonTitles } = require('./get-person-titles');
const { isAbbreviatedTitle } = require('./is-abbreviated-title');
const { isSameAddress } = require('./is-same-address');

module.exports = {
  buildContactToAddress,
  filterContacts,
  getContactMailingAddress,
  getContactName,
  getContactDisplay,
  getContactOrganisationIdsForMatter,
  getContactSalutation,
  getContactStatusLabel,
  getFirstOrganisationInList,
  getPersonTitles,
  isAbbreviatedTitle,
  isSameAddress,
};

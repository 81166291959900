import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { forms2PropTypes, FormLabel, useTranslation, DatePicker } from '@sb-itops/react';
import { capitalize, capitalizeAllWords } from '@sb-itops/nodash';
import { includeErrorClassnameIfInvalid } from '../../add-edit-matter/include-error-classname-if-invalid';
import { matterFieldsRegionStrategy } from '../../add-edit-matter/matter-fields-region-strategy';

import Styles from './MatterDetails.module.scss';

export const getMatterStatusOptions = ({ includeDeleted, t }) => {
  const options = [
    { label: 'Open', value: 'Open' },
    { label: 'Pending', value: 'Pending' },
    { label: 'Closed', value: 'Closed' },
    { label: capitalize(t('cancelled')), value: 'Cancelled' },
  ];

  if (includeDeleted) {
    options.push({ label: 'Deleted', value: 'Deleted' });
  }

  return options;
};

export const MatterDetails = ({
  // form state
  formInitialised,
  formDisabled,
  submitFailed,
  // form fields
  matterNumber,
  description,
  openedDate,
  // other
  autoNumberDefinition,
  matterNumberExistsWarning,
  // callbacks
  onFieldValuesUpdated,
}) => {
  const { t } = useTranslation();
  if (!formInitialised) {
    return null;
  }

  const useAutoReference = autoNumberDefinition && autoNumberDefinition.useAutoReference;

  return (
    <fieldset className="section-fieldset" disabled={formDisabled}>
      {(!useAutoReference || matterFieldsRegionStrategy.matterNumberRequired) && (
        <div className="row">
          <div className="col-lg-12 form-group">
            <FormLabel
              label={capitalizeAllWords(t('matterNumber'))}
              field={matterNumber}
              uppercase={false}
              submitFailed={submitFailed}
              optional={!matterFieldsRegionStrategy.matterNumberRequired}
            />
            <div>
              <input
                className={classnames('form-control', includeErrorClassnameIfInvalid(matterNumber, submitFailed))}
                type="text"
                disabled={formDisabled || useAutoReference}
                value={(matterNumber && matterNumber.value) || ''}
                onChange={(e) => onFieldValuesUpdated({ matterNumber: e.target.value })}
              />
            </div>
            {matterNumberExistsWarning && (
              <div className={classnames('col-lg-12', Styles.errorMessage)}>{matterNumberExistsWarning}</div>
            )}
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-lg-12 form-group">
          <FormLabel
            label="Matter Description"
            field={description}
            uppercase={false}
            submitFailed={submitFailed}
            optional={!matterFieldsRegionStrategy.matterDescriptionRequired}
          />
          <div>
            <textarea
              className={classnames(
                'form-control',
                Styles.description,
                includeErrorClassnameIfInvalid(description, submitFailed),
              )}
              disabled={formDisabled}
              value={(description && description.value) || ''}
              onChange={(e) => {
                e.preventDefault();
                onFieldValuesUpdated({ description: e.target.value });
              }}
              rows="5"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 form-group">
          <label>Matter Opened</label>
          <DatePicker
            onSelect={(date) => {
              onFieldValuesUpdated({ openedDate: date });
            }}
            value={openedDate?.value}
            hasError={openedDate && openedDate?.isInvalid}
            format="DD/MM/YYYY"
            hideDelete
          />
        </div>
      </div>
    </fieldset>
  );
};

const { Forms2Field } = forms2PropTypes;

MatterDetails.propTypes = {
  matterId: PropTypes.string,
  // form state
  formInitialised: PropTypes.bool.isRequired,
  formDisabled: PropTypes.bool,
  submitFailed: PropTypes.bool,
  // form fields
  matterNumber: Forms2Field,
  description: Forms2Field,
  openedDate: Forms2Field,
  // other
  autoNumberDefinition: PropTypes.object,
  matterNumberExistsWarning: PropTypes.string,
  // callbacks
  onFieldValuesUpdated: PropTypes.func.isRequired, // generic method for updating fields
};

MatterDetails.defaultProps = {
  matterId: undefined,
  // form state
  formDisabled: undefined,
  submitFailed: undefined,
  // form fields
  matterNumber: undefined,
  description: undefined,
  openedDate: undefined,
  // other
  autoNumberDefinition: undefined,
  matterNumberExistsWarning: undefined,
};

MatterDetails.displayName = 'MatterDetails';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getLogger } from '@sb-itops/fe-logger';
import { store, RESET_ALL_REDUX_DATA, flushPersistedStateP } from '@sb-itops/redux';
import { withReduxStore, withOnLoad } from '@sb-itops/react';
import { error as displayErrorToUser } from '@sb-itops/message-display';
import { getSubscriptionUpdatePaymentMethodUrl, getForgotPasswordUrl } from '@sb-itops/environment-config';
import * as appState from 'web/redux/features/application-state';
import {
  getIsLoginInProgress,
  getIsChallengeResponseInProgress,
  getChallengeType,
  getPasswordPolicy,
  getAuthenticationFailure,
  getOwningCompany,
  loginP,
  respondToChallengeP,
  setLoginFailed,
} from 'web/services/user-session-management';
import { LoginRoute } from './LoginRoute';

const log = getLogger('LoginRoute');

const getIsPrivateComputer = () => appState.selectors.getIsPrivateComputer(store.getState());
const setIsPrivateComputer = ({ isPrivateComputer }) =>
  store.dispatch(appState.actions.setPrivateComputer({ isPrivateComputer }));
const getLastLoggedInUser = () => appState.selectors.getLastLoggedInUser(store.getState());
const setLastLoggedInUser = ({ username }) => store.dispatch(appState.actions.setLastLoggedInUser({ username }));
const getIsUserDataClearing = () => appState.selectors.getIsUserDataClearing(store.getState());
const setUserDataClearing = ({ isClearing }) => store.dispatch(appState.actions.setUserDataClearing({ isClearing }));
const getLoginFailureMessage = () => appState.selectors.getLoginFailureMessage(store.getState());
const setLoginFailureMessage = (message) => store.dispatch(appState.actions.setLoginFailureMessage(message));
const getAuthorisationFailureType = () => appState.selectors.getAuthorisationFailureType(store.getState());
const getLoginBlockedReason = () => appState.selectors.getLoginBlockedReason(store.getState());
const setLoginBlockedReason = ({ reason }) => store.dispatch(appState.actions.setLoginBlockedReason({ reason }));
const getLoginFormSubheader = () => appState.selectors.getLoginFormSubheader(store.getState());
const setLoginFormSubheader = ({ subheader }) => store.dispatch(appState.actions.setLoginFormSubheader({ subheader }));

const { authorisationFailures, loginBlockedReasons } = appState.types;

const authFailureMessageLookup = {
  [authorisationFailures.UNKNOWN_FAILURE]: { message: 'Unknown error' },
  [authorisationFailures.NO_ACCESS]: {
    message:
      'Your user account does not have access to view Firm Billing Information. Please check with your Firm Administrator to gain access.',
    isVerbose: true,
  },
};

const getBranding = ({ owningCompany }) => {
  if (owningCompany === 'TriConvey') {
    return 'TriConvey';
  }

  // In case we are hitting this container after a logout, the owning company will be blank,
  // so we need to revert to determining it via querystring.
  const qs = new URLSearchParams(window.location.search.replace('/', ''));
  const isTriConveyBranding = qs.get('owner') && qs.get('owner').toLowerCase() === 'triconvey';
  if (isTriConveyBranding) {
    return 'TriConvey';
  }

  return 'Smokeball';
};

const mapStateToProps = (state, { onRedirect }) => ({
  username: getLastLoggedInUser(),
  isPrivateComputer: getIsPrivateComputer(),
  loginInProgress: getIsLoginInProgress(),
  forgotPasswordUrl: getForgotPasswordUrl(),
  showUpdatePaymentMethod: getAuthenticationFailure().failureType === 'MISSING_PRODUCT_CLAIM',
  challengeType: getChallengeType(),
  passwordPolicy: getPasswordPolicy(),
  challengeResponseInProgress: getIsChallengeResponseInProgress(),
  isUserDataClearing: getIsUserDataClearing(),
  branding: getBranding({ owningCompany: getOwningCompany() }),
  errorMessages: getLoginFailureMessage() ? [getLoginFailureMessage()] : [],
  onChallengeAnswered: (solution) => onChallengeAnsweredP({ solution, onRedirect }),
  onChallengeCancelled: () => setLoginFailed(),
  onUpdatePaymentMethod: () => updatePaymentMethod(),
  twoFactorXeroLoginBlock: getLoginBlockedReason() === loginBlockedReasons.NO_2FA_XERO_USER,
  loginFormSubheader: getLoginFormSubheader(),
});

const mapDispatchToProps = (dispatch, { onRedirect, clearAllCachesP }) => ({
  onLoad: () => {
    // Check if the login page was navigated to as a result of authorisation failure and set state accordingly.
    setLoginFailureMessage(authFailureMessageLookup[getAuthorisationFailureType()]);
  },
  onSwitchUser: () => dispatch(switchUsers({ clearAllCachesP })),
  onLogin: (loginData) => dispatch(performLogin({ ...loginData, onRedirect })),
  onEnableTwoFactorClick: () => {
    setLoginFormSubheader({ subheader: 'Once you complete your 2FA configuration, login below:' });
    setLoginBlockedReason({ reason: undefined }); // reset login block reason which displays login form
  },
});

export const LoginRouteContainer = withReduxStore(connect(mapStateToProps, mapDispatchToProps)(withOnLoad(LoginRoute)));

const switchUsers =
  ({ clearAllCachesP }) =>
  async (dispatch, getState) => {
    try {
      // If this is the first user switch, we need to nuke any data still persisted from the
      // previous user, as the previous user may have chosen private computer. Otherwise,
      // we don't need to do any work here.
      const hasPreviouslyLoggedInUser = !!appState.selectors.getLastLoggedInUser(getState());
      if (!hasPreviouslyLoggedInUser) {
        return;
      }

      // Clear the data in generic cache.
      log.info(`Clearing cached data for previous user`);
      setUserDataClearing({ isClearing: true });
      await clearAllCachesP({ clearPersistentStorage: true });
      await store.dispatch({ type: RESET_ALL_REDUX_DATA, payload: {} });
      await flushPersistedStateP();
      setUserDataClearing({ isClearing: false });

      // Clear the private computer and last logged in user state.
      setIsPrivateComputer({ isPrivateComputer: false });
      setLastLoggedInUser({ username: undefined });
    } catch (err) {
      // This situation could have major consequences, so we won't let the user login if this occurs.
      // The main consequence is that firms sharing a PC might see each-other's data.
      // The secondary consequence is that user's might create entities with an incorrect user.
      log.error('Failed to clear cache data for previous user', err);
      displayErrorToUser(
        'Failed to switch user. Please clear the site data manually or contact Smokeball support for further assistance.',
      );
    }
  };

const performLogin =
  ({ username, password, isPrivateComputer, onRedirect }) =>
  async () => {
    // Perform the login to authenticate user credentials.
    try {
      setLoginFailureMessage();
      await loginP({ username, password });
      setLastLoggedInUser({ username });
      setIsPrivateComputer({ isPrivateComputer });

      // If the login has not been challenged, redirect to post authentication.
      if (!getChallengeType()) {
        onRedirect('post-authentication');
      }

      // Otherwise the redux state updating to indicate a challenge will cause the route to display the
      // relevant component for answering the issued challenge.
    } catch (err) {
      log.warn('Failed to login', err);

      const authenticationFailure = getAuthenticationFailure();
      if (authenticationFailure.failureType === 'MISSING_PRODUCT_CLAIM') {
        setLoginFailureMessage({
          message: `To continue using your Smokeball account, please select "Update Payment Details" below.`,
          isVerbose: true,
        });

        return;
      }

      setLoginFailureMessage({ message: 'Wrong email and/or password combination.' });
    }
  };

const onChallengeAnsweredP = async ({ solution, onRedirect }) => {
  try {
    setLoginFailureMessage();
    await respondToChallengeP({ solution });
    onRedirect('post-authentication');
  } catch (err) {
    log.warn('Failed to answer login challenge', err);

    // For a code mismatch exception, we can just set a message asking the user to try again.
    if (err.type === 'CodeMismatchException') {
      setLoginFailureMessage({ message: 'Incorrect token provided. Please try again.' });
      return;
    }

    // Otherwise, the user needs to go back to login and start over.
    setLoginFailureMessage({ message: 'Login has expired. Please try again.' });
    setLoginFailed();
  }
};

const updatePaymentMethod = () => {
  const authenticationFailure = getAuthenticationFailure();
  if (authenticationFailure.failureType !== 'MISSING_PRODUCT_CLAIM') {
    throw new Error(
      `Updating payment method from login page only supported after authentication fails with 'MISSING_PRODUCT_CLAIM'`,
    );
  }

  const updatePaymentMethodUrl = getSubscriptionUpdatePaymentMethodUrl({ accountId: authenticationFailure.accountId });
  window.open(updatePaymentMethodUrl, '_blank').focus();
};

LoginRouteContainer.displayName = 'LoginRouteContainer';

LoginRouteContainer.propTypes = {
  onRedirect: PropTypes.func.isRequired,
  clearAllCachesP: PropTypes.func.isRequired,
};

LoginRouteContainer.defaultProps = {};

import { featureActive } from '@sb-itops/feature';
import { constants as invoiceSettingsConstants } from '@sb-billing/business-logic/invoice-settings';

angular.module('sb.billing.webapp').service('sbInvoiceSettingsSampleDataService', function(sbEnvironmentConfigService, sbRegionType, sbGstTaxSettingsService) {
  const that = this;
  const region = sbEnvironmentConfigService.getRegion();
  const { AU, US, GB } = sbRegionType;

  that.getData = getData;

  const INVOICE_ID = '11111111-aaaa-aaaa-1111-111111111111';
  const MATTER_ID = '11111111-aaaa-aaaa-1111-111111111111';
  const DEBTOR = buildDummyContact();
  const PAYOR = {
    id: '11111111-aaaa-aaaa-9999-111111111111',
    entityName: 'Smith & Smith'
  };
  const STAFF_MEMBERS = [{
    personId: '11111111-aaaa-aaaa-1111-111111111111',
    userId: '11111111-cccc-cccc-1111-111111111111',
    initials: 'FA',
    name: 'Staff Member 1',
  }, {
    personId: '11111111-aaaa-aaaa-2222-111111111111',
    userId: '11111111-cccc-cccc-2222-111111111111',
    initials: 'FB',
    name: 'Staff Member 2',
  }, {
    personId: '11111111-aaaa-aaaa-3333-111111111111',
    userId: '11111111-cccc-cccc-3333-111111111111',
    initials: 'EA',
    name: 'Staff Member 3',
  }, {
    personId: '11111111-aaaa-aaaa-4444-111111111111',
    userId: '11111111-cccc-cccc-4444-111111111111',
    initials: 'EB',
    name: 'Staff Member 4',
  }];

  /**
   * The return of this function will need to eventually pass `sb-billing/monorepo/billing/modules/@sb-billing/invoice-types/app/validateEntityData.js`,
   * so make sure any changes keep both places up to date
   * */ 
  function getData (model) {
    const invoice = buildDummyInvoiceVersion(model);
    const payments = buildDummyPayments(invoice);

    return {
      expenses: buildDummyExpenses(),
      fees: buildDummyFees(model.defaultLayout),
      gstTaxSettings: buildDummyGstTaxSettings(model.gstTaxSettings),
      invoice,
      invoiceSettings: buildDummyInvoiceSettings(model),
      invoiceTotals: buildDummyInvoiceTotals(invoice),
      payments,
      priorBalances: buildDummyPriorBalances(),
      statement: buildDummyReportsData(model.invoiceAdditionalOptions),
      firmDetails: {
        abn: 'XXXXXXXXX',
        acn: 'XXXXXXXXX',
      },
      region,
      operatingAccount: model.operatingAccount,
    };
  }

  function buildDummyClient () {
    return {
      name: DEBTOR.entityName,
      address: DEBTOR.person.mailingAddress,
      salutation: DEBTOR.entityName,
    };
  }

  // only thing used here is the interest - in non-Original invoice invoices
  function buildDummyInvoiceTotals (invoice) {
    return {
      invoiceId:  INVOICE_ID,
      accountId:  '11111111-aaaa-aaaa-1111-111111111111',
      debtorId:   DEBTOR.id,
      matterId:   MATTER_ID,
      billed:     0,
      paid:       0,
      unpaid:     0,
      writtenOff: 0,
      waived:     0,
      discount:   0,
      total:      0,
      tax:        0,
      interest:   invoice.isOriginalInvoice || region !== US ? 0 : 10000,
      unpaidExcInterest: 0,
      lastUpdated: '123',
    };
  }

  function buildDummyPriorBalances () {
    return {
      reportDataFound: true,
      payments: [], // TODO
      paymentsSinceLastInvoice: [
        {
          // represents a payment on a previous invoice, after the "previous" invoice was finalised
          amount: 1000,
          paymentId: '11111111-aaaa-aaaa-1111-111111111111',
          sourceAccountId: '11111111-aaaa-aaaa-1111-111111111111',
          matterId: MATTER_ID,
          note: 'Payment since last invoice',
          payorId: PAYOR.id,
          reversed: false,
          source: 'Check',
          timestamp: '2017-01-16T12:00:00.0000000Z',
        },
      ],
      creditsSinceLastInvoice: [{
        // represents a payment on a previous invoice, after the "previous" invoice was finalised
        amount: 10000,
        paymentId: '11111111-aaaa-aaaa-1111-111111111111',
        sourceAccountId: '11111111-aaaa-aaaa-1111-111111111111',
        matterId: MATTER_ID,
        note: 'Payment since last invoice',
        payorId: PAYOR.id,
        reversed: false,
        source: 'Credit',
        timestamp: '2017-01-16T12:00:00.0000000Z',
      },],
      priorInvoices: [{
        issuedDate: 20170119,
        invoiceNumber: 1,
        dueDate: 20170128,
        totalExcInterest: 40000,
        paid: 2500,
        unpaid: region === US ? 42500 : 37500,
        interest: region === US ? 5000 : 0,
      }, {
        issuedDate: 20170219,
        invoiceNumber: 2,
        dueDate: 20170228,
        totalExcInterest: 40000,
        paid: 5000,
        unpaid: region === US ? 40000 : 35000,
        interest: region === US ? 5000 : 0,
      }],
      quickPayments: [
        {
          // represents a quickPayment on the invoice
          amount: 10000,
          paymentId: '11111111-aaaa-aaaa-1111-111111111111',
          sourceAccountId: '11111111-aaaa-aaaa-1111-111111111111/Trust',
          matterId: MATTER_ID,
          note: 'Payment for this invoice from trust',
          payorId: PAYOR.id,
          reversed: false,
          source: '',
          timestamp: '2017-02-01T12:00:00.0000000Z',
          isQuickPayment: true,
        },
      ],
      totals: {
        interest: region === US ? 10000 : 0,
        totalExcInterest: 80000,
        unpaidExcInterest: 72500,
        unpaidInterest: region === US ? 10000 : 0,
        paid: 7500,
        unpaid: region === US ? 82500 : 72500,
        paymentsSinceLastInvoice: 1000,
        creditsSinceLastInvoice: 10000,
        totalMinusPriors: 73500,
      },
    };
  }

  function buildDummyGstTaxSettings (model) {
    if(!model) {
      return;
    }

    return {
      taxRate: model.taxRate,
      registeredForGst: !!model.registeredForGst,
    };
  }

  function buildDummyInvoiceSettings (model) {
    const settings = {
      accountId:     '11111111-aaaa-aaaa-1111-111111111111',
      letterhead:     model.letterhead,
      headingFont:    model.headingFont || 'Lato',
      bodyFont:       model.bodyFont || 'Lato',
      paymentDueDays: 7,
      footer:         model.footer || 'End of the invoice.',
      defaultLayout: {
        expenseLineItemConfiguration:  0,
        feeLineItemConfiguration:      0,
        showStaffNameOnEntries:        false,
        feeSummaryLineDescription:     null,
        expenseSummaryLineDescription: null,
        showNonBillableFees:           !!model.defaultLayout.includeNonBillableItems,
        showNonBillableExpenses:       !!model.defaultLayout.includeNonBillableItems,
        showDescriptionForEntries:     false,
      },
      invoiceAdditionalOptions: {
        showInvoiceSummary:     false,
        showAccountSummary:     false,
        showTransactionHistory: false,
        hidePriorBalance:       false,
        hidePaymentSummary:     false,
        showHoursSummary:       false,
        hideFeeTotal:           false,
        hideFeeTotalSummary:    false,
        hideDueDate:            false,
        showLessFundsInTrust:   false,
        hideAmountAndTaxPerLineItem: false,
        showTimekeeperRole: false,
        appendExpenseReceipts: false,
        showItemNumbers: false,
      },
      sectionOptions: {
        feeSummary: {
          showTimekeeperRole: false,
          showSection: true,
          lineBreakBefore: true,
        },
        priorBalances: {
          showSection: true,
          lineBreakBefore: false,
        },
        paymentSummary: {
          showSection: false,
          lineBreakBefore: false,
        },
        invoiceSummary: {
          showSection: true,
          lineBreakBefore: true,
        },
        accountSummary: {
          showSection: true,
          lineBreakBefore: false,
        },
        transactionHistory: {
          showSection: false,
          lineBreakBefore: false,
        },
      },
      eInvoiceOptions: featureActive('BB-5725') && featureActive('BB-6865')
        ? { enableDescriptionOnDemand: true, descriptionOnDemandLineItemText: '' }
        : undefined,
      versionId: '11111111-aaaa-aaaa-1111-111111111111',
      versionIds: [],
      lastUpdated: '2017-04-21T05:55:38.9170000Z',
      initialInvoiceNumber: 0,
      firmNameFont:     model.firmNameFont || 'Lato',
      firmNameFontSize: model.firmNameFontSize || '8px',
      letterHeadLayout: model.letterHeadLayout,
      pageMargins: model.pageMargins,
      gridStyle:   model.gridStyle,
      overdueText: model.overdueText,
      hasLogo: model.hasLogo,
      signatureName: model.signatureName
    };

    return settings;
  }

  function buildDummyContact () {
    const mailingAddresses = {
      [US]: {
        addressLine1: '200 W Adams Street',
        addressLine2: 'Suite 2700',
        city: 'Chicago',
        state: 'IL',
        zipCode: '60606'
      },
      [AU]: {
        addressLine1: 'Level 8, 20 Bridge Street',
        addressLine2: 'Sydney NSW 2000'
      },
      [GB]: {
        addressLine1: '27 Old Gloucester Street',
        city: 'London',
        zipCode: 'WC1N 3AX',
      },
    };

    return {
      id: '11111111-aaaa-aaaa-1111-111111111111',
      entityName: 'Smokeball',
      person: {
        letterNameFormat: 0,
        gender: 0,
        mailingAddress: mailingAddresses[region]
      }
    };
  }

  function getTitleLine1 ({titleLine1Option, sampleMatterDescription, sampleMatterTitle, titleLine1CustomText}) {
    const invoiceTitleType = {...invoiceSettingsConstants.titleLine1Type};

    switch (titleLine1Option) {
      case invoiceTitleType.None:
        return '';
  
      case invoiceTitleType.MatterDescription: 
        return sampleMatterDescription;

      case invoiceTitleType.MatterTitle:
        return sampleMatterTitle;

      case invoiceTitleType.Custom:
        return titleLine1CustomText;

      default:
        return sampleMatterTitle;
    }
  }

  function getTitleLine2 ({titleLine2Option, sampleMatterTitle, sampleMatterDescription, titleLine2CustomText}) {
    const invoiceTitleType = {...invoiceSettingsConstants.titleLine2Type};

    switch (titleLine2Option) {
      case invoiceTitleType.None:
        return '';
        
      case invoiceTitleType.MatterDescription: 
        return sampleMatterDescription;

      case invoiceTitleType.MatterTitle:
        return sampleMatterTitle;

      case invoiceTitleType.Custom:
        return titleLine2CustomText;

      default:
        return '';
    }
  }

  function buildDummyInvoiceVersion (model) {
    // preview will use clients defined here to render address block
    const client = buildDummyClient();
    const debtor = buildDummyDebtor();

    return {
      additionalOptions: buildDummyInvoiceAdditionalOptions(model),
      sectionOptions: featureActive('BB-12385') ? model.sectionOptions : undefined,
      eInvoiceOptions: featureActive('BB-5725') && featureActive('BB-6865')
        ? { enableDescriptionOnDemand: false, descriptionOnDemandLineItemText: '' }
        : undefined,
      // clients: [client, client, client, client, client, client], // uncomment to test long address blocks
      clients: [client],
      debtors: [debtor],
      discount: {
        fixedDiscount: 1000,
        percentage: 0,
        type: 0
      },
      dueDate: +moment(moment().utc().add(model.paymentDueDays, 'day')).format('YYYYMMDD'),
      entries: buildDummyInvoiceEntries(),
      feeTaxRate: sbGstTaxSettingsService.getTaxRate(),
      invoiceId: INVOICE_ID,
      invoiceNumber: model.initialInvoiceNumber,
      invoiceTitle: getTitleLine1(model),
      invoiceTitleLine2: getTitleLine2(model),
      isOriginalInvoice: featureActive('BB-1761'),
      issuedDate: +moment(moment().utc()).format('YYYYMMDD'),
      layout: buildDummyInvoicelayout(model),
      matterId: MATTER_ID,
      paidDate: 0,
      retainerRequestAmount: 200000,
      status: 0, // show as DRAFT
      validFrom: '2017-02-01T00:00:00.0000000Z',
      versionId: '11111111-aaaa-aaaa-1111-111111111111',
      templateId: model.templateId,
      footer: model.footer,
      summary: '',
    }
  }

  // Invoice entry id's here must map to fee or expense ids in the root dictionary fee & expense lists
  function buildDummyInvoiceEntries () {
    return [
      {
        type: 1, // Time base
        id: '11111111-aaaa-aaaa-1111-111111111111' // must match feeId in fees array
      },
      {
        type: 1, // Time base
        id: '11111111-aaaa-aaaa-2222-111111111111' // must match feeId in fees array
      },
      {
        type: 2, // expense
        id: '11111111-aaaa-aaaa-3333-111111111111' // must match expenseId in expenses array
      },
      {
        type: 2, // expense
        id: '11111111-aaaa-aaaa-4444-111111111111' // must match expenseId in expenses array
      }
    ];
  }

  function buildDummyDebtor () {
    return { id: DEBTOR.id, ratio: 1 };
  }

  function buildDummyInvoicelayout ({defaultLayout: model}) {
    const layout =  {
      expenseLineItemConfiguration:  model.expenseLineItemConfiguration,
      feeLineItemConfiguration:      model.feeLineItemConfiguration,
      showStaffNameOnEntries:        model.showStaffNameOnEntries,
      feeSummaryLineDescription:     model.feeSummaryLineDescription || 'Fees summary...',
      expenseSummaryLineDescription: model.expenseSummaryLineDescription || 'Expenses summary...',
      showNonBillableFees:         !!model.includeNonBillableItems,
      showNonBillableExpenses:     !!model.includeNonBillableItems,
      showDescriptionForEntries:     model.showDescriptionForEntries,
      showRateOnEntries:             model.showRateOnEntries,
      showDurationOnEntries:         model.showDurationOnEntries,
    };

    return layout;
  }

  function buildDummyInvoiceAdditionalOptions ({ invoiceAdditionalOptions: model}) {
    return {
      showInvoiceSummary:           model.showInvoiceSummary,
      showAccountSummary:           model.showAccountSummary,
      showRetainer:                 model.showRetainer,
      showTransactionHistory:       model.showTransactionHistory,
      hidePriorBalance:             model.hidePriorBalance,
      hidePaymentSummary:           model.hidePaymentSummary,
      showSummaryForTimekeepers:    model.showSummaryForTimekeepers,
      showTimekeeperRole:           model.showTimekeeperRole,
      showHoursSummary:             model.showHoursSummary,
      hideFeeTotal:                 model.hideFeeTotal,
      hideFeeTotalSummary:          model.hideFeeTotalSummary,
      hideDueDate:                  model.hideDueDate,
      showLessFundsInTrust:         model.showLessFundsInTrust,
      hideAmountAndTaxPerLineItem:  model.hideAmountAndTaxPerLineItem,
      appendExpenseReceipts:        model.appendExpenseReceipts,
      showItemNumbers:              featureActive('BB-12394') ? model.showItemNumbers : undefined,
    };
  }

  function buildDummyExpenses () {
    return [
      {
        expenseId: '11111111-aaaa-aaaa-3333-111111111111',
        expenseVersionId: '11111111-aaaa-aaaa-1111-111111111111',
        matterId: MATTER_ID,
        createdByUserId: STAFF_MEMBERS[0].userId,
        expenseActivityId: 'ACT1',
        isBillable: true,
        description: 'Expense Subject',
        notes: 'Expense description Line 1\nExpense description Line 2',
        quantity: 70,
        price: 50000,
        expenseDate: 20170330,
        expenseEarnerStaffId: STAFF_MEMBERS[2].personId, 
        expenseEarner: STAFF_MEMBERS[2], 
        waived: false,
        validFrom: '2017-04-21T05:55:38.9170000Z',
        source: 0
      },
      {
        expenseId: '11111111-aaaa-aaaa-4444-111111111111',
        expenseVersionId: '11111111-aaaa-aaaa-1111-111111111111',
        matterId: MATTER_ID,
        createdByUserId: STAFF_MEMBERS[0].userId,
        expenseActivityId: 'ACT1',
        isBillable: true,
        description: 'Expense Subject',
        notes: 'Expense description Line 1\nExpense description Line 2',
        quantity: 60,
        price: 40000,
        expenseDate: 20170329,
        expenseEarnerStaffId: STAFF_MEMBERS[3].personId,
        expenseEarner: STAFF_MEMBERS[3],
        waived: false,
        validFrom: '2017-04-21T05:55:38.9170000Z',
        source: 0
      }
    ];
  }

  function buildDummyFees ({includeNonBillableItems}) {
    const fees = [
      {
        feeId: '11111111-aaaa-aaaa-1111-111111111111',
        feeVersionId: '11111111-aaaa-aaaa-1111-111111111111',
        matterId: MATTER_ID,
        createdByUserId: STAFF_MEMBERS[0].userId,
        feeActivityId: 'ACT1',
        isBillable: true,
        description: 'Fee Subject',
        notes: 'Fee description Line 1\nFee description Line 2',
        duration: 30,
        rate: 35000,
        feeType: 1,
        feeDate: 20170330,
        feeEarnerStaffId: STAFF_MEMBERS[0].personId,
        feeEarner: STAFF_MEMBERS,
        waived: false,
        validFrom: '2017-04-21T05:55:38.9170000Z',
        amount: 17500,
        tax: 1750,
        // source: 0
      },
      { // billable time-entry
        feeId: '11111111-aaaa-aaaa-2222-111111111111',
        feeVersionId: '11111111-aaaa-aaaa-2222-111111111111',
        matterId: MATTER_ID,
        createdByUserId: STAFF_MEMBERS[0].userId,
        feeActivityId: 'ACT1',
        isBillable: true,
        description: 'Fee Subject',
        notes: 'Fee description Line 1\nFee description Line 2',
        duration: 30,
        rate: 30000,
        feeType: 1,
        feeDate: 20170330,
        feeEarnerStaffId: STAFF_MEMBERS[1].personId,
        feeEarner: STAFF_MEMBERS,
        waived: false,
        validFrom: '2017-04-21T05:55:38.9170000Z',
        amount: 15000,
        tax: 1500,
        // source: 0
      },
      { // billable time-entry
        feeId: '11111111-aaaa-aaaa-2222-111111111111',
        feeVersionId: '11111111-aaaa-aaaa-2222-111111111111',
        matterId: MATTER_ID,
        createdByUserId: STAFF_MEMBERS[0].userId,
        feeActivityId: 'ACT1',
        isBillable: true,
        description: 'Fee Subject',
        notes: 'Fee description Line 1\nFee description Line 2',
        duration: 30,
        rate: 30000,
        feeType: 1,
        feeDate: 20170330,
        feeEarnerStaffId: STAFF_MEMBERS[1].personId,
        feeEarner: STAFF_MEMBERS,
        waived: false,
        validFrom: '2017-04-21T05:55:38.9170000Z',
        amount: 15000,
        tax: 1500
        // source: 0
      },
      {  // billable fixed-fee
        feeId: '11111111-aaaa-aaaa-2222-111111111111',
        feeVersionId: '11111111-aaaa-aaaa-2222-111111111111',
        matterId: MATTER_ID,
        createdByUserId: STAFF_MEMBERS[0].userId,
        feeActivityId: 'ACT1',
        isBillable: true,
        description: 'Fee Subject',
        notes: 'Fee description Line 1\nFee description Line 2',
        duration: 0,
        rate: 10000,
        feeType: 0,
        feeDate: 20170330,
        feeEarnerStaffId: STAFF_MEMBERS[1].personId,
        feeEarner: STAFF_MEMBERS,
        waived: true,
        validFrom: '2017-04-21T05:55:38.9170000Z',
        amount: 10000,
        tax: 1000,
        // source: 0
      },
      {
        feeId: '11111111-aaaa-aaaa-2222-111111111111',
        feeVersionId: '11111111-aaaa-aaaa-2222-111111111111',
        matterId: MATTER_ID,
        createdByUserId: STAFF_MEMBERS[0].userId,
        feeActivityId: 'ACT1',
        isBillable: true,
        description: 'Fee Subject',
        notes: 'Fee description Line 1\nFee description Line 2',
        duration: 0,
        rate: 10000,
        amount: 10000,
        tax: 1000,
        feeType: 0,
        feeDate: 20170330,
        feeEarnerStaffId: STAFF_MEMBERS[1].personId,
        feeEarner: STAFF_MEMBERS,
        waived: true,
        validFrom: '2017-04-21T05:55:38.9170000Z',
        // source: 0
      },
      {
        feeId: '11111111-aaaa-aaaa-2222-111111111111',
        feeVersionId: '11111111-aaaa-aaaa-2222-111111111111',
        matterId: MATTER_ID,
        createdByUserId: STAFF_MEMBERS[0].userId,
        feeActivityId: 'ACT1',
        isBillable: true,
        description: 'Fee Subject',
        notes: 'Fee description Line 1\nFee description Line 2',
        duration: 0,
        rate: 10000,
        amount: 10000,
        tax: 1000,
        feeType: 0,
        feeDate: 20170330,
        feeEarnerStaffId: STAFF_MEMBERS[1].personId,
        feeEarner: STAFF_MEMBERS,
        waived: true,
        validFrom: '2017-04-21T05:55:38.9170000Z',
        // source: 0
      }
    ];

    if (includeNonBillableItems) {
      fees.push({ //non-billable time-entry
        feeId: '11111111-aaaa-aaaa-3333-111111111111',
        feeVersionId: '11111111-aaaa-aaaa-3333-111111111111',
        matterId: MATTER_ID,
        createdByUserId: STAFF_MEMBERS[0].userId,
        feeActivityId: 'UNB1',
        isBillable: false,
        description: 'Unbillable Fee Subject',
        notes: 'Fee description Line 1\nFee description Line 2',
        duration: 30,
        rate: 30000,
        amount: 15000,
        tax: 1500,
        feeType: 1,
        feeDate: 20170330,
        feeEarnerStaffId: STAFF_MEMBERS[1].personId,
        feeEarner: STAFF_MEMBERS,
        waived: false,
        validFrom: '2017-04-21T05:55:38.9170000Z',
        // source: 0
      });
    }

    return fees;
  }

  function buildDummyPayments () {
    return [
      {
        // represents a quickPayment on the invoice
        paymentId: '11111111-aaaa-aaaa-1111-111111111111',
        sourceAccountId: '11111111-aaaa-aaaa-1111-111111111111/Trust',
        sourceAccountBalanceType: 0,
        destinationAccountId: '11111111-aaaa-aaaa-1111-111111111111',
        accountId: '11111111-aaaa-aaaa-1111-111111111111',
        matterId: MATTER_ID,
        userId: STAFF_MEMBERS[0].userId,
        effectiveDate: 20170205,
        note: 'First payment',
        payorId: PAYOR.id,
        source: '',
        timestamp: '2017-02-01T12:00:00.0000000Z',
        invoices: [
          {
            invoiceId: INVOICE_ID,
            amount: 10000
          }
        ],
        sourceAccount: {
          accountType: 'Credit',
        },
        isQuickPayment: true,
      },
      {
        // represents a payment on a previous invoice, before the "previous" invoice was finalised
        paymentId: '11111111-aaaa-aaaa-1111-111111111111',
        sourceAccountId: '11111111-aaaa-aaaa-1111-111111111111',
        sourceAccountBalanceType: 0,
        destinationAccountId: '11111111-aaaa-aaaa-1111-111111111111',
        accountId: '11111111-aaaa-aaaa-1111-111111111111',
        matterId: MATTER_ID,
        userId: STAFF_MEMBERS[0].userId,
        effectiveDate: 20170101,
        note: 'Second payment',
        payorId: PAYOR.id,
        source: 'Check',
        timestamp: '2017-01-01T12:00:00.0000000Z',
        invoices: [
          {
            invoiceId: '11111111-aaaa-aaaa-9999-333333333333', // an invoiceId that is NOT the invoice
            amount: 20000
          }
        ]
      },
      {
        // quick payment on a previous invoice
        paymentId: '11111111-aaaa-aaaa-2222-111111111111',
        sourceAccountId: '11111111-aaaa-aaaa-1111-111111111111',
        sourceAccountBalanceType: 0,
        destinationAccountId: '11111111-aaaa-aaaa-1111-111111111111',
        accountId: '11111111-aaaa-aaaa-1111-111111111111',
        matterId: MATTER_ID,
        userId: STAFF_MEMBERS[0].userId,
        effectiveDate: 20170101,
        note: 'Third payment',
        payorId: PAYOR.id,
        source: 'Check',
        timestamp: '2017-01-01T12:00:00.0000000Z',
        invoices: [
          {
            invoiceId: '11111111-aaaa-aaaa-9999-333333333333', // an invoiceId that is NOT the invoice
            amount: 30000
          }
        ],
        sourceAccount: {
          accountType: 'Trust',
        },
        isQuickPayment: true,
      },
      {
        // represents a payment on a previous invoice, after the "previous" invoice was finalised
        paymentId: '11111111-aaaa-aaaa-1111-111111111111',
        sourceAccountId: '11111111-aaaa-aaaa-1111-111111111111',
        sourceAccountBalanceType: 0,
        destinationAccountId: '11111111-aaaa-aaaa-1111-111111111111',
        accountId: '11111111-aaaa-aaaa-1111-111111111111',
        matterId: MATTER_ID,
        userId: STAFF_MEMBERS[0].userId,
        effectiveDate: 20170101,
        note: 'Second payment',
        payorId: PAYOR.id,
        source: 'Check',
        timestamp: '2017-01-16T12:00:00.0000000Z',
        invoices: [
          {
            invoiceId: '11111111-aaaa-aaaa-9999-333333333333', // an invoiceId that is NOT the invoice
            amount: 20000
          }
        ]
      },
    ];
  }

  function buildDummyReportsData (model) {
    return {
      isStatementDataReady: true,
      showInvoiceSummary: model.showInvoiceSummary,
      matterContactRadio: 'matter',
      matterOrContactInfo: { id: MATTER_ID },
      invoiceHistory: {
        invoices: [
          {
            issuedDate: 20160411,
            invoiceNumber: '002993',
            matter: 'A123-Divorce',
            totalExcInterest: 300000,
            payments: 0,
            totalDue: 300000
          },
          {
            issuedDate: 20161212,
            invoiceNumber: '543223',
            matter: 'B33-Sell-Jones',
            totalExcInterest: 220000,
            payments: 220000,
            totalDue: 0
          },
          {
            issuedDate: 20170224,
            invoiceNumber: '123321',
            matter: 'A123-Divorce',
            totalExcInterest: 120000,
            payments: 100000,
            totalDue: 20000
          }
        ],
        totals: {
          total: 640000,
          payments: 320000,
          totalDue: 320000
        },
      },
      showAccountSummary: model.showAccountSummary,
      accountSummary: {
        accounts: region === US ? [
          {
            bankAccountDisplayText: 'Operating',
            credit: 300000,
            debit: 200000,
            totals: {
              balance: 100000,
            },
          },
          {
            bankAccountDisplayText: 'Trust',
            credit: 300000,
            debit: 300000,
            totals: {
              balance: 0,
            },
          }
        ]
        : [
          {
            bankAccountDisplayText: 'Trust',
            credit: 500000,
            debit: 200000,
            totals: {
              balance: 300000,
            },
          },
        ],
        totals: {
          balance: 100000
        }
      },
      showTransactionHistory: model.showTransactionHistory,
      txGroups: region === US ? [
        {
          bankAccountDisplayText: 'Operating',
          history: [
            {
              effectiveDate: 20161214,
              description: 'Money received',
              matter: '',
              credit: 100000,
              debit: 0,
              runningBalance: 100000
            },
            {
              effectiveDate: 20161216,
              description: 'Money received (from Trust)',
              matter: 'A123-Divorce',
              credit: 200000,
              debit: 0,
              runningBalance: '300000'
            },
            {
              effectiveDate: 20161218,
              description: 'Payment for invoice #1232',
              matter: 'A123-Divorce',
              credit: 0,
              debit: 200000,
              runningBalance: 100000
            },

          ],
          totals: {
            balance: 100000
          }
        },
        {
          bankAccountDisplayText: 'Trust',
          history: [
            {
              effectiveDate: 20161222,
              description: 'Money received',
              credit: 200000,
              debit: 0,
              runningBalance: 200000
            },
            {
              effectiveDate: 20161222,
              description: 'Money transferred (Operating account)',
              credit: 0,
              debit: 200000,
              runningBalance: 0
            }
          ],
          totals: {
            balance: 0
          }
        }
      ]
      : [
        {
          bankAccountDisplayText: 'Trust',
          history: [
            {
              effectiveDate: 20161222,
              description: 'Money received',
              credit: 500000,
              debit: 0,
              runningBalance: 500000,
            },
            {
              effectiveDate: 20161222,
              description: 'Money spent',
              credit: 0,
              debit: 200000,
              runningBalance: 300000,
            },
          ],
          totals: {
            balance: 300000,
          },
        },
      ],
    };
  }

});

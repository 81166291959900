import { Button, FloatingCard, useTranslation } from '@sb-itops/react';
import PropTypes from 'prop-types';
import * as React from 'react';
import { ConfirmationModalDialog } from '@sb-billing/react/confirmation-modal-dialog';
import { TrustAccountAddEditForm } from '../trust-account-add-edit-form';
import { TrustAccountCloseModal } from '../trust-account-close-modal';
import Styles from './TrustAccountAddEditCard.module.scss';

const TRUST_ACCOUNT_CLOSE_ACCOUNT_MODAL = 'trust-account-close-account-modal';

export const TrustAccountAddEditCard = React.memo(
  ({
    bankAccountId,
    canCloseAccount,
    formDisabled,
    formInitialised,
    formSubmitting,
    formValid,
    saveTrustAccountDetailsModalId,
    shouldShowConfirmation,
    showConfirmation,
    isBankAccountClosed,
    isValidStatutoryDepositMatterId,
    onCancel,
    onCloseCard,
    onCloseModal,
    onOpenAccount,
    onOpenModal,
    onSave,
    region,
    scope,
    showModal,
    isAuthorised,
  }) => {
    const { t } = useTranslation();
    const onSaveClick = shouldShowConfirmation && formValid ? showConfirmation : onSave;

    return (
      <div className={Styles.trustAccountAddEditCard}>
        <FloatingCard>
          <div className={Styles.container}>
            <h5 className={Styles.title}>
              {bankAccountId ? 'Edit' : 'Add'} {t('trustAccount')}
            </h5>
            <TrustAccountAddEditForm
              bankAccountId={bankAccountId}
              isBankAccountClosed={isBankAccountClosed}
              isValidStatutoryDepositMatterId={isValidStatutoryDepositMatterId}
              region={region}
              scope={scope}
              isAuthorised={isAuthorised}
            />
            {formInitialised && (
              <div className={Styles.formFooter}>
                <div>
                  <Button
                    locked={formDisabled}
                    disabled={formDisabled || !isAuthorised}
                    className={Styles.saveButton}
                    size="full-width"
                    onClick={isBankAccountClosed ? onOpenAccount : onSaveClick}
                  >
                    {isBankAccountClosed ? 'Reopen Account' : 'Save'}
                  </Button>
                  <Button
                    disabled={formDisabled}
                    className={Styles.cancelButton}
                    size="full-width"
                    type="secondary"
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                </div>
                <div>
                  {!!bankAccountId && !isBankAccountClosed && (
                    <Button
                      disabled={formDisabled || !canCloseAccount || !isAuthorised}
                      className={Styles.deleteButton}
                      size="full-width"
                      type="dangerAlt"
                      onClick={onOpenModal}
                    >
                      Close Account
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
        </FloatingCard>
        {bankAccountId && showModal && showModal === TRUST_ACCOUNT_CLOSE_ACCOUNT_MODAL && (
          <TrustAccountCloseModal bankAccountId={bankAccountId} onCloseCard={onCloseCard} onCloseModal={onCloseModal} />
        )}

        {shouldShowConfirmation && (
          <ConfirmationModalDialog
            onConfirm={onSave}
            isConfirming={formSubmitting}
            modalId={saveTrustAccountDetailsModalId}
            title="Save Account Details"
            body="Warning: once these details are saved they will not be able to be edited by anyone but the firm owner."
            primaryButtonText="Save"
          />
        )}
      </div>
    );
  },
);

TrustAccountAddEditCard.propTypes = {
  bankAccountId: PropTypes.string,
  canCloseAccount: PropTypes.bool.isRequired,
  region: PropTypes.string.isRequired,
  scope: PropTypes.string.isRequired,
  // form
  isBankAccountClosed: PropTypes.bool.isRequired,
  isAuthorised: PropTypes.bool.isRequired,
  saveTrustAccountDetailsModalId: PropTypes.string.isRequired,
  shouldShowConfirmation: PropTypes.bool,
  showConfirmation: PropTypes.func,
  isValidStatutoryDepositMatterId: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCloseCard: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onOpenAccount: PropTypes.func.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  formDisabled: PropTypes.bool.isRequired,
  formSubmitting: PropTypes.bool.isRequired,
  formInitialised: PropTypes.bool.isRequired,
  formValid: PropTypes.bool.isRequired,
  showModal: PropTypes.string.isRequired,
};

TrustAccountAddEditCard.defaultProps = {
  bankAccountId: undefined,
  shouldShowConfirmation: false,
  showConfirmation: () => {},
};

import validator from 'validator';
import uuid from '@sb-itops/uuid';

export function marshalRecon({
  startDate,
  endDate,
  openingLedgerBalance,
  bankStatementBalance,
  trustAccountId,
  ttoTransactions,
  adjustments,
  selectedDepositSlipIds,
  selectedTransactionIds,
  selectedTtoIds,
  existingDraftBankReconciliation,
}) {
  const reconciledDate = moment(new Date()).format('YYYYMMDD');
  const selectedTtoChildTxns = selectedTtoIds.reduce(
    (acc, ttoId) => acc.concat(ttoTransactions.find((tto) => tto.id === ttoId).transactionIds),
    [],
  );
  const transactionIds = [...selectedTransactionIds, ...selectedTtoChildTxns];
  const opdateId = uuid();
  return {
    startDate,
    endDate,
    bankStatementBalance,
    trustAccountId,
    bankAccountId: trustAccountId,
    reconciledDate,
    transactionIds,
    depositSlipIds: selectedDepositSlipIds.filter((id) => validator.isUUID(id)),
    adjustments: adjustments.filter((adj) => !adj.isDeleted),
    id: existingDraftBankReconciliation?.id || opdateId,
    reconciliationId: existingDraftBankReconciliation?.id || opdateId,
    opdateId: existingDraftBankReconciliation?.id || opdateId,
    // backend ignores it but it is needed in opdate otherwise the recon
    // summary panel "Opening Cash Book Balance" resets to 0 while saving
    openingLedgerBalance,
  };
}

import { store } from '@sb-itops/redux';
import { isString } from 'lodash';
import { fetchGetP, fetchPostP } from '@sb-itops/redux/fetch';
import uuid from '@sb-itops/uuid';
import * as messageDisplay from '@sb-itops/message-display';
import { capitalize } from '@sb-itops/nodash';
import { hasFacet, facets } from '@sb-itops/region-facets';
import {
    getOperatingChequePrintSettings,
    getDefaultOperatingChequePrintSettings,
    isOperatingChequesEnabled,
} from '@sb-billing/redux/operating-cheque-print-settings';
import {
  getById as getOperatingChequeById,
  getList as getOperatingChequeList,
  chequeExists,
} from '@sb-billing/redux/operating-cheques';
import { findLastChequeNumber as findLastOperatingChequeNumber, getNextChequeNumber } from 'web/services/cheques';
import { saveExpense } from '@sb-billing/redux/expenses/save-expense';
import { saveExpensePaymentDetailsForLockedExpense } from '@sb-billing/redux/expenses/save-expense-payment-details';

import { printMethodsByValue } from '@sb-billing/business-logic/cheques';
import { calculateTaxAmount, calculateOutputTaxAmount } from '@sb-billing/business-logic/expense/services';
import { dateToInteger } from '@sb-itops/date';
import { getContactTypeAheadSummaries } from 'web/redux/selectors/typeahead';
import { isEmpty } from 'lodash';
import { setModalDialogVisible } from '@sb-itops/redux/modal-dialog';
import { roundCents, roundDollars } from '@sb-billing/bankers-rounding';
import { getExpenseCustomTaskCodes } from '@sb-billing/redux/custom-task-code';
import { featureActive } from '@sb-itops/feature';
import { getRegion } from '@sb-itops/region';
import { PRINT_OPERATING_CHEQUE_MODAL_ID as LOD_PRINT_OPERATING_CHEQUE_MODAL_ID } from 'web/components';
import { getAccountId } from 'web/services/user-session-management';
import { getOperatingAccount } from '@sb-billing/redux/bank-account';
import { getById as getExpensePaymentDetailsById } from '@sb-billing/redux/expense-payment-details';
import { paymentMethodByName, paymentMethodLabel } from '@sb-billing/business-logic/expense-payment-details/entities/constants';
import { fileTypeSignature } from '@sb-itops/business-logic/files/entities/constants';
import { selectors as supportDebugSelectors } from 'web/redux/route/billing-support-debug';
import { getFileSignature, isValidByFileTypeSignatures } from '@sb-itops/business-logic/files/services';
import { dispatchCommand } from '@sb-integration/web-client-sdk';

const PRINT_OPERATING_CHEQUE_MODAL_ID = 'print-operating-cheque-modal';
const SUPPORTED_FILE_FORMATS = ['pdf', 'png', 'jpg', 'jpeg'];
const PRINT_MANUALLY = 'PrintManually';

const EXPENSE_TYPES = {
  DISBURSEMENT: 'disbursement',
  ANTICIPATED_DISBURSEMENT: 'anticipatedDisbursement'
}

const isDate = (date) => (Object.prototype.toString.call(date) === '[object Date]');

const getPaymentMethodOptions = ({ chequeEnabled, t }) => {
  const bankTransferOption = { label: paymentMethodLabel[paymentMethodByName.BANK_TRANSFER], value: paymentMethodByName.BANK_TRANSFER };
  const directDebitOption = { label: paymentMethodLabel[paymentMethodByName.DIRECT_DEBIT], value: paymentMethodByName.DIRECT_DEBIT };
  
  if (!chequeEnabled) {
    return [ bankTransferOption, directDebitOption ];
  }

  return [
    bankTransferOption,
    { label: `${capitalize(t("cheque"))}`, value: paymentMethodByName.CHEQUE },
    directDebitOption
  ];
}

angular.module('sb.billing.webapp').controller('SbExpenseEntryController', function ($scope, $timeout, sbUuidService, focusService, sbExpenseService,
  sbDateService, sbUnsavedChangesService, sbLoggerService, sbMattersMbService, sbFirmManagementMbService,
  sbBillingActivityService, hotkeys, sbGstTaxSettingsService, sbLocalisationService,
  sbUtbmsSettingsService, sbBillingConfigurationService, sbUtbmsActivityCodeService, sbUtbmsTaskCodeService,
  sbOperatingChequesService, sbAsyncOperationsService) {
  var that = this,
    log = sbLoggerService.getLogger('SbExpenseEntryController'),
    formDirty;

  const REGION = getRegion();
  const allowChequeNumberDuplication = hasFacet(facets.allowDuplicateCheque);
  const operatingBankAccountId = getOperatingAccount().id;

  //log.setLogLevel('info');
  that.t = sbLocalisationService.t;
  that.region = REGION;
  that.showPayToFieldForDisbursement = hasFacet(facets.showPayToFieldForDisbursement);
  that.showTaxRelatedFields = hasFacet(facets.tax);
  that.ctrlId = sbUuidService.get();
  that.editMode = $scope.features && $scope.features.update;
  that.useCommandDispatcher = $scope.features && $scope.features.useCommandDispatcher;
  that.onSuccess = $scope.features && $scope.features.onSuccess;
  that.checkLabel = capitalize(that.t("cheque"));
  that.isSubjectOverridable = !that.editMode;

  updateContactSummaries();

  that.view = {
    loaded: false,
    loggedInStaffMember: sbFirmManagementMbService.getLoggedInStaffMember(),
    staff: sbFirmManagementMbService.getAllStaffSummaries(),
    isOperatingChequesEnabled: isOperatingChequesEnabled(),
    showAddPayToContact: false,
    showAddSupplierContact: false,
    paymentMethodOptions: getPaymentMethodOptions({ chequeEnabled: isOperatingChequesEnabled(), t: sbLocalisationService.t }),
    // We need this for when payment method field is disabled as
    // you can have cheque linked to expense and then disable operating cheques in settings
    paymentMethodOptionsAll: getPaymentMethodOptions({ chequeEnabled: true, t: sbLocalisationService.t }),
    expenseTypes: EXPENSE_TYPES,
    showExpensePaidWarning: false,
    editingPaidExpense: false,
    disbursementLabel: that.t('capitalizeAllWords', { val: 'expense' }),
    anticipatedDisbursementLabel: `Anticipated ${that.t('capitalizeAllWords', { val: 'expense' })}`,
    showDebug: supportDebugSelectors.getShowDebug(store.getState()),
  };

  that.line = {
    amount: 0,
    isTaxOverridden: false,
    billable: true,
    activityCode: '',
    operatingChequePrintOptions: {},
    expensePaymentDetails: {},
    isAnticipated: false,
  }; // TODO: is this billable false for fixed-fee matters
  setDefaultExpensePaymentDetails();

  that.errors = {
    matter: false
  };
  that.warnings = {};
  that.sbData = {};
  that.focusOn = focusOn;
  that.isDataReady = isDataReady;
  that.updateAmountAndTax = updateAmountAndTax;
  that.initFields = initFields;
  that.prepField = prepField;
  that.submit = submit;
  that.delete = deleteExpense;
  that.onUpdateClick = onUpdateClick;
  that.unlockLine = unlockLine;
  that.onSelectMatter = onSelectMatter;
  that.onSelectStaff = onSelectStaff;
  that.onSelectExpenseType = onSelectExpenseType;
  that.onChangeTax = onChangeTax;
  that.onChangeOutputTax = onChangeOutputTax;
  that.isEditableMatter = isEditableMatter;
  that.dataChangeFunction = dataChangeFunction;
  that.onFocusCurrencyInput = onFocusCurrencyInput;
  that.onHasTaxChanged = onHasTaxChanged;
  that.onBillableChanged = onBillableChanged;
  that.onWaivedChanged = onWaivedChanged;
  that.onActivitySelect = onActivitySelect;
  that.onSubjectActivityChanged = onSubjectActivityChanged;
  that.onSubjectTextChanged = onSubjectTextChanged;
  that.onSubjectOverrideChange = onSubjectOverrideChange;
  that.onPaymentMethodChanged = onPaymentMethodChanged;
  that.onPayableToSupplierChanged = onPayableToSupplierChanged;
  that.onSupplierPaidChanged = onSupplierPaidChanged;
  that.showUtbmsFields = showUtbmsFields;
  that.enableTaskCodes = enableTaskCodes;
  that.showUtbmsActivities = showUtbmsActivities;
  that.showUtbmsDescription = showUtbmsDescription;
  that.markReceiptFileForDeletion = markReceiptFileForDeletion;
  that.showUpdateButtonToCreateCheque = showUpdateButtonToCreateCheque;

  that.createCheque = createCheque;
  that.hasOperatingCheque = hasOperatingCheque;
  that.isOperatingChequePrintManually = isOperatingChequePrintManually;
  that.isOperatingChequesEnabledAndPrintNow = isOperatingChequesEnabledAndPrintNow;
  that.onPrintMethodUpdated = onPrintMethodUpdated;
  that.onOperatingChequePayToUpdated = onOperatingChequePayToUpdated;
  that.onSupplierUpdated = onSupplierUpdated;
  that.toggleAddPayToContact = toggleAddPayToContact;
  that.toggleAddSupplierContact = toggleAddSupplierContact;
  that.onSavePayToContact = onSavePayToContact;
  that.onSaveSupplierContact = onSaveSupplierContact;
  that.isPaidDirectDebit = isPaidDirectDebit;
  that.isPaidBankTransfer = isPaidBankTransfer;
  that.isPaidCheque = isPaidCheque;
  that.isUpdateButtonDisabled = isUpdateButtonDisabled;
  that.focusKeyMatter = buildFocusKey('expense-matter-field');
  // anticipated disbursements
  that.supportsAD = () => featureActive("BB-9573");

  updateStaff();
  updateActivities();
  updateUtbmsActivityCodes();
  updateUtbmsTaskCodes();
  setDefaultOperatingChequeOptions();

  $scope.uploadedFiles = {};
  that.receiptDownloadUrl = undefined;
  that.isDeletingReceiptFile = false;

  $timeout(function () {
    updateView();
  });

  $timeout(function(){
    hotkeys.bindTo($scope).add({
      combo: 'ctrl+enter',
      description: 'save expense entry',
      allowIn: ['INPUT', 'TEXTAREA'],
      callback: submit
    });
  }, 0);

  if ($scope.date) {
    that.line.date = $scope.date;
  }

  if ($scope.preFill) {
    loadPrefill();
  } else {
    loadUnsavedChanges();
    setInitialFocus();
  }

  $timeout(handleLoadedOrTimeout, 10000);

  $scope.$on('$destroy', function () {
    if ($scope.features && $scope.features.storeUnsavedChanges && formDirty) {
      if ($scope.features.matterMode) {
        if (that.line.matter) {
          sbUnsavedChangesService.saveMemory('SbExpenseEntryController-' + that.line.matter, that.line);
        }
      } else {
        sbUnsavedChangesService.saveMemory('SbExpenseEntryController-general', that.line);
      }
    }
  });

  $scope.$watch('expenseQuickEntryForm', function (expenseQuickEntryForm) {
    if (expenseQuickEntryForm) {
      $scope.$watch('expenseQuickEntryForm.$dirty', function (dirty) {
        formDirty = dirty;
      });
    }
  });

  $scope.$watch('expenseLongEntryForm', function (expenseLongEntryForm) {
    if (expenseLongEntryForm) {
      $scope.$watch('expenseLongEntryForm.$dirty', function (dirty) {
        formDirty = dirty;
      });
    }
  });

  $scope.$watch('expenseEntry.line.date', function (newVal, oldVal) {
    if (newVal !== oldVal && !angular.equals(that.line.date, $scope.date)) {
      $scope.date = that.line.date;
    }
  });

  $scope.$watch('date', function (newVal, oldVal) {
    if (newVal !== oldVal && !angular.equals(that.line.date, $scope.date)) {
      that.line.date = $scope.date;
    }
  });

  $scope.$watch('expenseEntry.line.staff', function (newVal, oldVal) {
    if (newVal !== oldVal && !angular.equals(that.line.staff, $scope.staff)) {
      log.info('staff changed');
      $scope.staff = that.line.staff;
    }
  });

  $scope.$watch('staff', function (newVal, oldVal) {
    if (newVal !== oldVal && !angular.equals(that.line.staff, $scope.staff)) {
      that.line.staff = $scope.staff;
    }
  });

  $scope.$watch('expenseEntry.line.activity', function (newVal, oldVal) {
    if (newVal && (!oldVal || (newVal && oldVal && (newVal.activityId !== oldVal.activityId || newVal.code !== oldVal.code))) ) {
      log.info('activity changed');
      const billable = _.has(newVal, 'billable') ? newVal.billable : that.line.billable;
      // we only care about tax if its AU/GB
      const isTaxInclusive = hasFacet(facets.tax) ? (_.has(newVal, 'isTaxInclusive') ? newVal.isTaxInclusive : that.line.hasTax) : undefined;
      selectActivity({ ...newVal, billable, isTaxInclusive });
      if (newVal && (newVal.code || newVal.activityId)) {
        clearTaskCode();
      }
    }
    // when select subject from dropdown, we need to update the selectedActivity as well (activity's ng-model is selectedActivity)
    that.selectedActivity = that.line.activity;
  });

  $scope.$watch('expenseEntry.line.billable', function (newVal, oldVal) {
    if (newVal !== oldVal && !that.line.billable) {
      log.info('billable changed');
      that.line.waived = false;
    }
  });

  $scope.$watch('expenseEntry.line.operatingChequePrintOptions.chequePrintMethod', function (newVal, oldVal) {
    if (newVal !== oldVal && newVal === PRINT_MANUALLY) {
      log.info('chequePrintMethod changed');
      setDefaultOperatingChequeReference();
    }
  });

  if (hasFacet(facets.utbms)) {
    $scope.$watch('expenseEntry.line.taskCode', function (newVal, oldVal) {
      if (!oldVal || (newVal && oldVal && (newVal.code !== oldVal.code)) ) {
        log.info('task code changed');

        if (!that.editMode) {
          // Custom utbms codes have `isBillable` property with the value of `true` or `false`
          // standard utbms task codes do not have `isBillable` property and are always consider as billable
          that.line.billable = newVal && newVal.isBillable === false ? false : true;

          if (newVal && that.isSubjectOverridable) {
            that.line.description = newVal ? `${newVal.description || ''}` : undefined;
            that.prepField('description');
          }
        }

        if (newVal && newVal.code) {
          clearActivityCode();
        }
      }
    });

    $scope.$watch('expenseEntry.line.matter', function (newVal, oldVal) {
      // when new matter is selected, we need to reset some fields,
      // this is the case even when user clears the matter field
      const utbmsCodesRequiredForNewMatter = areUtbmsCodesRequiredForMatter(newVal);
      that.view.utbmsCodesRequiredForMatter = utbmsCodesRequiredForNewMatter;
      that.errors.utbmsTaskCode = false;
      updateUtbmsActivityCodes();

      if (!oldVal || (oldVal && newVal && (oldVal !== newVal))) {
        if (newVal) {
          const utbmsCodesRequiredByFirm = areUtbmsCodesRequiredByFirm();
          if (utbmsCodesRequiredByFirm) {
            // Firm requires UTBMS activity/task codes
            // Scenario 1: no matter selected and user pre-selects activity
            // Scenario 2: non-UTBMS matter selected and user pre-selects activity
            // For scenarios 1 & 2:
            //          2.1: switch to non-UTBMS matter > no change
            //          2.2: switch to UTBMS matter > disable activity, clear activity and associated subject
            // Scenario 3: UTBMS matter selected and user pre-selects expense
            //          3.1: switch to non-UTBMS matter > disable expense, clear expense and associated subject
            //          3.2: switch to another UTBMS matter, no change
            const utbmsEnabledForNewMatter = showUtbmsActivities(newVal)
            if (utbmsEnabledForNewMatter) {
              if (utbmsCodesRequiredForNewMatter) {
                // scenario 2.2
                if (that.line.activity && that.isSubjectOverridable) {
                  that.line.description = '';
                }
                clearActivityCode();
              }
            } else {
              // scenario 3.1
              if (that.line.taskCode && that.isSubjectOverridable) {
                that.line.description = '';
              }
              clearTaskCode();
            }
          } else {
            // Firm does NOT requires UTBMS activity/task codes
            // Scenario 4: no matter selected and user pre-selects activity
            // Scenario 5: non-UTBMS matter selected and user selects activity
            //          5.1: selects non-UTBMS matter, no change
            //          5.2: selects UTBMS matter > enable expense field
            // Scenario 6: UTBMS matter selected and user pre-selects expense
            //          6.1: selects non-UTBMS matter > disable expense field, clear expense and associated subject
            //          6.2: selects another UTBMS matter, no change
            const utbmsEnabledForNewMatter = showUtbmsActivities(newVal)
            if (!utbmsEnabledForNewMatter) {
              // scenario 6.1
              if (that.line.taskCode && that.isSubjectOverridable) {
                that.line.description = '';
              }
              clearTaskCode();
            }
          }
        }
      }
    });
  }

  function areUtbmsCodesRequiredByFirm() {
    if (!hasFacet(facets.utbms)) {
      return false;
    }

    const firmUtbmsSettings = sbUtbmsSettingsService.get();
    const utbmsCodesRequiredByFirm = firmUtbmsSettings && firmUtbmsSettings.isUtbmsEnabled && firmUtbmsSettings.utbmsCodesRequired || false;
    return utbmsCodesRequiredByFirm;
  }

  function areUtbmsCodesRequiredForMatter(matterId) {
    if (!hasFacet(facets.utbms) || !matterId) {
      return false;
    }

    const utbmsCodesRequiredByFirm = areUtbmsCodesRequiredByFirm();
    const matterBillingConfig = sbBillingConfigurationService.getByMatterId(matterId);
    const utbmsCodesRequiredForMatter = utbmsCodesRequiredByFirm && matterBillingConfig && matterBillingConfig.isUtbmsEnabled || false;
    return utbmsCodesRequiredForMatter;
  }

  function isPaidDirectDebit() {
    return (
      that.line &&
      that.line.expensePaymentDetails &&
      that.line.expensePaymentDetails.isPaid &&
      that.line.expensePaymentDetails.paymentMethod === paymentMethodByName.DIRECT_DEBIT
    );
  }

  function isPaidBankTransfer() {
    return (
      that.line &&
      that.line.expensePaymentDetails &&
      that.line.expensePaymentDetails.isPaid &&
      that.line.expensePaymentDetails.paymentMethod === paymentMethodByName.BANK_TRANSFER
    );
  }

  function isPaidCheque() {
    return (
      that.line &&
      that.line.expensePaymentDetails &&
      that.line.expensePaymentDetails.isPaid &&
      that.line.expensePaymentDetails.paymentMethod === paymentMethodByName.CHEQUE
    );
  }

  function isUpdateButtonDisabled() {
    // Update button is not enabled, unless you can actually change anything
    return (
      that.line.locked &&
      that.view.paymentMethodDisabled &&
      that.view.expenseTypeSelectionDisabled &&
      that.view.payableToggleDisabled &&
      that.view.supplierPaidToggleDisabled &&
      that.view.supplierDetailsDisabled
    );
  }

  function onPaymentMethodChanged(paymentMethod) {
    that.line.expensePaymentDetails.paymentMethod = paymentMethod.value;

    const isCheque = isPaidCheque();
    // for backwards compatibility
    that.line.operatingChequePrintOptions.chequePrintActive = isCheque;
    
    if (isCheque && that.line.expensePaymentDetails.supplierId) {
      that.line.operatingChequePrintOptions.payToId = that.line.expensePaymentDetails.supplierId;
    }
  }

  function onPayableToSupplierChanged(_name, isPayable) {
    that.line.expensePaymentDetails.isPayable = isPayable;
    if (!that.line.isAnticipated) {
      onSupplierPaidChanged(null, isPayable);
    }
  }

  function onSupplierPaidChanged(_name, isPaid) {
    that.line.expensePaymentDetails.isPaid = isPaid;
    if (isPaid) {
      that.line.expensePaymentDetails.paymentMethod = paymentMethodByName.BANK_TRANSFER; // default
    } else {
      // We need to reset cheque info when we toggle isPaid off just in case so we don't sent it to backend
      // This is to fix bug BB-12281 where we wrongly sent cheque info to backend even when user filled cheque
      // info and after that toggled isPaid off. This caused the expense was saved as not paid but had cheque generated.
      setDefaultOperatingChequeOptions();
    }
  }

  function onFocusCurrencyInput(event) {
    // Stops scrolls from changing the value in the currency field
    event.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false });
    
    event.target.select();
  }

  function onHasTaxChanged(_name, amountIncludesTax) {
    that.line.amountIncludesTax = amountIncludesTax;
    updateAmountAndTax();
  }

  function onBillableChanged(_name, isBillable) {
    that.line.billable = isBillable;
    updateAmountAndTax();
  }

  function onWaivedChanged(_name, isWaived) {
    that.line.waived = isWaived;
  }

  function onSubjectTextChanged(newText) {
    that.line.activity = { ...that.line.activity  };
    that.line.description = newText;
    that.line.activity.description = newText;
    that.prepField('description');
  }

  function onSubjectActivityChanged(newActivity){
    that.line.activity = { ...newActivity };
    that.line.description = that.line.activity.description;
    that.prepField('description');
  }

  function onSubjectOverrideChange(newText) {
    const emptySubject = !newText || !newText.trim()
    that.isSubjectOverridable = emptySubject;
  }

  function loadUnsavedChanges() {
    var line;
    if ($scope.features && $scope.features.storeUnsavedChanges) {
      line = sbUnsavedChangesService.loadMemory('SbExpenseEntryController-general');

      if (line) {
        that.line = line;
      }
    }
  }

  async function loadPrefill() {
    if (_.isObject($scope.preFill)) {
      // When adding or changing Expense or ExpenseVersion fields, please be
      // aware that $scope.preFill is being set in several places before this modal
      // is called. These calling code are transforming the base expense entity
      // before passing it in.
      //
      // So whenever a new field is being added to expense, expense version, or if
      // one of the custom fields set in preFill is changed, e.g.
      // 1. staffId: version.expenseEarnerStaffId,
      // 2. activityCode: version.expenseActivityId,
      // 3. units: version.quantity / 100,
      // 4. rate: version.price / 100,
      // 5. date:
      // 6. matter etc
      //
      // Please search for "ng-components/expense-entry/modal/expense-modal.html"
      // and ensure that preFill is being set as you expect it to in these calling
      // components. Failing to do so will result in bugs when the expense modal is
      // called from one of the following locations
      // 1. Draft Invoice
      // 2. View Invoice > Entries
      // 3. Invoices > Create Invoices > Select Matter > Edit unbilled matter entry
      // 4. Matter > Expenses
      // 5. Time & Fees > Expenses
      //
      // Tech Sprint Candidates
      // 1) Ensure all calling code only pass the base expense entity object without
      //    transformation. Do any transformation required here, so this code is not
      //    replicated everywhere.
      // 2) Have separate controllers for expense-long-entry and expense-quick-entry.
      //    Sharing the same controller for two separate forms can easily lead to
      //    unintentional bugs when modifying one form and unintentionally breaking the
      //    other.
      that.line = $scope.preFill;
      that.line.staff = findStaffById(that.line.staffId);
      that.line.date = isDate(that.line.date) ? that.line.date : sbDateService.from(that.line.date);

      // this is the way smokeball works. If we have an activity selected we have to set the activity and fill
      // the description from this activity not from the one of the list.
      const activityCode = that.line.utbmsActivityCode || that.line.activityCode;
      that.line.activity = { ...findActivityByCode(activityCode) };
      that.line.taskCode = { ...findTaskCodeByCode(that.line.utbmsTaskCode) };
      that.line.activity.description = that.line.description;
      that.line.initialActivity = { ...that.line.activity }

      that.line.prevActivityCode = that.line.activity && that.line.activity.code;
      that.line.taxExempt = that.line.activity && that.line.activity.taxExempt;
      that.line.tax = roundDollars(that.line.tax / 100); // tax stored as cents
      that.line.outputTax = that.line.outputTax !== undefined && that.line.outputTax !== null ? roundDollars(that.line.outputTax / 100) : undefined;
      that.line.isLatestVersion = sbExpenseService.isLatestVersion(that.line.expenseId, that.line.expenseVersionId);
      that.line.locked = !!(that.line.finalized && that.line.invoiceId) || !that.line.isLatestVersion || that.line.isInvoicedExternally;

      // load operating cheque if one exists else load default
      if (that.line.operatingChequeId) {
        that.line.operatingCheque = getOperatingChequeById(that.line.operatingChequeId);
      } else {
        setDefaultOperatingChequeOptions();
      }

      const expensePaymentDetails = getExpensePaymentDetailsById(that.line.expenseId);
      if (expensePaymentDetails) {
        that.line.expensePaymentDetails = { ...expensePaymentDetails };
      } else {
        setDefaultExpensePaymentDetails();
      }

      that.line.isAnticipated = !!that.line.isAnticipated;

      // backwards compatibility before isPayable existed, select cheque as an option if cheque id exists
      if ((!that.line.expensePaymentDetails.isPayable || !that.line.expensePaymentDetails.isPaid) && that.line.operatingChequeId) {
        that.line.expensePaymentDetails.isPayable = true;
        that.line.expensePaymentDetails.isPaid = true;
        that.line.expensePaymentDetails.paymentMethod = paymentMethodByName.CHEQUE;
      }

      if (that.line.expensePaymentDetails.paymentDue) {
        const paymentDue = that.line.expensePaymentDetails.paymentDue;
        that.line.expensePaymentDetails.paymentDue = isDate(paymentDue) ? paymentDue : sbDateService.from(paymentDue);
      } else {
        // paymentDue is 0 rfom backend when not set so we want to explicitly set it to undefined
        that.line.expensePaymentDetails.paymentDue = undefined;
      }

      await setReceiptDownloadLink();
    } else {
      // When opening modal from Matter > Expenses > New Expense Entry / New Disbursement Entry, prefill is
      // set with the matter id concerned instead of an expense line. Wrapping this
      // in an else statement to high light this use case that is easy to miss.
      setMatterFromPreFill();
    }

    that.view.showPayableToggle = that.line.isAnticipated ? false : true;
    that.view.showSupplierPaidToggle = that.line.isAnticipated ? true : false;
    that.view.showExpensePaidWarning = !that.line.locked && that.line.expensePaymentDetails.isPaid;

    setDisabledFlagForPaymentDetailsFields();
    that.view.utbmsCodesRequiredForMatter = areUtbmsCodesRequiredForMatter(that.line.matter);

    // We want to set this on load only
    that.view.editingPaidExpense = that.editMode && that.line.expensePaymentDetails.isPaid;
    that.updateAmountAndTax();
    setInitialFocus();
  }

  function setDefaultOperatingChequeOptions() {
    that.line.operatingChequePrintOptions = {
      payToId: undefined,
      chequeMemo: undefined,
      chequePrintActive: false,
      chequePrintMethod: getDefaultOperatingChequePrintMethod(),
    }
    setDefaultOperatingChequeReference();
  }

  function setDefaultExpensePaymentDetails() {
    const isAnticipated = that.line.isAnticipated;

    that.line.expensePaymentDetails = {
      isPayable: isAnticipated,
      isPaid: false,
      supplierId: undefined,
      supplierReference: undefined,
      paymentDue: undefined,
      paymentMethod: paymentMethodByName.BANK_TRANSFER,
      paymentReference: undefined,
      paymentAccountName: undefined,
    }

    that.view.showPayableToggle = !isAnticipated;
    that.view.showSupplierPaidToggle = isAnticipated;
    that.view.paymentMethodDisabled = false;
    that.view.expenseTypeSelectionDisabled = false;
    that.view.payableToggleDisabled = false;
    that.view.supplierPaidToggleDisabled = false;
    that.view.supplierDetailsDisabled = false;
  }

  function setDisabledFlagForPaymentDetailsFields() {
    that.view.paymentMethodDisabled = false;
    that.view.expenseTypeSelectionDisabled = false;
    that.view.payableToggleDisabled = false;
    that.view.supplierPaidToggleDisabled = false;
    that.view.supplierDetailsDisabled = false;
  
    if (!that.editMode) {
      return;
    }
  
    const isAD = that.line.isAnticipated;
    const isDisbursement = !that.line.isAnticipated;
  
    if (that.line.locked) {
      // locked - on finalised invoice
      that.view.expenseTypeSelectionDisabled = true;
  
      if (
        (isDisbursement && that.line.expensePaymentDetails.isPayable) ||
        (isAD && that.line.expensePaymentDetails.isPaid)
      ) {
        that.view.paymentMethodDisabled = true;
        that.view.supplierDetailsDisabled = isAD;
  
        that.view.payableToggleDisabled = isPaidCheque();
        that.view.supplierPaidToggleDisabled = isPaidCheque();
      } else if (isDisbursement && !that.line.expensePaymentDetails.isPayable) {
          // When "Not Payable" disbursement is on finalised invoice it can't be make "Payable"
          that.view.payableToggleDisabled = true;
          that.view.supplierPaidToggleDisabled = true;
      }
    } else {
      // not locked - not on finalised invoice
      if (
        (isDisbursement && that.line.expensePaymentDetails.isPayable) ||
        (isAD && that.line.expensePaymentDetails.isPaid)
      ) {
        that.view.expenseTypeSelectionDisabled = true;
        that.view.paymentMethodDisabled = true;
        that.view.payableToggleDisabled = isPaidCheque();
        that.view.supplierPaidToggleDisabled = isPaidCheque();
      }
    }
  } 

  function setDefaultOperatingChequeReference() {
    if (that.line.operatingChequePrintOptions && that.line.operatingChequePrintOptions.chequePrintMethod === PRINT_MANUALLY) {
      // find last cheque number in system and use that as the default reference number
      const lastChequeNumberFound = findLastOperatingChequeNumber(getOperatingChequeList()); // returns a string
      that.view.lastChequeNumberInSystem = lastChequeNumberFound;
      that.line.operatingChequePrintOptions.reference = getNextChequeNumber(getOperatingChequeList());

      // clear out any existing reference related errors
      that.errors.operatingChequeReference = false;
      that.errors.operatingChequeReferenceIsRequired = false;
      that.errors.operatingChequeReferenceShouldBeNumeric = false;
      that.errors.operatingChequeReferenceAlreadyInUse = false;

      that.warnings.operatingChequeReferenceAlreadyInUse = false;
    }
  }

  async function setReceiptDownloadLink() {
    if (that.line.attachmentFile && that.line.attachmentFile.location) {
      const accountId = getAccountId();
      that.receiptDownloadUrl = await getReceiptDownloadUrl({
        accountId,
        filePath: that.line.attachmentFile.location
      });
    }
  }

  function unlockLine() {
    if(that.line.isLatestVersion){
      that.line.locked = false;
    }
  }

  function focusOn($event, target) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    $timeout(function () {
      focusService.focusOn(that.ctrlId + '-' + target);
    });
  }

  function isDataReady() {
    if (!that.view.loaded) {
      that.view.loaded = that.view.staff &&
        that.view.activities;

      if (that.view.loaded) {
        setInitialFocus();
      }
    }
    return that.view.loaded;
  }

  function isEditableMatter() {
    return sbMattersMbService.isEditableMatter(that.sbData.matter.matterId);
  }

  function updateAmountAndTax() {
    if (that.line.rate >= 0 && that.line.units > 0) {
      that.line.amount = roundDollars(that.line.rate * that.line.units); // fixme: that.line.rate is in dollars
    } else {
      that.line.amount = 0;
    }

    recalculateTax();
  }

  function initFields(resetFlag, resetAll, matterMode) {
    if (resetFlag) {
      that.line.description = '';
      that.line.notes = '';
      that.line.activityCode = undefined;
      that.line.activity = undefined;
      that.line.prevActivityCode = undefined;
      that.line.taskCode = undefined;
      that.line.amountIncludesTax = false;
      that.line.isTaxOverridden = false;
      that.line.isOutputTaxOverridden = false;
      setDefaultExpensePaymentDetails();

      // reset all cheque fields for "Save & New" expense use case
      if (that.line.operatingChequePrintOptions && that.line.operatingChequePrintOptions.chequePrintActive) {
        setDefaultOperatingChequeOptions();
      }

      // reset file input for "Save & New" expense use case
      $scope.uploadedFiles = {};
      const receiptFileInput = document.getElementById('expense-attachment');
      if (receiptFileInput) { // exists only in expense-long-entry.html
        receiptFileInput.value = null;
      }
    }

    if (resetAll) {
      that.line.date = matterMode ? '' : that.line.date;
      that.line.staff = '';
      that.line.staffId = '';
      if (!$scope.features || !$scope.features.matterMode) {
        that.line.matter = '';
      }
    }

    if (!isDate(that.line.date)) {
      that.line.date = new Date();
    }

    if (!that.line.staff) {
      selectStaff();
    }

    // set units to 1 if
    if (resetFlag || that.line.units === undefined) {
      that.line.units = 1;
    }

    if (resetFlag || that.line.rate === undefined) {
      that.line.rate = 0;
    }

    that.updateAmountAndTax();
  }

  function prepField(name) {
    $timeout(function () {
      // anticipated disbursements
      const supportsAD = that.supportsAD();
      const isBankTransferOrDirectDebit = isPaidBankTransfer() || isPaidDirectDebit();
      switch (name) {
        case 'activity':
          selectActivity(findActivityByCode(that.line.activityCode));
          break;
        case 'date':
          that.errors.date = !isDate(that.line.date) && formDirty;
          break;
        case 'description':
          that.errors.description = formDirty && (!that.line.description || that.line.description.length < 1);
          break;
        case 'rate':
          if (+that.line.rate >= 0) {
            that.errors.rate = false;
            that.line.rate = roundDollars(+that.line.rate);
          } else {
            that.errors.rate = formDirty;
          }
          break;
        case 'staff':
          selectStaff(findStaffById(that.line.staff.id));
          break;
        case 'tax':
          that.errors.tax = that.line.amountIncludesTax && that.line.tax > that.line.amount;
          break;
        case 'outputTax':
          that.errors.outputTax = that.line.amountIncludesTax && that.line.outputTax > that.line.amount;
          break;
        case 'units':
          if (+that.line.units >= 0) {
            that.errors.units = false;
            that.line.units = roundDollars(+that.line.units);
          } else {
            that.errors.units = formDirty;
          }
          break;
        case 'matter':
          that.errors.matter = _.isEmpty(that.line.matter) && !!formDirty;
          break;
        case 'operatingChequePayTo':
          that.errors.operatingChequePayTo = isEmpty(that.line.operatingChequePrintOptions.payToId) && !!formDirty;
          break;
        case 'operatingChequeReference':
          validateAndSetErrorsForChequeFields();
          break;
        case 'paymentAccountName':
          that.errors.paymentAccountName = supportsAD && isBankTransferOrDirectDebit && !that.line.expensePaymentDetails.paymentAccountName;
          break;
        case 'paymentReference':
          that.errors.paymentReference = supportsAD && isBankTransferOrDirectDebit && !that.line.expensePaymentDetails.paymentReference;
          break;
        case 'paymentDue':
          that.errors.paymentDue = supportsAD && !isDate(that.line.expensePaymentDetails.paymentDue) && formDirty;
          break;
        case 'supplierId':
          that.errors.supplierId = supportsAD && that.line.expensePaymentDetails.isPayable && !that.line.expensePaymentDetails.supplierId && formDirty;
          break;
        case 'supplierReference':
          that.errors.supplierReference = isSupplierReferenceInvalid({ expensePaymentDetails: that.line.expensePaymentDetails, isAnticipated: that.line.isAnticipated }) && formDirty;
          break;

      }
    }, 200);
  }

  function isSupplierReferenceInvalid({ expensePaymentDetails, isAnticipated }) {
    if (!that.supportsAD()) {
      return false;
    }
    const { isPayable, isPaid, supplierReference } = expensePaymentDetails;
    return (
      (!isAnticipated && isPayable && !supplierReference) ||
      (isAnticipated && isPaid && !supplierReference)
    )
  }

  function buildFocusKey(key) {
    return `${that.ctrlId}-${key}`;
  }

  async function submit(leaveOpen) {
    let callback;
    if (leaveOpen || !$scope.onClose) {
      callback = angular.noop;
    } else {
      callback = $scope.onClose;

    }

    const expenseVersion = getVersion();

    const receiptFile = $scope.uploadedFiles.receiptFile;
    const isValid = await validate(expenseVersion, receiptFile);
    if (isValid) {
      const isNewExpense = !that.line.expenseId;
      const expenseId = that.line.expenseId || uuid(); // when editing use existing expenseId, otherwise create a new one
      const expenseVersionId = uuid(); // always create a new expenseVersionId when saving new or existing expense entry
      const now = sbDateService.now();

      that.line.processing = true;
      that.line.locked = true;

      expenseVersion.createdDate = now;
      expenseVersion.validFrom = now;

      if (that.isDeletingReceiptFile) {
        // remove file link from expense record, file is not deleted explicitly, it's kept for archival
        expenseVersion.attachmentFile = undefined;
      } else {
        // retain existing attachment
        expenseVersion.attachmentFile = that.line.attachmentFile;
      }

      // upload receipt file if a new file is selected
      if (receiptFile) {
        const accountId = getAccountId();
        const fileInBase64 = await fileToBase64(receiptFile);
        const fileName = receiptFile.name;
        const filePath = await uploadReceiptFile({
          accountId,
          expenseId,
          expenseVersionId,
          fileInBase64,
          fileName,
        });

        const attachmentFile = {
          location: filePath,
          fileName
        };

        expenseVersion.attachmentFile = attachmentFile;
      }

      if (that.useCommandDispatcher) {
        const message = {
          expenseId: that.line.expenseId || uuid(),
          expenseVersionId: uuid(),
          ...expenseVersion,
        }

        // Remove accountId in order to pass the Command Processor validation
        if (message.operatingCheque && message.operatingCheque.accountId) {
          delete message.operatingCheque.accountId;
        }

        dispatchCommand({ type: 'Billing.Expenses.Commands.SaveExpense', message }).then((saveExpenseResult) => {
          if (isNewExpense) {
            messageDisplay.success(`${sbLocalisationService.t('capitalizeAllWords', { val: 'expense' })} added successfully`);
          }

          formDirty = false;
          that.line.locked = false;
          that.line.processing = false;

          callback({ dismiss: true });

          if (that.onSuccess && typeof that.onSuccess === 'function') {
            that.onSuccess({ saved: saveExpenseResult && saveExpenseResult.version });
          }

          // determine whether to open print cheque form before resetting form fields
          const shouldOpenPrintChequeForm = isOperatingChequesEnabledAndPrintNow();

          if (shouldOpenPrintChequeForm) {
            const operatingChequeId = expenseVersion.operatingChequePrintOptions.chequeId;
            if (featureActive('BB-13415')) {
              // LOD print operating cheques modal, expecting different props
              setModalDialogVisible({ modalId: LOD_PRINT_OPERATING_CHEQUE_MODAL_ID, props: { chequeIds: [operatingChequeId], sbAsyncOperationsService } });
            } else {
              setModalDialogVisible({ modalId: PRINT_OPERATING_CHEQUE_MODAL_ID, props: { operatingChequeIds: [operatingChequeId] } });
            }
          }
        }).catch(err => {
          log.error('Failed to save expense', err);
          messageDisplay.error(`Failed to save ${sbLocalisationService.t('capitalizeAllWords', { val: 'expense' })}. Please check your connection and try again.`);
          callback({ dismiss: true });
        });

        return;
      }

      // save expense record
      const returnedEntity = await saveExpense({
        ...expenseVersion,
        expenseId,
        expenseVersionId,
      });
      if (isNewExpense) {
        messageDisplay.success(`${sbLocalisationService.t('capitalizeAllWords', { val: 'expense' })} added successfully`);
      }
      formDirty = false;
      that.line.locked = false;

      // determine whether to open print cheque form before resetting form fields
      // equivalent to isOperatingChequesEnabledAndPrintNow()
      const shouldOpenPrintChequeForm = that.view.isOperatingChequesEnabled &&
        that.line.operatingChequePrintOptions &&
        that.line.operatingChequePrintOptions.chequePrintActive &&
        that.line.operatingChequePrintOptions.chequePrintMethod === printMethodsByValue.PrintNow.value;

      // reset form fields
      if (returnedEntity) {
        that.initFields(true);
        that.focusOn(null, 'expense-activity-field', true);
        that.line.processing = false;
        that.line.billable = true;
        that.isSubjectOverridable = true; 
      } else {
        that.line.processing = false;
      }
      callback({saved: returnedEntity});
      if (that.onSuccess && typeof that.onSuccess === 'function') {
        that.onSuccess({ saved: returnedEntity });
      }

      if ($scope.features && $scope.features.storeUnsavedChanges) {
        sbUnsavedChangesService.saveMemory('SbExpenseEntryController-' + that.line.matter);
      }

      // open print cheque form if required
      if(shouldOpenPrintChequeForm){
        const operatingChequeId = expenseVersion.operatingChequePrintOptions.chequeId;
        if (featureActive('BB-13415')) {
          // LOD print operating cheques modal, expecting different props
          setModalDialogVisible({ modalId: LOD_PRINT_OPERATING_CHEQUE_MODAL_ID, props: { chequeIds: [operatingChequeId], sbAsyncOperationsService } });
        } else {
          setModalDialogVisible({ modalId: PRINT_OPERATING_CHEQUE_MODAL_ID, props: { operatingChequeIds: [operatingChequeId] } });
        }
      }
    } else {
      formDirty = true;
      if (that.errors.date) {
        that.focusOn(null, 'expense-date-field');
      } else if (that.errors.matter) {
        that.focusOn(null, 'expense-matter-field');
      } else if (that.errors.description) {
        that.focusOn(null, 'expense-description-field');
      } else if (that.errors.units) {
        that.focusOn(null, 'expense-units-field');
      } else if (that.errors.rate) {
        that.focusOn(null, 'expense-rate-field');
      } else if (that.errors.tax) {
        that.focusOn(null, 'expense-tax-field');
      } else if (that.errors.operatingChequeReference) {
        that.focusOn(null, 'expense-reference-field');
      }
      callback({saved: false});
    }
  }

  function deleteExpense(id) {
    var callback = $scope.onClose || angular.noop;

    sbExpenseService.deleteExpenseP(id).
      then(function (returnedEntity) {
        callback({deleted: returnedEntity});
      });
  }

  function updateView() {
    if (!$scope.features || !$scope.features.update) {
      // don't hose the fields if we're editing
      initFields();
    }
  }

  function updateStaff() {
    if ($scope.staff) {
      that.line.staff = findStaffById($scope.staff.id);
      that.line.staffId = that.line.staff && that.line.staff.id;
      selectStaff(that.line.staff);
    }
  }

  function updateActivities() {
    var activities = sbBillingActivityService.getActivities() || [];

    that.view.activities = _.filter(activities, function (act) {
      return act.type === sbBillingActivityService.activityTypes.Expense;
    });
  }

  function setInitialFocus() {
    if (($scope.features && $scope.features.matterMode) || that.line.matter) {
      that.focusOn(null, 'expense-activity-field');
    } else {
      that.focusOn(null, 'expense-matter-field');
    }
  }

  function setMatterFromPreFill() {
    if (_.isEmpty(that.line.matter)) {
      if (isString($scope.preFill)) {
        that.line.matter = $scope.preFill;

        if (_.get($scope, 'features.storeUnsavedChanges')) {
          that.line = sbUnsavedChangesService.loadMemory('SbExpenseEntryController-' + that.line.matter) || that.line;
        }
      } else if (_.isObject($scope.preFill) && $scope.preFill.matter) {
        that.line.matter = $scope.preFill.matter;
      }
      setInitialFocus();
    }
  }

  function onChangeTax() {
    that.line.isTaxOverridden = true;
  }

  function onChangeOutputTax() {
    that.line.isOutputTaxOverridden = true;
  }

  function onSelectExpenseType(expenseTypeSelected) {
    const isAnticipated = expenseTypeSelected && expenseTypeSelected.id === EXPENSE_TYPES.ANTICIPATED_DISBURSEMENT;
    that.line.isAnticipated = isAnticipated;
   
    that.line.expensePaymentDetails.isPayable =  isAnticipated ? true : false;
    that.line.expensePaymentDetails.isPaid = false;
    that.view.showPayableToggle = isAnticipated ? false : true;
    that.view.showSupplierPaidToggle = isAnticipated ? true : false;
  }

  function onSelectStaff(item, focusField) {
    const selectedStaffMember = !_.isEmpty(item) && _.find(sbFirmManagementMbService.getAllStaffSummaries(), summary => summary.id === item.id);
    selectStaff(selectedStaffMember);
    that.focusOn(undefined, focusField);
  }

  function selectStaff(staff) {
    $timeout(function () { // ensure that the view is ready
      if (!staff) {
        staff = getDefaultStaffMember();
      }

      if (staff) {
        that.line.staff = staff;
        that.line.staffId = that.line.staff.id;
      }
    });
  }

  function onActivitySelect(newActivity) {
    if (!that.editMode && !that.isSubjectOverridable) {
      newActivity = { ...newActivity, description: that.line.description };
    }
    // value of line.activity used for data saving and also passed to subject field in add mode
    // selectedActivity is used for activity's ng-model. We cannot use line.activity as ng-model directly
    // otherwise when user selects an activity, it updates the ng-model (expenseEntry.line.activity) before calling this function
    // and the subject field UI won't update even if we update the description in this function
    that.line.activity = newActivity;
  }

  function selectActivity(activity) {
    that.line.activityCode = (activity && activity.code) || '';

    if (_.isObject(activity)) {
      that.line.prevActivityCode = activity.code;

      if (!that.editMode) { // don't clobber data in edit mode
        that.line.billable = true;

        if (that.isSubjectOverridable) {
          that.line.description = `${activity.description || ''}`;
          that.prepField('description');
        }

        that.line.units = 1;

        if (+activity.units) {
          that.line.units = activity.units / 100; // TODO: decimal-operation
        }

        that.prepField('units');
        that.line.billable = activity.billable;
        that.line.amountIncludesTax = activity.amountIncludesTax;
        that.line.taxExempt = activity.taxExempt;

        if (activity.rate || activity.rate === 0) {
          that.line.rate = activity.rate / 100; // TODO: decimal-operation
          that.prepField('rate');
        }
      } else {
        // in edit mode, don't automatically modify fields that are already set,
        // taxExempt don't have a corresponding UI field thus should be updated
        // as per what the user would expect.
        that.line.taxExempt = activity.taxExempt;
      }
    } else {
      that.line.prevActivityCode = undefined;
    }

    that.updateAmountAndTax();
  }

  async function onUpdateClick() {
    if (!that.editMode) {
      return;
    }
    
    if (!that.line.locked) {
      // send both expense and payment details - handling and validation is same as saving new one
      submit();
    } else {
      // If expense is locked, we can update only payment details
      if (validatePaymentDetailsFields()) {
        // save expense record
        const paymentDetails = {
          // On purpose not providing expenseVersion fields so the expense version is not updated
          expensePaymentDetails: getPaymentDetailsVersion(),
          expenseId: that.line.expenseId,
          matterId: that.line.matter,
          isAnticipated: that.line.isAnticipated,
          operatingChequePrintOptions:
            userWouldLikeToCreateCheque() && {
              chequeId: that.line.operatingChequePrintOptions.chequeId || uuid(),
              bankAccountId: operatingBankAccountId,
              chequeDate: dateToInteger(new Date()),
              chequePrintActive: that.line.operatingChequePrintOptions.chequePrintActive,
              chequePrintMethod: that.line.operatingChequePrintOptions.chequePrintMethod,
              payToId: that.line.operatingChequePrintOptions.payToId,
              chequeMemo: that.line.operatingChequePrintOptions.chequeMemo,
              reference: that.line.operatingChequePrintOptions.reference,
              allowChequeNumberDuplication
            } || undefined,
        }

        if (that.useCommandDispatcher) {
          await dispatchCommand({ type: 'Billing.Expenses.Commands.SaveExpense', message: paymentDetails });
        } else {
          await saveExpensePaymentDetailsForLockedExpense(paymentDetails);
        }

        $scope.onClose({saved: true});

        // open print cheque form if required
        if(isOperatingChequesEnabledAndPrintNow()) {
          const operatingChequeId = paymentDetails.operatingChequePrintOptions.chequeId;
          if (featureActive('BB-13415')) {
            // LOD print operating cheques modal, expecting different props
            setModalDialogVisible({ modalId: LOD_PRINT_OPERATING_CHEQUE_MODAL_ID, props: { chequeIds: [operatingChequeId], sbAsyncOperationsService } });
          } else {
            setModalDialogVisible({ modalId: PRINT_OPERATING_CHEQUE_MODAL_ID, props: { operatingChequeIds: [operatingChequeId] } });
          }
        }
      } else {
        formDirty = true;
        // leave expense modal open
        $scope.onClose({saved: false});
      }
    }
  }

  function getVersion() {
    const expenseVersion = {
      amountIncludesTax: !!that.line.amountIncludesTax,
      expenseEarnerStaffId: that.line.staff && that.line.staff.id,
      matterId: that.line.matter,
      invoiceId: that.line.invoiceId,
      description: that.line.description,
      notes: that.line.notes,
      expenseActivityId: !isUtbmsActivity() ? that.line.activity && that.line.activity.code : undefined,
      expenseDate: that.line.date && sbDateService.to(that.line.date),
      quantity: Math.round(that.line.units * 100), // TODO: decimal-operation
      price: roundCents(that.line.rate * 100), // TODO: decimal-operation
      tax: roundCents(that.line.tax * 100) || 0,
      isTaxOverridden: that.line.isTaxOverridden,
      outputTax: featureActive('BB-12987') ? roundCents(that.line.outputTax * 100) || 0 : undefined, 
      isOutputTaxOverridden: featureActive('BB-12987') ? that.line.isOutputTaxOverridden : undefined,
      isBillable: !!that.line.billable,
      waived: !!that.line.waived,
      utbmsActivityCode: isUtbmsActivity() && that.line.activity ? that.line.activity.code : undefined,
      utbmsTaskCode: that.line.taskCode ? that.line.taskCode.code : undefined,
      operatingChequePrintOptions:
        userWouldLikeToCreateCheque() && {
          chequeId: that.line.operatingChequePrintOptions.chequeId || uuid(),
          bankAccountId: operatingBankAccountId,
          chequeDate: dateToInteger(new Date()),
          chequePrintActive: that.line.operatingChequePrintOptions.chequePrintActive,
          chequePrintMethod: that.line.operatingChequePrintOptions.chequePrintMethod,
          payToId: that.line.operatingChequePrintOptions.payToId,
          chequeMemo: that.line.operatingChequePrintOptions.chequeMemo,
          reference: that.line.operatingChequePrintOptions.reference,
          allowChequeNumberDuplication,
        } || undefined,
      isAnticipated: that.line.isAnticipated,
      // object or null when not AD supported
      expensePaymentDetails: getPaymentDetailsVersion()
    };
    
    return expenseVersion;
  }

  function getPaymentDetailsVersion() {
    let expensePaymentDetails = null;
    if (that.supportsAD()) {
      const isPaid = that.line.expensePaymentDetails.isPaid;
      expensePaymentDetails = {
        supplierId: that.line.expensePaymentDetails.supplierId,
        supplierReference: that.line.expensePaymentDetails.supplierReference,
        paymentDue: that.line.expensePaymentDetails.paymentDue && sbDateService.to(that.line.expensePaymentDetails.paymentDue), //yyyyMMdd
        isPaid,
        isPayable: that.line.expensePaymentDetails.isPayable,
        paymentMethod: isPaid ? that.line.expensePaymentDetails.paymentMethod : paymentMethodByName.NONE,
        paymentReference: isPaid ? that.line.expensePaymentDetails.paymentReference : undefined,
        paymentAccountName: isPaid ? that.line.expensePaymentDetails.paymentAccountName : undefined,
      }
    }

    return expensePaymentDetails;
  }

  async function validate(version, receiptFile) {
    var goodRate = +version.price >= 0,
      goodUnits = +version.quantity  > 0;
    that.errors = {};
    if (!version.expenseEarnerStaffId) {
      that.errors.staff = true;
    }
    if (!version.quantity) {
      that.errors.units = true;
    }
    if (!version.expenseDate) {
      that.errors.date = true;
    }
    if (that.view.utbmsCodesRequiredForMatter && !version.utbmsTaskCode) {
      that.errors.utbmsTaskCode = true;
    }

    if (!goodRate) {
      that.errors.rate = true;
    }
    if (!goodUnits) {
      that.errors.units = true;
    }
    if (!version.description || version.description.length < 1 || version.description.length > 1000) {
      that.errors.description = true;
    }
    if (!version.matterId) {
      that.errors.matter = true;
    }
    if (that.line.amountIncludesTax && that.line.tax > that.line.amount) {
      that.errors.tax = true;
    }
    if (that.line.amountIncludesTax && that.line.outputTax > that.line.amount) {
      that.errors.outputTax = true;
    }

    // validate file size and format if one is selected
    if (receiptFile) {
      const FOUR_MB = 4194304;
      if (receiptFile.size > FOUR_MB) {
        that.errors.receiptFileSize = true;
      }

      const fileExtension = getFileExtension(receiptFile.name);
      if (!SUPPORTED_FILE_FORMATS.includes(fileExtension)) {
        that.errors.receiptFileFormat = true;
      }

      const isAllowed = await isAllowedFileType(receiptFile);
      if (!isAllowed) {
        that.errors.receiptFileFormat = true;
      }
    }

    if (that.supportsAD()) {
      validateAndSetErrorsForPaymentDetailsFields();
    } else {
      // validate cheque fields if user would like to print cheque
      if (userWouldLikeToCreateCheque()) {
        validateAndSetErrorsForChequeFields();
      }
    }


    return !_.size(that.errors);
  }

  function findActivityByCode(code) {
    var re;

    if (!isString(code) || code.length < 1) {
      return;
    }
    re = new RegExp('^' + code.trim() + '$', 'i');

    const activities = isUtbmsActivity(code) ? that.view.allActivities : that.view.activities;

    return _.find(activities, function (activity) {
      return activity.code.match(re);
    });
  }

  function findTaskCodeByCode(code) {
    var re;

    // `code` would be set to ' ' for null on matter expense list for sorting purpose.
    // Please reference monorepo/apps/smokeball-billing-web/src/react-redux/components/expense-list/MatterExpenseList.container.jsx:80
    if (!isString(code) || code.length < 1 || code === ' ') {
      return;
    }

    re = new RegExp('^' + code.trim() + '$', 'i');

    return that.view.taskCodes.find(taskCode => taskCode.code.match(re));
  }

  function findStaffById(id) {
    return _.find(that.view.staff, member => member.id === id);
  }

  function getDefaultStaffMember() {
    if (that.view.loggedInStaffMember && that.view.staff) {
      return _.find(that.view.staff, function (member) {
        return member.id === that.view.loggedInStaffMember.id;
      }) || that.view.staff[0];
    }
  }

  function handleLoadedOrTimeout() {
    if (!isDataReady()) {
      messageDisplay.warn('Failed to load data required for expense entry');

      log.warn('Expense entry data load failed for staff/activities/loggedInStaffMember',
        !_.isEmpty(that.view.staff) && that.view.staff,
        !_.isEmpty(that.view.activities) && that.view.activities,
        !_.isEmpty(that.view.loggedInStaffMember) && that.view.loggedInStaffMember
      );
    }
  }

  function onSelectMatter(value) {
    that.line.matter = value && value.id;
    prepField('matter');
    that.focusOn(null, 'expense-activity-field');
  }

  function dataChangeFunction(key, doc) {
    if (doc && doc.data) {
      _.set(that.sbData, key, doc.data);
    } else {
      _.set(that.sbData, key, doc);
    }
  }

  function updateUtbmsActivityCodes() {
    if (!hasFacet(facets.utbms) ) return;

    const firmSettings = sbUtbmsSettingsService.get();
    if (firmSettings && !firmSettings.isUtbmsEnabled) return;

    // if utbms code is required for matter, don't allow selection of custom activities
    // in subject field which would normally auto-populate the activity field
    const utbmsCodesRequiredForMatter = areUtbmsCodesRequiredForMatter(that.line.matter);
    if (utbmsCodesRequiredForMatter) {
      that.view.allActivities = []; // activity field is disabled in this scenario.
    } else {
      that.view.allActivities = [
        ...that.view.activities.map(activity => ({
          ...activity,
          groupLabel: 'ACTIVITY CODES'
        })),
        ...sbUtbmsActivityCodeService.getFeeUtbmsActivities().map(activity => ({
          ...activity,
          groupLabel: 'UTBMS CODES'
        })),
      ];
    }
  }

  function updateUtbmsTaskCodes() {
    if (!hasFacet(facets.utbms)) return;

    const utbmsTaskCodes = sbUtbmsTaskCodeService.getExpenseTaskCodes() || [];

    const customTaskCodes = getExpenseCustomTaskCodes() || [];

    const hasUtbmsTaskCodes = utbmsTaskCodes && utbmsTaskCodes.length > 0;
    const hasCustomTaskCode = customTaskCodes && customTaskCodes.length > 0;

    // if both utbms and custom task codes are available, show them in separate groups
    that.view.taskCodes = (hasCustomTaskCode && hasUtbmsTaskCodes) ? [
      ...utbmsTaskCodes.map(codeItem => ({
        ...codeItem,
        groupLabel: 'UTBMS CODES'
      })),
      ...customTaskCodes.map(codeItem => ({
        ...codeItem,
        groupLabel: 'CUSTOM UTBMS CODES',
      })),
    ] : [...utbmsTaskCodes, ...customTaskCodes];
  }

  function showUtbmsFields() {
    if (!hasFacet(facets.utbms)) return false;

    const firmUtbmsSettings = sbUtbmsSettingsService.get();

    return (firmUtbmsSettings && firmUtbmsSettings.isUtbmsEnabled);
  }

  function isUtbmsActivity(code) {
    if (!hasFacet(facets.utbms)) return false;

    if (!showUtbmsFields() || !that.line.matter || (!code && !that.line.activity)) return false;

    const activityCode = code ? code : that.line.activity.code;
    return (sbUtbmsActivityCodeService.getFeeCodes().indexOf(activityCode) >= 0);
  }

  function showUtbmsActivities(matter) {
    if (!hasFacet(facets.utbms)) return false;
    const matterId = matter ? matter : that.line.matter;

    if (!matterId || !showUtbmsFields()) return false;

    const matterUtbmsSettings = sbBillingConfigurationService.getByMatterId(matterId);
    return (matterUtbmsSettings && matterUtbmsSettings.isUtbmsEnabled);
  }

  function enableTaskCodes() {
    return showUtbmsActivities();
  }

  function clearTaskCode() {
    that.line.taskCode = null;
  }

  function clearActivityCode() {
    that.line.activity = null;
  }

  function showUtbmsDescription() {
    if (!that.line.activity && that.line.taskCode) return true;
    return isUtbmsActivity();
  }

  /****** Input/Output Tax ******/
  function recalculateTax() {
    const isTaxFacetEnabled = hasFacet(facets.tax);
    let taxInCents = 0;
    let outputTaxInCents = 0;

    if (isTaxFacetEnabled) {
      const inputOutputTaxEnabled = featureActive('BB-12987');
      const taxRateInBasisPoints = sbGstTaxSettingsService.getTaxRate() // The tax rate is stored in basis points (1/10,000), so 1000 basis points = 10%.

      let activityInputTaxRate;
      let activityOutputTaxRate;
      const activity = that.line.activity;
      if (inputOutputTaxEnabled && activity) {
        activityInputTaxRate = activity.inputTaxRate;
        activityOutputTaxRate = activity.outputTaxRate;
      }

      if (that.line.taxExempt) {
        // You have a tax free activity set up for a disbursement, but a user can override the tax
        // & make it inc. tax at the point of entering the disbursement & the overridden tax is then applied.
        // Jess thinks the reason for allowing this would be to allow a level of flexibility to the user.
        // The activity/ disbursement might be tax free most of the time but they might on occasion have the
        // same disbursement but from a different supplier who do charge tax, so they could need to edit it on occasion.
        taxInCents = that.line.isTaxOverridden ? that.line.tax * 100 : 0;
        // It is a valid scenario that an activity code has tax exempt but with output tax rate, in this case we should re-calculate output tax if it's not overridden
        if (!that.line.isOutputTaxOverridden) {
          outputTaxInCents = activityOutputTaxRate !== undefined && activityOutputTaxRate !== null
            ? calculateOutputTaxAmount({
              expense: {
                amountIncludesTax: that.line.amountIncludesTax,
                isOutputTaxOverridden: that.line.isOutputTaxOverridden,
                tax: taxInCents,
                outputTax: that.line.outputTax * 100,
                quantity: that.line.units * 100,
                price: that.line.rate * 100,
              },
              outputTaxRate: activityOutputTaxRate,
            })
          : 0; // Tax exemption is really applicable to input tax only, when output tax is not overridden, it should be set to same as the input tax (like how it behaves for non tax exempt activity at below). But because Desktop currently implements this as 0, we’ll keep 0 for consistency for now. I bug ticket (BB-13867) has been raised to address this.
        } else {
          outputTaxInCents = that.line.outputTax * 100;
        }
      } else {
        const inputTaxRate =
          activityInputTaxRate !== undefined && activityInputTaxRate !== null
            ? activityInputTaxRate
            : taxRateInBasisPoints;

        taxInCents = calculateTaxAmount({
          amountIncludesTax: that.line.amountIncludesTax,
          isTaxOverridden: that.line.isTaxOverridden,
          tax: that.line.tax * 100,
          quantity: that.line.units * 100,
          price: that.line.rate * 100,
        }, inputTaxRate);

        if (activityOutputTaxRate === undefined || activityOutputTaxRate === null) {
          // If no activity selected or the selected activity code selected does not have output tax rates set up
          // Set the the Output tax value its modified value or input tax value
          outputTaxInCents = that.line.isOutputTaxOverridden
            ? that.line.outputTax * 100
            : taxInCents;
        } else {
          outputTaxInCents = calculateOutputTaxAmount({
            expense: {
              amountIncludesTax: that.line.amountIncludesTax,
              isOutputTaxOverridden: that.line.isOutputTaxOverridden,
              tax: taxInCents,
              outputTax: that.line.outputTax * 100,
              quantity: that.line.units * 100,
              price: that.line.rate * 100,
            },
            outputTaxRate: activityOutputTaxRate,
          });
        }
      }
    }

    that.line.tax = taxInCents / 100;
    that.line.outputTax = outputTaxInCents / 100;
  }


  /****** Expense File Attachment ******/

  async function isAllowedFileType(file) {
    // These should match SUPPORTED_FILE_FORMATS in this file
    const allowedSignatures = [fileTypeSignature.PDF, fileTypeSignature.PNG, fileTypeSignature.JPEG];
    return file.arrayBuffer().then((fileBuffer) => {
      const fileSignature = getFileSignature(fileBuffer);
      return isValidByFileTypeSignatures({
        fileSignature,
        validFileTypeSignatures: allowedSignatures,
      });
    });
  }  

  async function fileToBase64(file) {
    const reader = new FileReader();
    return new Promise(resolve => {
      reader.onload = event => {
        const fileInBase64 = event.target.result.split(',')[1]; // https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
        resolve(fileInBase64);
      }
      reader.readAsDataURL(file)
    });
  }

  function getFileExtension(fileName) {
    if (!fileName) {
      return undefined;
    }

    const parts = fileName.split('.');
    if (parts.length < 2) {
      return undefined;
    }

    const fileExtension = parts[parts.length - 1];
    return fileExtension && fileExtension.toLowerCase();
  }

  async function uploadReceiptFile({
    accountId,
    expenseId,
    expenseVersionId,
    fileInBase64,
    fileName,
  }) {
    const fileId = expenseVersionId;
    const fileExtension = getFileExtension(fileName);
    const path = `/itops/files/${accountId}/FileUploads`;
    const body = {
      entityType: "Expense",
      entityId: expenseId,
      entityFileType: "Receipt",
      fileId,
      fileName,
      fileExtension,
      fileInBase64
    }
    const fetchOptions = {
      dataType: 'json',
      body: JSON.stringify(body)
    };

    try {
      const response = await fetchPostP({ path, fetchOptions });
      return response && response.body && response.body.filePath;
    } catch (error) {
      log.error('Error occurred while uploading file', error);
      // there not much we can do to help the user other than letting them know
      messageDisplay.error(`File Upload Error: Please try uploading another file`);
      throw error;
    }
  }

  async function getReceiptDownloadUrl({accountId, filePath}) {
    const filePathUriEncoded = encodeURIComponent(filePath);
    const path = `/itops/files/${accountId}/FileUploads/?filePath=${filePathUriEncoded}`;
    try {
      const response = await fetchGetP({ path });
      return response && response.body && response.body.downloadUrl;
    } catch (error) {
      log.error('Error occurred getting download url for file', error);
    }
  }

  function markReceiptFileForDeletion() {
    that.isDeletingReceiptFile = true;
  }


  /****** Operating Cheque ******/

  function hasOperatingCheque() {
    return that.line.operatingChequeId ? true : false;
  }

  function userWouldLikeToCreateCheque() {
    return that.view.isOperatingChequesEnabled &&
      !hasOperatingCheque() &&
      that.line.operatingChequePrintOptions &&
      that.line.operatingChequePrintOptions.chequePrintActive;
  }

  function isOperatingChequePrintManually (value) {
    return value === printMethodsByValue.PrintManually.value;
  }

  function isOperatingChequesEnabledAndPrintNow() {
    return that.view.isOperatingChequesEnabled &&
      that.line.operatingChequePrintOptions &&
      that.line.operatingChequePrintOptions.chequePrintActive &&
      that.line.operatingChequePrintOptions.chequePrintMethod === printMethodsByValue.PrintNow.value;
  }

  function updateOperatingChequePrintSettings() {
    that.view.isOperatingChequesEnabled = isOperatingChequesEnabled();
    that.view.paymentMethodOptions = getPaymentMethodOptions({ chequeEnabled: isOperatingChequesEnabled(), t: sbLocalisationService.t });
  }

  function getDefaultOperatingChequePrintMethod() {
    const operatingChequeSettings =
    getOperatingChequePrintSettings() || getDefaultOperatingChequePrintSettings();
    return operatingChequeSettings.printMethod;
  }

  function onPrintMethodUpdated ({ value }) {
    that.line.operatingChequePrintOptions.chequePrintMethod = value;
  }

  function onOperatingChequePayToUpdated (selectedOption)  {
    that.line.operatingChequePrintOptions.payToId = selectedOption && selectedOption.value;
    prepField('operatingChequePayTo');
  }

  function showUpdateButtonToCreateCheque() {
    // show create cheque button only when
    // 1) expense has been billed (finalized invoice) or marked as externally billed, and
    // 2) operating cheque is enabled, and
    // 3) no cheque has been created yet
    // this is because the expense can no longer be edited when it's billed
    // but Betty might still want to create a cheque to pay for the expense
    const showButton = that.editMode &&
      (that.line.invoiceId && that.line.finalized || that.line.isInvoicedExternally) &&
      that.view.isOperatingChequesEnabled &&
      !that.line.operatingChequeId;
    return showButton;
  }

  async function createCheque() {
    if (validateChequeFields()) {
      // gather cheque details
      const isPrintManually = that.line.operatingChequePrintOptions.chequePrintMethod === PRINT_MANUALLY;
      const operatingCheque = {
        chequeId: uuid(),
        bankAccountId: operatingBankAccountId,
        chequeDate: dateToInteger(new Date()),
        expenseIds: [ that.line.expenseId ],
        isManual: isPrintManually,
        chequeNumber: isPrintManually ? that.line.operatingChequePrintOptions.reference : undefined,
        chequeMemo: that.line.operatingChequePrintOptions.chequeMemo,
        payToId: that.line.operatingChequePrintOptions.payToId,
        allowChequeNumberDuplication
      };

      // create operating cheque
      await sbOperatingChequesService.createCheque(operatingCheque);
      messageDisplay.success(`${capitalize(sbLocalisationService.t('cheque'))} added successfully`);

      // close expense modal
      $scope.onClose({saved: true});

      // open print cheque modal if print now
      if(isOperatingChequesEnabledAndPrintNow()){
        if (featureActive('BB-13415')) {
          // LOD print operating cheques modal, expecting different props
          setModalDialogVisible({ modalId: LOD_PRINT_OPERATING_CHEQUE_MODAL_ID, props: { chequeIds: [operatingCheque.chequeId], sbAsyncOperationsService } });
        } else {
          setModalDialogVisible({ modalId: PRINT_OPERATING_CHEQUE_MODAL_ID, props: { operatingChequeIds: [operatingCheque.chequeId] } });
        }
      }
    } else {
      formDirty = true;
      if (that.errors.operatingChequeReference) {
        that.focusOn(null, 'expense-reference-field');
      }
      // leave expense modal open
      $scope.onClose({saved: false});
    }
  }

  function validateAndSetErrorsForChequeFields() {
    // clear existing errors
    delete that.errors.operatingChequePayTo;
    delete that.errors.operatingChequePrintMethod;
    delete that.errors.operatingChequeReference;
    delete that.errors.operatingChequeReferenceIsRequired;
    delete that.errors.operatingChequeReferenceShouldBeNumeric;
    delete that.errors.operatingChequeReferenceAlreadyInUse;
    delete that.warnings.operatingChequeReference;
    delete that.warnings.operatingChequeReferenceAlreadyInUse;
    
    // validate cheque fields
    if(!that.line.operatingChequePrintOptions.payToId) {
      that.errors.operatingChequePayTo = true;
    }
    if(!that.line.operatingChequePrintOptions.chequePrintMethod) {
      that.errors.operatingChequePrintMethod = true;
    }
    if (that.line.operatingChequePrintOptions.chequePrintMethod === PRINT_MANUALLY) {
      if (!that.line.operatingChequePrintOptions.reference) {
        that.errors.operatingChequeReference = true;
        that.errors.operatingChequeReferenceIsRequired = true;
      } else if (!/^[\d]+$/.test(that.line.operatingChequePrintOptions.reference)) {
        that.errors.operatingChequeReference = true;
        that.errors.operatingChequeReferenceShouldBeNumeric = true;
      } else if ((!hasFacet(facets.allowDuplicateCheque) || !allowChequeNumberDuplication) && chequeExists(+that.line.operatingChequePrintOptions.reference)) {
        that.errors.operatingChequeReference = true;
        that.errors.operatingChequeReferenceAlreadyInUse = true;
      } else if (!Number.isNaN(that.view.lastChequeNumberInSystem) &&
        hasFacet(facets.allowDuplicateCheque) &&
        +that.line.operatingChequePrintOptions.reference <= +that.view.lastChequeNumberInSystem) {
        if (allowChequeNumberDuplication) {
          that.warnings.operatingChequeReference = true;
          that.warnings.operatingChequeReferenceAlreadyInUse = true;
        }
        else {
          that.errors.operatingChequeReference = true;
          that.errors.operatingChequeReferenceAlreadyInUse = true;
        }
      }
    }
  }

  function validateChequeFields() {
    that.errors = {};
    validateAndSetErrorsForChequeFields();
    const isValid = Object.keys(that.errors).length === 0;
    return isValid;
  }

  function validatePaymentDetailsFields() {
    that.errors = {};
    validateAndSetErrorsForPaymentDetailsFields();
    const isValid = Object.keys(that.errors).length === 0;
    return isValid;
  }

  function validateAndSetErrorsForPaymentDetailsFields() {
      // clear existing errors
      delete that.errors.supplierId;
      delete that.errors.supplierReference;
      delete that.errors.paymentDue;
      delete that.errors.paymentAccountName;
      delete that.errors.paymentReference;

      if (that.line.expensePaymentDetails.isPayable && !that.line.expensePaymentDetails.supplierId) {
        that.errors.supplierId = true;
      }
      if (isSupplierReferenceInvalid({ expensePaymentDetails: that.line.expensePaymentDetails, isAnticipated: that.line.isAnticipated })) {
        that.errors.supplierReference = true;
      }
      if (that.line.expensePaymentDetails.isPayable && !that.line.expensePaymentDetails.paymentDue) {
        that.errors.paymentDue = true;
      }
      const isBankTransferOrDirectDebit = isPaidBankTransfer() || isPaidDirectDebit();
      if (isBankTransferOrDirectDebit && !that.line.expensePaymentDetails.paymentAccountName) {
        that.errors.paymentAccountName = true;
      }
      if (isBankTransferOrDirectDebit && !that.line.expensePaymentDetails.paymentReference) {
        that.errors.paymentReference = true;
      }
      if (isPaidCheque() && userWouldLikeToCreateCheque()) {
        validateAndSetErrorsForChequeFields();
      }
  }

  function onSupplierUpdated(selectedOption)  {
    that.line.expensePaymentDetails.supplierId = selectedOption && selectedOption.value;
    prepField('supplierId');
  }

  function toggleAddPayToContact() {
    that.view.showAddPayToContact = !that.view.showAddPayToContact;
  }

  function toggleAddSupplierContact() {
    that.view.showAddSupplierContact = !that.view.showAddSupplierContact;
  }

  function onSavePayToContact(contactId) {
    that.line.operatingChequePrintOptions.payToId = contactId;
    that.view.showAddPayToContact = false;
    prepField('operatingChequePayTo');
  }

  function onSaveSupplierContact(contactId) {
    that.line.expensePaymentDetails.supplierId = contactId;
    that.view.showAddSupplierContact = false;
    prepField('supplierId');
  }


  function updateContactSummaries() {
    that.contactSummaries = getContactTypeAheadSummaries();
  }

  /****** Handling Notifications ******/

  const listeners = [
    $scope.$on('smokeball-data-update-sbBillingActivityService', () => {
      log.info('saw sbBillingActivityService update');
      updateActivities();
    }),
    $scope.$on('smokeball-data-update-sbContactsMbService', () => {
      log.info('saw sbContactsMbService update');
      updateContactSummaries();
    }),
    $scope.$on('smokeball-data-update-sbMattersMbService', () => {
      log.info('saw sbMattersMbService update');
      setMatterFromPreFill();
    }),
    $scope.$on('smokeball-data-update-sbOperatingChequesService', () => {
      log.info('saw sbOperatingChequesService update');
      setDefaultOperatingChequeReference();
    }),
    $scope.$on('smokeball-data-update-sbOperatingChequePrintSettingsService', () => {
      log.info('saw sbOperatingChequePrintSettingsService update');
      updateOperatingChequePrintSettings();
    }),
  ];

  that.$onDestroy = () => {
    for (let unregister of listeners) {
      unregister();
    }
  }
});

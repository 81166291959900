import composeHooks from '@sb-itops/react-hooks-compose';
import PropTypes from 'prop-types';
import { getRecentMatters } from '@sb-billing/redux/recent-matters';
import { useQuery } from '@apollo/client';
import {
  InitUserBillingAttributes,
  BillingDashboardRecentMatters,
  DashboardInvoiceTotals,
  DashboardTotalsData,
  DashboardInvoicesCount,
  MatterTableData,
} from 'web/graphql/queries';
import moment from 'moment';
import { useSubscribedQuery, useDashboardFees, useCacheQuery } from 'web/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { status as invoiceStatus } from '@sb-billing/business-logic/invoice/entities';

import { getActiveTrustAccounts } from '@sb-billing/redux/bank-account';
import { featureActive } from '@sb-itops/feature';
import { hasFacet, facets } from '@sb-itops/region-facets';
import { isNewTheme } from 'web/services/theme';
import { getLastCompleted as getLastCompletedBankRecon } from '@sb-billing/redux/bank-reconciliations';
import * as billsListFilters from 'web/redux/route/home-billing-bills-list';
import { forgetAccountStateParams } from 'web/redux/features/transactions-recent-account-selection/actions';
import { withReduxProvider } from '../../hocs/withReduxProvider';
import { withApolloClient } from '../../hocs/withApolloClient';
import { BillingDashboardRoute } from './BillingDashboardRoute';

const hooks = () => ({
  useTempState: () => ({
    scope: 'BILLING_DASHBOARD',
  }),
  useInvoiceFilterActions: () => {
    const dispatch = useDispatch();
    return {
      forgetAccountPageParams: () => {
        dispatch(
          forgetAccountStateParams({
            parentPageName: 'home.billing.accounts.transactions',
          }),
        );
      },
      onFilterByRecent: (value) => {
        dispatch(billsListFilters.actions.onFilterByRecent(value));
      },
      onFilterInvoiceStatus: (status) => {
        dispatch(billsListFilters.actions.onFilterInvoiceStatus(status));
      },
    };
  },
  useGraphQLforRecentMatters: () => {
    const matterIds = useSelector(() => getRecentMatters()) || [];

    const { data, loading, error } = useQuery(BillingDashboardRecentMatters, {
      variables: {
        matterIds,
        includeMatterBalance: false,
        filter: {
          includeNonBillableLeadMatters: true,
          includeDeleted: false,
        },
      },
    });

    if (error) {
      throw error;
    }
    return {
      recentMatters: {
        matters: (data?.matters || []).slice(0, 5),
        loading,
      },
    };
  },
  useFirmTotals: () => {
    const {
      data: firmTotalsData,
      loading: firmTotalsLoading,
      error: firmTotalsError,
    } = useSubscribedQuery(DashboardTotalsData, {});
    const {
      data: invoicesData,
      loading: invoicesLoading,
      error: invoicesError,
    } = useSubscribedQuery(DashboardInvoiceTotals, {
      variables: {
        filter: {
          pivotDate: Number(moment().format('YYYYMMDD')),
          statuses: [invoiceStatus.FINAL],
        },
      },
    });

    if (firmTotalsError || invoicesError) {
      throw firmTotalsError || invoicesError;
    }

    const showControlledMoneyAccount = featureActive('BB-6381') && hasFacet(facets.CMA);

    return {
      accounts: {
        loading: invoicesLoading || firmTotalsLoading,
        totals: {
          credit: firmTotalsData?.firmBalancesSummary?.credit || 0,
          controlledMoney: (showControlledMoneyAccount && firmTotalsData?.firmBalancesSummary?.controlledMoney) || 0,
          trust: firmTotalsData?.firmBalancesSummary?.trust || 0,
          operating: firmTotalsData?.firmBalancesSummary?.operating || 0,
          unbilled: firmTotalsData?.firmBalancesSummary?.unbilled || 0,
          unpaid: firmTotalsData?.firmBalancesSummary?.unpaidExcInterest || 0,
          overdue: invoicesData?.invoiceTotalSummary?.overdue || 0,
        },
        showControlledMoneyAccount,
      },
    };
  },
  useUserBillingAttributesData: () => {
    const { data, error, loading: userViewedLoading } = useCacheQuery(InitUserBillingAttributes.query);

    if (error) {
      throw new Error(error);
    }

    const userViewedMessages = data?.userBillingAttributes?.viewedMessages || [];

    return { userViewedMessages, userViewedLoading };
  },
  useInvoiceCount: () => {
    const {
      data: invoiceCount,
      loading: invoiceCountLoading,
      error: invoiceCountError,
    } = useSubscribedQuery(DashboardInvoicesCount, {
      variables: {
        pivotDate: Number(moment().format('YYYYMMDD')),
      },
    });
    if (invoiceCountError) {
      throw invoiceCountError;
    }

    return {
      invoiceData: {
        invoiceCount: invoiceCount?.invoiceCount || {},
        loading: invoiceCountLoading,
      },
    };
  },
  useBankRecData: () => {
    const trustAccounts = getActiveTrustAccounts();
    let lastReconciled = null;
    const numTransactionsNotReconciled = trustAccounts.reduce((count, trust) => {
      const lastCompletedBankRecon = getLastCompletedBankRecon(trust.id);
      if (!lastCompletedBankRecon) {
        return count;
      }
      const trustLastReconciled = lastCompletedBankRecon?.reconciledDate;
      if (trustLastReconciled && (!lastReconciled || lastReconciled < trustLastReconciled)) {
        lastReconciled = trustLastReconciled;
      }
      return count + (lastCompletedBankRecon?.unbankedTransactionIds?.length || 0);
    }, 0);
    return {
      lastReconciled,
      numTransactionsNotReconciled,
    };
  },
  useFeeListData: () => {
    const fees = useDashboardFees({});

    return {
      fees,
    };
  },
  useHasAnyMatter: () => {
    // Not using useSubscribedQuery as we dont want to sub to updates. After the welcome modal is closed this is no longer relevant anyway
    const {
      data,
      error,
      loading: hasMatterLoading,
    } = useQuery(MatterTableData.query, {
      variables: {
        matterListFilter: {
          excludeBillableLeadMatters: false,
          includeDeleted: true,
        },
        offset: 0,
        limit: 1,
        includeIsOverdrawn: hasFacet(facets.allowOverdraw),
      },
    });

    if (error) {
      throw error;
    }
    return {
      hasMatterLoading,
      hasMatter: !!data?.matterList?.results?.length,
    };
  },
});
const oldHooks = () => ({
  useTempState: () => ({
    scope: 'BILLING_DASHBOARD',
  }),
});
export const BillingDashboardRouteContainer = withApolloClient(
  withReduxProvider((props) => composeHooks(isNewTheme() ? hooks : oldHooks)(BillingDashboardRoute)(props)),
);

BillingDashboardRouteContainer.propTypes = {
  onClickLink: PropTypes.func,
};

BillingDashboardRouteContainer.defaultProps = {
  onClickLink: () => {},
};

import {
  calculatePaymentsTotal,
  calculateReceiptsTotal,
  calculateUnbankedReceipts,
  calculateUnpresentedPayments,
} from '@sb-billing/business-logic/bank-reconciliation/summary';

export function generateSummaryData({
  openingCashBookBalance,
  transactions,
  depositSlips,
  unbankedTransactions,
  ttoTransactions,
  selectedDepositSlipIds,
  selectedTransactionIds,
  selectedTtoIds,
  adjustments,
}) {
  const summaryData = {
    openingCashBookBalance: openingCashBookBalance?.value || 0,
    addReceipts: calculateReceiptsTotal({ transactions, depositSlips }),
    lessPayments: calculatePaymentsTotal({ transactions, ttoTransactions }),
    addUnbankedReceipts: calculateUnbankedReceipts({
      transactions,
      depositSlips,
      unbankedTransactions,
      selectedDepositSlipIds,
      selectedTransactionIds,
    }),
    lessUnpresentedPayments: calculateUnpresentedPayments({
      transactions,
      ttoTransactions,
      selectedTransactionIds,
      selectedTtoIds,
    }),
    adjustments,
  };

  summaryData.ledgerBalance = summaryData.openingCashBookBalance + summaryData.addReceipts + summaryData.lessPayments;
  return summaryData;
}

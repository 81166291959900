/* eslint-disable import/no-cycle */
import { cacheFactory, syncTypes } from '@sb-itops/redux';
import { optimisticUpdateFactory } from '@sb-itops/redux/optimistic-update';
import domain from '../domain';

export { setDefaultPaymentMethod } from './set-default-payment-method';

const api = cacheFactory({
  domain,
  name: 'subscription',
  keyPath: 'accountId',
  ngCacheName: 'sbSubscriptionService',
  syncType: syncTypes.ALL,
});

export const { getById, getList, getMap, updateCache, UPDATE_CACHE, getLastUpdated } = api;

export const getSubscription = () => getList()[0];

export const { opdateCache, rollbackOpdateCache } = optimisticUpdateFactory({
  ngCacheName: 'sbSubscriptionService',
  keyPath: 'accountId',
});

import { gql } from '@apollo/client';

const query = gql`
  query SupportDataExplorerFee($id: ID!) {
    feeVersions(id: $id) {
      id
      versions {
        # id # excluding ID on purpose to avoid overriding Fee cache with older versions
        activityCode
        amountIncludesTax
        billableTax
        billableDuration
        createdByAutoTime
        createdByUserId
        createdByStaff {
          id
          initials
          isFormerStaff
          name
        }
        description
        duration
        feeActivityId
        feeDate
        feeEarnerStaff {
          id
          initials
          firstName
          lastName
        }
        feeType
        feeVersionId
        finalized
        invoice {
          id
        }
        isBillable
        isDeleted
        isInvoicedExternally
        isTaxExempt
        matter {
          id
        }
        notes
        rate
        sourceItems {
          description
          duration
          billable
          originalBillable
          sourceActivityIds
          activityType
          activityCount
          activityRelatedId
        }
        tax
        utbmsActivityCode
        utbmsTaskCode
        validFrom
        validTo
        waived
      }
    }
  }
`;

const notificationIds = ['FeesNotifications.AccountFeesUpdated'];

/**
 * @typedef { import('../../types.js').SubscribedQuery } SubscribedQuery
 * @type {SubscribedQuery}
 */
export const SupportDataExplorerFee = {
  query,
  notificationIds,
};

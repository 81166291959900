import uuid from '@sb-itops/uuid';
import { fetchPostP } from '@sb-itops/redux/fetch';
import { store } from '@sb-itops/redux';
import { selectors as authSelectors } from '@sb-itops/redux/auth.2';
import { PrintManually } from '@sb-billing/business-logic/cheques';
import { getById as getExpenseById, opdateCache, rollbackOpdateCache } from './index';
import {
  opdateCache as operatingChequeOpdateCache,
  rollbackOpdateCache as rollbackOperatingChequeOpdateCache,
} from '../operating-cheques';
import {
  opdateCache as expensePaymentDetailsOpdateCache,
  rollbackOpdateCache as rollbackExpensePaymentDetailsOpdateCache,
  getById as getExpensePaymentDetailsById,
} from '../expense-payment-details';

const getUserId = () => authSelectors.getUserId(store.getState());

export const saveExpense = (expenseVersion) => {
  const newExpenseVersion = { ...expenseVersion };

  const saveExpenseThunk = async () => {
    newExpenseVersion.expenseId = newExpenseVersion.expenseId || uuid();
    newExpenseVersion.expenseVersionId = newExpenseVersion.expenseVersionId || uuid();
    newExpenseVersion.createdByUserId = getUserId();

    const existingExpense = getExpenseById(newExpenseVersion.expenseId) || {};
    // Could be new expense or update of existing expense
    const expenseVersionToSave = { ...existingExpense, ...newExpenseVersion };

    // The shape of an expense differs to the payload delivered to the endpoint
    let expenseOpdateVersion = expenseVersionToSave;
    let chequeOpdate;
    let expensePaymentDetailsOpdate;
    if (expenseVersionToSave?.operatingChequePrintOptions) {
      const {
        chequeId,
        chequeMemo,
        chequeNumber: reference,
        payToId,
        bankAccountId,
        chequeDate,
        chequePrintMethod,
        // eslint-disable-next-line no-unsafe-optional-chaining
      } = expenseVersionToSave?.operatingChequePrintOptions;
      expenseOpdateVersion = {
        ...expenseVersionToSave,
        operatingChequeId: chequeId,
        operatingCheque: { chequeId, chequeMemo, chequeNumber: reference, payToId },
        operatingChequePrintOptions: {},
      };
      opdateCache({ optimisticEntities: [expenseOpdateVersion] });
      // Add operating cheque optimistic update
      chequeOpdate = {
        chequeId,
        bankAccountId,
        chequeDate,
        expenseIds: [newExpenseVersion.expenseId],
        isManual: chequePrintMethod === PrintManually,
        chequeNumber: reference,
        chequeMemo,
        payToId,
      };
      operatingChequeOpdateCache({
        optimisticEntities: [chequeOpdate],
      });
    } else {
      opdateCache({ optimisticEntities: [expenseOpdateVersion] });
    }
    // if expensePaymentDetails is not provided, backend assume it stays unchanged
    if (expenseVersionToSave?.expensePaymentDetails) {
      let updatedExpensePaymentDetails = { ...expenseVersionToSave.expensePaymentDetails };
      const existingExpensePaymentDetails = getExpensePaymentDetailsById(newExpenseVersion.expenseId);
      if (existingExpensePaymentDetails) {
        updatedExpensePaymentDetails = { ...existingExpensePaymentDetails, ...updatedExpensePaymentDetails };
        expenseVersionToSave.expensePaymentDetails = updatedExpensePaymentDetails;
      }
      expensePaymentDetailsOpdate = updatedExpensePaymentDetails;

      expensePaymentDetailsOpdateCache({ optimisticEntities: [expensePaymentDetailsOpdate] });
    }

    // Let angular know about the new expense version
    window.angular
      .element('.billing-webapp')
      .injector()
      .get('$rootScope')
      .$broadcast('smokeball-data-update-expense-saved', expenseVersion);

    // Some react code is listening to angular events via the redux store. This duplicates the event for those listeners
    store.dispatch({ type: 'smokeball-data-update-expense-saved', payload: expenseVersion });

    // Apply the save in the backend.
    try {
      const path = `/billing/expense/:accountId/`;
      const fetchOptions = { body: JSON.stringify(expenseVersionToSave) };
      await fetchPostP({ path, fetchOptions });
    } catch (err) {
      // Roll back the opdate.
      // TODO: LM: This whole interace is baked, it should use ids.
      rollbackOpdateCache({ optimisticEntities: [expenseOpdateVersion] }); // This should really be the optimistic entity, but the code for opdating is truly baked.
      if (chequeOpdate) {
        rollbackOperatingChequeOpdateCache({ optimisticEntities: [chequeOpdate] });
      }
      if (expensePaymentDetailsOpdate) {
        rollbackExpensePaymentDetailsOpdateCache({ optimisticEntities: [expensePaymentDetailsOpdate] });
      }
      // Rethrow error so UI can respond if necessary
      throw err;
    }
    return expenseVersion;
  };

  return store.dispatch(saveExpenseThunk);
};
